/**
 * It contains store module for Merchant store.
 */

import _ from "lodash";

// Api
import {
  getStoresApi,
  addStoreWithImagesApi,
  updateStoreWithImagesApi,
  deleteStoreApi,
  updateStoreStatusApi,
  getAllTaxConfigsApi,
  getAllDeliveryRulesOfStoreApi,
  updateDeliveryRuleStatusByIdApi,
  deleteDeliveryRuleByIdApi,
  getDeliveryRuleByIdApi,
  updateDeliveryRuleByIdApi,
  createDeliveryRuleApi,
} from "@/api/merchant_stores.js";

import { getTimeZoneApi  } from "@/api/general.js";

// Model
import {
  parseStores,
  parseStore,
  // MODEL_MERCHANT_STORE
} from "@/models/merchant/merchant_store.js";
// Commons
import {
  getListForDropDownMenu,
  isResponseOK,
  isResponseCREATED,
  dataURItoFile,
  isNullOrUndefined,
} from "@/utils/general.js"
import { sortByNameAscending }    from "@/utils/sort.js"
import { ENUM_STATUS_STRING }     from "@/constants/common";


export default {
  namespaced: true,

  state: {
    stores: [],           // {Object[]} List of Merchant Stores: Refer to MODEL_MERCHANT_STORE.
    selectableStores: [], // {Object[]} List of selectable Stores: {value:id, label:name, text:name}.
    
    currentStore: {},       // {Object[]} Current Merchant Store: Refer to MODEL_MERCHANT_STORE.
    currentStoreImages: [], // {File}   Image files For new Promotion.
    imagesToDelete: [],     // {string[]} Urls of images to delete.
    isCurrentStoreLocationMoved: false, // {Boolean} To determine if the current store location is moved out of the city.

    currentDeliveryRules: null, // {Object[]} Current delivry rules of the Merchant Store: Refer to MODEL_DELIVERY_RULE.
    currentDeliveryRule: null,  // {Object} A specific delivery rule of the current store that is being edited.


    // TODO : It is for the future use in case the merchant may restore the stores.
    deletedStores: [],    // {Object[]} List of deleted(soft) Merchant Stores: Refer to MODEL_MERCHANT_STORE.

    allTaxConfigs: [], // {id, country, province, taxList} To find the tax config for merchant store.
  },

  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.stores = [];
      state.selectableStores = [];

      state.currentStore = {};
      state.currentStoreImages = [];
      state.imagesToDelete = [];

      state.currentDeliveryRules = null;

      state.deletedStores = [];
    },
    /**
     * Set all Merchant Stores in the state.
     * @param {Object} state  Local State object.
     * @param {Object} stores List of Merchant Stores: Refer to MODEL_MERCHANT_STORE.
     */
    setAll(state, stores) {
      state.stores = stores;
    },
    /**
     * Set all deleted Merchant Stores in the state.
     * @param {Object} state  Local State object.
     * @param {Object} stores List of deleted Merchant Stores: Refer to MODEL_MERCHANT_STORE.
     */
    setAllDeletedStores(state, stores) {
      state.deletedStores = stores;
    },
    /**
     * Delete a Merchant Store in the state.
     * @param {Object} state Local State object.
     * @param {Object} index Index of a Merchant Store in the list.
     */
    deleteOne(state, index) {
      state.stores.splice(index, 1);
    },
    /**
     * Add a Merchant Store in the state.
     * @param {Object} state Local State object.
     * @param {Object} store Merchant Store: Refer to MODEL_MERCHANT_STORE.
     */
    addOne(state, store) {
      state.stores.push(store);
    },
    /**
     * Add a Merchant Store(Soft deleted) in the state.
     * @param {Object} state Local State object.
     * @param {Object} store Merchant Store: Refer to MODEL_MERCHANT_STORE.
     */
    addOneDeletedStore(state, store) {
      state.deletedStores.push(store);
    },
    /**
     * Update a Merchant Store in the state.
     * @param {Object} state Local State object.
     * @param {Object} store Merchant Store: Refer to MODEL_MERCHANT_STORE.
     * @param {Object} index Index of a Merchant Store in the list.
     */
    updateOne(state, { store, index }) {
      if (index != -1) {
        state.stores[index] = {
          ...state.stores[index],
          ...store
        };
      } else {
        state.currentStore = {
          ...state.currentStore,
          ...store
        };
      }
    },

    /**
     * Update currentStore in the state.
     * @param {Object} state Local State object.
     * @param {Object} store Merchant Store: Refer to MODEL_MERCHANT_STORE.
     */
    updateCurrentStore(state, store) {
      state.currentStore = {
        ...state.currentStore,
        ...store
      };
    },
    /**
     * Reset currentStore, currentStoreImages and imagesToDelete in the state.
     * @param {Object} state Local State object.
     */
    resetCurrentStore(state) {
      state.currentStore = {};
      state.currentStoreImages = [];
      state.imagesToDelete = [];
      state.isCurrentStoreLocationMoved = false;
    },
    /**
     * Set currentStoreImages.
     * @param {Object}    state  Local State object.
     * @param {Object[]}  data   List of Image object. {file, preview}.
     */
    setCurrentStoreImages(state, data) {
      // TODO : Varify the input.
      state.currentStoreImages = data;
    },
    /**
     * Add list of image urls to imagesToDelete.
     * @param {Object} state   Local State object.
     * @param {string} imgUrls List of image urls to delete.
     */
    addImagesToDelete(state, imgUrls) {
      state.imagesToDelete = [...state.imagesToDelete, ...imgUrls];
      state.imagesToDelete = _.uniq(state.imagesToDelete);
    },

    /**
     * Set current working delivery rules of the store.
     * @param {Object}    state  Local State object.
     * @param {Object[]}  data   List of delivery rule object: Refer to MODEL_DELIVERY_RULE.
     */
    setCurrentDeliveryRules(state, data) {
      // TODO : Varify the input.
      state.currentDeliveryRules = data;
    },

    /**
     * Set current delivery rule object.
     * @param {Object} state Local state
     * @param {Object} deliveryRule Delivery rule object
     */
    setCurrentDeliveryRule(state, deliveryRule) {
      state.currentDeliveryRule = deliveryRule;
    },

    /**
     * Update status of a Merchant Store in the state.
     * It update status of currentStore if the index is -1.
     * @param {Object} state  Local State object.
     * @param {Object} status Satus.
     * @param {Object} index  Index of a Merchant Store in the list.
     */
    updateStatus(state, { status, index }) {
      if (index != -1) {
        state.stores[index].status = status;
      } else {
        state.currentStore.status = status;
      }
      // console.log('state.stores', state.stores)
    },
    /**
     * Set selectable Stores in the state.
     * @param {Object} state Local State object.
     * @return {Object[]} List of selectable Stores: {value:id, label:name, text:name}.
     */
    setSelectableStores(state) {
      state.selectableStores = getListForDropDownMenu(state.stores);
    },

    /**
     * Set all available tax configs.
     * @param {Object} state Local state object
     * @param {TaxConfig[]} data List of all available tax configs
     */
    setTaxConfigs(state, data) {
      state.allTaxConfigs = data;
    },

    /**
     * Reset current store's tax configurations by removing all.
     * @param {Object} state local state object
     */
    resetTaxConfigForCurrentStore(state) {
      state.currentStore.taxConfigIds = [];
    },

    /**
     * Set the boolean value to determine whether the current store locaiton is moved out of the city.
     * @param {object} state local state object
     * @param {Boolean} data is store location moved out of the city or not.
     */
    setIsCurrentStoreLocationMoved(state, data) {
      state.isCurrentStoreLocationMoved = data;
    }
  },

  getters: {
    // 
    /**
     * Get isMerchantStoresSet.
     * @return {bool} True if Merchant Stores are set, False otherwise.
     */
    isMerchantStoresSet: (state) => {
      return Array.isArray(state.stores) && state.stores.length > 0
    },
    /**
     * Get number of active stores.
     * @return {Integer} num of stores 
     */
    numOfStores: (state) => {
      return state.stores.length;
    },
    /**
     * Get Merchant Stores by ascending name order.
     * @return {Object[]} List of Merchant Stores in ascending order by name: Refer to MODEL_MERCHANT_STORE.
     */
    storesNameOrder: (state) => {
      return [...state.stores].sort(sortByNameAscending);
    },

    /**
     * Get selectable Stores in the state.
     */
    selectableStores: (state) => {
      return state.selectableStores;
    },

    /**
     * Get current working Merchant Store.
     * @return {Object} Current Merchant Store: Refer to MODEL_MERCHANT_STORE.
     */
    currentStore: (state) => {
      return state.currentStore;
    },
    currentStoreImageFiles: state => {
      if (state.currentStoreImages.length === 0) {
        return null;
      } else {
        return _.compact(
          state.currentStoreImages.map(image => {
            return image.file ? dataURItoFile(image.preview) : null;
          })
        );
      }
    },
    currentStoresImageObjects: state => {
      if (state.currentStoreImages.length === 0) {
        return null;
      } else {
        return state.currentStoreImages.map(image => {
          return {
            "file": image.file ? image.file : null,
            "preview": image.preview
          }
        });
      }
    },
    imagesToDelete: state => {
      return state.imagesToDelete;
    },
    
    /**
     * Get current working delivery rules of the store.
     */
    currentDeliveryRules: state => {
      return state.currentDeliveryRules;
    },

    /**
     * Get current delivery rule
     * @param {Object} state Local state
     * @returns delivery rule
     */
    currentDeliveryRule: state => {
      return state.currentDeliveryRule;
    },

    /**
     * Get a Merchant Store by Id.
     * @param {string} id Id of a Merchant Store.
     * @return {Object} Merchant Store: Refer to MODEL_MERCHANT_STORE.
     */
    findStoreById: (state) => (id) => {
      return state.stores.find(
        store => store.id === id
      );
    },
    /**
     * Get index of a Merchant Store in the list by Id.
     * @param {string} id Id of a Merchant Store.
     * @return {number} Index of a Merchant Store in the list.
     */
    findIndexById: (state) => (id) => {
      return state.stores.indexOf(
        state.stores.find(
          store => store.id === id
        )
      );
    },

    /**
     * Get all available tax configs.
     * @param {Object} state Locall state
     * @returns list of all available tax configs
     */
    taxConfigList: (state) => {
      return state.allTaxConfigs;
    },

    /**
     * To determine whether the current store locaiton is moved out of the city.
     * @param {Object} state local state object.
     * @returns true if current store's location is moved out of the city.
     */
    isCurrentStoreLocationMoved: (state) => {
      return state.isCurrentStoreLocationMoved;
    },
    
    /*
     * Get list of tax configs by list of their ids.
     * @param {Object} state local state
     * @param {List<String>} ids list of tax config ids. 
     * @returns List of tax configs.
     */
    getTaxConfigsByIds: (state) => (ids) => {
      return ids.map(id => state.allTaxConfigs.find(taxConfig => taxConfig.id === id));
    },

    /**
     * Get tax configs for a store by it's id.
     * @param {Object} state Local state
     * @param {Object} getters List of all getters for current module. 
     * @returns list of taxConfigIds 
     */
    getDefaultTaxConfigsOfStore: (state, getters) => (storeId) => {
      return getters.findStoreById(storeId).taxConfigIds;
    },

    /**
     * Check whether the given list of tax ids are default store tax by given storeId.
     * @param {Object} state Local state object
     * @param {Object} getters    Current module getters
     * @returns true if the given tax ids are completely matched with store's tax ids else false.
     */
    checkIsDefaultStoreTax: (state, getters) => (taxConfigIds, storeId) => {
      // Check if the tax config ids are customized and are not default anymore.
      let isDefaultStoreTax = true;
      const defaultTaxConfigsForStore = getters.getDefaultTaxConfigsOfStore(storeId);

      if (isNullOrUndefined(defaultTaxConfigsForStore)) return false; // invalid case, a store must have tax ids.

      if (!isNullOrUndefined(taxConfigIds)) {

        if (defaultTaxConfigsForStore.length == taxConfigIds.length) {

          const matchedTaxConfigIds = taxConfigIds.filter(taxConfigId => 
            defaultTaxConfigsForStore.includes(taxConfigId));

          if (matchedTaxConfigIds.length !== defaultTaxConfigsForStore.length) {
            isDefaultStoreTax = false;
          }

        } else {
          isDefaultStoreTax = false;
        }
      } else {
        // For tax exempt case.
        isDefaultStoreTax = false;
      }

      return isDefaultStoreTax;
    },
    getStoreNameById: (state) => (id) => {
      const store = state.stores.find(
        store => store.id === id
      );

      return (!isNullOrUndefined(store)) ? store.name : id;
    }
  },
  actions: {
    /**
     * Load Merchant Stores.
     * It loads Merchant Stores from the sever and set selectableStores.
     * @return {Object} Response object of a Promise.
     */
    async loadStores({ commit }) {
      const response = await getStoresApi();
      if (isResponseOK(response)) {
        // Filter and parse the stores.
        const stores = parseStores(response.data.filter(store => store.status !== ENUM_STATUS_STRING.DELETED));
        const deletedStores = parseStores(response.data.filter(store => store.status === ENUM_STATUS_STRING.DELETED));
        // console.log('stores', stores)
        // Sat the state.
        commit("setAll", stores);
        commit("setAllDeletedStores", deletedStores);
        commit("setSelectableStores");
      } else {}
      return response;
    },
    /**
     * Update a Merchant Store.
     * It updates a Merchant Store and set selectableStores.
     * @return {Object} Response object of a Promise.
     */
    async updateOneStore({ commit, getters }, { merchantStore, imageObjects }) {
      // console.log('updateOneStore', merchantStore);
      // console.log('updateOneStore', imageObjects);
      // TODO: fix it on BE side
      if (merchantStore.phone.length < 12) {
        merchantStore.phone = `+1${merchantStore.phone}`;
      }

      const response = await updateStoreWithImagesApi(
        merchantStore,
        imageObjects,
      );

      if (isResponseOK(response)) {
        commit("updateOne", {
          store: response.data,
          index: getters.findIndexById(merchantStore.id),
        });
        commit("resetCurrentStore");
        commit("updateCurrentStore", response.data);
        commit("setSelectableStores");
      } else {}
      return response;
    },
    // TODO : deal with the response syntax error.
    /**
     * Delete a Merchant Store.
     * It deletes a Merchant Store and set selectableStores.
     * @return {Object} Response object of a Promise.
     */
    async deleteOneStore({ commit, getters }, id) {
      const response = await deleteStoreApi(id);
      // console.log('response', response)
      commit("deleteOne", getters.findIndexById(id));
      commit("setSelectableStores");
      return response;
    },
    /**
     * Add a Merchant Store.
     * It adds a Merchant Store and set selectableStores.
     * @return {Object} Response object of a Promise.
     */
    async addOneStore({ commit, getters}, { merchantStore, imageObjects }) {
      // const testObj = {
      //   ...merchantStore,
      //   'timeZone': getters.currentStore.timeZone, // Add timezone.
      // };
      // console.log('testObj', testObj)
      const response = await addStoreWithImagesApi(
        {
          ...merchantStore,
          'timeZone': getters.currentStore.timeZone, // Add timezone.
        },
        imageObjects,
      );
      
      if (isResponseCREATED(response)) {
        commit("addOne", parseStore(response.data));
        commit("updateCurrentStore", parseStore(response.data));
        commit("setSelectableStores");
      } else {}
      return response;
    },
    /**
     * Update status of a Merchant Store.
     * @param {Object} merchantStore Merchant Store object: Refer to MODEL_MERCHANT_STORE.
     */
    async updateStoreStatus({ commit, getters }, merchantStore) {
      // console.log(merchantStore);
      const { id, status } = merchantStore;
      const response = await updateStoreStatusApi(id, status);
      // console.log('updateStoreStatus', response);
      if (isResponseOK(response)) {
        commit("updateStatus", {
          status: status,
          index: getters.findIndexById(id),
        });

        commit("setSelectableStores");

      } else {}
      return response;
    },

    /**
     * Update timeZone of currentStore.
     * @param {Object} location Location object object: Refer to MODEL_LOCATION.
     */
    async updateCurrentStoreTimeZone({ commit }, location) {
      const response = await getTimeZoneApi(location.y, location.x);
      if(isResponseOK(response)){
        commit("updateCurrentStore", { timeZone: response.data });
      }
    },

    /**
     * Get all available taxConfigs
     */
    async getAllTaxConfigs({commit}) {
      const response = await getAllTaxConfigsApi();
      if (isResponseOK(response)) {
        commit("setTaxConfigs", response.data); // response.data: TaxConfig[]
      }
    },


    // Delivery rule actions

    /**
     * Get all delivery rules of given store. And store it in the state.
     * @param {Local store commit object} param0 commit
     * @param {BigInteger} storeId MerchantStore id.
     */
    async getAllDeliveryRulesOfStore({commit}, storeId) {
      const response = await getAllDeliveryRulesOfStoreApi(storeId);
      if (isResponseOK(response)) {
        commit("setCurrentDeliveryRules", response.data);
      }
    },

    /**
     * Update delivery rule by id
     * @param {Local store commit object} param0 commit
     * @param {BigInteger} storeId        MerchantStore id.
     * @param {String}     deliveryRuleId DeliveryRule id.
     * @param {Status}     status         Status of Delivery Rule.
     */
    async updateDeliveryRuleStatusById({commit}, {storeId, deliveryRuleId, status}) {
      const response = await updateDeliveryRuleStatusByIdApi(storeId, deliveryRuleId, status);
      if (isResponseOK(response)) {
        // The front end data is already in sync.
      }
    },

    /**
     * Delete delivery rule by id.
     * @param {Local store commit object} param0 commit
     * @param {BigInteger} storeId MerchantStore id.
     * @param {String} deliveryRuleId DeliveryRule id.
     */
    async deleteDeliveryRule({commit, dispatch}, {storeId, deliveryRuleId}) {
      const response = await deleteDeliveryRuleByIdApi(storeId, deliveryRuleId);
      if (isResponseOK(response)) {
        // successfully delete. Load all the rules again to sync data.
        dispatch('getAllDeliveryRulesOfStore', storeId);
      }
    },

    /**
     * Load a delivery rule by id.
     * @param {Object}     commit         Local state commit object
     * @param {BigInteger} storeId        MerchantStore id
     * @param {String}     deliveryRuleId DeliveryRule id. 
     */
    async getDeliveryRule({commit}, {storeId, deliveryRuleId}) {
      const response = await getDeliveryRuleByIdApi(storeId, deliveryRuleId);
      if (isResponseOK(response)) {
        commit('setCurrentDeliveryRule', response.data);
      }
    },

    /**
     * Update delivery rule
     * @param {BigInteger} storeId MerchantStore id 
     * @param {String} deliveryRuleId DeliveryRule id
     * @param {Object} deliveryRuleObject Data for delivery rule object.
     */
    async updateDeliveryRule({dispatch}, {storeId, deliveryRuleId, deliveryRuleObject}) {
      const response = await updateDeliveryRuleByIdApi(storeId, deliveryRuleId, deliveryRuleObject);
      if (isResponseOK(response)) {
        dispatch('getAllDeliveryRulesOfStore', storeId);
      }
    },

    /**
     * Create delivery rule 
     * @param {BigInteger} storeId MerchantStore id
     * @param {Object} deliveryRuleObject Delivery rule object, refer to: MODEL_DELIVERY_RULE 
     */
    async createDeliveryRule({dispatch}, {storeId, deliveryRuleObject}) {
      const response = await createDeliveryRuleApi(storeId, deliveryRuleObject);
      if (isResponseOK(response)) {
        dispatch('getAllDeliveryRulesOfStore', storeId);
      }
    },



    // Local store helpers
    /**
     * Update Information of a Merchant Store locally.
     */
    updateStoreInfo({ commit, getters }, data) {
      const { id, info } = data;
      commit("updateOne", {
        index: getters.findIndexById(id),
        store: info,
      });
    },

    /**
     * Update Address of currentStore locally.
     * @param {Object} address Address object: Refer to MODEL_ADDRESS.
     */
    updateCurrentStoreAddress({ commit }, address) {
      commit("updateCurrentStore", { address: address });
    },
    /**
    * Update currentStore in the state.
    * @param {Object} store Merchant Store: Refer to MODEL_MERCHANT_STORE.
    */
    updateCurrentStore({ commit }, store) {
      commit("updateCurrentStore", store);
    },
    /**
     * Reset currentStore in the state.
     */
    resetCurrentStore({ commit }) {
      commit("resetCurrentStore");
    },
    /**
     * Set currentStoreImages.
     * @param {Object[]} data List of Image object. {file, preview}.
     */
    setCurrentStoreImages({ commit }, data) {
      // TODO : Varify the input.
      commit("setCurrentStoreImages", data);
    },

    /**
     * Set current working delivery rules of the store.
     * @param {Object[]} data List of delivery rule object: Refer to MODEL_DELIVERY_RULE.
     */
    setCurrentDeliveryRules({ commit }, data) {
      // TODO : Varify the input.
      commit("setCurrentDeliveryRules", data);
    },

    /**
     * Add list of image urls to imagesToDelete.
     * @param {string} imgUrls List of image urls to delete.
     */
    addImagesToDelete({ commit }, imgUrls) {
      commit("addImagesToDelete", imgUrls);
    },
  }
};
  