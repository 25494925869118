/**
 * It contains model of ProductAttribute object.
 */
//TODO: move to model/product?

// General parse and serialize function
import { parse, serialize } from "../general.js";

/**
 * ProductAttribute Model - for Parse and Serialize use.
 */
const PRODUCT_ATTRIBUTE_MODEL = {
  id: "id",
  merchantID: "merchantID",
  name: "name",
  description: "description"
};

/**
 * Prase a ProductAttribute object.
 * @param {Object} productAttribute ProductAttribute object: Refer to PRODUCT_ATTRIBUTE_MODEL.
 * @return {Object} Prased ProductAttribute object.
 */
export function parseProductAttribute(productAttribute) {
  return productAttribute;
  // TODO
  // return parse(productAttribute, PRODUCT_ATTRIBUTE_MODEL);
}

/**
 * Serialize a ProductAttribute object.
 * @param {Object} productAttribute ProductAttribute object: Refer to PRODUCT_ATTRIBUTE_MODEL.
 * @return {Object} Serialized ProductAttribute object.
 */
export function serializeProductAttribute(productAttribute) {
  return productAttribute;
  // TODO
  // return serialize(productAttribute, PRODUCT_ATTRIBUTE_MODEL);
}