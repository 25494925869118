var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    { staticClass: "custom-form-group" },
    [
      _c("h6", [_vm._v(_vm._s(_vm.$t("product.provide-price")))]),
      _vm._v(" "),
      _c(
        "b-row",
        { attrs: { "align-v": "center", "no-gutters": "" } },
        [
          _c(
            "b-colxx",
            { attrs: { sm: _vm.colSize } },
            [
              _c(
                "b-input-group",
                {
                  class: {
                    "mb-5": _vm.$v.data.price.$invalid && !_vm.isFirstLoad,
                  },
                  attrs: { append: "CAD" },
                },
                [
                  _c("b-form-input", {
                    staticClass: "custom-form-input",
                    attrs: {
                      "data-cy": `${_vm.name}-input-price`,
                      placeholder: _vm.$t("forms.price"),
                      disabled: _vm.disabled,
                    },
                    on: { keypress: _vm.restrictDecimal },
                    model: {
                      value: _vm.data.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "price", $$v)
                      },
                      expression: "data.price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "invalid-feedback-space-md",
                  attrs: {
                    state: !_vm.$v.data.price.$invalid || _vm.isFirstLoad,
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("validations.num-greater-and-0")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }