// Edit Forms for Name and Description
// Author : Huen Oh (heons921@gmail.com)
// @param {bool}   value     Value for isChanged. v-model. (in, out)
// @param {bool}   disabled  Disabled property of the component. (in)
// @param {number} colSize   Size of the column for the form. : 1 ~ 12. (in)
// @param {Object} data      Data object: {name:"", description:""}. (in, out)
// @param {string} name      Name of the component. It is the identifier of the component. (in)

<template>
  <b-form>
   <b-row>
    <b-colxx xxs="12" md="20">
      <!-- Name -->
      <b-form-group >
        <b-row align-v="end">
          <b-colxx xxs="10">
            <h4 class="mb-0 pb-2">{{$t(`${label}.provide-name`)}}</h4>
          </b-colxx>
          <b-colxx xxs="2">
            <p class="text-right text-muted text-small mb-0 pb-2">{{textCountName}}</p>
          </b-colxx>
        </b-row>
        <b-form-input
          :data-cy="`${name}-input-name`"
          v-model="data.name"  
          :state="!$v.data.name.$invalid || isFirstLoad"
          :disabled="disabled"
        ></b-form-input>
        <b-form-invalid-feedback>
          {{ $t('general.minimum-3-characters')}}
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- Description -->
      <b-form-group >
        <b-row align-v="end">
          <b-colxx xxs="10">
            <h4 class="mb-0 pb-2">{{$t(`${label}.provide-description`)}}</h4>
          </b-colxx>
          <b-colxx xxs="2">
            <p class="text-right text-muted text-small mb-0 pb-2">{{textCountDescription}}</p>
          </b-colxx>
        </b-row> 
        <b-textarea
          :data-cy="`${name}-input-description`"
          v-model="data.description"
          :rows="5"
          :max-rows="5"
          :state="!$v.data.description.$invalid || isFirstLoad"
          :disabled="disabled"
        ></b-textarea>
        <b-form-invalid-feedback>
          {{ $t('general.minimum-3-characters')}}
        </b-form-invalid-feedback>
      </b-form-group>     
    </b-colxx>
   </b-row>
  </b-form>

</template>
<script>

import { validationMixin } from "vuelidate";
const { required, minLength,maxLength } = require("vuelidate/lib/validators");

export default {
  components:{
  },
  props:{
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {number} Size of the column for the form. : 1 ~ 12.
    colSize: {
      type: Number,
      default: 10,
    },
    // {bool} Value for isChanged. v-model.
    value: {
      type: Boolean,
      default: false
    },
    // {Object} data : {name:"", description:""}
    data: {
      type: Object,
      default: () => { return {name:"", description:""} }
    },
    // {string} Name of the component. It is the identifier of the component. (in)
    name: {
      type: String,
      default: ""
    },
    // {string} To set the label names: Product/Promotion
    label: {
      type: String,
      default: ""
    },
    // {Boolean} Is First Load, in order not to display validation message at first
    isFirstLoad:{
      type: Boolean,
      default: true
    }
    
  },
  data(){
    return{
      // data: {name:"", description:""},
    }
  },
  mixins: [validationMixin],
  validations: {
     data: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(35)
      },
      description: {
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
    },
  },
  computed:{
    // For text count display.
    textCountName: function () {
      return this.data.name.length+"/35";
    },
    textCountDescription: function () {
      return this.data.description.length+"/255";
    }
  },

  methods: {
    
  },

  watch: {

  },

  mounted(){ 
  }
}
</script>