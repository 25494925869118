var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline-block mr-4 mb-1 align-top" },
    [
      _c(
        "div",
        { staticClass: "search-sm d-inline-block" },
        [
          _c("b-input", {
            attrs: {
              "data-cy": "top-input-search",
              placeholder: _vm.$t("menu.search"),
            },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.searchOnEnter.apply(null, arguments)
              },
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          attrs: { size: "xs", variant: "outlined" },
          on: { click: _vm.searchOnEnter },
        },
        [_vm._v("Search")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }