/**
 * It contains backend CRUD Apis for Merchant Profile.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import {BE_URLS} from "@/constants/config.js";

// Modules in the application.
import axios from "@/api/axios/axios_core_deal.js";
import {uploadOneImageApi, uploadImagesApi} from "@/api/general.js";
import {serializeProfile} from "@/models/merchant/merchant_profile.js";

// URLs for backend
export const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;
const MERCHANT_PROFILE_URL = `${HOST}/merchants/profile`;
const LOGO_URL = `${HOST}/merchants/profile/logo`;
const IMAGES_URL = `${HOST}/merchants/profile/images`;
const STRIPE_SESSION_URL = `${HOST}/merchants/get-session-data/`;
const STRIPE_CUSTOMER_URL = `${HOST}/merchants/get-customer/`;
const STRIPE_SUBSCRIPTIONS_URL = `${HOST}/merchants/get-subscriptions/`;
const STRIPE_CANCEL_SUBSCRIPTION_URL = `${HOST}/merchants/cancel-subscription/`;
const STRIPE_SUBSCRIPTION_SESSION_URL=`${HOST}/merchants/create-session/`;
/**
 * Get Merchant Profile.
 * @return {Promise} Result of GET for Merchant Profile.
 */
export function getProfileApi() {
  return axios.get(MERCHANT_PROFILE_URL, {
    headers: {
      'Access-Control-Allow-Origin': '*',
    }
  });
}

/**
 * Update Merchant Profile.
 * @param {Object}  data    Mercahnt Profile object: Refer to MODEL_MERCHANT_STORE.
 * @param {File}    logo    Logo image file.
 * @param {File[]}  images  Business image files.
 * @return {Promise} Result of POST for Merchant Profile.
 */
export async function updateProfileApi(data, logo, images) {
  // TODO : It is temporary fix. since response of these give syntax error.
  setTimeout(() => {
    // It updates profile's logo image directly in the server.
    if (logo) {
      uploadOneImageApi(LOGO_URL, logo);
    } else {
    }
  }, 200);

  setTimeout(() => {
    // It updates profile's business images directly in the server.
    if (Array.isArray(images) && images.length > 0) {
      uploadImagesApi(IMAGES_URL, images);
    } else {
    }
  }, 400);

  return await axios.post(MERCHANT_PROFILE_URL, serializeProfile(data));
}

/**
 * Delete business images of Merchant Profile.
 * @param {string[]} imgUrls Urls of Business images.
 * @return {Promise} Result of DELETE for business images of Merchant Profile.
 */
export async function deleteImageUrlsApi(imgUrls) {
  let formData = new FormData();
  for (const imgUrl of imgUrls) {
    formData.append("urls", imgUrl);
  }
  return await axios({
    method: "delete",
    url: IMAGES_URL,
    headers: {
      Accept: "*/*"
    },
    data: formData
  });
}

export async function getSessionData(sessionId) {
  return axios.get(STRIPE_SESSION_URL + sessionId, {
    headers: {
      'Access-Control-Allow-Origin': '*',
    }
  });
}

export async function getStripeCustomerData(customerId) {
  return axios.get(STRIPE_CUSTOMER_URL + customerId, {
    headers: {
      'Access-Control-Allow-Origin': '*',
    }
  });
}
export async function getStripeCustomerSubscriptions(customerId) {
  return axios.get(STRIPE_SUBSCRIPTIONS_URL + customerId, {
    headers: {
      'Access-Control-Allow-Origin': '*',
    }
  });
}

export async function cancelStripeCustomerSubscription(subscriptionId) {
  let formData = new FormData();
  formData.append("subscriptionId", subscriptionId);

  return await axios({
    method: "delete",
    url: STRIPE_CANCEL_SUBSCRIPTION_URL,
    headers: {
      Accept: "*/*"
    },
    data: formData
  });

}
export async function getStripeCustomerSubscriptionSession(customerId,successUrl) {
  let formData = new FormData();
  formData.append("customerId", customerId);
  formData.append("successURL", successUrl);

  return await axios({
    method: "post",
    url: STRIPE_SUBSCRIPTION_SESSION_URL,
    headers: {
      Accept: "*/*"
    },
    data: formData
  });

}
