/**
 * It contains backend Apis for social media posting.
 * @author: Aysegul Yalcinkaya
 */

// Constant.
import {BE_URLS} from "@/constants/config.js";

// Modules in the application.
import axios from "@/api/axios/axios_ayrshare";
import axios_openai from "@/api/axios/axios_openai";

import {BIconBadge4k} from "bootstrap-vue";
import HttpStatus from "http-status-codes";

// URLs for Backend
const HOST = BE_URLS.BACKEND_PAYMENT_SERVER_URL;

const CREATE_POST = `https://app.ayrshare.com/api/post`;
const CREATE_POST_CONTENT = `https://api.openai.com/v1/chat/completions`;
const CREATE_POST_IMAGE = `https://api.openai.com/v1/images/generations `;
const POST_HISTORY = `https://app.ayrshare.com/api/history`;
/**
 * Send request to create post
 * @return {Promise} POST Promise.
 */
export function createScheduledPost(data) {
    if (! data.hasOwnProperty("autopost")) data.autopost={};

    let result = axios.request({
        url: CREATE_POST,
        method: "POST",
        data:
            {
                post: data.content.toString(),
                mediaUrls:data.mediaUrls,
                platforms: data.platforms,
                profileKey: data.profileKey,
                scheduleDate: data.scheduleDate,
                autopost:data.autopost
            }
    });
    console.log(result);
    return result;
}

export async function getPostHistory(data){
  axios.defaults.headers.common['Profile-Key'] = data.profileKey
  let result= await axios.request({
    url: POST_HISTORY,
    method:"GET",
  })
  return result;
}
//createPostContent function
export async function createPostContent(data) {
  console.log(data);

  let messageContent = ""; // Initialize an empty string to hold the specific message content.

  // Determine the focus of the post and create a tailored message for either a perk or a holiday.
  if (data.perk && !data.holiday) { // If there is a perk but no holiday specified
      messageContent = "Business type: " + data.businessType + "\n" +
          "Description: " + data.description + "\n" +
          "Name: " + data.name + "\n" +
          "Business lines: " + data.businessLines + "\n" +
          "Promotion: " + data.perk + "\n" +
          "Promotion start date: " + data.startDate;
  } else if (data.holiday && !data.perk) { // If there is a holiday but no perk specified
      messageContent = "Business type: " + data.businessType + "\n" +
          "Description: " + data.description + "\n" +
          "Name: " + data.name + "\n" +
          "Business lines: " + data.businessLines + "\n" +
          "Celebrating: " + data.holiday + "\n" +
          "Event date: " + data.startDate;
  } else {
      // Default message or error handling could go here if neither is specified
      messageContent = "Business type: " + data.businessType + "\n" +
          "Description: " + data.description + "\n" +
          "Name: " + data.name + "\n" +
          "Business lines: " + data.businessLines + "\n" +
          "Note: No specific promotion or holiday information provided.";
  }

  return await axios_openai.request({
      url: CREATE_POST_CONTENT,
      method: "POST",
      data: {
          model: "gpt-3.5-turbo",
          messages: [
              {
                  role: "system",
                  content: "You are a helpful assistant to create post content for the given platform.\n" +
                      "You will get the merchant's business information from the user. Create content with 200 characters max.\n" +
                      "The post can be related to a given promotion or a specific holiday."
              },
              {
                  role: "user",
                  content: messageContent
              }
          ]
      }
  });
}

//old code
/*export async function createPostContent(data) {
    console.log(data);
    return await axios_openai.request({
        url: CREATE_POST_CONTENT,
        method: "POST",
        data:
            {
                model: "gpt-3.5-turbo",

                messages: [
                    {
                        role: "system",
                        content: "You are a helpful assistant to create a post content for the given platform.\n" +
                            "You will get the merchant's business information from the user. Create a content with 200 characters max." +
                          "Post can be related to given promotion or given holiday. Use whichever is given."
                    },
                    {
                        role: "user",
                        content: "business type:" + data.businessType + "\n" +
                          "description: " +data.description + "\n" +
                          "name: " + data.name + "\n" +
                          "Business lines:" + data.businessLines + "\n" +
                          "Promotion:" + data.perk + "\n" +
                          "start date: " + data.startDate+"\n"+
                          "for holiday: "+data.holiday
                    }]
            }
    });


}
*/

export async function createPostImage(data) {
  var reason="";//currently reason is not used for image generation 
  var prompt="";
    if (data.hasOwnProperty("perk")){
      reason=data.perk;
      prompt="Create a realistic style image about a business. Business is about:" + data.businessLines +
      ". Have only three products related to " + data.businessLines + ". " +
      "NO TEXT in image." + 
      "Natural lighting with some shadows.";
    }
    else {
      reason=data.holiday;
      prompt= "Create an image for a social media post for this holiday: " + reason;
    }
    return await axios_openai.request({
        url: CREATE_POST_IMAGE,
        method: "POST",
        data:
            {
                model: "dall-e-3",
                prompt: prompt,                 
                style: "vivid",
                quality: "hd",
                n: 1,
                size: "1024x1024"
            }
    });


}


