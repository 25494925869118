/**
 * It contains backend CRUD Apis for AddonGroups.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// Modules in the application.
import axios                    from "@/api/axios/axios_core_deal.js";
import { uploadOneImageApi }    from "@/api/general.js";
import { serializeAddonGroup }  from "@/models/products/addon_group.js";

// URLs for backend
const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;
const IMAGE_UPLOAD_URL = BE_URLS.BACKEND_IMAGE_UPLOAD_URL;

/**
 * Get URL for AddonGroups.
 * @param {string} [id] ID of an AddonGroup object.
 * @return {string} URL of backend for AddonGroups
 */
function getUrl(id) {
  return `${HOST}/addon-groups/${id ? id : ""}`;
}

/**
 * Get URL for Addon update in a AddonGroup.
 * @param {string} [id] ID of an AddonGroup object.
 * @return {string} URL of backend for Addon update in a AddonGroup.
 */
function getUrlForAddonUpdate(id) {
  return `${HOST}/addon-groups/${id ? id : ""}/addons`;
}

/**
 * Get AddonGroups.
 * @return {Promise} Result of GET for AddonGroups.
 */
export async function getAddonGroupsApi() {
  return await axios.get(getUrl());
}

/**
 * Get an AddonGroup by Id.
 * @param {string} id ID of an AddonGroup object.
 * @return {Promise} Result of GET for an AddonGroup by Id.
 */
export async function getOneAddonGroupApi(id) {
  return await axios.get(getUrl(id));
}

/**
 * Add an AddonGroup.
 * @param {Object} data AddonGroup object: Refer to ADDON_GROUP_MODEL and EMPTY_ADDON_GROUP.
 * @return {Promise} Result of POST for an AddonGroup.
 */
export async function addAddonGroupApi(data) {
  // console.log("dd");
  return await axios.post(getUrl(), serializeAddonGroup(data));
}

/**
 * Add an AddonGroup with an image.
 * 
 * It adds an AddonGroup with an image.
 * If imageObject is not provided(null or undefined), it adds an AddonGroup without an image.
 *
 * @param {Object} data           AddonGroup object: Refer to ADDON_GROUP_MODEL and EMPTY_ADDON_GROUP.
 * @param {Object} [imageObject]  Image object: {files, preview}
 * @return {Promise} Result of POST for an AddonGroup.
 * @return {null}    If there is an error - TODO
 */
export async function addAddonGroupWithImageApi(data, imageObject) {
  // let toSend = { ...serializeAddonGroup(data) };
  let toSend = data;

  if (!imageObject || !imageObject.file) {
    // Add(Upload) an AddonGroup without an image
    return addAddonGroupApi(toSend);

  } else {
    // Add(Upload) an AddonGroup with an image

    // Upload image
    const imageFromServer = await uploadOneImageApi(IMAGE_UPLOAD_URL, imageObject.file);
    // console.log(imageFromServer);

    // Add an AddonGroup when uploading an image is successful
    if (imageFromServer != null) {
      // Add the image to the toSend data
      toSend.changedImages = [imageFromServer];
      // console.log(toSend);
      return addAddonGroupApi(toSend);
    } else {
      return null;
    }
  }
}

/**
 * Update an AddonGroup.
 * @param {Object} data AddonGroup object: Refer to ADDON_GROUP_MODEL and EMPTY_ADDON_GROUP.
 * @return {Promise} Result of PUT for an AddonGroup.
 */
export async function updateAddonGroupApi(data) {
  return await axios.put(getUrl(data.id), serializeAddonGroup(data));
}

/**
 * Update an AddonGroup with an image.
*
 * It updates a AddonGroup with an image.
 * If imageObject is undefined, it updates a AddonGroup without an image.
 * If imageObject is null, it updates a AddonGroup by deleting an image.
 *
 * @param {Object} data           AddonGroup object: Refer to ADDON_GROUP_MODEL and EMPTY_ADDON_GROUP.
 * @param {Object} [imageObject]  Image object: {files, preview}
 * @return {Promise} Result of PUT for an AddonGroup.
 * @return {null}    If there is an error - TODO
 */
export async function updateAddonGroupWithImageApi(data, imageObject) {
  // console.log("imageObject", imageObject);
  // let toSend = { ...serializeStore(data) };
  let toSend = data;

  if (undefined === imageObject) {
    // Update an AddonGroup without an image
    return updateAddonGroupApi(toSend);

  } else if (null === imageObject) {
    // Update an AddonGroup with deleting an image
    // Update deletedImages
    if (toSend.images) {
      toSend.deletedImages = toSend.images;
    }
    return updateAddonGroupApi(toSend);

  } else {
    // Update an AddonGroup with adding/changing an image
    if (imageObject.file) {
      // Upload images
      const imageFromServer = await uploadOneImageApi(IMAGE_UPLOAD_URL, imageObject.file);
      // console.log(imagesFromServer);

      // Update an AddonGroup When uploading an image is successful
      if (imageFromServer != null) {
        // Add the image to the toSend data
        toSend.changedImages = [imageFromServer];
        // Update deletedImages
        if (toSend.images) {
          toSend.deletedImages = toSend.images;
        }
        // console.log(toSend);
        return updateAddonGroupApi(toSend);

      } else {
        return null;
      }

    } else {
      // Update an AddonGroup without an image
      return updateAddonGroupApi(toSend);
    }
  }

}

/**
 * Delete an AddonGroup.
 * @param {Object} id ID of an AddonGroup object.
 * @return {Promise} Result of DELETE for an AddonGroup.
 */
export async function deleteAddonGroupApi(id) {
  return await axios.delete(getUrl(id));
}


/**
 * Add Addons to an AddonGroup.
 * @param {string}   id   ID of an AddonGroup object.
 * @param {string[]} data Array of Addons' Id: [addon.id, addon.id, ...]
 * @return {Promise} Result of PUT to add Addons to an AddonGroup.
 */
export async function addAddonsInGroupApi(id, data) {
  const addons = {
    addons: data
  };
  return await axios.put(getUrlForAddonUpdate(id), addons);
}

/**
 * Add Addons to an AddonGroup.
 * @param {string}   id   ID of an AddonGroup object.
 * @param {string[]} data Array of Addons' Id: [addon.id, addon.id, ...]
 * @return {Promise} Result of DELETE to delete Addons from an AddonGroup.
 */
export async function deleteAddonsInGroupApi(id, data) {
  const addons = {
    addons: data
  };
  // return await axios.delete(getUrlForAddonUpdate(id), addons);
  return await axios({
    method: "delete",
    url: getUrlForAddonUpdate(id),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    },
    data: addons
  });
}
