
import HttpStatus from 'http-status-codes';

import LocalStorageService from '@/utils/local_store';

import {
  sendLoginApi,
  sendRegistrationApi,
  sendPasswordResetRequestApi,
  sendSetupNewPasswordApi,
  getAccountInfoApi
} from "@/api/auth.js";

import { serialize }    from "@/models/auth.js";
import { isResponseOK } from "@/utils/general.js"

// TODO: move to locales?
const BAD_CREDS_ERROR = "Bad credentials";
const UNAUTHORIZED_ERROR = "Unauthorized";
const UNKNOWN_ERROR = "Something went wrong. Try again";

export default {
  namespaced: true,
  state: {
    accountInfo: {},
    merchant: {},
    username: "",
    // let's use both for login and for registration
    errorReason: ""
  },
  mutations: {
    /**
     * Set merchant authentication.
     * @param {Object} state Local State object.
     * @param {Object} merchant Merchant object.
     */
    setMerchantAuth(state, merchant) {
      state.merchant = merchant;
      LocalStorageService.setAuth(merchant);
    },
    /**
     * Remove merchant authentication.
     * @param {Object} state Local State object.
     **/
    removeMerchantAuth(state) {
      state.merchant = {};
      LocalStorageService.deleteAuth();
      LocalStorageService.deleteIsStripeConnected();
    },
    /**
     * Set username
     * @param {Object} state Local State object.
     * @param {string} usernmae Username.
     */
    setUsername(state, username) {
      state.username = username;
      LocalStorageService.setUsername(username);
    },
    /**
     * Remove merchant username.
     * @param {Object} state Local State object.
     */
    removeUsername(state) {
      state.username = "";
      LocalStorageService.deleteUsername();
    },
    setAccountInfo(state, data) {
      state.accountInfo = data;
    },

    /**
     * Set errorReason: Wrong credentials.
     * @param {Object} state Local State object.
     **/
    setErrorWrongCreds(state) {
      state.errorReason = BAD_CREDS_ERROR;
    },
    /**
     * Set errorReason: Wrong Registeration Input.
     * @param {Object} state Local State object.
     **/
    setErrorWrongRegistrationInput(state) {
      state.errorReason = BAD_CREDS_ERROR;
    },
    /**
     * Set errorReason: General Unknown
     * @param {Object} state Local State object.
     **/
    setErrorGeneral(state) {
      state.errorReason = UNKNOWN_ERROR;
    },
    /**
     * Set errorReason: Custom reason.
     * @param {Object} state Local State object.
     **/
    setErrorReason(state, errorReason) {
      state.errorReason = errorReason;
    },
    /**
     * Clear errorReason.
     * @param {Object} state Local State object.
     **/
    clearErrorReason(state) {
      state.errorReason = "";
    }
  },

  getters: {
    /**
     * Getter current username.
     * @return {string} Username.
     **/
    username: state => {
      return state.username;
    },
    /**
     * Getter current merchantId.
     * @return {string} merchantId.
     **/
    merchantId: state => {
      return state.merchant.merchantID;
    },
    accountInfo: state => {
      return state.accountInfo;
    },
  },

  actions: {
    /**
     * Set the error to Wrong Credentionals.
     */
    setErrorWrongCreds({ commit }) {
      commit("setErrorWrongCreds");
    },
    /**
     * Set the error to General.
     */
    setErrorGeneral({ commit }) {
      commit("setErrorGeneral");
    },
    clearErrorReason({ commit }) {
      commit("clearErrorReason");
    },
    initializeMerchant({ commit }) {
      const merchant = LocalStorageService.getAuth();
      const merchantUsername = LocalStorageService.getUsername();
      if (merchant && merchantUsername) {
        commit("setMerchantAuth", merchant);
        commit("setUsername", merchantUsername);
      }
    },
    destructMerchant({ commit }) {
      commit("removeMerchantAuth");
      commit("removeUsername");
    },
    /**
     * Making ajax calls to backend for authentication
     * @param {Object} data Object for the login.
     */
    login({ commit }, data) {
      const { username, password, onSuccess, onFail } = data;
      sendLoginApi({
        "username": username,
        "password": password
      }).then(response => {
        commit("setMerchantAuth", response.data);
        commit("setUsername", username);
        onSuccess();
      })
        .catch(error => {
          if (error.response.status == HttpStatus.BAD_REQUEST) {
            commit("setErrorWrongCreds");
          } else if (error.response.status == HttpStatus.UNAUTHORIZED) {
            commit("setErrorReason", UNAUTHORIZED_ERROR);
          } else {
            commit("setErrorGeneral");
          }
          onFail(error.response.status);
        });
    },
    // commit to remove merchant
    logout({ commit }) {
      commit("removeMerchantAuth");
      commit("removeUsername");
      // Remove the orderHistoryColumns from localstorage
      LocalStorageService.deleteOrderHistoryColumns();
    },
    /**
     * Register the user.
     * @param {Object} data Data object: {user: User Object, onSuccess:Call back for success, onFail:Call back for fail}
     */
    registration({ commit }, data) {
      const { user, onSuccess, onFail } = data;
      sendRegistrationApi(serialize(user))
        .then(response => {
          console.log(response);
          onSuccess();
        })
        .catch(error => {
          if (error.response && error.response.status === HttpStatus.BAD_REQUEST) {
            const errorMessage = error.response.data.violations[0].message;
            commit("setErrorReason", errorMessage);
          } else {
            commit("setErrorGeneral");
          };
          onFail();
        });
    },
    /**
     * Send reset Password request.
     * @param {Object} data Data object: {email:Email of the user, onSuccess:Call back for success, onFail:Call back for fail}
     */
    forgetPassword({ commit }, data) {
      const { email, onSuccess, onFail } = data;
      sendPasswordResetRequestApi(email)
        .then(response => {
          // console.log(response);
          onSuccess();
        })
        .catch(error => {
          commit("setErrorGeneral");
          onFail(error);
        });
    },
    /**
     * Send new Password request.
     * @param {Object} data Data object: {token:Token, toSend:Data to send, onSuccess:Call back for success, onFail:Call back for fail}
     */
    resetPassword({ commit }, data) {
      const { token, toSend, onSuccess, onFail } = data;
      sendSetupNewPasswordApi(token, toSend)
        .then(response => {
          // console.log(response);
          onSuccess();
        })
        .catch(error => {
          commit("setErrorGeneral");
          onFail(error);
        });
    },

    async loadAccountInfo({ commit }) {
      const response = await getAccountInfoApi();
      if (isResponseOK(response)) {
        commit("setAccountInfo", response.data);
      }
      return response.data;
    },
  }
};

