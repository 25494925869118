var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.data, function (group, index) {
      return _c("BaseBadge", {
        key: group.value,
        attrs: {
          cyPrefix: `${_vm.cyPrefix}-badge-product-group-${index}`,
          data: _vm.getNameByIdFromList(group, _vm.selectableProductGroups),
          variant: "primary",
        },
        on: {
          onDelete: function ($event) {
            return _vm.onDelete(index)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }