// Image Buttons blocks for the list Component: Rewards
// Author : Huen Oh (heons921@gmail.com)
// @param {object} config Config object details about components.

<template>
  <div class="align-self-center pl-1 pr-4 cls-list-right-reward">
    <b-row class="align-self-center" align-h="end">
      <!-- Points -->
      <b-col>
        <b-row class="align-self-center pr-4 mt-4" v-if="config.points">
          <h2 class="cls-text-right">{{config.points.requiredPoints}} pt</h2>
        </b-row>
      </b-col> 
      <!-- Toggle button--> 
      <b-col cols="3">
        <b-row class="align-self-center pr-2 mt-4"> 
          <a href="javascript:void(0);" :id="`${config.name}-toggle-btn-status`">
            <toggle-button-status
              :name="config.name"
              v-if="config.toggleBtn"
              :status="config.toggleBtn.status"
              :disabled="config.toggleBtn.disabled"
              @on-toggle-change="config.toggleBtn.change"
            />
          </a>
          <!-- Tooltip for disabled status toggle button -->
          <b-tooltip 
            v-if="config.toggleBtn.disabled" 
            :target="`${config.name}-toggle-btn-status`" 
            placement="bottom" 
            :title="$t('promotions.status-disabled-tooltip')"/>
        </b-row>
      </b-col> 
      <!-- Image buttons -->
      <b-col cols="4">
        <b-row class="align-self-center pr-2 mt-4"> 
          <!-- Image button: Duplicate Reward -->
          <ImageButtonDuplicate
            class="ml-2"
            v-if="config.imgBtnDuplicate"
            :id="config.id"
            :name="config.name"
            @click="config.imgBtnDuplicate.click"
          />
          <!-- Image button: Edit Reward -->
          <ImageButtonEdit
            v-if="config.imgBtnEdit"
            :id="config.id"
            :name="config.name"
            @click="config.imgBtnEdit.click"
          />
          <!-- Image button: Delete Reward -->
          <ImageButtonDelete
            v-if="config.imgBtnDelete"
            :id="config.id"
            :name="config.name"
            @click="config.imgBtnDelete.click"
          />
        </b-row>
      </b-col>
    </b-row>
    
    <!-- Expire Date -->
    <b-row class="align-self-center m-0 p-0" align-h="end">
      <b-col></b-col>
      <b-col cols="7" class="p-0">
        <p class="text-muted text-small text-right">
          {{$t('general.expires') + (config.expireDate ? formatDate(config.expireDate) : 'N/A')}}
        </p>
      </b-col> 
    </b-row>
  </div>    
</template>


<script>
import ImageButtonEdit        from '@/components/Forms/Buttons/ImageButtonEdit'
import ImageButtonDelete      from '@/components/Forms/Buttons/ImageButtonDelete'
import ImageButtonDuplicate   from '@/components/Forms/Buttons/ImageButtonDuplicate'
import ToggleButtonStatus     from '@/components/Forms/ToggleButtonStatus'
import { formatDate }         from "@/utils/general.js"

export default {
  components:{
    ImageButtonEdit,
    ImageButtonDelete,
    ImageButtonDuplicate,
    ToggleButtonStatus,
  },
  props:{
    // {object} Config object details about components
    config:{
      type:Object,
      default:() => {},
    },
  },
  methods:{
    formatDate,
  }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>