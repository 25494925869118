// TopMenu Component
// Author : Huen Oh (heons921@gmail.com)
// @param {object} config  Config object details about components.
// @param {string} title   Title of the page.


<template>
  <b-row>
    <b-colxx xxs="12">
      <!-- Title -->
      <h1>{{ title }}</h1>
      <piaf-breadcrumb/>
      <div class="mb-2 mt-2">
        <b-button 
          variant="empty" 
          class="pt-0 pl-0 d-inline-block d-md-none" 
          v-b-toggle.displayOptions
        >
          {{ $t('layouts.display-options') }} <i class="simple-icon-arrow-down align-middle" />
        </b-button>
        <!-- Top selection menu -->
        <b-collapse id="displayOptions" class="d-md-flex">
          <span class="mr-3 mb-2 d-inline-block float-md-left" v-if="config.btnAdd">
            <ButtonAddItem
              @click="config.btnAdd.click"
            />
          </span>
          <span class="mr-3 mb-2 d-inline-block float-md-left" v-if="config.btnEdit">
            <ButtonEditItem  
              @click="config.btnEdit.click"
            />
          </span>
          <span class="mr-3 mb-2 d-inline-block float-md-left" v-if="config.btnDelete">
            <ButtonDeleteItem
              @click="config.btnDelete.click"
            />
          </span>
          <div class="mr-3 mb-2 d-block d-md-inline-block ">
            <SearchBar
              v-if="config.search" 
              @change="config.search.change"
            />
            <SearchOnEnter
              v-if="config.searchOnEnter"
              :defaultValue="config.filters.searchText"
              @onEnter="config.searchOnEnter.onEnter"
            />
            <DropdownFilterStore
              v-if="config.filterStore"
              :label="config.filters.store.label"
              @click="config.filterStore.click"
            />
            <ServeOptionFilter 
              v-if="config.serveOption"
              :serveOptions="config.filters.serveOptions"
              @change="config.serveOption.change"
            />
            <DropdownFilterOrderStatus 
              v-if="config.orderStatus"
              :selectedOrderStatus="config.filters.orderStatus"
              @click="config.orderStatus.click"
            />
            <SelectDateRange
              v-if="config.dateRange"
              :duration="config.filters.duration"
              @change="config.dateRange.change"
            />
            <PaginationPageSizeFilter 
              v-if="config.pageSize"
              :pageSize="config.filters.pageSize"
              @change="config.pageSize.change"
            />
            <DropdownFilterColumns
              v-if="config.filterColumns"
              :defaultSelected="config.filterColumns.defaultSelected"
              :selectedColumns="config.filters.fields"
              @change="config.filterColumns.change"
            />
            <DropdownSortOrderBy
              v-if="config.sortOrderBy"
              :label="config.filters.order.label"
              :orderOptions="config.sortOrderBy.orderOptions"
              @click="config.sortOrderBy.click"
            />
            <DropdownFilterAddonGroup
              v-if="config.filterAddonGroup"
              :label="config.filters.group.label"
              @click="config.filterAddonGroup.click"
            />
            <DropdownFilterPromotionTypes
              v-if="config.filterPromotionTypes"
              :label="config.filters.type.label"
              :options="config.filterPromotionTypes.options"
              @click="config.filterPromotionTypes.click"
            />
            <DropdownFilterProductGroup
              v-if="config.filterProductGroup"
              :label="config.filters.group.label"
              @click="config.filterProductGroup.click"
            />
            <DropdownFilterProductCategory
              v-if="config.filterProductCategory"
              :label="config.filters.category.label"
              @click="config.filterProductCategory.click"
            />
            <TopMenuCheckbox
              v-if="config.TopMenuCheckbox"
              :checked="config.TopMenuCheckbox.checked"
              :indeterminate="config.TopMenuCheckbox.indeterminate"
              @change="config.TopMenuCheckbox.change"
            />            
          </div>

          <div class="mb-2 d-inline-block float-md-left    ">
            <b-button 
              data-cy="drag-drop-cancel-product-groups-btn"
              variant="outline-secondary"
              class="mr-2"
              size="xs" 
              v-if="config.draggableList"
              @click="config.draggableList.btnEditCancelDragging.click"
            >{{config.draggableList.isDragging ? $t('buttons.cancel') : $t('buttons.edit')}}</b-button>

            <b-button 
              data-cy="drag-drop-save-product-groups-btn"
              variant="outline-secondary"
              size="xs" 
              v-if="config.draggableList && config.draggableList.isDragging"
              @click="config.draggableList.btnSaveDragging.click"
            >{{$t('setup.save')}}</b-button>
          </div>


          <span class="mb-2 ml-auto top-menu-clear-filters-button" v-if="config.clearAllFilters">
            <ButtonClearFilters
              @click="config.clearAllFilters.click"
            />
          </span>
        </b-collapse>

        <p class="text-muted" v-if="config.draggableList">
          <strong>{{config.draggableList.message}}</strong>
        </p>

        <span></span>

      </div>
      <div class="separator mb-5"/>
    </b-colxx>
  </b-row>
</template>    

<script>
import ButtonAddItem      from '@/components/Forms/ButtonAddItem'
import ButtonEditItem     from '@/components/Forms/ButtonEditItem'
import ButtonDeleteItem   from '@/components/Forms/ButtonDeleteItem'
import SearchBar          from '@/components/Forms/SearchBar'
import TopMenuCheckbox    from '@/components/Forms/TopMenuCheckbox'
import SelectDateRange    from '@/components/Forms/SelectDateRange'
import SearchOnEnter      from '@/components/Forms/SearchOnEnter'
import ButtonClearFilters from '@/components/Forms/ButtonClearFilters'
import PaginationPageSizeFilter       from '@/components/Forms/PaginationPageSizeFilter'
import ServeOptionFilter              from '@/components/Forms/ServeOptionFilter'
import DropdownFilterOrderStatus      from '@/components/Forms/DropdownFilterOrderStatus'
import DropdownFilterColumns          from '@/components/Forms/DropdownFilterColumns'
import DropdownSortOrderBy            from '@/components/Forms/DropdownSortOrderBy'
import DropdownFilterStore            from '@/components/Forms/DropdownFilterStore'
import DropdownFilterAddonGroup       from '@/components/Forms/DropdownFilterAddonGroup'
import DropdownFilterPromotionTypes   from '@/components/Forms/DropdownFilterPromotionTypes'
import DropdownFilterProductGroup     from '@/components/Forms/DropdownFilterProductGroup'
import DropdownFilterProductCategory  from '@/components/Forms/DropdownFilterProductCategory'


export default {
  components:{
    ButtonAddItem,
    ButtonEditItem,
    ButtonDeleteItem,
    ButtonClearFilters,
    SearchBar,
    SearchOnEnter,
    SelectDateRange,
    TopMenuCheckbox,
    DropdownSortOrderBy,
    DropdownFilterStore,
    DropdownFilterProductGroup,
    DropdownFilterPromotionTypes,
    DropdownFilterAddonGroup,
    DropdownFilterProductCategory,
    ServeOptionFilter,
    DropdownFilterOrderStatus,
    PaginationPageSizeFilter,
    DropdownFilterColumns,
  },
  props:{
    // {object} Config object details about components
    config:{
      type:Object,
      default:() => {},
    },
    // {string} Title of the page
    title:{
      type:String,
      default:""
    }
  }
}
</script>