var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "position-relative", on: { click: _vm.onClick } },
    [
      _c("i", {
        staticClass: "d-none d-sm-inline-block",
        class: _vm.data.icon,
      }),
      _vm._v(" "),
      !_vm.isNullOrUndefined(_vm.data) &&
      !_vm.isNullOrUndefined(_vm.data.badgeNumber) &&
      _vm.data.badgeNumber > 0
        ? _c(
            "b-badge",
            {
              staticClass: "position-absolute badge-top-right circle-badge",
              attrs: { variant: "primary" },
            },
            [_vm._v(_vm._s(_vm.data.badgeNumber) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }