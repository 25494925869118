/**
 * It contains backend CRUD Apis for ProductCategories.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// Modules in the application.
import axios                        from "@/api/axios/axios_core_deal.js";
import { serializeProductCategory } from "@/models/products/product_category.js";

// URLs for backend
const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;

/**
 * Get URL for ProductCategories.
 * @param {string} [id] ID of a ProductCategory object.
 * @return {string} URL of backend for ProductCategories.
 */
function getUrl(id) {
  return `${HOST}/product-categories/${id ? id : ""}`;
}

/**
 * Get ProductCategories.
 * @return {Promise} Result of GET for ProductCategories.
 */
export async function getProductCategoriesApi() {
  return await axios.get(getUrl());
}

/**
 * Get a ProductCategory by Id.
 * @param {string} id ID of a ProductCategory object.
 * @return {Promise} Result of GET for a ProductCategory by Id.
 */
export async function getOneProductCategoryApi(id) {
  return await axios.get(getUrl(id));
}

/**
 * Add a ProductCategory.
 * @param {Object} data ProductCategory object: Refer to PRODUCT_CATEGORY_MODEL.
 * @return {Promise} Result of POST for a ProductCategory.
 */
export async function addProductCategoryApi(data) {
  return await axios.post(getUrl(), serializeProductCategory(data));
}

/**
 * Update a ProductCategory.
 * @param {Object} data ProductCategory object: Refer to PRODUCT_CATEGORY_MODEL.
 * @return {Promise} Result of PUT for a ProductCategory.
 */
export async function updateProductCategoryApi(data) {
  return await axios.put(getUrl(data.id), serializeProductCategory(data));
}

/**
 * Delete a ProductCategory by Id.
 * @param {string} id ID of a ProductCategory object.
 * @return {Promise} Result of DELETE for deleting a ProductCategory.
 */
export async function deleteProductCategoryApi(id) {
  return await axios.delete(getUrl(id));
}

