/**
 * 
 * @param {Object[]} registration 
 * @return {String} firstName
 * @return {String} lastname
 * @return {String} username
 * @return {String} email
 * @return {String} password
 * @return {String} matchingpassword
 */
export function serialize(registration) {
  return {
    firstName: registration.firstname,
    lastName: registration.lastname,
    username: registration.username,
    email: registration.email,
    password: registration.password,
    matchingPassword: registration.matchingPassword,
  };
}
