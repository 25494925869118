// Toogle Button for General checking.
// Author : Huen Oh (heons921@gmail.com)
// @param {string}  name      Name of the component. Index in usual.
// @param {bool}    value     Value for the toggle button. v-model.
// @param {bool}    disabled  Disabled property of the toogle button.

<template>
  <toggle-button
    :data-cy="`${name}-toggle-btn-check`"
    color="#87bcbf"
    :labels="{checked: 'v', unchecked: 'x'}"
    :value="value"
    :sync="true"
    :width="40"  
    :disabled="disabled"
    @change="onChangeSwitch"
  />
</template>


<script>
import { ToggleButton } from 'vue-js-toggle-button'

export default {
  props:{
    // {string} Name of the component. Index in usual.
    name: {
      type: String,
      default: ""
    },
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    value: {  // {bool} Value for the toggle button. v-model.
      type: Boolean,
      default: false
    }
  },
  components: { ToggleButton },
  data(){
    return {
      // switchValue: false, // {bool} Value for the toggle button.
    }    
  },
  created: function () {
    // // Set value for the toggle button.
    // this.switchValue = this.inputValue;
  },
  watch: {
    // status: function() {
    //   // Set value for the toggle button.
    //   this.switchValue = this.inputValue;
    // }
  },
  computed: {
  },
  methods:{
    /**
     * On change switch(Toogle button).
     * @param {Object} event Event Object : {value, tag, srcEvent}
     */
    onChangeSwitch(event) {
      // console.log('onChangeSwitch', this.value);
      // console.log('onChangeSwitch', event.value);
      // Do emit for v-model
      this.$emit('input', event.value)
    }
  },
  mounted(){
  }
}
</script>
