var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.detailed
    ? _c(
        "b-card",
        { staticClass: "p-3 mb-2", attrs: { "no-body": "" } },
        [
          _c(
            "b-card",
            {
              staticClass: "order-promotion-card py-2 px-3 my-2 text-white",
              class: { [_vm.getClassByPromotionType]: true },
              attrs: {
                "data-cy": `list-item-order-promotion-${_vm.name}`,
                "no-body": "",
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-left", attrs: { cols: "8" } },
                    [
                      _c(
                        "h4",
                        { staticClass: "m-0 p-0" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                "data-cy": `list-item-order-promotion-${_vm.name}-link`,
                                to: `?p=${_vm.data.id}`,
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text-white router-link",
                                  on: { click: _vm.onTitleClick },
                                },
                                [_vm._v(_vm._s(_vm.data.title))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { cols: "4" } },
                    [
                      !_vm.data.isRedeem
                        ? _c(
                            "h4",
                            {
                              staticClass: "m-0 p-0",
                              attrs: {
                                "data-cy": `list-item-order-promotion-${_vm.name}-reward`,
                              },
                            },
                            [_vm._v(_vm._s(_vm.reward))]
                          )
                        : _c(
                            "h4",
                            {
                              staticClass: "m-0 p-0",
                              attrs: {
                                "data-cy": `list-item-order-promotion-${_vm.name}-reward`,
                              },
                            },
                            [_vm._v(_vm._s(_vm.usedPointsOrPunches))]
                          ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mx-1 mt-3 p-0" },
            [
              _c("b-col", [
                _c("h6", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("promotions.applied-products"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                {
                  staticClass: "text-right h6",
                  attrs: {
                    "data-cy": `list-item-order-promotion-${_vm.name}-products`,
                  },
                },
                [
                  _vm.data.appliedProductList &&
                  _vm.data.appliedProductList.length > 0
                    ? _vm._l(_vm.data.appliedProductList, function (product) {
                        return _c(
                          "b-row",
                          { key: product.id },
                          [
                            _c("b-col", { staticClass: "text-right" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "router-link",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onAppliedProductNameClick(
                                        product.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(product.name) + ":")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "pl-0", attrs: { cols: "2" } },
                              [
                                _vm._v(
                                  _vm._s(product.quantity) +
                                    " " +
                                    _vm._s(_vm.$t("general.qty"))
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      })
                    : [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("promotions.entire-cart")) +
                            "\n        "
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "m-1" }),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mx-1 mt-3 p-0" },
            [
              _c("b-col", [
                _c("h6", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("promotions.discount-applied"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "text-right h6" },
                [
                  _vm.data.totalDiscountAmount >= 0
                    ? [
                        _c(
                          "b-row",
                          {
                            attrs: {
                              "data-cy": `list-item-order-promotion-${_vm.name}-discount-amount`,
                            },
                          },
                          [
                            _c("b-col", { staticClass: "text-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("promotions.total-discount-amount")
                                ) + ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "pl-0", attrs: { cols: "2" } },
                              [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.formatPrice(
                                        _vm.data.totalDiscountAmount
                                      )
                                    )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.data.isAddonCharged === false &&
                        _vm.data.isAddonsPresentToDiscount === true
                          ? _c(
                              "b-row",
                              {
                                attrs: {
                                  "data-cy": `list-item-order-promotion-${_vm.name}-addon-discount-amount`,
                                },
                              },
                              [
                                _c("b-col", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("promotions.addon-discount-amount")
                                    ) + ":"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  { staticClass: "pl-0", attrs: { cols: "2" } },
                                  [
                                    _vm._v(
                                      "$" +
                                        _vm._s(
                                          _vm.formatPrice(
                                            _vm.data.addonDiscountAmount
                                              ? _vm.data.addonDiscountAmount
                                              : 0
                                          )
                                        )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm.data.isAddonCharged === false &&
                            _vm.data.isAddonsPresentToDiscount === false
                          ? _c(
                              "b-row",
                              [
                                _c("b-col", { staticClass: "text-right" }, [
                                  _vm._v(
                                    "\n            (" +
                                      _vm._s(
                                        _vm.$t("promotions.no-addons-present")
                                      ) +
                                      ")\n          "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _c(
                              "b-row",
                              [
                                _c("b-col", { staticClass: "text-right" }, [
                                  _vm._v(
                                    "\n            (" +
                                      _vm._s(
                                        _vm.$t("promotions.addon-charged")
                                      ) +
                                      ")\n          "
                                  ),
                                ]),
                              ],
                              1
                            ),
                      ]
                    : _vm.data.isDiscountNotApplicable
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("general.not-applicable-full")) +
                            "\n      "
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "m-1" }),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mx-1 mt-3" },
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c("h6", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("promotions.perk-conditions"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "text-right", attrs: { cols: "8" } },
                [
                  Object.entries(_vm.data.conditions).length > 0
                    ? _vm._l(
                        _vm.data.conditions,
                        function (value, conditionName, index) {
                          return _c(
                            "b-badge",
                            {
                              key: index,
                              staticClass: "ml-1 mb-1",
                              attrs: { variant: "primary" },
                            },
                            [
                              _c(
                                "h6",
                                {
                                  staticClass: "m-0 p-0",
                                  attrs: {
                                    "data-cy": `list-item-order-promotion-${_vm.name}-condition-${index}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          `promotions.conditions.${_vm.getLocalizationVariableName(
                                            conditionName,
                                            value
                                          )}`,
                                          [value]
                                        )
                                      ) +
                                      " \n          "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }
                      )
                    : [
                        _c(
                          "b-badge",
                          {
                            staticClass: "ml-1 mb-1",
                            attrs: { variant: "primary" },
                          },
                          [
                            _c("h6", { staticClass: "m-0 p-0" }, [
                              _vm._v(_vm._s(_vm.$t("general.none"))),
                            ]),
                          ]
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "b-card",
        {
          staticClass: "order-promotion-card py-2 px-3 my-2 text-white",
          class: { [_vm.getClassByPromotionType]: true },
          attrs: {
            "data-cy": `list-item-order-promotion-${_vm.name}`,
            "no-body": "",
          },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-left", attrs: { cols: "8" } }, [
                _c(
                  "h4",
                  { staticClass: "m-0 p-0" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          "data-cy": `list-item-order-promotion-${_vm.name}-link`,
                          to: `?p=${_vm.data.id}`,
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "text-white router-link",
                            on: { click: _vm.onTitleClick },
                          },
                          [_vm._v(_vm._s(_vm.data.title))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("b-col", { staticClass: "text-right", attrs: { cols: "4" } }, [
                !_vm.data.isRedeem
                  ? _c(
                      "h4",
                      {
                        staticClass: "m-0 p-0",
                        attrs: {
                          "data-cy": `list-item-order-promotion-${_vm.name}-reward`,
                        },
                      },
                      [_vm._v(_vm._s(_vm.reward))]
                    )
                  : _c(
                      "h4",
                      {
                        staticClass: "m-0 p-0",
                        attrs: {
                          "data-cy": `list-item-order-promotion-${_vm.name}-reward`,
                        },
                      },
                      [_vm._v(_vm._s(_vm.usedPointsOrPunches))]
                    ),
              ]),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }