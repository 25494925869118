<template>
  <div class="order-history">
    <top-menu
      title="Order History"
      :config="{
        searchOnEnter: { onEnter: onSearchEnter },
        filterStore: { click: onStoreFilter },
        dateRange: { change: onDateFilter },
        serveOption: { change: onServeOptionsChange },
        orderStatus: { click: onOrderStatusClick },
        pageSize: { change: onPageSizeChange }, 
        filterColumns: { change: onFilterColumns, defaultSelected: defaultSelectedColumns },
        clearAllFilters: { click: onClearFiltersClick },
        filters: { 
          store: selectedStore,
          serveOptions: serveOptions.length > 0 ? serveOptions : undefined,
          duration: selectedReportDuration.start && selectedReportDuration.end ? selectedReportDuration : defaultReportDuration,
          pageSize: pageSize, 
          searchText: searchText,
          fields: currentSelectedFieldsValues,
          orderStatus: orderStatus,
        },
      }"
    />
   
    <b-card class="w-100" body-class="py-0 px-0">
      <b-table 
        :items="orders"
        :fields="fields"
        class="text-center"
        thead-class="primary-color-table-head"
        :data-cy="`table-order-history`"
        primary-key="orderDetail"
        hover
        responsive
        no-border-collapse
        :sort-by.sync="sortBySync"
        :sort-desc.sync="sortDescSync"
      >
        <!-- Order Number  -->
        <template #cell(id)="data">
          <router-link
            :to="{ name: 'order-detail', params: { id: data.value }}"
            :data-cy="`table-order-history-number-details-${data.index}-btn-show`"
            class="order-number-link"
          >
            {{orderNumber(data.value)}}
          </router-link>
        </template>

        <!-- Store Name  -->
        <template #cell(storeId)="data">
          {{storeName(data.value)}}
        </template>

        <!-- Customer Name  -->
        <template #cell(customerName)="data">
          {{customerName(data.value)}}
        </template>

        <!-- Customer Phone  -->
        <template #cell(customerPhone)="data">
          <template v-if="data.value && data.value !== null">
            {{formatPhoneNumber(data.value)}}
          </template>
          <template v-else>
            {{$t('general.not-applicable')}}
          </template>
        </template>

        <!-- Sub amount  -->
        <template #cell(subAmount)="data">
          <template v-if="data.value !== null && data.value !== ''">
            {{formatAmount(data.value)}}
          </template>
          <template v-else>
            {{$t('general.not-applicable')}}
          </template>
        </template>

        <!-- Discount amount  -->
        <template #cell(discountAmount)="data">
          <template v-if="data.value !== null && data.value !== ''">
            {{formatAmount(data.value)}}
          </template>
          <template v-else>
            {{$t('general.not-applicable')}}
          </template>
        </template>

        <!-- Tax amount  -->
        <template #cell(appliedTaxes)="data">
          <template v-if="data.value !== null && data.value !== ''">
            {{taxAmount(data.value)}}
          </template>
          <template v-else>
            {{$t('general.not-applicable')}}
          </template>
        </template>

        <!-- Total amount  -->
        <template #cell(totalAmount)="data">
          <template v-if="data.value !== null && data.value !== ''">
            {{formatAmount(data.value)}}
          </template>
          <template v-else>
            {{$t('general.not-applicable')}}
          </template>
        </template>

        <!-- Global points used  -->
        <template #cell(globalPointsUsed)="data">
          <template v-if="data.value && data.value > 0">
            {{`${data.value} ${$t('order.reward-points-suffix')}`}}
          </template>
          <template v-else>
            {{$t('general.not-applicable')}}
          </template>
        </template>

        <!-- Serve Type: Pickup, Dinein, Delivery  -->
        <template #cell(isPickup)="data">
          {{serveType(data.value)}}
        </template>

        <!-- Preparing Time  -->
        <template #cell(preparingTime)="data">
          <template v-if="data.value && data.value !== null">
            {{formatTime(data.value)}}
          </template>
          <template v-else>
            {{$t('general.not-applicable')}}
          </template>
        </template>

        <!-- Delivery Time  -->
        <template #cell(deliveringTime)="data">
          <template v-if="isDeliveryOrder(data.item)">
            {{formatTime(data.value)}}
          </template>
          <template v-else>
            {{$t('general.not-applicable')}}
          </template>
        </template>

        <!-- Delivery Address  -->
        <template #cell(deliveryAddress)="data">
          <template v-if="isDeliveryOrder(data.item)">
            {{formatAddress(data.value)}}
          </template>
          <template v-else>
            {{$t('general.not-applicable')}}
          </template>
        </template>

        <!-- Delivery Distance  -->
        <template #cell(deliveryDistance)="data">
          <template v-if="isDeliveryOrder(data.item)">
            {{formatDistance(data.value)}}
          </template>
          <template v-else>
            {{$t('general.not-applicable')}}
          </template>
        </template>

        <!-- Delivery Price  -->
        <template #cell(deliveryPrice)="data">
          <template v-if="isDeliveryOrder(data.item)">
            {{formatAmount(data.value)}}
          </template>
          <template v-else>
            {{$t('general.not-applicable')}}
          </template>
        </template>

        <!-- Order Status  -->
        <template #cell(status)="data">
          <b-badge :variant="orderStatusVariant(data.value)">{{data.value}}</b-badge>
        </template>
        
        <!-- Serve Status - Duplicated field with isPickUp Ticket: BP-2691. -->
        <!-- <template #cell(serveStatus)="data">
          <b-badge variant="info">{{data.value}}</b-badge>
        </template> -->

        <!-- Order completed at  -->
        <template #cell(lastUpdatedAt)="data">
          <template v-if="data.value !== null">
          {{formatDateWithTime(data.value)}}
          </template>
          <template v-else>
            {{$t('general.not-applicable')}}
          </template>
        </template>

        <!-- Image button: Show Order detail  -->
        <template #cell(orderDetails)="data">
          <router-link
            :to="{ name: 'order-detail', params: { id: data.item.id }}"
            :data-cy="`table-order-history-details-${data.index}-btn-show`"
          >
            <b-img
              class="cls-btn-icon"
              src="/assets/img/eye.png"
              id="btn_edit">
            </b-img>
          </router-link>
        </template>
      </b-table>
      <b-pagination
        v-model="pageNumberVModel"
        :total-rows="pagination.totalOrders"
        :per-page="pageSize.value"
        align="center"
        v-on:input="onPaginationClick"
        :data-cy="`table-order-history-pagination`"
        last-class="pagination-buttons-size"
        first-class="pagination-buttons-size"
        next-class="pagination-buttons-size"
        prev-class="pagination-buttons-size"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>

import TopMenu from '@/components/Forms/TopMenu.vue';

import { mapActions, mapGetters, mapMutations } from 'vuex';
import { parseDateRange, formatTime } from "@/utils/datetime";
import { formatNumber, formatDateWithTime, formatPhoneNumber, formatAddress, formatDistance, isNullOrUndefined } from '@/utils/general';
import { ENUM_ORDER_STATUS_STRING, ENUM_ORDER_TYPE_STRING } from "@/models/order";

import '@/assets/css/sass/style_custom.scss';

export default {
  components: {
    "top-menu": TopMenu,
  },
  methods: {
    // Map actions.
    ...mapActions("order", ["loadArchivedOrders", "initializeOrderHistoryFilters"]),
    ...mapMutations("order", [
      "setSelectedStore", 
      "setSelectedReportDuration",
      "setServeOptions",
      "setPageNumber",
      "setPageSize",
      "setSearchText",
      "setFields",
      "setOrderStatus",
      "setSortBy",
      "setSortDesc",
      "clearAllFilters"]),

    formatDateWithTime,
    formatPhoneNumber,
    formatTime,
    formatAddress,
    formatDistance,

    async loadOrders() {
      
      await this.loadArchivedOrders({
        serveOptions: this.parseServeOptions(this.serveOptions),
        storeId: this.selectedStore.value,
        orderStatus: this.orderStatus.value,
        startDate: this.formattedDateRange.startDate,
        endDate: this.formattedDateRange.endDate,
        pageNumber: this.pageNumber-1,
        pageSize: this.pageSize.value,
        searchText: this.searchText,
        responseFields: this.mapFieldsForRequest(this.fields.map(field => field.key)),
      });
    },
    /**
     * serveOptions: Array
     * Example: ["a","b"] => "a,b"
     * return convert array to value separated with commas.
     */
    parseServeOptions(serveOptions) {
      return serveOptions
        .filter(option => option.active)
        .map(option => option.value)
        .toString();
    },

    /*
    * Update Orders on filtering by Store.
    */
    onStoreFilter(payload) {
      this.setSelectedStore(payload);
      this.loadOrders();
    },
    
    /*
    * Update Orders on filtering by date range.
    */
    onDateFilter(dateRange) {
      this.setSelectedReportDuration(dateRange.duration);
      this.loadOrders();
    },
    
    onPaginationClick(page) {
      this.setPageNumber(page);
      this.loadOrders();
    },

    onPageSizeChange(pageSize) {
      this.setPageSize(pageSize);
      this.loadOrders();
    },

    onServeOptionsChange(serveOptionsArray) {
      this.setServeOptions(serveOptionsArray);
      this.loadOrders();
    },

    onSearchEnter(searchText) {
      this.setSearchText(searchText);
      this.loadOrders();
    },

    onFilterColumns(fields) {
      const mappedValues = fields.map(field => ({key: field, ...this.mapDropdownValuesToTableColumns(field)}))
      this.setFields(mappedValues);
      this.loadOrders();
    },

    onClearFiltersClick() {
      this.clearAllFilters();
      this.setFields(this.defaultFields);
      this.loadOrders();
    },

    onOrderStatusClick(orderStatus){
      this.setOrderStatus(orderStatus);
      this.loadOrders();
    },

    /* Methods for table cells */

    orderNumber(orderId) {
      return orderId.substring(orderId.length - 4)
    },

    storeName(storeId) {
      const store = this.findStoreById(storeId);
      return store ? store.name : "";
    },

    taxAmount(appliedTaxes) {
      if (isNullOrUndefined(appliedTaxes)) return '';

      // Tax exempt case
      if (appliedTaxes.length === 0) return this.$t('general.tax-exempt');

      return this.formatAmount(appliedTaxes.map(tax => tax.taxAmount).reduce((total, current) => total + current));
    },

    /**
     * Makes sure to Capitalize first letter.
     */
    customerName(name) {
      return name.split(" ")
        .map(name => name.charAt(0).toUpperCase() + name.substring(1))
        .join(" ");
    },

    formatAmount(amount) {
      return `$${formatNumber(amount, true)}`;
    },    

    orderStatusVariant(status) {
      if (status === ENUM_ORDER_STATUS_STRING.COMPLETED) {
        return "success";
      } else if (status === ENUM_ORDER_STATUS_STRING.REFUNDED) {
        return "danger";
      } else {
        return null;
      }
    },

    serveType(isPickupValue) {
      if (isPickupValue === true) {
        return this.$t('order.serve-status.pick-up');
      } else if (isPickupValue === false) {
        return this.$t('order.serve-status.dine-in');
        } else {
        return this.$t('order.serve-status.delivery');
      }
    },

    /**
     * Used in v-if for delivery values (delivery time, price, address, distance).
     */
    isDeliveryOrder(order) {
      // isPickup value: not true and not false.
      return (!order.isPickup && order.isPickup !== false) && (order.deliveryAddress!=null)
    },

    /**
     * List of fields(columns)
     */
    mapFieldsForRequest(fields) {
      return fields.map(field => {
          switch (field) {
            case "customerName": 
              return "customerId";
            case "customerPhone":
              return "customerId";
            case "lastUpdatedAt":
              return "updatedAt";
            default:
              return field;
          }
        }).filter(field => field !== null)
        .toString();
    },
    mapDropdownValuesToTableColumns(key) {
      const values = {
        id: {label: this.$t('order.fields.id') },
        customerName: {label: this.$t('order.fields.customer-name'), sortable: true},
        storeId: {label: this.$t('order.fields.store-id')},
        customerPhone: {label: this.$t('order.fields.customer-phone'), sortable: true},
        subAmount: {label: this.$t('order.fields.sub-amount'), sortable: true},
        discountAmount: {label: this.$t('order.fields.discount-amount'), sortable: true},
        appliedTaxes: {label: this.$t('order.fields.tax-amount'), sortable: true},
        totalAmount: {label: this.$t('order.fields.total-amount'), sortable: true},
        globalPointsUsed: {label: this.$t('order.fields.global-points-used'), sortable: true},
        isPickup: {label: this.$t('order.fields.is-pickup')},
        preparingTime: {label: this.$t('order.fields.preparing-time'), sortable: true},
        deliveringTime: {label: this.$t('order.fields.delivering-time'), sortable: true},
        deliveryAddress: {label: this.$t('order.fields.delivery-address')},
        deliveryDistance: {label: this.$t('order.fields.delivery-distance'), sortable: true},
        deliveryPrice: {label: this.$t('order.fields.delivery-price'), sortable: true},
        status: {label: this.$t('order.fields.status')},
        // serveStatus: {label: this.$t('order.fields.serve-status')}, // Duplicated field with isPickUp Ticket: BP-2691.
        lastUpdatedAt: {label: this.$t('order.fields.last-updated-at'), sortable: true, sortByFormatted: value => (new Date(value))},
        orderDetails: {label: this.$t('order.fields.order-details')}
      }
      return values[key];
    },
  },
  computed: {
    // Map getters.
    ...mapGetters("order", [
      "orders", 
      "pagination", 
      "selectedStore",
      "selectedReportDuration",
      "serveOptions",
      "pageNumber",
      "pageSize",
      "searchText",
      "fields",
      "sortBy",
      "sortDesc",
      "orderStatus"]),
    ...mapGetters("merchant_store", ["selectableStores", "findStoreById"]),

    /**
     * For DropdownFilterColumns in TopMenu
     */
    defaultSelectedColumns() {
      return ["id", "customerName", "customerPhone", "storeId", "subAmount", "discountAmount", "appliedTaxes", "totalAmount", "orderDetails"];
    },
    defaultFields() {
      return this.defaultSelectedColumns.map(key => ({key: key, ...this.mapDropdownValuesToTableColumns(key)}));
    },
    currentSelectedFieldsValues() {
      return this.fields.map(field => field.key);
    },
    pageNumberVModel: {
      set(value) {
        this.setPageNumber(value);
      },
      get() {
        return this.pageNumber;
      }
    },
    sortBySync: {
      get() {
        return this.sortBy
      },
      set(value) {
        this.setSortBy(value)
      }
    },
    sortDescSync: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.setSortDesc(value);
      }
    },
    /**
     * Get default date range
     * Current month - previous month.
     */
    defaultReportDuration() {
      const currentDate = new Date();

      return ({
        start: new Date(
          currentDate.getFullYear(),
          currentDate.getUTCMonth() - 1,
          currentDate.getUTCDate()
        ),
        end: new Date(
          currentDate.getFullYear(),
          currentDate.getUTCMonth(),
          currentDate.getUTCDate()
        )
      })
    },

    /**
     * Format the date range as 'YYYY-MM-DDTHH:mm:ssZ'.
     * Also, the start date must be at the beginning of the day and end date must be at the end time of the day.
     * Refer to: utils/datetime#parseDateRange() function.
     */
    formattedDateRange() {
      if (isNullOrUndefined(this.selectedReportDuration.start) || isNullOrUndefined(this.selectedReportDuration.end)) {
        // If date range is not selected by user yet, then use default date range.
        return parseDateRange(this.defaultReportDuration.start, this.defaultReportDuration.end);
      } else {
        return parseDateRange(this.selectedReportDuration.start, this.selectedReportDuration.end);
      }

    }
  },
  mounted() {
    if (this.fields && this.fields.length === 0) {
      this.initializeOrderHistoryFilters(this.defaultFields);
    }
    // load Order history only 1st page.
    this.loadOrders();
  },
};
</script>
