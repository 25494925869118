/**
 * It contains models of Order objects.
 */

/**
 * Enum value of the type of Order as string.
 */
export const ENUM_ORDER_TYPE_STRING = {
  NORMAL: "NORMAL",
  REDEEM: "REDEEM",
}


/**
 * Enum value of the type of Order as string.
 */
export const ENUM_ORDER_ITEM_TYPE_STRING = {
  REDEEM: "REDEEM",
  FREE_ITEM: "FREE_ITEM"
}

/**
 * Enum value of the status of Order as string.
 */
export const ENUM_ORDER_STATUS_STRING = {
  CHECKOUT:   "CHECKOUT",
  CONFIRMED:  "CONFIRMED",
  PAID:       "PAID",
  COMPLETED:  "COMPLETED",
  CANCELED:   "CANCELED",
  REFUNDED:   "REFUNDED"
}

/**
 * Enum value of the serve status of Order as String.
 */
export const ENUM_SERVE_OPTIONS_STRING = {
  PICKUP: "PICKUP",
  DINE_IN: "DINE_IN",
  DELIVERY: "DELIVERY",
}

/**
 * Enum value of the serving status of Order as string.
 */
export const ENUM_ORDER_SERVING_STATUS_STRING = {
  ACCEPTED:       'ACCEPTED',
  REJECTED:       'REJECTED',
  PREPARING:      'PREPARING',
  READY:          'READY',
  DELIVERING:     'DELIVERING',
  DELIVERED:      'DELIVERED',
  SERVED:         'SERVED',
  PICKUP:         'PICKUP',
};

/**
 * Enum value of the serving status of Order as string.
 */
export const MAP_ORDER_STATUS_TO_API = {
  PAID_AT_STORE:  'paid-at-store', //?
  CANCELED:       'cancel',     // ORDER_STATUS
  REJECTED:       'reject',
  ACCEPTED:       'accept',
  PREPARING:      'preparing',
  READY:          'ready',
  DELIVERING:     'delivering',
  DELIVERED:      'delivered',
  SERVED:         'served',
  PICKUP:         'pickup',
  COMPLETED:      'complete',   // ORDER_STATUS
};

/**
 * Order Item Model.
 */
export const MODEL_ORDER_ITEM = {
  productId: "",	      // {string}   Product Id. required.
  productName: "",      // {string}   Product Name. required.
  instruction: "",      // {[string]} Instruction.
  price: "", 	          // {number}   Price of the product. required.
  unit:"",              // {[string]} Unit of the product.
  quantity: 1,          // {number}   Quantity of the product. required(min: 1).
  totalAmount: "", 	    // {number}   Total price. rquired.
  addon: "",	          // {boolean}  Is addon.

  totalAddonAmount: "", // {number}   Total price of Addons.
  addons: [             // {Object[]} List of Addons.
    {
      productId: "",	  // {string}   Product Id. required.
      productName: "",  // {string}   Product Name. required.
      instruction: "",  // {[string]} Instruction.
      price: "", 	      // {number}   Price of the product. required.
      unit: "",         // {[string]} Unit of the product.
      quantity: 1,      // {number}   Quantity of the product. required(min: 1).
      totalAmount: "", 	// {number}   Total price. rquired.
      addon: "",        // {boolean}  Is addon.
    }
  ], 
}

/**
 * Promotion Detail Model - It is used in Orders.
 * Refer to MODEL_PROMOTION_DETAIL in PromotionDetail
 */

/**
 * Order Model - To read from server.
 */
export const MODEL_ORDER_READ = {
  id: "",                 // {string}     Id of the Order.
  qrCode: "",             // {string}     QR code of the order.
  merchantId: "",         // {string}     Merchant Id.
  storeId: "",            // {string}     Store Id.
  staffId: "",            // {[string]}   Staff Id.
  customerId: "",         // {string}     Customer Id.
  preparingTime: null,    // {number}     Preparing Time as Mins.
  deliveringTime: null,   // {number}     Delivering Time as Mins.
  items: null,            // {Object[]}   Items in order Objects. required, Refer to MODEL_ORDER_ITEM.
  promotions: null,       // {Object[]}   Promotion Detail Objects. required, Refer to MODEL_PROMOTION_DETAIL.
  subAmount: null,        // {number}     Sub amount. required.
  discountAmount: null,   // {number}     Discount amount. required.
  hst: null,              // {number}     HST amount. required.
  taxAmount: null,        // {number}     TAX amount. required.
  totalAmount: null,      // {number}     Total amount. required.
  globalPointsUsed: null, // {[number]}   Global point being used in the Order.
  points: null,           // {[number]}   Reward Points.
  isPaidOnline: true,     // {boolean}    Is paid online.
  chargedId: "",          // {string}     Charged Id.
  paidAt: "",             // {[Date]}     Paied date/time.
  orderedAt: "",          // {[Date]}     Ordered date/time.
  lastUpdatedAt: "",      // {[Date]}     Last updated date/time.
  isPickup: true,         // {[boolean]}  Serving type. (true:PICKUP, false:DINEIN, null:DELIVERY)
  serveStatus: "",        // {string}     Served status of the Order. Refer to ENUM_ORDER_SERVING_STATUS_STRING.
  status: "",             // {string}     Status of the Order. Refer to ENUM_ORDER_STATUS_STRING.
  messageByMerchant: "",  // {string}    Messagae set by the Merchant.

  // Delivery Information.
  deliveryAddress: null,  // {[Object]} Delivery Address. Refer to MODEL_ADDRESS.
  deliveryDistance: null, // {[number]} Delivery Distance.
  deliveryPrice: null,    // {[number]} Delivery Price.

  customerName: null,     // {[String]} Customer's full name.
  customerPhone: null,    // {[String]} Customer's phone number.
};


/**
 * Parse Order objects.
 * @param {Object[]} orders Order objects: Refer to MODEL_ORDER_READ.
 * @return {Object} Prased Order object.
 */
export function parseOrders(orders) {
  return orders;
  // TODO
  // return parse(order, MODEL_ORDER_READ);
}

/**
 * Parse a Order object.
 * @param {Object} order Order object: Refer to MODEL_ORDER_READ.
 * @return {Object} Prased Order object.
 */
export function parseOrder(order) {
  return order;
  // TODO
  // return parse(order, MODEL_ORDER_READ);
}

/**
 * Serialize a Order object.
 * @param {Object} order Order object: Refer to MODEL_ORDER_READ
 * @return {Object} Serialized Order object.
 */
export function serializeOrder(order) {
  return order;
  // TODO
  // return serialize(order, MODEL_ORDER_READ);
}
