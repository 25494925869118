/**
 * @fileoverview It contains base settings of axios in the application.
 * name: axios_base.js
 * 1. Parse response using json-bigint.
 *  - Most of the IDs are BigInteger in the Backend.
 *  - The JavaScript parses BigInteger to number, which is in double precision, 
 *    and it alters the value of BigInteger.
 *  - Therefore, it needs to be parsed IDs(BigInteger) to string and the library is for this purpose.
 * 2. Refresh token when the request get UNAUTHORIZED response.
 *  - It only has the call back functions of success and fail on intercept the response.
 *  - interceptResponseSuccess, interceptResponseFail
 *  - Those call backs need to be set with 'interceptors.response.use' after creating the axios object.
 * @author Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// 3rd party libraries.
import Vue          from "vue";
import i18n     from '@/i18n';
import axios        from "axios";
import HttpStatus   from 'http-status-codes';

// Modules in the application.
import router               from "../../router";
import LocalStorageService  from "@/utils/local_store.js";

// Api
import { sendLoginApi } from "../auth.js";


// TODO : Test it.
// import { mapActions } from "vuex";

const JSONbig = require("json-bigint")({ "storeAsString": true });

const LOGIN_URL = `${BE_URLS.BACKEND_URL}/login`;

const axios_base = axios;


// To reset all persisted data.
// const resetAll = mapActions(["resetAll"]).resetAll;

/**
 * It catches response and transfroms.
 * If the data is empty, it just return.
 * Or it parse the data with JSONbig for id in BigInteger.
 */
axios_base.defaults.transformResponse = [function (data) {
  // console.log('transformResponse');
  try {
    return JSONbig.parse(data);
  } catch {
    // console.log('error-catched');
    return data;
  }
}];

/**
 * Intercept function for the response of axios when it's successful.
 * @param {Object} response Response object from axios.
 * @return {Object} Response object from axios.
 */
const interceptResponseSuccess = function (response) {
  // console.log('response-intercept', response);
  return response;
}

/**
 * Intercept function for the response of axios when it's failed.
 * @param {Object} error        Error object from axios.
 * @param {Object} customAxios  Custom axios object to be called when refreshing token is successful.
 * @return {Promise} Promise object.
 */
const interceptResponseFail = function (error, customAxios) {
  // console.log('axios_base - error', error);
  // console.log('axios_base - error.response', error.response);

  // Pass all non 401s back to the caller.
  if (!!error.response && error.response.status !== HttpStatus.UNAUTHORIZED) {
    // console.log('error', error)
    // console.log('error.response', error.response)
    // TODO : Deal with some of common error response or Skip some of them. e.g. FORBIDDEN.
    if(error.response.status === HttpStatus.SERVICE_UNAVAILABLE){
      Vue.$notify("error", i18n.tc('notify.title-fail'), i18n.tc('notify.service-unavailable'), { duration: 3000, permanent: true });
    }else if (error.response.status !== HttpStatus.FORBIDDEN) {
      // Check if response with violations.
      if (error.response.data && error.response.data.violations) {
        if (Array.isArray(error.response.data.violations) && error.response.data.violations.length > 0) {
          error.response.data.violations.forEach(violation => {
            Vue.$notify("error", i18n.tc('notify.title-fail'), violation.message, { duration: 3000, permanent: true });
          })
        }
      }
      // Else if contains main error message.
      else if (error.response.data && error.response.data.message) {
        Vue.$notify("error", i18n.tc('notify.title-fail'), error.response.data.message, { duration: 3000, permanent: true });
      } 
      // Else show generic message.
      else {
        Vue.$notify("error", i18n.tc('notify.title-fail'), i18n.tc('notify.server-internal-error'), { duration: 3000, permanent: true });
      }
    } // else
    return Promise.reject(error);
  }

  const originalRequest = error.config;
  // console.log('axios_base - error.config', error.config);
  if (originalRequest) {
    // console.log('axios_base - inside-if');
    if (!!originalRequest._retry || originalRequest.url === LOGIN_URL) {
      // console.log("axios_base - re-direct to login");
      // TODO : compare with 'name'.
      if (router.history.current.path != "/user/login") {
        // Reset all persisted data.
        window.sessionStorage.clear();
        // resetAll();
        router.push({ name: 'login' });
      }
      // console.log('return')
      return Promise.reject(error);
    }

    // console.log('axios_base - customAxios', customAxios);
    originalRequest._retry = true;
    const refreshToken = LocalStorageService.getRefreshToken();
    return sendLoginApi({ "refresh_token": refreshToken })
      .then(res => {
        // console.log("axios_base - refresh - success");
        LocalStorageService.setAuth(res.data);
        return customAxios(originalRequest);
      })
      .catch(error => {
        // console.log("axios_base - refresh - fail");
        return customAxios(originalRequest);
      });
  }
}

export { axios_base, interceptResponseSuccess, interceptResponseFail };
