// Compare functions for 'sort'
// Author : Huen Oh (heons921@gmail.com)

/**
 * Base Compare function using a property name of an object.
 * It compares two object and return -1, 1, or 0 accordinlgy.
 * This function should be wrapped for the callback function of prototype.sort()
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @param {string}  propName    Property name to comapre.
 * @param {bool}    isAscending Order of sort ture:Ascending, false:Descending.
 * @return {number} -1, 1, or 0
 */
function sortByProp(a, b, propName, isAscending = true) {
  if (!propName) {
    return 0; // No sort
  } else { }
  
  if (!a[propName] || !b[propName]) {
    return 0; // No sort
  } else { }

  const itemA = a[propName];
  const itemB = b[propName];

  if(a[propName] instanceof String && b[propName] instanceof String){
    itemA = a[propName].toLowerCase();
    itemB = b[propName].toLowerCase();
  }

  if (itemA < itemB) {
    return isAscending ? -1 : 1;
  }
  if (itemA > itemB) {
    return isAscending ? 1 : -1;
  }
  
  return 0;
};

function sortByPropAndCreatedAt(a, b, propName, isAscending = true){
  if (!propName) {
    return 0; // No sort
  } else { }

  if (!a[propName] || !b[propName]) {
    return 0; // No sort
  } else { }

  const itemA = a[propName];
  const itemB = b[propName];

  if (a[propName] instanceof String && b[propName] instanceof String) {
    itemA = a[propName].toLowerCase();
    itemB = b[propName].toLowerCase();
  }

  if (itemA < itemB) {
    return isAscending ? -1 : 1;
  }
  if (itemA > itemB) {
    return isAscending ? 1 : -1;
  }

  // If itemA == itemB, then sort by createdAt descending
  return sortByProp(a, b, 'createdTime', false);
}

// By Name
/**
 * Compare function using 'name' property of an object.
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByName = function (a, b) {
  return sortByProp(a, b, 'name', true);
};
/**
 * Compare function using 'name' property of an object in ascending order.
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByNameAscending = function (a, b) {
  return sortByProp(a, b, 'name', true);
};
/**
 * Compare function using 'name' property of an object in descending order.
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByNameDecending = function (a, b) {
  return sortByProp(a, b, 'name', false);
};

// By Status
/**
 * Compare function using 'status' property of an object.
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByStatus = function (a, b) {
  return sortByProp(a, b, 'status', true);
};
/**
 * Compare function using 'status' property of an object in ascending order.
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByStatusAscending = function (a, b) {
  return sortByProp(a, b, 'status', true);
};
/**
 * Compare function using 'status' property of an object in descending order.
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByStatusDecending = function (a, b) {
  return sortByProp(a, b, 'status', false);
};


// By day
/**
 * Compare function using 'day' property of an object.
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByDay = function (a, b) {
  return sortByProp(a, b, 'day', true);
};

/**
 * Compare function using 'day' property of an object in descending order.
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByDayAscending = function (a, b) {
  return sortByProp(a, b, 'day', true);
};

/**
 * Compare function using 'day' property of an object in descending order.
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByDayDecending = function (a, b) {
  return sortByProp(a, b, 'day', false);
};


// By lastUpdatedAt
/**
 * Compare function using 'lastUpdatedAt' property of an object.
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByLastUpdatedAt = function (a, b) {
  return sortByProp(a, b, 'lastUpdatedAt', false);
};
/**
 * Compare function using 'lastUpdatedAt' property of an object in ascending order.
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByLastUpdatedAtAscending = function (a, b) {
  return sortByProp(a, b, 'lastUpdatedAt', true);
};
/**
 * Compare function using 'lastUpdatedAt' property of an object in descending order.
 * @param {Object}  a           Object to compare for the sort.
 * @param {Object}  b           Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByLastUpdatedAtDecending = function (a, b) {
  return sortByProp(a, b, 'lastUpdatedAt', false);
};

/**
 * Compare function using 'rank' property of an object in ascending order.
 * This function currently is only used by Product Group
 * @param {*} a 
 * @param {*} b 
 * @returns 
 */
export let sortByRankAtAscending = function (a, b){
  return sortByPropAndCreatedAt(a, b, "rank", true);
}

/**
 * Compare function using 'rank' property of an object in decending order.
 * This function currently is only used by Product Group
 * @param {*} a
 * @param {*} b
 * @returns
 */
export let sortByRankAtDecending = function (a, b) {
  return sortByPropAndCreatedAt(a, b, "rank", false);
}

// TODO : remove
// By orderedAt
/**
 * Compare function using 'orderedAt' property of an object.
 * @param {Object} a Object to compare for the sort.
 * @param {Object} b Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByOrderedAt = function (a, b) {
  return sortByProp(a, b, 'orderedAt', false);
};
/**
 * Compare function using 'orderedAt' property of an object in descending order.
 * @param {Object} a Object to compare for the sort.
 * @param {Object} b Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByOrderedAtAscending = function (a, b) {
  return sortByProp(a, b, 'orderedAt', true);
};
/**
 * Compare function using 'orderedAt' property of an object in descending order.
 * @param {Object} a Object to compare for the sort.
 * @param {Object} b Object to compare for the sort.
 * @return {number} -1, 1, or 0
 */
export let sortByOrderedAtDecending = function (a, b) {
  return sortByProp(a, b, 'orderedAt', false);
};

/**
 * Sort the given array of objects with order.
 * NOTE: Default is sortByNameAscending. If there is no matching props, it doesn't sort.
 * @param {Objet[]} listData  List of object to sort.
 * @param {Object}  order     Order object fot sorting: {label, isAscending}
 */
export let sortInOrder = function (listData, order) {
  // listData.
  if ("Name" == order.label) {
    if (order.isAscending === true) {
      listData.sort(sortByNameAscending);
    } else {
      listData.sort(sortByNameDecending);
    }
  } else if ("Status" == order.label) {
    if (order.isAscending === true) {
      listData.sort(sortByStatusAscending);
    } else {
      listData.sort(sortByStatusDecending);
    }
  } else if ("Date" == order.label) {
    if (order.isAscending === true) {
      listData.sort(sortByLastUpdatedAtAscending);
    } else {
      listData.sort(sortByLastUpdatedAtDecending);
    }
  } else if ("Rank" == order.label) {
    if (order.isAscending === true) {
      listData.sort(sortByRankAtAscending);
    } else {
      listData.sort(sortByRankAtDecending);
    }
  } else { 
    listData.sort(sortByName);
  }
}


