// Search bar component, works when enter key is pressed

<template>
  <div class="d-inline-block mr-4 mb-1 align-top">
    <div class="search-sm d-inline-block">
      <b-input 
        data-cy="top-input-search"
        :placeholder="$t('menu.search')" 
        v-model="search"
        @keydown.enter.native="searchOnEnter"
      />
    </div>
    <b-button size="xs" variant="outlined" @click="searchOnEnter">Search</b-button>
  </div> 
</template>


<script>

export default {
  props: {
    defaultValue: String,
  },
  data() {
    return {
      search:'' // {string} Search keyword.
    }
  },
  methods:{
    searchOnEnter: function(phrase) {
      this.$emit('onEnter', this.search);
    },
  },
  mounted: function () {
    if (this.defaultValue) {
      this.search = this.defaultValue;
    }
  },
  watch: {
    defaultValue: function (newVal, oldVal) {
      this.search = newVal;
    }
  }
}
</script>
