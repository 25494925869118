<template>
  <b-button 
    variant="outline-secondary" 
    size="xs" 
    @click="onClick"
  >
  Clear All
  </b-button>
</template>

<script>
export default {
  methods:{
    /**
     * On click the button.
     */
    onClick() {
      this.$emit('click');
    }
  }
} 
</script>