var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { "theme-colors": true, shown: _vm.isOpen },
      on: {
        mouseenter: function ($event) {
          _vm.isMenuOver = true
        },
        mouseleave: function ($event) {
          _vm.isMenuOver = false
        },
      },
    },
    [
      _c("div", { staticClass: "p-4" }, [
        _c("p", { staticClass: "text-muted mb-2" }, [
          _vm._v(_vm._s(_vm.$t("layouts.theme.light"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex flex-row justify-content-between mb-4" },
          _vm._l(
            _vm.colors.filter((x) => x.indexOf("light") > -1),
            function (color, index) {
              return _c("a", {
                key: `light_${index}`,
                class: _vm.getIconClass(color),
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.changeThemeColor(color)
                  },
                },
              })
            }
          ),
          0
        ),
        _vm._v(" "),
        _c("p", { staticClass: "text-muted mb-2" }, [
          _vm._v(_vm._s(_vm.$t("layouts.theme.dark"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex flex-row justify-content-between" },
          _vm._l(
            _vm.colors.filter((x) => x.indexOf("dark") > -1),
            function (color, index) {
              return _c("a", {
                key: `dark_${index}`,
                class: _vm.getIconClass(color),
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.changeThemeColor(color)
                  },
                },
              })
            }
          ),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pb-0 pl-4 pt-4" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Border Radius" } },
            [
              _c(
                "b-form-radio-group",
                {
                  attrs: { name: "radius" },
                  model: {
                    value: _vm.radius,
                    callback: function ($$v) {
                      _vm.radius = $$v
                    },
                    expression: "radius",
                  },
                },
                [
                  _c(
                    "b-form-radio",
                    {
                      attrs: { value: "rounded" },
                      on: {
                        change: function ($event) {
                          return _vm.changeRadius("rounded")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("layouts.border-radius.rounded")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      attrs: { value: "flat" },
                      on: {
                        change: function ($event) {
                          return _vm.changeRadius("flat")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("layouts.border-radius.flat")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "theme-button",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggle.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "simple-icon-magic-wand" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }