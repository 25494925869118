/**
 * It contains model of AddonGroup object.
 */

 // General parse and serialize function
import { parse, serialize } from "../general.js";

/**
 * AddonGroup Model - for Parse and Serialize use.
 */
const ADDON_GROUP_MODEL = {
  id: "id",
  name: "name",
  description: "description",
  images: "images",
  merchantId: "merchantId",
  isAddon: "isAddon",
  products: "products",
  isSameAddonAllowed: "isSameAddonAllowed", // {boolean} To check if adding same addon multiple times is allowed.
  forcedMin: "forcedMin",
  forcedMax: "forcedMax",
  lastUpdatedAt: "lastUpdatedAt",
};

/**
 * Empty default AddonGroup.
 */
export const EMPTY_ADDON_GROUP = {
  name: "",
  description: "",
  images: null,
  // merchantId: "",
  // isAddon: true,
  products: [],

  isSameAddonAllowed: true,
  forcedMin: 0,
  forcedMax: 0,
  lastUpdatedAt: "",
};

/**
 * Prase AddonGroup objects.
 * @param {Object} addonGroups AddonGroup objects: Refer to ADDON_GROUP_MODEL and EMPTY_ADDON_GROUP.
 * @return {Object[]} Prased AddonGroup objects.
 */
export function parseAddonGroups(addonGroups) {
  return addonGroups;
  // TODO
  // return parse(addonGroup, PRODUCT_GROUP_MODEL);
}

/**
 * Prase an AddonGroup object.
 * @param {Object} addonGroup AddonGroup object: Refer to ADDON_GROUP_MODEL and EMPTY_ADDON_GROUP.
 * @return {Object} Prased AddonGroup object.
 */
export function parseAddonGroup(addonGroup) {
  return addonGroup;
  // TODO
  // return parse(addonGroup, PRODUCT_GROUP_MODEL);
}

/**
 * Serialize an AddonGroup object.
 * @param {Object} addonGroup AddonGroup object: Refer to ADDON_GROUP_MODEL and EMPTY_ADDON_GROUP.
 * @return {Object} Serialized AddonGroup object.
 */
export function serializeAddonGroup(addonGroup) {
  return addonGroup;
  // TODO
  // return serialize(addonGroup, PRODUCT_GROUP_MODEL);
}