import LocalStorageService from "@/utils/local_store.js";

// Api
import { sendLoginApi } from "@/api/auth.js";

// Constant.
import { WEBSOCKET_URL } from "@/constants/config.js";

import Vue from 'vue'

/**
 * It establishes a websocket connection
 * @param {*} successCallback 
 * @param {*} errorCallback 
 */
export function connectWebsocket(successCallback, errorCallback) {
  // hasRetry = true represents that it will reconnect if connection fail
  let hasRetry = true;
  Vue.$connectWS(WEBSOCKET_URL,
    successCallback,
    // Failure callback 
    (err) => {
      console.log('error connecting websocket: '  + JSON.stringify(err));

      // If socket hasn't tried to reconnect, then reconnect
      if (hasRetry){
        hasRetry = false;

        reconnect(successCallback, errorCallback);

        if (errorCallback) {
          errorCallback();
        }
      }
    });
}

/**
 * reconnect websocket if first attempt is fail
 * @param {*} successCallback 
 * @param {*} errorCallback 
 */
function reconnect(successCallback, errorCallback) {
  const refreshToken = LocalStorageService.getRefreshToken();
  sendLoginApi({ "refresh_token": refreshToken })
    .then(res => {
      LocalStorageService.setAuth(res.data);

      // connect again with retry "false"
      Vue.$connectWS(WEBSOCKET_URL, successCallback, errorCallback);
    })
    .catch(error => {
      console.log("The socket connection is not authenticated");
    });
}

/**
 * Disconnect websocket
 */
export function disconnectWebsocket() {
  Vue.$disconnectWS(() => {
    console.log('Websocket connection is closed');
  });
}

/**
 * The websocket client subscribe for particular channel
 * @param {*} channel : the channel that client want to subscribe
 * @param {*} handler : Message handler for the subscribed channel
 */
export function subscribeWebsocket(channel, handler) {
  return Vue.$stompClient.subscribe(channel, handler);
}
