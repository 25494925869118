/**
 * It contains store module for Products.
 */

import HttpStatus from 'http-status-codes';

import {
  getProductsApi,
  getOneProductApi,
  addProductApi,
  addProductWithImagesApi,
  deleteProductApi,
  updateProductApi,
  updateProductWithImagesApi,
} from "@/api/product/products.js";

import { parseProduct, parseProductList }           from "@/models/products/product.js";
import { sortInOrder }            from "@/utils/sort.js"
import { getListForDropDownMenu, isResponseOK, isResponseCREATED, isNullOrUndefined } from "@/utils/general.js"

import { ENUM_STATUS_STRING } from "@/constants/common";


export default {
  namespaced: true,
  state: {
    products: [],             // {Object[]} List of Products: Refer to PRODUCT_MODEL.
    filteredProducts: [],     // {Object[]} List of filtered Products: Refer to PRODUCT_MODEL.
    selectableProducts: [],   // {Object[]} List of selectable Products: {value:id, label:name, text:name}.
    currentProductIndex: -1,  // {number}   Index of current Product.
    filters: {                // {Object}   Filters.
      store: { value: "", label: "" },
      category: { value: "", label: "" },
      group: { value: "", label: "" },
      order: { value: "", label: "", isAscending: true, },
      keyword: "",
    },
    deletedProducts: [],     // {Object[]} List of deleted Products.
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.products = [];
      state.filteredProducts = [];
      state.selectableProducts = [];
      state.currentProductIndex = -1;
      state.filters = {
        store: { value: "", label: "" },
        category: { value: "", label: "" },
        group: { value: "", label: "" },
        order: { value: "", label: "", isAscending: true, },
        keyword: "",
      };
      state.deletedProducts = [];
    },
    /**
     * Set the index of current Product.
     * @param {Object}  state Local State object.
     * @param {number}  index Index of current Product.
     */
    setCurrentProductIndex(state, index) {
      state.setCurrentProductIndex = index
    },
    /**
     * Set all Products in the state.
     * @param {Object} state    Local State object.
     * @param {Object} products List of Product objects: Refer to PRODUCT_MODEL.
     */
    setAllProducts(state, products) {
      state.products = products;
    },
    /**
     * Delete a Product in the state.
     * @param {Object} state Local State object.
     * @param {number} index Index of a Product in the list.
     */
    deleteOneProduct(state, index) {
      // Add deleted product to the deleted list
      const deletedProduct = state.products[index];

      /*
      if (!isNullOrUndefined(deletedProduct)) {
        state.deletedProducts = [...state.deletedProducts, deletedProduct];
      }*/

      // Remove the deleted product from current products list
      state.products.splice(index, 1);
    },
    /**
     * Add a Product in the state.
     * @param {Object} state    Local State object.
     * @param {Object} product  Product object: Refer to PRODUCT_MODEL.
     */
    addOneProduct(state, product) {
      state.products.push(product);
    },
    /**
     * Add a Product into the list of deleted Product in the state.
     * @param {Object} state Local State object.
     * @param {Object} product Product object: Refer to PRODUCT_MODEL.
     */
    addOneDeletedProduct(state, product) {
      state.deletedProducts = [...state.deletedProducts, product];
    },
    /**
     * Upate a Product in the state.
     * @param {Object} state    Local State object.
     * @param {Object} product  Product object: Refer to PRODUCT_MODEL.
     * @param {number} index    Index of a Product in the list.
     */
    updateOneProduct(state, { product, index }) {
      state.products[index] = {
        ...state.products[index],
        ...product,
        "deletedGroups": []
      };
    },
    /**
     * Set selectable Product in the state.
     * @param {Object} state Local State object.
     */
    setSelectableProducts(state) {
      state.selectableProducts = getListForDropDownMenu(state.products);
    },
    /**
     * Add a filtered Product in the state.
     * @param {Object} state   Local State object.
     * @param {Object} product Product object to be added.
     */
    addOneFilteredProduct(state, product) {
      state.filteredProducts.push(product);
    },
    /**
     * Delete a filtered Product in the state.
     * @param {Object} state  Local State object.
     * @param {number} index  Index of a filtered Product in the list.
     */
    deleteOneFilteredProduct(state, index) {
      state.filteredProducts.splice(index, 1);
    },
    /**
     * Set filters in the state.
     * @param {Object} state    Local State object.
     * @param {Object} filters  Filter object.
     */
    setProductFilters(state, filters) {
      state.filters = {
        ...state.filters,
        ...filters,
      }
    },
    /**
     * Apply Product filters.
     * @param {Object} state Local State object.
     */
    applyProductFilters(state) {
      // console.log("applyProductFilters");
      // console.log(this.filters);
      // Apply filters
      // 1. not deleted
      state.filteredProducts = state.products.filter(element => element.status != ENUM_STATUS_STRING.DELETED);
      // 2. store
      if (state.filters.store.value != "") {
        state.filteredProducts = state.filteredProducts.filter(element => element.stores.includes(state.filters.store.value));
      }
      // 3. Category
      if (state.filters.category.value != "") {
        state.filteredProducts = state.filteredProducts.filter(element => element.categories.includes(state.filters.category.value));
      }
      // 4. Group
      if (state.filters.group.value != "") {
        state.filteredProducts = state.filteredProducts.filter(element => element.groups.includes(state.filters.group.value));
      }
      // 5. Name in search
      if (state.filters.keyword != "") {
        state.filteredProducts = state.filteredProducts.filter(
          element => element.name.toLowerCase().includes(state.filters.keyword.toLowerCase())
        );
      }
      // 6. order
      sortInOrder(state.filteredProducts, state.filters.order);
    },
  },
  getters: {
    /**
     * Get isProductSet
     * @return {bool} True if products are set, False otherwise.
     */
    isProductSet: (state) => {
      return state.products.length > 0;
    },
    /**
     * Get Product in the state.
     */
    products: (state) => {
      return state.products;
    },
    /**
     * Get all deleted products in the state
     * @param {*} state 
     * @returns 
     */
    deletedProducts: (state) => {
      return state.deletedProducts;
    },
    /**
     * Get selectable Product in the state.
     */
    selectableProducts: (state) => {
      return state.selectableProducts;
    },
    /**
     * Get filtered Product in the state.
     */
    filteredProducts: (state) => {
      return state.filteredProducts;
    },
    /**
     * Get filters in the state.
     */
    filters: (state) => {
      return state.filters;
    },
    
    /**
     * Get current Product in the state.
     */
    currentProduct: (state) => {
      const index = state.currentProductIndex;
      return state.products[index >= 0 ? index : 0]; // what if there is no products?
    },
    /**
     * Get a Product by Id.
     * @param {string} id Id of a Product.
     */
    findProductById: (state) => (id) => {
      return state.products.find(
        product => product.id === id
      );
    },
    /**
     * Get a deleted product by its Id
     * @param {string} id Id of a Product
     * @returns 
     */
    findDeletedProductById: state => id => {
      const product = state.deletedProducts.find(p => p.id == id);

      return product;
    },
    /**
     * Get an index of a Product by Id.
     * @param {string} id Id of a Product.
     */
    findProductIndexById: (state) => (id) => {
      return state.products.findIndex(
        product => product.id === id
      );
    },
    /**
     * Get Products by ProductGroup Id.
     * @param {string} id Id of a ProductGroup.
     */
    findProductsByGroupId: (state) => (id) => {
      const availableProducts = state.products.filter(
        product => product.groups.includes(id)
      );

      const deletedProducts = state.deletedProducts.filter(
        product => product.groups.includes(id)
      );
      
      return [...availableProducts, ...deletedProducts];
    },

    /**
     * Function to validate if the products in the productList is valid.
     * @param {Object[]} productList Product in List: Refer to MODEL_PRODUCT_IN_LIST.
     * @return {boolean} True if all the Products in list is valid(not DELETED), false otherwise.
     */
    isProductListValid: (state) => (productList) => {
      const productIds = !Array.isArray(productList) ? [] : productList.map(productInList => productInList.id);
      for (const productId of productIds) {
        const product = state.products.find(
          product => product.id === productId
        );
        if (product === undefined) {
          return false;
        } else { }
      }
      return true;
    },

    /**
     * Find products which belongs to given store
     * @param {Object} state local state
     * @param {String} storeId Merchant Store id
     * @returns list of filtered products
     */
    findProductsByStoreId: (state) => (storeId) => {
      return state.products.filter(product => {
        if (!isNullOrUndefined(product.stores)) {
          return product.stores.includes(storeId);
        } else {
          return false;
        }
      })
    },
  },
  actions: {
    /**
     * Load Products.
     * It loads Products from the sever and set selectable Products.
     * @return {Object} Response object of a Promise.
     */
    async loadProducts({ commit }) {
      const response = await getProductsApi();
      if (isResponseOK(response)) {
        const products = parseProductList(response.data);
        commit("setAllProducts", products.filter(el => el.status != ENUM_STATUS_STRING.DELETED));
        // commit("setAllProducts", products);
        commit("setSelectableProducts");
      } else {
        console.log("[Error]loadProducts:");
        // this._vm.$notify("error", "Fail", "Loading product", { duration: 3000, permanent: false });
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Get product detail by product id.
     * It load the detail of specific product by its id.
     * @return {Object} Response object of a Promise.
     */
    async getDeletedProduct({ commit }, data) {
      const { id } = data;

      const response = await getOneProductApi(id);

      if (isResponseOK(response)) {
        let product = parseProduct(response.data);

        commit("addOneDeletedProduct", product);

        return product;
      }

      return null;
    },
    /**
     * Update a Product.
     * It updates a Product and selectable Products accordingly.
     * @param {Object} product Product object: Refer to PRODUCT_MODEL.
     */
    async updateOneProduct({ commit, getters }, product) {
      const response = await updateProductApi(product);
      if (isResponseOK(response)) {
        commit(
          "updateOneProduct",
          {
            product: parseProduct(response.data),
            index: getters.findProductIndexById(product.id)
          }
        );
        commit("setSelectableProducts");
        // this._vm.$notify("primary", "Success", "Product update", { duration: 3000, permanent: false });
      } else {
        console.log("[Error]updateOneProduct:");
        // this._vm.$notify("error", "Fail", "Product update", { duration: 3000, permanent: false });
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Update a Product with images.
     * It updates a Product and selectable Products accordingly.
     * @param {Object}    product         Product object: Refer to PRODUCT_MODEL.
     * @param {Object[]} [imageObjects[]] Image objects: [{files, preview}]
     */
    async updateOneProductWithImages({ commit, getters }, { product, imageObjects }) {
      const response = await updateProductWithImagesApi(product, imageObjects);
      if (isResponseOK(response)) {
        commit(
          "updateOneProduct",
          {
            product: parseProduct(response.data),
            index: getters.findProductIndexById(product.id)
          }
        );
        commit("setSelectableProducts");
        // this._vm.$notify("primary", "Success", "Product update", { duration: 3000, permanent: false });
      } else {
        console.log("[Error]updateOneProductWithImages:");
        // this._vm.$notify("error", "Fail", "Product update", { duration: 3000, permanent: false });
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Delete a Product (Soft).
     * It deletes a Product and selectable Products accordingly.
     * @param {string} id Id of a Product.
     */
    async deleteOneProduct({ commit, getters }, id) {
      const response = await deleteProductApi(id);
      if (isResponseOK(response)) {
        commit("deleteOneProduct", getters.findProductIndexById(id));
        commit("setSelectableProducts");
      } else {
        console.log("[Error]deleteOneProduct:");
        // this._vm.$notify("error", "Fail", "Deleting product", { duration: 3000, permanent: false });
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Add a Product.
     * It adds a Product and updates selectable Products accordingly.
     * @param {Object} product Product object: Refer to PRODUCT_MODEL.
     */
    async addOneProduct({ commit }, product) {
      const response = await addProductApi(product);
      if (isResponseCREATED(response)) {
        commit("addOneProduct", parseProduct(response.data));
        commit("setSelectableProducts");
      } else {
        console.log("[Error]addOneProduct:");
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Add a Product with images.
     * It adds a Product and selectable Products accordingly.
     * @param {Object}    product         Product object: Refer to PRODUCT_MODEL.
     * @param {Object[]} [imageObjects[]] Image objects: [{files, preview}]
     */
    async addOneProductWithImages({ commit }, { product, imageObjects }) {
      const response = await addProductWithImagesApi(product, imageObjects);
      if (isResponseCREATED(response)) {
        commit("addOneProduct", parseProduct(response.data));
        commit("setSelectableProducts");
      } else {
        console.log("[Error]addOneProductWithImages:");
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },

    // Filters
    /**
     * Apply filters.
     */
    applyProductFilters({ commit }) {
      commit("applyProductFilters");
    },
    /**
     * Set and apply filters.
     * @param {Object} filters Filters object.
     */
    setProductFilters({ commit }, filters) {
      commit("setProductFilters", filters);
      commit("applyProductFilters");
    },
    /**
     * Reset and apply filters.
     * @param {Object} [filters] Filters object.
     */
    resetProductFilters({ commit }, filters) {
      const emptyFilters = {
        store: { value: "", label: "" },
        category: { value: "", label: "" },
        group: { value: "", label: "" },
        order: { value: "", label: "" },
        keyword: "",
        ...filters,
      };
      commit("setProductFilters", emptyFilters);
      commit("applyProductFilters");
    },
    /**
     * Add a filtered Product in the state.
     * @param {string} id Id of a Product.
     */
    addOneFilteredProduct({ state, commit }, id) {
      const product = state.products.find(el => el.id == id);
      if (product) {
        commit("addOneFilteredProduct", product);
      } else {}
    },
    /**
     * Delete a filtered Product in the state.
     * @param {string} id Id of a Product.
     */
    deleteOneFilteredProduct({ state, commit }, id) {
      const idx = state.filteredProducts.findIndex(el => el.id == id);
      commit("deleteOneFilteredProduct", idx);
    },


  }
};
