var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-sm d-inline-block mr-1 align-top" },
    [
      _c("b-input", {
        attrs: {
          "data-cy": "top-input-search",
          placeholder: _vm.$t("menu.search"),
        },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }