var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.heading && _vm.heading.length > 0
        ? _c("h1", [_vm._v(_vm._s(_vm.heading))])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-nav",
        {
          staticClass:
            "pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block",
        },
        [_c("b-breadcrumb", { attrs: { items: _vm.items } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }