var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-button",
    {
      attrs: { variant: "outline-secondary", size: "xs" },
      on: { click: _vm.onClick },
    },
    [_vm._v("\nClear All\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }