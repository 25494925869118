/**
 * It contains store module for ProductGroups.
 */

import {
  getProductGroupsApi,
  addProductGroupApi,
  addProductGroupWithImageApi,
  deleteProductGroupApi,
  updateProductGroupApi,
  updateProductGroupWithImageApi,
  addProductsInGroupApi,
  deleteProductsInGroupApi,
  updateProductGroupRankApi
} from "@/api/product/product_groups.js";

import {
  getListForDropDownMenu,
  isAvailableTimeEqual,
  isHideUntilEqual,
  isResponseOK,
  isResponseCREATED,
  isNullOrUndefined
} from '@/utils/general'

import i18n from '@/i18n';

import { parseProductGroup, parseProductGroups }  from "@/models/products/product_group.js";
import { sortInOrder }        from "@/utils/sort.js"
import { ENUM_STATUS_STRING } from "@/constants/common";
import { GROUP_OTHERS }       from "@/constants/common";


export default {
  namespaced: true,
  state: {
    productGroups: [],              // List of ProductGroups: Refer to PRODUCT_GROUP_MODEL.
    selectableProductGroups: [],    // List of selectable ProductGroups: {value:id, label:name, text:name}
    currentProductGroupIndex: -1,   // Index of current ProductGroup
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.productGroups = [];
      state.selectableProductGroups = [];
      state.currentProductGroupIndex = -1;
    },
    /**
     * Set all ProductGroups in the state.
     * @param {Object} state          Local State object.
     * @param {Object} productGroups  List of ProductGroup objects: Refer to PRODUCT_GROUP_MODEL.
     */
    setAllProductGroups(state, productGroups) {
      state.productGroups = productGroups;
    },
    /**
     * Delete a ProductGroup in the state.
     * @param {Object} state Local State object.
     * @param {number} index Index of a ProductGroup in the list.
     */
    deleteOneProductGroup(state, index) {
      state.productGroups.splice(index, 1);
    },
    /**
     * Add a ProductGroup in the state.
     * @param {Object} state  Local State object.
     * @param {Object} group  ProductGroup object: Refer to PRODUCT_GROUP_MODEL.
     */
    addOneProductGroup(state, group) {
      state.productGroups.push(group);
    },
    /**
     * Upate a ProductGroup in the state.
     * @param {Object} state  Local State object.
     * @param {Object} group  ProductGroup object: Refer to PRODUCT_GROUP_MODEL.
     * @param {number} index  Index of a ProductGroup in the list.
     */
    updateOneProductGroup(state, { group, index }) {
      state.productGroups[index] = {
        ...state.productGroups[index],
        ...group
      };
    },
    /**
     * Set selectable ProductGroup in the state.
     * @param {Object} state Local State object.
     */
    setSelectableProductGroups(state) {
      state.selectableProductGroups = getListForDropDownMenu(state.productGroups);
    },
    
    /**
     * Sort the product group by rank ascending and descending by createdTime
     * @param {} param0 
     */
    sortProductGroupsByRank(state) {
      // Sort by rank
      sortInOrder(state.productGroups, { label: "Rank", isAscending: true });
    },
  },
  getters: {
    /**
     * Get isProductGroupsSet
     * @return {bool} True if ProductGroups are set, False otherwise.
     */
    isProductGroupsSet: (state) => {
      return state.productGroups.length > 0;
    },
    /**
     * Get ProductGroup in the state.
     */
    productGroups: (state) => {
      return state.productGroups;
    },
    /**
     * Get selectable ProductGroup in the state.
     */
    selectableProductGroups: (state) => {
      return state.selectableProductGroups;
    },
    /**
     * Get current ProductGroup in the state.
     */
    currentProductGroup: (state) => {
      const index = state.currentProductGroupIndex;
      return state.productGroups[index >= 0 ? index : 0]; // what if there is no productGroups?
    },
    /**
     * Get a ProductGroup by Id.
     * @param {string} id Id of a ProductGroup.
     */
    findProductGroupById: (state) => (id) => {
      return state.productGroups.find(
        group => group.id === id
      );
    },
    /**
     * Get an index of a ProductGroup by Id.
     * @param {string} id Id of a ProductGroup.
     */
    findProductGroupIndexById: (state) => (id) => {
      return state.productGroups.findIndex(
        group => group.id === id
      );
    },
    /**
     * Get ProductGroups by a Product Id.
     * @param {string} id Id of a Product.
     */
    findProductGroupsByProductId: (state) => (id) => {
      return state.productGroups.filter(
        group => group.products.includes(id)
      );
    },
  },
  actions: {
    /**
     * Load ProductGroups.
     * It loads ProductGroups from the sever and set selectable ProductGroups.
     */
    async loadProductGroups({ commit, dispatch }) {
      const response = await getProductGroupsApi();
      if (isResponseOK(response)) {
        const productGroups = parseProductGroups(response.data);

        // Sort the product group by rank
        sortInOrder(productGroups, { label: "Rank", isAscending: true });

        // Set all ProductGroups
        commit("setAllProductGroups", productGroups);
        // Set selectable ProductGroups
        commit("setSelectableProductGroups");
        // Set status of ProductGroups by checking all the status of the Products.
        dispatch("getQueriedStatus");
        // Set schedule of ProductGroups by checking all the schedule of the Products.
        dispatch("getQueriedSchedule");
      } else {
        console.log("[Error]loadProductGroups:");
        // this._vm.$notify("error", "Fail", "Loading group", { duration: 3000, permanent: false });
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Update a ProductGroup.
     * It updates a ProductGroup and selectable ProductGroups accordingly.
     * @param {Object} group ProductGroup object: Refer to PRODUCT_GROUP_MODEL.
     */
    async updateOneProductGroup({ commit, getters, dispatch }, group) {
      // Index of the ProductGroup
      const index = getters.findProductGroupIndexById(group.id);

      // TODO : Seperate it
      // Delete Products from the ProductGroup
      let isDel = false;
      if (group.deleteProductList) {
        if (group.deleteProductList.length > 0) {
          const response_del = await deleteProductsInGroupApi(group.id, group.deleteProductList);
          if (isResponseOK(response_del)) {
            group.products = response_del.data.products;
            isDel = true;
          } else { }
        } else { }
      } else { }
      
      // TODO : Seperate it
      // Add Products from the ProductGroup
      let isAdd = false;
      if (group.addProductList) {
        if (group.addProductList.length > 0) {
          const response_add = await addProductsInGroupApi(group.id, group.addProductList);
          if (isResponseOK(response_add)) {
            group.products = response_add.data.products;
            isAdd = true;
          } else { }
        } else { }
      } else { }

      // Re-load the Products
      if (isDel || isAdd) {
        // All the product of those updated by product group operation should be re-fetched
        // since it is only updated in the server.
        // TODO : load only those updated.
        dispatch('product/loadProducts', null, { root: true });
      }
      
      // Update the ProductGroup
      const response = await updateProductGroupApi(group);
      if (isResponseOK(response)) {
        // Update status of the Products in the ProductGroup
        dispatch("updateQueriedStatus", group);
        // TODO : FIX_GROUP_SCHEDULE_UPDATE 
        // Update schedule of the Products in the ProductGroup
        // dispatch("updateQueriedSchedule", group);

        // Update the ProductGroup
        commit(
          "updateOneProductGroup",
          {
            group: parseProductGroup(response.data),
            index: index
          }
        );
        // Set selectable ProductGroups
        commit("setSelectableProductGroups");
        // Set status or ProductGroups by checking all the status of the Products.
        dispatch("getQueriedStatus");
        // TODO : FIX_GROUP_SCHEDULE_UPDATE 
        // Set schedule of ProductGroups by checking all the schedule of the Products.
        // dispatch("getQueriedSchedule");
      } else {
        console.log("[Error]updateOneProductGroup:");
        // this._vm.$notify("error", "Fail", "ProductGroup update", { duration: 3000, permanent: false });
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },

    /**
     * Update Product Group rank 
     * @param {*} data - including to be updated ProductGroup id and its rank
     */
    async updateProductGroupRank({ commit, dispatch}, data){
      const response = await updateProductGroupRankApi(data);
      if(isResponseOK(response)){
        const updatedProductGroups = parseProductGroup(response.data);
        
        console.log('updated group:', updatedProductGroups);

        // Update the saved product groups to storage
        commit("setAllProductGroups", updatedProductGroups);

        // Set status or ProductGroups by checking all the status of the Products.
        dispatch("getQueriedStatus");
        // Set schedule of ProductGroups by checking all the schedule of the Products.
        dispatch("getQueriedSchedule");

        // Sort the updated product groups by new rank
        commit("sortProductGroupsByRank");
      }
    },

    /**
     * Update a ProductGroup with images.
     * It updates a ProductGroup and selectable ProductGroups accordingly.
     * @param {Object}  group           ProductGroup object: Refer to PRODUCT_GROUP_MODEL.
     * @param {Object}  [imageObjects]  Image objects: {files, preview}
     */
    async updateOneProductGroupWithImage({ commit, getters, dispatch }, { group, imageObjects }) {
      // Index of the ProductGroup
      const index = getters.findProductGroupIndexById(group.id);

      // TODO : Seperate it
      // Delete Products from the ProductGroup
      let isDel = false;
      if (group.deleteProductList) {
        if (group.deleteProductList.length > 0) {
          const response_del = await deleteProductsInGroupApi(group.id, group.deleteProductList);
          if (isResponseOK(response_del)) {
            group.products = response_del.data.products;
            isDel = true;
          } else { }
        } else { }
      } else { }

      // TODO : Seperate it
      // Add Products from the ProductGroup
      let isAdd = false;
      if (group.addProductList) {
        if (group.addProductList.length > 0) {
          const response_add = await addProductsInGroupApi(group.id, group.addProductList);
          if (isResponseOK(response_add)) {
            group.products = response_add.data.products;
            isAdd = true;
          } else { }
        } else { }
      } else { }
      

      // Re-load the Products
      if (isDel || isAdd) {
        // All the product of those updated by product group operation should be re-fetched
        // since it is only updated in the server.
        // TODO : load onnly those updated.
        dispatch('product/loadProducts', null, { root: true });
      }

      // Update the ProductGroup
      const response = await updateProductGroupWithImageApi(group, imageObjects);
      if (isResponseOK(response)) {
        // Update status of the Products in the ProductGroup
        dispatch("updateQueriedStatus", group);

        // TODO : FIX_GROUP_SCHEDULE_UPDATE Find out why it is not working properly.
        //        It works fine calling it outside of store module, but not with dispatch in Dialog.
        //        productCompare.availableTime points old version. 
        //        BP-950
        // Update schedule of the Products in the ProductGroup
        // dispatch("updateQueriedSchedule", group);

        // Update the ProductGroup
        commit(
          "updateOneProductGroup",
          {
            group: parseProductGroup(response.data),
            index: index
          }
        );
        // Set selectable ProductGroups
        commit("setSelectableProductGroups");
        // Set status or ProductGroups by checking all the status of the Products.
        dispatch("getQueriedStatus");
        // TODO : FIX_GROUP_SCHEDULE_UPDATE 
        // Set schedule of ProductGroups by checking all the schedule of the Products.
        // dispatch("getQueriedSchedule");
      } else {
        console.log("[Error]updateOneProductGroupWithImage:");
        // this._vm.$notify("error", "Fail", "ProductGroup update", { duration: 3000, permanent: false });
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Delete a ProductGroup.
     * It deletes a ProductGroup and selectable ProductGroups accordingly.
     * @param {string} id Id of a ProductGroup.
     */
    async deleteOneProductGroup({ commit, getters, dispatch }, id) {
      const response = await deleteProductGroupApi(id);
      if (isResponseOK(response)) {
        commit("deleteOneProductGroup", getters.findProductGroupIndexById(id));
        // Set selectable ProductGroups
        commit("setSelectableProductGroups");
        // Re-load the Products
        dispatch('product/loadProducts', null, { root: true });
      } else {
        console.log("[Error]deleteOneProductGroup:");
        // this._vm.$notify("error", "Fail", "Deleting group", { duration: 3000, permanent: false });
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Add a ProductGroup.
     * It adds a ProductGroup and selectable ProductGroups accordingly.
     * @param {Object} group ProductGroup object: Refer to PRODUCT_GROUP_MODEL.
     */
    async addOneProductGroup({ commit, dispatch }, group) {
      // Update products in a group if there is addProductList.
      if (group.addProductList) {
        group.products = group.addProductList;
      } else {}

      const response = await addProductGroupApi(group);
      if (isResponseCREATED(response)) {
        commit("addOneProductGroup", parseProductGroup(response.data));
        // Set selectable ProductGroups
        commit("setSelectableProductGroups");
        // Re-load the Products if necessary.
        if (group.addProductList) {
          if (group.addProductList.length > 0) {
            dispatch('product/loadProducts', null, { root: true });
          } else { }
        } else { }
      } else {
        console.log("[Error]addOneProductGroup:");
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Add a ProductGroup with images.
     * It adds a ProductGroup and selectable ProductGroups accordingly.
     * @param {Object}  group         ProductGroup object: Refer to PRODUCT_GROUP_MODEL.
     * @param {Object}  [imageObject] Image object: {files, preview}
     */
    async addOneProductGroupWithImage({ commit, dispatch }, { group, imageObject }) {
      // Update products in a group if there is addProductList.
      if (group.addProductList) {
        group.products = group.addProductList;
      } else { }

      const response = await addProductGroupWithImageApi(group, imageObject);
      if (isResponseCREATED(response)) {
        commit("addOneProductGroup", parseProductGroup(response.data));
        // Set selectable ProductGroups
        commit("setSelectableProductGroups");
        // Re-load the Products if necessary.
        if (group.addProductList) {
          if (group.addProductList.length > 0) {
            dispatch('product/loadProducts', null, { root: true });
          } else { }
        } else { }
        
      } else {
        console.log("[Error]addOneProductGroupWithImage:");
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Delete Products from a ProductGroup.
     * It deletes Products in a ProductGroup and updates QueriedStatus and QueriedSchedule accordingly.
     * @param {Object} group ProductGroup object(partial): {id, deleteProductList}.
     */
    async deleteProductsInGroup({ commit, getters, dispatch }, group) {
      // Index of the ProductGroup
      const index = getters.findProductGroupIndexById(group.id);

      // TODO : Seperate it
      // Delete Products from the ProductGroup
      if (Array.isArray(group.deleteProductList)) {
        if (group.deleteProductList.length > 0) {
          const response = await deleteProductsInGroupApi(group.id, group.deleteProductList);
          if (isResponseOK(response)) {
            group = response.data;

            // Update the ProductGroup
            commit(
              "updateOneProductGroup",
              {
                group: parseProductGroup(group),
                index: index
              }
            );

            // Set status or ProductGroups by checking all the status of the Products.
            dispatch("getQueriedStatus");
            // Set schedule of ProductGroups by checking all the schedule of the Products.
            dispatch("getQueriedSchedule");
          } else { }
        } else { }
      } else { }
    },


    // Queries.
    /**
     * Set status of ProductGroups by checking all the status of the Products.
     */
    getQueriedStatus({ state, commit, rootGetters }) {

      for (let i = 0; i < state.productGroups.length; ++i) {
        const curProductIds = state.productGroups[i].products ? state.productGroups[i].products : [];

        let status = ENUM_STATUS_STRING.INACTIVE;
        for (let j = 0; j < curProductIds.length; ++j) {
          const product = rootGetters["product/findProductById"](curProductIds[j]);
          if (product) {
            if (product.status === ENUM_STATUS_STRING.ACTIVE) {
              status = product.status;
              break;
            } else { }
          } else { }
        }

        // Update status of the ProductGroup
        commit(
          "updateOneProductGroup",
          {
            group: {status:status},
            index: i
          }
        );
      }
      // console.log(state.productGroups);
    },
    // TODO : simplify it.
    async updateQueriedStatus({ rootGetters, dispatch }, productGroup) {

      // Update only if there is a status in the product group
      // Eg. if status is not changed in edit mode it shouldn't be changed or
      //     all the status of the product in the product group can be changed unintentionally.
      if (productGroup.status) {

        const curProductIds = productGroup.products ? productGroup.products:[];
        for (let j = 0; j < curProductIds.length; ++j) {
          let product = rootGetters["product/findProductById"](curProductIds[j]);
          if (product) {
            if (product.status != productGroup.status) {
              // Update
              if (product.status != ENUM_STATUS_STRING.DELETED) {
                product.status = productGroup.status;
                // console.log(product);
                await dispatch('product/updateOneProduct', product, { root: true });
              } else { }
            } else { }
          } else { }
        }
       
        // TODO : if condition to check success
        // this.addNotify("Success", "Status of products in a group is updated");
      }
    },
    // TODO : simplify it.
    /**
     * Set schedule of ProductGroups by checking all the schedule of the Products.
     */
    getQueriedSchedule({ state, commit, rootGetters }) {
      for (let i = 0; i < state.productGroups.length; ++i) {
        const curProductIds = state.productGroups[i].products ? state.productGroups[i].products:[];

        // Add additional information for the product group
        let isSyncedInGroup = {
          hideUntil: false,
          availableTime: false
        };

        let isHideUntilEqualInGroup = true;
        let isAvailableTimeEqualInGroup = true;
        let productBase = null;
        let productCompare = null;

        if (curProductIds.length == 0) {
          // Do nothing
        } else if (curProductIds.length == 1) {
          // It is always equal if the product in a group is only one.
          productBase = rootGetters["product/findProductById"](curProductIds[0]);
          if (productBase) {
            if (productBase.status == ENUM_STATUS_STRING.DELETED) {
              // If the only product is deleted one make it to null
              productBase = null;
            }
          } else {
            productBase = null;
          }

        } else {
          // get base product
          let idxBase = 0;
          for (idxBase = 0; idxBase < curProductIds.length; ++idxBase) {
            productBase = rootGetters["product/findProductById"](curProductIds[idxBase]);
            if (productBase) {
              if (productBase.status != ENUM_STATUS_STRING.DELETED) {
                break;
              }
            }
          }

          // In case there is no available base product
          if (idxBase == curProductIds.length) {
            productBase = null;
          }

          // It will not run if there is  no available base product
          for (let j = idxBase + 1; j < curProductIds.length; ++j) {
            productCompare = rootGetters["product/findProductById"](curProductIds[j]);
            if (productCompare) {
              if (productCompare.status != ENUM_STATUS_STRING.DELETED) {
                if (!isAvailableTimeEqual(productBase.availableTime, productCompare.availableTime)) {
                  isAvailableTimeEqualInGroup = false;
                }
                if (!isHideUntilEqual(productBase.hideUntil, productCompare.hideUntil)) {
                  isHideUntilEqualInGroup = false;
                }
                if ((isAvailableTimeEqualInGroup == false) && (isHideUntilEqualInGroup == false)) {
                  break;
                }
              }
            }
          }
        }

        // console.log('isHideUntilEqualInGroup', isHideUntilEqualInGroup);
        // console.log('isAvailableTimeEqualInGroup', isAvailableTimeEqualInGroup);
        let scheduleData = {
          "isSyncedInGroup": isSyncedInGroup,
        }
        if (productBase != null) {
          // Update hideUntil in a product group 
          // since hideUntil of all the products in it is equal
          if (isHideUntilEqualInGroup) {
            scheduleData.hideUntil = productBase.hideUntil;
            scheduleData.isSyncedInGroup.hideUntil = true;
          } else {
            scheduleData.isSyncedInGroup.hideUntil = false;
          }

          // Update availableTime in a product group 
          // since availableTime of all the products in it is equal
          if (isAvailableTimeEqualInGroup) {
            scheduleData.availableTime = productBase.availableTime;
            scheduleData.isSyncedInGroup.availableTime = true;
          } else {
            scheduleData.isSyncedInGroup.availableTime = false;
          }
        } else {
          scheduleData.isSyncedInGroup.hideUntil = false;
          scheduleData.isSyncedInGroup.availableTime = false;
        }


        // Update status of the ProductGroup
        commit(
          "updateOneProductGroup",
          {
            group: {
              ...scheduleData,
              type: "group_product" //TODO : find right place
            },
            index: i
          }
        );

      }

      // console.log(state.productGroups);
    },
    async updateQueriedSchedule({ rootGetters, dispatch }, productGroup) {
      // console.log(productGroup);

      // TODO : isSyncedInGroup may need to be added with parse
      if (!productGroup.isSyncedInGroup) {
        productGroup.isSyncedInGroup = {};
      }
      
      if ((productGroup.isSyncedInGroup.isChanged == true)
        && ((productGroup.isSyncedInGroup.hideUntil == true) || (productGroup.isSyncedInGroup.availableTime == true))
      ) {

        const curProductIds = productGroup.products ? productGroup.products:[];
        for (let j = 0; j < curProductIds.length; ++j) {
          let product = rootGetters["product/findProductById"](curProductIds[j]);
          if (product) {
            if (product.status != ENUM_STATUS_STRING.DELETED) {
              // Update only if there are any changes.
              // console.log('test equal', isAvailableTimeEqual(productGroup.availableTime, product.availableTime));
              // Update hideUntil
              if (productGroup.isSyncedInGroup.hideUntil == true) {
                if (product.hideUntil != productGroup.hideUntil) {
                  product.hideUntil = productGroup.hideUntil;
                }
              }

              // Update availableTime
              if (productGroup.isSyncedInGroup.availableTime == true) {
                const isTimeEqual = isAvailableTimeEqual(productGroup.availableTime, product.availableTime);
                if (!isTimeEqual) {
                  product.availableTime = productGroup.availableTime;
                }
              }

              await dispatch('product/updateOneProduct', product, { root: true });
            }
          }
        }
        // TODO : if condition to check success
        // this.addNotify("Success", "Schedule of products in a group is updated");
      }

    },


    /**
     * Get labeled ProductGroups with Products as children.
     * It is to display ProductGroups and Products in Tree.
     * @return {Object[]} Labeled ProductGroups with Products. [{id, label, children:{id, label}}...]
     */
    async getLabeledGroupsWithProducts({ state, rootGetters }) {
      
      let groups =  state.productGroups
        .filter(g => !isNullOrUndefined(g.products) && g.products.length > 0)
        .map(group => {
          return {
            ...group,
            label: `[G]${group.name}`,
            children: rootGetters["product/findProductsByGroupId"](group.id).map(product => {
              return {
                id: product.id,
                label: (isNullOrUndefined(product.status) || product.status == ENUM_STATUS_STRING.DELETED) ? product.name + ' (deleted)' : product.name,
              }
            })
          }
        });

      const allProducts = rootGetters["product/products"];
      const allDeletedProducts = rootGetters["product/deletedProducts"];

      let productWithoutGroup = allProducts.filter(p => isNullOrUndefined(p.groups) || p.groups.length == 0);
      let deletedProductWithoutGroup = allDeletedProducts.filter(p => isNullOrUndefined(p.groups) || p.groups.length == 0);

      productWithoutGroup.push(...deletedProductWithoutGroup)

      if(productWithoutGroup.length > 0){
        const otherGroup = {
          id: GROUP_OTHERS,
          label: i18n.tc('product-group.others'),
          children: productWithoutGroup.map(p => {
            return {
              id: p.id,
              label: (isNullOrUndefined(p.status) || p.status == ENUM_STATUS_STRING.DELETED) ? p.name + ' (deleted)' : p.name,
            }
          })
        };

        groups.push(otherGroup);
      }

      return groups;
    },

  }
};
