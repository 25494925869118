/**
 * It contains store module for ProductUnits.
 */

import {
  getProductUnitsApi,
  addProductUnitApi,
  updateProductUnitApi,
  deleteProductUnitApi,
} from "@/api/product/product_units.js";

import { parseProductUnit }       from "@/models/products/product_unit.js";
import { sortByName }             from "@/utils/sort.js"
import { getListForDropDownMenu, isResponseOK, isResponseCREATED } from "@/utils/general.js"

export default {
  namespaced: true,
  state: {
    productUnits: [],           // List of ProductUnits: Refer to PRODUCT_UNIT_MODEL.
    selectableProductUnits: [], // List of selectable ProductUnits: {value:name, label:name, text:name}
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.productUnits = [];
      state.selectableProductUnits = [];
    },
    /**
     * Set all ProductUnits in the state.
     * @param {Object} state Local State object.
     * @param {Object} units List of ProductUnit objects: Refer to PRODUCT_UNIT_MODEL.
     */
    setAllProductUnits(state, units) {
      state.productUnits = units;
    },
    /**
     * Delete a ProductUnit in the state.
     * @param {Object} state Local State object.
     * @param {number} index Index of a ProductUnit in the list.
     */
    deleteOneProductUnit(state, index) {
      state.productUnits.splice(index, 1);
    },
    /**
     * Add a ProductUnit in the state.
     * @param {Object} state Local State object.
     * @param {Object} unit  ProductUnit object: Refer to PRODUCT_UNIT_MODEL.
     */
    addOneProductUnit(state, unit) {
      state.productUnits.push(unit);
    },
    /**
     * Upate a ProductUnit in the state.
     * @param {Object} state Local State object.
     * @param {Object} unit  ProductUnit object: Refer to PRODUCT_UNIT_MODEL.
     * @param {number} index Index of a ProductUnit in the list.
     */
    updateOneProductUnit(state, { unit, index }) {
      state.productUnits[index] = {
        ...state.productUnits[index],
        ...unit
      };
    },
    /**
     * Set selectable ProductUnits in the state.
     * @param {Object} state Local State object.
     */
    setSelectableProductUnits(state) {
      state.selectableProductUnits = getListForDropDownMenu(state.productUnits, false);
    },
  },
  getters: {
    /**
     * Get ProductUnits in the state.
     */
    productUnits: (state) => {
      return state.productUnits;
    },
    /**
     * Get selectable ProductUnits in the state.
     */
    selectableProductUnits: (state) => {
      return state.selectableProductUnits;
    },
    /**
     * Get a ProductUnit by Id.
     * @param {string} id Id of a ProductUnit.
     */
    findProductUnitById: (state) => (id) => {
      return state.productUnits.find(
        unit => unit.id === id
      );
    },
    /**
     * Get an index of a ProductUnit by Id.
     * @param {string} id Id of a ProductUnit.
     */
    findProductUnitIndexById: (state) => (id) => {
      return state.productUnits.findIndex(
        unit => unit.id === id
      );
    },
  },
  actions: {
    /**
     * Load ProductUnits.
     * It loads ProductUnits from the sever and set selectable ProductUnits.
     */
    async loadProductUnits({ commit }) {
      const response = await getProductUnitsApi();
      if (isResponseOK(response)) {
        const productUnits = parseProductUnit(response.data);
        commit("setAllProductUnits", productUnits.sort(sortByName));
        commit("setSelectableProductUnits");
      } else {
        console.log("[Error]loadProductUnits:");
      }
      return response;
    },
    /**
     * Update a ProductUnit.
     * It updates a ProductUnit and selectable ProductUnits accordingly.
     * @param {Object} unit ProductUnit object: Refer to PRODUCT_UNIT_MODEL.
     */
    async updateOneProductUnit({ commit, getters }, unit) {
      const response = await updateProductUnitApi(unit);
      if (isResponseOK(response)) {
        commit(
          "updateOneProductUnit",
          {
            unit: parseProductUnit(response.data),
            index: getters.findProductUnitIndexById(unit.id)
          }
        );
        commit("setSelectableProductUnits");
      } else {
        console.log("[Error]updateOneProductUnit:");
      }
      return response;
    },
    /**
     * Delete a ProductUnit.
     * It deletes a ProductUnit and selectable ProductUnits accordingly.
     * @param {string} id Id of a ProductUnit.
     */
    async deleteOneProductUnit({ commit, getters }, id) {
      const response = await deleteProductUnitApi(id);
      if (isResponseOK(response)) {
        commit("deleteOneProductUnit", getters.findProductUnitIndexById(id));
        commit("setSelectableProductUnits");
      } else {
        console.log("[Error]deleteOneProductUnit:");
      }
      return response;
    },
    /**
     * Add a ProductUnit.
     * It adds a ProductUnit and selectable ProductUnits accordingly.
     * @param {Object} unit ProductUnit object: Refer to PRODUCT_UNIT_MODEL.
     */
    async addOneProductUnit({ commit }, unit) {
      const response = await addProductUnitApi(unit);
      if (isResponseCREATED(response)) {
        commit("addOneProductUnit", parseProductUnit(response.data));
        commit("setSelectableProductUnits");
      } else {
        console.log("[Error]addOneProductUnit:");
      }
      return response;
    },
  }
};
