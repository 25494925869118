// Posts Page
// Author : Aysegul Yalcinkaya

<template>
  <b-colxx class="disable-text-selection">

    <!-- Top Menu -->
    <TopMenu
        :title="$t('menu.your-posts')"
        :config="{
      }"
    />
    <!-- List of Posts -->
    <template v-if="isLoad">
      <b-row key="thumb"  >

          <ListPost v-for="(item,index) in getAllPosts" :key="index" :id="item.id"
              :cyPrefix="`list-item-post-${index}`"
              :data="item"
              />

      </b-row>
    </template>

    <template v-else>
      <div data-cy="div-loading" class="loading"></div>
    </template>

  </b-colxx>
</template>


<script>

import DialogAlert from '@/components/Common/DialogAlert'
import TopMenu from '@/components/Forms/TopMenu'
import DialogProduct from '@/components/Products/DialogProduct'
import ListComponent from '@/components/Common/ListComponent'
import ListPost from '@/components/Listing/ListPost'

import {EMPTY_PRODUCT} from "@/models/products/product"

import {TYPE_PROMOTION, getDealType} from '@/models/promotion'

import {PATH_MAP_PROMOTIONS} from "@/constants/common.js";

// Notifications
import {
  addNotifySuccess,
  addNotifyWarning,
  addNotifyFail,
} from "@/utils/notify.js"
import {isNullOrUndefined, isResponseOK} from "@/utils/general.js"

import {mapGetters, mapActions, mapMutations} from 'vuex'
import {getPostHistory} from "@/api/social_media_post";



export default {
  components: {
    ListPost,
    TopMenu,
  },

  data() {
    return {
      isLoad: false,      // Is page loaded.
      search: "",          // Search keyword


     }
  },

  methods: {
    // Notifications.
    addNotifySuccess,
    addNotifyWarning,
    addNotifyFail,

    // Map actions.
    ...mapActions("social_media_post", ["loadPostHistory","setAllPosts"]),
    ...mapActions("social_media_accounts", ["getAyrshareUserInfo"]),

    init() {
      if (!isNullOrUndefined(this.ayrshareKey)) {
        this.getAyrshareUserInfo({
          profileKey: this.ayrshareKey,
          onSuccess: this.onGetAyrshareUserInfoSuccess,
          onFail: this.onGetAyrshareUserInfoFail
        })
      }
    },
    onGetAyrshareUserInfoSuccess(response) {
      if (isResponseOK(response)) {
        this.accounts = response.data.displayNames;
      }
    },
    onGetAyrshareUserInfoFail(response) {
      console.log("on check fail" + response);
    },

    /**
     * Get all the data for the page.
     */
    getAll() {
      // Get posts and store locally
      this.getPosts();
    },

    async getPosts() {
      await this.loadPostHistory({
        values: {
          profileKey: this.ayrshareKey,
        },
      }).then(response => {

        this.isLoad = true;
        if (response.status==200)
          this.setAllPosts(response.data)
        else
          this.setAllPosts(null)
      });
    },


  },
  computed: {
    // Map gatters
    ...mapGetters("merchant_profile", ["ayrshareKey"]),
    ...mapGetters("social_media_post", ["getAllPosts"]),
  },
  mounted() {
    this.$store.dispatch("auth/initializeMerchant");
    this.init();
    this.$nextTick(() => {
      this.getAll();
    });

  }
};
</script>
