
/**
 * It contains backend Apis for global points.
 * @author: Hanson Phung
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// Modules in the application.
import axios from "@/api/axios/axios_core_deal";

// URLs for Backend
const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;

// TODO : Obsolated
/**
 * Get Merchant Global Point
 * @return {Promise} Result of get Merchant Global Points
 */
const GET_MERCHANT_GLOBAL_POINT_URL = `${HOST}/merchant-points`;
export function getMerchantGlobalPointApi() {
    return axios.get(GET_MERCHANT_GLOBAL_POINT_URL,{
      headers:{
        'Access-Control-Allow-Origin':'*',
      }
    });
}

/**
 * Merchant Redeem Gloobal Point
 * @return {Promise} Result of merchant's Global Point after redeem
 */
const REDEEM_MERCHANT_GLOBAL_POINT_URL = `${HOST}/merchant-points/redeem`;
export function redeemMerchantGlobalPointApi(data) {
    return axios.post(REDEEM_MERCHANT_GLOBAL_POINT_URL, data,{
      headers:{
        'Access-Control-Allow-Origin':'*',
      }
    });
}

