var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        [
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-colxx",
                { attrs: { xxs: "2" } },
                [
                  _c("toggle-button-check", {
                    attrs: { name: "stock-limit", disabled: _vm.disabled },
                    on: { input: _vm.onChangeInput },
                    model: {
                      value: _vm.limitedStock,
                      callback: function ($$v) {
                        _vm.limitedStock = $$v
                      },
                      expression: "limitedStock",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-colxx", { attrs: { xxs: "3" } }, [
                _c("h6", [
                  _vm._v(_vm._s(`${_vm.$t("promotions.limits-stock-title")}`)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-colxx",
                [
                  _vm.limitedStock
                    ? _c("b-form-input", {
                        staticClass: "mt-2",
                        class: { "mb-5": _vm.$v.limitedStockValue.$invalid },
                        attrs: {
                          "data-cy": "promotion-stock-limit-input-num",
                          type: "number",
                          state: !_vm.$v.limitedStockValue.$invalid,
                          disabled: _vm.disabled,
                        },
                        on: {
                          change: () => {
                            this.limitedStockValue =
                              this.limitedStockValue < 1
                                ? 1
                                : this.limitedStockValue
                          },
                        },
                        model: {
                          value: _vm.limitedStockValue,
                          callback: function ($$v) {
                            _vm.limitedStockValue = $$v
                          },
                          expression: "limitedStockValue",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-form-invalid-feedback",
                    { staticClass: "invalid-feedback-space-md" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.no-points-range")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-colxx", { attrs: { xxs: "12" } }, [
                _c("p", { staticClass: "text-muted text-small mt-3" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("promotions.limits-stock-description")) +
                      "\n        "
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }