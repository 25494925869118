// List componet of Orders.
// Author : Huen Oh (heons921@gmail.com)
// @param {string} cyPrefix Prefix for data-cy.
// @param {Object} data Store object: Refer to MODEL_ORDER_READ.


<template>
  <b-card 
    :data-cy="`${cyPrefix}`"
    class="card-order mt-0 mb-3 p-0"
    @click="onClickCard"
  >
  <!-- router-link replace - to replace current route with the new one whenever Order Card is selected. 
  So that, we can use router.back to navigate to previous location before user opened OrdersMain page. -->
  <router-link :to="data.id" replace
    v-bind:id="data.id">
    <p :data-cy="`${cyPrefix}-paid-at`" class="text-center text-semi-large font-weight-bold">{{formatLongDateWithTime(paidAt)}}</p>
    <div class="text-center order-grid">
      <div>
        <h1 :data-cy="`${cyPrefix}-order-number`" class="m-0 font-weight-bold">
          {{idLast4Digits}}
        </h1>
      </div>

      <div>
        <h4 :data-cy="`${cyPrefix}-number-of-items`" class="m-0">
          {{numberOfItems}} {{$t('order.items')}}
        </h4>
      </div>

      <div>
        <h4 :data-cy="`${cyPrefix}-total-amount`" class="m-0">
          ${{totalAmount}}
        </h4>
      </div>

      <div>
        <p :data-cy="`${cyPrefix}-last-updated`" class="text-medium text-muted m-0">
          {{lastUpdated}} {{$t('order.ago')}}
        </p>
      </div>

      <div>
        <p :data-cy="`${cyPrefix}-serve-type`" class="text-medium m-0">
          {{serveType}}
        </p>
      </div>

      <div>
        <h4 :data-cy="`${cyPrefix}-serve-status`" class="text-order-new font-weight-bold m-0">
          {{serveStatus}}
        </h4>
      </div>
    </div>
  </router-link>
  </b-card>
</template>


<script>
// Events.
/**
 * Event on click.
 * 
 * @event ListOrderCard#click
 * @type {object}
 */

import { mapGetters } from "vuex";

import { 
  ENUM_ORDER_STATUS_STRING,
  ENUM_ORDER_SERVING_STATUS_STRING 
} from '@/models/order';


// utils
import { formatDateWithTime, formatNumber }       from "@/utils/general.js";
import { addTime, transformTimeWithTimeZone, differentTime, formatLongDateWithTime }  from "@/utils/datetime";

// constants
import { TIME } from "@/constants/common";

export default {
  props:{
    // {string} Prefix for data-cy.
    cyPrefix: {
      type: String,
      default: ""
    },
    // {Object} Store object: Refer to MODEL_ORDER_READ.
    data: {
      type: Object,
      default:() => {}
    }
  },
  components: {
  },
  data(){
    return {
      curTime: new Date(),
      // Interval to update lastUpdatedAt of order
      timerInterval: null,
      intervalPeriod: 10
    }
  },
  computed:{
    ...mapGetters("order",             ["currentStore"]),    
    ...mapGetters("merchant_store",    ["findStoreById"]),

    
    // {string} Last 4 digits of the order Id.
    idLast4Digits() {
      return '#' + this.data.id.substring(this.data.id.length - 4);
    },
    // {string} Serve type of the order.
    serveType() {
      if ((this.data.status === ENUM_ORDER_STATUS_STRING.COMPLETED)
        || (this.data.status === ENUM_ORDER_STATUS_STRING.REFUNDED)) {
        // completed with date.
        // TODO : wait for the backend updatedDate.
        // return "Completed";
        return formatDateWithTime(this.data.lastUpdatedAt);
      } else {
        if (this.data.isPickup === true) {
          return this.$t('order.serve-status.pick-up');
        } else if (this.data.isPickup === false) {
          return this.$t('order.serve-status.dine-in');
        } else {
          return this.$t('order.serve-status.delivery');
        }
      }
    },
    serveStatus(){
      switch(this.data.serveStatus){
        case null:
          return this.$t('order.serve-status.new');
        case ENUM_ORDER_SERVING_STATUS_STRING.ACCEPTED:
          return this.$t('order.serve-status.accepted');
        case ENUM_ORDER_SERVING_STATUS_STRING.PREPARING:
          return this.$t('order.serve-status.preparing');
        case ENUM_ORDER_SERVING_STATUS_STRING.READY:
          return this.$t('order.serve-status.ready');
        case ENUM_ORDER_SERVING_STATUS_STRING.DELIVERING:
          return this.$t('order.serve-status.delivering');
      }
    },
    numberOfItems(){
      return this.data.numberOfItems;
    },
    totalAmount(){
      return this.formatNumber(this.data.totalAmount);
    },
    storeTimeZone(){
      // Get currentStore timezone
      const currentStoreId = this.currentStore.value;
      const selectedStore = this.findStoreById(currentStoreId);
      return selectedStore.timeZone.zoneName;
    },
    lastUpdated(){
      // Calculate current at store timezone
      const curStoreTime = transformTimeWithTimeZone(this.storeTimeZone, this.curTime);
      
      // Calculate last updated time at store timezone
      const lastUpdatedAtTime = new Date(this.data.lastUpdatedAt);
      const lastUpdatedAtStoreTime = transformTimeWithTimeZone(this.storeTimeZone, lastUpdatedAtTime);
      
      // Find difference time in minute since last updated at
      const difference = differentTime(curStoreTime, lastUpdatedAtStoreTime, TIME.MINUTE);
      // Minute
      if(difference < 60){ return `${difference}m`; }
      // Hour
      if(difference < 60*24){ return `${Math.floor(difference/60)}h`; }
      // Day
      return `${Math.floor(difference/60/24)}d`;
    },
    paidAt(){
      return transformTimeWithTimeZone(this.storeTimeZone, new Date(this.data.paidAt));
    }
  },
  mounted() {
    // Update lastUpatedAt of order per 10 seconds
    this.timerInterval = setInterval(this.updateTimer, 1000 * this.intervalPeriod);
  },
  beforeDestroy(){
    // Clear timer interval before leaving the page
    clearInterval(this.timerInterval);
  },
  methods: {
    onClickCard() {
      this.$emit('click');
    },
    /**
     * Timer to update lastUpdatedAt of orders every 10 seconds
     */
    updateTimer(){
      this.curTime = new Date();
    },
    /**
     * Format number with 2 decimal places
     */
    formatNumber(num){
      const isToFixed = true;
      return formatNumber(num, isToFixed);
    },
    /**
     * format Time from date object
     */
    formatLongDateWithTime(time){
      return formatLongDateWithTime(time);
    }
  }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>
