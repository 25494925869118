// Componet for Base Badge 
// Author : Huen Oh (heons921@gmail.com)
// @param {string} cyPrefix Prefix for data-cy.
// @param {string} data     Data to be displayed in the badge.
// @param {string} variant  Type of the badge. It changes the color.

<template>
  <b-badge
    class="mb-1 ml-2" :variant="variant"
  >
    {{ data }}
    <a
      :data-cy="`${cyPrefix}-delete`" 
      href="javascript:void(0);"
      @click="onDelete"
    >x</a> 
  </b-badge>  
</template>


<script>
/**
 * Event on click of delete bagde.
 * 
 * @event BaseBadge#onDelete
 * @type {object}
 */

export default {
  components:{
  },
  props:{
    // {string} Prefix for data-cy.
    cyPrefix: {
      type: String,
      default: ""
    },
    // {string} Data to be displayed in the badge.
    data:{
      type:String,
      default: ""
    },
    // {string} Type of the badge. It changes the color.
    variant:{
      type:String,
      default: "info"
    },
  },
  methods:{
    onDelete(){
      // Event - BaseBadge#onDelete
      this.$emit('onDelete');
    }
  }
}
</script>
