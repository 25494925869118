/**
 * It contains model of Product object.
 */

// General parse and serialize function
import { parse, serialize } from "../general.js";
import { objToStrMap, strMapToObj } from '@/utils/general';

/**
 * Model - Product in list.
 * It simple has Id of a product for the reference
 * and groupIndex to group the product within a list.
 */
const MODEL_PRODUCT_IN_LIST = {
  id: "",           // {string}   Product Id.
  groupIndex: null, // {[number]} Group index to group the products in a list.
  quantity: null,   // {[number]} Quantity of the products in a list.
}

/**
 * Product Model - for Parse and Serialize use.
 */
const PRODUCT_MODEL = {
  id: "id",
  name: "name",
  description: "description",
  images: "images",
  attributes: "attributes",
  categories: "categories",
  price: "price",
  unit: "unit",
  merchantID: "merchantID",
  stores: "stores",
  productTaxConfigs: "productTaxConfigs",
  groups: "groups",
  isAddon: "isAddon",
  addonGroups: "addonGroups",
  hideUntil: "hideUntil",
  availableTime: "availableTime",
  status: "productStatus",
  lastUpdatedAt: "lastUpdatedAt"
};

/**
 * Empty default Product.
 */
export const EMPTY_PRODUCT = {
  name: "",
  description: "",
  images: null,
  attributes: {},
  categories: [],
  price: 0.0,
  unit: "",
  // merchantId: "merchantId",
  stores: [],
  productTaxConfigs: [],
  groups: [],
  // isAddon: false,
  addonGroups: [],
  hideUntil: null,
  avaiableTime: null,
  status: "INACTIVE",
  lastUpdatedAt: "",
};

/**
 * Parse a Product object.
 * @param {Object} product Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
 * @return {Object} Prased Product object.
 */
export function parseProduct(product) {
  return product;
  // TODO
  // return parse(product, PRODUCT_MODEL);
}

/**
 * Parse a list of Product object.
 * @param {Array} productList List of Product objects: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
 * @returns List of parsed Product object.
 */
export function parseProductList(productList) {
  return productList.map(product => parseProduct(product));
}

/**
 * Serialize a Product object.
 * @param {Object} product Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
 * @return {Object} Serialized Product object.
 */
export function serializeProduct(product) {
  return product;
  // TODO
  // return serialize(product, PRODUCT_MODEL);
}
