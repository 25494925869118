// TODO : add in-code documentation DONE
// TODO : update the model. it seems not completed.

import { parse, serialize } from "./general.js";

/**
 * Model for USER_RATING_DATA CHART.
 */
const USER_RATING_DATA = {
  rate: "rate",                                  // {Number} Rates in average rating chart e.g 1,2,3,4,5.
  totalRatingsPerRate: "totalRatingsPerRate",    // {Number} Total UserRatings for that rate. 
};

/**
 * Model for USER_RATING_PERCENTAGE.
 */
const USER_RATING_PERCENTAGE = {
  rate: "rate",
  percentRatingsPerRate: "percentRatingsPerRate",
};

/**
 * Parse a UserRating object.
 * @param {Object} data UserRating object: Refer to USER_RATING_DATA.
 * @return {Object} Prased UserRating object.
 */
export function parseUserRatingChartData(data) {
  return parse(
    { rate: data.rate, totalRatingsPerRate: data.totalRatingsPerRate },
    USER_RATING_DATA
  );
}

/**
 * Parse a UserRatingPercentage object.
 * @param {Object} data UserRatingPercentage object: Refer to USER_RATING_PERCENTAGE.
 * @return {Object} Prased UserRatingPercentage object.
 */
export function parseUserRatingPercentage(data) {

  return parse(
    { rate: data.rate, percentRatingsPerRate: data.percentRatingsPerRate },
    USER_RATING_PERCENTAGE
  );
}

/**
 * Serialize a UserRating object.
 * @param {Object} data UserRating object: Refer to USER_RATING_DATA.
 * @return {Object} Prased UserRating object.
 */
export function serializeUserRatingChartData(data) {
  return serialize(data, USER_RATING_DATA);
}

/**
 * Serialize a UserRatingPercentage object.
 * @param {Object} data UserRatingPercentage object: Refer to USER_RATING_PERCENTAGE.
 * @return {Object} Prased UserRatingPercentage object.
 */
export function serializeUserRatingPercentage(data) {
  return serialize(data, USER_RATING_PERCENTAGE);
}
