// Componet for Table List For Products
// Author : Huen Oh (heons921@gmail.com)
// @param {string}   cyPrefix    Prefix of the component. For data-cy.
// @param {string}   cySuffix    Suffix of the component. For data-cy.
// @param {array}    items       Items for the table list. 
// @param {boolean}  disabled    Diabled property of the component.
// @param {array}    fields      Fields  for the table list.       


<template>
  <b-row>
    <b-colxx md="12">
      <b-card body-class="pt-0 px-0">
        <!-- Table for Items -->
        <b-table
          :data-cy="`${cyPrefix}-table-${cySuffix}`"
          hover
          responsive
          sticky-header
          no-border-collapse
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- Status -->
          <template v-slot:cell(status)="row">
            <!-- Toggle button for status of  Items -->
            <toggle-button-status
              :name="`${cyPrefix}-table-${cySuffix}-${row.index}`"
              :ref="'toggle-status-' + row.item.id"
              :status="row.item.status"
              :disabled="disabled"
              @on-toggle-change="changeStatus(row.item)"      
            />
          </template>
          <!-- Groups of  Items -->
          <template v-slot:cell(groups)="row">
            {{ getNamesByIdsFromList(row.item.groups, selectableGroups) }}
          </template>
          <!-- Image buttons -->
          <template v-slot:cell(actions)="row">
            <div class="pl-1 align-self-center pr-4 action-block">
              <!-- Image button: Show detail  -->
              <a 
                :data-cy="`${cyPrefix}-table-${cySuffix}-${row.index}-btn-show`"
                href="#" @click="onEdit(row.item)" v-if="disabled == true"
              >
                <b-img
                  class="cls-btn-icon"
                  src="/assets/img/view.svg"
                  id="btn_edit">
                </b-img>
              </a>
              <!-- Image button: Edit  -->
              <a 
                :data-cy="`${cyPrefix}-table-${cySuffix}-${row.index}-btn-edit`"
                href="#" @click="onEdit(row.item)" v-if="disabled != true"
              >
                <b-img
                  class="cls-btn-icon"
                  src="/assets/icons/pencil.svg"
                  id="btn_edit">
                </b-img>
              </a>
              <!-- Image button: Delete -->
              <a 
                :data-cy="`${cyPrefix}-table-${cySuffix}-${row.index}-btn-delete`"
                href="#" @click="onDelete(row.item.id)" v-if="disabled != true"
              >
                <b-img
                  class="cls-btn-icon"
                  src="/assets/icons/close.svg"
                  id="btn_delete">
                </b-img>
              </a>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-colxx>
  </b-row>
</template>

<script>
// Events.
/**
 * Event on change status from ToggleButtonStatus
 * 
 * @event TableListProduct#changeStatus
 * @type {object}
 */

 /**
 * Event on click Edit image button.
 * 
 * @event TableListProduct#editItem
 * @type {object}
 */

 /**
 * Event on click Delete image button.
 * 
 * @event TableListProduct#deleteItem
 * @type {object}
 */

import { getNamesByIdsFromList }  from '@/utils/general'
import ToggleButtonStatus         from '@/components/Forms/ToggleButtonStatus'

export default {
  components:{
    ToggleButtonStatus,
  },
  props:{
    // {string} Prefix for data-cy.
    cyPrefix: {
      type: String,
      default: ""
    },
    // {string} Suffix for data-cy.
    cySuffix: {
      type: String,
      default: ""
    },
    // {array} items of the table. 
    items:{
      type: Array,
      default:()=>[]    
    },
    // {boolean} Disabled property of the component.
    disabled: {
      type: Boolean,
    },
    // {array} selectableGroups for the table. 
    selectableGroups:{
      type: Array,
      default:()=>[]    
    },
  },
  data() {
    return {
      sortBy: 'name',   // {string}   Sort the table by.
      sortDesc: false,  // {boolean}  Order for sorting table.
      fields: [         // {Object[]} Fields for Addons list table.
        { key: 'status',   sortable: true },
        { key: 'name',     sortable: true },
        { key: 'groups',   sortable: true },
        { key: 'actions',  sortable: false },
      ],
    }
  },
  methods:{  
    getNamesByIdsFromList,
    
    changeStatus(product) {
      // Event - TableListProduct#changeStatus
      const refName = 'toggle-status-' + product.id;
      product.status = this.$refs[refName].getSwitchValueToString();
      this.$emit('changeStatus', product);
    },
    onEdit(item){
      // Event - TableListProduct#editItem
      this.$emit('editItem', item);
    },
    onDelete(id){
      // Event - TableListProduct#deleteItem
      this.$emit('deleteItem', id);
    },
  },
}
</script>
<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>