import Vue  from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'

import app  from '../main'
import menu from "./modules/menu";
import auth from "./modules/auth";

import order            from './modules/order';
import promotion        from './modules/promotion';
import user_profile     from "./modules/user_profile";
import merchant_store   from "./modules/merchant_store";
import merchant_profile from "./modules/merchant_profile";
import global_point     from "./modules/global_point";
import payments         from "./modules/payments";

import addon              from './modules/product/addon';
import addon_group        from './modules/product/addon_group';
import product            from './modules/product/product';
import product_group      from './modules/product/product_group';
import product_unit       from './modules/product/product_unit';
import product_category   from './modules/product/product_category';
import product_attribute  from './modules/product/product_attribute';

import merchant_dashboard from "./modules/dashboard/merchant_dashboard";
import sales_dashboard    from "./modules/dashboard/sales_dashboard";
import detail_chart       from "./modules/dashboard/detail_chart";

import websocket from "./modules/websocket";

import LocalStorageService from "@/utils/local_store";
import social_media_accounts from "./modules/social_media_accounts";
import social_media_post from "@/store/modules/social_media_post";

Vue.use(Vuex);

/**
 * Creating store that holds everything globally
 */
const store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    merchant: { access_token: "" }, // For test
  },
  getters: {
  },
  mutations: {
    // set language
    /**
     * Set language.
     * @param {Object} state Local State object.
     * @param {string} payload Language setting. e.g.en.
     */
    changeLang(state, payload) {
      app.$i18n.locale = payload
      LocalStorageService.setCurrentLanguage(payload);
    }
  },
  actions: {
    /**
     * Reset state of all modules.
     */
    resetAll({ commit }) {
      commit("addon/resetAll");
      commit("addon_group/resetAll");

      commit("product/resetAll");
      commit("product_group/resetAll");
      commit("product_unit/resetAll");
      commit("product_category/resetAll");
      commit("product_attribute/resetAll");
      commit("order/resetAll");
      commit("promotion/resetAll");
      commit("global_point/resetAll");

      commit("merchant_profile/resetAll");
      commit("merchant_store/resetAll");

      commit("merchant_dashboard/resetAll");
      commit("sales_dashboard/resetAll");
      commit("detail_chart/resetAll");
      commit("user_profile/resetAll");

      commit("websocket/resetAll");
    },
    /**
     * Load all data of modules from the server.
     */
    async loadAll({ dispatch }) {
      dispatch("addon/loadAddons");
      dispatch("addon_group/loadAddonGroups");

      await dispatch("product/loadProducts"); // This should be before Promotion.
      dispatch("product_group/loadProductGroups");
      dispatch("product_unit/loadProductUnits");
      dispatch("product_category/loadProductCategories");
      dispatch("product_attribute/loadProductAttributes");
      dispatch("promotion/loadPromotions");

      // dispatch("merchant_profile/loadProfileServer");
      dispatch("merchant_store/loadStores");
    },
    setLang({commit}, payload) {
      commit("changeLang", payload)
    }
  },
  modules: {
    menu,
    // our
    auth,
    merchant_store,
    merchant_profile,
    user_profile,
    addon,
    addon_group,
    product,
    product_group,
    product_unit,
    product_category,
    product_attribute,
    order,
    promotion,
    global_point,
    payments,

    detail_chart,
    sales_dashboard,
    merchant_dashboard,

    social_media_accounts,
    social_media_post,
    websocket
  }
});

export default store;
