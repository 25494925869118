// Dialog with Date and Time(Schedule)
// Author : Huen Oh (heons921@gmail.com)
// @param: {Object} data              Object with day date time: {availableTime}.
// @param: {bool}   disabled          Disabled property of the toogle button.
// @param: {bool}   dialog            Handler variable for the dialog(Close and Open).
// @param: {bool}   disableHideUntil  To not to use hideUntil, default:false.

<template>
  <!-- Dialog: Date and Time(Schedule) -->
  <b-modal id="modalDateTime" ref="modalDateTime" :title="$t('product.select-schedule')" class="modal-right" size="lg" @hide="onCencel()">
    <b-form>
      <!-- Date Selection -->
      <b-card v-if="!disableHideUntil">
        <b-row class="mb-5">
          <b-colxx xxs="3">
            <!-- Checkbox is only for ProductGroup -->
            <b-form-checkbox 
              data-cy="dlg-schedule-hide-until-checkbox-group"
              :disabled="disabled"
              v-if="data.type == 'group_product'"
              v-model="isSyncedInGroup.hideUntil"
              class="mb-0"
            >
              <h3>{{$t('product.hide-until')}}</h3>
            </b-form-checkbox>
            <h3 v-else>{{$t('product.hide-until')}}</h3>
          </b-colxx>
          <b-colxx>
            <!-- Toggle button to enable hideUntil Date Picker -->
            <toggle-button
              data-cy="dlg-schedule-hide-until-toggle-btn"
              :disabled="disabled"
              v-model="switches.hideUntil"
              :value="switches.hideUntil"
              :sync="true"
              color="#f18024"
              :width="100"
              :labels="{checked: 'HIDE', unchecked: 'NOT HIDE'}"
              @change="markChanged()"
            />
          </b-colxx>
          <!-- Date Picker for hideUntil -->
          <b-colxx xxs="12">
            <v-date-picker
              data-cy="dlg-schedule-hide-until-date-picker"
              v-if="switches.hideUntil"
              v-model="hideUntil"
              mode="single"
              :disabled-dates='disabled ? { weekdays: [1,2,3,4,5,6, 7] } : { weekdays: [] }'
              :input-props="{ 
                class: 'form-control', placeholder: $t('product.hide-until'), disabled:disabled, }"
              @input="markChanged()"></v-date-picker>
          </b-colxx>
        </b-row>
      </b-card>
      <!-- Available Time selection -->
      <b-card>
        <DayDateTime
          ref="availableTime"
          :disabled="disabled"
          :days="availableTime"
          :typeGroup="data.type == 'group_product'"
          :isGroupSynced="isSyncedInGroup.availableTime"
        />
      </b-card>
  </b-form>
    <template slot="modal-footer">
      <b-button 
        data-cy="dlg-schedule-btn-cancel"
        variant="outline-secondary"
        @click="onCencel()"
      >{{ $t('buttons.cancel') }}</b-button>
      <b-button 
        data-cy="dlg-schedule-btn-submit"
        variant="primary" 
        @click="onSubmit()" 
        class="mr-1"
        :disabled="disabled"
      >{{ $t('buttons.submit') }}</b-button>
    </template>
  </b-modal>
</template>

<script>

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import { ToggleButton } from 'vue-js-toggle-button'
import DayDateTime from '@/components/Common/DayDateTime'

import { DAY_OF_WEEK_LONG } from "@/constants/common"


// TODO : move to right place
// Eempty schedule data. It is just a reference for data type
const EMPTY_SCHEDULE = {
  hideUntil: null,  // Hide until: Date object
  availableTime: [  // Available day and time
    // {
    //   day: 'SUNDAY',
    //   fromTime: "08:00",
    //   toTime: "16:00",
    // }
  ],
  // TODO : rename
  isSyncedInGroup: {         // To check if it is synced in a group
    hideUntil: false,
    availableTime: false,
    isChanged: false,
  },
};


export default {
  components:{
    'vue-slider': VueSlider,
    ToggleButton,
    DayDateTime,
  },
  props:{
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {Object} Object with day date time: {availableTime}.
    data: {
      type: Object,
      default: () => {}
    },
    // {bool} Handler variable for the dialog(Close and Open)
    dialog: {
      type: Boolean,
      default: false
    },
    // {bool} To not to use hideUntil, default:false.
    disableHideUntil: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      hideUntil: null,      // Hide until: Date object
      switches:{
        hideUntil:false,    // Switch for applying hideUntil
      },
      isSyncedInGroup: {    // To check if it is synced in a group
        hideUntil: false,
        availableTime: false,
        isChanged: false,
      },
      availableTime: [],
    }
  },

  methods: {
    /**
     * Reset the dialog
     */
    reset() {
      this.switches.hideUntil = false;
      this.hideUntil = null;

      this.isSyncedInGroup.hideUntil = false;
      this.isSyncedInGroup.availableTime = false;
      this.isSyncedInGroup.isChanged = false;
    },

    /**
     * Initialize the dialog
     */
    initDialog() {
      // Reset
      this.reset();

      // To update the slider when data.availableTime is updated.
      this.availableTime = this.data.availableTime;

      // Hide Until
      if(this.data.hideUntil) {
        this.switches.hideUntil = true;
        this.hideUntil = new Date(this.data.hideUntil);
      } else {}

      // Group synced status
      if (this.data.isSyncedInGroup) {
        if (this.data.isSyncedInGroup.hideUntil) {
          this.isSyncedInGroup.hideUntil = this.data.isSyncedInGroup.hideUntil;
        }
        if (this.data.isSyncedInGroup.availableTime) {
          this.isSyncedInGroup.availableTime = this.data.isSyncedInGroup.availableTime;
        }
      }
      // this.isSyncedInGroup.isChanged = false;
      // this.refreshSlide();
      // console.log(this.hideUntil);
    },

    /**
     * On Cencel button : Close the dialog
     */
    onCencel() {
      this.$emit('dialog-close');
    },
    
    /**
     * On Submit button : Save the data and Close the dialog
     */
    onSubmit() {
      // console.log("onSubmit");
      // console.log(this.data);

      // Hide until
      let dataToApply = {};
      if(this.switches.hideUntil == true) {
        dataToApply.hideUntil = this.hideUntil;
      } else {
        dataToApply.hideUntil = null;
      }

      // Available date and time
      dataToApply.availableTime = this.$refs.availableTime.getAvailableTime();

      // Group synced status
      dataToApply.isSyncedInGroup = this.isSyncedInGroup;
      dataToApply.isSyncedInGroup.availableTime = this.$refs.availableTime.getIsGroupSync();
      dataToApply.isSyncedInGroup.isChanged = dataToApply.isSyncedInGroup.isChanged || this.$refs.availableTime.getIsChanged();
      
      // console.log(dataToApply);
      // console.log(this.hideUntil);
      this.$emit('dialog-submit', dataToApply);
      this.$emit('dialog-close');
    },

    // // Refresh all slides : It is required to be worked properly in <b-modal> in vue-bootstrap
    // refreshSlide() {
    //   if(this.$refs.sliders) {
    //     this.$refs.sliders.forEach(s => s.refresh());
    //   } else {}
    // },

    /**
     * Mark the dialog to changed.
     */
    markChanged() {
      // console.log('markChanged');
      this.isSyncedInGroup.isChanged = true;
      this.isSyncedInGroup.hideUntil = true;
    },
  },

  watch: {
    // Control of the dialog: Show, Hide
    dialog: function(newVal, oldVal){
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if(newVal == true) {
        this.initDialog();
        this.$refs["modalDateTime"].show();
      } else {
        this.$refs["modalDateTime"].hide();
      }
    }
  },

  mounted(){ 
    // console.log(this.data);
    this.reset();
  }

}
</script>