// Dropdown for Sort Order By selection.
// Author : Huen Oh (heons921@gmail.com)
// @param {string}  label Label text for the dropdown. (in)
// @param {array}  orderOptions options for the orderby dropdown. (in)

<template>
  <b-dropdown 
    data-cy="top-dropdown-orderby" 
    id="ddown2" :text="`${$t('layouts.orderby')}: ${label}`" 
    variant="outline-dark" 
    class="mr-1 btn-group align-top" 
    size="xs" 
    no-flip
  >                
    <b-dropdown-item 
      :data-cy="`top-dropdown-orderby-item-${index}`" 
      v-for="(order,index) in orderOptions" 
      :key="index" 
      @click="onClick(order)"
    >
    {{ order.label }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>

export default {
  props:{
    // {string} Label text for the dropdown. (in)
    label:{
      type: String,
      default: "",
    },
    //{array} orderOptions options for the order by dropdown
    orderOptions:{
      type:Array,
      default:() => [],
    }
  },
  methods:{
    /**
     * On click the button.
     */
    onClick(filter) {
      this.$emit('click', filter);
    }
  }
}
</script>
