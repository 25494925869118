/**
 * It contains store module for Sales Dashboard.
 */

// Apis.
import {
  getSalesMetricWithinDateRangeApi,
  getSalesInitMetricWithinDateRangeApi
} from "@/api/dashboard/sales_dashboard.js";

import { parseSalesDashboard }             from "@/models/salesDashboard";
import { parseChartData }                  from "@/models/detailChartData";
import { isValidDate }                     from "@/utils/datetime"
import { isNullOrUndefined, isResponseOK } from "@/utils/general.js"

export default {
  namespaced: true,
  state: {
    initData: [],   // {Object[]} Initial Metric data: Refer to DASHBOARD_INIT.
    reportData: [], // {Object[]} Report data: Refer to DASHBOARD_DETAIL_CHART.
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.initData = [];
      state.reportData = [];
    },
    /**
     * Set initial data.
     * @param {Object} state Local State object.
     * @param {Object} data Initial Metric data: Refer to DASHBOARD_INIT.
     */
    setInitData(state, data) {
      if (Array.isArray(data)) {
        state.initData = data.map(d => {
          return parseSalesDashboard(d);
        });
      } else {
        state.initData = [];
      }
    },
    /**
     * Set report data.
     * @param {Object} state Local State object.
     * @param {Object} data Report data: Refer to DETAIL_CHART_DATA.
     */
    setReportData(state, data) {
      if (Array.isArray(data)) {
        state.reportData = data;
      } else {
        state.reportData = [];
      }
    },
  },
  getters: {
    /**
     * Return non-realtime chart data.
     * @param {Object}  state Local State object.
     */
    reportData: state => {
      return state.reportData;
    },
    /**
     * Return initial data.
     * @param {Object}  state Local State object.
     */
    initData: state => {
      return state.initData;
    }
  },
  actions: {
    /** 
     * Load initial data for the sales dashboard.
     * @param  {Object} payload Payload to get the data: Refer to DASHBOARD_PAYLOAD_GENERAL.
     * @return {Object} Response object of a Promise.
     */
    async loadInitMetricWithinDateRange({ commit }, payload) {
      // Validate if startDate & endDate are binded
      if (!isValidDate(payload.startDate) || !isValidDate(payload.endDate) || isNullOrUndefined(payload.merchantId)) {
        return null;
      } else {}

      const response = await getSalesInitMetricWithinDateRangeApi(payload);
      if (isResponseOK(response)) {
        commit("setInitData", response.data);
      } else {}
      return response;
    },

    /** 
     * Load particular metric data belong to merchant/store within during with viewMode DAILY/WEEKLY/MONTHLY.
     * @param  {Object} payload Payload to get the data: Refer to DASHBOARD_PAYLOAD_METRIC.
     * @return {Object} Response object of a Promise.
     */
    async loadSalesMetricWithinDateRange({ commit }, payload) {
      // Validate if startDate & endDate are binded
      if (!isValidDate(payload.startDate) || !isValidDate(payload.endDate)) {
        return null;
      } else {}

      const response = await getSalesMetricWithinDateRangeApi(payload);
      if (isResponseOK(response)) {
        const data = response.data.map(x => {
          return parseChartData(x);
        });
        commit("setReportData", data);
      } else {}
      return response;
    }
  }
};
