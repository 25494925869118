// List applied tax on order
// Props:   data {Object} Tax object 
//          name {String} For data-cy attribute

<template>
	<b-row v-if="(isNullOrUndefined(data) == false) && (!isNullOrUndefined(data.taxAmount)) && (data.taxAmount > 0)">
    <b-col class="text-left ml-2" :data-cy="`applied-tax-order-${name}-type`">
      {{ data.taxType }}({{ data.taxPercentage }}%)
    </b-col>
    <b-col class="text-right mr-3" :data-cy="`applied-tax-order-${name}-amount`">
      ${{!isNullOrUndefined(data.taxAmount) ? formatPriceWith2Decimal(data.taxAmount) : ''}}
    </b-col>
  </b-row>
</template>

<script>
import {isNullOrUndefined, formatPriceWith2Decimal} from '@/utils/general'

export default {
  props: {
    // Tax object: taxType, taxPercentage, taxAmount
    data: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: "",
    }
  },
  methods: {
    isNullOrUndefined,
    formatPriceWith2Decimal,
  }
}
</script>