// Dialog componet of Addon for Add/Edit/Show
// Author : Huen Oh (heons921@gmail.com)
// @param {bool}    dialog  Handler variable for the dialog(Close and Open).
// @param {Object}  data    Addon object: Refer to ADDON_MODEL and EMPTY_ADDON. It should be a copied object.
// @param {bool}    isOnAddonPage since this model is displayed on other pages than the addon. Use this value to display extra info, if necessary.


<template>
  <!-- Dialog for Addon -->
  <b-modal id="modalAddon" ref="modalAddon" size="lg" @hide="onCencel()">
    <!-- Emulate built in modal header with custom title and buttons -->
    <template v-slot:modal-header>
      <div class="w-100">
        <!-- Header Component -->
        <HeaderDialogProduct
          title="objects.addon"
          name="dlg-addon"
          :status="data.status"
          :disabled="data.showOnly"
          :actionType="actionType"
          @changeStatus="updateStatus"
        />

        <div v-if="isOnAddonPage === false">
          <p v-if="isAddonDeleted" class="text-warning">
            <u>
              <strong>{{$t('general.note')}}: {{$t('addon.addon-deleted')}}</strong>
            </u>
          </p>
          <p v-else class="text-muted">
            <u>
              <strong>{{$t('general.note')}}: </strong>
              {{$t('addon.current-information-note')}}
            </u>
          </p>
        </div>

      </div>
    </template>

    <b-form>
      <!-- Dialog for Alert -->
      <dialog-alert
        :dialog="dialogs.alert"
        :description="$t('alert.no-change')"
        :actionType="actionType"
        :buttonSubmitText="$t('buttons.ok')"
        @dialog-close="closeDialogAlert"
        @dialog-submit="onSubmitDialogAlert"
      />
      <b-row>
        <b-colxx sm="6">
          <!-- Name and Description -->
          <EditNameDescription
            name="dlg-addon" 
            :data="data"
            label="addon"
            :disabled="data.showOnly"
            :isFirstLoad="isFirstLoad"
          />
          <!-- Addon Price -->
          <EditPrice
            name="dlg-addon"
            :colSize="sizeColMain"
            :data="data"
            :disabled="data.showOnly"
            :isFirstLoad="isFirstLoad"
          />
          <!-- AddonGroups -->
          <select-addon-groups
            name="dlg-addon"
            ref="selAddonGroups"
            v-model="isChanged"
            :disabled="data.showOnly"
            :groups="data.groups"
            :isRequired="true"
            :isFirstLoad="isFirstLoad"
            @isValid="(ele) => {isValidGroups = ele;}"
          />
        </b-colxx>

        <b-colxx sm="6">
          <!-- Image Selection -->
          <b-form-group>
            <h6 v-if="!data.showOnly">{{$t('product.choose-image')}}</h6>
            <h6 v-else>{{$t('product.images')}}</h6>
            <b-row align-v="center" no-gutters>
              <b-colxx :sm="sizeColMain">
                <ImagesSelection
                  name="dlg-addon"
                  v-model="isChanged"
                  :images="data.images"
                  :single="false"
                  :disabled="data.showOnly"
                  key="selImage"
                  ref="selImage"
                />
              </b-colxx>
            </b-row>
          </b-form-group>
          <!-- Addon Attribute Selection -->
          <select-attributes
            name="dlg-addon"
            ref="selAttrubute"
            v-model="isChanged"
            :disabled="data.showOnly"
            :attributes="data.attributes"
          />        
        </b-colxx>
      </b-row>
      <b-row>
        <b-colxx>
          <!-- Selection :  Stores -->
          <CheckSelectStores
            name="dlg-addon"
            :storeIds="data.stores"
            :productTaxConfigs="data.productTaxConfigs"
            :disabled="data.showOnly"
            :isFirstLoad="isFirstLoad"
            :allowTaxConfigDialog="true"
            ref="selStores"
          />
        </b-colxx>
      </b-row>
    </b-form>

    <!-- Footer -->
    <template slot="modal-footer">
      <FooterDialogProduct
        name="dlg-addon"
        :disabled="($v.$invalid && !isFirstLoad) || isAddonDeleted"
        :actionType="actionType"
        @click-cancel="onCencel"
        @click-submit="onSubmit"
      />
    </template>
  </b-modal>
</template>

<script>
import DialogNameDescription  from '@/components/Common/DialogNameDescription'
import DialogAlert            from '@/components/Common/DialogAlert'
import ImagesSelection        from '@/components/Common/ImagesSelection';
import HeaderDialogProduct    from '@/components/Common/HeaderDialogProduct'
import FooterDialogProduct    from '@/components/Common/FooterDialogProduct'
import ToggleButtonStatus     from '@/components/Forms/ToggleButtonStatus'
import EditPrice              from '@/components/Forms/Editbox/EditPrice'
import CheckSelectStores      from '@/components/Forms/CheckSelectStores'
import SelectAttributes       from '@/components/Forms/SelectAttributes'
import SelectAddonGroups      from '@/components/Forms/SelectAddonGroups'
import EditNameDescription    from '@/components/Forms/Editbox/EditNameDescription'


import { getNameByIdFromList, addItemValueToList }  from "@/utils/general"

import { ENUM_STATUS_STRING } from "@/constants/common";

// Notifications
import {
  addNotifySuccess,
} from "@/utils/notify.js"

import { mapGetters, mapActions } from 'vuex'

// For validation
import { validationMixin } from "vuelidate";
const { required, minLength, maxLength, decimal, sameAs } = require("vuelidate/lib/validators");

// Empty selected item
const EMPTY_SEL_ITEM = {
  group_addon: {value:"", label:"", text:""},
  attribute:  {value:"", label:"", text:""},
  store:"",
};

export default {
  components:{
    DialogAlert,
    DialogNameDescription,
    ImagesSelection,
    ToggleButtonStatus,
    CheckSelectStores,
    SelectAttributes,
    SelectAddonGroups,
    EditNameDescription,
    EditPrice,
    FooterDialogProduct,
    HeaderDialogProduct
  },
props: {
    // {bool} Handler variable for the dialog(Close and Open).
  dialog: {
    type: Boolean, 
    default: false,
  },
    // {Object} Addon object: Refer to ADDON_MODEL.
  data: {
    type: Object,
    default: () => {},
  },
  //{Bool} to determine if this model is shown at default addon page or at some other page.
  isOnAddonPage: {
    type: Boolean,
    default: true,
  }
},
  data(){
    return{
      sizeColMain:10,   // {number} Size of the column for forms.
      actionType: "",   // {string} Type of action : "Add", "Edit" or "Show"
      VAL_TRUE: true,   // {bool}  True value for the validation - const.

      isChanged: false, // {bool} Flag if anything has changed in the dialog.
      isfirstWatch: {   // {bool} Flag if it the first watch to detemine isChanged flag.
        data: true,
        addonImages: true,
      },
      isValidGroups: false, // {bool} Flag if AddonGroups are valid.

      dialogs: {        // Handler object for dialogs(Close and Open).
        alert:false,
      },
      
      newSelItem: EMPTY_SEL_ITEM, // Selected items object: Refer to EMPTY_SEL_ITEM.,
      
      // is First Load, in order to not display validation message at first
      isFirstLoad: true

    }
  },
  // Validations
  mixins: [validationMixin],
  validations: {
    data: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(35)
      },
      description: {
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      price: {
        required,
        decimal
      },
      stores:{
        required,
        minLength: minLength(1),
      }
    },
    isValidGroups: {
      required,
      isTrue: sameAs('VAL_TRUE'),
    }
  },

  methods: {
    // Get name of an item from a list.
    getNameByIdFromList,

    // Add a value of an item to the item list if it doesn't exist in the list.
    addItemValueToList,

    // Notifications.
    addNotifySuccess,

    // Map actions.
    ...mapActions("addon", [
      "updateOneAddonWithImages",
      "addOneAddonWithImages",
      "applyAddonFilters",
      "setAddonFilters",
    ]),

    /**
     * Reset all variables.
     */
    reset() {
      this.newSelItem = EMPTY_SEL_ITEM;

      this.isChanged = false;
      this.isfirstWatch.data = true;
      this.isfirstWatch.addonImages = true;
    },
    /**
     * Initialize the dialog with input data.
     */
    initDialog() {
      this.reset();
      // console.log('initDialog', this.data);

      // Set title
      if (this.data.showOnly) {
        this.actionType = this.$t('actions.show');
      } else if (this.data.id) {
        this.actionType = this.$t('actions.edit');
      } else {
        this.actionType = this.$t('actions.add');
      }
    },

    // TODO : double call with @hide 
    /**
     * On Cencel button : Close the dialog.
     */
    onCencel() {
      this.isFirstLoad = true;
      console.log("onCencel");
      this.$emit('dialog-close');
    },
    /**
     * On Submit button : Save the data and Close the dialog.
     */
    onSubmit() {      
      this.isFirstLoad = false;

      // Not submit the form if fail validation
      if(this.$v.$invalid){
        return;
      }
      // console.log('onSubmit', this.data);
      // console.log('onSubmit', this.isChanged);

      if(this.data.showOnly === true) {
        // Show -> Edit
        this.data.showOnly = false;
        this.actionType = this.$t('actions.edit');
      } else {
        if(this.data.id) { //Edit
          this.submitChanges();
        } else {
          // Add
          if(this.isChanged) {
            this.submitChanges();
          } else {
            // There is no change, are you sure?
            this.openDialogAlert();
          }
        }
      }
    },
    /**
     * Submit changes of the dialog.
     */
    submitChanges() {
      // console.log(this.data);

      // Set Attributes.
      this.data.attributes = this.$refs.selAttrubute.getAttrubutesObject();

      // Set AddonGroups.
      const updatedGroups = this.$refs.selAddonGroups.getGroups();
      let deletedGroups = [];
      this.data.groups.forEach(group => {
        if(!updatedGroups.includes(group)) {
          deletedGroups.push(group);
        } else {}
      });
      this.data.deletedGroups = deletedGroups;
      this.data.groups = updatedGroups;

      this.data.productTaxConfigs = this.$refs.selStores.getTaxConfigIdsObject();

      // Set image objects for the change.
      const imageObjects = this.$refs.selImage.getImageObjects(); 
      // console.log('submitChanges', imageObject);

      // Select what to do: Update or Add by id
      if(this.data.id != undefined) {
        // Update - PUT
        this.updateAddon(this.data, imageObjects);
      } else {
        // Add - POST
        this.addAddon(this.data, imageObjects);
      }

      this.onCencel();
    },

    /**
     * Add an Addon to the server.
     * @param {Object} addon              Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
     * @param {Object[]} [imageObjects[]] Image objects: [{files, preview}]
     */
    addAddon(addon, imageObjects) {
      // console.log('addAddon', addon);
      // If there is no images, the addon will be added without images
      this.addOneAddonWithImages({addon, imageObjects})
        .then((response)=>{
          this.applyAddonFilters();
          this.addNotifySuccess(this.$t('notify.addon-add'));
        });;
    },
    /**
     * Update an Addon to the server.
     * @param {Object} addon              Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
     * @param {Object[]} [imageObjects[]] Image objects: [{files, preview}]
     */
    updateAddon(addon, imageObjects){
      let addonToUpdate = {...addon, availableTime:null,} // Temporary.
      this.updateOneAddonWithImages({addon:addonToUpdate, imageObjects:imageObjects})
        .then((response)=>{
          // console.log('updateAddon', response);
          if(response.data) {
            this.applyAddonFilters();
            this.addNotifySuccess(this.$t('notify.addon-update'));
          }
      });
    },
    
    /**
     * Update status of the item
     * @param {string} status Status: "ACTIVE" or "INACTIVE"
     */
    updateStatus(status) {
      this.data.status = status;
    },

    /**
     * Delete a category. It only updates locally.
     * @param {number} index index of an item to delete.
     */
    deleteCategory(index) {
      this.data.categories.splice(index,1);
    },

    // Dialog operations - Unchanged submit Alert
    /**
     * Close Alert dialog - Unchanged submit.
     */
    closeDialogAlert() {
      this.dialogs.alert = false;
    },
    /**
     * Open Alert dialog - Unchanged submit.
     */
    openDialogAlert() {
      this.dialogs.alert = true;
    },
    /**
     * On Submit Alert dialog - Unchanged submit.
     * Submit the changes.
     */
    onSubmitDialogAlert() {
      this.submitChanges();
    },

  },
  computed:{
    // Map gatters
    ...mapGetters("merchant_store",     ["selectableStores", "getDefaultTaxConfigsOfStore"]),
    
    /**
     * Check whether the addon is deleted 
     */
    isAddonDeleted(){
      return this.data.status === ENUM_STATUS_STRING.DELETED;
    },
  },
  
  watch: {
    /**
     * Watch to control the dialog: Show, Hide.
     * @param {bool} newVal New value.
     * @param {bool} oldVal Old value.
     */
    dialog: function(newVal, oldVal){
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if(newVal == true) {
        this.initDialog();
        this.$refs["modalAddon"].show();
      } else {
        this.$refs["modalAddon"].hide();
      }
    },

    /**
     * Watch to check changes in the data.
     */
    data: {
      deep: true,
      // immediate: true,
      handler() {
        if(this.isfirstWatch.data == false) {
          if(this.isChanged == false) {
            this.isChanged = true;
            // console.log("watch data changed");
          }
        } else {
          // console.log("watch data changed", this.data);
          this.isfirstWatch.data = false;
        }
      },
    },

  },

  mounted(){ 
  }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>

