
import {
    sendCheckStripeConnectedApi,
    sendConnectMerchantStripeApi,
    getStripeDashboardUrlApi,
    getStripeOnboardingLinkApi
} from "../../api/payments.js";

import LocalStorageService from "@/utils/local_store.js";

export default {
  namespaced: true,
  state: {
    /**
     * Initial value true because to only show the notification message once it is checked that account is not connected to stripe rather than showing it initally.
     * Used in TopNav.vue.
     * {boolean} To indicate if the stripe account is connected.
     */
    isStripeConnected: true,
  },
  mutations: {
    /**
     * Set isStripeConnected.
     * @param {Object} state        Local State object.
     * @param {boolean} isConnected Is stripe account connected.
     */
    setIsStripeConnected(state, isConnected) {
      state.isStripeConnected = isConnected;
    },
  },
  getters: {
    /**
     * Set isStripeConnected.
     */
    isStripeConnected: (state) => {
      return state.isStripeConnected;
    },
  },
    actions: {
        /**
         * Check whether merchant Stripe account is connected to platform
         */
        checkStripeConnected({ commit }, data) {
            const { onSuccess, onFail } = data;

            sendCheckStripeConnectedApi(data)
                .then(response => {
                    commit("setIsStripeConnected", response.data);
                    
                    LocalStorageService.setIsStripeConnected(response.data);

                    onSuccess(response);
                })
              .catch(error => {
                    if (onFail) {
                        onFail(error.response);
                    }
                });
        },
        /**
         * Request to connect merchant Stripe account to platform
         */
        sendConnectMerchantStripe({ commit }, data) {

            const { authCode, onSuccess, onFail } = data;

            sendConnectMerchantStripeApi(data)
                .then(response => {
                    // console.log(response);
                    onSuccess(response);
                })
                .catch(error => {
                    if (onFail) {
                        onFail(error.response);
                    }
                });
        },
        /**
         * Request to get Stripe onboarding URL
         */
        getStripeOnboardingURL({ commit }, data) {
            const { onSuccess, onFail } = data;

            getStripeOnboardingLinkApi()
                .then(response => {
                    onSuccess(response);
                })
                .catch(error => {
                    if (onFail) {
                        onFail(error.response);
                    }
                });
        },

        /**
         * Request to connect merchant Stripe account to platform
         */
        getStripeDashboardUrl({ commit }, data) {

            const { onSuccess, onFail } = data;

            getStripeDashboardUrlApi()
                .then(response => {
                    // console.log(response);
                    onSuccess(response);
                })
                .catch(error => {
                    if (onFail) {
                        onFail(error.response);
                    }
                });
        }
    }
};
