<template>
  <b-dropdown
    data-cy="top-dropdown-filterOrderStatus"
    :text="$t('order.fields.status') + ': ' + $t(`order.status.${selectedOrderStatus.label}`)"
    variant="outline"
    class="mr-1 mb-1"
    size="xs"
  >
    <b-dropdown-item
      v-for="(orderStatus, index) in orderStatuses"
      :key="index"
      :data-cy="`top-dropdown-filterOrderStatus-item-${index}`"
      @click="onClick(orderStatus)"
      :active="orderStatus.active"
    >
    {{ $t(`order.status.${orderStatus.label}`) }}
    </b-dropdown-item>
  </b-dropdown>
  
</template>

<script>
export default {
  props: {
    selectedOrderStatus: Object,
  },
  data() {
    return {
      orderStatuses: [
        {value: "", label: "all", active: false},
        {value: "COMPLETED", label: "completed", active: false},
        {value: "REFUNDED", label: "refunded", active: false}
      ]
    }
  },
  methods: {
    onClick(filter) {
      this.$emit("click", filter);
    }
  },
  mounted() {
    if (this.selectedOrderStatus && this.selectedOrderStatus.value) {
      this.orderStatuses = this.orderStatuses.map(status => (status.value === this.selectedOrderStatus.value) ? 
                                                    ({...status, active: true}) : 
                                                    ({...status, active: false}));
    }
  },
  watch: {
    selectedOrderStatus(newVal, oldVal) {
      if (newVal && (newVal.value || newVal.value === "")) {
        this.orderStatuses = this.orderStatuses.map(type => (type.value === newVal.value) ? 
                                                    ({...type, active: true}) : 
                                                    ({...type, active: false}));
      }
    }
  }
}
</script>