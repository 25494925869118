/**
 * It contains store module for Promotions.
 */

import _ from "lodash";

import {
  getPromotionsApi,
  getOnePromotionApi,
  deletePromotionApi,
  addPunchCardApi,
  addPointPerkApi,
  addRewardItemApi,
  addDealApi,
  updatePunchCardApi,
  updatePointPerkApi,
  updateRewardItemApi,
  updateDealApi,
  updatePromotionStatusApi,
  uploadImages,
} from "@/api/promotions.js";

import { parsePromotions, parsePromotion } from "@/models/promotion.js";

import { isNullOrUndefined } from "@/utils/general";

import { sortInOrder } from "@/utils/sort.js"
import { isResponseOK, isResponseCREATED, dataURItoFile } from "@/utils/general.js"
import { ENUM_STATUS_STRING } from "@/constants/common";

// API mapping for types of promotion.
const API_TYPE_MAP = {
  PUNCH_CARD: {
    update: updatePunchCardApi,
    create: addPunchCardApi
  },
  REWARD_ITEM: {
    update: updateRewardItemApi,
    create: addRewardItemApi
  },
  POINT_PERK: {
    update: updatePointPerkApi,
    create: addPointPerkApi
  },
  DEAL: {
    update: updateDealApi,
    create: addDealApi
  }
}

export default {
  namespaced: true,
  state: {
    promotions: [],         // {Object[]} List of Promotions: Refer to MODEL_PROMOTION_READ.
    filteredPromotions: [], // {Object[]} List of filtered Promotions: Refer to MODEL_PROMOTION_READ.
    fileImage: {},          // {Object}   Images files. Id of a promotion is prop. {:id:File}.
    imagesToDelete: [],     // {string[]} Urls of images to delete.
    // for new promo processing
    // TODO : may not need. just add one with id=-1
    currentPromotion: {},         // {Object} Current working Promotion: Refer to MODEL_PROMOTION_READ.
    currentPromotionImage: null,  // {File}   Image files For new Promotion.
    // current set up step
    step: 0,                // {number} Step of the Promotion Create/Edit.
    filters: {              // Filters
      store: { value: "", label: "" },
      type: { value: "", label: "" },
      order: { value: "", label: "", isAscending: true, },
      keyword: "",
    },
    deletedPromotions: [],  // {Object[]} List of deleted Promotions:
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.promotions = [];
      state.filteredPromotions = [];
      state.fileImage = {};
      state.imagesToDelete = [];
      state.currentPromotion = {};
      state.currentPromotionImage = null;
      state.step = 0;
      state.filters = {
        store: { value: "", label: "" },
        type: { value: "", label: "" },
        order: { value: "", label: "", isAscending: true, },
        keyword: "",
      };
      state.deletedPromotions = [];
    },
    /**
     * Increase step of the process + 1.
     * @param {Object}  state Local State object.
     */
    increaseStep(state) {
      state.step += 1;
    },
    /**
     * Reset step of the process to 0.
     * @param {Object}  state Local State object.
     */
    resetStep(state) {
      state.step = 0;
    },
    /**
     * Set all Promotions in the state.
     * @param {Object} state Local State object.
     * @param {Object} data  List of Promotion objects: Refer to MODEL_PROMOTION_READ.
     */
    setAllPromotions(state, data) {
      state.promotions = data;
    },
    /**
     * Add a Promotion in the state.
     * @param {Object} state Local State object.
     * @param {Object} promo Promotion object: Refer to MODEL_PROMOTION_READ.
     */
    addOnePromotion(state, promo) {
      state.promotions = [...state.promotions, promo];
    },
    /**
     * Add a Promotion into the list of deleted Promotion in the state.
     * @param {Object} state Local State object.
     * @param {Object} promo Promotion object: Refer to MODEL_PROMOTION_READ.
     */
    addOneDeletedPromotion(state, promo) {
      state.deletedPromotions = [...state.deletedPromotions, promo];
    },
    /**
     * Delete a Promotion in the state.
     * @param {Object} state Local State object.
     * @param {string} id    Id of a Promotion.
     */
    deleteOnePromotionById(state, id) {
      // Add deleted promotion to the deleted list
      const deletedPromotion = state.promotions.find(p => p.id == id);

      if (!isNullOrUndefined(deletedPromotion)){
        state.deletedPromotions = [...state.deletedPromotions, deletedPromotion];
      }
      
      // Exclude the deleted one from the list promotions
      state.promotions = state.promotions.filter(promo => promo.id != id);
    },
    // TODO : change index to id.
    /**
     * Upate a Promotion in the state.
     * @param {Object} state  Local State object.
     * @param {Object} promo  Promotion object: Refer to MODEL_PROMOTION_READ.
     * @param {number} index  Index of a Promotion in the list.
     */
    updateOnePromotion(state, { promo, index }) {
      if (index != -1) {
        state.promotions[index] = {
          ...state.promotions[index],
          ...promo
        };
        // console.log('state.promotions', state.promotions[index]);
      } else {
        state.currentPromotion = {
          ...state.currentPromotion,
          ...promo
        };
      }
    },
    /**
     * Upate status of a Promotion in the state.
     * @param {Object} state  Local State object.
     * @param {string} id     Id of a Promotion.
     * @param {string} status status of a Promotion.
     */
    updateStatus(state, { id, status }) {
      let toUpdate = state.promotions.find(promo => promo.id == id);
      if (toUpdate == null) {
        state.currentPromotion.status = status;
      } else {
        toUpdate.status = status;
        state.promotions = [
          ...state.promotions.filter(promo => promo.id != id),
          toUpdate
        ];
      }
      // console.log('state.promotions', state.promotions)
    },
    /**
     * Upate status of a Promotion in the state.
     * @param {Object} state  Local State object.
     * @param {File}   file   Image file.
     * @param {string} id     Id of a Promotion.
     */
    setFile(state, { file, id }) {
      if (id) {
        state.fileImage[id] = file;
      } else {
        state.currentPromotionImage = file;
      }
    },
    
    // TODO : rename
    /**
     * Add url of an image to imagesToDelete.
     * @param {Object} state  Local State object.
     * @param {string} imgUrl Urls of an image to delete.
     */
    removeImage(state, imgUrl) {
      state.imagesToDelete = [...state.imagesToDelete, imgUrl];
      state.imagesToDelete = _.uniq(state.imagesToDelete);
    },
    /**
     * Empty imagesToDelete.
     * @param {Object} state  Local State object.
     */
    cleanToDelete(state) {
      state.imagesToDelete = [];
    },

    /**
     * Set image object of current promotion in the state.
     * @param {Object} state  Local State object.
     * @param {Objecr} image  Image object {file, preview}.
     */
    setCurrentPromotionImage(state, image) {
      if (image) {
        state.currentPromotionImage = { ...image };
      } else {
        state.currentPromotionImage = null;
      }
    },
    /**
     * Upate current Promotion in the state.
     * @param {Object} state  Local State object.
     * @param {Object} promo  Promotion object: Refer to MODEL_PROMOTION_READ.
     */
    updateCurrentPromotion(state, promo) {
      state.currentPromotion = {
        ...state.currentPromotion,
        ...promo
      };
    },
    /**
     * Reset currentPromotion and currentPromotionImage in the state.
     * @param {Object} state  Local State object.
     */
    resetCurrentPromotion(state) {
      state.currentPromotion = {};
      state.currentPromotionImage = null;
    },

    /**
     * Delete a filtered Promotion in the state.
     * @param {Object} state  Local State object.
     * @param {number} index  Index of a filtered Promotion in the list.
     */
    deleteOneFilteredPromotion(state, index) {
      state.filteredPromotions.splice(index, 1);
    },
    /**
     * Set filters in the state.
     * @param {Object} state    Local State object.
     * @param {Object} filters  Filter object.
     */
    setPromotionFilters(state, filters) {
      state.filters = {
        ...state.filters,
        ...filters,
      }
    },
    /**
     * Apply Promotion filters.
     * @param {Object} state Local State object.
     */
    applyPromotionFilters(state) {
      // console.log("applyPromotionFilters");
      // console.log(this.filters);
      // Apply filters

      // 1. not deleted
      state.filteredPromotions = state.promotions.filter(element => element.status != ENUM_STATUS_STRING.DELETED);
      // console.log(state.promotions);
      // console.log(state.filters);

      // 2. Type.
      if (state.filters.type.value !== "") {
        // TODO
        if (state.filters.type.value === "ALL_NO_REWARD") {
          state.filteredPromotions = state.filteredPromotions.filter(element => element.type !== "REWARD_ITEM");
        } else {
          // TODO : for type of deals.
          state.filteredPromotions = state.filteredPromotions.filter(element => element.type === state.filters.type.value);
        }
      } else { }
      // console.log(state.filteredPromotions);
      // 3. store
      if (state.filters.store.value != "") {
        state.filteredPromotions = state.filteredPromotions.filter(element => element.stores.includes(state.filters.store.value));
      } else { }
      // 4. Name in search
      if (state.filters.keyword != "") {
        state.filteredPromotions = state.filteredPromotions.filter(
          element => element.name.toLowerCase().includes(state.filters.keyword.toLowerCase())
        );
      } else { }
      // 6. order
      sortInOrder(state.filteredPromotions, state.filters.order);
      // console.log(state.filteredPromotions);
    },
  },
  getters: {
    promotions: state => {
      return state.promotions;
    },
    promotions_reward_items: state => {
      return state.promotions.filter(promo => promo.type==='REWARD_ITEM');
    },
    /**
     * Get filtered Promotions in the state.
     */
    filteredPromotions: (state) => {
      return state.filteredPromotions;
    },
    /**
     * Get filters in the state.
     */
    filters: (state) => {
      return state.filters;
    },
    /**
     * Get step in the state.
     */
    step: state => {
      return state.step;
    },
    /**
     * Get current promotion in the state.
     */
    currentPromotion: state => {
      return state.currentPromotion;
    },
    /**
     * Get current promotion image file in the state.
     */
    currentPromotionImageName: state => {
      if (state.currentPromotionImage === null) {
        return null;
      } else {
        return state.currentPromotionImage.name ? state.currentPromotionImage.name : null;
      }
    },
    /**
     * Get current promotion image object in the state.
     */
    currentPromotionImageObject: state => {
      if (state.currentPromotionImage === null) {
        return null;
      } else {
        return {
          "file": state.currentPromotionImage.file ? state.currentPromotionImage.file : null, 
          "preview": state.currentPromotionImage.preview ? state.currentPromotionImage.preview : "",
        }
      }
    },
    /**
     * Get images to be deleted in the state.
     */
    imagesToDelete: state => {
      return state.imagesToDelete;
    },
    // getCurrentPromo: state => {
    //   if (!state.editPromoId) {
    //     return null;
    //   }
    //   return state.promotions.find(promo => promo.id == state.editPromoId);
    // },
    findPromoById: state => id => {
      return state.promotions.find(promo => promo.id == id);
    },

    findDeletedPromoById: state => id => {
      const promotion = state.deletedPromotions.find(promo => promo.id == id);

      return promotion;
    },
    findPromoIndexById: state => id => {
      return state.promotions.findIndex(
        promo => promo.id === id
      );
    },
    getImageFileById: state => id => {
      return state.fileImage[id];
    },

    /**
     * Find List of Promotion's name which uses the Product.
     * @return List of Promotion's name which uses the Product. Empty Array if not found.
     */
    getPromotionByProductId: state => productId => {
      let foundPromotionList = [];
      const promotions = state.promotions;
      // console.log('promotions', promotions)
      for (const promo of promotions) {
        const numProductsInRequired = !Array.isArray(promo.requiredProductList) ? 0 :
          promo.requiredProductList.filter(productInList => productId === productInList.id).length;
        const numProductsInReward = !Array.isArray(promo.rewardProductList) ? 0 :
          promo.rewardProductList.filter(productInList => productId === productInList.id).length;
        
        if ((numProductsInRequired > 0) || (numProductsInReward > 0)) {
          foundPromotionList.push({ id: promo.id, name: promo.name});
        } else {}
      };

      return foundPromotionList;
    },
  },
  actions: {
    // API
    /**
     * Load Promotions.
     * It loads Promotions from the sever and apply fileters.
     * @return {Object} Response object of a Promise.
     */
    async loadPromotions({ commit, rootGetters }) {
      const response = await getPromotionsApi();
      if (isResponseOK(response)) {
        let promotions = parsePromotions(response.data);

        // Update isValid status based on the Products in the List.
        for (let promo of promotions) {
          const validRequired = rootGetters["product/isProductListValid"](promo.requiredProductList);
          const validReward = rootGetters["product/isProductListValid"](promo.rewardProductList);
          promo.isValid = validRequired && validReward;
        }

        commit("setAllPromotions", promotions.filter(el => el.status != ENUM_STATUS_STRING.DELETED));
        commit("applyPromotionFilters");
      }
      return response;
    },
    /**
     * Get promotion detail by promotion id.
     * It load the detail of specific promotion by its id.
     * @return {Object} Response object of a Promise.
     */
    async getDeletedPromotion({ commit, rootGetters }, data) {
      const { id } = data;

      const response = await getOnePromotionApi(id);
      
      if (isResponseOK(response)) {
        let promotion = parsePromotion(response.data);

        // Update isValid status based on the Products of the promotion.
        const validRequired = rootGetters["product/isProductListValid"](promotion.requiredProductList);
        const validReward = rootGetters["product/isProductListValid"](promotion.rewardProductList);
        promotion.isValid = validRequired && validReward;

        commit("addOneDeletedPromotion", promotion);

        return promotion;
      }

      return null;
    },
    /**
     * Add a Promotion.
     * It adds a Promotion and selectable Products accordingly.
     * @param {Object} data Promotion object: Refer to TODO
     * @return {Object} Response object of a Promise.
     */
    async addOnePromotion({ commit, dispatch }, data) {
      // console.log('addOnePromotion', data);
      const { promoType, content, files, isStatusChanged } = data;
      const apiFunc = API_TYPE_MAP[promoType].create;

      // Add the image.
      // This step could be done in the component as well, but will need to change all the final step componenets for all different Promotion types.
      if (Array.isArray(files) && files.length > 0) {
        content.changedImages = files;
      }

      const response = await apiFunc(content);

      if (isResponseCREATED(response)) {
        // so what? It's already in the list -> reload with the main page.
        commit("cleanToDelete");

        if (isStatusChanged) {
          // Update status.
          await dispatch("updatePromotionStatus", { id: response.data.id, status: content.status });
        } else {
          commit("applyPromotionFilters");
        }
      }
      
      return response;
    },
    async updateOnePromotion({ commit, state, dispatch }, data) {
      const { promoType, content, files, isStatusChanged } = data;

      const apiFunc = API_TYPE_MAP[promoType].update;

      // TODO : Find better way.
      if (!content.deletedImages) {
        content.deletedImages = _.compact(state.imagesToDelete); //Test
      }

      // Add the image.
      // This step could be done in the component as well, but will need to change all the final step componenets for all different Promotion types.
      if (Array.isArray(files) && files.length > 0) {
        content.changedImages = files;
      }
      
      const response = await apiFunc(content.id, content);
      if (isResponseOK(response)) {
        // so what? It's already in the list -> reload with the main page.
        commit("cleanToDelete");

        if (isStatusChanged) {
          // Update status.
          await dispatch("updatePromotionStatus", { id: content.id, status: content.status });
        } else {
          commit("applyPromotionFilters");
        }
      }
      // console.log('updateOnePromotion', response);
      return response.status;
    },
    async updatePromotionStatus({ commit }, data) {
      const { id, status } = data;
      // console.log(id);
      // console.log(status);
      const response = await updatePromotionStatusApi(id, status);
      // console.log('updatePromotionStatus', response);
      if (isResponseOK(response)) {
        commit("updateStatus", { id, status });
        commit("applyPromotionFilters");
      } else {}
      return response.status;
    },
    async duplicatePromotion({ commit }, data) {
      const { promoType, content, files } = data;
      const fixedContent = _.pick(content, [
        // TODO: better to specify directly
      ])
      // TODO : images???

      const apiFunc = API_TYPE_MAP[promoType].create;
      const response = await apiFunc(fixedContent, files);
      if (isResponseOK(response)) {
        // so what? It's already in the list
      }
      return response.status;
    },
    // TODO : it is not asnyc cause error in return of promise deletePromotionApi
    async deleteOnePromotion({ commit }, id) {
      const response = await deletePromotionApi(id);

      if(isResponseOK(response)){
        commit("deleteOnePromotionById", id);
        commit("applyPromotionFilters");
      }
      
      return response;
    },
    /**
     * Uploads the promotion image and store the response in currentPromotionImage.name
     * @param {Object} localStore 
     * @param {Object} imageObject {file, preview} image to upload 
     */
    async updatePromotionImage({ commit }, imageObject) {
      const uploadedImages = await uploadImages([imageObject.file]);
      // Reduce the image size and store it as File
      await commit("setCurrentPromotionImage", {file: null, preview: imageObject.preview, name: uploadedImages[0]});
    },

    // Helpers
    removeImage({ commit }, imgUrl) {
      commit("removeImage", imgUrl);
    },
    setImageFile({ commit }, data) {
      const { id, imageFile } = data;
      commit("setFile", {
        id: id,
        file: imageFile,
      });
    },

    /**
     * Set image object of current promotion in the state.
     * @param {Objecr} image  Image object {file, preview}.
     */
    setCurrentPromotionImage({ commit }, image) {
      commit("setCurrentPromotionImage", image);
    },
    /**
     * Upate current Promotion in the state.
     * @param {Object} promo  Promotion object: Refer to MODEL_PROMOTION_READ.
     */
    updateCurrentPromotion({ commit }, promo) {
      commit("updateCurrentPromotion", promo)
    },
    /**
     * Reset currentPromotion in the state.
     */
    resetCurrentPromotion({ commit }) {
      commit("resetCurrentPromotion");
      commit("cleanToDelete");
    },
    
    // Step control
    increaseStep({ commit }) {
      commit("increaseStep")
    },
    resetStep({ commit }) {
      commit("resetStep")
    },

    // Filters
    /**
     * Apply filters.
     */
    applyPromotionFilters({ commit }) {
      commit("applyPromotionFilters");
    },
    /**
     * Set and apply filters.
     * @param {Object} filters Filters object.
     */
    setPromotionFilters({ commit }, filters) {
      commit("setPromotionFilters", filters);
      commit("applyPromotionFilters");
    },
    /**
     * Reset and apply filters.
     * @param {Object} [filters] Filters object.
     */
    resetPromotionFilters({ commit }, filters) {
      const emptyFilters = {
        store: { value: "", label: "" },
        order: { value: "", label: "" },
        type: { value: "", label: "" },
        keyword: "",
        ...filters,
      };
      commit("setPromotionFilters", emptyFilters);
      commit("applyPromotionFilters");
    },
    /**
     * Delete a filtered Promotion in the state.
     * @param {string} id Id of a Promotion.
     */
    deleteOneFilteredPromotion({ state, commit }, id) {
      const idx = state.filteredPromotions.findIndex(el => el.id == id);
      commit("deleteOneFilteredPromotion", idx);
    },

  }
};
