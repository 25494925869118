var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isNullOrUndefined(_vm.data) == false &&
    !_vm.isNullOrUndefined(_vm.data.taxAmount) &&
    _vm.data.taxAmount > 0
    ? _c(
        "b-row",
        {
          staticClass: "text-muted",
          attrs: { "data-cy": `applied-tax-${_vm.type}-${_vm.name}` },
        },
        [
          _c("b-col", [
            _c(
              "span",
              {
                staticClass: "ml-5",
                attrs: { "data-cy": `tax-type-${_vm.type}-${_vm.name}` },
              },
              [
                _vm._v(
                  _vm._s(_vm.data.taxType) +
                    " (" +
                    _vm._s(_vm.data.taxPercentage) +
                    "%)"
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("b-col", { staticClass: "order-item-price" }, [
            _c(
              "div",
              {
                staticClass: "text-right mr-5",
                attrs: { "data-cy": `tax-amount-${_vm.type}-${_vm.name}` },
              },
              [
                _vm._v(
                  "+ $" +
                    _vm._s(_vm.formatPriceWith2Decimal(_vm.data.taxAmount))
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }