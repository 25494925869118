const data = [
  {
    id: "dashboards",
    icon: "iconsminds-shop-4",
    label: "menu.dashboards",
    subs: [
      {
        icon: "simple-icon-briefcase",
        label: "menu.merchant-dashboard",
        to: "/app/dashboards/merchant-dashboard"
      },
      {
        icon: "simple-icon-briefcase",
        label: "menu.sales-dashboard",
        to: "/app/dashboards/sales-dashboard"
      },
    ]
  },
  {
    id: "setup",
    icon: "iconsminds-digital-drawing",
    label: "menu.setup",
    subs: [
      {
        id: "setup.business-type",
        icon: "simple-icon-notebook",
        label: "menu.business-type",
        to: { name: "business-type" },
      },
      {
        id: "setup.business-location",
        icon: "simple-icon-map",
        label: "menu.business-location",
        to: { name: "business-location" },
      },
      {
        id: "setup.global-points",
        icon: "simple-icon-star",
        label: "menu.global-points",
        to: { name: "global-points" }
      },
      {
        id: "setup.payment",
        icon: "simple-icon-credit-card",
        label: "menu.payment",
        to: { name: "payment" }
      },
      {
        id: "setup.social-media-accounts",
        icon: "simple-icon-picture",
        label: "menu.social-media-accounts",
        to: { name: "social-media-accounts" }
      },
      {
        id: "setup.subscriptions",
        icon: "simple-icon-energy",
        label: "menu.subscriptions",
        to: { name: "subscriptions" }
      }
    ]
  },
  {
    id: "products",
    icon: "iconsminds-mine",
    label: "menu.products",
    subs: [
      {
        id: "products.product-groups",
        icon: "simple-icon-notebook",
        label: "menu.product-groups",
        to: { name: "product-groups" },
      },
      {
        id: "products.products",
        icon: "simple-icon-list",
        label: "menu.products",
        to: { name: 'products' },
      },
      {
        id: "products.addon-groups",
        icon: "iconsminds-box-close",
        label: "menu.addon-groups",
        to: { name: "addon-groups" },
      },
      {
        id: "products.addons",
        icon: "iconsminds-box-with-folders",
        label: "menu.addons",
        to: { name: "addons" },
      },
    ]
  },
  {
    id: "promotions",
    icon: "iconsminds-gift-box",
    label: "menu.promotions",
    subs: [
      {
        id: "promotions.promotions-main",
        icon: "simple-icon-badge",
        label: "menu.promotions-main",
        to: { name: "promotions-main" },
      },
      {
        id: "promotions.new-perk",
        icon: "iconsminds-add",
        label: "menu.promotions-new",
        to: { name: "promotions-new" },
      },
      {
        id: "promotions.rewards",
        icon: "iconsminds-crown-2",
        label: "menu.promotions-reward",
        to: { name: "promotions-rewards" },
      },
    ]
  },
  {
    id: "social-media-posting",
    icon: "simple-icon-picture",
    label: "menu.social-media-posting",
    subs: [
      {
        id: "social-media-posting.your-posts",
        icon: "simple-icon-picture",
        label: "menu.your-posts",
        to: { name: "your-posts" },
      },
      {
        id: "social-media-posting.ai-post-generator",
        icon: "iconsminds-brain",
        label: "menu.ai-post-generator",
        to: { name: "ai-post-generator" },
      },
    ]
  },
  {
    id: "report",
    icon: "iconsminds-statistic",
    label: "menu.report",
    subs: [
      {
        id: "report.order-history",
        icon: "simple-icon-notebook",
        label: "menu.order-history",
        to: { name: "order-history" },
      },
    ]
  },
  {
    id: "support",
    icon: "simple-icon-support",
    label: "menu.support",
    subs: [
      {
        id: "support.default",
        icon: "simple-icon-support",
        label: "menu.support",
        to: { name: "support-main" },
      },
    ]
  }
];
export default data;
