var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.data, function (post, index) {
      return _c(
        "b-badge",
        {
          key: index,
          staticClass: "mb-2 ml-2",
          attrs: { variant: "info", href: post.postUrl, target: "_blank" },
        },
        [_vm._v("\n    " + _vm._s(post.platform) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }