var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { attrs: { "align-v": "center" } },
        [
          _c(
            "b-colxx",
            { attrs: { xxs: "2" } },
            [
              _c("toggle-button-check", {
                attrs: { name: "time-limit", disabled: _vm.disabled },
                model: {
                  value: _vm.isTimeLimited,
                  callback: function ($$v) {
                    _vm.isTimeLimited = $$v
                  },
                  expression: "isTimeLimited",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-colxx", [
            _vm.isReward
              ? _c("h6", [
                  _vm._v(
                    _vm._s(`${_vm.$t("promotions.reward-limits-time-title")}`)
                  ),
                ])
              : _c("h6", [
                  _vm._v(_vm._s(`${_vm.$t("promotions.limits-time-title")}`)),
                ]),
          ]),
          _vm._v(" "),
          _c(
            "b-colxx",
            { attrs: { xxs: "12" } },
            [
              _c("p", { staticClass: "text-muted text-small" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("promotions.limits-time-description")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _vm.isTimeLimited
                ? [
                    _c(
                      "b-form-group",
                      [
                        !_vm.disabled
                          ? _c("v-date-picker", {
                              attrs: {
                                "data-cy": "time-limit-date-picker",
                                mode: "range",
                                "input-props": {
                                  "data-cy": "time-limit-date-picker-input",
                                  class: "form-control",
                                  placeholder: _vm.$t(
                                    "form-components.date-range"
                                  ),
                                },
                              },
                              model: {
                                value: _vm.selectedValueRange,
                                callback: function ($$v) {
                                  _vm.selectedValueRange = $$v
                                },
                                expression: "selectedValueRange",
                              },
                            })
                          : _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                "data-cy": "time-limit-date-picker-input",
                                placeholder: _vm.$t(
                                  "form-components.date-range"
                                ),
                                disabled: "",
                              },
                              domProps: { value: _vm.formattedDateRange },
                            }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }