var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "card-order mt-0 mb-3 p-0",
      attrs: { "data-cy": `${_vm.cyPrefix}` },
      on: { click: _vm.onClickCard },
    },
    [
      _c(
        "router-link",
        { attrs: { to: _vm.data.id, replace: "", id: _vm.data.id } },
        [
          _c(
            "p",
            {
              staticClass: "text-center text-semi-large font-weight-bold",
              attrs: { "data-cy": `${_vm.cyPrefix}-paid-at` },
            },
            [_vm._v(_vm._s(_vm.formatLongDateWithTime(_vm.paidAt)))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "text-center order-grid" }, [
            _c("div", [
              _c(
                "h1",
                {
                  staticClass: "m-0 font-weight-bold",
                  attrs: { "data-cy": `${_vm.cyPrefix}-order-number` },
                },
                [_vm._v("\n        " + _vm._s(_vm.idLast4Digits) + "\n      ")]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "h4",
                {
                  staticClass: "m-0",
                  attrs: { "data-cy": `${_vm.cyPrefix}-number-of-items` },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.numberOfItems) +
                      " " +
                      _vm._s(_vm.$t("order.items")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "h4",
                {
                  staticClass: "m-0",
                  attrs: { "data-cy": `${_vm.cyPrefix}-total-amount` },
                },
                [_vm._v("\n        $" + _vm._s(_vm.totalAmount) + "\n      ")]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "p",
                {
                  staticClass: "text-medium text-muted m-0",
                  attrs: { "data-cy": `${_vm.cyPrefix}-last-updated` },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.lastUpdated) +
                      " " +
                      _vm._s(_vm.$t("order.ago")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "p",
                {
                  staticClass: "text-medium m-0",
                  attrs: { "data-cy": `${_vm.cyPrefix}-serve-type` },
                },
                [_vm._v("\n        " + _vm._s(_vm.serveType) + "\n      ")]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "h4",
                {
                  staticClass: "text-order-new font-weight-bold m-0",
                  attrs: { "data-cy": `${_vm.cyPrefix}-serve-status` },
                },
                [_vm._v("\n        " + _vm._s(_vm.serveStatus) + "\n      ")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }