var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("dialog-name-description", {
        attrs: {
          name: "dlg-category",
          title: _vm.$t("product.add-new-category"),
          dialog: _vm.dialogs.category,
        },
        on: {
          "dialog-close": _vm.closeDialogCategory,
          "dialog-submit": _vm.onSubmitDialogCategory,
        },
      }),
      _vm._v(" "),
      _c(
        "b-form-group",
        [
          !_vm.disabled
            ? _c("h6", [
                _vm._v(_vm._s(_vm.$t("product.choose-product-category"))),
              ])
            : _c("h6", [_vm._v(_vm._s(_vm.$t("product.product-category")))]),
          _vm._v(" "),
          _c(
            "b-row",
            { attrs: { "align-v": "center", "no-gutters": "" } },
            [
              _c(
                "b-colxx",
                { attrs: { sm: _vm.colSize } },
                [
                  !_vm.disabled
                    ? _c(
                        "v-select",
                        {
                          attrs: {
                            "data-cy": `${_vm.name}-select-category`,
                            options: _vm.currentSelOptions,
                          },
                          on: { input: _vm.onSelectItem },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "search",
                                fn: function ({ attributes, events }) {
                                  return [
                                    _c(
                                      "input",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "vs__search",
                                            attrs: {
                                              "data-cy": `${_vm.name}-select-category-input`,
                                            },
                                          },
                                          "input",
                                          attributes,
                                          false
                                        ),
                                        events
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1394061118
                          ),
                          model: {
                            value: _vm.selectedCategory,
                            callback: function ($$v) {
                              _vm.selectedCategory = $$v
                            },
                            expression: "selectedCategory",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("validations.no-category-available")
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.disabled
                ? _c(
                    "b-colxx",
                    { attrs: { sm: "2" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "cls-btn-add",
                          attrs: {
                            "data-cy": `${_vm.name}-btn-add-category`,
                            variant: "outline-primary",
                            size: "xs",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openDialogCategory()
                            },
                          },
                        },
                        [
                          _c("b-img", {
                            attrs: {
                              src: "/assets/icons/plus.svg",
                              widht: "12",
                              height: "12",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { attrs: { "align-v": "center", "no-gutters": "" } },
            [
              _vm._l(_vm.currentCategories, function (category, index) {
                return _c(
                  "b-badge",
                  {
                    key: category,
                    staticClass: "mb-1 cls-badge",
                    attrs: { pill: "", variant: "outline-dark" },
                  },
                  [
                    _vm._v("\n        " + _vm._s(category) + " \n        "),
                    !_vm.disabled
                      ? _c(
                          "a",
                          {
                            attrs: {
                              "data-cy": `${_vm.name}-badge-category-${index}-delete`,
                              href: "javascript:void(0);",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteCategory(index)
                              },
                            },
                          },
                          [_vm._v("x")]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.currentCategories.length == 0 && _vm.disabled
                ? _c(
                    "b-badge",
                    {
                      staticClass: "mb-1 cls-badge",
                      attrs: { pill: "", variant: "outline-dark" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.none")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }