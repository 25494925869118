// Componet for selecting one Store.
// Author : Huen Oh (heons921@gmail.com)
// @param {string}    name        Name of the component. Index in usual. (in)
// @param {bool}      disabled    Disabled property of the component. (in)
// @param {number}    colSize     Size of the column for the form. : 1 ~ 12. (in)
// @param {boolean}   isAll       To display -All- option or not. (in)


<template>
<div>  
  <!-- Store Selection -->
  <b-form-group>
    <!-- <h6>{{$t('product.choose-product-category')}}</h6> -->
    <b-row align-v="center" no-gutters>
      <b-colxx :sm="colSize">
        <v-select 
          :data-cy="`${name}-select-one-store`"
          :options="currentSelOptions" 
          v-model="selectedStore" 
          @input="onClick"
          v-if="!disabled"
        >
          <span slot="no-options">
            {{$t('validations.no-store-available')}}
          </span>

          <template #search="{ attributes, events }">
            <input
              :data-cy="`${name}-select-one-store-input`"
              class="vs__search"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
      </b-colxx>
    </b-row>
  </b-form-group>

</div>
</template>


<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import { mapGetters } from 'vuex'

// TODO: Compare it with CheckSelectStores. The functions can be shared somehow.
export default {
  props:{
    // {string} Name of the component. Index in usual.
    name: {
      type: String,
      default: ""
    },
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {string} Size of the column for the form. : 1 ~ 12.
    colSize: {
      type: Number,
      default: 10,
    },
    // {boolean} To display -All- option or not.
    isAll:{
      type: Boolean,
      default: true,
    },
  },
  components: {
    'v-select': vSelect,
  },
  data () {
    return {
      selectedStore: null,
    }
  },
  computed:{
    // Map gatters
    ...mapGetters("merchant_store",   ["selectableStores"]),
    ...mapGetters("order",            ["currentStore"]),

    // Computed value to exclude selected items from the selection option.
    currentSelOptions: function() {
      if (this.isAll) {
        return this.selectableStores;
      } else {
        return this.selectableStores.filter((item) => {
          return item.value !== "";
        });
      }
    },
  },
  mounted () {
  },
  methods:{
    resetSelection() {
      this.selectedStore = null;
      this.$emit('click', this.selectedStore);
    },
    /**
     * On click the button.
     */
    onClick(filter) {
      this.$emit('click', filter);
    }
  }

}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>

