/**
 * It contains models of Promotion objects.
 * 
 * Types : PUNCH_CARD, DEAL, REWARD_ITEM, POINT_PERK.
 */

// General parse and serialize function
import { parse, serialize } from "@/models/general.js";

import { isNullOrUndefined } from "@/utils/general";

// Type of Promotions.
export const TYPE_PROMOTION = {
  PUNCH_CARD:         "PUNCH_CARD",
  PUNCH_CARD_REDEEM:  "PUNCH_CARD_REDEEM",
  REWARD_ITEM:        "REWARD_ITEM",
  POINT_PERK:         "POINT_PERK",
  DEAL:               "DEAL",
}

export const TYPE_PROMOTION_LOWERCASE = {
  PUNCH_CARD:         "punch-card",
  PUNCH_CARD_REDEEM:  "punch-card-redeem",
  REWARD_ITEM:        "reward-item",
  POINT_PERK:         "point-perk",
  DEAL:               "deal",
}

// Type of deals.
// TODO : change to upper case.
export const TYPE_DEAL = {
  DEAL_BUY_ONE_GET_ONE:   "deal-buy-one-get-one",   // Buy x Get discount on x items.
  DEAL_FREE_ITEM:         "deal-free-item",         // Free item.
  DEAL_DISCOUNT_ITEMS_P:  "deal-discount-items-p",  // Discount for items with percent.
  DEAL_DISCOUNT_CART_P:   "deal-discount-cart-p",   // Discount in a cart with percent.
  DEAL_DISCOUNT_CART_F:   "deal-discount-cart-f",   // Discount in a cart with fixed amount.
  DEAL_MULTI_COMBO:       "deal-multi-combo",       // Combo.
  DEAL_MULTI_BUNDLE:      "deal-multi-bundle",      // Bundle.
}

/**
 * PunchCard Model - To write.
 */
export const MODEL_PUNCH_CARD_WRITE = {
  name: "",                 // {string}     Name of the PunchCard, required.
  description: "",          // {string}     Description of the PunchCard, required.
  status: "",               // {string}     Status of the promotion
  changedImages: [],        // {[string[]]} URLs of changed images.
  deletedImages: [],        // {[string[]]} URLs of deleted images.
  isAddonCharged: false,    // {bool}       Option to charge Addon, (true, false), required.
  isMaster: null,           // {[bool]}     Option to be master, (null:no exclusive(default), true:master, false:exclusive).
  availableFrom: {},        // {[Date]}     The PunchCard is available from this date.
  availableTo: {},          // {[Date]}     The PunchCard is available to this date.
  displayTime: [],          // {[Object[]]} The PunchCard is displayed in this time only, Object:{day, fromTime, toTime}.
  stores: [],               // {string[]}   Ids of Stores, required.
  requiredProductList: [],  // {Object[]}   Reference object to required Products, required, refer to MODEL_PRODUCT_IN_LIST.
  rewardProductList: [],    // {Object[]}   Reference object to reward Products, required, refer to MODEL_PRODUCT_IN_LIST.

  requiredPunches: 1,       // {number}     Number of required punches, required(PUNCH_CARD).
  rewardItems: 1,           // {number}     Number of reward items, required(PUNCH_CARD).
  rewardPoints: null,       // {[number]}   Reward points.
};

/**
 * PointPerk Model - To write.
 */
export const MODEL_POINT_PERK_WRITE = {
  name: "",                 // {string}     Name of the PointPerk, required.
  description: "",          // {string}     Description of the PointPerk, required.
  status: "",               // {string}     Status of the promotion
  changedImages: [],        // {[string[]]} URLs of changed images.
  deletedImages: [],        // {[string[]]} URLs of deleted images.
  isAddonCharged: false,    // {bool}       Option to charge Addon, (true, false), required.
  isMaster: null,           // {[bool]}     Option to be master, (null:no exclusive(default), true:master, false:exclusive).
  availableFrom: {},        // {[Date]}     The PointPerk is available from this date.
  availableTo: {},          // {[Date]}     The PointPerk is available to this date.
  displayTime: [],          // {[Object[]]} The PointPerk is displayed in this time only, Object:{day, fromTime, toTime}.
  stores: [],               // {string[]}   Ids of Stores, required.
  requiredProductList: [], // {Object[]}   Reference object to required Products, required, refer to MODEL_PRODUCT_IN_LIST.
  rewardProductList: [],   // {Object[]}   Reference object to reward Products, required, refer to MODEL_PRODUCT_IN_LIST.

  rewardPoints: 1,          // {number}     Reward Points, required(POINT_PERK).
  maxRedeem: null,          // {[number]}   Number of maximum redeem, (null:unlimited).
  limitedStock: null,       // {[number]}   Number of limited stock, (null:unlimited).
  isNewClient: null,        // {[bool]}     Options to limit client, (null:any(default), true:new, false:returning).
};

/**
 * RewardItem Model - To write.
 */
export const MODEL_REWARD_ITEM_WRITE = {
  name: "",                 // {string}     Name of the RewardItem, required.
  description: "",          // {string}     Description of the RewardItem, required.
  status: "",               // {string}     Status of the promotion
  changedImages: [],        // {[string[]]} URLs of changed images.
  deletedImages: [],        // {[string[]]} URLs of deleted images.
  isAddonCharged: false,    // {bool}       Option to charge Addon, (true, false), required.
  isMaster: null,           // {[bool]}     Option to be master, (null:no exclusive(default), true:master, false:exclusive).
  availableFrom: {},        // {[Date]}     The RewardItem is available from this date.
  availableTo: {},          // {[Date]}     The RewardItem is available to this date.
  displayTime: [],          // {[Object[]]} The RewardItem is displayed in this time only, Object:{day, fromTime, toTime}.
  stores: [],               // {string[]}   Ids of Stores, required.
  requiredProductList: [],  // {Object[]}   Reference object to required Products, required, refer to MODEL_PRODUCT_IN_LIST.
  rewardProductList: [],    // {Object[]}   Reference object to reward Products, required, refer to MODEL_PRODUCT_IN_LIST.

  requiredPoints: 1,        // {number}     Required Points, required(REWARD_ITEM).
};

//TODO : conditions for type of deals.
/**
 * DEAL Model - To write.
 */
export const MODEL_DEAL_WRITE = {
  name: "",                 // {string}     Name of the Deal, required.
  description: "",          // {string}     Description of the Deal, required.
  status: "",               // {string}     Status of the promotion
  changedImages: [],        // {[string[]]} URLs of changed images.
  deletedImages: [],        // {[string[]]} URLs of deleted images.
  isAddonCharged: false,    // {bool}       Option to charge Addon, (true, false), required.
  isMaster: null,           // {[bool]}     Option to be master, (null:no exclusive(default), true:master, false:exclusive).
  availableFrom: {},        // {[Date]}     The Deal is available from this date.
  availableTo: {},          // {[Date]}     The Deal is available to this date.
  displayTime: [],          // {[Object[]]} The Deal is displayed in this time only, Object:{day, fromTime, toTime}.
  stores: [],               // {string[]}   Ids of Stores, required.
  requiredProductList: [],  // {Object[]}   Reference object to required Products, required, refer to MODEL_PRODUCT_IN_LIST.
  rewardProductList: [],    // {Object[]}   Reference object to reward Products, required, refer to MODEL_PRODUCT_IN_LIST.

  couponCode: "",           // {[string]}   Coupon for deals.
  discountPercent: null,    // {[number]}   Discount percent.
  discountAmount: null,     // {[number]}   Discount amount, fixed.
  fixedAmount: null,        // {[number]}   Fixed amount - Flat Price.
  requiredItems: null,      // {[number]}   Number of required items.
  requiredAmount: 0,        // {number}     Required amount to appy the deal, required(DEAL).
  maxRedeem: null,          // {[number]}   Number of maximum redeem, (null:unlimited).
  limitedStock: null,       // {[number]}   Number of limited stock, (null:unlimited).
  isNewClient: null,        // {[bool]}     Options to limit client, (null:any(default), true:new, false:returning).
  isPublicAvailable: true,  // {bool}       The promotion is available in public if it is true, required(DEAL).
  highlightAmount: null,  	// {[bool]}     Highlight the Deal if price in the cart is above this value, (null/0:no-highlight(default)).
  highlightInProductList: null, // {number[]=null} Group Index to highlight. (null:no-highlight(default)).
};

/**
 * Promotion Model in general - To update.
 */
export const MODEL_PROMOTION_GENERAL_UPDATE = {
  id: "",             // {string}     Id of the Promotion
  name: "",           // {string}     Name of the Promotion, required.
  description: "",    // {string}     Description of the Promotion, required.
  deletedImages: [],  // {[string[]]} URLs of deleted images.
  images: [],         // {[string[]]} URLs of images. The first image is the default.
  stores: [],         // {string[]}   Ids of Stores, required.
}

/**
 * Promotion Model - To read from server.
 */
export const MODEL_PROMOTION_READ = {
  id: "",                   // {string}     Id of the Promotion
  type: "",                 // {string}     Types of the Promotion: PUNCH_CARD, DEAL, REWARD_ITEM, POINT_PERK
  name: "",                 // {string}     Name of the Promotion, required.
  description: "",          // {string}     Description of the Promotion, required.
  images: [],               // {[string[]]} URLs of images. The first image is the default.
  qrCode: "",               // {string}     QR code of the promotion.
  couponCode: "",           // {[string]}   Coupon for deals.
  requiredProductList: [],  // {Object[]}   Reference object to required Products, required, refer to MODEL_PRODUCT_IN_LIST.
  rewardProductList: [],    // {Object[]}   Reference object to reward Products, required, refer to MODEL_PRODUCT_IN_LIST.
  requiredPunches: 0,       // {number}     Number of required punches, required(PUNCH_CARD).
  requiredItems: 1,         // {number}     Number of required items.
  requiredAmount: 0,        // {number}     Required amount to appy the deal, required(DEAL).
  requiredPoints: 1,        // {number}     Required Points, required(REWARD_ITEM).
  rewardItems: 0,           // {number}     Number of reward items, required(PUNCH_CARD).
  rewardPoints: null,       // {number}     Reward Points, required(POINT_PERK).
  discountPercent: null,    // {[number]}   Discount percent.
  discountAmount: null,     // {[number]}   Discount amount, fixed.
  fixedAmount: null,        // {[number]}   Fixed amount - Flat Price.
  isAddonCharged: false,    // {bool}       Option to charge Addon, (true, false), required.
  availableFrom: {},        // {[Date]}     The Promotion is available from this date.
  availableTo: {},          // {[Date]}     The Promotion is available to this date.
  displayTime: [],          // {[Object[]]} The Promotion is displayed in this time only, Object:{day, fromTime, toTime}.
  isPublicAvailable: true,  // {bool}       The promotion is available in public if it is true.
  maxRedeem: null,          // {[number]}   Number of maximum redeem, (null:unlimited).
  limitedStock: null,       // {[number]}   Number of limited stock, (null:unlimited).
  isMaster: null,           // {[bool]}     Option to be master, (null:no exclusive(default), true:master, false:exclusive).
  isNewClient: null,        // {[bool]}     Options to limit client, (null:any(default), true:new, false:returning).
  highlightAmount: 0,       // {[bool]}     Highlight the Deal if price in the cart is above this value, (null/0:no-highlight(default)).
  highlightInProductList: null, // {number[]=null} Group Index to highlight. (null:no-highlight(default)).
  // TODO: buy one get one free -> highlight item1 or item2? e.g. highlightItem: index of requiredItems[[]].
  // TODO: buy one get one free -> discount Percent item1, item2, manual/auto discountPercent[] or discountPercentRequired, isAutoDiscount
  // TODO: Percent discount on cart -> need max amount, discountAmount?
  // TODO: Combo deal -> item1, item2, item3...? make required as [[]] and use index to highlightItems
  merchantId: 0,          // {string}     Id of the merchant.
  stores: [],             // {string[]}   Ids of Stores, required.
  status: "",             // {string}     Status of the Promotion, (INACTIVE, ACTIVE, DELETED, REDEEMED). Refer to ENUM_STATUS_STRING.

  // Local use
  isValid: false,         // {boolean} To check if the Promotion is valid. e.g. Products in requiredProductList and rewardProductList are not DELETED.
};


/**
 * Get Type of a Deal.
 * It assumes promotion.type === 'DEAL'.
 * @param {Object} promotion Promotion object: Refer to MODEL_PROMOTION_READ.
 * @return {string} Type of Deal.
 */
export function getDealType(promotion) {

  if (promotion.requiredProductList.length === 0) {
    if (promotion.rewardProductList.length === 0) {
      // On Cart
      if ((promotion.discountPercent)
        && (promotion.discountPercent !== 0)) {
        // Percent
        return TYPE_DEAL.DEAL_DISCOUNT_CART_P;
      } else {
        // Fxied
        return TYPE_DEAL.DEAL_DISCOUNT_CART_F;
      }
    } else {
      // On Items
      if ((!promotion.discountPercent)
        || (promotion.discountPercent === 0)) {
        // TODO : it is temporary default value. -> some unknown
        return TYPE_DEAL.DEAL_DISCOUNT_ITEMS_P;
      } else {
        if (promotion.discountPercent === 100) {
          return TYPE_DEAL.DEAL_FREE_ITEM;
        } else {
          return TYPE_DEAL.DEAL_DISCOUNT_ITEMS_P;
        }
      }
    }
  } else {
    // TODO : update it. with num rewardProduct.

    // Num of group is 1 : requiredProductList, rewardProductList
    // Get number of group in requiredProductList
    const numGroupInRequired = _.uniq(
      promotion
        .requiredProductList
        .map(product => product.groupIndex)
    );

    // Get number of group in rewardProductList
    const numGroupInReward = _.uniq(
      promotion
        .rewardProductList
        .map(product => product.groupIndex)
    );

    if ((numGroupInRequired.length === 1) && (numGroupInReward.length === 1)) {
      return TYPE_DEAL.DEAL_BUY_ONE_GET_ONE;
    } else {
      // Others : TODO
      // TODO : it is temporary default value. -> combo or bundle.
      if (promotion.fixedAmount) {
        return TYPE_DEAL.DEAL_MULTI_BUNDLE;
      } else {
        return TYPE_DEAL.DEAL_MULTI_COMBO;
      }
      
    }    
  }
}

/**
 * Parse Promotion objects.
 * @param {Object[]} promotions Promotion objects: Refer to MODEL_PROMOTION.
 * @return {Object} Prased Promotion object.
 */
export function parsePromotions(promotions) {
  promotions.forEach((promotion) => {
    if (promotion.type === 'DEAL') {
      promotion.typeDeal = getDealType(promotion);
    } else { }
    promotion.isValid = true;
  });
  return promotions;
  // TODO
  // return parse(promotion, MODEL_PROMOTION);
}

/**
 * Parse a Promotion object.
 * @param {Object} promotion Promotion object: Refer to MODEL_PROMOTION.
 * @return {Object} Prased Promotion object.
 */
export function parsePromotion(promotion) {
  if (promotion.type === 'DEAL') {
    promotion.typeDeal = getDealType(promotion);
  }
  return promotion;
  // TODO
  // return parse(promotion, MODEL_PROMOTION);
}

/**
 * Serialize a Promotion object.
 * @param {Object} promotion Promotion object: Refer to MODEL_PROMOTION
 * @return {Object} Serialized Promotion object.
 */
export function serializePromotion(promotion) {
  return promotion;
  // TODO
  // return serialize(promotion, MODEL_PROMOTION);
}

/**
 * To convert promotion type lower case to promotionType upper case
 * @param {} promotionType 
 */
export function convertPromotionTypeUpperCase(promotionType){
  if(isNullOrUndefined(promotionType)){
    return "";
  }

  switch(promotionType){
    case TYPE_PROMOTION_LOWERCASE.PUNCH_CARD:
       return TYPE_PROMOTION.PUNCH_CARD;
    case TYPE_PROMOTION_LOWERCASE.POINT_PERK:
      return TYPE_PROMOTION.POINT_PERK;
    case TYPE_PROMOTION_LOWERCASE.REWARD_ITEM:
      return TYPE_PROMOTION.REWARD_ITEM;
    default:
      return TYPE_PROMOTION.DEAL;
  }
}