var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("toggle-button", {
    attrs: {
      "data-cy": `${_vm.name}-toggle-btn-check`,
      color: "#87bcbf",
      labels: { checked: "v", unchecked: "x" },
      value: _vm.value,
      sync: true,
      width: 40,
      disabled: _vm.disabled,
    },
    on: { change: _vm.onChangeSwitch },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }