/**
 * @fileoverview It create aixos for the INTRA_SERVICE_MERCHANT_DASHBOARD service in the application.
 * @author Huen Oh (heons921@gmail.com)
 */

// Constant.
import { INTRA_SERVICE_MERCHANT_DASHBOARD } from "@/constants/config.js";

// Modules in the application.
import { axios_base, interceptResponseSuccess, interceptResponseFail } from "./axios_base";
import LocalStorageService  from "@/utils/local_store.js";

const axios_merchant_dashboard = axios_base.create();

/**
 * Intercept the request to set additional headers.
 */
axios_merchant_dashboard.interceptors.request.use(
  config => {
    const token = LocalStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers[`intra-service-name`] = INTRA_SERVICE_MERCHANT_DASHBOARD;
    } // else
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

/**
 * Intercept function for the response of axios when it's failed.
 * @param {Object} error Error object from axios.
 * @return {Promise} Promise object.
 */
const customInterceptResponseFail = function (error) {
  return interceptResponseFail(error, axios_merchant_dashboard);
}

/**
 * Intercept the response.
 */
axios_merchant_dashboard.interceptors.response.use(
  interceptResponseSuccess,
  customInterceptResponseFail
);

export default axios_merchant_dashboard;
