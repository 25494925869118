// Component for Dialog Alert.
//
// @param {string}  description Description of Alert.
// @param {boolean} dialog      Handler variable for the dialog(Close and Open).

<template>
  <div class="dialog-alert">
    <!-- Aler dialog -->
    <b-modal id="modalAlert" ref="modalAlert" size="sm" @hide="onCencel()" :hide-header="actionType==''" :title="actionType">  
      <p class="markdown-body" v-html="description"></p>
      <template slot="modal-footer">
        <b-button 
          data-cy="dlg-alert-btn-ok"
          variant="primary" 
          @click="onSubmit()" 
          class="mr-1">
          {{ buttonSubmitText }}
        </b-button>
        <b-button 
          data-cy="dlg-alert-btn-cancel"
          variant="outline-secondary" 
          @click="onCencel()"
        >{{ $t('buttons.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>


export default {
  components:{
  },
    
  props: {
    // {string} Type of action: Add/Edit/Delete
    actionType:{
      type:String,
      default:""      
    },
    // {string} Description of Alert
    description:{
      type:String,
      default: "",
    },
    // {String} The text displayed in the submit button
    buttonSubmitText:{
      type: String,
      default: "Submit"
    },
    // {boolean} Handler variable for the dialog(Close and Open).
    dialog:{
      type:Boolean,
      default: false,
    },
  },
  data(){
    return{
      data: "",
    }
  },

  methods: {
    /**
     * On Cencel button : Close the dialog
     */
    onCencel() {
      this.$emit('dialog-close');
    },
    /**
     * On Submit button : Save the data and Close the dialog
     */
    onSubmit() {
      // console.log("onSubmit");
      // console.log(this.data);
      this.$emit('dialog-submit');
      this.$emit('dialog-close');
    },
  },

  watch: {
    // Control of the dialog: Show, Hide
    dialog: function(newVal, oldVal){
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if(newVal == true) {
        this.$refs["modalAlert"].show();
      } else {
        this.$refs["modalAlert"].hide();
      }
    }
  },

  mounted(){ 
  }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>