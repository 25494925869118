/**
 * It contains model of Merchant Profile object.
 */

// import { parse, serialize } from "../general.js";

/**
 * Model for Merchant Profile.
 */
const MODEL_MERCHANT_PROFILE = {
  // id:"",           // {string}   Id.(number in the server).
  // merchantId:, ""  // {string}   Merchant Id.(number in the server).
  name: "",           // {string}   Name.
  description: "",    // {string}   Description.
  logoUrl: "",        // {string}   Url of Logo image.
  imagesUrl: [],      // {string[]} Urls of Images.
  phone: "",          // {string}   Phone number.
  email: "",          // {string}   Email.
  website: "",        // {string}   Url of the website.
  businessLines: [],  // {string[]} Categories for the business.
  address: {},        // {Object}   Address: Refer to MODEL_ADDRESS.
  stores: [],         // {Object[]} Merchant Stores: Refer to MODEL_MERCHANT_STORE.
  ayrshareKey:"",     // {string} Ayrshare Profile key
  stripeCustomerId:"", // {string} Stripe Customer Id
};

export const businessTypes = [
  {
    id: 0,
    title: "Digital marketing",
    img: "/assets/img/digital_marketing.svg",
  },
  {
    id: 1,
    title: "Food/Beverage",
    img: "/assets/img/FoodBeverage.svg",
  },
  {
    id: 2,
    title: "Clothing",
    img: "/assets/img/hanger.svg",
  },
  {
    id: 3,
    title: "Automotive",
    img: "/assets/img/car.svg",
  },
  {
    id: 4,
    title: "Beauty",
    img: "/assets/img/lotus.svg",
  },
  {
    id: 5,
    title: "Entertainment",
    img: "/assets/img/ticket.svg",
  },
  {
    id: 6,
    title: "Pet Store",
    img: "/assets/img/track.svg",
  },
  {
    id: 7,
    title: "Sports and Fitness",
    img: "/assets/img/sport.svg",
  },
  {
    id: 8,
    title: "Toy Store",
    img: "/assets/img/toys.svg",
  },
  {
    id: 9,
    title: "Studios",
    img: "/assets/img/artist.svg",
  },
  {
    id: 10,
    title: "Retails",
    img: "/assets/img/shop-bag.svg",
  },
];

export const businessCategoriesWithTypes = {
  "Food/Beverage" : [
    {
      id: 0,
      title: "Restaurant",
      img: "/assets/img/business_category_restaurant.svg"
    },
    {
      id: 1,
      title: "Hamburger",
      img: "/assets/img/business_category_hamburger.svg"
    },
    {
      id: 2,
      title: "Pizza",
      img: "/assets/img/business_category_pizza.svg"
    },
    {
      id: 3,
      title: "Grill",
      img: "/assets/img/business_category_grill.svg"
    },
    {
      id: 4,
      title: "Seafood",
      img: "/assets/img/business_category_seafood.svg"
    },
    {
      id: 5,
      title: "Coffee Shop",
      img: "/assets/img/business_category_coffee_house.svg"
    },
    {
      id: 6,
      title: "Bar",
      img: "/assets/img/business_category_bar.svg"
    },
    {
      id: 7,
      title: "Bakery",
      img: "/assets/img/business_category_bakery.png"
    },
    {
      id: 8,
      title: "Ice Cream/Forzen Yogurt",
      img: "/assets/img/business_category_ice_cream.png"
    },
    {
      id: 9,
      title: "Shawarma",
      img: "/assets/img/shawarma_logo.svg"
    },
  ],
  "Clothing": [
    {
      id: 0,
      title: "Men's Clothing",
      img: "/assets/img/hanger.svg"
    },
    {
      id: 1,
      title: "Women's Clothing",
      img: "/assets/img/hanger.svg"
    },
    {
      id: 2,
      title: "Kid's Clothing",
      img: "/assets/img/hanger.svg"
    },
    {
      id: 3,
      title: "Casual Wear",
      img: "/assets/img/hanger.svg"
    },
    {
      id: 4,
      title: "Formal Wear",
      img: "/assets/img/hanger.svg"
    },
    {
      id: 5,
      title: "Business Attire",
      img: "/assets/img/hanger.svg"
    }

  ],
  "Automotive": [
    {
      id: 0,
      title: "Car Repair",
      img: "/assets/img/car_repair_logo.svg"
    },
    {
      id: 1,
      title: "Car Parts ",
      img: "/assets/img/car_parts_logo.svg"
    },
    {
      id: 2,
      title: "Car Dealership",
      img: "/assets/img/car_dealership_logo.svg"
    }
  ],
  "Beauty": [
    {
      id: 0,
      title: "Spa",
      img: "/assets/img/stones.svg"
    },
    {
      id: 1,
      title: "Nail Salon",
      img: "/assets/img/nail-polish.svg"
    },
    {
      id: 2,
      title: "Hair Salon - Men",
      img: "/assets/img/hair-dryer.svg"
    },
    {
      id: 3,
      title: "Hair Salon - Women",
      img: "/assets/img/hair-dryer.svg"
    },
    {
      id: 4,
      title: "Hair Salon - Unisex",
      img: "/assets/img/hair-dryer.svg"
    },
    {
      id: 5,
      title: "Skincare",
      img: "/assets/img/dimples.svg"
    },
    {
      id: 6,
      title: "Makeup accessories",
      img: "/assets/img/lotus.svg"
    },
  ],
  "Entertainment": [
    {
      id: 0,
      title: "Sport Complex",
      img: "/assets/img/dumbbell.svg"
    },
    {
      id: 1,
      title: "Theater",
      img: "/assets/img/theater.svg"
    },
    {
      id: 2,
      title: "Golf",
      img: "/assets/img/golf-stick.svg"
    },
    {
      id: 3,
      title: "Bowling",
      img: "/assets/img/bowling.svg"
    },
  ],
  "Pet Store": [
    {
      id: 0,
      title: "Cat Food",
      img: "/assets/img/track.svg"
    },
    {
      id: 1,
      title: "Dog Food",
      img: "/assets/img/track.svg"
    },
    {
      id: 2,
      title: "Bird feed",
      img: "/assets/img/track.svg"
    },
    {
      id: 3,
      title: "Pet accessories",
      img: "/assets/img/track.svg"
    },
    {
      id: 4,
      title: "Acquatic supplies",
      img: "/assets/img/track.svg"
    },
    {
      id: 5,
      title: "Grooming services",
      img: "/assets/img/track.svg"
    },
    {
      id: 6,
      title: "Pet Toys",
      img: "/assets/img/track.svg"
    }
  ],
  "Sports and Fitness": [
    {
      id: 0,
      title: "Nutrition and Supplements Store",
      img: "/assets/img/sport.svg",
    },
    {
      id: 1,
      title: "Gym Membership",
      img: "/assets/img/sport.svg",
    },
  ],
  "Toy Store": [
    {
      id: 0,
      title: "Toy Store",
      img: "/assets/img/toys.svg"
    }
  ],
  "Studios": [
    {
      id: 0,
      title: "Studios",
      img: "/assets/img/artist.svg"
    }
  ],
  "Retails": [
    {
      id: 0,
      title: "Game Shop",
      img: "/assets/img/gamepad.svg"
    },
    {
      id: 1,
      title: "Gift Shop",
      img: "/assets/img/shopping-bag.svg"
    },
    {
      id: 2,
      title: "Vape Shop",
      img: "/assets/img/electronic-cigarette.svg"
    },
    {
      id: 3,
      title: "Cannabis Shop",
      img: "/assets/img/cannabis_logo.svg"
    },
  ],
  "Digital marketing": [
    {
      id: 0,
      title: "Social Media Advertising",
      img: "/assets/img/social_media_logo.svg"
    },
    {
      id: 1,
      title: "Instagram advertising",
      img: "/assets/img/instagram_logo.svg"
    },
    {
      id: 2,
      title: "Twitter/X.com advertising",
      img: "/assets/img/x_logo.svg"
    },
    {
      id: 3,
      title: "Facebook advertising",
      img: "/assets/img/facebook_logo.svg"
    },
    {
      id: 4,
      title: "WhatsApp marketing",
      img: "/assets/img/whatsapp_logo.svg"
    },
  ],
}

/**
 * Parse a Merchant Profile object.
 * @param {Object} profile Merchant Profile object: Refer to MODEL_MERCHANT_PROFILE.
 * @return {Object} Prased Merchant Profile object.
 */
export function serializeProfile(profile) {
    // TODO:
    // return serialize(profile, MODEL_MERCHANT_PROFILE);
    return profile;
}

/**
 * Serialize a Merchant Profile object.
 * @param {Object} profile Merchant Profile object: Refer to MODEL_MERCHANT_PROFILE.
 * @return {Object} Serialized Merchant Profile object.
 */
export function parseProfile(profile) {
    // TODO:
    // return serialize(profile, MODEL_MERCHANT_PROFILE);
    return profile;
}
