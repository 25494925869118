// Image Buttons blocks for the list Component: Products
// Author : Huen Oh (heons921@gmail.com)
// @param {object} config Config object details about components.

<template>
  <div class="pl-0 align-self-center pr-4">
    <b-row>
      <b-col cols="4">
        <!-- Toggle button for status -->
        <b-row class="align-self-center pr-4 mt-4"> 
          <a href="javascript:void(0);" :id="`${config.name}-toggle-btn-status`">
            <toggle-button-status
              v-if="config.toggleBtn"
              :name="config.name"
              :status="config.toggleBtn.status"
              :disabled="config.toggleBtn.disabled"
              @on-toggle-change="config.toggleBtn.change"
            />
          </a>
          <!-- Tooltip for disabled status toggle button -->
          <b-tooltip 
            v-if="config.toggleBtn.disabled" 
            :target="`${config.name}-toggle-btn-status`"
            placement="bottom" 
            :title="config.toggleBtn.disabledReason"/>
        </b-row>
      </b-col>
      <b-col>
        <b-row class="align-self-center pr-2 mt-4">
          <ImageButtonSchedule
            v-if="config.imgBtnSchedule"
            :id="config.id"
            :name="config.name"
            @click="config.imgBtnSchedule.click"
          />
          <ImageButtonShow
            v-if="config.imgBtnShow"
            :id="config.id"
            :name="config.name"
            @click="config.imgBtnShow.click"
          />
          <ImageButtonDuplicate
            v-if="config.imgBtnDuplicate"
            :id="config.id"
            :name="config.name"
            @click="config.imgBtnDuplicate.click"
          />
          <ImageButtonAdd
            v-if="config.imgBtnAdd"
            :id="config.id"
            :name="config.name"
            @click="config.imgBtnAdd.click"
          />
          <ImageButtonEdit
            v-if="config.imgBtnEdit"
            :id="config.id"
            :name="config.name"
            @click="config.imgBtnEdit.click"
          />
          <ImageButtonDelete
            v-if="config.imgBtnDelete"
            :id="config.id"
            :name="config.name"
            @click="config.imgBtnDelete.click"
          />
          <!-- Checkbox for the selection -->
          <b-form-checkbox 
            v-if="config.checkBox"
            :data-cy="`${config.name}-checkbox`" 
            :checked="config.checkBox.checked" 
            id="item_checkbox"
            class="itemCheck ml-3" 
            disabled=""
          />
        </b-row>       
        <!-- Last Updated Date -->
        <b-row class="align-self-center mb-0" v-if="config.lastUpdate">
          <p class="mt-3 text-muted text-small mb-0">{{$t('general.last-updated') + formatDate(config.lastUpdate.update)}}</p>
        </b-row>
      </b-col> 
    </b-row>
  </div>    
</template>



<script>
import ImageButtonAdd         from '@/components/Forms/Buttons/ImageButtonAdd'
import ImageButtonEdit        from '@/components/Forms/Buttons/ImageButtonEdit'
import ImageButtonShow        from '@/components/Forms/Buttons/ImageButtonShow'
import ImageButtonDelete      from '@/components/Forms/Buttons/ImageButtonDelete'
import ImageButtonSchedule    from '@/components/Forms/Buttons/ImageButtonSchedule'
import ImageButtonDuplicate   from '@/components/Forms/Buttons/ImageButtonDuplicate'
import ToggleButtonStatus     from '@/components/Forms/ToggleButtonStatus'
import {formatDate}           from "@/utils/general.js"


export default {
  components:{
    ImageButtonAdd,
    ImageButtonEdit,
    ImageButtonShow,
    ImageButtonDelete,
    ImageButtonSchedule,
    ImageButtonDuplicate,
    ToggleButtonStatus,
  },
  props:{
    // {object} Config object details about components.
    config:{
      type:Object,
      default:() => {},
    },
  },
  methods:{
    // Format Data for Last Updated Date
    formatDate,
  }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>