/**
 * It contains store module for Orders.
 */

import {
  getOneOrderApi,
  getOrdersByStoreIdApi,
  updateOrderStatusApi,
  // TODO: REST request should have Api suffix
  getArchivedOrders,
  getNumberOfNewOrdersByMerchantApi
} from "@/api/orders"

import {
  ENUM_ORDER_SERVING_STATUS_STRING,
  ENUM_ORDER_STATUS_STRING,
  MAP_ORDER_STATUS_TO_API,
} from "@/models/order.js"


// utils
import { sortByLastUpdatedAtDecending } from "@/utils/sort.js"
import { parseOrder, parseOrders } from "@/models/order.js";
import { isResponseOK, SELECTABLE_ALL } from "@/utils/general.js"

import LocalStorageService from "@/utils/local_store";

export default {
  namespaced: true,
  state: {
    orders: [],       // {Object[]} List of Orders: Refer to MODEL_ORDER_READ.
    currentOrder: {}, // {Object}   Current Order.
    currentStore: {}, // {Object}   Current Store from the selection. {label, value},
    numNewOrders: 0,   // {Number}   Number of new orders of all stores belong to this merchant 
    orderHistoryFilters: {
      selectedStore: SELECTABLE_ALL, // {Object} { value: String, label: String}   Selected store.
      selectedReportDuration: {},                   // {Object}   Selected duration. {start, end}.
      serveOptions: [],                             // {String[]} Selected serve options. 
      orderStatus: {value: "", label: "all"},     // {Object} { value: String, label: String} Selected Order status.
      pageNumber: 1,                                // {Number(Int)} Page number of the table.  
      pageSize: {value: 10, text: "10"},            // {Object} Page size for rows of the table.    
      searchText: null,                             // {String} Search value for searchbar.   
      fields: [],
      sortBy: "lastUpdatedAt",
      sortDesc: true,
    },
    pagination: {},   // {Object} {totalOrders, pageNumber, pageSize}
    orderNotificationInterval: null
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object} state Local State object.
     */
    resetAll(state) {
      state.orders = [];
      state.currentOrder = {};
      state.currentStore = {};
      state.numNewOrders = 0;
      state.orderHistoryFilters.selectedStore = SELECTABLE_ALL;
      state.orderHistoryFilters.selectedReportDuration = {};
      state.orderHistoryFilters.serveOptions = [];
      state.orderHistoryFilters.orderStatus = { value: "", label: "all" };
      state.orderHistoryFilters.pageNumber = 1;
      state.orderHistoryFilters.pageSize = { value: 10, text: "10" };
      state.orderHistoryFilters.searchText = null;
      state.orderHistoryFilters.fields = [];
      state.orderHistoryFilters.sortBy = "lastUpdatedAt";
      state.orderHistoryFilters.sortDesc = true;
      state.orderNotificationInterval = null;
    },
    /**
     * Set all Orders in the state.
     * @param {Object} state  Local State object.
     * @param {Object} orders List of Order objects: Refer to MODEL_ORDER_READ.
     */
    setAllOrders(state, orders) {
      state.orders = orders;
    },
    /**
     * Set current Order in the state.
     * @param {Object} state Local State object.
     * @param {Object} order Order objects: Refer to MODEL_ORDER_READ.
     */
    setCurrentOrder(state, order) {
      state.currentOrder = order;
    },
    /**
     * Set current Store in the state.
     * @param {Object} state Local State object.
     * @param {Object} store Selection objects for the store: {lable, value}.
     */
    setCurrentStore(state, store) {
      state.currentStore = store;
    },
    /**
     * Upate a Order in the state.
     * @param {Object} state Local State object.
     * @param {Object} order Order object: Refer to MODEL_ORDER_READ.
     * @param {number} index Index of a Order in the list.
     */
    updateOneOrder(state, { order, index }) {
      state.orders[index] = {
        ...state.orders[index],
        ...order
      };
    },
    /**
     * Add an order to the state
     * @param {Object} state 
     * @param {Object} order will be added 
     */
    addOrder(state, { order }){
      state.orders = [order, ...state.orders];
    },
    /**
     * Set number of new orders
     * @param {Object} state 
     * @param {Object} num : Number of new orders of all stores belong to this merchant  
     */
    setNumNewOrders(state, numNewOrders ){
      state.numNewOrders = numNewOrders;
    },
    /**
     * Set Order pagination properties, for OrderDetail view.
     * @param {Object} state 
     * @param {Object} pagination {totalOrders, pageNumber, pageSize}
     */
    setOrderPagination(state, pagination) {
      state.pagination = pagination;
    },
    /**
     * Set Order History selectedStoreId filter value.
     * @param {Object} state 
     * @param {Object} selectedStore {Object} { value: String, label: String} Selected Store
     */
    setSelectedStore(state, selectedStore) {
      state.orderHistoryFilters.selectedStore = selectedStore;
    },
    /**
     * Set Order History selectedReportDuration filter value.
     * @param {Object} state 
     * @param {Object} selectedReportDuration {Object} {start, end} Selected duration. 
     */
    setSelectedReportDuration(state, selectedReportDuration) {
      state.orderHistoryFilters.selectedReportDuration = selectedReportDuration;
    },
    /**
     * Set Order History serveOptions filter value.
     * @param {Object} state 
     * @param {Object} serveOptions {String[]} Selected serve options (isPickup values). 
     */
    setServeOptions(state, serveOptions) {
      state.orderHistoryFilters.serveOptions = serveOptions;
    },
    /**
     * Set Order History pagination pageNumber value.
     * @param {Object} state 
     * @param {Object} pageNumber {Number(Int)} Page number of the Order History table. 
     */
    setPageNumber(state, pageNumber) {
      state.orderHistoryFilters.pageNumber = pageNumber;
    },
    /**
     * Set Order History table pageSize filter value.
     * @param {Object} state 
     * @param {Object} pageSize {Object {value: "", text: ""}} Page size for rows of the Order History table. 
     */
    setPageSize(state, pageSize) {
      state.orderHistoryFilters.pageSize = pageSize;
    },
    /**
     * Set Order History table text search filter value.
     * @param {Object} state 
     * @param {Object} searchText {String} For Search bar in Order History table. 
     */
    setSearchText(state, searchText) {
      state.orderHistoryFilters.searchText = searchText;
    },
    /**
     * Set Order History table fields filter value.
     * @param {Object} state 
     * @param {Object} fields {Object} List of columns to display in Order History table. 
     */
    setFields(state, fields) {
      state.orderHistoryFilters.fields = fields;
      LocalStorageService.setOrderHistoryColumns(fields);
    },
    /**
     * Set Order History table orderStatus filter value.
     * @param {Object} state 
     * @param {Object} orderStatus {Object} Order Status. 
     */
    setOrderStatus(state, orderStatus) {
      state.orderHistoryFilters.orderStatus = orderStatus;
    },
    /**
     * Resets all the values in orderHistoryFilters object in state.
     * @param {Object} state 
     */
    clearAllFilters(state) {
      state.orderHistoryFilters.selectedStore = SELECTABLE_ALL;
      state.orderHistoryFilters.selectedReportDuration = {};
      state.orderHistoryFilters.serveOptions = [];
      state.orderHistoryFilters.orderStatus = { value: "", label: "all" };
      state.orderHistoryFilters.pageNumber = 1;
      state.orderHistoryFilters.pageSize = { value: 10, text: "10" };
      state.orderHistoryFilters.searchText = null;
      state.orderHistoryFilters.fields = [];
      LocalStorageService.deleteOrderHistoryColumns();
      state.orderHistoryFilters.sortBy = "lastUpdatedAt";
      state.orderHistoryFilters.sortDesc = true;
    },
    /**
     * Set Column name to sort by.
     * @param {Object} state 
     * @param {String} sortBy table column name
     */
    setSortBy(state, sortBy) {
      state.orderHistoryFilters.sortBy = sortBy;
    },
    /**
     * Set Sort direction for the table.
     * @param {Object} state 
     * @param {Boolean} sortDesc Descending or Ascending
     */
    setSortDesc(state, sortDesc) {
      state.orderHistoryFilters.sortDesc = sortDesc;
    },
    /**
     * Set interval to play sound to notify new orders
     * @param {Object} state 
     * @param {String} interval - Id of the interval 
     */
    setOrderNotificationInterval(state, interval){
      state.orderNotificationInterval = interval;
    }
  },
  getters: {
    /**
     * Get Orders in the state.
     */
    orders: (state) => {
      return state.orders;
    },
    /**
     * Get Current Order in the state.
     */
    currentOrder: (state) => {
      return state.currentOrder;
    },
    /**
     * Get Current Store in the state.
     */
    currentStore: (state) => {
      return state.currentStore;
    },
    /**
     * Get a Order by Id.
     * @param {string} id Id of a Order.
     */
    findOrderById: (state) => (id) => {
      return state.orders.find(
        order => order.id === id
      );
    },
    /**
     * Get an index of a Order by Id.
     * @param {string} id Id of a Order.
     */
    findOrderIndexById: (state) => (id) => {
      return state.orders.findIndex(
        order => order.id === id
      );
    },
    numNewOrders: (state) => {
      return state.numNewOrders;
    },
    pagination: (state) => {
      return state.pagination;
    },
    selectedStore: (state) => {
      return state.orderHistoryFilters.selectedStore;
    },
    selectedReportDuration: (state) => {
      return state.orderHistoryFilters.selectedReportDuration;
    },
    serveOptions: (state) => {
      return state.orderHistoryFilters.serveOptions;
    },
    pageNumber: (state) => {
      return state.orderHistoryFilters.pageNumber;
    },
    pageSize: (state) => {
      return state.orderHistoryFilters.pageSize;
    },
    searchText: (state) => {
      return state.orderHistoryFilters.searchText;
    },
    fields: (state) => {
      return state.orderHistoryFilters.fields;
    },
    orderStatus: (state) => {
      return state.orderHistoryFilters.orderStatus;
    },
    sortBy: (state) => {
      return state.orderHistoryFilters.sortBy;
    },
    sortDesc: (state) => {
      return state.orderHistoryFilters.sortDesc;
    },
    orderNotificationInterval: (state) => {
      return state.orderNotificationInterval;
    }
  },
  actions: {
    /**
     * Load Orders by Store Id.
     * It loads Orders from the sever.
     * @param {string} storeId Store Id.
     * @return {Object} Response object of a Promise.
     */
    async loadOrdersByStoreId({ commit }, storeId) {
      const response = await getOrdersByStoreIdApi(storeId);
      if (isResponseOK(response)) {
        const orders = parseOrders(response.data);
        // TODO : may need to store separtely.
        commit("setAllOrders", orders.sort(sortByLastUpdatedAtDecending));
      } else {
        console.log("[Error]loadOrders:");
      }
      return response;
    },

    /**
     * Loads all (archived)Orders with status: COMPLETED, REFUNDED.
     * @param {Object} params {isPickup, storeId, startDate, endDate} 
     * @return {Object} Response object of a Promise.
     */
    async loadArchivedOrders({ commit }, params) {
      // TODO: REST request should have API suffix
      const response = await getArchivedOrders(params);
      if (isResponseOK(response)) {
        const orders = parseOrders(response.data.content);
        const pagination = {
          totalOrders: response.data.totalElements,
          pageNumber: response.data.pageNumber,
          pageSize: response.data.pageSize,
        }
        commit("setAllOrders", orders);
        commit("setOrderPagination", pagination);
      } else {
        console.log("[Error]loadArhchivedOrders:");
      }
      return response;
    },
    /**
     * Load one Order.
     * It loads one Order by id from the sever.
     * @param {string} id Order Id.
     * @return {Object} Response object of a Promise.
     */
    async loadOneOrder({ commit }, id) {
      const response = await getOneOrderApi(id);
      if (isResponseOK(response)) {
        const order = parseOrder(response.data);
        commit("setCurrentOrder", order);
      } else {
        console.log("[Error]loadOneOrder:");
      }
      return response;
    },
    /**
     * Get number of  new orders of all stores belong to this merchant
     */
    async getNumberOfNewOrdersByMerchant({ state, commit }){
      const response = await getNumberOfNewOrdersByMerchantApi();
      if(isResponseOK(response)){
        const numNewOrders = parseInt(response.data);
        commit("setNumNewOrders", numNewOrders );
      }
    },
    /**
     * Update an Order's serveStatus.
     * @param {Object} data Order object with additional(newServeStatus). Refer to MODEL_ORDER_READ.
     * @return {Object} Response object of a Promise.
     */
    async updateOrderServeStatus({ commit, getters }, data) {
      const { id, storeId, status, serveStatus, newServeStatus, payload, onSuccess, onFail, } = data;
      let errorReason = null;
      // console.log('updateOrderServeStatus', data)
      switch (newServeStatus) {
        case ENUM_ORDER_SERVING_STATUS_STRING.REJECTED:
          if (
            // (status != ENUM_ORDER_STATUS_STRING.CONFIRMED) && 
            (status != ENUM_ORDER_STATUS_STRING.PAID)) {
              // errorReason = "The order is not CONFIRMED nor PAID";
            errorReason = "The order is not PAID";
          } else {
            if (serveStatus) {
              errorReason = "It is not able to REJECT. The order is already in Process.";
            } else { }
          }
          
        case ENUM_ORDER_SERVING_STATUS_STRING.ACCEPTED:
          if (
            // (status != ENUM_ORDER_STATUS_STRING.CONFIRMED) &&
            (status != ENUM_ORDER_STATUS_STRING.PAID)) {
            // console.log('status', status)
            // console.log('ENUM_ORDER_STATUS_STRING.PAID', ENUM_ORDER_STATUS_STRING.PAID)
            // errorReason = "The order is not CONFIRMED nor PAID";
            errorReason = "The order is not PAID";
          } else { }
          break;
        
        case ENUM_ORDER_SERVING_STATUS_STRING.PREPARING:
          if (serveStatus != ENUM_ORDER_SERVING_STATUS_STRING.ACCEPTED) {
            errorReason = "The order is not ACCEPTED";
          } else { }
          break;
        
        case ENUM_ORDER_SERVING_STATUS_STRING.READY:
          if (serveStatus != ENUM_ORDER_SERVING_STATUS_STRING.PREPARING) {
            errorReason = "The order is not PREPARING";
          } else { }
          break;
        
        case ENUM_ORDER_SERVING_STATUS_STRING.PICKUP:
        case ENUM_ORDER_SERVING_STATUS_STRING.DELIVERING:
        case ENUM_ORDER_SERVING_STATUS_STRING.SERVED:
          if (serveStatus != ENUM_ORDER_SERVING_STATUS_STRING.READY) {
            errorReason = "The order is not READY";
          } else { }
          break;
        
        case ENUM_ORDER_SERVING_STATUS_STRING.DELIVERED:
          if (serveStatus != ENUM_ORDER_SERVING_STATUS_STRING.DELIVERING) {
            errorReason = "The order is not DELIVERING";
          } else { }
          break;
      }

      let response = null
      if (errorReason === null) {

        // Final check for the completed order case.
        let statusForApi = MAP_ORDER_STATUS_TO_API[newServeStatus];
        if ((newServeStatus === ENUM_ORDER_SERVING_STATUS_STRING.PICKUP)
          || (newServeStatus === ENUM_ORDER_SERVING_STATUS_STRING.SERVED)
          || (newServeStatus === ENUM_ORDER_SERVING_STATUS_STRING.DELIVERED)) {
          statusForApi = MAP_ORDER_STATUS_TO_API[ENUM_ORDER_STATUS_STRING.COMPLETED];
        } else { }
        
        updateOrderStatusApi(id, storeId, statusForApi, payload).then(response => {
          // console.log('response', response);
          if (isResponseOK(response)) {
            const order = parseOrder(response.data);

            const index = getters.findOrderIndexById(id);

            // If the order is still in the list, then update the order
            if(index >= 0){
              commit(
                "updateOneOrder",
                {
                  order: order,
                  index: index
                });
              // Set current order
              commit("setCurrentOrder", getters.findOrderById(id));

            }else{}
            
            if (onSuccess) {
              onSuccess(response);
            } // else
          } else {
            console.log("[Error]updateOrderServeStatus:");
          }
        }).catch(err => {
          // console.log('err', err.response);
          if (onFail) {
            onFail(err.response);
          } // else
        });
      
      } else {
        response = {};
        response.errorReason = errorReason;
        return response;
      }
      
    },
    /**
         * 
         * @param {Object} state Local State object 
         * @param {Object} updatedOrder The updated order handled by other client 
         */
    updateOrderList({ commit, getters, state }, updatedOrder) {
      const index = getters.findOrderIndexById(updatedOrder.id);
      // This order is already in the list, then update the order
      if(index != -1){
        let tmpOrders = state.orders.slice();

        // Remove order from order list if updated order is COMPLETED/REFUNDED
        if (updatedOrder.status === ENUM_ORDER_STATUS_STRING.COMPLETED 
          || updatedOrder.status === ENUM_ORDER_STATUS_STRING.REFUNDED){
            tmpOrders.splice(index, 1);            
          }
        else{
          // Otherwise, update the order in order list
          tmpOrders[index] = updatedOrder;
        }

        commit("setAllOrders", tmpOrders);
      } else if (updatedOrder.status === ENUM_ORDER_STATUS_STRING.PAID){
        // If order is not in the list & its status is PAID, add order to list
        commit("addOrder", { order: updatedOrder});
      }
    },
    /**
     * Set current Order in the state.
     * @param {Object} order Order objects: Refer to MODEL_ORDER_READ.
     */
    setCurrentOrder({ commit }, order) {
      commit("setCurrentOrder", order);
    },
    /**
     * Set current Store in the state.
     * @param {Object} store Selection objects for the store: {lable, value}.
     */
    setCurrentStore({ commit }, store) {
      commit("setCurrentStore", store);
    },
    /**
     * Update number of new orders of merchant
     * @param {Number} number of new orders 
     */
    updateNumNewOrders({ commit }, numNewOrders) {
      commit("setNumNewOrders", numNewOrders);
    },
    /**
     * Load filter columns from local storage if present.
     * @param {Object} param0         Local state object
     * @param {Array}  defaultColumns List of default columns which should be selected if not stored.
     */
    initializeOrderHistoryFilters({ commit, state }, defaultColumns) {

      const columns = LocalStorageService.getOrderHistoryColumns();
      // columns variable must be stored as array.

      if (columns && columns.length > 0) {
        commit("setFields", columns);
      } else {
        commit("setFields", defaultColumns);
      }
    },
    updateOrderNotificationInterval({ commit }, interval) {
      commit("setOrderNotificationInterval", interval);
    }
  }
};
