/**
 * @fileoverview It create aixos for the INTRA_SERVICE_CORE_DEAL service in the application.
 * @author Aysegul Yalcinkaya
 */

// Constant.
import { INTRA_SERVICE_CORE_DEAL } from "@/constants/config.js";

// Modules in the application.
import { axios_base, interceptResponseSuccess, interceptResponseFail } from "./axios_base";
import Vue from "vue";
import i18n from "@/i18n";

const axios_openai = axios_base.create();

/**
 * Intercept the request to set additional headers.
 */
axios_openai.interceptors.request.use(
  config => {
   // const token = LocalStorageService.getAccessToken();
    const token='sk-proj-yaBcx1QFQex7BJYroifrT3BlbkFJm8ZCGoL7y50spYOBQwZf';
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Content-Type"]=`application/json`
    } // else
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

/**
 * Intercept function for the response of axios when it's failed.
 * @param {Object} error Error object from axios.
 * @return {Promise} Promise object.
 */
const customInterceptResponseFail = function (error) {
  // console.log('axios_base - error', error);
  // console.log('axios_base - error.response', error.response);

  // Pass all non 401s back to the caller.
  if (!!error.response) {
    // console.log('error', error)
    // console.log('error.response', error.response)
    // TODO : Deal with some of common error response or Skip some of them. e.g. FORBIDDEN.
    if (error.response.data && error.response.data.message) {
        Vue.$notify("error", i18n.tc('notify.title-fail'), error.response.data.message, { duration: 3000, permanent: true });
      }
      // Else show generic message.
      else {
        Vue.$notify("error", i18n.tc('notify.title-fail'), i18n.tc('notify.server-internal-error'), { duration: 3000, permanent: true });
      }
    } // else
    return Promise.reject(error);

}

/**
 * Intercept the response.
 */
axios_openai.interceptors.response.use(
  interceptResponseSuccess,
  customInterceptResponseFail
);


export default axios_openai;
