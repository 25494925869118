// Component - Authentication Dialog for current user.
// @param {string}  title       Title.
// @param {string}  description Description.
// @param {boolean} dialog      Handler variable for the dialog(Close and Open).

<template>
  <!-- Dialog -->
  <b-modal id="modalAlert" ref="modalAlert" size="md" @hide="onCencel()" hide-header="">
    <h2 class="text-center mb-5">{{title}}</h2>
    <DropdownFilterStore
      v-if="showStoreFilter"
      :label="selectedStore.label"
      :isAll="false"
      @click="onSelectedStoreChange"
      class="d-inline-block mb-4"
    />

    <!-- Password Input -->
    <label class="form-group has-float-label mb-4">
      <input 
        :data-cy="`dlg-auth-input-password`"
        v-on:keyup.enter="onSubmit()"
        type="password" 
        class="form-control" 
        v-model="password"
      >
      <span>{{ $t('user.password') }}</span>
    </label>
    <!-- Footer - Buttons -->
    <template slot="modal-footer">
      <b-button 
        :data-cy="`dlg-auth-btn-ok`"
        variant="primary" 
        @click="onSubmit()" 
        class="mr-1"
      >{{ $t('buttons.ok') }}</b-button>
      <b-button 
        :data-cy="`dlg-auth-btn-cancel`"
        variant="outline-secondary" 
        @click="onCencel()"
      >{{ $t('buttons.cancel') }}</b-button>
    </template>
  </b-modal>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import LocalStorageService from "@/utils/local_store"

import DropdownFilterStore from '@/components/Forms/DropdownFilterStore'

import {isEmptyString} from '@/utils/general';
import {showValidationError} from '@/utils/notify';

export default {
  components:{
    DropdownFilterStore,
  },
    
  props: {
    // {string} Title.
    title:{
      type:String,
      default: "",
    },
    // {string} Description.
    description:{
      type:String,
      default: "",
    },
    // {boolean} Handler variable for the dialog(Close and Open).
    dialog:{
      type:Boolean,
      default: false,
    },
    // {boolean} Whether to show the store filter based on number of merchant stores.
    showStoreFilter: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return{
      password: "", // {string} Password.
      selectedStore: {}, // Stores: {value:id, label:name, text:name}
    }
  },
  computed: {
    // Map getters.
    ...mapGetters("auth", ["username"]),
    ...mapGetters("order", ["currentStore"]),

    // Map state.
    ...mapState("auth", {
      errorReason: state => state.errorReason,
    })
  },

  methods: {
    // Map actions.
    ...mapActions("auth", [
      "login", 
      "clearErrorReason", 
      "setErrorWrongCreds", 
    ]),

    showValidationError,

    /**
     * On Cencel button : Close the dialog
     */
    onCencel() {
      this.password = "";
      this.$emit('dialog-close');
    },
    /**
     * On Submit button : Try Login and Close the dialog
     */
    onSubmit() {

      if ((this.showStoreFilter && isEmptyString(this.selectedStore.value)) ||
          (this.password === "")) {
        // Either store or password is empty, show notification.
        this.showValidationError(this.$t('validations.store-and-password-required'))
        return;
      }

      // Login
      this.login({
        username: this.username || LocalStorageService.getUsername(),
        password: this.password,
        onSuccess: () => {
          this.$emit('dialog-submit', this.selectedStore);
          this.$emit('dialog-close');
        },
        onFail: () => this.setErrorWrongCreds(),
      });

    },

    /**
     * store {Object} Merchant store 
     */
    onSelectedStoreChange(store) {
      console.log("testing store value: ", store)
      if (store.value != "") {
        this.selectedStore = store;
      }
    }

  },

  watch: {
    // Control of the dialog: Show, Hide
    dialog: function(newVal, oldVal){
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if(newVal == true) {
        this.$refs["modalAlert"].show();
      } else {
        this.$refs["modalAlert"].hide();
      }
    },

    /**
     * Watch for errorReason
     */
    errorReason: function(val) {
      if (val != "") {
        this.$notify(
          'warning',
          this.$t('user.wrong-creds-title'),
          this.errorReason,
          { duration: 3000, permanent: false }
        );
        this.clearErrorReason();
      }
    }, 
    /**
     * Watch if current store was changed. Could be due to removing the store.
     */
    currentStore: function (value) {
      this.selectedStore = value;
    }
  },

  mounted(){ 
    this.selectedStore = this.currentStore;
  }
}
</script>