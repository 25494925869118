/**
 * @fileoverview It create aixos for the INTRA_SERVICE_CORE_DEAL service in the application.
 * @author Aysegul Yalcinkaya
 */

// Constant.
import { INTRA_SERVICE_CORE_DEAL } from "@/constants/config.js";

// Modules in the application.
import { axios_base, interceptResponseSuccess, interceptResponseFail } from "./axios_base";
import HttpStatus from "http-status-codes";
import Vue from "vue";
import i18n from "@/i18n";
import router from "@/router";
import LocalStorageService from "@/utils/local_store";
import {sendLoginApi} from "@/api/auth";

const axios_ayrshare = axios_base.create();

/**
 * Intercept the request to set additional headers.
 */
axios_ayrshare.interceptors.request.use(
  config => {
   // const token = LocalStorageService.getAccessToken();
    const token='XGJH803-9XBM3W3-KW63E29-RDM1G1V';
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } // else
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

/**
 * Intercept function for the response of axios when it's failed.
 * @param {Object} error Error object from axios.
 * @return {Promise} Promise object.
 */
const customInterceptResponseFail = function (error) {
  // console.log('axios_base - error', error);
  // console.log('axios_base - error.response', error.response);

  // Pass all non 401s back to the caller.
  if (!!error.response && error.response.status !== HttpStatus.UNAUTHORIZED) {
    // console.log('error', error)
    // console.log('error.response', error.response)
    // TODO : Deal with some of common error response or Skip some of them. e.g. FORBIDDEN.
    if(error.response.status === HttpStatus.SERVICE_UNAVAILABLE){
      Vue.$notify("error", i18n.tc('notify.title-fail'), i18n.tc('notify.service-unavailable'), { duration: 3000, permanent: true });
    }else if (error.response.status !== HttpStatus.FORBIDDEN) {
      // Check if response with violations.
      if (error.response.data && error.response.data.violations) {
        if (Array.isArray(error.response.data.violations) && error.response.data.violations.length > 0) {
          error.response.data.violations.forEach(violation => {
            Vue.$notify("error", i18n.tc('notify.title-fail'), violation.message, { duration: 3000, permanent: true });
          })
        }
      }
      // Else if contains main error message.
      else if (error.response.data && error.response.data.message) {
        if (error.response.data.code==221){
          return error;
        }
        Vue.$notify("error", i18n.tc('notify.title-fail'), error.response.data.message, { duration: 3000, permanent: true });
      }
      // Else show generic message.
      else {
        Vue.$notify("error", i18n.tc('notify.title-fail'), i18n.tc('notify.server-internal-error'), { duration: 3000, permanent: true });
      }
    } // else
    return Promise.reject(error);
  }

  const originalRequest = error.config;
  // console.log('axios_base - error.config', error.config);
  if (originalRequest) {
    // console.log('axios_base - inside-if');
    if (!!originalRequest._retry || originalRequest.url === LOGIN_URL) {
      // console.log("axios_base - re-direct to login");
      // TODO : compare with 'name'.
      if (router.history.current.path != "/user/login") {
        // Reset all persisted data.
        window.sessionStorage.clear();
        // resetAll();
        router.push({ name: 'login' });
      }
      // console.log('return')
      return Promise.reject(error);
    }

    // console.log('axios_base - customAxios', customAxios);
    originalRequest._retry = true;
    const refreshToken = LocalStorageService.getRefreshToken();
    return sendLoginApi({ "refresh_token": refreshToken })
      .then(res => {
        // console.log("axios_base - refresh - success");
        LocalStorageService.setAuth(res.data);
        return customAxios(originalRequest);
      })
      .catch(error => {
        // console.log("axios_base - refresh - fail");
        return customAxios(originalRequest);
      });
  }
}

/**
 * Intercept the response.
 */
axios_ayrshare.interceptors.response.use(
  interceptResponseSuccess,
  customInterceptResponseFail
);


export default axios_ayrshare;
