/**
 * It contains backend Apis for Sales Dashboard.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// Modules in the application.
import axios                  from "@/api/axios/axios_merchant_dashboard";
import { isNullOrUndefined }  from "@/utils/general";

// URLs for Backend
const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;

const MERCHANT_STORE_SALES_METRIC_URL           = `${HOST}/sales-dashboard`;
const MERCHANT_STORE_SALES_METRIC_REALTIME_URL  = `${HOST}/sales-dashboard/realtime`;

const MERCHANT_STORE_INIT_METRIC_URL  = `${MERCHANT_STORE_SALES_METRIC_URL}/init`;
const NEW_RETURNING_CLIENT_DATA_URL   = `${MERCHANT_STORE_SALES_METRIC_URL}/new-returning-clients`; // TODO : Is it being used?


/**
 * Get data for SalesMetric within a date range.
 * @param {Object} data Payload contains metric, viewMode, startDate, endDate, merchantId, storeId.
 * @return {Promise} Result of GET for SalesMetric.
 */
export function getSalesMetricWithinDateRangeApi(data) {
  let endpoint = `${MERCHANT_STORE_SALES_METRIC_URL}/${data.metric}/${data.viewMode}/${data.startDate}/${data.endDate}/${data.merchantId}`;

  if (!isNullOrUndefined(data.storeId)) {
    endpoint = `${endpoint}/${data.storeId}`;
  } // else

  return axios.get(endpoint,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}


/**
 * Get data for InitMetric within a date range.
 * @param {Object} data Payload contains startDate, endDate, merchantId, storeId.
 * @return {Promise} Result of GET for InitMetric.
 */
export async function getSalesInitMetricWithinDateRangeApi(data) {
  let endpoint = `${MERCHANT_STORE_INIT_METRIC_URL}/${data.startDate}/${data.endDate}/${data.merchantId}`;

  if (!isNullOrUndefined(data.storeId)) {
    endpoint = `${endpoint}/${data.storeId}`;
  } // else

  return axios.get(endpoint,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}
