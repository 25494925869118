// List tax configurations by filtering them by location
// Author: Sukhpreet Singh
// Props: appliedTaxConfigs {TaxConfig[]} list of applied tax configurations. 
//        locations         {Object}      Country, province, city 
// Events: addTax           Emits with selected tax config object to add to applied tax configs
//         removeTax        Emits with selected tax config object to remove from applied tax configs.

<template>
  <b-card body-class="px-0 py-0" class="overflow-hidden">
    <b-table 
      hover 
      responsive
      sticky-header
      head-variant="primary"
      :sort-compare="sortCompare"
      :items="filteredTaxConfigs" 
      :fields="taxConfigFields"
    >
      <!-- Percent Slot -->
      <template #cell(percentage)="row">
        {{`${row.item.percentage}%`}}
      </template>

      <!-- Tax level slot -->
      <template #cell(level)="row">
        {{getTaxLevel(row.item)}}
      </template>

      <!-- Actions slot  -->
      <template #cell(actions)="row">
        <div>
          <b-button v-if="isTaxPresentById(row.item.id)" size="xs" variant="danger" @click="remove(row.item)">{{$t('actions.remove')}}</b-button>
          <b-button v-else size="xs" variant="primary" @click="add(row.item)">{{$t('actions.add')}}</b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>

import { isNullOrUndefined } from '@/utils/general';

import { mapGetters } from 'vuex';

export default {
  props: {
    appliedTaxConfigs: {
      type: Array,
      default: [],
    },
    location: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      taxConfigFields: [
        {key: 'taxType', label: this.$t('store.tax-details.fields.tax-name'), sortable: true},
        {key: 'productCategory', label: this.$t('store.tax-details.fields.tax-category'), sortable: true},
        {key: 'percentage', label: this.$t('store.tax-details.fields.percentage'), sortable: true},
        {key: 'level', label: this.$t('store.tax-details.fields.level')},
        {key: 'description', label: this.$t('store.tax-details.fields.description'), sortable: true},
        {key: 'actions', label: this.$t('store.tax-details.fields.actions'), sortable: true},
      ],
    }
  },
  
  computed: {
    // Map getters.
    ...mapGetters("merchant_store", [
      "taxConfigList",
    ]),

    /**
     * Get filtered tax configs by currentStore's location (city, province, country)
     */
    filteredTaxConfigs() {
      let resultTaxConfigs = [];

      // to filter the list by country.
      if (!isNullOrUndefined(this.location.country)) {
        resultTaxConfigs = this.taxConfigList.filter(taxConfig => {
          
          return taxConfig.country === this.location.country && 
          ( taxConfig.province === null || taxConfig.province === this.location.province ) &&
          ( taxConfig.city === null || taxConfig.city === this.location.city )
        });
      }

      return resultTaxConfigs;
    }
  },
  methods: {
    
    /**
     * Get the level of tax. 
     */
    getTaxLevel(taxConfig) {
      if (taxConfig.city !== null) {
        return "City"
      } else {
        if (taxConfig.province !== null) {
          return "Province"
        } else {
          if (taxConfig.country !== null) {
            return "Country"
          }
        }
      }
    },

    /**
     * Checks if taxConfigId is already selected.
     * Returns true if already selected else false.
     */
    isTaxPresentById(id) {
      const found = this.appliedTaxConfigs.find(taxConfig => taxConfig.id === id);
      return !isNullOrUndefined(found);
    },

    /**
     * Function used in table to sort columns.
     * Currently, it is only used for 'actions' slot column.
     * To display all selected taxes together in the beginning or the end.
     */
    sortCompare(aRow, bRow, key) {
      const idKey = 'id'
      const aId = aRow[idKey];
      const bId = bRow[idKey];

      const aIdExists = !isNullOrUndefined(this.appliedTaxConfigs.find(taxConfig => taxConfig.id === aId));
      const bIdExists = !isNullOrUndefined(this.appliedTaxConfigs.find(taxConfig => taxConfig.id === bId));

      if (key === 'actions') {
        if (aIdExists && !bIdExists) {
          return -1; // asecending case
        } else if (!aIdExists && bIdExists) {
          return 1;  // descending case
        } else {
          return 0;  // drow case
        }
      } else {
        return null; // Fallback to bootstrap's native sortCompare.
      }
    },

    /**
     * Event to add a tax config to applied list.
     */
    add(taxConfig) {
      this.$emit('addTax', taxConfig);
    },

    /**
     * Event to remove the applied tax config.
     */
    remove(taxConfig) {
      this.$emit('removeTax', taxConfig);
    },
  }
  
}
</script>