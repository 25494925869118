/**
 * It contains store module for Detail Chart.
 */

// Apis.
import {
  getSalesMetricWithinDateRangeApi
} from "@/api/dashboard/sales_dashboard.js";

import { parseChartData, parseRealtimeChartData } from "@/models/detailChartData";
import { realTimeAreaChartOptions }               from "@/components/Charts/config";
import {
  isValidDate,
  getDayFromDate,
  getWeekFromDate,
  getMonthFromDate,
  getYearFromDate,
  getDaysOfYear,
  getWeeksOfYear,
  compareDate
} from "@/utils/datetime";

import { isNullOrUndefined, isResponseOK } from "@/utils/general.js"

import { CHART_VIEW_MODE } from "@/constants/common";


export default {
  namespaced: true,
  state: {
    metric: "NONE",       // {string} Metric. Refer to DASHBOARD_METRIC.
    reportData: [],       // {Object[]} Report data: Refer to DASHBOARD_DETAIL_CHART.
    realtimeData: [],     // {Object[]} Report data: Refer to DASHBOARD_DETAIL_CHART.
    realtimeTick: 0,      // {number}   Tick number for the realtime data: Refer to realTimeAreaChartOptions.tick.
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.metric = "NONE";
      state.reportData = [];
      state.realtimeData = [];
      state.realtimeTick = 0;
    },
    /**
     * Reset realtime data of the chart.
     * @param {Object} state  Local State object.
     */
    resetRealtimeData(state) {
      state.realtimeData = [];
    },
    /**
     * Set metric of the chart.
     * @param {Object} state  Local State object.
     * @param {string} metric Metric. Refer to DASHBOARD_METRIC.
     */
    setMetric(state, metric) {
      state.metric = metric
    },
    /**
     * Set non-realtime chart data.
     * @param {Object} state  Local State object.
     * @param {string} data Metric. Refer to DASHBOARD_DETAIL_CHART.
     */
    setReportData(state, data) {
      state.reportData = data;
    },
    /**
     * Set realtime chart data.
     * @param {Object} state  Local State object.
     * @param {string} data Metric. Refer to DASHBOARD_DETAIL_CHART.
     */
    setRealtimeData(state, data) {
      state.realtimeData = data;
    },

    /**
     * Set realtime chart tick.
     * @param {Object} state Local State object.
     * @param {number} tick  Tick number.
     */
    setRealtimeTick(state, tick) {
      state.realtimeTick = tick;
    }
  },
  getters: {
    /**
     * Return metric of the chart.
     * @param {Object} state Local State object.
     */
    metric: (state) => {
      return state.metric;
    },
    /**
     * Return non-realtime chart data.
     * @param {Object} state Local State object.
     */
    reportData: (state) => {
      return state.reportData;
    },
    /**
     * Return realtime chart data.
     * @param {Object} state Local State object.
     */
    realtimeData: (state) => {
      return state.realtimeData;
    },
    /**
     * Return realtime chart tick.
     * @param {Object} state Local State object.
     */
    realtimeTick: (state) => {
      return state.realtimeTick;
    },
  },

  actions: {
    /**
     * The real-time data will be the difference between data of last real-time api call and current data
     * The data of the chart will be slide as a window with size of {{realTimeAreaChartOptions.xAxeSize}} which is configurable
     */
    loadRealtimeData({ state, commit }, data) {
      let realtimeData = parseRealtimeChartData(data);
      if (state.realtimeTick % realTimeAreaChartOptions.tick != 0) {
        realtimeData.label = "";
      } else {}

      commit("setRealtimeTick", state.realtimeTick + 1);

      // Calculate the total New Data
      const newData = { label: realtimeData.label, total: realtimeData.total };
      
      let realtimeChartData = [...state.realtimeData, newData];
      if (realtimeChartData.length > realTimeAreaChartOptions.xAxeSize) {
        realtimeChartData.splice(0, 1);
      } else {}

      commit("setRealtimeData", realtimeChartData);
    },

    /**
     * Load particular metric data belong to merchant/store within during with viewMode DAILY/WEEKLY/MONTHLY
     * @param  {Object} payload Payload to get the data: Refer to DASHBOARD_PAYLOAD_METRIC.
     * @return {Object} Response object of a Promise.
     */
    async loadSalesMetricWithinDateRange({ commit }, payload) {
      // Validate if startDate & endDate are binded
      if (!isValidDate(payload.startDate) || !isValidDate(payload.endDate) || isNullOrUndefined(payload.merchantId)) {
        return null;
      } else {}

      const response = await getSalesMetricWithinDateRangeApi(payload);
      let data = response.data;
      
      if (isResponseOK(response)) {
        // Fill empty data for days/weeks/years has no record
        const startYear = getYearFromDate(payload.startDate);
        const endYear = getYearFromDate(payload.endDate);
        let year = startYear;

        while(year <= endYear){
          let viewMode = "";
          let start = 0;
          let end = 0;

          switch (payload.viewMode) {
            case CHART_VIEW_MODE.DAILY: {
              viewMode = "day";
              // Day of Year is 1 based
              start = (year == startYear) ? getDayFromDate(payload.startDate) : 1;
              end = (year == endYear) ? getDayFromDate(payload.endDate) : getDaysOfYear(year);
              break;
            }
            case CHART_VIEW_MODE.WEEKLY: {
              viewMode = "week";
              // Week of Year is 1 based
              start = (year == startYear) ? getWeekFromDate(payload.startDate) : 1;
              end = (year == endYear) ? getWeekFromDate(payload.endDate) : getWeeksOfYear(year);
              break;
            }
            case CHART_VIEW_MODE.MONTHLY: {
              viewMode = "month";
              // Month is 0 based
              start = (year == startYear) ? getMonthFromDate(payload.startDate) : 0;
              end = (year == endYear) ? getMonthFromDate(payload.endDate) : 11;
              break;
            }
          }

          while(start <= end){
            if (!response.data.some((element) => element[viewMode] == start && element.year == year)) {  // If array does not have that day/week/month.
              // Then push the empty data for that day/week/month.
              data.push({
                metric: null,
                day: (viewMode === "day" ? start : null), week: (viewMode === "week" ? start : null),
                month: (viewMode === "month" ? start : null), year: year, total: 0
              });
            }

            // Go to next day
            start++;
          }

          // Go to next year
          year++;
        }

        // Sort data based on day/week/month/year
        data.sort((a, b) => compareDate(a, b));

        const chartData = data.map(x => { return parseChartData(x) });

        commit("setReportData", chartData);
      }

      return response;
    }
  }
};
