// Image Button for Schedule.
// Author : Huen Oh (heons921@gmail.com)
// @param {string} name Name of the component. For data-cy.
// @param {string} id   Id of an object which is assigned to the component.

<template>
  <!-- Image button: Edit Schedule -->
  <a :data-cy="`${name}-btn-schedule`" href="javascript:void(0);">
    <b-img 
      :id="'schedule-'+id" 
      class="no-checkbox cls-img-btn ml-2" 
      src="/assets/icons/work-schedule.svg" 
      @click="onClick"
    />
    <b-tooltip :target="'schedule-'+id" placement="bottom" :title="$t('tool-tips.schedule')"></b-tooltip>
  </a>
</template>


<script>
export default {
  props:{
    // {string} Name of the component. For data-cy.
    name: {
      type: String,
      default: ""
    },
    // {string} Id of an object which is assigned to the component.
    id: {
      type: String,
      default: ""
    },
  },
  methods:{
    /**
     * On click the button.
     */
    onClick() {
      this.$emit('click');
    }
  }
}
</script>
<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>
