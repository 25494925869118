// Componet for Badges of Store
// Author : Huen Oh (heons921@gmail.com)
// @param {string}   cyPrefix Prefix for data-cy.
// @param {string[]} data     List of strings for the badges.

<template>
  <div>
    <BaseBadge
      v-for="(store,index) in data" 
      :key="store.value"
      :cyPrefix="`${cyPrefix}-badge-store-${index}`"
      :data="getNameByIdFromList(store, selectableStores)"
      variant="secondary"
      @onDelete="onDelete(index)"
    />
  </div>  
</template>


<script>
/**
 * Event on click of delete bagde.
 * 
 * @event BadgeListStore#onDelete
 * @type {object}
 * @param {number} index Index.
 */

import BaseBadge from '@/components/Common/BaseBadge'
import { getNameByIdFromList } from "@/utils/general.js"
import { mapGetters } from 'vuex'

export default {
  components:{
    BaseBadge,
  },
  computed:{
    ...mapGetters("merchant_store", ["selectableStores"]),
 },
  props:{
    // {string} Prefix for data-cy.
    cyPrefix: {
      type: String,
      default: ""
    },
    // {string[]} List of strings for the badges.
    data:{
      type:Array,
      default: () => []
    },
  },
  methods:{
    // Get name of an item from a list
    getNameByIdFromList,
    
    onDelete(index){
      // Event - BadgeListStore#onDelete
      this.$emit('onDelete',index)
    }
  }
}
</script>
