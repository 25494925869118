var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalAddonGroup",
      attrs: { id: "modalAddonGroup", size: "lg" },
      on: {
        hide: function ($event) {
          return _vm.onCencel()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("HeaderDialogProduct", {
                attrs: {
                  title: "objects.addon-group",
                  name: "dlg-addon-group",
                  status: _vm.data.status,
                  disabled: _vm.data.showOnly || _vm.addonsInGroup.length <= 0,
                  actionType: _vm.actionType,
                },
                on: { changeStatus: _vm.updateStatus },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-form",
        [
          _c("dialog-alert", {
            attrs: {
              dialog: _vm.dialogs.alert,
              description: _vm.$t("alert.no-change"),
              actionType: _vm.actionType,
              buttonSubmitText: _vm.$t("buttons.ok"),
            },
            on: {
              "dialog-close": _vm.closeDialogAlert,
              "dialog-submit": _vm.onSubmitDialogAlert,
            },
          }),
          _vm._v(" "),
          _c("dialog-addon", {
            attrs: { dialog: _vm.dialogs.addon, data: _vm.currentAddon },
            on: { "dialog-close": _vm.closeDialogAddon },
          }),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-colxx",
                { attrs: { sm: "6" } },
                [
                  _c("EditNameDescription", {
                    attrs: {
                      name: "dlg-addon-group",
                      label: "addon-group",
                      data: _vm.data,
                      disabled: _vm.data.showOnly,
                      isFirstLoad: _vm.isFirstLoad,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-button-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "pl-5 pr-5",
                          attrs: {
                            variant: "primary",
                            "data-cy": "dlg-addon-group-btn-optional",
                            pressed: _vm.selectedOptionType == 1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClickSelectedOptionTypeBtn(1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("buttons.optional")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "pr-5 pl-5",
                          attrs: {
                            "data-cy": "dlg-addon-group-btn-mandatory",
                            variant: "primary",
                            pressed: _vm.selectedOptionType == 2,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClickSelectedOptionTypeBtn(2)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("buttons.mandatory")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "mb-3 mt-3",
                      attrs: {
                        "data-cy": "dlg-addon-group-btn-check-same-allowed",
                        disabled: _vm.data.showOnly,
                      },
                      model: {
                        value: _vm.isSameAddonAllowed,
                        callback: function ($$v) {
                          _vm.isSameAddonAllowed = $$v
                        },
                        expression: "isSameAddonAllowed",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("addon-group.place-holder.checkbox")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c("b-colxx", { attrs: { sm: "5" } }, [
                        _c(
                          "label",
                          { staticClass: "form-group has-float-label" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.forcedMin,
                                  expression: "forcedMin",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                "data-cy":
                                  "dlg-addon-group-input-force-minimum",
                                type: "number",
                                disabled: _vm.data.showOnly,
                              },
                              domProps: { value: _vm.forcedMin },
                              on: {
                                change: _vm.onChangeForcedMin,
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.forcedMin = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("objects.labels.force-minimum"))
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-colxx", { attrs: { sm: "5" } }, [
                        _c(
                          "label",
                          { staticClass: "form-group has-float-label" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.forcedMax,
                                  expression: "forcedMax",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                "data-cy":
                                  "dlg-addon-group-input-force-maximum",
                                type: "number",
                                disabled: _vm.data.showOnly,
                              },
                              domProps: { value: _vm.forcedMax },
                              on: {
                                change: _vm.onChangeForcedMax,
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.forcedMax = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("objects.labels.force-maximum"))
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-colxx",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("h6", [
                        _vm._v(_vm._s(_vm.$t("product.choose-image"))),
                      ]),
                      _vm._v(" "),
                      _c("ImagesSelection", {
                        key: "selImage",
                        ref: "selImage",
                        attrs: {
                          name: "dlg-addon-group",
                          images: _vm.data.images,
                          single: true,
                          disabled: _vm.data.showOnly,
                        },
                        model: {
                          value: _vm.isChanged,
                          callback: function ($$v) {
                            _vm.isChanged = $$v
                          },
                          expression: "isChanged",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mt-2", attrs: { "align-v": "end" } },
            [
              _c("BaseSelectItem", {
                attrs: {
                  colSize: _vm.sizeColMain,
                  disabled: _vm.data.showOnly,
                  title: _vm.$t("addon-group.add-addon-to-group"),
                  cyPrefix: "dlg-addon-group",
                  cySuffix: "addon",
                  options: _vm.currentSelAddonOptions,
                  noOptionsMessage: _vm.$t("validations.no-add-on-available"),
                },
                on: { input: _vm.addAddonToGroup },
              }),
              _vm._v(" "),
              _c("b-colxx", { attrs: { sm: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass: "search-sm d-inline-block float-md-right m-3",
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        "data-cy": "dlg-addon-group-input-search",
                        placeholder: _vm.$t("menu.search"),
                      },
                      model: {
                        value: _vm.searchKeyword,
                        callback: function ($$v) {
                          _vm.searchKeyword = $$v
                        },
                        expression: "searchKeyword",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("TableListProducts", {
            attrs: {
              cyPrefix: "dlg-addon-group",
              cySuffix: "addons",
              items: _vm.searchedAddonsInGroup,
              disabled: _vm.data.showOnly,
              selectableGroups: _vm.selectableAddonGroups,
            },
            on: {
              changeStatus: _vm.updateOneAddon,
              editItem: _vm.onEditAddon,
              deleteItem: _vm.onDeleteAddon,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c("FooterDialogProduct", {
            attrs: {
              name: "dlg-addon-group",
              disabled: _vm.$v.data.$invalid && !_vm.isFirstLoad,
              actionType: _vm.actionType,
            },
            on: { "click-cancel": _vm.onCencel, "click-submit": _vm.onSubmit },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }