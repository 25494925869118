var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "overflow-hidden", attrs: { "body-class": "px-0 py-0" } },
    [
      _c("b-table", {
        attrs: {
          hover: "",
          responsive: "",
          "sticky-header": "",
          "head-variant": "primary",
          "sort-compare": _vm.sortCompare,
          items: _vm.filteredTaxConfigs,
          fields: _vm.taxConfigFields,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(percentage)",
            fn: function (row) {
              return [
                _vm._v(
                  "\n      " + _vm._s(`${row.item.percentage}%`) + "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(level)",
            fn: function (row) {
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm.getTaxLevel(row.item)) + "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (row) {
              return [
                _c(
                  "div",
                  [
                    _vm.isTaxPresentById(row.item.id)
                      ? _c(
                          "b-button",
                          {
                            attrs: { size: "xs", variant: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.remove(row.item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("actions.remove")))]
                        )
                      : _c(
                          "b-button",
                          {
                            attrs: { size: "xs", variant: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.add(row.item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("actions.add")))]
                        ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }