// Componet for selecting Products and/or ProductGroups
// Author : Huen Oh (heons921@gmail.com)
// @param {string}   [name = ""]               Name of the component.
// @param {bool}     [disabled = false]        Disabled property of the component. (in).
// @param {bool}     [value = false]           Value for 'isSelected'. v-model. (in, out).
// @param {number}   [groupIndex = null]       Group index of products. Refer to MODEL_PRODUCT_IN_LIST. (in).
// @param {number}   [quantity = 1]            Quantity of products. Refer to MODEL_PRODUCT_IN_LIST. (in).
// @param {string[]} [productIds = []]         Selected products' Ids. (in).
// @param {string[]} [disabledProductIds = []] Disabled products' Ids from the selection. (in, out).
// @param {Object[]} labeledGroupsWithProducts Labeled ProductGroups with Products. [{id, label, children:{id, label}}...]. (in).

<template>
<div>
  <treeselect 
    :data-cy="`${name}-tree-select`"
    v-model="selectedProductIds" 
    :multiple="true" 
    :options="labeledGroupsWithProducts" 
    :sort-value-by="'LEVEL'"
    :show-count="true"
    :value-consists-of="'ALL'"
    :disabled="disabled"
    :clearable="false"
    @select="onSelect"
    @deselect="onDeselect"
    @input="onChangeGroupSelection"
  >
    <div 
      class="m-0 p-0"
      slot="value-label" 
      slot-scope="{ node }" 
      :data-cy="`${name}-tree-select-selected-item-${node.label}`"
      v-bind:class="{ 'text-theme-2 font-weight-bold': isDeleted(node)}"
    >{{node.label}}</div>
    <label 
      slot="option-label" 
      slot-scope="{ node, shouldShowCount, count, labelClassName }" 
      :class="labelClassName" 
      :data-cy="`${name}-tree-select-selectable-item-${node.label}`">
        {{ node.label }}
      <span v-if="shouldShowCount" class="countClassName">({{ count }})</span>
    </label>
  </treeselect>
</div>
</template>


<script>
// Events.
/**
 * Event to update labeledGroupsWithProducts.
 * 
 * labeledGroupsWithProducts needs to be updated at the parent component.
 * It's because 
 *  1. treeselect.options only accpet the changes of entire object.
 *     The options for the selection would not be refreshed even if some of items.isDisabled value is changed.
 *     It means the object needs to be replaced.
 *  2. Since labeledGroupsWithProducts is prop. It needs to be replaced at the parent component.
 *
 * @event TreeselectProducts#updateLabel
 * @type {object}
 * @param {number} groupIndex Group index of the selected products's Ids.
 */

/**
 * Event to update v-model(isSelected).
 * 
 * @event TreeselectProducts#input
 * @type {object}
 * @param {boolean} isSelected It indicates if the products are selected or not.
 */

/**
 * Event to notify that this selection is changed.
 * 
 * @event TreeselectProducts#change
 * @type {object}
 */


import _ from 'lodash';

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

// Mappter for vuex
import { mapGetters, mapActions } from 'vuex';

import { isNullOrUndefined }  from "@/utils/general";

import { GROUP_OTHERS }       from '@/constants/common'

export default {
  props:{
    // {string} Name of the component.
    name: {
      type: String,
      default: ""
    },
    // {bool} Disabled property of the component. (in).
    disabled: {
      type: Boolean,
      default: false
    },
    // {[number]} Group index of products. Refer to MODEL_PRODUCT_IN_LIST. (in).
    groupIndex: {
      type: Number,
      default: null,
    },
    // {[number]} Quantity of products. Refer to MODEL_PRODUCT_IN_LIST. (in).
    quantity: {
      type: Number,
      default: 1,
    },
    // {string[]} Selected products' Ids. (in).
    productIds: {
      type: Array,
      default: () => []
    },
    // {string[]} Disabled products' Ids from the selection. (in, out).
    disabledProductIds: {
      type: Array,
      default: () => []
    },
    // {Object[]} Labeled ProductGroups with Products. [{id, label, children:{id, label}}...] (in).
    labeledGroupsWithProducts: {
      type: Array,
      default: () => []
    },
    // {bool} Value for 'isSelected'. v-model. (in, out).
    value: {
      type: Boolean,
      default: false
    },
  },
  components: { Treeselect },
  data(){
    return {
      selectedProductIds: [], // {string[]} Selected products' Ids. - copy of productIds.

      // {string[]} New selected products' Ids.
      //  - Temp value to use in onSelect / watch of disabledProductIds.
      newSelected:[],
    }
  },
  created: function () {
    // console.log('created');
  },
  computed: {
    // Map getters.
    ...mapGetters("product",        ["findProductsByGroupId", "findProductById", "findDeletedProductById"]),
    ...mapGetters("product_group",  ["findProductGroupById"]),
  },
  methods:{
    ...mapActions("product", ["getDeletedProduct"]),
    
    // TODO : move to product group store?.
    /**
     * Get ids of selected Products.
     */
    getSelectedProductIds() {
      // If id is Group -> add all children(Products)
      // If id is Product -> add it
      let selectedProductIds = [];

      for(let id of this.selectedProductIds) {
        // Ignore group 'Others' since it is a customized group including orphaned products
        if(id !== GROUP_OTHERS){
          const group = this.findProductGroupById(id);
          if(group) {
            selectedProductIds.push(
              ...this.findProductsByGroupId(id).map(product => {
                return product.id;
              })
            );
          } else {
            selectedProductIds.push(id);
          }
        }
      }
        

      return _.uniq(selectedProductIds);      
    },
    /**
     * Get reference object list of selected Products.
     */
    getSelectedProductList() {
      return this.getSelectedProductIds().map((id) => {
        return {
          id : id,
          groupIndex: this.groupIndex,
          quantity: this.quantity,
        }
      })
    },

     /**
     * Set ids of selected Products.
     * @param {string[]} productIds Selected Products' Ids.
     */
    setSelectedProductIds(productIds) {
      if(Array.isArray(productIds)) {
        this.selectedProductIds = [...productIds];
      } else {}
    },

    /**
     * Update selectedProductIds.
     * It add/remove ProductGroups' Ids by its Products selection.
     */
    updateSelectedProductIds() {
      // console.log('updateSelectedProductIds - groupIndex', this.groupIndex)
      for(let i = 0; i < this.labeledGroupsWithProducts.length; ++i) {
        const group = this.labeledGroupsWithProducts[i];
        let isAllSelected = true;
        if(group.children) {
          // Check if all the Products in a ProductGroup is selected.
          for(let j=0; j < group.children.length; ++j) {
            if(this.selectedProductIds.includes(group.children[j].id)) {
              // Do nothing
            } else {
              isAllSelected = false;
              break;
            }
          }

          if(!isAllSelected) {
            // Remove ProductGroup
            const idx = this.selectedProductIds.findIndex(ele => {return ele === group.id});
            if(idx != -1) {
              this.selectedProductIds.splice(idx, 1);
            } else {}
          } else {
            // Add ProductGroup
            this.selectedProductIds.push(group.id);
          }
        }
      }
      // console.log('updateSelectedProductIds', this.selectedProductIds);
      this.selectedProductIds = _.uniq(this.selectedProductIds);

      // console.log('updateSelectedProductIds', this.labeledGroupsWithProducts);
    },
    /**
     * On change group selection.
     *  - Update selectedProductIds.
     *  - Update v-model value, which is indicating if the products are selected or not.
     * @fires TreeselectProducts#input
     * @fires TreeselectProducts#change
     */
    onChangeGroupSelection() {
      // console.log('onChangeGroupSelection', this.selectedProductIds);
      this.updateSelectedProductIds();
      const isSelected = this.selectedProductIds.length > 0 ? true : false;
      // Event - TreeselectProducts#input
      this.$emit('input', isSelected);
      this.$emit('change');
    },

    /**
     * On select a product from the treeselect.
     * Add id of selected node and its children to disabledProductIds.
     * @param {Object} node Selected node object from the treeselect.
     */
    onSelect(node) {
      // console.log('onSelect', node);
      this.newSelected = [];
      if(node.children) {
        // All children
        for (let child of node.children) {
          if(!this.disabledProductIds.includes(child.id)) {
            this.newSelected.push(child.id);
            this.disabledProductIds.push(child.id);
          } else {}
        }
      } else {
        this.newSelected.push(node.id);
        if(!this.disabledProductIds.includes(node.id)) {
          this.disabledProductIds.push(node.id);
        } else {}
      }
      // // TODO : group
      // if(!this.disabledProductIds.includes(node.id)) {
      //   this.newSelected.push(node.id);
      //   this.disabledProductIds.push(node.id);
      // } else {}
    },
    /**
     * On deselect a product from the tree treeselect.
     * Remove id of selected node and its children to disabledProductIds.
     * @param {Object} node Deselected node object from the treeselect.
     */
    onDeselect(node) {
      // console.log('onDeSelect', node);
      if(node.children) {
        // All children
        // deselect the children which are in the selected items.
        for (let child of node.children) {
          if(this.selectedProductIds.includes(child.id)) {
            // const numFilterd = this.disabledProductIds.filter(id => id === child.id).length;

            //find number of it. for loop
            let index = this.disabledProductIds.indexOf(child.id);
            while(index != -1) {
              this.disabledProductIds.splice(index,1);
              index = this.disabledProductIds.indexOf(child.id);
            }
          } else {}
        }
      } else {
        let index = this.disabledProductIds.indexOf(node.id);
        
        while(index != -1) {
          this.disabledProductIds.splice(index,1);
          index = this.disabledProductIds.indexOf(node.id);
        }
      }

      // // TODO : group
      // let index = this.disabledProductIds.indexOf(node.id);
      // while(index != -1) {
      //   this.disabledProductIds.splice(index,1);
      //   index = this.disabledProductIds.indexOf(node.id);
      // }
    },
    /**
     * To determine a node is deleted
     */
    isDeleted(node){
      return node.label.includes('(deleted)');
    },
    async loadDeletedProduct(){
      // If there is a product which was soft-deleted, then try to get detail of that product & add it to the options
      for(let id of this.productIds) {
        const group = this.findProductGroupById(id);

        // group Others is a customized group including all orphaned products
        if(isNullOrUndefined(group) && id !== GROUP_OTHERS) {
          let product = this.findProductById(id);

          if(isNullOrUndefined(product)){
            product = this.findDeletedProductById(id);
          }

          if(isNullOrUndefined(product)){
            product = await this.getDeletedProduct({id : id});
            
            if(!isNullOrUndefined(product)){
              for(let i = 0; i < this.labeledGroupsWithProducts.length; i++){
                // product not belong to any group, then add into GROUP_OTHERS
                if((product.groups.length == 0 && this.labeledGroupsWithProducts[i].id === GROUP_OTHERS)
                // Or belong to particular group
                ||  product.groups.includes(this.labeledGroupsWithProducts[i].id)){
                  this.labeledGroupsWithProducts[i].children.push( {id: product.id, label: product.name + ' (deleted)'} );                    
                }
              }
            }
            
          }
        }
      }
    }
  },
  async mounted(){
    // console.log('mounted');
    if(Array.isArray(this.productIds)) {
      this.selectedProductIds = [...this.productIds];
      
      await this.loadDeletedProduct();
    } else {}
  },
  watch: {
    /**
     * Watch for productIds. 
     * It simply copy productIds to selectedProductIds.
     */
    async productIds() {
      // console.log('watch productIds - groupIndex', this.groupIndex)
      // console.log('watch productIds - productIds', this.productIds)

      if(Array.isArray(this.productIds)) {
        this.selectedProductIds = [...this.productIds];

        await this.loadDeletedProduct();
      } else {}
    },
    /**
     * Watch for disabledProductIds.
     * It enables/disables items of labeledGroupsWithProducts by 
     *  selectedProductIds, newSelected and disabledProductIds.
     * @fires TreeselectProducts#updateLabel
     */
    disabledProductIds: {
      deep: true,
      handler() {
        // console.log('watch - groupIndex', this.groupIndex);
        // console.log('watch - selectedProductIds', this.selectedProductIds);
        // console.log('watch - disabledProductIds', this.disabledProductIds);

        for(let i = 0; i < this.labeledGroupsWithProducts.length; ++i) {
          let group = this.labeledGroupsWithProducts[i];
          if(group.children) {
            // Check isDisabled by selection.
            for(let j=0; j < group.children.length; ++j) {
              let child = group.children[j];
              if (
                this.selectedProductIds.includes(child.id) 
                || (this.newSelected.includes(child.id))
              ) {
                // console.log('False : id', child.id)
                child.isDisabled = false;
              } else {
                if (this.disabledProductIds.includes(child.id)) {
                  child.isDisabled = true;
                  // console.log('True : id', child.id)
                } else {
                  child.isDisabled = false;
                  // console.log('False : id', child.id)
                }
              }
            }
          } else {}

          //TODO : for the group.
        }
        console.log('watch - labeledGroupsWithProducts', this.labeledGroupsWithProducts);

        // Tried with forceUpdate but it doesn't work.
        // this.$refs.treeselectref.$forceUpdate();
        // this.$forceUpdate();

        // Event - TreeselectProducts#updateLabel
        this.$emit('updateLabel', this.groupIndex);
      },
    },
  },
}
</script>