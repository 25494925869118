/**
 * It contains store module for Addons.
 */

import {
  getAddonsApi,
  getOneAddonApi,
  addAddonApi,
  addAddonWithImagesApi,
  deleteAddonApi,
  updateAddonApi,
  updateAddonWithImagesApi,
} from "@/api/product/addons.js";

import { parseAddon }   from "@/models/products/addon.js";
import { sortInOrder }  from "@/utils/sort.js"
import { getListForDropDownMenu, isResponseOK, isResponseCREATED, isNullOrUndefined } from "@/utils/general.js"

import { ENUM_STATUS_STRING } from "@/constants/common";


export default {
  namespaced: true,
  state: {
    addons: [],             // List of Addons: Refer to ADDON_MODEL.
    filteredAddons: [],     // List of filtered Addons: Refer to ADDON_MODEL.
    selectableAddons: [],   // List of selectable Addons: {value:id, label:name, text:name}
    currentAddonIndex: -1,  // Index of current Addon
    filters: {              // Filters
      store: { value: "", label: "" },
      group: { value: "", label: "" },
      order: { value: "", label: "", isAscending: true, },
      keyword: "",
    },
    deletedAddons: [],     // {Object[]} List of deleted Addons.
  },
  
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.addons = [];
      state.filteredAddons = [];
      state.selectableAddons = [];
      state.currentAddonIndex = -1;
      state.filters = {
        store: { value: "", label: "" },
        group: { value: "", label: "" },
        order: { value: "", label: "", isAscending: true, },
        keyword: "",
      };
      state.deletedAddons = [];
    },
    /**
     * Set the index of current Addon.
     * @param {Object}  state Local State object.
     * @param {number}  index Index of current Addon.
     */
    setCurrentAddonIndex(state, index) {
      state.setCurrentAddonIndex = index
    },
    /**
     * Set all Addons in the state.
     * @param {Object} state  Local State object.
     * @param {Object} addons List of Addon objects: Refer to ADDON_MODEL.
     */
    setAllAddons(state, addons) {
      state.addons = addons;
    },
    /**
     * Delete a Addon in the state.
     * @param {Object} state Local State object.
     * @param {number} index Index of a Addon in the list.
     */
    deleteOneAddon(state, index) {
      // Add deleted addon to the deleted list
      const deletedAddon = state.addons[index];

      if (!isNullOrUndefined(deletedAddon)) {
        state.deletedAddons = [...state.deletedAddons, deletedAddon];
      }

      // Remove the deleted addons from current products list
      state.addons.splice(index, 1);
    },
    /**
     * Add a Addon in the state.
     * @param {Object} state  Local State object.
     * @param {Object} addons Addon object: Refer to ADDON_MODEL.
     */
    addOneAddon(state, addon) {
      state.addons.push(addon);
    },
    /**
     * Add a Addon into the list of deleted Addon in the state.
     * @param {Object} state Local State object.
     * @param {Object} addon Addon object: Refer to ADDON_MODEL.
     */
    addOneDeletedAddon(state, addon) {
      state.deletedAddons = [...state.deletedAddons, addon];
    },
    /**
     * Upate a Addon in the state.
     * @param {Object} state    Local State object.
     * @param {Object} product  Addon object: Refer to ADDON_MODEL.
     * @param {number} index    Index of a Addon in the list.
     */
    updateOneAddon(state, { addon, index }) {
      state.addons[index] = {
        ...state.addons[index],
        ...addon,
        "deletedGroups": []
      };
    },
    /**
     * Set selectable Addon in the state.
     * @param {Object} state Local State object.
     */
    setSelectableAddons(state) {
      state.selectableAddons = getListForDropDownMenu(state.addons);
    },
    /**
     * Add a filtered Addon in the state.
     * @param {Object} state Local State object.
     * @param {Object} addon Addon object to be added.
     */
    addOneFilteredAddon(state, addon) {
      state.filteredAddons.push(addon);
    },
    /**
     * Delete a filtered Addon in the state.
     * @param {Object} state  Local State object.
     * @param {number} index  Index of a filtered Addon in the list.
     */
    deleteOneFilteredAddon(state, index) {
      state.filteredAddons.splice(index, 1);
    },
    /**
     * Set Addon filters in the state.
     * @param {Object} state    Local State object.
     * @param {Object} filters  Filter object.
     */
    setAddonFilters(state, filters) {
      state.filters = {
        ...state.filters,
        ...filters,
      }
    },
    /**
     * Apply Addon filters.
     * @param {Object} state Local State object.
     */
    applyAddonFilters(state) {
      // console.log("applyAddonFilters");
      // console.log(this.filters);
      // Apply filters
      // 1. not deleted
      state.filteredAddons = state.addons.filter(element => element.status != ENUM_STATUS_STRING.DELETED);
      // 2. store
      if (state.filters.store.value != "") {
        state.filteredAddons = state.filteredAddons.filter(element => element.stores.includes(state.filters.store.value));
      }
      // 3. Group
      if (state.filters.group.value != "") {
        state.filteredAddons = state.filteredAddons.filter(element => element.groups.includes(state.filters.group.value));
      }
      // 4. Name in search
      if (state.filters.keyword != "") {
        state.filteredAddons = state.filteredAddons.filter(
          element => element.name.toLowerCase().includes(state.filters.keyword.toLowerCase())
        );
      }
      // 5. order
      sortInOrder(state.filteredAddons, state.filters.order);

      // console.log("applyAddonFilters", state.addons);
      // console.log("applyAddonFilters", state.filters);
      // console.log("applyAddonFilters", state.filteredAddons);
    },
  },

  getters: {
    /**
     * Get Addon in the state.
     */
    addons: (state) => {
      return state.addons;
    },
    /**
     * Get selectable Addon in the state.
     */
    selectableAddons: (state) => {
      return state.selectableAddons;
    },
    /**
     * Get filtered Addon in the state.
     */
    filteredAddons: (state) => {
      return state.filteredAddons;
    },
    /**
     * Get filters in the state.
     */
    filters: (state) => {
      return state.filters;
    },

    /**
     * Get current Addon in the state.
     */
    currentAddon: (state) => {
      const index = state.currentAddonIndex;
      return state.addons[index >= 0 ? index : 0]; // what if there is no addons?
    },
    /**
     * Get a Addon by Id.
     * @param {string} id Id of an Addon.
     */
    findAddonById: (state) => (id) => {
      return state.addons.find(
        addon => addon.id === id
      );
    },
    /**
     * Get a deleted addon by its Id
     * @param {string} id Id of an Addon
     * @returns 
     */
    findDeletedAddonById: state => id => {
      const addon = state.deletedAddons.find(p => p.id == id);

      return addon;
    },
    /**
     * Get an index of a Addon by Id.
     * @param {string} id Id of a Addon.
     */
    findAddonIndexById: (state) => (id) => {
      return state.addons.findIndex(
        addon => addon.id === id
      );
    },
    /**
     * Get Addons by AddonGroup Id.
     * @param {string} id Id of an AddonGroup.
     */
    findAddonsByGroupId: (state) => (id) => {
      return state.addons.filter(
        addon => addon.groups.includes(id)
      );
    },

    /**
     * Find addons which belong to given store.
     * @param {Object} state local state
     * @returns list of filtered addons
     */
    findAddonsByStoreId: (state) => (storeId) => {
      return state.addons.filter(addon => {
        if (!isNullOrUndefined(addon.stores)) {
          return addon.stores.includes(storeId);
        } else {
          return false;
        }
      })

    }
  },

  actions: {
    /**
     * Load Addons.
     * It loads Addons from the sever and set selectable Addons.
     */
    async loadAddons({ commit }) {
      const response = await getAddonsApi();
      if (isResponseOK(response)) {
        const addons = parseAddon(response.data);
        commit("setAllAddons", addons.filter(el => el.status != ENUM_STATUS_STRING.DELETED));
        // commit("setAllAddons", addons);
        commit("setSelectableAddons");
      } else {
        console.log("[Error]loadAddons:");
        // this._vm.$notify("error", "Fail", "Loading addon", { duration: 3000, permanent: false });
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },

    /**
     * Get addon detail by addon id.
     * It load the detail of specific addon by its id.
     * @return {Object} Response object of a Promise.
     */
    async getDeletedAddon({ commit }, data) {
      const { id } = data;

      const response = await getOneAddonApi(id);

      if (isResponseOK(response)) {
        let addon = parseAddon(response.data);

        commit("addOneDeletedAddon", addon);

        return addon;
      }

      return null;
    },
    /**
     * Update a Addon.
     * It updates a Addon and selectable Addons accordingly.
     * @param {Object} addon Addon object: Refer to ADDON_MODEL.
     */
    async updateOneAddon({ commit, getters }, addon) {
      const response = await updateAddonApi(addon);
      if (isResponseOK(response)) {
        commit(
          "updateOneAddon",
          {
            addon: parseAddon(response.data),
            index: getters.findAddonIndexById(addon.id)
          }
        );
        commit("setSelectableAddons");
        // this._vm.$notify("primary", "Success", "Addon update", { duration: 3000, permanent: false });
      } else {
        console.log("[Error]updateOneAddon:");
        // this._vm.$notify("error", "Fail", "Addon update", { duration: 3000, permanent: false });
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Update a Addon with images.
     * It updates a Addon and selectable Addons accordingly.
     * @param {Object}    addon             Addon object: Refer to ADDON_MODEL.
     * @param {Object[]}  [imageObjects[]]  Image objects: [{files, preview}]
     */
    async updateOneAddonWithImages({ commit, getters }, { addon, imageObjects }) {
      const response = await updateAddonWithImagesApi(addon, imageObjects);
      if (isResponseOK(response)) {
        commit(
          "updateOneAddon",
          {
            addon: parseAddon(response.data),
            index: getters.findAddonIndexById(addon.id)
          }
        );
        commit("setSelectableAddons");
        // this._vm.$notify("primary", "Success", "Addon update", { duration: 3000, permanent: false });
      } else {
        console.log("[Error]updateOneAddonWithImages:");
        // this._vm.$notify("error", "Fail", "Addon update", { duration: 3000, permanent: false });
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Delete a Addon (Soft).
     * It deletes a Addon and selectable Addons accordingly.
     * @param {string} id Id of a Addon.
     */
    async deleteOneAddon({ commit, getters }, id) {
      const response = await deleteAddonApi(id);

      if (isResponseOK(response)) {
        commit("deleteOneAddon", getters.findAddonIndexById(id));
        commit("setSelectableAddons");
      } else {
        console.log("[Error]deleteOneAddon:");
        // this._vm.$notify("error", "Fail", "Deleting addon", { duration: 3000, permanent: false });
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Add a Addon.
     * It adds a Addon and selectable Addons accordingly.
     * @param {Object} addon Addon object: Refer to ADDON_MODEL.
     */
    async addOneAddon({ commit }, addon) {
      const response = await addAddonApi(addon);
      if (isResponseCREATED(response)) {
        commit("addOneAddon", parseAddon(response.data));
        commit("setSelectableAddons");
      } else {
        console.log("[Error]addOneAddon:");
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Add a Addon with images.
     * It adds a Addon and selectable Addons accordingly.
     * @param {Object}    addon             Addon object: Refer to ADDON_MODEL.
     * @param {Object[]}  [imageObjects[]]  Image objects: [{files, preview}]
     */
    async addOneAddonWithImages({ commit }, { addon, imageObjects }) {
      const response = await addAddonWithImagesApi(addon, imageObjects);
      if (isResponseCREATED(response)) {
        commit("addOneAddon", parseAddon(response.data));
        commit("setSelectableAddons");
      } else {
        console.log("[Error]addOneAddonWithImages:");
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },

    // Filters
    /**
     * Apply filters.
     */
    applyAddonFilters({ commit }) {
      commit("applyAddonFilters");
    },
     /**
     * Set and apply filters.
     * @param {Object} filters Filters object.
     */
    setAddonFilters({ commit }, filters) {
      commit("setAddonFilters", filters);
      commit("applyAddonFilters");
    },
    /**
     * Reset and apply filters.
     * @param {Object} [filters] Filters object.
     */
    resetAddonFilters({ commit }, filters) {
      const emptyFilters = {
        store: { value: "", label: "" },
        group: { value: "", label: "" },
        order: { value: "", label: "" },
        keyword: "",
        ...filters,
      };
      commit("setAddonFilters", emptyFilters);
      commit("applyAddonFilters");
    },
    /**
     * Add a filtered Addon in the state.
     * @param {string} id Id of a Addon.
     */
    addOneFilteredAddon({ state, commit }, id) {
      const addon = state.addons.find(el => el.id === id);
      if (addon) {
        commit("addOneFilteredAddon", addon);
      } else { }
    },
    /**
     * Delete a filtered Addon in the state.
     * It deletes a Addon and selectable Addons accordingly.
     * @param {string} id Id of a Addon.
     */
    deleteOneFilteredAddon({ state, commit }, id) {
      const idx = state.filteredAddons.findIndex(el => el.id === id);
      commit("deleteOneFilteredAddon", idx);
    },
    /**
     * Get list of add-ons that only belong to specific group
     * @param {*} param0 
     * @param {*} addonGroupId 
     */
    getAddonOnlyBelongToSpecificGroup( { state }, addonGroupId){
      return state.addons.filter(ad => ad.groups.length == 1 && ad.groups.includes(addonGroupId));
    }
  }
};
