// TODO : find usage.
// import moment from "moment";
import moment from "moment-timezone";
import _ from "lodash";
import { TIME }  from "@/constants/common";

/**
 * Serialising string date to Y:M:D
 * 
 * @param {String} stringDate
 * @return {Object []} Object of stringDate  
 */
export let serializeDate = function (stringDate) {
  let year, month, day;
  [year, month, day] = stringDate.split("-");
  return {
    year: year,
    month: month,
    day: day
  };
};
/**
 * Serialising string date to H:M:S
 * 
 * @param {String} stringTime
 * @return {Object []} Object of stringTime  
 */
export let serializeTime = function (stringTime) {
  let hour, minute, second;
  [hour, minute, second] = stringTime.split(":");
  return {
    hour: hour,
    minute: minute,
    second: second
  };
};

export let getDateFromDayYear = function (day, year) {
  let date = new Date(year, 0, day);
  return moment(date).format("MMM Do");
}

export let getDateFromWeekYear = function (week, year) {
  const date = moment(new Date(year, 0, week * 7 + 1));
  const from_date = moment(new Date(year, 0, week * 7 + 1)).startOf('week');
  const to_date = moment(new Date(year, 0, week * 7 + 1)).endOf('week');

  return `${from_date.format("MMM Do")} - ${to_date.format("MMM Do")}`;
}

export let getMonthFromMonthYear = function (month, year) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return `${months[month]}-${year}`;
}

export let getDayFromDate = function (date) {
  return parseInt(moment(date).format("DDD"));
}

export let getWeekFromDate = function (date) {
  return parseInt(moment(date).format("ww"));
}

export let getMonthFromDate = function (date) {
  return parseInt(moment(date).format("MM")) - 1;
}

export let getYearFromDate = function (date) {
  return parseInt(moment(date).format("YYYY"));
}

export let getCurrentTime = function () {
  return moment().format("hh:mm");
}

/**
 * Get total number of days in specific year
 * @param {*} year 
 */
export let getDaysOfYear = function(year){
  return moment([year, 11, 31]).dayOfYear();
}

/**
 * Get total nubmer of weeks in specific year
 * @param {*} year 
 */
export let getWeeksOfYear = function (year) {
  return moment([year, 11, 31]).isoWeeksInYear();
}

/**
 * Formatting time using Moment
 * 
 * @param {Object []} Struct
 * @return  {String} in formated way of HH:mm
 */
export let parseTime = function (struct) {
  let padHours = _.padStart(struct.hour ? struct.hour : 0, 2, "0");
  let padMinutes = _.padStart(struct.minute ? struct.minute : 0, 2, "0");
  return moment(`1970-01-01 ${padHours}:${padMinutes}`).format("HH:mm");
};
/**
 * Formatting Date using Moment
 * 
 * @param {Object []} struct
 * @return  {String} in formated way of YYYY-MM-DD
 */
export let parseDate = function (struct) {
  return moment(`${struct.year}-${struct.month}-${struct.day}`).format(
    "YYYY-MM-DD"
  );
};

export let formatDate = function (stringDate) {
  let startDateObj = moment.utc(stringDate, "YYYY-MM-DD");
  return startDateObj.format("YYYY-MM-DD");
}

export let isValidDate = function (stringDate) {
  let startDateObj = moment.utc(stringDate, "YYYY-MM-DD");
  return (startDateObj.format("YYYY-MM-DD")) != "Invalid date";
}

/**
 * Formatting Date using Moment
 * 
 * @param {String} startDate
 * @param {String} endDate
 * @param {String} startTime
 * @param {String} endTime
 * @return  {bool} true/false according to condition
 */
export let isProperStartEnd = function (startDate, endDate, startTime, endTime) {
  let startDateObj = moment.utc(startDate, "YYYY-MM-DD");
  let endDateObj = moment.utc(endDate, "YYYY-MM-DD");

  let startTimeObj = moment.utc(startTime, "HH:mm");
  let endTimeObj = moment.utc(endTime, "HH:mm");

  let nowDateObj = moment().format("YYYY-MM-DD");

  let sameDateOrLater =
    startDateObj.isSameOrAfter(nowDateObj, "day") &&
    endDateObj.isSameOrAfter(startDateObj, "day");
  let sameDateProperTime =
    !endDateObj.isSame(startDateObj) || startTimeObj.isBefore(endTimeObj);

  return sameDateOrLater && sameDateProperTime;
};



// TODO : documenation. DONE
// get weekdays between two given dates
/**
 * Get weekdays between two given dates.
 * @param {Date} startDate Start date of the report duration.
 * @param {Date} endDate End date of the report duration.
 * @return {Array} Array containing string values of weekdays in report duration. 
*/
export let getWeekDaysWithinRange = function (startDate, endDate) {
  let start = moment(startDate);                  // moment object set to startDate.
  let end = moment(endDate);                      // moment object set to endDate.
  let days = end.diff(start, 'day') + 1;          // {number} number of days between given report duration.

  let weekdays = [];                              // empty array to hold weekdays between given report duration.

  const order = {                                 // set order for weekdays
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6,
    "Sunday": 7
  }

  while (days != 0 && weekdays.length < 7) {   // if number of days in report duration is not zero    
    weekdays.push(start.format('dddd'));       // AND weekdays array has less than 7 weekdays
    start.add(1, 'days');                      // add object containing weekday as string to weekdays array
    days--;                                    // increase the start to next date
  }                                            // and reduce the number of days in report duration by 1

  // Sort the weekdays array by string value of weekday using order given in order object
  weekdays.sort((weekDayA, weekdayB) => order[weekDayA] - order[weekdayB]);

  return weekdays;
}

export let getTodayWeekDay = function () {
  let today = moment(new Date());

  return today.format('dddd');
}

/**
 * Converts hour in 24 hour clock to 12 hour clock
 * @param {Number} hour Hour to be converted.
 * @return {String} String representing 12 hr clock of input 24 hour clock.
*/
export let convertHourFormat24To12 = function (hour) {

  return hour < 12 ? `${hour}:00 am`
    : (hour === 12 ? `00:00 pm` : `${hour - 12}:00 pm`);
}

// TODO: remove - not being used.
/**
 * Get offset of the timezone as string from the GMT offset value.
 * e.g. -04:00 (Toronto)
 * @param {number} offset GMT offset number in seconds.
 */
export let getTimeZoneOffsetString = function (offset) {
  let strTimeZoneOffset;
  let offsetAsTime = offset / 3600;
  // console.log('offsetAsTime', offsetAsTime);
  if (offsetAsTime < 0) {
    strTimeZoneOffset = '-';
    offsetAsTime = offsetAsTime - (offsetAsTime * 2);
  } else {
    strTimeZoneOffset = '+';
  }

  const parsedOffset = _.padStart(offsetAsTime ? offsetAsTime : 0, 2, "0");
  // console.log('parsedOffset', parsedOffset);
  strTimeZoneOffset = strTimeZoneOffset + parsedOffset + ':00';
  // console.log('strDate', strDate);
  return strTimeZoneOffset;
}

/**
 * Get offset of the timezone as string from the zoneName value.
 * e.g. -04:00 or -05:00(Toronto) depends on day saving time.
 * @param {number} zoneName Zone name. e.g."America/Toronto"
 */
export let getTimeZoneOffsetStringByZoneName = function (zoneName) {

  const m = moment.tz(zoneName);
  const offset = m.utcOffset();

  let strTimeZoneOffset;
  let offsetAsTime = offset / 60;
  // console.log('offsetAsTime', offsetAsTime);
  if (offsetAsTime < 0) {
    strTimeZoneOffset = '-';
    offsetAsTime = offsetAsTime - (offsetAsTime * 2);
  } else {
    strTimeZoneOffset = '+';
  }

  const parsedOffset = _.padStart(offsetAsTime ? offsetAsTime : 0, 2, "0");
  // console.log('parsedOffset', parsedOffset);
  strTimeZoneOffset = strTimeZoneOffset + parsedOffset + ':00';
  // console.log('strDate', strDate);
  return strTimeZoneOffset;
}

/**
 * Get Date range(startDate, endDate) in format of '2020-10-01T00:00:00.000'.
 * e.g. {startDate: '2020-10-01T00:00:00.000', endDate: '2020-10-01T23:59:59.999'}
 * @param {number} startOffset Start offset of the date from today.
 * @param {number} endOffset End offset of the date from today.
 * @return {Object} {startDate, endDate:}
 */
export let getFormatedDateRange = function (startOffset, endOffset) {
  // TODO : validate the input.
  const date = new Date();

  let startDate = new Date();
  startDate.setDate(date.getDate() + startOffset);

  let endDate = new Date();
  endDate.setDate(date.getDate() + endOffset);

  const strStartDate = startDate.getFullYear() + '-'
    + _.padStart(startDate.getMonth() + 1, 2, "0") + '-'
    + _.padStart(startDate.getDate(), 2, "0") + 'T00:00:00.000';

  const strEndDate = endDate.getFullYear() + '-'
    + _.padStart(endDate.getMonth() + 1, 2, "0") + '-'
    + _.padStart(endDate.getDate(), 2, "0") + 'T23:59:59.999';

  return { startDate: strStartDate, endDate: strEndDate };
}

/**
 * Get Date range(startDate, endDate) in format of 'YYYY-MM-DDTHH:mm:ssZ'.
 * e.g. {startDate: '2020-10-01T00:00:00-05:00', endDate: '2020-10-01T23:59:59-05:00'}
 * @param {number} startDate Start date.
 * @param {number} endDate End date.
 * @return {Object} {startDate, endDate:}
 */
export let parseDateRange = function (startDate, endDate) {
  
  if (!startDate || !endDate) 
    return { startDate: null, endDate: null }
  
  return { 
    startDate: moment(startDate).format(),  
    endDate:  moment(endDate).endOf('day').format() // NOTE: Make sure to set the endDate time to end of the day (23:59:59) using moment().endOf('day')
  };
}

export let transformTimeWithTimeZone = function(timezone, time){
  return new Date(time.toLocaleString('en-US', { timeZone: timezone }));
}

/**
 * Find difference time between 2 date base on unit which is SECOND, MINUTE, HOUR OR DAY
 * @param {Date} timeA 
 * @param {Date} timeB 
 * @param {Constant TIME} unit 
 */
export let differentTime = function(timeA, timeB, unit){
  let base = 0;
  
  switch(unit){
    case TIME.SECOND:
      base = 1000;
      break;
    case TIME.MINUTE:
      base = 1000 * 60;
      break;
    case TIME.HOUR:
      base = 1000 * 60 * 60;
      break;
    default:
      base = 1000 * 60 * 60 * 24;
  }

  if(timeA.getTime() > timeB.getTime())
    return Math.floor((timeA.getTime() - timeB.getTime()) / (base));
  return Math.ceil((timeA.getTime() - timeB.getTime()) / (base));
}

export let addTime = function(org, addedTime, unit){
  let addedUnit = '';

  switch (unit) {
    case TIME.SECOND:
      addedUnit = "seconds";
      break;
    case TIME.MINUTE:
      addedUnit = "minutes";
      break;
    case TIME.HOUR:
      addedUnit = "hours";
      break;
    default:
      addedUnit = "days";
  }

  let result = moment(org);                  // moment object set to startDate.
  result = result.add(parseInt(addedTime), addedUnit);

  return new Date(result);
}

export let compareDate = function(a, b){
  if (a.year < b.year)
    return -1;
  if (a.year == b.year) {
    return (a.day > b.day || a.week > b.week || a.month > b.month) ? 1 : -1;
  }
  return 1;
}

/**
 * Converts 100 to 1 hour(s) 40 minute(s).
 * @param {Integer} time Example: 120 hours
 */
export function formatTime(time) {
  const hour = Math.floor(time / 60);
  const minute = time % 60
  const hourString = hour && hour > 0 ? `${hour} ${this.$t('general.hours')}` : "";
  const minuteString = minute && minute > 0 ? `${minute} ${this.$t('general.minutes')}` : "";

  if (hourString === "" && minuteString === "") {
    return null;
  } else {
    return `${hourString} ${minuteString}`;
  }
}


/**
 * Format the Date with time as MMMM DD, YYYY - hh:mm AM/PM.
 * @param {String} date
 * @return  {String} in formated way with required locale
 */
export function formatLongDateWithTime(date) {
  if (date) {
    return moment(new Date(date)).format("MMMM DD, YYYY - hh:mm A");
  } else {
    return "";
  }
}


/**
 * Formats Date range object to string value => "yyyy-mm-dd - yyyy-mm-dd"
 * @param {Object} {start, end} startDate and endDate 
 * @returns string value => "yyyy-mm-dd - yyyy-mm-dd"
 */
export function formatRangeValue({start, end}) {

  const format = "YYYY-MM-DD";
  const startDate = new Date(start);
  const endDate = new Date(end);


  return `${moment(startDate).format(format)} - ${moment(endDate).format(format)}`;
  
}