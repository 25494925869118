// Sidebar of Orders.
// It contains list of all orders.
// Author : Huen Oh (heons921@gmail.com)


<template>
  <div :data-cy="`${cyPrefix}`" :class="{'global-warning-space':!isStripeConnected}">
    <!-- Title -->
    <a href="javascript:void(0);" id="orders-sidbar-title" @click="onClickOrderTitle">
      <h4 class="text-center font-weight-bold my-2">
        {{$t('order.your-orders')}}
      </h4>
      <b-tooltip target="orders-sidbar-title" placement="bottom" triggers="hover" :title="$t('tool-tips.refesh')" />
    </a>

    <!-- Search by order number -->
    <div class="search-sm d-inline-block mb-3 ml-4">
      <b-input 
        data-cy="dlg-product-group-input-search"
        :placeholder="$t('menu.search')" 
        v-model="searchKeyword"
      />
    </div>

    <!-- Buttons for Order Type Filters -->
    <b-row class="order-type-block order-type-grid" align-h="between">
      <b-col :data-cy="`${cyPrefix}-filter-new-orders`" class="order-type-cols order-type-border"  @click="OnClickOrderTypeBtn(null)" :class="{selected:selectedTypes.new}">
        <span class="order-type-icon font-weight-bold theme-color-text">{{$t('order.serve-status.new').toUpperCase()}}
          <b-badge variant="secondary" class="badge-top-right circle-badge">{{numberNewOrders}}</b-badge>
        </span>
      </b-col>

      <b-col :data-cy="`${cyPrefix}-filter-accepted-orders`" class="order-type-cols order-type-border"  @click="OnClickOrderTypeBtn('ACCEPTED')" :class="{selected:selectedTypes.accepted}">
        <span class="order-type-icon font-weight-bold theme-color-text">{{$t('order.serve-status.accepted').toUpperCase()}}
          <b-badge variant="secondary" class="badge-top-right circle-badge">{{numberAcceptedOrders}}</b-badge>
        </span>
      </b-col>

      <b-col :data-cy="`${cyPrefix}-filter-preparing-orders`" class="order-type-cols order-type-border" @click="OnClickOrderTypeBtn('PREPARING')" :class="{selected:selectedTypes.preparing}">
        <span class="order-type-icon font-weight-bold theme-color-text">{{$t('order.serve-status.preparing').toUpperCase()}}
          <b-badge variant="secondary" class="badge-top-right circle-badge">{{numberPreparingOrders}}</b-badge>
        </span>
      </b-col>

      <b-col :data-cy="`${cyPrefix}-filter-ready-orders`" class="order-type-cols order-type-border" @click="OnClickOrderTypeBtn('READY')" :class="{selected:selectedTypes.ready}">
        <span class="order-type-icon font-weight-bold theme-color-text">{{$t('order.serve-status.ready').toUpperCase()}}
          <b-badge variant="secondary" class="badge-top-right circle-badge">{{numberReadyOrders}}</b-badge>
        </span>
      </b-col>
    </b-row>
    
    <!-- List of Orders -->
    <vue-perfect-scrollbar class="scroll" :settings="{ suppressScrollX: true, wheelPropagation: false }">
      <ListOrderCard 
        v-for="(order, index) in filteredOrders" :key="index"
        :cyPrefix="`list-item-order-${index}`"
        :data="order"
        :class="{selected:(currentOrder && (order.id===currentOrder.id))? true : false}"
        @click="onClickOrderCard(order)"
      />
      
    </vue-perfect-scrollbar>
   
  </div>
</template>


<script>
import ListOrderCard from '@/components/Listing/ListOrderCard'
import { ENUM_ORDER_SERVING_STATUS_STRING } from '@/models/order'

// utils
import { sortByLastUpdatedAtDecending } from "@/utils/sort.js"

// Mapper of vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  components: {
    'ListOrderCard': ListOrderCard,
  },
  props:{
    // {string} Prefix for data-cy.
    cyPrefix: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      selectedTypes : {
        new: true,
        accepted: false,
        preparing: false,
        ready: false,
      },
      // search order by last 4 digits order number
      searchKeyword: "",
    }
  },
  computed: {
    ...mapGetters("order", [
      "orders",
      "currentOrder",
      "currentStore",
    ]),

    ...mapGetters("payments",       ["isStripeConnected"]),
    ...mapGetters("merchant_store", ["selectableStores", "numOfStores"]),

    // Selected store object (including other information of the selected store)
    selectedStore() {
      const storeId = this.currentStore.value;
      return store = this.findStoreById(storeId);
    },

    // Filtered Orders by serve Status.
    filteredOrders () {
      let filtered = [];
      
      this.orders.map(o => {
        // Return order if the order matches the search keyword
        if(o.id.substring(o.id.length - 4).includes(this.searchKeyword)){
          if(this.selectedTypes.new && o.serveStatus === null){
            filtered.push(o);
          }
          if(this.selectedTypes.accepted && o.serveStatus === ENUM_ORDER_SERVING_STATUS_STRING.ACCEPTED){
            filtered.push(o);
          }
          if(this.selectedTypes.preparing && o.serveStatus === ENUM_ORDER_SERVING_STATUS_STRING.PREPARING){
            filtered.push(o);
          }
          if(this.selectedTypes.ready && (o.serveStatus === ENUM_ORDER_SERVING_STATUS_STRING.READY 
                                          || o.serveStatus === ENUM_ORDER_SERVING_STATUS_STRING.DELIVERING)){
            filtered.push(o);
          }
          return o;
        }
      });

      filtered.sort(sortByLastUpdatedAtDecending);

      return filtered;
    },
    numberNewOrders(){
      return this.orders.filter(order => order.serveStatus === null).length;
    },
    numberAcceptedOrders(){
      return this.orders.filter(order => order.serveStatus === ENUM_ORDER_SERVING_STATUS_STRING.ACCEPTED).length;
    },
    numberPreparingOrders(){
      return this.orders.filter(order => order.serveStatus === ENUM_ORDER_SERVING_STATUS_STRING.PREPARING).length;
    },
    numberReadyOrders(){
      return this.orders.filter(order => order.serveStatus === ENUM_ORDER_SERVING_STATUS_STRING.READY
                                       || order.serveStatus === ENUM_ORDER_SERVING_STATUS_STRING.DELIVERING).length;
    }
  },
  methods: {
    // Map actions.
    ...mapActions("order", [
      "loadOrdersByStoreId",
      "loadOneOrder",
      "setCurrentOrder"
    ]),
    ...mapActions("merchant_store", [
      "findStoreById",
      "loadStores"
    ]),
    /**
     * On click order card item.
     * It set current Order with the selection.
     */
    onClickOrderCard(order) {
      this.loadOneOrder(order.id);
    },
    /**
     * On click order title.
     * It refreshes the list or Orders.
     */
    onClickOrderTitle() {
      if(this.currentStore.label) {
        this.loadOrdersByStoreId(this.currentStore.value);
      } else {}
    },
    /**
     * On click order type buttons.
     * It filters the order list based on selected serve status.
     */
    OnClickOrderTypeBtn(serveStatus) {
      // Reset all filter
      this.selectedTypes = {
        new: false,
        accepted: false,
        preparing: false,
        ready: false
      };

      // Select the chosen type
      switch(serveStatus){
        case null:
          this.selectedTypes.new = !this.selectedTypes.new;
          break;
        case ENUM_ORDER_SERVING_STATUS_STRING.ACCEPTED:
          this.selectedTypes.accepted = !this.selectedTypes.accepted;
          break;
        case ENUM_ORDER_SERVING_STATUS_STRING.PREPARING:
          this.selectedTypes.preparing = !this.selectedTypes.preparing;
          break;
        case ENUM_ORDER_SERVING_STATUS_STRING.READY:
          this.selectedTypes.ready = !this.selectedTypes.ready;
          break;
      }

      // Select the first order in the list
      if(this.filteredOrders.length > 0){
        this.loadOneOrder(this.filteredOrders[0].id);
      }
      // Otherwise, empty current order
      else{
        this.setCurrentOrder({});
      }

      if ((!this.selectedTypes.new) && (this.currentOrder.serveStatus === null)) {
        this.setCurrentOrder({});
      } // else
      if ((!this.selectedTypes.preparing) && (this.currentOrder.serveStatus === ENUM_ORDER_SERVING_STATUS_STRING.PREPARING)) {
        this.setCurrentOrder({});
      } // else
      if ((!this.selectedTypes.ready) && (this.currentOrder.isPickup === ENUM_ORDER_SERVING_STATUS_STRING.READY)) {
        this.setCurrentOrder({});
      } // else
    }
  },
  watch:{
    currentStore: function(){
      // Reset search keyword when switch store
      this.searchKeyword = "";
    }
  }
};
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>

