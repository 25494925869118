var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "root" } }, [
    _c("div", { staticClass: "fixed-background" }),
    _vm._v(" "),
    _c("main", [
      _c("div", { staticClass: "container" }, [_c("router-view")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }