// Section to display global points usage. 
// Props: cyPrefix  {String} - prefix for data-cy attribute
// 				data			{Object} - contains globalPointsUsed and globalPointsEquivalentAmount for Order.
// Author: Sukhpreet Singh (sukhpreets6718@gmail.com)

<template>
	<b-card class="m-3">
		<h1 class="font-weight-bold">{{$t('global-points.global-points-title')}}</h1>
		<b-card 
			:data-cy="`${cyPrefix}-global-points`"
			class="order-promotion-card text-white rounded py-2 px-3 my-2"
			no-body
		>
			<b-row>
				<b-col cols="6" class="text-left">
					<h4 class="m-0 p-0">{{formatNumber(data.globalPointsUsed)}} {{$t('order.global-points-suffix')}}</h4>
				</b-col>

				<b-col cols="6" class="text-right">
					<h4 class="m-0 p-0" :data-cy="`${cyPrefix}-global-points-money-conversion`">
						{{$t('order.details.converts-to')}}: ${{data.globalPointsEquivalentAmount ? formatPrice(data.globalPointsEquivalentAmount) : ''}}
					</h4>
				</b-col>

			</b-row>
		</b-card>
	</b-card>
</template>

<script>

import { formatNumber }  from "@/utils/general";

export default {
	props: {
		// {string} Prefix for data-cy.
    cyPrefix: {
      type: String,
      default: ""
    },
    // {Object} globalPointsUsed, globalPointsEquivalentAmount
    data: {
      type: Object,
      default:() => {}
    }
	},
	methods: {
		formatNumber, 

		formatPrice(num) {
      const isToFixed = true;
      return this.formatNumber(num, isToFixed);
    },
	}
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>