// Dialog componet of ProductGroup for Add/EditShow
// Author : Huen Oh (heons921@gmail.com)
// @param {bool}    dialog  Handler variable for the dialog(Close and Open).
// @param {Object}  data    ProductGroup object: Refer to PRODUCT_GROUP_MODEL and EMPTY_PRODUCT_GROUP. It should be a copied object.
// TODO : may need @on-submit for further action.

<template>
  <!-- Dialog for ProductGroup -->
  <b-modal id="modalProductGroup" ref="modalProductGroup" size="lg" @hide="onCencel()">
    <!-- Emulate built in modal header with custom title and buttons -->
    <template v-slot:modal-header>
       <!-- Header Component -->
      <HeaderDialogProduct
        title="objects.product-group"
        name="dlg-product-group"
        :status="data.status"
        :disabled="data.showOnly || (productsInGroup.length <= 0)"
        :actionType="actionType"
        @clickSchedule="openDialogSchedule"
        @changeStatus="updateStatus"
      />
    </template>

    <b-form>
      <!-- Dialog for Alert -->
      <dialog-alert
        :dialog="dialogs.alert"
        :description="$t('alert.no-change')"
        :actionType="actionType"
        :buttonSubmitText="$t('buttons.ok')"
        @dialog-close="closeDialogAlert"
        @dialog-submit="onSubmitDialogAlert"
      />
      <!-- Dialog for Date/Time -->
      <dialog-date-time
        :disabled="data.showOnly"
        :data="data"
        :dialog="dialogs.schedule"
        @dialog-close="closeDialogSchedule"
        @dialog-submit="onSubmitDialogSchedule"
      />
      <!-- Dialog for Product -->
      <dialog-product
        :dialog="dialogs.product"
        :data="currentProduct"
        @dialog-close="closeDialogProduct"
      />
      <b-row>
        <!-- Left -->
        <b-colxx sm="6">
          <!-- Name and Description -->
          <EditNameDescription
            name="dlg-product-group"
            :data="data"
            label="product-group"
            :disabled="data.showOnly"
            :isFirstLoad="isFirstLoad"
          />
        </b-colxx>
        <!-- Right -->
        <b-colxx sm="6">
          <!-- Image Selection -->
          <b-form-group>
            <h6>{{$t('product.choose-image')}}</h6>
            <ImagesSelection
              name="dlg-product-group"
              v-model="isChanged"
              :images="data.images"
              :single="true"
              :disabled="data.showOnly"
              key="selImage"
              ref="selImage"
            />
          </b-form-group>
        </b-colxx>
      </b-row>

      <b-row align-v="end" class="mt-2">
        <!-- Left -->
        <BaseSelectItem
          :colSize="sizeColMain"
          :disabled="data.showOnly"
          :title="$t('product-group.add-product-to-group')"
          cyPrefix="dlg-product-group"
          cySuffix="product"
          :noOptionsMessage="$t('validations.no-product-available')"
          :options="currentSelProductOptions"
          @input="addProductToGroup"
        />
        <!-- Right -->
        <b-colxx sm="6">
          <!-- Search -->
          <div class="search-sm d-inline-block float-md-right m-3">
            <b-input 
              data-cy="dlg-product-group-input-search"
              :placeholder="$t('menu.search')" 
              v-model="searchKeyword"
            />
          </div>
        </b-colxx>
      </b-row>
      <!-- Table List for added product -->
      <TableListProducts
        cyPrefix="dlg-product-group"
        cySuffix="products"
        :items="searchedProductsInGroup"
        :disabled="data.showOnly"
        :selectableGroups="selectableProductGroups"
        @changeStatus="updateOneProduct" 
        @editItem="onEditProduct"
        @deleteItem="onDeleteProduct" 
      />
    </b-form>

    <!-- Footer -->
    <template slot="modal-footer">
      <FooterDialogProduct
        name="dlg-product-group"
        :disabled="$v.data.$invalid && !isFirstLoad"
        :actionType="actionType"
        @click-cancel="onCencel"
        @click-submit="onSubmit"
      />
    </template>
  </b-modal>
</template>


<script>
import vSelect from "vue-select"

import DialogAlert             from '@/components/Common/DialogAlert'
import DialogDateTime          from '@/components/Common/DialogDateTime'
import ImagesSelection         from '@/components/Common/ImagesSelection';
import FooterDialogProduct     from '@/components/Common/FooterDialogProduct'
import HeaderDialogProduct     from '@/components/Common/HeaderDialogProduct'
import TableListProducts     from '@/components/Common/TableListProducts'
import BaseSelectItem          from '@/components/Common/BaseSelectItem'
import ToggleButtonStatus      from '@/components/Forms/ToggleButtonStatus'
import EditNameDescription     from '@/components/Forms/Editbox/EditNameDescription'


import { getNamesByIdsFromList, isResponseCREATED } from '@/utils/general'

// Notifications
import {
  addNotifySuccess,
  addNotifyWarning,
  addNotifyFail,
} from "@/utils/notify.js"

// For validation
import { validationMixin } from "vuelidate";
const { required, minLength, maxLength } = require("vuelidate/lib/validators");

import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  components:{
    vSelect,
    DialogAlert,
    DialogDateTime,
    ImagesSelection,
    ToggleButtonStatus,
    EditNameDescription,
    HeaderDialogProduct,
    FooterDialogProduct,
    BaseSelectItem,
    TableListProducts,
    DialogProduct: () => import('@/components/Products/DialogProduct'), // Async import
  },
  props: {
    // {bool} Handler variable for the dialog(Close and Open).
    dialog: {
      type: Boolean,
      default: false,
    },
    // {Object} ProductGroup object: Refer to PRODUCT_GROUP_MODEL and EMPTY_PRODUCT_GROUP. It should be a copied object.
    data: {
      type: Object,
      default: () => {},
    }
  },
  data(){
    return{
      sizeColMain:10,   // {number} Size of the column for forms.
      actionType: "",   // {string} Type of action : "Add", "Edit" or "Show"

      isChanged: false, // Flag if anything has changed in the dialog.
      isfirstWatch: {   // Flag if it the first watch to detemine isChanged flag.
        data: true,
      },
      isStatusChanged: false, // To check status is changed. To not to update unintentionally edits //TODO : move to the component
      
      dialogs: {        // Handler object for dialogs(Close and Open).
        schedule:false,
        alert:false,
        product:false,
      },

      currentProduct:{},  // Current Product data for Addon dialog.

      selProduct:{},      // {Object} Selected Product to add.
      addedProducts:[],   // {Object[]} Added Products by Product Selection to add.
      searchKeyword: "",  // {string} Search keyword to search Products in the table.

      // For the changes in the table.
      isTableChanged: false, // {boolean} Flag if there is a change in the table.
      copiedProducts: [],    // {string[]) Copied productIds from to be comared with addProductList in duplication mode to update isTableChanged.

      // is First Load, in order to not display validation message at first
      isFirstLoad: true
    }
  },
  // Validations
  mixins: [validationMixin],
  validations: {
    data: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(35)
      },
      description: {
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
    }
  },

  methods: {
    // Get names(labels) where [list].value == [ids]
    getNamesByIdsFromList,

    // Notifications
    addNotifySuccess,
    addNotifyWarning,
    addNotifyFail,

    // Map actions.
    ...mapActions('product', [
      "updateOneProduct",
      "resetProductFilters",
      "addOneFilteredProduct",
      "deleteOneFilteredProduct"
    ]),
    ...mapActions("product_group", [
      "addOneProductGroupWithImage", 
      "updateOneProductGroupWithImage",
      "updateQueriedSchedule",
      "getQueriedSchedule",
      ]),

      ...mapMutations("product_group", ["sortProductGroupsByRank"]),

    /**
     * Reset all variables.
     */
    reset() {
      this.isChanged = false;
      this.isfirstWatch.data = true;
      this.isStatusChanged = false;
      this.addedProducts = [];

      this.isTableChanged = false;
      this.copiedProducts = [];
    },
    /**
     * Initialize the dialog with input data.
     */
    initDialog() {
      // console.log('initDialog', this.data);

      // Reset
      this.reset();
      if(this.data.id) {
        // Reset the Product filters to show the list of Products in a ProductGroup
        this.resetProductFilters({group:{value:this.data.id, label:this.data.name}});
      }

      // TODO : It is a temporary fix. We need to find at which point the products becomes null.
      // Check the products and make it empty if it is not a list.
      if (!Array.isArray(this.data.products)) {
        this.data.products = [];
      } else {}
      
      // Update product list on duplication
      if(!this.data.id) {
        if(this.data.products.length > 0) {
          for(let productId of this.data.products) {
            const product = this.findProductById(productId);
            if(product) {
              this.data.addProductList.push(productId);
              this.addedProducts.push(product); 
              this.copiedProducts.push(productId);
            }
          }
          this.data.products = [];
        }
      }
      // console.log(this.filteredProducts);
      // console.log(this.products);
      // console.log(this.addedProducts);

      // Set title
      if (this.data.showOnly) {
        this.actionType = this.$t('actions.show');
      } else if (this.data.id) {
        this.actionType = this.$t('actions.edit');
      } else {
        this.actionType = this.$t('actions.add');
      }
    },

    /**
     * On Cencel button : Close the dialog.
     */
    onCencel() {
      this.isFirstLoad = true;

      this.$emit('dialog-close');
    },
    /**
     * On Submit button : Save the data and Close the dialog.
     */
    onSubmit() {
      this.isFirstLoad = false;

      // Not submit the form if fail validation
      if(this.$v.$invalid){
        return;
      }

      if(this.data.showOnly === true) {
        // Show -> Edit
        this.data.showOnly = false;
        this.actionType = this.$t('actions.edit');
      } else {
        if(this.data.id) { //Edit
          this.submitChanges();
        } else {
          // Add
          if(this.isChanged || this.isTableChanged) {
            this.submitChanges();
          } else {
            // There is no change, are you sure?
            this.openDialogAlert();
          }
        }
      }
    },
    /**
     * Submit changes of the dialog.
     */
    submitChanges() {
      // console.log("onSubmit", this.data);

      // Set image object for the change.
      let imageObject = null;
      const imageObjects = this.$refs.selImage.getImageObjects(); 
      if(imageObjects.length > 0) {
        imageObject = imageObjects[0];
      } else {}
      // console.log('submitChanges', imageObject);

      // Select what to do: Update or Add by id
      if(this.data.id != undefined) {
        // Update - PUT
        if(!this.isStatusChanged) {
          delete this.data.status;
        }
        this.updateProductGroup(this.data, imageObject);
        // TODO : add emit on-submit?
      } else {
        // Add - POST
        // Add a ProductGroup with images.
        // If there is no images, the product will be added without images
        this.addProductGroup(this.data, imageObject);
        // TODO : add emit on-submit?
      }
      this.$emit('dialog-close');
    },

    /**
     * Add a ProductGroup to the server.
     * @param {Object} productGroup ProductGroup object: Refer to PRODUCT_GROUP_MODEL and EMPTY_PRODUCT_GROUP.
     * @param {Object} imageObject  Image object: {files, preview}
     */
    async addProductGroup(productGroup, imageObject) {
      // console.log("addProductGroup");
      productGroup.products = productGroup.addProductList;
      let response = await this.addOneProductGroupWithImage({group:productGroup, imageObject:imageObject});
      if (isResponseCREATED(response)) {
        await this.updateQueriedSchedule(productGroup);
        this.getQueriedSchedule();
        this.sortProductGroupsByRank();
        this.addNotifySuccess(this.$t('notify.product-group-add'));
        this.$emit('onAddItem', response.data.id);
      } else {
        this.addNotifyFail(this.$t('notify.product-group-add'));
      }
    },
    /**
     * Update a ProductGroup to the server.
     * @param {Object} productGroup ProductGroup object: Refer to PRODUCT_GROUP_MODEL and EMPTY_PRODUCT_GROUP.
     * @param {Object} imageObject  Image object: {files, preview}
     */
    async updateProductGroup(productGroup, imageObjects){
      // console.log("updateProductGroup", productGroup);
      await this.updateOneProductGroupWithImage({group:productGroup, imageObjects:imageObjects});
      await this.updateQueriedSchedule(productGroup);
      this.getQueriedSchedule();
      this.sortProductGroupsByRank();
      this.addNotifySuccess(this.$t('notify.product-group-update'));
    },

    /**
     * Update status of the item
     * @param {string} status Status: "ACTIVE" or "INACTIVE"
     */
    updateStatus(status) {
      this.data.status = status;
      this.isStatusChanged = true;
    },

    // Dialog operations - Schedule
    /**
     * Close Schedule dialog.
     */
    closeDialogSchedule() {
      this.dialogs.schedule = false;
    },
    /**
     * Open Schedule dialog.
     */
    openDialogSchedule() {
      this.dialogs.schedule = true;
    },
    /**
     * On Submit Schedule dialog.
     * Update Schedule - hideUntil and availableTime.
     * @param {Object} data  Schedule object: Refer to EMPTY_SCHEDULE.
     */
    onSubmitDialogSchedule(data) {
        // console.log(data);
      this.data.hideUntil = data.hideUntil;
      this.data.availableTime = data.availableTime;
      this.data.isSyncedInGroup = data.isSyncedInGroup;
      // console.log(this.data);
    },


    // Dialog operations - Product
    /**
     * Close Product dialog - Add.
     */
    closeDialogProduct() {
      this.dialogs.product = false;
    },
    /**
     * Open Product dialog - Add.
     * @param {Objec} addon Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
     */
    openDialogProduct(product) {
      this.currentProduct = JSON.parse(JSON.stringify(product)); // Copy
      this.dialogs.product = true;
    },
    // onSubmitDialogProduct() in dialog.

    // Dialog operations - Unchanged submit Alert
    /**
     * Close Alert dialog - Unchanged submit.
     */
    closeDialogAlert() {
      this.dialogs.alert = false;
    },
    /**
     * Open Alert dialog - Unchanged submit.
     */
    openDialogAlert() {
      this.dialogs.alert = true;
    },
    /**
     * On Submit Alert dialog - Unchanged submit.
     * Submit the changes.
     */
    onSubmitDialogAlert() {
      this.submitChanges();
    },

    // TODO : revise with updateStatus()
    /**
     * Change a Product' status to the server.
     * @param {Object} product  Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
     */
    onChangeProductStatus(product) {
      const refName = 'toggle-status-' + product.id;
      product.status = this.$refs[refName].getSwitchValueToString();
      this.updateOneProduct(product);
    },
    /**
     * Open Product dialog to edit.
     * @param {Object} product  Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
     */
    onEditProduct(product) {
      // console.log('onEditProduct', product);
      product.showOnly = this.data.showOnly;
      this.openDialogProduct(product);
    },
    /**
     * Change a Product locally..
     * @param {string} productId  Id of a Product object.
     */
    onDeleteProduct(productId) {
      // console.log('onDeleteProduct', productId);
      const idx = this.data.addProductList.findIndex(el => el==productId);
      if(idx != -1) {
        this.data.addProductList.splice(idx, 1);
        this.addedProducts.splice(idx, 1);
      } else {
        // To check product's groups
        const product = this.findProductById(productId);

        // To delete
        this.deleteOneFilteredProduct(productId);
        this.data.deleteProductList.push(productId);
      
      }

      // Update isTableChanged value.
      this.updateIsTableChanged();
    },
    addProductToGroup(selProduct) { //TODO : may not need - watch.selProduct
      // console.log("addProductToGroup", product);
      if((selProduct != null) && (selProduct.value !== "")){
        const productId = this.data.products.find(el => el === selProduct.value);
        // console.log('productId', productId);
        if(!productId) {
          const product = this.findProductById(selProduct.value);
          this.addedProducts.push(product); // To display // TODO : color?
          // Added to product list to be added
          this.data.addProductList.push(selProduct.value);
          
        } else {
          const deletedProductIdx = this.data.deleteProductList.findIndex(el => el === selProduct.value);
          // console.log('deletedProductIdx', deletedProductIdx);
          // It is being resotred. Remove it from deleteProductList
          if(deletedProductIdx != -1) {
            this.addOneFilteredProduct(selProduct.value);
            this.data.deleteProductList.splice(deletedProductIdx, 1);
          } else {}
        }

        // Update isTableChanged value.
        this.updateIsTableChanged();
      } else {}
    },
    // TODO : find way to use either computed or watch. - tried and failed once.
    /**
     * Update isTableChanged based on the table changes.
     */
    updateIsTableChanged() {
      let changed = false;
      if(!this.data.id) {
        if(this.copiedProducts.length > 0) {
          // TODO : Make it as general function.
          // Compare products and addProductList
          if(this.copiedProducts.length === this.data.addProductList.length) {
            this.copiedProducts.forEach((productId) => {
              if(this.data.addProductList.includes(productId)) {
                // Nothing.
                // console.log('changed-false', changed)
              } else {
                // console.log('changed-true', changed)
                changed = true;
              }
            })
          } else {
            changed = true;
          }
        } else {
          changed = (this.data.addProductList.length > 0) || (this.data.deleteProductList.length > 0);
        }
      } else {
        changed = (this.data.addProductList.length > 0) || (this.data.deleteProductList.length > 0);
      }

      this.isTableChanged = changed;
      // console.log('this.data.addProductList', this.data.addProductList)
      // console.log('this.data.deleteProductList', this.data.deleteProductList)
      // console.log('this.isTableChanged', this.isTableChanged)
    }
  },

  computed:{
    // Map gatters
    ...mapGetters("product", [
      "products", 
      "selectableProducts", 
      "filteredProducts", 
      "filters", 
      "findProductById"
    ]),
    ...mapGetters("product_group",  ["selectableProductGroups"]),

    // Products in a ProductGroup
    productsInGroup(){
      if(this.data.id) {
        // Edit/Show
        return [...this.filteredProducts, ...this.addedProducts];
      } else {
        // Add
        return [...this.addedProducts];
      }
    },
    // Searched Products in a ProductGroup
    searchedProductsInGroup() {
      return this.productsInGroup.filter((product) => {
        return product.name.toLowerCase().includes(this.searchKeyword.toLowerCase());
      })
    },

    // Computed value to exclude selected items from the selection option.
    currentSelProductOptions: function() {
      return this.selectableProducts.filter((item) => {
        return !this.productsInGroup.map((product) => {
          return product.id;
        }).includes(item.value) && (item.value != "");
      });
    },
  },
  watch: {
    /**
     * Watch to control the dialog: Show, Hide.
     * @param {bool} newVal New value.
     * @param {bool} oldVal Old value.
     */
    dialog: function(newVal, oldVal){
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if(newVal == true) {
        this.initDialog();
        this.$refs["modalProductGroup"].show();
      } else {
        this.$refs["modalProductGroup"].hide();
      }
    },

    /**
     * Watch to check changes in the data.
     */
    data: {
      deep: true,
      // immediate: true,
      handler() {
        if(this.isfirstWatch.data == false) {
          if(this.isChanged == false) {
            this.isChanged = true;
            // console.log("watch data changed");
          }
        } else {
          // console.log("watch data changed", this.data);
          this.isfirstWatch.data = false;
        }
      },
    },

    /**
     * Watch to add a Product in a ProductGroup.
     
    selProduct: {
      deep: true,
      handler() {
        if((this.selProduct != null) && (this.selProduct.value !== "")){
          const productId = this.data.products.find(el => el === this.selProduct.value);
          // console.log('productId', productId);

          if(!productId) {
            const product = this.findProductById(this.selProduct.value);
            this.addedProducts.push(product); // To display // TODO : color?
            // Added to product list to be added
            this.data.addProductList.push(this.selProduct.value);
            
          } else {
            const deletedProductIdx = this.data.deleteProductList.findIndex(el => el === this.selProduct.value);
            // console.log('deletedProductIdx', deletedProductIdx);
            // It is being resotred. Remove it from deleteProductList
            if(deletedProductIdx != -1) {
              this.addOneFilteredProduct(this.selProduct.value);
              this.data.deleteProductList.splice(deletedProductIdx, 1);
            } else {}
          }

          // Reset selection.
          this.selProduct = null;
          // console.log(product);

          // Update isTableChanged value.
          this.updateIsTableChanged();
        } else {}
      }
    },

    **/
  },

  mounted(){ 
    
  }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>

