var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "card-body d-flex flex-column flex-lg-row justify-content-between p-0",
    },
    [
      _c("h1", { staticClass: "mb-0" }, [
        _vm._v(_vm._s(_vm.actionType) + " " + _vm._s(_vm.$t(_vm.title))),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.name.includes("product")
            ? _c(
                "a",
                {
                  attrs: {
                    "data-cy": `${_vm.name}-btn-schedule`,
                    href: "javascript:void(0);",
                  },
                },
                [
                  _c("b-img", {
                    staticClass: "cls-btn-icon",
                    attrs: { src: "/assets/icons/work-schedule.svg" },
                    on: { click: _vm.onClick },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("toggle-button-status", {
            attrs: {
              name: _vm.name,
              status: _vm.status,
              disabled: _vm.disabled,
            },
            on: { "on-toggle-change": _vm.onChangeStatus },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }