var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-colxx",
    { staticClass: "disable-text-selection" },
    [
      _c("TopMenu", {
        attrs: { title: _vm.$t("menu.your-posts"), config: {} },
      }),
      _vm._v(" "),
      _vm.isLoad
        ? [
            _c(
              "b-row",
              { key: "thumb" },
              _vm._l(_vm.getAllPosts, function (item, index) {
                return _c("ListPost", {
                  key: index,
                  attrs: {
                    id: item.id,
                    cyPrefix: `list-item-post-${index}`,
                    data: item,
                  },
                })
              }),
              1
            ),
          ]
        : [
            _c("div", {
              staticClass: "loading",
              attrs: { "data-cy": "div-loading" },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }