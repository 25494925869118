var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar",
      class: { "is-global-warning": !_vm.isStripeConnected },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return (() => {}).apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "main-menu",
          class: { "is-global-warning": !_vm.isStripeConnected },
        },
        [
          _c(
            "vue-perfect-scrollbar",
            {
              staticClass: "scroll",
              attrs: {
                settings: { suppressScrollX: true, wheelPropagation: false },
              },
            },
            [
              _c(
                "ul",
                { staticClass: "list-unstyled" },
                _vm._l(_vm.menuItems, function (item) {
                  return _c(
                    "li",
                    {
                      key: `parent_${item.id}`,
                      class: {
                        active:
                          (_vm.selectedParentMenu === item.id &&
                            _vm.viewingParentMenu === "") ||
                          _vm.viewingParentMenu === item.id,
                      },
                      attrs: {
                        "data-cy": `side-bar-${item.id}`,
                        "data-flag": item.id,
                      },
                    },
                    [
                      item.newWindow
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: item.to,
                                rel: "noopener noreferrer",
                                target: "_blank",
                              },
                            },
                            [
                              _c("i", { class: item.icon }),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.$t(item.label))),
                              ]),
                            ]
                          )
                        : item.subs && item.subs.length > 0
                        ? _c(
                            "a",
                            {
                              attrs: { href: `#${item.to}` },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openSubMenu($event, item)
                                },
                              },
                            },
                            [
                              _c("i", { class: item.icon }),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.$t(item.label))),
                              ]),
                            ]
                          )
                        : _c(
                            "router-link",
                            {
                              attrs: { to: item.to },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.changeSelectedParentHasNoSubmenu(
                                    item.id
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { class: item.icon }),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.$t(item.label))),
                              ]),
                            ]
                          ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "sub-menu",
          class: { "is-global-warning": !_vm.isStripeConnected },
        },
        [
          _c(
            "vue-perfect-scrollbar",
            {
              staticClass: "scroll",
              attrs: {
                settings: { suppressScrollX: true, wheelPropagation: false },
              },
            },
            _vm._l(_vm.menuItems, function (item, itemIndex) {
              return _c(
                "ul",
                {
                  key: `sub_${item.id}`,
                  class: {
                    "list-unstyled": true,
                    "d-block":
                      (_vm.selectedParentMenu === item.id &&
                        _vm.viewingParentMenu === "") ||
                      _vm.viewingParentMenu === item.id,
                  },
                  attrs: { "data-parent": item.id },
                },
                _vm._l(item.subs, function (sub, subIndex) {
                  return _c(
                    "li",
                    {
                      key: subIndex,
                      class: {
                        "has-sub-item": sub.subs && sub.subs.length > 0,
                        active: _vm.$route.path.indexOf(sub.to) > -1,
                      },
                      attrs: { "data-cy": `side-bar-${sub.id}` },
                    },
                    [
                      sub.newWindow
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: sub.to,
                                rel: "noopener noreferrer",
                                target: "_blank",
                              },
                            },
                            [
                              _c("i", { class: sub.icon }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.$t(sub.label)))]),
                            ]
                          )
                        : sub.subs && sub.subs.length > 0
                        ? [
                            _c(
                              "b-link",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle",
                                    value: `menu_${itemIndex}_${subIndex}`,
                                    expression:
                                      "`menu_${itemIndex}_${subIndex}`",
                                  },
                                ],
                                staticClass: "rotate-arrow-icon opacity-50",
                                attrs: { variant: "link" },
                              },
                              [
                                _c("i", {
                                  staticClass: "simple-icon-arrow-down",
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "d-inline-block" }, [
                                  _vm._v(_vm._s(_vm.$t(sub.label))),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-collapse",
                              {
                                attrs: {
                                  visible: "",
                                  id: `menu_${itemIndex}_${subIndex}`,
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "list-unstyled third-level-menu",
                                  },
                                  _vm._l(
                                    sub.subs,
                                    function (thirdLevelSub, thirdIndex) {
                                      return _c(
                                        "li",
                                        {
                                          key: `third_${itemIndex}_${subIndex}_${thirdIndex}`,
                                          class: {
                                            "third-level-menu": true,
                                            active:
                                              _vm.$route.path ===
                                              thirdLevelSub.to,
                                          },
                                        },
                                        [
                                          thirdLevelSub.newWindow
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: thirdLevelSub.to,
                                                    rel: "noopener noreferrer",
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    class: thirdLevelSub.icon,
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          thirdLevelSub.label
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: thirdLevelSub.to,
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    class: thirdLevelSub.icon,
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          thirdLevelSub.label
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        : _c("div", [
                            _vm.menuStatus[sub.id] !== undefined &&
                            !_vm.menuStatus[sub.id].isActive
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: "#" } },
                                      [
                                        _c("i", { class: sub.icon }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-muted",
                                            attrs: { id: `menu-${sub.id}` },
                                          },
                                          [_vm._v(_vm._s(_vm.$t(sub.label)))]
                                        ),
                                        _vm._v(" "),
                                        _c("b-tooltip", {
                                          attrs: {
                                            target: `menu-${sub.id}`,
                                            placement: "bottom",
                                            title:
                                              _vm.menuStatus[sub.id]
                                                .errorMessage,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: sub.to } },
                                      [
                                        _c("i", { class: sub.icon }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t(sub.label))),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                          ]),
                    ],
                    2
                  )
                }),
                0
              )
            }),
            0
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }