import {
    createAyrshareAccount,
    getAyrshareOnboardingLink, getAyrshareUserInfo,
    disconnectSocialMediaAccount
} from "@/api/social_media_accounts";

import LocalStorageService from "@/utils/local_store.js";
import merchant_profile from "@/store/modules/merchant_profile";

export default {
    namespaced: true,
    state: {
        isSocialMediaAccountConnected: false,
    },

    mutations: {
        /**
         * Set isSocialMediaAccountConnected.
         * @param {Object} state        Local State object.
         * @param {boolean} isConnected Is stripe account connected.
         */
        setIsSocialMediaAccountConnected(state, isConnected) {
            state.isSocialMediaAccountConnected = isConnected;
        },

    },
    getters: {
        /**
         * Set isSocialMediaAccountConnected.
         */
        getISocialMediaAccountConnected: (state) => {
            return state.isSocialMediaAccountConnected;
        },

    },
    actions: {

        /**
         * Request to create merchant Ayrshare account to platform
         */
        createAyrshareAccount({commit}, data) {

            const {key, onSuccess, onFail} = data;

            createAyrshareAccount(data)
                .then(response => {
                    // console.log(response);
                    commit("setIsSocialMediaAccountConnected", true);
                    onSuccess(response);
                })
                .catch(error => {
                    if (onFail) {
                        onFail(error.response);
                    }
                });
        },
        /**
         * Request to get Ayrshare onboarding URL
         */
        getAyrshareOnboardingLink({commit}, data) {
            const {key, onSuccess, onFail} = data;

            getAyrshareOnboardingLink(data)
                .then(response => {
                    onSuccess(response);
                })
                .catch(error => {
                    if (onFail) {
                        onFail(error.response);
                    }
                });
        },

        /**
         * Request to get connected Social Media Accounts
         * */
        getAyrshareUserInfo({commit}, data) {
            const {key, onSuccess, onFail} = data;

            getAyrshareUserInfo(data)
                .then(response => {
                    onSuccess(response);
                })
                .catch(error => {
                    if (onFail) {
                        onFail(error.response);
                    }
                });
        },

        disconnectSocialMediaAccount({commit}, values) {
            const {profileKey, data, onSuccess, onFail} = values;
            disconnectSocialMediaAccount(profileKey, data.platform)
                .then(response => {
                    onSuccess(response);
                })
                .catch(error => {
                    if (onFail) {
                        onFail(error.response);
                    }
                });
        },
        setConnected({commit}, data) {

            const {key, onSuccess, onFail} = data;
            commit("setIsSocialMediaAccountConnected", data.isConnected);
        },
    }
};
