// Dialog component for Tax configuration. 
// For use on Products/Addons page. 
// Author: Sukhpreet Singh (sukhpreets6718@gmail.com)
// @param {Boolean} disabled  To disable input components when not editing. 
// @param {Object}  data      Object containing required information for the component.
// @param {Boolean} dialog    When to show and hide the modal.
// @param {String}  storeId   To filter tax config for specific store's products.

<template>
  <!-- Dialog: Tax Config for Products -->
  <b-modal id="modalTaxConfig" ref="modalTaxConfig" :title="$t('product.select-tax-config')" class="modal-right" size="lg" @hide="onCancel()">
    <b-form>
      <!-- Tax config table  -->
      <div>
        <ListTaxConfigs 
          :appliedTaxConfigs="appliedTaxConfigs"
          :location="{country: country, province: province, city: city}"
          @addTax="addTax"
          @removeTax="removeTax"
        />
      </div>
    </b-form>
    <template slot="modal-footer">
      <b-button 
        data-cy="dlg-schedule-btn-cancel"
        variant="outline-secondary"
        @click="onCancel()"
      >{{ $t('buttons.cancel') }}</b-button>
      <b-button 
        data-cy="dlg-schedule-btn-submit"
        variant="primary" 
        @click="onSubmit()" 
        class="mr-1"
        :disabled="disabled"
      >{{ $t('buttons.submit') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { isNullOrUndefined } from '@/utils/general';
import { showValidationError } from '@/utils/notify';

import ListTaxConfigs from '@/components/Listing/ListTaxConfigs'

export default {
  props: {
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {bool} Handler variable for the dialog(Close and Open)
    dialog: {
      type: Boolean,
      default: false
    },
    // {String} To filter tax config for specific store's products.
    storeId: {
      type: String,
      default: null
    },
    taxConfigIds: {
      type: Array,
      default: () => []
    }
  },

  components: {
    ListTaxConfigs,
  },

  data() {
    return {
      taxConfigFields: [
        {key: 'taxType', label: this.$t('store.tax-details.fields.tax-name'), sortable: true},
        {key: 'productCategory', label: this.$t('store.tax-details.fields.product-category'), sortable: true},
        {key: 'percentage', label: this.$t('store.tax-details.fields.percentage'), sortable: true},
        {key: 'description', label: this.$t('store.tax-details.fields.description'), sortable: true},
        {key: 'actions', label: this.$t('store.tax-details.fields.actions'), sortable: true},
      ],

      appliedTaxConfigs: [],

      // for filtering tax configs
      country: null,
      province: null,
      city: null, 

    }
  },

  computed: {

    ...mapGetters('merchant_store', ['taxConfigList', 'findStoreById', 'getDefaultTaxConfigsOfStore']),

  },

  methods: {
    showValidationError,

    // Map actions.
    ...mapActions("merchant_store",   ["updateCurrentStore", "getAllTaxConfigs"]),

    /**
     * Close the dialog box.
     */
    onCancel() {
      this.$emit('dialog-close');
    },

    /**
     * Save the changes made.
     */
    onSubmit() {

      const dataToApply = {};

      dataToApply.storeId = this.storeId;
      dataToApply.taxConfigIds = this.appliedTaxConfigs.map(appliedTaxConfig => appliedTaxConfig.id);

      if ((dataToApply.taxConfigIds.length === 0)) {
        // Tax exempt case.
        dataToApply.taxConfigIds = [];
      } 

      this.$emit('dialog-submit', dataToApply);
      this.$emit('dialog-close');
    },

    /**
     * Reset all the values which need to when reinitializing dialog box.
     */
    reset() {
      this.appliedTaxConfigs = [];

      this.country = null;
      this.province = null;
      this.city = null;
    },

    /**
     * Handles initialization logic.
     */
    initDialog() {
      this.reset();

      if (!isNullOrUndefined(this.taxConfigIds) && this.taxConfigIds.length >= 0) { // taxConfigIds length can be 0 in case of tax exempt.
        this.appliedTaxConfigs = this.taxConfigIds.map(taxConfigId => {
          const foundTaxConfig = this.taxConfigList.find(taxConfig => taxConfig.id === taxConfigId);
          if (isNullOrUndefined(foundTaxConfig)) {
            return null; // will not happen unless some tax config data is deleted unintentionally.
          } else {
            return foundTaxConfig;
          }
        }).filter(taxConfig => !isNullOrUndefined(taxConfig));

      } // else

      // get store's location by id
      const store = this.findStoreById(this.storeId);
      if (!isNullOrUndefined(store)) {
        this.country = store.address.country;
        this.province = store.address.province;
        this.city = store.address.city;
      }

    },

    /**
     * Checks if taxConfigId is already selected.
     * Returns true if already selected else false.
     */
    isTaxPresentById(id) {
      const found = this.appliedTaxConfigs.find(taxConfig => taxConfig.id === id);
      return !isNullOrUndefined(found);
    },

    /**
     * Adds given taxConfigId to the selected ids list (appliedTaxConfigIds)
     */
    addTax(taxConfig) {
      this.appliedTaxConfigs = [...this.appliedTaxConfigs, taxConfig];
    },

    /**
     * Removes given taxConfigId from the selected ids list (appliedTaxConfigIds)
     */
    removeTax(taxConfig) {
      this.appliedTaxConfigs = this.appliedTaxConfigs.filter(appliedTaxConfig => appliedTaxConfig.id != taxConfig.id);
    },

    /**
     * Function used in table to sort columns.
     * Currently, it is only used for 'actions' slot column.
     * To display all selected taxes together in the beginning or the end.
     */
    sortCompare(aRow, bRow, key) {
      const idKey = 'id'
      const aId = aRow[idKey];
      const bId = bRow[idKey];

      const aIdExists = !isNullOrUndefined(this.appliedTaxConfigs.find(taxConfig => taxConfig.id === aId));
      const bIdExists = !isNullOrUndefined(this.appliedTaxConfigs.find(taxConfig => taxConfig.id === bId));

      if (key === 'actions') {
        if (aIdExists && !bIdExists) {
          return -1; // asecending case
        } else if (!aIdExists && bIdExists) {
          return 1;  // descending case
        } else {
          return 0;  // drow case
        }
      } else {
        return null; // Fallback to bootstrap's native sortCompare.
      }
    },
  },

  watch: {
    // Control of the dialog: Show, Hide
    dialog: function(newVal, oldVal){
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if(newVal == true) {
        this.initDialog();
        this.$refs["modalTaxConfig"].show();
      } else {
        this.$refs["modalTaxConfig"].hide();
      }
    }
  },
}
</script>