var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          attrs: {
            "data-cy": `${_vm.name}-btn-cancel`,
            variant: "outline-secondary",
          },
          on: {
            click: function ($event) {
              return _vm.onClick()
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("buttons.cancel")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "mr-1",
          attrs: {
            "data-cy":
              _vm.actionType == _vm.$t("actions.show")
                ? `${_vm.name}-btn-edit`
                : `${_vm.name}-btn-submit`,
            variant: "primary",
            disabled: _vm.disabled,
          },
          on: {
            click: function ($event) {
              return _vm.onSubmit()
            },
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.actionType == _vm.$t("actions.show")
                  ? _vm.$t("buttons.edit")
                  : _vm.$t("buttons.submit")
              ) +
              "\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }