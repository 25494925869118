/**
 * It contains backend CRUD Apis for Products.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from '@/constants/config.js';

// Modules in the application.
import axios                                from "@/api/axios/axios_core_deal.js";
import { uploadImagesApi, updateImagesApi } from "@/api/general.js";
import { serializeProduct }                 from "@/models/products/product.js";

// URLs for backend
const HOST              = BE_URLS.BACKEND_DEAL_SERVER_URL;
const IMAGE_UPLOAD_URL  = BE_URLS.BACKEND_IMAGE_UPLOAD_URL;

/**
 * Get URL for Products.
 * @param {string} [id] ID of a Product object.
 * @return {string} URL of backend for Products
 */
function getUrl(id) {
  return `${HOST}/products/${id ? id : ""}`;
}

/**
 * Get Products.
 * @return {Promise} Result of GET for Products.
 */
export async function getProductsApi() {
  return await axios.get(getUrl());
}

/**
 * Get a Product by Id.
 * @param {string} id ID of a Product object.
 * @return {Promise} Result of GET for a Product by Id.
 */
export async function getOneProductApi(id) {
  return await axios.get(getUrl(id));
}

/**
 * Add a Product.
 * @param {Object} data Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
 * @return {Promise} Result of POST for a Product.
 */
export async function addProductApi(data) {
  return await axios.post(getUrl(), serializeProduct(data));
}

/**
 * Add a Product with images.
 *
 * It adds a Product with images.
 * If imageObject is not provided(null or undefined), it adds a Product without images.
 *
 * @param {Object}   data           Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
 * @param {Object[]} [imageObjects] Image objects: [{files, preview}]
 * @return {Promise} Result of POST for a Product.
 * @return {null}    If there is a error - TODO
 */
export async function addProductWithImagesApi(data, imageObjects) {
  // let toSend = { ...serializeProduct(data) };
  let toSend = data;

  if (!Array.isArray(imageObjects) || (imageObjects.length <= 0)) {
    // Add(Upload) the Product without images
    return addProductApi(toSend);

  } else {
    // Add(Upload) the Product with images
    // Convert image arrary
    // [{file, preview}] -> [file]
    let imageFiles = _.compact(imageObjects.map((image) => { return image.file }));

    // Upload images
    toSend.changedImages = await uploadImagesApi(IMAGE_UPLOAD_URL, imageFiles);
    // console.log(toSend);
    return addProductApi(toSend);
  }
}

/**
 * Update a Product.
 * @param {Object} data Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
 * @return {Promise} Result of PUT for a Product.
 */
export async function updateProductApi(data) {
  return await axios.put(getUrl(data.id), serializeProduct(data));
}

/**
 * Update a Product with images.
 *
 * It updates a Product with images.
 * If imageObject is not provided(null or undefined), it updates Product without images.
 *
 * @param {Object}   data           Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
 * @param {Object[]} [imageObjects] Image objects: [{files, preview}]
 * @return {Promise} Result of PUT for a Product.
 * @return {null}    If there is a error - TODO
 */
export async function updateProductWithImagesApi(data, imageObjects) {
  // let toSend = { ...serializeProduct(data) };
  let toSend = data;

  // console.log('updateProductWithImagesApi-in', imageObjects);
  if (!Array.isArray(imageObjects)) {
    // Update(Upload) the Product without images
    return updateProductApi(toSend);

  } else {
    // Update(Upload) the Product with images

    // Update images : changedImages, deletedImages.
    await updateImagesApi(IMAGE_UPLOAD_URL, data, imageObjects);

    // console.log('updateProductWithImagesApi-excute', data);
    return updateProductApi(toSend);
  }
}

/**
 * Delete a Product by Id.
 * @param {string} id ID of a Product object.
 * @return {Promise} Result of DELETE for deleting a Product.
 */
export async function deleteProductApi(id) {
  return await axios.delete(getUrl(id));
}



