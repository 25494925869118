// Select Time Limit component.
// Author : Huen Oh (heons921@gmail.com)
// @param: {Object} data      Object with date ranage: {availableFrom, availableTo}.
// @param: {bool}   disabled  Disabled property of the toogle button.

<template>
<div>
   <!-- Time limit -->
  <b-row align-v="center">
    <!-- Toggle button for the check -->
    <b-colxx xxs="2">
      <toggle-button-check
        name="time-limit"
        v-model="isTimeLimited" 
        :disabled="disabled"
      />
    </b-colxx>
    <!-- Title -->
    <b-colxx>
      <h6 v-if="isReward">{{`${$t('promotions.reward-limits-time-title')}`}}</h6>
      <h6 v-else>{{`${$t('promotions.limits-time-title')}`}}</h6>
    </b-colxx>
    <b-colxx xxs="12">
      <!-- Description -->
      <p class="text-muted text-small">
        {{$t('promotions.limits-time-description')}}
      </p>
      <!-- Date Picker -->
      <template v-if="isTimeLimited">
        <b-form-group >
          <v-date-picker 
            v-if="!disabled"
            data-cy="time-limit-date-picker"
            mode="range"
            v-model="selectedValueRange"
            :input-props="{
              'data-cy':'time-limit-date-picker-input',
              class: 'form-control', 
              placeholder: $t('form-components.date-range'),
            }"
          />
          <input
            v-else
            class="form-control"
            data-cy="time-limit-date-picker-input"
            :placeholder="$t('form-components.date-range')"
            :value="formattedDateRange"
            disabled
          />
        </b-form-group>
      </template>
    </b-colxx>
  </b-row>
</div>
</template>


<script>
import moment from 'moment';
import ToggleButtonCheck    from '@/components/Forms/ToggleButtonCheck'

import { formatRangeValue } from '@/utils/datetime'

export default {
  props:{
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    data: {  // {Object} Object with date ranage: {availableFrom, availableTo}.
      type: Object,
      default: {}
    },
    isReward: {
      type: Boolean,
      default: false
    }
  },
  components: { ToggleButtonCheck },
  data(){
    return {
      isTimeLimited: false, // {bool}   To check time limit.
      isLoaded: false,

      selectedValueRange: { // {Object} Selected dates for time limit.
        start: new Date(),  // {Object} Start date : Date object.
        end: new Date()     // {Object} End date : Date object.
      },
    }    
  },
  created: function () {
    // // Set value for the toggle button.
    // this.switchValue = this.inputValue;
  },
  watch: {
    /**
     * Watch to check changes in data.
     */
    data: {
      deep: true,
      // immediate: true,
      handler() {
        // Only works for the first load.
        if(!this.isLoaded) {
          // Set time limit.
          this.setTimeLimit();
          this.isLoaded = true;
        } else {}
      },
    },
  },
  computed: {
    /**
     * Converts date range {start, end} to string value
     */
    formattedDateRange() {
      if (this.selectedValueRange.start && this.selectedValueRange.end) {
        return formatRangeValue(this.selectedValueRange);
      } 
    }
  },
  methods:{
    /**
     * Get selected time limit : availableFrom
     */
    getAvailableFrom() {
      return this.isTimeLimited ? moment(this.selectedValueRange.start).format() : null;
    },
    /**
     * Get selected time limit : availableTo
     */
    getAvailableTo() {
      return this.isTimeLimited ? moment(this.selectedValueRange.end).endOf('day').format() : null;
    },

    setTimeLimit() {
      this.isTimeLimited = this.data.availableFrom != null && this.data.availableTo != null

      if (this.isTimeLimited) {
        const availableFrom = moment(this.data.availableFrom);
        const availableTo = moment(this.data.availableTo);
        this.selectedValueRange = {
          start: availableFrom.toDate(),
          end: availableTo.toDate()
        }
      } else {
        this.selectedValueRange = {
          start: moment(new Date()).toDate(),
          end: moment(new Date()).toDate()
        }
      }
    }
  },
  mounted(){
    this.setTimeLimit();    
  }
}
</script>
