/**
 * It contains backend CRUD Apis for ProductUnits.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// Modules in the application.
import axios                    from "@/api/axios/axios_core_deal.js";
import { serializeProductUnit } from "@/models/products/product_unit.js";

// URLs for backend
const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;

/**
 * Get URL for ProductUnits.
 * @param {string} [id] ID of a ProductUnit object.
 * @return {string} URL of backend for ProductUnits.
 */
function getUrl(id) {
  return `${HOST}/product-units/${id ? id : ""}`;
}

/**
 * Get ProductUnits.
 * @return {Promise} Result of GET for ProductUnits.
 */
export async function getProductUnitsApi() {
  return await axios.get(getUrl());
}

/**
 * Get a ProductUnit by Id.
 * @param {string} id ID of a ProductUnit object.
 * @return {Promise} Result of GET for a ProductUnit by Id.
 */
export async function getOneProductUnitApi(id) {
  return await axios.get(getUrl(id));
}

/**
 * Add a ProductUnit.
 * @param {Object} data ProductUnit object: Refer to PRODUCT_UNIT_MODEL.
 * @return {Promise} Result of POST for a ProductUnit.
 */
export async function addProductUnitApi(data) {
  return await axios.post(getUrl(), serializeProductUnit(data));
}

/**
 * Update a ProductUnit.
 * @param {Object} data ProductUnit object: Refer to PRODUCT_UNIT_MODEL.
 * @return {Promise} Result of PUT for a ProductUnit.
 */
export async function updateProductUnitApi(data) {
  return await axios.put(getUrl(data.id), serializeProductUnit(data));
}

/**
 * Delete a ProductUnit by Id.
 * @param {string} id ID of a ProductUnit object.
 * @return {Promise} Result of DELETE for deleting a ProductUnit.
 */
export async function deleteProductUnitApi(id) {
  return await axios.delete(getUrl(id));
}

