/**
 * It contains backend Apis for Authentication.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// 3rd party libraries.
import axios from "axios";

// URLs for backend
const HOST      = BE_URLS.BACKEND_URL;
const LOGIN_URL = `${HOST}/login`;
const REGISTRATION_URL = `${HOST}/merchant/registration`;
const REG_ACTIVATE_URL = `${HOST}/user/active`;
const REG_RESEND_ACTIVATE_TOKEN_URL = `${HOST}/user/activation/resend/token`;
const REG_RESEND_ACTIVATE_EMAIL_URL = `${HOST}/user/activation/resend/email`;
const PASSWORD_RESET_REQUEST_URL    = `${HOST}/user/password/reset`;
const IS_ACTIVATED_URL              = `${HOST}/user/is-activated`;

/**
 * Send registration active request.
 * @param {string} token Token to activate the account.
 * @return {Promise} GET Promise.
 */
export function sendRegActivateRequestApi(token) {
  // return axios.get(`${REG_ACTIVATE_URL}/${token}`);
  return axios({
    method: "get",
    url: `${REG_ACTIVATE_URL}/${token}`,
    headers: {
      'Access-Control-Allow-Origin':'*',
      'intra-service-name': 'user-management'
    },
  });
}

/**
 * Send request for re-sending activation email.
 * @param {string} token Expired token.
 * @return {Promise} GET Promise.
 */
export function sendRegResendActivateRequestApi(token) {
  return axios({
    method: "get",
    url: `${REG_RESEND_ACTIVATE_TOKEN_URL}/${token}`,
    headers: {
      'Access-Control-Allow-Origin':'*',
      'intra-service-name': 'user-management'
    },
  });
}

/**
 * Send request for re-sending activation email by email.
 * @param {string} email Expired token.
 * @return {Promise} GET Promise.
 */
export function sendRegResendActivateByEmailRequestApi(email) {
  return axios({
    method: "get",
    url: `${REG_RESEND_ACTIVATE_EMAIL_URL}/${email}`,
    headers: {
      'Access-Control-Allow-Origin':'*',
      'intra-service-name': 'user-management'
    },
  });
}

/**
 * Send Password reset request.
 * @param {string} email Email of the user.
 * @return {Promise} POST Promise.
 */
export function sendPasswordResetRequestApi(email) {
  const formData = new FormData();
  formData.append("email", email);
  // return axios.post(`${PASSWORD_RESET_REQUEST_URL}`, formData);
  return axios({
    method: "post",
    url: `${PASSWORD_RESET_REQUEST_URL}`,
    headers: {
      'Access-Control-Allow-Origin':'*',
      'intra-service-name': 'user-management'
    },
    data: formData
  });
}

/**
 * Send setup new Password request.
 * @param {string} token Token to set new password.
 * @param {Object} data New password data: TODO.
 * @return {Promise} POST Promise.
 */
export function sendSetupNewPasswordApi(token, data) {
  // return axios.post(`${PASSWORD_RESET_REQUEST_URL}/${token}`, data);
  return axios({
    method: "post",
    url: `${PASSWORD_RESET_REQUEST_URL}/${token}`,
    headers: {
      'Access-Control-Allow-Origin':'*',
      'intra-service-name': 'user-management'
    },
    data: data
  });
}

/**
 * Send registration request.
 * @param {Object} data Registration object: TODO.
 * @return {Promise} POST Promise.
 */
export function sendRegistrationApi(data) {
  // return axios.post(REGISTRATION_URL, data);
  return axios({
    method: "post",
    url: REGISTRATION_URL,
    headers: {
      'Access-Control-Allow-Origin':'*',
      'intra-service-name': 'user-management'
    },
    data: data
  });
}

/**
 * Send login request.
 * @param {Object} data Login data: TODO.
 * @return {Promise} POST Promise.
 */
export function sendLoginApi(data) {
  // return axios.post(LOGIN_URL, data);
  return axios({
    method: "post",
    url: LOGIN_URL,
    headers: {
      'Access-Control-Allow-Origin':'*',
      'intra-service-name': 'user-management'
    },
    data: data
  });
}


/**
 * Send is-actived request to the given account(email) is activated or not.
 * @param {string} email Eamil of the account to check.
 * @return {Promise} GET Promise.
 */
export function sendIsActivateRequestApi(email) {
  return axios({
    method: "get",
    url: `${IS_ACTIVATED_URL}/${email}`,
    headers: {
      'Access-Control-Allow-Origin':'*',
      'intra-service-name': 'user-management'
    },
  });
}



// TODO : Seperate from this module???
import LocalStorageService from "@/utils/local_store.js";

/**
 * Get Account Info
 * @return {Promise} Result of get for AccountInfo
 */
const ACCOUNT_INFO_URL = `${BE_URLS.BACKEND_URL}/user/account-info`;
export function getAccountInfoApi() {
  const token = LocalStorageService.getAccessToken();
  return axios({
    method: "get",
    url: `${ACCOUNT_INFO_URL}`,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Access-Control-Allow-Origin':'*',
      'intra-service-name': 'user-management'
    },
  });
}

