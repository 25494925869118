/**
 * It contains backend CRUD Apis for Orders.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import {BE_URLS} from "@/constants/config.js";

// Modules in the application.
import axios from "@/api/axios/axios_core_deal";

// URLs for backend
const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;
const ORDERS_URL = `${HOST}/orders`;
const ORDERS_STORE_URL = `${ORDERS_URL}/store`;
const NUMBER_NEW_ORDERS_URL = `${ORDERS_URL}/merchant/count`;

/**
 * Get Orders.
 * @return {Promise} Result of GET for Orders.
 */
export function getOrdersApi(params) {
  if (params) {
    return axios.get(ORDERS_URL, {
      params: params, headers: {
        'Access-Control-Allow-Origin': '*',
      }
    });
  } else {
    return axios.get(ORDERS_URL, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
    });
  }
}

/**
 * Get a Order by Id.
 * @param {string} id ID of a Order object.
 * @return {Promise} Result of GET for a Order by Id.
 */
export function getOneOrderApi(id) {
  return axios.get(`${ORDERS_URL}/${id}`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
    }
  });
}

/**
 * Get Orders by Store Id.
 * @param {string} id ID of a Store object.
 * @return {Promise} Result of GET for Orders.
 */
export function getOrdersByStoreIdApi(id, params) {
  if (params) {
    return axios.get(`${ORDERS_STORE_URL}/${id}`, {
      params: params, headers: {
        'Access-Control-Allow-Origin': '*',
      }
    });
  } else {
    return axios.get(`${ORDERS_STORE_URL}/${id}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
    });
  }
}

export function getNumberOfNewOrdersByMerchantApi() {
  return axios.get(`${NUMBER_NEW_ORDERS_URL}`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
    }
  });
}

/**
 * Get all (archived)Orders with status: COMPLETED, REFUNDED.
 * @param {Object} params {isPickup, storeId, startDate, endDate}
 * @return {Promise} Result of GET for Orders.
 */
export function getArchivedOrders(params) {
  if (params) {
    return axios.get(`${ORDERS_URL}/archived`, {
      params: params, headers: {
        'Access-Control-Allow-Origin': '*',
      }
    });
  } else {
    return axios.get(`${ORDERS_URL}/archived`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
    });
  }
}

/**
 * Update status of a Order by Id.
 * @param {string} orderId  ID of a Order object.
 * @param {string} storeId  ID of a Store object.
 * @param {string} status   Status of a Order object: Refer to ENUM_STATUS_STRING.
 * @param {Object} data     Payload object in the body: Refer to TODO.
 * @return {Promise} Result of POST for status of a Order by Id.
 */
export function updateOrderStatusApi(orderId, storeId, status, data) {
  return axios.post(
    `${ORDERS_URL}/${orderId}/${status}/${storeId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }
    }
  );
}


