/**
 * It contains backend Apis for Merchant Dashboard.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// Modules in the application.
import axios                  from "@/api/axios/axios_merchant_dashboard";
import { isNullOrUndefined }  from "@/utils/general";

// URLs for Backend
const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;

const MERCHANT_STORE_PLATFORM_URL           = `${HOST}/merchant-dashboard/platform`;
const MERCHANT_STORE_USER_RATING_URL        = `${HOST}/merchant-dashboard/user-rating`;
const MERCHANT_STORE_POPULAR_TIME_URL       = `${HOST}/merchant-dashboard/popular-time`;
const MERCHANT_STORE_MERCHANT_METRIC_URL    = `${HOST}/merchant-dashboard`;
const MERCHANT_STORE_USER_RATING_ORDER_URL  = `${HOST}/merchant-dashboard/user-rating/list`

const MERCHANT_STORE_INIT_METRIC_URL      = `${MERCHANT_STORE_MERCHANT_METRIC_URL}/init`;

/**
 * Get data for PopularTimeChart within a date range.
 * @param {Object} data Payload contains merchantId,storeId,startDate,endDate.
 * @return {Promise} Result of GET for PopularTimeChart.
*/
export function getPopularTimeByDayWithinDateRangeApi(data) {
  let endpoint = `${MERCHANT_STORE_POPULAR_TIME_URL}/${data.startDate}/${data.endDate}/${data.weekDay}/${data.merchantId}`;

  // If storeId is present then add it to endpoint.
  if (!isNullOrUndefined(data.storeId)) {
    endpoint = `${endpoint}/${data.storeId}`;
  } // else

  return axios.get(endpoint,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}

/**
 * Get initialization data for merchant dashboard within a date range.
 * @param  {Object} data
 * @return {Promise} Result of GET for initialization data for merchant dashboard.
 */
export async function getMerchantInitMetricWithinDateRangeApi(data) {
  let endpoint = `${MERCHANT_STORE_INIT_METRIC_URL}/${data.startDate}/${data.endDate}/${data.merchantId}`;

  if (!isNullOrUndefined(data.storeId)) {
    endpoint = `${endpoint}/${data.storeId}`;
  } // else

  return axios.get(endpoint,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}


/**
 * Get data for AvgUserRatingChart in report duration.
 * @param {Object} data Payload contains merchantId,storeId,startDate,endDate.
 * @return {Promise} Result of GET for AvgUserRatingChart.
*/
export function getUserRatingDataWithinDateRangeApi(data) {
  let endpoint = `${MERCHANT_STORE_USER_RATING_URL}/${data.startDate}/${data.endDate}/${data.merchantId}`;

  // If storeId is present then add it to endpoint.
  if (!isNullOrUndefined(data.storeId)) {
    endpoint = `${endpoint}/${data.storeId}`;
  } // else

  return axios.get(endpoint,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}

export function getUserRatingOrderWithinDateRangeApi(data){
  let endpoint = `${MERCHANT_STORE_USER_RATING_ORDER_URL}/${data.startDate}/${data.endDate}/${data.merchantId}`;


  // If storeId is present then add it to endpoint.
  if (!isNullOrUndefined(data.storeId)) {
    endpoint = `${endpoint}/${data.storeId}`;
  } // else

  endpoint = `${endpoint}?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;

  return axios.get(endpoint,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });

}

/**
 * Get User usage PlatformData within a date range.
 * @param  {Object} data
 * @return {Promise} Result of GET for PlatformData.
 */
export function getPlatformDataWithinDateRangeApi(data) {
  let endpoint = `${MERCHANT_STORE_PLATFORM_URL}/${data.startDate}/${data.endDate}/${data.merchantId}`;

  if (!isNullOrUndefined(data.storeId)) {
    endpoint = `${endpoint}/${data.storeId}`;
  } // else

  return axios.get(endpoint,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}
