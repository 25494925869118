/**
 * It contains store module for ProductAttributes.
 */

import {
  getProductAttributesApi,
  addProductAttributeApi,
  updateProductAttributeApi,
  deleteProductAttributeApi,
} from "@/api/product/product_attributes.js";

import { parseProductAttribute }  from "@/models/products/product_attribute.js";
import { sortByName }             from "@/utils/sort.js"
import { getListForDropDownMenu, isResponseOK, isResponseCREATED } from "@/utils/general.js"


export default {
  namespaced: true,
  state: {
    productAttributes: [],           // List of ProductAttributes: Refer to PRODUCT_ATTRIBUTE_MODEL.
    selectableProductAttributes: [], // List of selectable ProductAttributes: {value:name, label:name, text:name}.
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.productAttributes = [];
      state.selectableProductAttributes = [];
    },
    /**
     * Set all ProductAttributes in the state.
     * @param {Object} state Local State object.
     * @param {Object} attrs List of ProductAttribute objects: Refer to PRODUCT_ATTRIBUTE_MODEL.
     */
    setAllProductAttributes(state, attrs) {
      state.productAttributes = attrs;
    },
    /**
     * Delete a ProductAttribute in the state.
     * @param {Object} state Local State object.
     * @param {number} index Index of a ProductAttribute in the list.
     */
    deleteOneProductAttribute(state, index) {
      state.productAttributes.splice(index, 1);
    },
    /**
     * Add a ProductAttribute in the state.
     * @param {Object} state Local State object.
     * @param {Object} attr  ProductAttribute object: Refer to PRODUCT_ATTRIBUTE_MODEL.
     */
    addOneProductAttribute(state, attr) {
      state.productAttributes.push(attr);
    },
    /**
     * Upate a ProductAttribute in the state.
     * @param {Object} state Local State object.
     * @param {Object} attr  ProductAttribute object: Refer to PRODUCT_ATTRIBUTE_MODEL.
     * @param {number} index Index of a ProductAttribute in the list.
     */
    updateOneProductAttribute(state, { attr, index }) {
      state.productAttributes[index] = {
        ...state.productAttributes[index],
        ...attr
      };
    },
    /**
     * Set selectable ProductAttributes in the state.
     * @param {Object} state Local State object.
     */
    setSelectableProductAttributes(state) {
      state.selectableProductAttributes = getListForDropDownMenu(state.productAttributes, false);
    },
  },
  getters: {
    /**
     * Get ProductAttributes in the state.
     */
    productAttributes: (state) => {
      return state.productAttributes;
    },
    /**
     * Get selectable ProductAttributes in the state.
     */
    selectableProductAttributes: (state) => {
      return state.selectableProductAttributes;
    },
    /**
     * Get a ProductAttribute by Id.
     * @param {string} id Id of a ProductAttribute.
     */
    findProductAttributeById: (state) => (id) => {
      return state.productAttributes.find(
        attr => attr.id === id
      );
    },
    /**
     * Get an index of a ProductAttribute by Id.
     * @param {string} id Id of a ProductAttribute.
     */
    findProductAttributeIndexById: (state) => (id) => {
      return state.productAttributes.findIndex(
        attr => attr.id === id
      );
    },
  },
  actions: {
    /**
     * Load ProductAttributes.
     * It loads ProductAttributes from the sever and set selectable ProductAttributes.
     */
    async loadProductAttributes({ commit }) {
      const response = await getProductAttributesApi();
      if (isResponseOK(response)) {
        const productAttributes = parseProductAttribute(response.data);
        commit("setAllProductAttributes", productAttributes.sort(sortByName));
        commit("setSelectableProductAttributes");
      } else {
        console.log("[Error]loadProductAttributes:");
      }
      return response;
    },
    /**
     * Update a ProductAttribute.
     * It updates a ProductAttribute and selectable ProductAttributes accordingly.
     * @param {Object} attr ProductAttribute object: Refer to PRODUCT_ATTRIBUTE_MODEL.
     */
    async updateOneProductAttribute({ commit, getters }, attr) {
      const response = await updateProductAttributeApi(attr);
      if (isResponseOK(response)) {
        commit(
          "updateOneProductAttribute",
          {
            attr: parseProductAttribute(response.data),
            index: getters.findProductAttributeIndexById(attr.id)
          }
        );
        commit("setSelectableProductAttributes");
      } else {
        console.log("[Error]updateOneProductAttribute:");
      }
      return response;
    },
    /**
     * Delete a ProductAttribute.
     * It deletes a ProductAttribute and selectable ProductAttributes accordingly.
     * @param {string} id Id of a ProductAttribute.
     */
    async deleteOneProductAttribute({ commit, getters }, id) {
      const response = await deleteProductAttributeApi(id);
      if (isResponseOK(response)) {
        commit("deleteOneProductAttribute", getters.findProductAttributeIndexById(id));
        commit("setSelectableProductAttributes");
      } else {
        console.log("[Error]deleteOneProductAttribute:");
      }
      return response;
    },
    /**
     * Add a ProductAttribute.
     * It adds a ProductAttribute and selectable ProductAttributes accordingly.
     * @param {Object} attr ProductAttribute object: Refer to PRODUCT_ATTRIBUTE_MODEL.
     */
    async addOneProductAttribute({ commit }, attr) {
      const response = await addProductAttributeApi(attr);
      if (isResponseCREATED(response)) {
        commit("addOneProductAttribute", parseProductAttribute(response.data));
        commit("setSelectableProductAttributes");
      } else {
        console.log("[Error]addOneProductAttribute:");
      }
      return response;
    },
  }
};
