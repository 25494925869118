// List componet of Orders' Promotions.
// Author : Huen Oh (heons921@gmail.com)
// @param {string}  name     Name of the component: Index in usual.
// @param {Object}  data     Object for the component: Refer to PromotionDetail.getPromotionInfo.
// @param {boolean} detailed Value to determine whether to show promotion detail information or not. Used in OrderDetail.vue


<template>

  <b-card no-body class="p-3 mb-2" v-if="detailed">
    <b-card 
      :data-cy="`list-item-order-promotion-${name}`"
      class="order-promotion-card py-2 px-3 my-2 text-white"
      :class="{[getClassByPromotionType]:true}" 
      no-body
    >
      <b-row>
        <b-col cols="8" class="text-left">
          <h4 class="m-0 p-0">
            <router-link :data-cy="`list-item-order-promotion-${name}-link`" :to="`?p=${data.id}`">
              <span 
                class="text-white router-link" 
                @click="onTitleClick"
              >{{data.title}}</span>
            </router-link>
          </h4>
        </b-col>
        <b-col cols="4" class="text-right">
          <h4 v-if="!data.isRedeem" class="m-0 p-0" :data-cy="`list-item-order-promotion-${name}-reward`">{{reward}}</h4>
          <h4 v-else class="m-0 p-0" :data-cy="`list-item-order-promotion-${name}-reward`">{{usedPointsOrPunches}}</h4>
        </b-col>
      </b-row>
    </b-card>

    <!-- Promotion detail information  -->

    <!-- Perk description must be present for all types of promotions  -->
    <!-- TODO: Need to update this information based on backend update. Waiting for update in PromotionDetail in backend response -->
    <!-- <b-row class="mx-1 mt-3 p-0">
      <b-col>
        <h6><strong>{{$t('promotions.perk-description')}}</strong></h6>
      </b-col>
      <b-col class="text-right">
        <h6 :data-cy="`list-item-order-promotion-${name}-description`">{{perkDescription}}</h6>
      </b-col>
    </b-row>
    <hr class="m-1"/> -->

    <!-- Applied products  -->
    <b-row class="mx-1 mt-3 p-0">
      <b-col>
        <h6><strong>{{$t('promotions.applied-products')}}</strong></h6>
      </b-col>
      <b-col class="text-right h6" :data-cy="`list-item-order-promotion-${name}-products`">
          <!-- Applied on product list  -->
          <template v-if="data.appliedProductList && data.appliedProductList.length > 0">
            <b-row 
              v-for="(product) in data.appliedProductList"
              :key="product.id"
            >
              <!-- Product name  -->
              <b-col class="text-right">
                <span class="router-link" @click="onAppliedProductNameClick(product.id)">{{product.name}}:</span>
              </b-col>
              <!-- Product Quantity  -->
              <b-col cols="2" class="pl-0">{{product.quantity}} {{$t('general.qty')}}</b-col>
            </b-row>
          </template>
          <!-- Applied on entire cart  -->
          <template v-else>
            {{$t('promotions.entire-cart')}}
          </template>
      </b-col>
    </b-row>
    <hr class="m-1"/>
    
    <!-- Discounts applied  -->
    <b-row class="mx-1 mt-3 p-0">
      <b-col>
        <h6><strong>{{$t('promotions.discount-applied')}}</strong></h6>
      </b-col>
      <b-col class="text-right h6">
        <!-- Discount applicable - only appliable for Deals and Redeem Punch Card and Reward Item -->
        <template v-if="data.totalDiscountAmount >= 0">
          <!-- Total(Product) discount amount  -->
          <b-row :data-cy="`list-item-order-promotion-${name}-discount-amount`">
            <b-col class="text-right">{{$t('promotions.total-discount-amount')}}:</b-col>
            <b-col cols="2" class="pl-0">${{formatPrice(data.totalDiscountAmount)}}</b-col>
          </b-row>
          <!-- Addon discount amount if available  -->
          <b-row v-if="data.isAddonCharged === false && data.isAddonsPresentToDiscount === true" :data-cy="`list-item-order-promotion-${name}-addon-discount-amount`">
            <b-col class="text-right">{{$t('promotions.addon-discount-amount')}}:</b-col>
            <b-col cols="2" class="pl-0">${{formatPrice(data.addonDiscountAmount ? data.addonDiscountAmount : 0)}}</b-col>
          </b-row>
          <!-- Addon discount info if available but no addons were used in order  -->
          <b-row v-else-if="data.isAddonCharged === false && data.isAddonsPresentToDiscount === false">
            <b-col class="text-right">
              ({{$t('promotions.no-addons-present')}})
            </b-col>
          </b-row>
          <!-- Addon discount info if not available  -->
          <b-row v-else>
            <b-col class="text-right">
              ({{$t('promotions.addon-charged')}})
            </b-col>
          </b-row>
        </template>
        <!-- Discount not applicable  -->
        <template v-else-if="data.isDiscountNotApplicable">
          {{$t('general.not-applicable-full')}}
        </template>
      </b-col>
    </b-row>
    <hr class="m-1"/>

    <!-- Perk conditions  -->
    <b-row class="mx-1 mt-3">
      <b-col cols="4">
        <h6><strong>{{$t('promotions.perk-conditions')}}</strong></h6>
      </b-col>
      <b-col cols="8" class="text-right">
        <template v-if="Object.entries(data.conditions).length > 0">
          <b-badge v-for="(value, conditionName, index) in data.conditions" :key="index" class="ml-1 mb-1" variant="primary">
            <h6 class="m-0 p-0" :data-cy="`list-item-order-promotion-${name}-condition-${index}`">
              {{$t(`promotions.conditions.${getLocalizationVariableName(conditionName, value)}`, [value])}} 
            </h6>
          </b-badge>
        </template>
        <template v-else>
          <b-badge class="ml-1 mb-1" variant="primary">
            <h6 class="m-0 p-0">{{$t('general.none')}}</h6>
          </b-badge>
        </template>
      </b-col>
    </b-row>

    <!-- End Promotion Detail  -->

  </b-card>

  <b-card 
    :data-cy="`list-item-order-promotion-${name}`"
    class="order-promotion-card py-2 px-3 my-2 text-white"
    :class="{[getClassByPromotionType]:true}" 
    no-body
    v-else
  >
    <b-row>
      <b-col cols="8" class="text-left">
        <h4 class="m-0 p-0">
          <router-link :data-cy="`list-item-order-promotion-${name}-link`" :to="`?p=${data.id}`">
            <span 
              class="text-white router-link" 
              @click="onTitleClick"
            >{{data.title}}</span>
          </router-link>
        </h4>
      </b-col>
      <b-col cols="4" class="text-right">
        <h4 v-if="!data.isRedeem" class="m-0 p-0" :data-cy="`list-item-order-promotion-${name}-reward`">{{reward}}</h4>
        <h4 v-else class="m-0 p-0" :data-cy="`list-item-order-promotion-${name}-reward`">{{usedPointsOrPunches}}</h4>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {TYPE_PROMOTION, TYPE_PROMOTION_LOWERCASE} from '@/models/promotion'
import {formatPrice, isNullOrUndefined} from '@/utils/general'
import {addNotifyWarning} from '@/utils/notify'

export default {
  props:{
    // {string} Name of the component. Index in usual.
    name: {
      type: String,
      default: ""
    },
    // {Object} Object for the component: Refer to PromotionDetail.getPromotionInfo.
    data: {
      type: Object,
      default:() => {}
    },
    detailed: {
      type: Boolean,
      default: () => false,
    }
  },
  computed: {

    /**
     * Returns the text for reward according to PromotionDetail type.
     * Only handle Normal Order types.
     */
    reward() {
      if (this.data.type === TYPE_PROMOTION.PUNCH_CARD) {
        if (!isNullOrUndefined(this.data.reward)) {
          if (!isNullOrUndefined(this.data.rewardPoints)) {
            return `${this.data.reward} ${this.$t('promotions.rewards.punch-card')}/ ${this.data.rewardPoints} ${this.$t('promotions.rewards.point-perk')}`;
          }
          return `${this.data.reward} ${this.$t('promotions.rewards.punch-card')}`;
        } else {
          return '';
        }
      } 
      else if (this.data.type === TYPE_PROMOTION.POINT_PERK) {
        return `${this.data.reward} ${this.$t('promotions.rewards.point-perk')}`;
      }
      else if (this.data.type === TYPE_PROMOTION.DEAL) {
        return `- $${this.data.reward}`
      } else {
        return '';
      }

    },

    /**
     * Returns text for used points or punches for Redeem PromotionDetail.
     * Only handles Redeem Promotion types.
     */
    usedPointsOrPunches() {
      if (this.data.type === TYPE_PROMOTION.REWARD_ITEM && !isNullOrUndefined(this.data.usedPoints)) {
        return `- ${this.data.usedPoints} ${this.$t('promotions.rewards.point-perk')}`;
      }
      else if (this.data.type == TYPE_PROMOTION.PUNCH_CARD_REDEEM && !isNullOrUndefined(this.data.usedPunches)) {
        return `- ${this.data.usedPunches} ${this.$t('promotions.rewards.punch-card')}`;
      } else {
        return '';
      }
    },

    /**
     * Returns lowercase promotion type as class name to apply different background colors.
     */
    getClassByPromotionType() {
      return TYPE_PROMOTION_LOWERCASE[this.data.type];
    }
  },
  methods: {
    formatPrice,
    addNotifyWarning,
    isNullOrUndefined,

    /**
     * Event emmitter when PromotionDetail title is clicked. Opens a dialog box to show current Promotion information for reference.
     */
    onTitleClick() {
      this.$emit("show-item");
    },

    /**
     * Emit event to show the product dialog box with current information for reference.
     */
    onAppliedProductNameClick(productId) {
      this.$emit('show-applied-product', productId);
    },

    /**
     * Returns text for different types of conditions in PromotionDetail.
     * Conditions can be: isMaster, isAddonCharged, .. , couponCode etc. Refer to PromotionDetail.getPromotionInfo.
     */
    getLocalizationVariableName(conditionName, value) {
      if (conditionName == 'isMaster' || conditionName == 'isNewClient') {
        return `${conditionName}-${value}`;
      } else {
        return conditionName;
      }
    }
  }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>