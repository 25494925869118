var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoaded && !_vm.isOrderEmpty
    ? _c(
        "div",
        { staticClass: "order-detail-main-div" },
        [
          _c("dialog-product", {
            attrs: {
              dialog: _vm.dialogs.product.show,
              data: _vm.dialogs.product.value,
              isOnProductPage: false,
            },
            on: { "dialog-close": _vm.closeDialogProduct },
          }),
          _vm._v(" "),
          _c("dialog-addon", {
            attrs: {
              dialog: _vm.dialogs.addon.show,
              data: _vm.dialogs.addon.value,
              isOnAddonPage: false,
            },
            on: { "dialog-close": _vm.closeDialogAddon },
          }),
          _vm._v(" "),
          _c("dialog-promotion", {
            attrs: {
              dialog: _vm.dialogs.promotion.show,
              data: _vm.dialogs.promotion.value,
              isOnPromotionPage: false,
            },
            on: {
              "dialog-close": _vm.closeDialogPromotion,
              "edit-item": function ($event) {
                return _vm.editPromotion(_vm.dialogs.promotion.value)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "b-alert",
            {
              staticClass: "mx-3 rounded",
              attrs: { show: _vm.isOrderRejected, variant: "danger" },
            },
            [
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("order.message-by-merchant"))),
              ]),
              _vm._v(
                ": " + _vm._s(_vm.currentOrder.messageByMerchant) + "\n  "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-card",
            { staticClass: "mx-3 px-3" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex flex-column", attrs: { md: "10" } },
                    [
                      _c("h1", { attrs: { "data-cy": "heading-order-id" } }, [
                        _c("strong", [_vm._v(_vm._s(_vm.heading))]),
                      ]),
                      _vm._v(" "),
                      _c("h2", [_vm._v(_vm._s(_vm.storeName))]),
                      _vm._v(" "),
                      _c("h6", [_vm._v(_vm._s(_vm.orderCompletedAt))]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "ml-auto", attrs: { md: "1" } },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            "data-cy": "link-order-history",
                            to: "order-history",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            { attrs: { "data-cy": "button-back" } },
                            [_vm._v(_vm._s(_vm.$t("buttons.back")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card",
                        { staticClass: "p-3 mt-3", attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h6", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("order.fields.serve-status")
                                    ) + ": "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("b-col", [
                                _c(
                                  "h6",
                                  [
                                    _c(
                                      "b-badge",
                                      {
                                        staticClass: "text-uppercase",
                                        attrs: {
                                          variant: "primary",
                                          "data-cy":
                                            "order-detail-server-status",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.serveStatus))]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    [
                      _vm.isOrderRejected === false
                        ? _c(
                            "b-card",
                            {
                              staticClass: "p-3 mt-3",
                              attrs: { "no-body": "" },
                            },
                            [
                              _c(
                                "b-row",
                                { staticClass: "mb-2" },
                                [
                                  _c("b-col", [
                                    _c("h6", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("order.fields.preparing-time")
                                        ) + ": "
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-col", [
                                    _c(
                                      "h6",
                                      {
                                        attrs: {
                                          "data-cy":
                                            "order-detail-preparing-time",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatTime(
                                              _vm.currentOrder.preparingTime
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isDeliveryOrder
                                ? _c(
                                    "b-row",
                                    { staticClass: "mt-1" },
                                    [
                                      _c("b-col", [
                                        _c("h6", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "order.fields.delivering-time"
                                              )
                                            ) + ": "
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("b-col", [
                                        _c(
                                          "h6",
                                          {
                                            attrs: {
                                              "data-cy":
                                                "order-detail-delivering-time",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatTime(
                                                  _vm.currentOrder
                                                    .deliveringTime
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-card", { staticClass: "m-3" }, [
            _c("h1", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.$t("order.customer-details"))),
            ]),
            _vm._v(" "),
            _c("h4", { attrs: { "data-cy": "order-detail-customer-name" } }, [
              _vm._v(
                _vm._s(
                  _vm.currentOrder.customerName
                    ? _vm.customerName
                    : this.$t("customer-no-name")
                )
              ),
            ]),
            _vm._v(" "),
            _vm.currentOrder.customerPhone
              ? _c("div", [
                  _c(
                    "h4",
                    {
                      staticClass: "text-muted",
                      attrs: { "data-cy": "order-detail-customer-phone" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.formatPhoneNumber(
                              _vm.currentOrder.customerPhone
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentOrder.isPickup === null
              ? _c("div", [
                  _c(
                    "h4",
                    {
                      staticClass: "text-muted",
                      attrs: { "data-cy": "order-detail-customer-address" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.currentOrder.deliveryAddress.unit
                            ? _vm.currentOrder.deliveryAddress.unit + " -"
                            : ""
                        ) +
                          "\n        " +
                          _vm._s(
                            _vm.currentOrder.deliveryAddress.streetNumber
                          ) +
                          ", \n        " +
                          _vm._s(_vm.currentOrder.deliveryAddress.streetName) +
                          ", \n        " +
                          _vm._s(_vm.currentOrder.deliveryAddress.city) +
                          ", \n        " +
                          _vm._s(_vm.$t("address.postal")) +
                          ": " +
                          _vm._s(_vm.currentOrder.deliveryAddress.postalCode) +
                          "\n      "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "b-card",
            { staticClass: "m-3" },
            [
              _vm._l(_vm.currentOrder.items, function (product, index) {
                return _c("ListOrderProduct", {
                  key: index,
                  attrs: { name: `${index}`, data: product, detailed: true },
                  on: {
                    "show-product": function ($event) {
                      return _vm.openDialogProduct(product.productId)
                    },
                    "show-addon": _vm.openDialogAddon,
                  },
                })
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "text-left ml-2" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("order.price.sub")) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right mr-3",
                          attrs: { "data-cy": "order-detail-sub-total" },
                        },
                        [
                          _vm._v(
                            "\n          $" +
                              _vm._s(
                                _vm.formatPrice(_vm.currentOrder.subAmount)
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.currentOrder.isPickup == null
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "text-left ml-2" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("order.price.delivery")) +
                                "\n          "
                            ),
                            _vm.currentOrder.isDeliveryPriceTaxExempt
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(_vm.$t("store.delivery-no-tax")) +
                                      ")"
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right mr-3",
                              attrs: {
                                "data-cy": "order-detail-delivery-price",
                              },
                            },
                            [
                              _vm._v(
                                "\n          $" +
                                  _vm._s(
                                    _vm.formatPrice(
                                      _vm.currentOrder.deliveryPrice
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentOrder.discountAmount
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "text-left text-primary ml-2" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("order.price.perks")) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right text-primary mr-3",
                              attrs: {
                                "data-cy": "order-detail-discount-amount",
                              },
                            },
                            [
                              _vm._v(
                                "\n          - $" +
                                  _vm._s(
                                    _vm.formatPrice(
                                      _vm.currentOrder.discountAmount
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isNullOrUndefined(_vm.currentOrder.serviceFee) &&
                  _vm.currentOrder.serviceFee > 0
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "text-left ml-2" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("order.price.service-fee")) +
                                "\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right mr-3",
                              attrs: { "data-cy": "order-detail-service-fee" },
                            },
                            [
                              _vm._v(
                                "\n          $" +
                                  _vm._s(
                                    _vm.formatPrice(_vm.currentOrder.serviceFee)
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isNullOrUndefined(_vm.currentOrder.appliedTaxes) == false
                    ? _vm._l(
                        _vm.currentOrder.appliedTaxes,
                        function (tax, taxIdx) {
                          return _c("ListAppliedTaxOnOrder", {
                            key: taxIdx,
                            attrs: { data: tax, name: `${taxIdx}` },
                          })
                        }
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentOrder.globalPointsUsed
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "text-left font-weight-bold ml-2" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("order.price.global-points")) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right font-weight-bold mr-3",
                              attrs: {
                                "data-cy": "order-detail-global-points-used",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.formatNumber(
                                      _vm.currentOrder.globalPointsUsed
                                    )
                                  ) +
                                  " pts.\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-left font-weight-bold ml-2" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("order.price.total")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right font-weight-bold mr-3",
                          attrs: { "data-cy": "order-detail-total-amount" },
                        },
                        [
                          _vm._v(
                            "\n          $" +
                              _vm._s(
                                _vm.formatPrice(_vm.currentOrder.totalAmount)
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm.currentOrder.globalPointsUsed
            ? _c("OrderGlobalPoints", {
                attrs: {
                  "cy-prefix": "order-details",
                  data: _vm.getOrderGlobalPointsProps,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.promotionPerks.length > 0
            ? _c(
                "b-card",
                { staticClass: "m-3" },
                [
                  _c("h1", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$t("order.perks"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.promotionPerks, function (item, index) {
                    return _c("ListOrderPromotion", {
                      key: index,
                      attrs: { name: `${index}`, detailed: true, data: item },
                      on: {
                        "show-item": function ($event) {
                          return _vm.openDialogPromotion(item.id)
                        },
                        "show-applied-product": _vm.openDialogProduct,
                      },
                    })
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
    : _c("div", { staticClass: "loading", attrs: { "data-cy": "div-loading" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }