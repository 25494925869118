// Dropdown for ProductGroup Filter selection.
// Author : Huen Oh (heons921@gmail.com)
// @param {string} label Label text for the dropdown. (in)

<template>
   <b-dropdown 
     data-cy="top-dropdown-product-group"
     :text="`${$t('product.group')}: ${label}`" 
     variant="outline-dark"
     class="mr-1 btn-group align-top"
     size="xs"
     no-flip    
    >
      <b-dropdown-item
        :data-cy="`top-dropdown-product-group-item-${index}`"
        v-for="(group,index) in selectableProductGroups" 
        :key="index"
        @click="onClick(group)"
      >
      {{ group.label }}
      </b-dropdown-item>
    </b-dropdown>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  props:{
    // {string} Label text for the dropdown. (in)
    label:{
      type: String,
      default: "",
    }
  },
  computed:{
    // Map getters
    ...mapGetters("product_group",    ["selectableProductGroups"]),
  },
  methods:{
    /**
     * On click the button.
     */
    onClick(filter) {
      this.$emit('click', filter);
    }
  }
}
</script>
