// Edit Price Component.
// Author : Huen Oh (heons921@gmail.com)
// @param {bool}   disabled Disabled property of the component. (in)
// @param {string} name     For data-cy value
// @param {Object} data     Data object: {price:""}. (in, out)
// @param {number} colSize  Size of the column for the form. : 1 ~ 12. (in)

<template>
  <!-- Price Selection -->
  <b-form-group class="custom-form-group">
    <h6>{{$t('product.provide-price')}}</h6>
    <b-row align-v="center" no-gutters>
      <b-colxx :sm="colSize">
        <b-input-group append="CAD"
        v-bind:class="{ 'mb-5' : $v.data.price.$invalid && !isFirstLoad}">
          <b-form-input
            v-model="data.price"
            class="custom-form-input"
            :data-cy="`${name}-input-price`"
            :placeholder="$t('forms.price')"
            :disabled="disabled"
            @keypress="restrictDecimal"
          />
        </b-input-group>
        <b-form-invalid-feedback
        class="invalid-feedback-space-md" 
        :state="!$v.data.price.$invalid || isFirstLoad">
          {{ $t('validations.num-greater-and-0')}}
        </b-form-invalid-feedback>
      </b-colxx>
    </b-row>
  </b-form-group>
</template> 

 
<script>
// For validation
import { validationMixin } from "vuelidate";
const { required, decimal, minValue } = require("vuelidate/lib/validators");

// utils
import { isHasLess2DecimalPlaces } from "@/utils/general.js"

export default {
  props:{
    // {string} Name of the component. For data-cy.
    name: {
      type: String,
      default: ""
    },
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {Object} data : {price:""}}
    data: {
      type: Object,
      default: () => { return {price:""} }
    },
    // {number}    colSize     Size of the column for the form. : 1 ~ 12. (in)
    colSize:{
     type:Number,
     default:10
    },
    // {Boolean} Is First Load, in order not to display validation message at first
    isFirstLoad:{
      type: Boolean,
      default: true
    }
  },
  mixins: [validationMixin],
  validations: {
    data: {
      price: {
        required,
        decimal,
        minValue: minValue(0),
      },
    }, 
  },    
  methods:{
    /**
     * Restrict users to enter decimal no more than 2 places
     */
    restrictDecimal($event){
      const isValid = isHasLess2DecimalPlaces(this.data.price);
      if(!isValid){
        $event.preventDefault();
      }
    }
  }
}
</script>