var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      staticClass: "mr-1 mb-1",
      attrs: {
        "data-cy": "top-dropdown-filterColumn",
        text: _vm.$t("order.history.filter-columns"),
        variant: "outline-dark",
        "menu-class": "dropdown-filter-column-ul",
        size: "xs",
        "no-flip": "",
      },
    },
    _vm._l(_vm.columnOptions, function (column, index) {
      return _c(
        "b-dropdown-item",
        {
          key: index,
          attrs: {
            "data-cy": `top-dropdown-filterColumn-item-${index}`,
            active: column.active,
            disabled: column.disabled,
          },
          on: {
            click: function ($event) {
              return _vm.onClick(column)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(column.text) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }