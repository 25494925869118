// Componet for selecting ProductGroups.
// Author : Huen Oh (heons921@gmail.com)
// @param {string}    name        Name of the component. Index in usual. (in)
// @param {bool}      value       Value for isChanged. v-model. (in, out)
// @param {bool}      disabled    Disabled property of the component. (in)
// @param {bool}      isRequired  To check if at least one selection is required. (in)
// @param {number}    colSize     Size of the column for the form. : 1 ~ 12. (in)
// @param {string[]}  groups      ProductGroups. (in)

<template>
<div>
  <!-- Dialog for ProductGroup - Add -->
  <dialog-product-group
    :dialog="dialogs.groups"
    :data="emptyProductGroup"
    @dialog-close="closeDialogProductGroup"
    @onAddItem="onAddItem"
  />

  <!-- ProductGroups -->
  <b-form-group>
    <h6 v-if="!disabled">{{$t('product.choose-product-group')}}</h6>
    <h6 v-else>{{$t('product.product-group')}}</h6>
    <b-row align-v="center" no-gutters v-if="!disabled">
      <!-- ProductGroups : Selection -->
      <b-colxx :sm="colSize">
        <v-select
          :data-cy="`${name}-select-product-group`"
          :options="currentSelOptions"
          v-model="selGroup"
          v-bind:class="{ 'mb-5' : $v.currentGroups.$invalid}"
          @input="onSelectItem"
        >
        <span slot="no-options">
          {{$t('validations.no-product-group-available')}}
        </span>
          <template #search="{ attributes, events }">
            <input
              :data-cy="`${name}-select-product-group-input`"
              class="vs__search"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
      </b-colxx>
      <!-- ProductGroups : Add button -->
      <b-colxx>
        <b-button
          :data-cy="`${name}-btn-add-product-group`"
          variant="outline-primary"
          size="xs"
          class="cls-btn-add"
          @click="openDialogProductGroup()"
        >
          <b-img src="/assets/icons/plus.svg" width="12" height="12"></b-img>
        </b-button>
      </b-colxx>
    </b-row>
    <b-row align-v="center" no-gutters>
      <!-- ProductGroups : Validation feedkback -->
      <b-form-invalid-feedback :state="isRequired ? (!($v.currentGroups.$invalid) || isFirstLoad): true">
        {{ $t('validations.no-selection-validation')}}
      </b-form-invalid-feedback>
      <!-- ProductGroups : Badge -->
      <b-badge
        v-for="(productGroup,index) in currentGroups" :key="productGroup"
        class="mb-1 cls-badge" pill variant="outline-dark"
      >
        {{ getNameByIdFromList(productGroup, selectableProductGroups) }} 
        <a 
          :data-cy="`${name}-badge-product-group-${index}-delete`" 
          href="javascript:void(0);" 
          @click="deleteProductGroup(index)" 
          v-if="!disabled"
        >x</a>
      </b-badge>
    
      <b-badge v-if="currentGroups.length == 0 && disabled" class="mb-1 cls-badge" pill variant="outline-dark">
        {{$t('general.none')}}
      </b-badge>
      
    </b-row>
  </b-form-group>
</div>
</template>


<script>
import vSelect    from 'vue-select'
import 'vue-select/dist/vue-select.css'

import DialogProductGroup       from '@/components/Products/DialogProductGroup'
import { getNameByIdFromList, addItemValueToList }  from "@/utils/general"

// Notifications
import {
  addNotifyWarning,
} from "@/utils/notify.js"

import { mapGetters, mapActions } from 'vuex'

// For validation
import { validationMixin } from "vuelidate";
const { required, minLength } = require("vuelidate/lib/validators");

import { EMPTY_PRODUCT_GROUP }    from "@/models/products/product_group"


export default {
  // TODO : Find way to reuse this with SelectAddonGroups
  // To prop : selectableProductGroups
  // To use some common: EMPTY_PRODUCT_GROUP
  // Problem : dialog-product-group. 
  //           One way is to merge the dialog of ProductGroup and AddonGroup with type selection.

  props:{
    // {string} Name of the component. Index in usual.
    name: {
      type: String,
      default: ""
    },
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {number} Size of the column for the form. : 1 ~ 12.
    colSize: {
      type: Number,
      default: 10,
    },
    // {string[]} Groups.
    groups: {
      type: Array,
      default: () => {},
    },
    // {bool} Value for isChanged. v-model.
    value: {
      type: Boolean,
      default: false
    },
    // {bool} To check if at least one selection is required.
    isRequired: {
      type: Boolean,
      default: false,
    },
    // {Boolean} Is First Load, in order not to display validation message at first
    isFirstLoad: {
      type: Boolean,
      default: true
    }
  },
  components: {
    'v-select': vSelect,
    DialogProductGroup,
  },
  data(){
    return {
      emptyProductGroup: EMPTY_PRODUCT_GROUP,   // {Objet} Current ProductGroup data for ProductGroup dialog.
      selGroup: {value: "", label:"", text:""}, // {Object} Selected group object.
      currentGroups: [],  // {string[]} Current selected groups

      dialogs: {          // Handler object for dialogs(Close and Open).
        groups:false,
      },

      isFirstWatch:true,  // {bool} Flag if it the first watch to detemine isChanged flag.
      isChanged:false,    // {bool} Flag if anything has changed in the component. // TODO : may not need
    }    
  },

  created: function () {
    // console.log('created');
  },
  watch: {
    /**
     * Watch to check changes in currentGroups.
     */
    currentGroups: {
      deep:true,
      handler() {
        // console.log("watch currentGroups changed");
        if(this.isFirstWatch == false) {
          this.isChanged = true;
          this.$emit('input', true);
          // console.log("watch currentGroups changed");
        } else {
          // console.log("watch currentGroups changed", this.currentGroups);
          this.isFirstWatch = false;
        }

        // Emit validity.
        if(this.isRequired) {
          this.$emit('isValid', this.currentGroups.length > 0);
        } else {
          this.$emit('isValid', true);
        }
        
      },
    },
  },
  computed: {
    // Map getters
    ...mapGetters("product_group", ["selectableProductGroups"]),

    // Computed value to exclude selected items from the selection option.
    currentSelOptions: function() {
      return this.selectableProductGroups.filter((group) => {
        return !this.currentGroups.includes(group.value) && (group.value !== "");
      });
    },
  },

  // Validations
  mixins: [validationMixin],
  validations: {
    currentGroups:{
      required,
      minLength: minLength(1),
    },
  },

  methods:{
    // Get name of an item from a list.
    getNameByIdFromList,

    // Add a value of an item to the item list if it doesn't exist in the list.
    addItemValueToList,

    // Notifications.
    addNotifyWarning,

    /**
     * Reset values.
     */
    reset() {
      this.selGroup = {value: "", label:"", text:""};
      this.currentGroups = [];
      this.isFirstWatch = true;
      this.isChanged = false;
    },
    /**
     * Get selected ProductGroups.
     * @return {string[]} Selected ProductGroups.
     */
    getGroups() {
      return this.currentGroups;
    },
    /**
     * Delete an ProductGroup. It only updates locally.
     * At least one ProductGroup is required.
     * @param {number} index index of an item to delete.
     */
    deleteProductGroup(index) {
      // console.log(this.data.groups);
      if(this.isRequired) {
        if (this.currentGroups.length > 1) {
          this.currentGroups.splice(index,1);
        } else {
          this.addNotifyWarning(this.$t('notify.one-group-required'));
        }
      } else {
         this.currentGroups.splice(index,1);
      }
      
    },

    // Dialog operations - ProductGroup
    /**
     * Close ProductGroup dialog - Add.
     */
    closeDialogProductGroup() {
      this.dialogs.groups = false;
    },
    /**
     * Open ProductGroup dialog - Add.
     */
    openDialogProductGroup() {
      this.emptyProductGroup = JSON.parse(JSON.stringify(EMPTY_PRODUCT_GROUP));
      this.emptyProductGroup.deleteProductList = [];
      this.emptyProductGroup.addProductList = [];
      this.dialogs.groups = true;
    },
    // onSubmitDialogProductGroup() in dialog.

    /**
     * On select item from the selection.
     */
    onSelectItem() {
      this.addItemValueToList(this.currentGroups, this.selGroup);
      this.selGroup = {value: "", label:"", text:""};
    },

    /**
     * On add item from add button.
     * It adds the added item to the list.
     */
    onAddItem(id) {
      this.addItemValueToList(this.currentGroups, {value:id});
    },
  },
  mounted(){
    // console.log('disabled', this.disabled);
    this.currentGroups = JSON.parse(JSON.stringify(this.groups));
  },

}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>

