var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("toggle-button", {
    attrs: {
      "data-cy": `${_vm.name}-toggle-btn-status`,
      color: "#f18024",
      sync: true,
      labels: {
        checked: _vm.ENUM_STATUS_STRING.ACTIVE,
        unchecked: _vm.ENUM_STATUS_STRING.INACTIVE,
      },
      width: 80,
      disabled: _vm.disabled,
    },
    on: {
      change: function ($event) {
        return _vm.onChangeStatus()
      },
    },
    model: {
      value: _vm.switchStatus,
      callback: function ($$v) {
        _vm.switchStatus = $$v
      },
      expression: "switchStatus",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }