/**
 * It contains model of ProductCategory object.
 */
//TODO: move to model/product?

// General parse and serialize function
import { parse, serialize } from "../general.js";

/**
 * ProductCategory Model - for Parse and Serialize use.
 */
const PRODUCT_CATEGORY_MODEL = {
  id: "id",
  merchantID: "merchantID",
  name: "name",
  description: "description"
};

/**
 * Prase a ProductCategory object.
 * @param {Object} productCagetory ProductCategory object: Refer to PRODUCT_CATEGORY_MODEL.
 * @return {Object} Prased ProductCategory object.
 */
export function parseProductCategory(productCagetory) {
  return productCagetory;
  // TODO
  // return parse(productCagetory, PRODUCT_CATEGORY_MODEL);
}

/**
 * Serialize a ProductCategory object.
 * @param {Object} productCagetory ProductCategory object: Refer to PRODUCT_CATEGORY_MODEL.
 * @return {Object} Serialized ProductCategory object.
 */
export function serializeProductCategory(productCagetory) {
  return productCagetory;
  // TODO
  return serialize(productCagetory, PRODUCT_CATEGORY_MODEL);
}