// Componet for Badges of Category
// Author : Huen Oh (heons921@gmail.com)
// @param {string}   cyPrefix Prefix for data-cy.
// @param {string[]} data     List of strings for the badges.

<template>
  <div>
    <b-badge
        v-for="(post, index) in data" :key="index"
        class="mb-2 ml-2"
        variant="info"
        :href="post.postUrl"
        target="_blank"
    >
      {{ post.platform }}
    </b-badge>
  </div>
</template>


<script>
/**
 * Event on click of delete bagde.
 *
 * @event BadgeListCategory#onDelete
 * @type {object}
 * @param {number} index Index.
 */

import BaseBadge from '@/components/Common/BaseBadge'

export default {
  components:{
    BaseBadge,
  },
  props:{
    // {string} Prefix for data-cy.
    cyPrefix: {
      type: String,
      default: ""
    },
    // {string[]} List of strings for the badges.
    data:{
      type:Array,
      default: () => []
    },
  },
  methods:{
    onDelete(index){
      // Event - BadgeListCategory#onDelete
      this.$emit('onDelete',index)
    }
  }
}
</script>
