// Toogle Button for status of Product/ProductGroup/Addon/AddonGroup
// Author : Huen Oh (heons921@gmail.com)
// @param {string} name      Name of the component. Index in usual.
// @param {string} status    Status: "ACTIVE" or "INACTIVE"
// @param {bool}   disabled  Disabled property of the toogle button

<template>
  <toggle-button
    :data-cy="`${name}-toggle-btn-status`"
    v-model="switchStatus"
    color="#f18024"
    :sync="true"
    :labels="{
      checked: ENUM_STATUS_STRING.ACTIVE, 
      unchecked: ENUM_STATUS_STRING.INACTIVE
    }"
    :width="80"  
    @change="onChangeStatus()"
    :disabled="disabled"
  />
</template>


<script>
import { ToggleButton } from 'vue-js-toggle-button'
import { 
  ENUM_STATUS_STRING,
  ENUM_STATUS_VALUE,
  MAP_STATUS_STRING_TO_VALUE
} from "@/constants/common";

export default {
  props:{
    // {string} Name of the component. Index in usual.
    name: {
      type: String,
      default: ""
    },
    // {string} Status: "ACTIVE" or "INACTIVE"
    status: {
      type: String,
      default: ENUM_STATUS_STRING.INACTIVE
    },
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
  },
  components: { ToggleButton },
  data(){
    return {
      ENUM_STATUS_STRING, // To use in the template.

      switchStatus:false, // Status switch
    }    
  },
  created: function () {
    // Set status for the toggle button.
    if(this.status !== ENUM_STATUS_STRING.DELETED) {
      this.switchStatus = MAP_STATUS_STRING_TO_VALUE.get(this.status);
    } else {
      this.switchStatus = ENUM_STATUS_VALUE.INACTIVE;
    }    
  },
  watch: {
    status: function() {
      // Set status for the toggle button.
      if(this.status !== ENUM_STATUS_STRING.DELETED) {
        this.switchStatus = MAP_STATUS_STRING_TO_VALUE.get(this.status);
      } else {
        this.switchStatus = ENUM_STATUS_VALUE.INACTIVE;
      }
    }
  },
  computed: {
    // switchStatus(){
    //   return false;
    //   // return MAP_STATUS_STRING_TO_VALUE[this.status];
    // },
  },
  methods:{
    // Get Status as string.
    getSwitchValueToString() {
      if (this.switchStatus == true) {
        return ENUM_STATUS_STRING.ACTIVE;
      } else {
        return ENUM_STATUS_STRING.INACTIVE;
      }
    },
    /**
     * Update status both in local and server
     */
    onChangeStatus() {
      // console.log("onChangeStatus", this.switchStatus);
      // console.log("onChangeStatus", MAP_STATUS_STRING_TO_VALUE.get(this.status));
      if(this.switchStatus != MAP_STATUS_STRING_TO_VALUE.get(this.status)) {
        this.$emit('on-toggle-change', this.getSwitchValueToString());
      }            
    },
  },
  mounted(){
  }
}
</script>
