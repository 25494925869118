var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    [
      _c(
        "b-row",
        [
          _c(
            "b-colxx",
            { attrs: { xxs: "12", md: "20" } },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "end" } },
                    [
                      _c("b-colxx", { attrs: { xxs: "10" } }, [
                        _c("h4", { staticClass: "mb-0 pb-2" }, [
                          _vm._v(_vm._s(_vm.$t(`${_vm.label}.provide-name`))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("b-colxx", { attrs: { xxs: "2" } }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-right text-muted text-small mb-0 pb-2",
                          },
                          [_vm._v(_vm._s(_vm.textCountName))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      "data-cy": `${_vm.name}-input-name`,
                      state: !_vm.$v.data.name.$invalid || _vm.isFirstLoad,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.data.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "name", $$v)
                      },
                      expression: "data.name",
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-invalid-feedback", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("general.minimum-3-characters")) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "end" } },
                    [
                      _c("b-colxx", { attrs: { xxs: "10" } }, [
                        _c("h4", { staticClass: "mb-0 pb-2" }, [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.label}.provide-description`))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("b-colxx", { attrs: { xxs: "2" } }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-right text-muted text-small mb-0 pb-2",
                          },
                          [_vm._v(_vm._s(_vm.textCountDescription))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-textarea", {
                    attrs: {
                      "data-cy": `${_vm.name}-input-description`,
                      rows: 5,
                      "max-rows": 5,
                      state:
                        !_vm.$v.data.description.$invalid || _vm.isFirstLoad,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.data.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "description", $$v)
                      },
                      expression: "data.description",
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-invalid-feedback", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("general.minimum-3-characters")) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }