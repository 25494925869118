
import {
  getUserProfile,
  uploadProfileAvatar,
  updateDob
} from "../../api/user_profile.js";

import { isResponseOK } from "@/utils/general.js"

export default {
  namespaced: true,
  state: {
    // url and dob only
    // why we cant use one
    // endpoint for this?
    profile: {}
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.profile = {};
    },
    setAccountInfo(state, data) {
      state.info = data;
    },
    setUserProfile(state, data) {
      state.profile = data;
    },
    setAvatar(state, avatar) {
      state.profile = {...state.profile, ...{"avatarURL": avatar} }
    }
  },
  getters: {
    profile: (state) => {
      return state.profile;
    },
    avatarURL: (state) => {
      return state.profile.avatarURL;
    }
  },
  actions: {
    async load({ dispatch }) {
      const profile = await dispatch("loadProfile");
      return {...profile};
    },
    async loadProfile({ commit }) {
      const response = await getUserProfile()
      if (isResponseOK(response)) {
        console.log('response.data', response.data)
        commit("setUserProfile", response.data);
        return response.data;
      } else {
        return {};
      }
    },
    updateAvatar({ commit }, file) {
      uploadProfileAvatar(file)
        .then(response => {
          commit("setAvatar", response.data)
        })
    },
    updateDob({ commit }, dob) {
      updateDob(dob)
        .then(response => {
          commit("setUserProfile", response.data)
        })
    }
  }
};
  