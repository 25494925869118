/**
 * It contains backend CRUD Apis for Merchant Stores.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// 3rd party libraries.
import _ from "lodash";

// Modules in the application.
import axios                                      from "@/api/axios/axios_core_deal.js";
import { uploadImagesApi, updateImagesApi }       from "@/api/general.js";
import { serializeStore, serializeDeliveryRule }  from "@/models/merchant/merchant_store.js";

// URLs for backend
const HOST              = BE_URLS.BACKEND_DEAL_SERVER_URL;
const MERCHANT_URL      = `${HOST}/merchant-stores`;
// const STATUS_URL        = `${MERCHANT_URL}/status`;
const IMAGE_UPLOAD_URL  = BE_URLS.BACKEND_IMAGE_UPLOAD_URL;
const TAX_CONFIG_URL    = `${HOST}/tax-config`;

const DELVIERY_RULE     = 'delivery-rules';


/**
 * Get Stores.
 * @return {Promise} Result of GET for Stores.
 */
export async function getStoresApi() {
  return await axios.get(MERCHANT_URL);
}

/**
 * Add a Store.
 * @param {Object}  data Store object: Refer to MODEL_MERCHANT_STORE.
 * @return {Promise} Result of POST for a Store.
 */
export async function addStoreApi(data) {
  return await axios.post(MERCHANT_URL, serializeStore(data));
}
/**
 * Add a Store with images.
 *
 * It adds a Store with images.
 * If imageObject is not provided(null or undefined), it adds a Store without images.
 *
 * @param {Object}   data           Store object: Refer to MODEL_MERCHANT_STORE.
 * @param {Object[]} [imageObjects] Image objects: [{files, preview}]
 * @return {Promise} Result of POST for a Store.
 * @return {null}    If there is a error - TODO
 */
export async function addStoreWithImagesApi(data, imageObjects) {
  // let toSend = { ...serializeStore(data) };
  let toSend = data;

  if (!Array.isArray(imageObjects) || (imageObjects.length <= 0)) {
    // Add(Upload) the Store without images
    return addStoreApi(toSend);

  } else {
    // Add(Upload) the Store with images
    // Convert image arrary
    // [{file, preview}] -> [file]
    let imageFiles = _.compact(imageObjects.map((image) => { return image.file }));

    // Upload images
    toSend.changedImages = await uploadImagesApi(IMAGE_UPLOAD_URL, imageFiles);
    // console.log(toSend);
    return addStoreApi(toSend);
  }
}


/**
 * Update a Store.
 * @param {Object}   data Store object: Refer to MODEL_MERCHANT_STORE.
 * @return {Promise} Result of PUT for a Store.
 */
export async function updateStoreApi(data) {
  // console.log('updateStoreApi', data);
  return await axios.put(`${MERCHANT_URL}/${data.id}`, serializeStore(data));
}
/**
 * Update a Store with images.
 *
 * It updates a Store with images.
 * If imageObject is not provided(null or undefined), it updates Store without images.
 *
 * @param {Object}   data           Store object: Refer to MODEL_MERCHANT_STORE.
 * @param {Object[]} [imageObjects] Image objects: [{files, preview}]
 * @return {Promise} Result of PUT for a Store.
 * @return {null}    If there is a error - TODO
 */
export async function updateStoreWithImagesApi(data, imageObjects) {
  // let toSend = { ...serializeStore(data) };
  let toSend = data;

  // console.log('updateStoreWithImagesApi-in', imageObjects);
  if (!Array.isArray(imageObjects)) {
    // Update(Upload) the Store without images
    return updateStoreApi(toSend);

  } else {
    // Update(Upload) the Store with images

    // Update images : changedImages, deletedImages.
    await updateImagesApi(IMAGE_UPLOAD_URL, data, imageObjects);

    // console.log('updateStoreWithImagesApi-excute', data);
    return updateStoreApi(toSend);
  }
}

/**
 * Delete a Store.
 *
 * @param {Object}  id  Id of a store.
 * @return {Promise} Result of DELETE for a Store.
 */
export async function deleteStoreApi(id) {
  return await axios.delete(`${MERCHANT_URL}/${id}`);
}


/**
 * Update status of a Store by Id.
 * @param {string} id     ID of a Store object.
 * @param {string} status Status of a Store object: Refer to ENUM_STATUS_STRING.
 * @return {Promise} Result of PUT for status of a Store by Id.
 */
export async function updateStoreStatusApi(id, status) {
  return await axios.put(`${MERCHANT_URL}/${id}/${status}`);
}

/**
 * Get all available TaxConfig.
 * @returns {Promise} Resulr of GET all availalble tax configs 
 */
export async function getAllTaxConfigsApi() {
  return await axios.get(TAX_CONFIG_URL);
}


// Delivery Rule apis

/**
 * Get all delivery rules of the given MerchantStore.
 * @param {BigInteger} storeId MerchantStore id.
 * @returns List of delivery rules.
 */
export async function getAllDeliveryRulesOfStoreApi(storeId) {
  return await axios.get(`${MERCHANT_URL}/${storeId}/${DELVIERY_RULE}`);
}

/**
 * Update delivery rule's status.
 * @param {BigInteger} storeId MerchantStore id. 
 * @param {String} deliveryRuleId DeliveryRule id.
 * @param {Status} status DeliveryRule status.
 * @returns 
 */
export async function updateDeliveryRuleStatusByIdApi(storeId, deliveryRuleId, status) {
  return await axios.put(`${MERCHANT_URL}/${storeId}/${DELVIERY_RULE}/${deliveryRuleId}/status/${status}`);
}

/**
 * Delete a delivery rule by id and storeid.
 * @param {BigInteger} storeId MerchantStore id.
 * @param {String} deliveryRuleId DeliveryRule id.
 * @returns 
 */
export async function deleteDeliveryRuleByIdApi(storeId, deliveryRuleId) {
  return await axios.delete(`${MERCHANT_URL}/${storeId}/${DELVIERY_RULE}/${deliveryRuleId}`);
}

/**
 * Get a delivery rule by storeid and rule id.
 * @param {BigInteger} storeId MerchantStoreId
 * @param {String} deliveryRuleId DeliveryRule Id
 * @returns Delivery rule object
 */
export async function getDeliveryRuleByIdApi(storeId, deliveryRuleId) {
  return await axios.get(`${MERCHANT_URL}/${storeId}/${DELVIERY_RULE}/${deliveryRuleId}`);
}

/**
 * Update an existing delivery rule.
 * @param {BigInteger} storeId MerchantStore id
 * @param {String} deliveryRuleId Delivery Rule id
 * @param {Object} deliveryRuleObject DeliveryRule object, Refer to: MODEL_DELIVERY_RULE in models/merchant/merchant_store.js
 * @returns updated delivery rule.
 */
export async function updateDeliveryRuleByIdApi(storeId, deliveryRuleId, deliveryRuleObject) {
  return await axios.put(
      `${MERCHANT_URL}/${storeId}/${DELVIERY_RULE}/${deliveryRuleId}`, 
      serializeDeliveryRule(deliveryRuleObject)
  );
}

/**
 * Create new delivery rule.
 * @param {BigInteger} storeId MerchantStore id
 * @param {object} delvieryRuleObject Delivery rule object. Refer to: MODEL_DELIVERY_RULE
 * @returns 
 */
export async function createDeliveryRuleApi(storeId, deliveryRuleObject) {
  return await axios.post(
    `${MERCHANT_URL}/${storeId}/${DELVIERY_RULE}`,
    serializeDeliveryRule(deliveryRuleObject)
  );
}

