var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { "global-warning-space": !_vm.isStripeConnected },
      attrs: { "data-cy": `${_vm.cyPrefix}` },
    },
    [
      _c(
        "a",
        {
          attrs: { href: "javascript:void(0);", id: "orders-sidbar-title" },
          on: { click: _vm.onClickOrderTitle },
        },
        [
          _c("h4", { staticClass: "text-center font-weight-bold my-2" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("order.your-orders")) + "\n    "),
          ]),
          _vm._v(" "),
          _c("b-tooltip", {
            attrs: {
              target: "orders-sidbar-title",
              placement: "bottom",
              triggers: "hover",
              title: _vm.$t("tool-tips.refesh"),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-sm d-inline-block mb-3 ml-4" },
        [
          _c("b-input", {
            attrs: {
              "data-cy": "dlg-product-group-input-search",
              placeholder: _vm.$t("menu.search"),
            },
            model: {
              value: _vm.searchKeyword,
              callback: function ($$v) {
                _vm.searchKeyword = $$v
              },
              expression: "searchKeyword",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        {
          staticClass: "order-type-block order-type-grid",
          attrs: { "align-h": "between" },
        },
        [
          _c(
            "b-col",
            {
              staticClass: "order-type-cols order-type-border",
              class: { selected: _vm.selectedTypes.new },
              attrs: { "data-cy": `${_vm.cyPrefix}-filter-new-orders` },
              on: {
                click: function ($event) {
                  return _vm.OnClickOrderTypeBtn(null)
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "order-type-icon font-weight-bold theme-color-text",
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("order.serve-status.new").toUpperCase()) +
                      "\n        "
                  ),
                  _c(
                    "b-badge",
                    {
                      staticClass: "badge-top-right circle-badge",
                      attrs: { variant: "secondary" },
                    },
                    [_vm._v(_vm._s(_vm.numberNewOrders))]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "order-type-cols order-type-border",
              class: { selected: _vm.selectedTypes.accepted },
              attrs: { "data-cy": `${_vm.cyPrefix}-filter-accepted-orders` },
              on: {
                click: function ($event) {
                  return _vm.OnClickOrderTypeBtn("ACCEPTED")
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "order-type-icon font-weight-bold theme-color-text",
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("order.serve-status.accepted").toUpperCase()
                    ) + "\n        "
                  ),
                  _c(
                    "b-badge",
                    {
                      staticClass: "badge-top-right circle-badge",
                      attrs: { variant: "secondary" },
                    },
                    [_vm._v(_vm._s(_vm.numberAcceptedOrders))]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "order-type-cols order-type-border",
              class: { selected: _vm.selectedTypes.preparing },
              attrs: { "data-cy": `${_vm.cyPrefix}-filter-preparing-orders` },
              on: {
                click: function ($event) {
                  return _vm.OnClickOrderTypeBtn("PREPARING")
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "order-type-icon font-weight-bold theme-color-text",
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("order.serve-status.preparing").toUpperCase()
                    ) + "\n        "
                  ),
                  _c(
                    "b-badge",
                    {
                      staticClass: "badge-top-right circle-badge",
                      attrs: { variant: "secondary" },
                    },
                    [_vm._v(_vm._s(_vm.numberPreparingOrders))]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "order-type-cols order-type-border",
              class: { selected: _vm.selectedTypes.ready },
              attrs: { "data-cy": `${_vm.cyPrefix}-filter-ready-orders` },
              on: {
                click: function ($event) {
                  return _vm.OnClickOrderTypeBtn("READY")
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "order-type-icon font-weight-bold theme-color-text",
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("order.serve-status.ready").toUpperCase()) +
                      "\n        "
                  ),
                  _c(
                    "b-badge",
                    {
                      staticClass: "badge-top-right circle-badge",
                      attrs: { variant: "secondary" },
                    },
                    [_vm._v(_vm._s(_vm.numberReadyOrders))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vue-perfect-scrollbar",
        {
          staticClass: "scroll",
          attrs: {
            settings: { suppressScrollX: true, wheelPropagation: false },
          },
        },
        _vm._l(_vm.filteredOrders, function (order, index) {
          return _c("ListOrderCard", {
            key: index,
            class: {
              selected:
                _vm.currentOrder && order.id === _vm.currentOrder.id
                  ? true
                  : false,
            },
            attrs: { cyPrefix: `list-item-order-${index}`, data: order },
            on: {
              click: function ($event) {
                return _vm.onClickOrderCard(order)
              },
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }