var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "m-3" },
    [
      _c("h1", { staticClass: "font-weight-bold" }, [
        _vm._v(_vm._s(_vm.$t("global-points.global-points-title"))),
      ]),
      _vm._v(" "),
      _c(
        "b-card",
        {
          staticClass: "order-promotion-card text-white rounded py-2 px-3 my-2",
          attrs: { "data-cy": `${_vm.cyPrefix}-global-points`, "no-body": "" },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-left", attrs: { cols: "6" } }, [
                _c("h4", { staticClass: "m-0 p-0" }, [
                  _vm._v(
                    _vm._s(_vm.formatNumber(_vm.data.globalPointsUsed)) +
                      " " +
                      _vm._s(_vm.$t("order.global-points-suffix"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("b-col", { staticClass: "text-right", attrs: { cols: "6" } }, [
                _c(
                  "h4",
                  {
                    staticClass: "m-0 p-0",
                    attrs: {
                      "data-cy": `${_vm.cyPrefix}-global-points-money-conversion`,
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.$t("order.details.converts-to")) +
                        ": $" +
                        _vm._s(
                          _vm.data.globalPointsEquivalentAmount
                            ? _vm.formatPrice(
                                _vm.data.globalPointsEquivalentAmount
                              )
                            : ""
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }