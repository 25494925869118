/**
 * It contains model of ProductGroup object.
 */

// General parse and serialize function
import { parse, serialize } from "../general.js";

/**
 * ProductGroup Model - for Parse and Serialize use.
 */
const PRODUCT_GROUP_MODEL = {
  id: "id",
  name: "name",
  description: "description",
  merchantId: "merchantId",
  isAddon: "isAddon",
  products: "products",
  rank: "rank"
};

/**
 * Empty default ProductGroup.
 */
export const EMPTY_PRODUCT_GROUP = {
  name: "",
  description: "",
  images: null,
  // merchantId: "",
  // isAddon: true,
  products: [],
  lastUpdatedAt: "",
};

/**
 * Parse ProductGroup objects.
 * @param {Object} productGroups ProductGroup objects: Refer to PRODUCT_GROUP_MODEL and EMPTY_PRODUCT_GROUP.
 * * @return {Object[]} Prased ProductGroup objects.
 */
export function parseProductGroups(productGroups) {
  return productGroups;
  // TODO
  // return parse(productGroup, PRODUCT_GROUP_MODEL);
}

/**
 * Parse a ProductGroup object.
 * @param {Object} productGroup ProductGroup object: Refer to PRODUCT_GROUP_MODEL and EMPTY_PRODUCT_GROUP.
 * * @return {Object} Prased ProductGroup object.
 */
export function parseProductGroup(productGroup) {
  // productGroup.isSyncedInGroup = {};
  return productGroup;
  // TODO
  // return parse(productGroup, PRODUCT_GROUP_MODEL);
}

/**
 * Serialize a ProductGroup object.
 * @param {Object} productGroup ProductGroup object: Refer to PRODUCT_GROUP_MODEL and EMPTY_PRODUCT_GROUP.
 * @return {Object} Serialized ProductGroup object.
 */
export function serializeProductGroup(productGroup) {
  return productGroup;
  // TODO
  // return serialize(productGroup, PRODUCT_GROUP_MODEL);
}