// Sidebar menu
// @Author: Piaf library



<template>
<div class="sidebar" v-bind:class="{ 'is-global-warning': !isStripeConnected }" @click.stop="()=>{}">
  <div class="main-menu" v-bind:class="{ 'is-global-warning': !isStripeConnected }">
    <vue-perfect-scrollbar class="scroll" :settings="{ suppressScrollX: true, wheelPropagation: false }">
      <ul class="list-unstyled">
        <li v-for="item in menuItems"
          :data-cy="`side-bar-${item.id}`"
          :class="{ 'active' : (selectedParentMenu === item.id && viewingParentMenu === '') || viewingParentMenu === item.id }"
          :key="`parent_${item.id}`"
          :data-flag="item.id">
          <a v-if="item.newWindow" :href="item.to" rel="noopener noreferrer" target="_blank">
            <i :class="item.icon" />
            <div class="text-center">{{ $t(item.label) }}</div>
          </a>
          <a v-else-if="item.subs && item.subs.length>0" @click.prevent="openSubMenu($event,item)" :href="`#${item.to}`">
            <i :class="item.icon" />
            <div class="text-center">{{ $t(item.label) }}</div>
          </a>
          <router-link v-else @click.native="changeSelectedParentHasNoSubmenu(item.id)" :to="item.to">
            <i :class="item.icon" />
            <div class="text-center">{{ $t(item.label) }}</div>
          </router-link>
        </li>
      </ul>
    </vue-perfect-scrollbar>
  </div>
  <div class="sub-menu" v-bind:class="{ 'is-global-warning': !isStripeConnected }">
    <vue-perfect-scrollbar class="scroll" :settings="{ suppressScrollX: true, wheelPropagation: false }">
      <ul v-for="(item,itemIndex) in menuItems"
        :class="{'list-unstyled':true, 'd-block' : (selectedParentMenu === item.id && viewingParentMenu === '') || viewingParentMenu === item.id }"
        :data-parent="item.id"
        :key="`sub_${item.id}`"
      >
        <li v-for="(sub,subIndex) in item.subs"
          :data-cy="`side-bar-${sub.id}`"
          :key="subIndex"
          :class="{'has-sub-item' : sub.subs && sub.subs.length > 0 , 'active' : $route.path.indexOf(sub.to)>-1}"
        >
          <a v-if="sub.newWindow" :href="sub.to" rel="noopener noreferrer" target="_blank">
            <i :class="sub.icon" />
            <span>{{ $t(sub.label) }}</span>
          </a>
          <template v-else-if="sub.subs &&  sub.subs.length > 0">
            <b-link v-b-toggle="`menu_${itemIndex}_${subIndex}`" variant="link" class="rotate-arrow-icon opacity-50">
              <i class="simple-icon-arrow-down"></i>
              <span class="d-inline-block">{{$t(sub.label)}}</span>
            </b-link>
            <b-collapse visible :id="`menu_${itemIndex}_${subIndex}`">
              <ul class="list-unstyled third-level-menu">
                <li v-for="(thirdLevelSub, thirdIndex) in sub.subs" :key="`third_${itemIndex}_${subIndex}_${thirdIndex}`" :class="{'third-level-menu':true , 'active' : $route.path ===thirdLevelSub.to}">
                  <a v-if="thirdLevelSub.newWindow" :href="thirdLevelSub.to" rel="noopener noreferrer" target="_blank">
                    <i :class="thirdLevelSub.icon" />
                    <span>{{ $t(thirdLevelSub.label) }}</span>
                  </a>
                  <router-link v-else :to="thirdLevelSub.to">
                    <i :class="thirdLevelSub.icon" />
                    <span>{{ $t(thirdLevelSub.label) }}</span>
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </template>

           <div v-else>
              <div v-if="menuStatus[sub.id] !== undefined && !menuStatus[sub.id].isActive">
                <router-link to="#">
                  <i :class="sub.icon" />
                  <span class="text-muted" :id="`menu-${sub.id}`">{{ $t(sub.label) }}</span>
                  <!-- TODO : [BootstrapVue warn]: tooltip - Unable to find target element in document. -->
                  <b-tooltip
                    :target="`menu-${sub.id}`"
                    placement="bottom"
                    :title="menuStatus[sub.id].errorMessage">
                  </b-tooltip>
                </router-link>
              </div>
              <div v-else>
                <router-link :to="sub.to">
                  <i :class="sub.icon" />
                  <span>{{ $t(sub.label) }}</span>
                </router-link>
              </div>
            </div>
        </li>
      </ul>
    </vue-perfect-scrollbar>
  </div>
</div>
</template>

<script>

import {
  menuHiddenBreakpoint,
  subHiddenBreakpoint
} from "@/constants/config";

import menuItems from "@/constants/menu";
import LocalStorageService from "@/utils/local_store.js";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      selectedParentMenu: "",
      viewingParentMenu: "",
      menuItems,
      menuStatus: {   // {Object} status for the menu to enable/disable: {isActive, errorMessage}
        'setup.business-location': {
          isActive: false,
          errorMessage: "Set Business Type and Profile first",
        },
        'products.products': {
          isActive: false,
          errorMessage: "Set Business Location first in the Setup menu",
        },
        'products.product-groups': {
          isActive: false,
          errorMessage: "Set Business Location first",
        },
        'products.addons': {
          isActive: false,
          errorMessage: "Set Add-on Groups first",
        },
        'products.addon-groups': {
          isActive: false,
          errorMessage: "Set Business Location first",
        },
        'promotions.promotions-main': {
          isActive: false,
          errorMessage: "Set Products first",
        },
        'promotions.new-perk': {
          isActive: false,
          errorMessage: "Set Products first",
        },
        'promotions.rewards': {
          isActive: false,
          errorMessage: "Set Products first",
        },
        'report.order-history': {
          isActive: false,
          errorMessage: "Set Business Location first",
        },
        'support.default': {
          isActive: true,
          errorMessage: "It is not available for now.",
        },
      }
    };
  },
  mounted() {
    this.selectMenu();
    window.addEventListener("resize", this.handleWindowResize);
    document.addEventListener("click", this.handleDocumentClick);
    this.handleWindowResize();

       // Initialize the module.
    this.init();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleDocumentClick);
    window.removeEventListener("resize", this.handleWindowResize);
  },
  methods: {
    // Map mutations.
    ...mapMutations([
      "changeSideMenuStatus",
      "addMenuClassname",
      "changeSelectedMenuHasSubItems"
    ]),

    /**
     * Initialize the components.
     */
    init() {
      // TODO: update to more compact conditions.
      this.menuStatus['setup.business-location'].isActive = this.isMerchantProfileSet;

      this.menuStatus['products.products'].isActive = this.isMerchantStoresSet;
      this.menuStatus['products.product-groups'].isActive = this.isMerchantStoresSet;
      this.menuStatus['products.addon-groups'].isActive = this.isMerchantStoresSet;
      this.menuStatus['report.order-history'].isActive = this.isMerchantStoresSet;

      this.menuStatus['products.addons'].isActive = this.isAddonGroupsSet;

      this.menuStatus['promotions.promotions-main'].isActive = this.isProductSet;
      this.menuStatus['promotions.new-perk'].isActive = this.isProductSet;
      this.menuStatus['promotions.rewards'].isActive = this.isProductSet;

    },
    selectMenu() {
      const currentParentUrl = this.$route.path
        .split("/")
        .filter(x => x !== "")[1];
      if (currentParentUrl !== undefined || currentParentUrl !== null) {
        this.selectedParentMenu = currentParentUrl.toLowerCase();
      } else {
        this.selectedParentMenu = "dashboards";
      }
      this.isCurrentMenuHasSubItem();
    },
    isCurrentMenuHasSubItem() {
      const menuItem = this.menuItems.find(
        x => x.id === this.selectedParentMenu
      );
      const isCurrentMenuHasSubItem =
        menuItem && menuItem.subs && menuItem.subs.length > 0 ? true : false;
      if (isCurrentMenuHasSubItem != this.selectedMenuHasSubItems) {
        if (!isCurrentMenuHasSubItem) {
          this.changeSideMenuStatus({
            step: 0,
            classNames: this.menuType,
            selectedMenuHasSubItems: false
          });
        }else{
          this.changeSideMenuStatus({
            step: 0,
            classNames: this.menuType,
            selectedMenuHasSubItems: true
          });
        }
      }
      return isCurrentMenuHasSubItem;
    },
    changeSelectedParentHasNoSubmenu(parentMenu) {
      this.selectedParentMenu = parentMenu;
      this.viewingParentMenu = parentMenu;
      this.changeSelectedMenuHasSubItems(false);
      this.changeSideMenuStatus({
        step: 0,
        classNames: this.menuType,
        selectedMenuHasSubItems: false
      });
    },
    openSubMenu(e, menuItem) {
      const selectedParent = menuItem.id;
      const hasSubMenu = menuItem.subs && menuItem.subs.length > 0;
      this.changeSelectedMenuHasSubItems(hasSubMenu);
      if (!hasSubMenu) {
        this.viewingParentMenu = selectedParent;
        this.selectedParentMenu = selectedParent;
        this.toggle();
      } else {
        const currentClasses = this.menuType ?
          this.menuType.split(" ").filter(x => x !== "") :
          "";
        if (!currentClasses.includes("menu-mobile")) {
          if (
            currentClasses.includes("menu-sub-hidden") &&
            (this.menuClickCount === 2 || this.menuClickCount === 0)
          ) {
            this.changeSideMenuStatus({
              step: 3,
              classNames: this.menuType,
              selectedMenuHasSubItems: hasSubMenu
            });
          } else if (
            currentClasses.includes("menu-hidden") &&
            (this.menuClickCount === 1 || this.menuClickCount === 3)
          ) {
            this.changeSideMenuStatus({
              step: 2,
              classNames: this.menuType,
              selectedMenuHasSubItems: hasSubMenu
            });
          } else if (
            currentClasses.includes("menu-default") &&
            !currentClasses.includes("menu-sub-hidden") &&
            (this.menuClickCount === 1 || this.menuClickCount === 3)
          ) {
            this.changeSideMenuStatus({
              step: 0,
              classNames: this.menuType,
              selectedMenuHasSubItems: hasSubMenu
            });
          }
        } else {
          this.addMenuClassname({
            classname: "sub-show-temporary",
            currentClasses: this.menuType
          });
        }
        this.viewingParentMenu = selectedParent;
      }
    },
    handleDocumentClick(e) {
      this.viewingParentMenu = "";
      this.selectMenu();
      this.toggle();
    },
    toggle() {
      const currentClasses = this.menuType.split(" ").filter(x => x !== "");
      if (
        currentClasses.includes("menu-sub-hidden") &&
        this.menuClickCount === 3
      ) {
        this.changeSideMenuStatus({
          step: 2,
          classNames: this.menuType,
          selectedMenuHasSubItems: this.selectedMenuHasSubItems
        });
      } else if (
        currentClasses.includes("menu-hidden") ||
        currentClasses.includes("menu-mobile")
      ) {
        if (!(this.menuClickCount === 1 && !this.selectedMenuHasSubItems)) {
          this.changeSideMenuStatus({
            step: 0,
            classNames: this.menuType,
            selectedMenuHasSubItems: this.selectedMenuHasSubItems
          });
        }
      }
    },
    // Resize
    handleWindowResize(event) {
      if (event && !event.isTrusted) {
        return;
      }
      let nextClasses = this.getMenuClassesForResize(this.menuType);
      this.changeSideMenuStatus({
        step: 0,
        classNames: nextClasses.join(" "),
        selectedMenuHasSubItems: this.selectedMenuHasSubItems
      });
    },
    getMenuClassesForResize(classes) {
      let nextClasses = classes.split(" ").filter(x => x !== "");
      const windowWidth = window.innerWidth;
      if (windowWidth < menuHiddenBreakpoint) {
        nextClasses.push("menu-mobile");
      } else if (windowWidth < subHiddenBreakpoint) {
        nextClasses = nextClasses.filter(x => x !== "menu-mobile");
        if (
          nextClasses.includes("menu-default") &&
          !nextClasses.includes("menu-sub-hidden")
        ) {
          nextClasses.push("menu-sub-hidden");
        }
      } else {
        nextClasses = nextClasses.filter(x => x !== "menu-mobile");
        if (
          nextClasses.includes("menu-default") &&
          nextClasses.includes("menu-sub-hidden")
        ) {
          nextClasses = nextClasses.filter(x => x !== "menu-sub-hidden");
        }
      }
      return nextClasses;
    }
  },
  computed: {
    // Map getters.
    ...mapGetters({
      menuType: "getMenuType",
      menuClickCount: "getMenuClickCount",
      selectedMenuHasSubItems: "getSelectedMenuHasSubItems"
    }),
    ...mapGetters('addon_group',      ["isAddonGroupsSet"]),
    ...mapGetters('merchant_profile', ["isMerchantProfileSet"]),
    ...mapGetters('merchant_store',   ["isMerchantStoresSet"]),
    ...mapGetters('product'   ,       ["isProductSet"]),
    ...mapGetters("payments",         ["isStripeConnected"]),
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        const toParentUrl = to.path.split("/").filter(x => x !== "")[1];
        if (toParentUrl !== undefined || toParentUrl !== null) {
          this.selectedParentMenu = toParentUrl.toLowerCase();
        } else {
          this.selectedParentMenu = "dashboards";
        }
        this.selectMenu();
        this.toggle();
        this.changeSideMenuStatus({
          step: 0,
          classNames: this.menuType,
          selectedMenuHasSubItems: this.selectedMenuHasSubItems
        });
        window.scrollTo(0, top);
      }
    },
    // TODO: update to more compact conditions.
    /**
     * Watch for isMerchantProfileSet to enable/disable the menu.
     */
    isMerchantProfileSet() {
      this.menuStatus['setup.business-location'].isActive = this.isMerchantProfileSet;
    },
    /**
     * Watch for isMerchantStoresSet to enable/disable the menu.
     */
    isMerchantStoresSet() {
      this.menuStatus['products.product-groups'].isActive = this.isMerchantStoresSet;
      this.menuStatus['products.addon-groups'].isActive = this.isMerchantStoresSet;
      this.menuStatus['report.order-history'].isActive = this.isMerchantStoresSet;
      this.menuStatus['products.products'].isActive = this.isMerchantStoresSet;

    },
    /**
     * Watch for isAddonGroupsSet to enable/disable the menu.
     */
    isAddonGroupsSet() {
      this.menuStatus['products.addons'].isActive = this.isAddonGroupsSet;
    },
    /**
     * Watch for isProductset to enable/disable the menu.
     */
    isProductSet() {
      this.menuStatus['promotions.promotions-main'].isActive = this.isProductSet;
      this.menuStatus['promotions.new-perk'].isActive =  this.isProductSet;
      this.menuStatus['promotions.rewards'].isActive =  this.isProductSet  ;
    },
  }
};
</script>
