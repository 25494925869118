/**
 * It contains backend CRUD Apis for User Profile.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// Modules in the application.
import axios from "@/api/axios/axios_core_deal";

// URLs for Backend
const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;
const USER_PROFILE_URL = `${HOST}/user/profile`;
const USER_PROFILE_AVATAR_URL = `${HOST}/user/profile/avatar`;



/**
 * Get User Profile
 * @return {Promise} Result of get for UserProfile
 */
export function getUserProfile() {
  return axios.get(USER_PROFILE_URL,
    {
      headers:{
        'Access-Control-Allow-Origin':'*',
      }
    });
}

/**
 * Uploading AvatarImage
 * @param { File[]} file file of UserProfile
 * @return {Promise} Result of Post request for UserProfileAvatar
 */
export function uploadProfileAvatar(file) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(
    USER_PROFILE_AVATAR_URL,
    formData,
    {
      headers:{
        'Access-Control-Allow-Origin':'*',
      }
    }
  );
}

/**
 * Updating dateofBirth
 * @param { String} dob dob of UserProfile
 * @return {Promise} Result of Post request for UpdateDob
 */
export function updateDob(dob) {
  return axios.post(
    USER_PROFILE_URL,
    { "dob": dob },
    {
      headers:{
        'Access-Control-Allow-Origin':'*',
      }
    }
  );
}

