var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-badge",
    { staticClass: "mb-1 ml-2", attrs: { variant: _vm.variant } },
    [
      _vm._v("\n  " + _vm._s(_vm.data) + "\n  "),
      _c(
        "a",
        {
          attrs: {
            "data-cy": `${_vm.cyPrefix}-delete`,
            href: "javascript:void(0);",
          },
          on: { click: _vm.onDelete },
        },
        [_vm._v("x")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }