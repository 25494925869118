var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalProduct",
      attrs: { id: "modalProduct", size: "lg" },
      on: {
        hide: function ($event) {
          return _vm.onCencel()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c("HeaderDialogProduct", {
                    attrs: {
                      title: "objects.product",
                      name: "dlg-product",
                      status: _vm.data.status,
                      disabled: _vm.data.showOnly,
                      actionType: _vm.actionType,
                    },
                    on: {
                      clickSchedule: _vm.openDialogSchedule,
                      changeStatus: _vm.updateStatus,
                    },
                  }),
                  _vm._v(" "),
                  _vm.isOnProductPage === false
                    ? _c("div", [
                        _vm.isProductDeleted
                          ? _c("p", { staticClass: "text-warning" }, [
                              _c("u", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$t("general.note")) +
                                      ": " +
                                      _vm._s(_vm.$t("product.product-deleted"))
                                  ),
                                ]),
                              ]),
                            ])
                          : _c("p", { staticClass: "text-muted" }, [
                              _c("u", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("general.note")) + ": "),
                                ]),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("product.current-information-note")
                                    ) +
                                    "\n          "
                                ),
                              ]),
                            ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-form",
        [
          _c("dialog-alert", {
            attrs: {
              dialog: _vm.dialogs.alert,
              description: _vm.$t("alert.no-change"),
              actionType: _vm.actionType,
              buttonSubmitText: _vm.$t("buttons.ok"),
            },
            on: {
              "dialog-close": _vm.closeDialogAlert,
              "dialog-submit": _vm.onSubmitAlertDialog,
            },
          }),
          _vm._v(" "),
          _c("dialog-date-time", {
            attrs: {
              disabled: _vm.data.showOnly,
              data: _vm.data,
              dialog: _vm.dialogs.schedule,
            },
            on: {
              "dialog-close": _vm.closeDialogSchedule,
              "dialog-submit": _vm.onSubmitDialogSchedule,
            },
          }),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-colxx",
                { attrs: { sm: "6" } },
                [
                  _c("EditNameDescription", {
                    attrs: {
                      name: "dlg-product",
                      data: _vm.data,
                      label: "product",
                      isFirstLoad: _vm.isFirstLoad,
                      disabled: _vm.data.showOnly,
                    },
                  }),
                  _vm._v(" "),
                  _c("EditPrice", {
                    attrs: {
                      name: "dlg-product",
                      colSize: _vm.sizeColMain,
                      data: _vm.data,
                      disabled: _vm.data.showOnly,
                      isFirstLoad: _vm.isFirstLoad,
                    },
                  }),
                  _vm._v(" "),
                  _c("select-product-groups", {
                    ref: "selProductGroups",
                    attrs: {
                      name: "dlg-product",
                      disabled: _vm.data.showOnly,
                      groups: _vm.data.groups,
                      isRequired: false,
                      isFirstLoad: _vm.isFirstLoad,
                    },
                    on: {
                      isValid: (ele) => {
                        _vm.isValidProductGroups = ele
                      },
                    },
                    model: {
                      value: _vm.isChanged,
                      callback: function ($$v) {
                        _vm.isChanged = $$v
                      },
                      expression: "isChanged",
                    },
                  }),
                  _vm._v(" "),
                  _c("select-addon-groups", {
                    ref: "selAddonGroups",
                    attrs: {
                      name: "dlg-product",
                      disabled: _vm.data.showOnly,
                      groups: _vm.data.addonGroups,
                      isRequired: false,
                      isFirstLoad: _vm.isFirstLoad,
                    },
                    on: {
                      isValid: (ele) => {
                        _vm.isValidAddonGroups = ele
                      },
                    },
                    model: {
                      value: _vm.isChanged,
                      callback: function ($$v) {
                        _vm.isChanged = $$v
                      },
                      expression: "isChanged",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    [
                      !_vm.data.showOnly
                        ? _c("h6", [
                            _vm._v(_vm._s(_vm.$t("product.choose-unit"))),
                          ])
                        : _c("h6", [_vm._v(_vm._s(_vm.$t("product.unit")))]),
                      _vm._v(" "),
                      !_vm.data.showOnly
                        ? _c(
                            "b-row",
                            {
                              attrs: { "align-v": "center", "no-gutters": "" },
                            },
                            [
                              _c(
                                "b-colxx",
                                { attrs: { sm: _vm.sizeColMain } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      "data-cy": "dlg-product-select-unit",
                                      options: _vm.currentSelUnitOptions,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.updateUnit()
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "search",
                                          fn: function ({
                                            attributes,
                                            events,
                                          }) {
                                            return [
                                              _c(
                                                "input",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "vs__search",
                                                      attrs: {
                                                        "data-cy": `dlg-product-select-unit-input`,
                                                      },
                                                    },
                                                    "input",
                                                    attributes,
                                                    false
                                                  ),
                                                  events
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      642739972
                                    ),
                                    model: {
                                      value: _vm.newSelItem.unit,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.newSelItem, "unit", $$v)
                                      },
                                      expression: "newSelItem.unit",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { attrs: { "align-v": "center", "no-gutters": "" } },
                        [
                          _c(
                            "b-colxx",
                            { attrs: { sm: _vm.sizeColMain } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  "data-cy": "dlg-product-input-unit",
                                  placeholder: _vm.$t("forms.unit"),
                                  state:
                                    !_vm.$v.data.unit.$invalid ||
                                    _vm.isFirstLoad,
                                  disabled: _vm.data.showOnly,
                                },
                                model: {
                                  value: _vm.data.unit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "unit", $$v)
                                  },
                                  expression: "data.unit",
                                },
                              }),
                              _vm._v(" "),
                              _c("b-form-invalid-feedback", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("validations.no-value")) +
                                    "\n              "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-colxx",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "b-form-group",
                    [
                      !_vm.data.showOnly
                        ? _c("h6", [
                            _vm._v(_vm._s(_vm.$t("product.choose-image"))),
                          ])
                        : _c("h6", [_vm._v(_vm._s(_vm.$t("product.images")))]),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { attrs: { "align-v": "center", "no-gutters": "" } },
                        [
                          _c(
                            "b-colxx",
                            { attrs: { sm: _vm.sizeColMain } },
                            [
                              _c("ImagesSelection", {
                                key: "selImage",
                                ref: "selImage",
                                attrs: {
                                  name: "dlg-product",
                                  images: _vm.data.images,
                                  single: false,
                                  disabled: _vm.data.showOnly,
                                },
                                model: {
                                  value: _vm.isChanged,
                                  callback: function ($$v) {
                                    _vm.isChanged = $$v
                                  },
                                  expression: "isChanged",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("select-categories", {
                    ref: "selCategory",
                    attrs: {
                      name: "dlg-product",
                      disabled: _vm.data.showOnly,
                      categories: _vm.data.categories,
                    },
                    model: {
                      value: _vm.isChanged,
                      callback: function ($$v) {
                        _vm.isChanged = $$v
                      },
                      expression: "isChanged",
                    },
                  }),
                  _vm._v(" "),
                  _c("select-attributes", {
                    ref: "selAttrubute",
                    attrs: {
                      name: "dlg-product",
                      disabled: _vm.data.showOnly,
                      attributes: _vm.data.attributes,
                    },
                    model: {
                      value: _vm.isChanged,
                      callback: function ($$v) {
                        _vm.isChanged = $$v
                      },
                      expression: "isChanged",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-colxx",
                [
                  _c("CheckSelectStores", {
                    ref: "selStores",
                    attrs: {
                      name: "dlg-product",
                      storeIds: _vm.data.stores,
                      productTaxConfigs: _vm.data.productTaxConfigs,
                      disabled: _vm.data.showOnly,
                      isFirstLoad: _vm.isFirstLoad,
                      allowTaxConfigDialog: true,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c("FooterDialogProduct", {
            attrs: {
              name: "dlg-product",
              disabled:
                (_vm.$v.$invalid && !_vm.isFirstLoad) || _vm.isProductDeleted,
              actionType: _vm.actionType,
            },
            on: { "click-cancel": _vm.onCencel, "click-submit": _vm.onSubmit },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }