// Componet for issue type selection.
// Author : Huen Oh (heons921@gmail.com)
// @param {string}    name        Name of the component. Index in usual. (in)
// @param {bool}      disabled    Disabled property of the component. (in)
// @param {number}    colSize     Size of the column for the form. : 1 ~ 12. (in)

<template>
<div>  
  <!-- Issue type Selection -->
  <b-form-group>
    <!-- <h6>{{$t('product.choose-product-category')}}</h6> -->
    <b-row align-v="center" no-gutters>
      <b-colxx :sm="colSize">
        <v-select 
          :data-cy="`${name}-select-one-issue`"
          :options="currentSelOptions" 
          v-model="selectedIssue" 
          @input="onClick"
          v-if="!disabled"
        >
          <template #search="{ attributes, events }">
            <input
              :data-cy="`${name}-select-one-issue-input`"
              class="vs__search"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
      </b-colxx>
    </b-row>
    <!-- ProductGroups : Validation feedkback -->
      <b-form-invalid-feedback :state="(selectedIssue || isFirstLoad)? true: false">
        {{ $t('validations.no-selection-validation')}}
      </b-form-invalid-feedback>
  </b-form-group>

</div>
</template>


<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  props:{
    // {string} Name of the component. Index in usual.
    name: {
      type: String,
      default: ""
    },
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {string} Size of the column for the form. : 1 ~ 12.
    colSize: {
      type: Number,
      default: 10,
    },
    // {Boolean} Is First Load, in order not to display validation message at first
    isFirstLoad: {
      type: Boolean,
      default: true
    }
  },
  components: {
    'v-select': vSelect,
  },
  data () {
    return {
      currentSelOptions: [
        {
          label:this.$t('support.issue-type.orders-urgent'),
          value:"Orders (Urgent)",
        },
        {
          label:this.$t('support.issue-type.orders'),
          value:"Orders",
        },
        {
          label: this.$t('support.issue-type.account'),
          value:"Account",
        },
        {
          label:this.$t('support.issue-type.promotions'),
          value:"Promotions",
        },
        {
          label:this.$t('support.issue-type.products'),
          value:"Products",
        },
        {
          label:this.$t('support.issue-type.redeem-global-points'),
          value:"Redeem global points",
        },
        {
          label:this.$t('support.issue-type.payment-method-connection'),
          value:"Payment method connection",
        },
        {
          label:this.$t('support.issue-type.business-inquiries'),
          value:"Business Inquiries",
        },
        {
          label:this.$t('support.issue-type.other'),
          value:"Other",
        },
      ],

      selectedIssue: null,
    }
  },
  computed:{
  },
  mounted () {
    this.resetSelection();
  },
  methods:{
    resetSelection() {
      this.selectedIssue = {
        label:this.$t('support.issue-type.other'),
        value:"Other",
      };
      this.$emit('click', this.selectedIssue);
    },
    /**
     * On click the button.
     */
    onClick(filter) {
      this.$emit('click', filter);
    }
  }

}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>

