var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      attrs: {
        "data-cy": `${_vm.name}-btn-duplicate`,
        href: "javascript:void(0);",
      },
    },
    [
      _c("b-img", {
        staticClass: "no-checkbox cls-img-btn ml-2",
        attrs: {
          id: "duplicate-" + _vm.id,
          src: "/assets/icons/duplicate_use.svg",
        },
        on: { click: _vm.onClick },
      }),
      _vm._v(" "),
      _c("b-tooltip", {
        attrs: {
          target: "duplicate-" + _vm.id,
          placement: "bottom",
          title: _vm.$t("tool-tips.duplicate"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }