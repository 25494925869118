/*
 * HighlightInProductList component - To choose when to highlight a promotion in user cart based on selected products in promotion
 * Author: Sukhpreet Singh
 * @param highlightInProductList {Number[]} which items must be in cart in order to highlight the promotion
 * @param productList            {Object[]} list of required products in promotion
 * @param perkType               {String}   type of the promotion
 * @param show                   {Boolean}  false - to make the component editable, otherwise true              
 */

<template>
  <div>
    <h6>{{ `${$t("promotions.highlight-title")}` }}</h6>

    <b-form-group>
      <b-form-radio
        v-if="(show === true && isUseHighlight === false) || show === false"
        data-cy="promotion-highlight-radio-false"
        v-model="isUseHighlight"
        :value="false"
        class="mb-2"
      >
        {{ $t("promotions.highlight-no-highlight") }}
      </b-form-radio>
      <b-form-radio
        v-if="(show === true && isUseHighlight === true) || show === false"
        data-cy="promotion-highlight-radio-true"
        v-model="isUseHighlight"
        :value="true"
        class="mb-2"
      >
        {{ $t("promotions.highlight-custom-selection.title") }}
      </b-form-radio>
      <div v-if="isUseHighlight">
        <div v-if="perkType === TYPE_DEAL.DEAL_BUY_ONE_GET_ONE">
          <b-form-radio
            v-if="(show === true && highlightItem === 0) || show === false"
            data-cy="promotion-highlight-radio-required"
            class="ml-4 mb-2"
            v-model="highlightItem"
            :value="0"
          >
            {{ $t("promotions.highlight-custom-selection.required") }}
          </b-form-radio>
          <b-form-radio
            v-if="(show === true && highlightItem === 1) || show === false"
            data-cy="promotion-highlight-radio-reward"
            class="ml-4 mb-2"
            v-model="highlightItem"
            :value="1"
          >
            {{ $t("promotions.highlight-custom-selection.reward") }}
          </b-form-radio>

          <b-form-radio
            v-if="(show === true && highlightItem === 2) || show === false"
            data-cy="promotion-highlight-radio-required-reward"
            class="ml-4 mb-2"
            v-model="highlightItem"
            :value="2"
          >
            {{ $t("promotions.highlight-custom-selection.required-reward") }}
          </b-form-radio>
        </div>
        <div v-else>
          <b-form-group v-if="show === false">
            <b-form-radio
              :data-cy="`promotion-highlight-radio-true-${index}`"
              v-for="(groupIndex, index) in groupIndexList"
              :key="index"
              class="ml-4 mb-2"
              v-model="highlightItem"
              :value="groupIndex"
            >
              {{
                $t(
                  "promotions.highlight-custom-selection.buy-one-get-one-children"
                )
              }}{{ groupIndex + 1 }}
            </b-form-radio>
          </b-form-group>
          <b-form-group v-else>
            <b-form-radio
              :data-cy="`promotion-highlight-radio-true-${highlightItem}`"
              class="ml-4 mb-2"
              v-model="highlightItem"
              :value="highlightItem"
            >
              {{
                $t(
                  "promotions.highlight-custom-selection.buy-one-get-one-children"
                )
              }}{{ highlightItem + 1 }}
            </b-form-radio>
          </b-form-group>
        </div>
      </div>
      <p class="text-muted text-small">
        {{ $t("promotions.highlight-custom-selection.description") }}
      </p>
    </b-form-group>
  </div>
</template>

<script>
import { TYPE_DEAL }  from '@/models/promotion'

export default {
	props: {
    // which items must be in cart in order to highlight the promotion
    highlightInProductList: {
      type: Array,
      default: null,
    },
    // list of required products in promotion
    productList: {
      type: Array,
      default: () => [],
    },
    // type of the promotion
    perkType: {
      type: String,
      default: "",
    },
    // false - to make the component editable, otherwise true
    show: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return { 
      isUseHighlight: Array.isArray(this.highlightInProductList) ? true : false,
      highlightItem: Array.isArray(this.highlightInProductList) ? this.highlightInProductList[0] : 0,

      TYPE_DEAL,
    }
  },

  watch: {
    highlightInProductList: function (val) {
      // to update local state values based on prop
      
      this.isUseHighlight = Array.isArray(val) ? true : false;
  
      this.highlightItem = Array.isArray(val) ? val[0] : 0;
    }
  },

  computed: {
    groupIndexList() {
      // Get groupIndexList.
      // _.compact removes 0, null, undefined
      let maxGroupNum = _.max(
        _.compact(this.productList.map(product => product.groupIndex))
      );
      maxGroupNum = maxGroupNum ? maxGroupNum : 0;

      return _.range(maxGroupNum+1);
      
    }
  },

  methods: {
    // To retrieve final value of highlightInProductList field
    getHighlightInProductList() {
      return this.isUseHighlight ? [this.highlightItem] : null;
    }
  }

}
</script>