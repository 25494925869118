/**
 * It contains Prototype of PromotionDetail Object.
 */

/**
 * PromotionDetail Model - It is used in Orders.
 */
const MODEL_PROMOTION_DETAIL = {
  id: "",                     // {string}   Id of the Promotion.
  type: "",                   // {string}   Types of the Promotion: PUNCH_CARD, DEAL, REWARD_ITEM, POINT_PERK. required.
  name: "",                   // {string}   Name of the Promotion, required.
  appliedProductList: [],     // {Object[]} Reference object to applied Products, required, refer to MODEL_PRODUCT_IN_LIST.
  rewardPunches: null,        // {[number]} Reward Punches.
  rewardPoints: null,         // {[number]} Reward Points.
  discountPercent: null,      // {[number]} Discount percent.
  discountAmount: null,       // {[number]} Discount amount, fixed.
  isAddonCharged: false,      // {bool}     Option to charge Addon, (true, false), required.
  isNewClient: null,          // {[bool]}   Options to limit client, (null:any(default), true:new, false:returning).
  addonDiscountAmount: null,  // {[number]} Addon discount amount.
  totalDiscountAmount: null,  // {[number]} Total discount amount.
  isMaster: null,             // {[bool]}   Option to be master, (null:no exclusive(default), true:master, false:exclusive).
  couponCode: null,           // {[string]} Coupon code. If it is not null, it is revealed Promotion by User's coupon input.
  minimumAmount: null,        // {number}   Minimun cart amount required to apply this promotion.
}


import { formatPrice, isNullOrUndefined } from "@/utils/general";
import { TYPE_PROMOTION } from "@/models/promotion";


/**
 * Get promotion detail information to display in ListOrderPromotion.vue.
 * @param {MODEL_PROMOTION_DETAIL} orderPromotions  To create list of PromotionDetails
 * @param {MODEL_ORDER_ITEM} orderProducts          To populate product name for all applied products in promotion detail.
 * @returns List of Object with promotion detail information prepared for ListOrderPromotion component.
 */
export function getPerkList(orderPromotions, orderProducts) {

  if (isNullOrUndefined(orderPromotions) || !Array.isArray(orderPromotions)) {
    return [];
  }

  // Add the list of Perks.
  return orderPromotions
    .map(promotionDetail => {

      let tmpPromotion = new PromotionDetail(promotionDetail);
      // Add product name from order product list.
      tmpPromotion.addProductNameFromOrderProduct(orderProducts);
      tmpPromotion.setIsAddonsPresentToDiscount(orderProducts);

      return tmpPromotion.getPromotionInfo();
    })
    .filter(promotionDetail => !isNullOrUndefined(promotionDetail));
}


/**
 * Constructor of PromotionDetail Object.
 * @param {Object} promotionDetail PromotionDetail object: Refer to MODEL_PROMOTION_DETAIL.
 */
export function PromotionDetail(promotionDetail) {
  this.id = promotionDetail.id;
  this.type = promotionDetail.type;
  this.name = promotionDetail.name;

  if (Array.isArray(promotionDetail.appliedProductList)
    && promotionDetail.appliedProductList.length > 0) {
    this.appliedProductList = JSON.parse(JSON.stringify(promotionDetail.appliedProductList));
  } else {
    this.appliedProductList = [];
  }

  this.rewardPunches = promotionDetail.rewardPunches;
  this.rewardPoints = promotionDetail.rewardPoints;
  this.discountPercent = promotionDetail.discountPercent;
  this.discountAmount = promotionDetail.discountAmount;
  this.isAddonCharged = promotionDetail.isAddonCharged; 
  this.isNewClient = promotionDetail.isNewClient;
  this.addonDiscountAmount = promotionDetail.addonDiscountAmount;
  this.totalDiscountAmount = promotionDetail.totalDiscountAmount;
  this.isMaster = promotionDetail.isMaster;
  this.couponCode = promotionDetail.couponCode;
  this.requiredAmount = promotionDetail.requiredAmount;
  this.rewardItems = promotionDetail.rewardItems; 
  this.requiredPoints = promotionDetail.requiredPoints; 
  this.requiredPunches = promotionDetail.requiredPunches; 

  // To display text if no add-ons were present in the order to discount.
  this.isAddonsPresentToDiscount = true;
};

/**
 * Get the list of perks from the PromotionDetail.
 */
PromotionDetail.prototype.getPromotionInfo = function () {

  /*
  Return object will be:
  {
    id,
    type,
    title,
    isRedeem,
    reward/usedPoints/usedPunches,  // Conditional: based on Promotion type.
    rewardPoints,                   // Optional: only applicable for Redeem PunchCard Promotion.
    appliedProductList,
    itemDiscountAmount,             // Optional: only applicable for Deals and redeem promotions
    addonDiscountAmount,            // Optional: only applicable for Deals and redeem promotions
    isAddonCharged,
    isAddonsPresentToDiscount
    conditions: {
      isMaster,
      isNewClient,
      minimumRequiredPoints,        // Only applicable for RewardItem
      minimumRequiredPunches        // Only applicable for Redeem PunchCard
      requiredAmount                // Only appliable for Deals
    }
  }
  */

  // Common information
  const promotionDetailInfo = {
    id: this.id,
    type: this.type,
    title: (this.type === TYPE_PROMOTION.PUNCH_CARD_REDEEM || this.type === TYPE_PROMOTION.REWARD_ITEM) 
                ? `${this.name} (Redeem)` : this.name ,
    appliedProductList: this.appliedProductList,
    isAddonCharged: this.isAddonCharged,
    isAddonsPresentToDiscount: this.isAddonsPresentToDiscount,
    conditions: {},
    description: []
  };

  // Each case sets all the available information important to specific PromotionType. 
  switch (this.type) {
    case TYPE_PROMOTION.POINT_PERK:
      
      // Reward
      if (!isNullOrUndefined(this.rewardPoints)) {
        promotionDetailInfo.reward = this.rewardPoints;
      }

      // Discount
      promotionDetailInfo.isDiscountNotApplicable = true;

      // Conditions
      promotionDetailInfo.conditions.isMaster = (this.isMaster === true || this.isMaster === false) ? this.isMaster : null;
      promotionDetailInfo.conditions.isNewClient = (this.isNewClient === true || this.isNewClient === false) ? this.isNewClient : null;
      
      return promotionDetailInfo;

    case TYPE_PROMOTION.PUNCH_CARD:
      if (!isNullOrUndefined(this.rewardPunches)) {           // Normal PunchCard
        // Reward
        promotionDetailInfo.reward = this.rewardPunches;
        promotionDetailInfo.rewardPoints = this.rewardPoints;

        // Discount
        promotionDetailInfo.isDiscountNotApplicable = true;

        return promotionDetailInfo;

      } else {
        // Fall back case (if cannot determine which type of PunchCard it is.), Return: { totalDiscountAmount, addonDiscoutnAmount }

        // Applied discount
        promotionDetailInfo.totalDiscountAmount = this.totalDiscountAmount;
        promotionDetailInfo.addonDiscountAmount = this.addonDiscountAmount;
        
        return promotionDetailInfo;
      }

    case TYPE_PROMOTION.REWARD_ITEM:
      
      // Used points information
      promotionDetailInfo.isRedeem = true;
      if (!isNullOrUndefined(this.requiredPoints)) {
        promotionDetailInfo.usedPoints = this.calculateUsedPoints();
      }

      // Applied discount
      promotionDetailInfo.totalDiscountAmount = this.totalDiscountAmount;
      promotionDetailInfo.addonDiscountAmount = this.addonDiscountAmount;

      // Conditions
      if (!isNullOrUndefined(this.requiredPoints)) {
        promotionDetailInfo.conditions.minimumRequiredPoints = this.requiredPoints; 
      }

      return promotionDetailInfo;

    case TYPE_PROMOTION.PUNCH_CARD_REDEEM:

      if (!isNullOrUndefined(this.requiredPunches)) {  // Redeem PunchCard

        // Used Punches information
        promotionDetailInfo.isRedeem = true;
        promotionDetailInfo.usedPunches = this.calculateUsedPunches();

        // Applied discount
        promotionDetailInfo.totalDiscountAmount = this.totalDiscountAmount;
        promotionDetailInfo.addonDiscountAmount = this.addonDiscountAmount;

        // Conditions
        if (!isNullOrUndefined(this.rewardItems) && !isNullOrUndefined(this.requiredPunches)) {
          promotionDetailInfo.conditions.minimumRequiredPunches = this.requiredPunches;
        }

        return promotionDetailInfo;

      }
      
    case TYPE_PROMOTION.DEAL:

      // Reward
      if (!isNullOrUndefined(this.totalDiscountAmount)) {
        promotionDetailInfo.reward = formatPrice(parseFloat(this.totalDiscountAmount ? this.totalDiscountAmount : 0) + 
                                                  parseFloat(this.addonDiscountAmount ? this.addonDiscountAmount : 0))
      }

      // Applied discount
      promotionDetailInfo.totalDiscountAmount = this.totalDiscountAmount;
      promotionDetailInfo.addonDiscountAmount = this.addonDiscountAmount;

      // Conditions
      promotionDetailInfo.conditions.isMaster = (this.isMaster === true || this.isMaster === false) ? this.isMaster : null;
      promotionDetailInfo.conditions.isNewClient = (this.isNewClient === true || this.isNewClient === false) ? this.isNewClient : null;
      if ((this.couponCode) && (this.couponCode.length > 0)) {
        promotionDetailInfo.conditions.couponCode = this.couponCode;
      }
      if (!isNullOrUndefined(this.requiredAmount)) {
        promotionDetailInfo.conditions.requiredAmount = this.requiredAmount;
      }

      return promotionDetailInfo;
  }

};

/**
 * Calculates total number of punches used on the Redeem Punch Card promotion..
 * @returns {Integer} total number of punches used on the promotion..
 */
PromotionDetail.prototype.calculateUsedPunches = function () {
  if (isNullOrUndefined(this.requiredPunches) || 
      isNullOrUndefined(this.rewardItems) ||
      !Array.isArray(this.appliedProductList) ||
      this.appliedProductList.length === 0) {
    return null;
  }

  const numOfItems = this.appliedProductList
    .map(product => product.quantity)
    .reduce((quantity, totalQuantity) => totalQuantity + quantity);

  return (Math.ceil(numOfItems/this.rewardItems) * this.requiredPunches);
}

/**
 * Calcualtes total number of points used on Reward Item promotion.
 * @returns {Integer} total number of points used
 */
PromotionDetail.prototype.calculateUsedPoints = function () {
  if (isNullOrUndefined(this.requiredPoints) || 
      !Array.isArray(this.appliedProductList) ||
      this.appliedProductList.length === 0) {
    return null;
  }

  const numOfItems = this.appliedProductList
    .map(product => product.quantity)
    .reduce((quantity, totalQuantity) => totalQuantity + quantity);

  return numOfItems * this.requiredPoints;
}

/**
 * Updates the appliedProductList by adding product name for each products by finding in the products in order items list.
 * @param {List<OrderProduct>} items Order.items
 */
PromotionDetail.prototype.addProductNameFromOrderProduct = function (items) {
  if (isNullOrUndefined(items) || !Array.isArray(items)) {
    return;
  }

  this.appliedProductList = this.appliedProductList.map(product => {
    // Find the name in items array
    const foundItem = items.filter(item => item.productId === product.id)[0];

    // update product and return
    if (foundItem.productName != null) {
      product.name = foundItem.productName;
      return product;
    } else {
      return product;
    }
    
  });

}

/**
 * Sets the value for isAddonsPresentToDiscount to true if applied product is present with atleast one addon.
 * @param {List<OrderProduct>} items Order.items
 */
PromotionDetail.prototype.setIsAddonsPresentToDiscount = function (items) {
  if (isNullOrUndefined(items) || !Array.isArray(items)) {
    return;
  }

  // List of ids of applied products
  let productIds = [], filteredItems = items;
  productIds = this.appliedProductList.map(product => product.id);

  // If applied product list is empty, means discount applied on cart
  if (this.appliedProductList.length === 0) {
    // Do not filter using PromotionDetail.appliedProductList
  } else {
    // Filter OrderProducts by applied product ids.
    filteredItems = items
      .filter(item => productIds.includes(item.productId));
  }
  
  
  // Filter OrderProducts if there is atleast 1 addon present.
  filteredItems = filteredItems
    .filter(item => Array.isArray(item.addons) && item.addons.length > 0)
    
  if (Array.isArray(filteredItems) && filteredItems.length > 0) {
    this.isAddonsPresentToDiscount = true;
  } else {
    this.isAddonsPresentToDiscount = false;
  }
}

PromotionDetail.prototype.constructor = PromotionDetail;