var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalTaxConfig",
      staticClass: "modal-right",
      attrs: {
        id: "modalTaxConfig",
        title: _vm.$t("product.select-tax-config"),
        size: "lg",
      },
      on: {
        hide: function ($event) {
          return _vm.onCancel()
        },
      },
    },
    [
      _c("b-form", [
        _c(
          "div",
          [
            _c("ListTaxConfigs", {
              attrs: {
                appliedTaxConfigs: _vm.appliedTaxConfigs,
                location: {
                  country: _vm.country,
                  province: _vm.province,
                  city: _vm.city,
                },
              },
              on: { addTax: _vm.addTax, removeTax: _vm.removeTax },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              attrs: {
                "data-cy": "dlg-schedule-btn-cancel",
                variant: "outline-secondary",
              },
              on: {
                click: function ($event) {
                  return _vm.onCancel()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mr-1",
              attrs: {
                "data-cy": "dlg-schedule-btn-submit",
                variant: "primary",
                disabled: _vm.disabled,
              },
              on: {
                click: function ($event) {
                  return _vm.onSubmit()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.submit")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }