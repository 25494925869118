var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h6", [_vm._v(_vm._s(`${_vm.$t("promotions.highlight-title")}`))]),
      _vm._v(" "),
      _c(
        "b-form-group",
        [
          (_vm.show === true && _vm.isUseHighlight === false) ||
          _vm.show === false
            ? _c(
                "b-form-radio",
                {
                  staticClass: "mb-2",
                  attrs: {
                    "data-cy": "promotion-highlight-radio-false",
                    value: false,
                  },
                  model: {
                    value: _vm.isUseHighlight,
                    callback: function ($$v) {
                      _vm.isUseHighlight = $$v
                    },
                    expression: "isUseHighlight",
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("promotions.highlight-no-highlight")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          (_vm.show === true && _vm.isUseHighlight === true) ||
          _vm.show === false
            ? _c(
                "b-form-radio",
                {
                  staticClass: "mb-2",
                  attrs: {
                    "data-cy": "promotion-highlight-radio-true",
                    value: true,
                  },
                  model: {
                    value: _vm.isUseHighlight,
                    callback: function ($$v) {
                      _vm.isUseHighlight = $$v
                    },
                    expression: "isUseHighlight",
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("promotions.highlight-custom-selection.title")
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isUseHighlight
            ? _c("div", [
                _vm.perkType === _vm.TYPE_DEAL.DEAL_BUY_ONE_GET_ONE
                  ? _c(
                      "div",
                      [
                        (_vm.show === true && _vm.highlightItem === 0) ||
                        _vm.show === false
                          ? _c(
                              "b-form-radio",
                              {
                                staticClass: "ml-4 mb-2",
                                attrs: {
                                  "data-cy":
                                    "promotion-highlight-radio-required",
                                  value: 0,
                                },
                                model: {
                                  value: _vm.highlightItem,
                                  callback: function ($$v) {
                                    _vm.highlightItem = $$v
                                  },
                                  expression: "highlightItem",
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t(
                                        "promotions.highlight-custom-selection.required"
                                      )
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (_vm.show === true && _vm.highlightItem === 1) ||
                        _vm.show === false
                          ? _c(
                              "b-form-radio",
                              {
                                staticClass: "ml-4 mb-2",
                                attrs: {
                                  "data-cy": "promotion-highlight-radio-reward",
                                  value: 1,
                                },
                                model: {
                                  value: _vm.highlightItem,
                                  callback: function ($$v) {
                                    _vm.highlightItem = $$v
                                  },
                                  expression: "highlightItem",
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t(
                                        "promotions.highlight-custom-selection.reward"
                                      )
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (_vm.show === true && _vm.highlightItem === 2) ||
                        _vm.show === false
                          ? _c(
                              "b-form-radio",
                              {
                                staticClass: "ml-4 mb-2",
                                attrs: {
                                  "data-cy":
                                    "promotion-highlight-radio-required-reward",
                                  value: 2,
                                },
                                model: {
                                  value: _vm.highlightItem,
                                  callback: function ($$v) {
                                    _vm.highlightItem = $$v
                                  },
                                  expression: "highlightItem",
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t(
                                        "promotions.highlight-custom-selection.required-reward"
                                      )
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _vm.show === false
                          ? _c(
                              "b-form-group",
                              _vm._l(
                                _vm.groupIndexList,
                                function (groupIndex, index) {
                                  return _c(
                                    "b-form-radio",
                                    {
                                      key: index,
                                      staticClass: "ml-4 mb-2",
                                      attrs: {
                                        "data-cy": `promotion-highlight-radio-true-${index}`,
                                        value: groupIndex,
                                      },
                                      model: {
                                        value: _vm.highlightItem,
                                        callback: function ($$v) {
                                          _vm.highlightItem = $$v
                                        },
                                        expression: "highlightItem",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              "promotions.highlight-custom-selection.buy-one-get-one-children"
                                            )
                                          ) +
                                          _vm._s(groupIndex + 1) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            )
                          : _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "ml-4 mb-2",
                                    attrs: {
                                      "data-cy": `promotion-highlight-radio-true-${_vm.highlightItem}`,
                                      value: _vm.highlightItem,
                                    },
                                    model: {
                                      value: _vm.highlightItem,
                                      callback: function ($$v) {
                                        _vm.highlightItem = $$v
                                      },
                                      expression: "highlightItem",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t(
                                            "promotions.highlight-custom-selection.buy-one-get-one-children"
                                          )
                                        ) +
                                        _vm._s(_vm.highlightItem + 1) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "text-muted text-small" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("promotions.highlight-custom-selection.description")
                ) +
                "\n    "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }