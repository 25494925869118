var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      staticClass: "mr-1 mb-1",
      attrs: {
        "data-cy": "top-dropdown-filterOrderStatus",
        text:
          _vm.$t("order.fields.status") +
          ": " +
          _vm.$t(`order.status.${_vm.selectedOrderStatus.label}`),
        variant: "outline",
        size: "xs",
      },
    },
    _vm._l(_vm.orderStatuses, function (orderStatus, index) {
      return _c(
        "b-dropdown-item",
        {
          key: index,
          attrs: {
            "data-cy": `top-dropdown-filterOrderStatus-item-${index}`,
            active: orderStatus.active,
          },
          on: {
            click: function ($event) {
              return _vm.onClick(orderStatus)
            },
          },
        },
        [
          _vm._v(
            "\n  " +
              _vm._s(_vm.$t(`order.status.${orderStatus.label}`)) +
              "\n  "
          ),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }