// Componet for selecting Categories.
// Author : Huen Oh (heons921@gmail.com)
// @param {string} name       Name of the component. Index in usual.
// @param {bool}   value      Value for isChanged. v-model.
// @param {bool}   disabled   Disabled property of the component. (in)
// @param {number} colSize    Size of the column for the form. : 1 ~ 12. (in)
// @param {Object} categories Categories. (in)


<template>
<div>
  <!-- Dialog for Add Category -->
  <!-- TODO : change add-new-category -->
  <dialog-name-description
    name="dlg-category"
    :title="$t('product.add-new-category')"
    :dialog="dialogs.category"
    @dialog-close="closeDialogCategory"
    @dialog-submit="onSubmitDialogCategory"
  />
  
  <!-- Product Category Selection -->
  <!-- TODO : change choose-category -->
  <b-form-group>
    <h6 v-if="!disabled">{{$t('product.choose-product-category')}}</h6>
    <h6 v-else>{{$t('product.product-category')}}</h6>
    <b-row align-v="center" no-gutters>
      <!-- Product Category : Selection -->
      <b-colxx :sm="colSize">
        <v-select 
          :data-cy="`${name}-select-category`"
          :options="currentSelOptions" 
          v-model="selectedCategory" 
          @input="onSelectItem"
          v-if="!disabled"
        >
          <span slot="no-options">
            {{$t('validations.no-category-available')}}
          </span>

          <template #search="{ attributes, events }">
            <input
              :data-cy="`${name}-select-category-input`"
              class="vs__search"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
      </b-colxx>
      <!-- Product Category : Add button -->
      <b-colxx sm="2" v-if="!disabled">
        <b-button
          :data-cy="`${name}-btn-add-category`"
          variant="outline-primary"
          size="xs"
          class="cls-btn-add"
          @click="openDialogCategory()"
        >
          <b-img src="/assets/icons/plus.svg" widht="12" height="12"></b-img>
        </b-button>
      </b-colxx>
    </b-row>
    <b-row align-v="center" no-gutters>
      <!-- Product Category : Badge -->
      <b-badge
        v-for="(category, index) in currentCategories" :key="category"
        class="mb-1 cls-badge" pill variant="outline-dark"
      >
        {{ category }} 
        <a 
          :data-cy="`${name}-badge-category-${index}-delete`"
          href="javascript:void(0);" 
          @click="deleteCategory(index)" 
          v-if="!disabled"
        >x</a>
      </b-badge>

      <b-badge v-if="currentCategories.length == 0 && disabled" class="mb-1 cls-badge" pill variant="outline-dark">
        {{$t('general.none')}}
      </b-badge>
    </b-row>
  </b-form-group>

</div>
</template>


<script>
import vSelect    from 'vue-select'
import 'vue-select/dist/vue-select.css'

import DialogNameDescription  from '@/components/Common/DialogNameDescription'

import { addItemValueToList, isNullOrUndefined } from "@/utils/general"

import { mapGetters, mapActions } from 'vuex'

// Notifications
import {
  addNotifySuccess,
  addNotifyFail,
} from "@/utils/notify.js"

export default {
  props:{
    // {string} Name of the component. Index in usual.
    name: {
      type: String,
      default: ""
    },
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {string} Size of the column for the form. : 1 ~ 12.
    colSize: {
      type: Number,
      default: 10,
    },
    // {string[]} Categories.
    categories: {
      type: Array,
      default: () => [],
    },
    // {bool} Value for isChanged. v-model.
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'v-select': vSelect,
    DialogNameDescription,
  },
  data(){
    return {
      selectedCategory: {value: "", label:"", text:""}, // Selected attrubute object.
      currentCategories: [],  // Current selected categories [{value:"", label:"", text:""}]

      dialogs: {              // Handler object for dialogs(Close and Open).
        category:false,
      },

      isFirstWatch:true,  // {bool} Flag if it the first watch to detemine isChanged flag.
      isChanged:false,    // {bool} Flag if anything has changed in the component. // TODO : may not need
    }    
  },

  created: function () {
    // console.log('created');
  },
  watch: {
    /**
     * Watch to check changes in currentCategories.
     */
    currentCategories: {
      deep:true,
      handler() {
        // console.log("watch currentCategories changed");
        if(this.isFirstWatch == false) {
          this.isChanged = true;
          this.$emit('input', true);
          // console.log("watch currentCategories changed");
        } else {
          // console.log("watch currentCategories changed", this.currentCategories);
          this.isFirstWatch = false;
        }
      },
    },
  },
  computed: {
    // Map getters
    ...mapGetters("product_category",  ["selectableProductCategories"]),

    // Computed value to exclude selected items from the selection option.
    currentSelOptions: function() {
      return this.selectableProductCategories.filter((element) => {
        return !this.currentCategories.includes(element.value) && (element.value !== "");
      });
    },
  },
  methods:{
    // Add a value of an item to the item list if it doesn't exist in the list.
    addItemValueToList,

    // Notifications.
    addNotifySuccess,
    addNotifyFail,

    // Map actions.
    ...mapActions("product_category",  ["addOneProductCategory"]),

    /**
     * Reset values.
     */
    reset() {
      this.selectedCategory = {value: "", label:"", text:""};
      this.currentCategories = [];
      this.isFirstWatch = true;
      this.isChanged = false;
    },
    /**
     * Get selected Product Categories.
     * @return {string[]} Selected Product Categories.
     */
    getCategories() {
      return this.currentCategories;
    },

    /**
     * Delete an category. It only updates locally.
     * @param {number} index index of an item to delete.
     */
    deleteCategory(index) {
      this.currentCategories.splice(index,1);
    },

    // Dialog operations - Category
    /**
     * Close Category dialog - Add.
     */
    closeDialogCategory() {
      this.dialogs.category = false;
    },
    /**
     * Open Category dialog - Add.
     */
    openDialogCategory() {
      this.dialogs.category = true;
    },
    /**
     * On Submit Category dialog - Add.
     * Add and reload Categories.
     * @param {Object} data Category object: Refer to PRODUCT_ATTRIBUTE_MODEL
     */
    onSubmitDialogCategory(data) {
      // console.log('onSubmitDialogCategory', data);

      // Trim the string values
      if (!isNullOrUndefined(data.name)) {
        data.name = data.name.trim();
      }
      if (!isNullOrUndefined(data.description)) {
        data.description = data.description.trim();
      }

      // Check if the category to add is already exist.
      const isExist = this.selectableProductCategories.find((element) => {
        return element.value === data.name;
      });

      if (!isNullOrUndefined(isExist)) {
        this.addNotifyFail(this.$t('notify.category-exist'));
        return;
      }
      
      if (data.name === "") {
        this.addNotifyFail(this.$t('notify.category-empty-name'));
        return;
      }

      this.addOneProductCategory(data).then(()=> {
        this.addItemValueToList(this.currentCategories, {value:data.name});
        this.selectedCategory = {value: "", label:"", text:""};
        this.addNotifySuccess(this.$t('notify.category-add'));
      });
    },

    /**
     * On select item from the selection.
     */
    onSelectItem() {
      this.addItemValueToList(this.currentCategories, this.selectedCategory);
      this.selectedCategory = {value: "", label:"", text:""};
    },
  },
  mounted(){
    if(Array.isArray(this.categories)) {
      this.currentCategories = JSON.parse(JSON.stringify(this.categories));
    }
  },

}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>

