var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100" },
    [_c("router-view"), _vm._v(" "), _c("color-switcher")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }