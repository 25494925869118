<template>
  <b-dropdown
    data-cy="top-dropdown-pageSize"
    :text="pageSizeLabel"
    variant="outline-dark" 
    class="mr-1 mb-1 btn-group " 
    size="xs" 
    no-flip
  >
    <b-dropdown-item 
      v-for="(pageSize,index) in pageSizeOptions"
      :data-cy="`top-dropdown-pageSize-item-${index}`"
      :key="index" 
      @click="onClick(pageSize)"
    >
      {{ pageSize.text }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    pageSize: {
      type: Object,
      default: () => ({value: 5, text: "5"}),
    }
  },
  methods: {
    onClick(filter) {
      this.$emit("change", filter);
    }
  },
  computed: {
    // TODO: I think should change page size options to [5, 10, 20, 50]. What do you think?
    pageSizeOptions() {
      return [
        {value: 5, text: "5"},
        {value: 10, text: "10"},
        {value: 15, text: "15"},
        {value: 20, text: "20"},
      ];
      
    },
    pageSizeLabel() {
      return `${this.$t('order.history.page-size')}: ${this.pageSize.text}`;
    },
  }
}
</script>