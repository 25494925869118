/**
 * It contains store module for Merchant Profile.
 */

import {
  getProfileApi,
  updateProfileApi,
  deleteImageUrlsApi,
  getSessionData,
  getStripeCustomerData,
  getStripeCustomerSubscriptions,
  cancelStripeCustomerSubscription,
  resumeStripeCustomerSubscription
} from "@/api/merchant_profile.js";

import {parseProfile} from "@/models/merchant/merchant_profile.js";

import {isResponseOK, dataURItoFile} from "@/utils/general.js"

/**
 * Merchant Profile data processing.
 *
 * So the workflow here is the following:
 * - modify profile's data
 * - add images to as logo/images
 * - then call uploadProfileServer to upload
 * image files first and then entire profile object.
 */
export default {
  namespaced: true,
  state: {
    profile: {},                // {Object}  Merchant Profile object: Refer to MODEL_MERCHANT_PROFILE.

    taxIdsForInitialStore: [], // {TaxConfigId[]} List of tax ids for initial store creation when a new profile is created for the merchant.

    // For logo image.
    isLogoImageDeleted: false,  // {boolean} To check if the logo image is deleted.
    logoImageObject: null,      // {Object}  Logo image object.

    // For business images.
    businessImagesToDelete: [], // {string[]} Business image Urls to delete.
    businessImageObjects: [],   // {Object[]} Business image objects.

    // {Object[]} Operation Time of the business. It is for the initial setting of the profile: Refer to MODEL_DAY_TIME.
    operationTime: [],

    // {Object[]} Serve options of the business including DINE_IN, DELIVERY, PICKUP.
    serveOptions: [],
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.profile = {};

      state.isLogoImageDeleted = false;
      state.logoImageObject = null;

      state.businessImagesToDelete = [];
      state.businessImageObjects = [];

      state.operationTime = [];
      state.serveOptions = [];
    },
    // General profile
    /**
     * Set(Update) Merchant Profile in the state.
     * @param {Object} state   Local State object.
     * @param {Object} profile Merchant Profile object: Refer to MODEL_MERCHANT_PROFILE.
     */
    setProfile(state, profile) {
      state.profile = {...state.profile, ...profile};
    },
    /**
     * Set(Update) address of Merchant Profile in the state.
     * @param {Object} state   Local State object.
     * @param {Object} address Address object: Refer to MODEL_ADDRESS.
     */
    updateProfileAddress(state, address) {
      state.profile.address = {
        ...state.profile.address,
        ...address
      };
    },
    /**
     * Set(Update) business lines of Merchant Profile in the state.
     * @param {Object}    state  Local State object.
     * @param {string[]}  blines Business lines.
     */
    updateProfileBusinessLines(state, blines) {
      state.profile.businessLines = [...blines];
    },


    /**
     * Reset all images objects for the merchant profile in the state.
     * @param {Object} state Local State object.
     */
    resetImageObjects(state) {
      state.logoImageObject = null;
      state.isLogoImageDeleted = false;
      state.businessImageObjects = [];
      state.businessImagesToDelete = [];
    },
    /**
     * Set the logo image object in the state.
     * @param {Object}  state       Local State object.
     * @param {Object}  imageObject Image object for the logo.
     */
    setLogoImageObject(state, imageObject) {
      state.logoImageObject = imageObject;
    },
    /**
     * Set if the logo image(profile.logoUrl) is deleted in the state.
     * @param {Object}  state     Local State object.
     * @param {boolean} isDeleted Is the logo image deleted.
     */
    setIsLogoImageDeleted(state, isDeleted) {
      state.isLogoImageDeleted = isDeleted;
    },
    /**
     * Set the business image objects in the state.
     * @param {Object}    state         Local State object.
     * @param {Object[]}  imageObjects  Image objects for the business.
     */
    setBusinessImageObjects(state, imageObjects) {
      state.businessImageObjects = [...imageObjects];
    },
    /**
     * Update business images to delete in the state.
     * @param {Object}    state   Local State object.
     * @param {string[]}  imgUrls Urls of business images to delete.
     */
    updateBusinessImagesToDelete(state, imgUrls) {
      state.businessImagesToDelete = [...state.businessImagesToDelete, ...imgUrls];
    },

    /**
     * Set the operation time in the state.
     * @param {Object}    state         Local State object.
     * @param {Object[]}  operationTime Operation time objects: Refer to MODEL_DAY_TIME.
     */
    setOperationTime(state, operationTime) {
      state.operationTime = operationTime;
    },

    /**
     * Set the serving option in the state.
     * @param {Object}    state         Local State object.
     * @param {Object[]}  serveOptions Serving option
     */
    setServeOptions(state, serveOptions) {
      state.serveOptions = serveOptions;
    },


    /**
     * Set Business Type of Merchant Store setup.
     * @param {Object} state Local state object.
     * @param {String} businessType Business Type of Merchant.
     */
    updateBusinessType(state, businessType) {
      state.profile.businessType = businessType;
    },

    setBusinessType(state, businessType) {
      state.businessType = businessType;
    },

    /**
     * Set list of tax ids for initial store on profile creation.
     * @param {Object} state Local state
     * @param {TaxIds[]} taxIds List of selected tax ids.
     */
    setTaxIdsForInitialStore(state, taxIds) {
      state.taxIdsForInitialStore = taxIds;
    },

    setAyrshareKey(state, ayrshareKey) {
      state.profile.ayrshareKey = ayrshareKey;
    },

    setStripeCustomerId(state, customerId) {
      state.profile.stripeCustomerId = customerId;
    }
  },
  getters: {
    /**
     * Get if merchant businessLines is set.
     * It checks merchant businessLines of the Merchant Profile.
     * @return {bool} True if merchant businessLines is set, False otherwise.
     */
    isMerchantBusinessLineSet: (state) => {
      return Array.isArray(state.profile.businessLines) && state.profile.businessLines.length > 0;
    },
    /**
     * Get if the merchant profile is set.
     * It checkes logoUrl of the Merchant Profile.
     * @return {bool} True if Merchant Profile is set, False otherwise.
     */
    isMerchantProfileSet: (state) => {
      return state.profile.name ? true : false;
    },
    /**
     * Get basic Merchant Profile: name, description, phone, email and website.
     * @return {Object} Basic Merchant Profile.
     */
    profileMain: state => {
      return {
        name: state.profile.name,
        description: state.profile.description,
        phone: state.profile.phone,
        email: state.profile.email,
        website: state.profile.website
      }
    },
    /**
     * Get Business Type.
     * @return {String} Business Type (Parent category)
     */
    businessType: state => {
      return state.profile.businessType;
    },
    /**
     * Get business line of Merchant Profile.
     * @return {string[]} Business lines of Merchant Profile.
     */
    businessLines: state => {
      return state.profile.businessLines;
    },
    /**
     * Get address of Merchant Profile.
     * @return {Object} Address of Merchant Profile: Refer to MODEL_ADDRESS.
     */
    profileAddress: state => {
      return state.profile.address;
    },
    /**
     * Get business images of Merchant Profile.
     * @return {string[]} Business image urls of Merchant Profile.
     */
    profileImageUrls: state => {
      return state.profile.imagesUrl
    },
    /**
     * Get logo image of Merchant Profile.
     * @return {string} Logo image url of Merchant Profile.
     */
    profileLogoUrl: state => {
      return state.profile.logoUrl
    },
    /**
     * Get if the current logo image is deleted.
     * @return {bool} True if the current logo image is deleted, False otherwise.
     */
    isLogoImageDeleted: state => {
      return state.isLogoImageDeleted;
    },
    /**
     * Get the logo image file if there is any.
     * @return {[File]} The logo image file if there is any, null otherwise.
     */
    logoImageFile: state => {
      if (state.logoImageObject === null) {
        return null;
      } else {
        return state.logoImageObject.file ? dataURItoFile(state.logoImageObject.preview) : null;
      }
    },
    /**
     * Get the logo image object if there is any.
     * @return {[Object]} The logo image object if there is any, null otherwise.
     */
    logoImageObject: state => {
      if (state.logoImageObject === null) {
        return null;
      } else {
        return {
          "file": state.logoImageObject.file ? dataURItoFile(state.logoImageObject.preview) : null,
          "preview": state.logoImageObject.preview
        };
      }
    },
    /**
     * Get the business image files if there is any.
     * @return {File[]} The business image files if there is any, empty array otherwise.
     */
    businessImageFiles: state => {
      if (state.businessImageObjects.length === 0) {
        return [];
      } else {
        return _.compact(
          state.businessImageObjects.map(image => {
            return image.file ? dataURItoFile(image.preview) : null;
          })
        );
      }
    },
    /**
     * Get the business image objects if there is any.
     * @return {Object[]} The business image objects if there is any, empty array otherwise.
     */
    businessImageObjects: state => {
      if (state.businessImageObjects.length === 0) {
        return [];
      } else {
        return state.businessImageObjects.map(image => {
          return {
            "file": image.file ? dataURItoFile(image.preview) : null,
            "preview": image.preview
          }
        });
      }
    },
    /**
     * Get the business images to be deleted.
     * @return {string[]} The business image to be deleted.
     */
    businessImagesToDelete: state => {
      return state.businessImagesToDelete;
    },
    // TODO : No-use for now.
    /**
     * Get stores of Merchant Profile.
     * @return {Object[]} Merchant Stores: Refer to MODEL_MERCHANT_STORE.
     */
    stores: state => {
      return state.profile.stores;
    },

    /**
     * Get the operationTime.
     * @return {Object[]} Operation time objects: Refer to MODEL_DAY_TIME.
     */
    operationTime: state => {
      return state.operationTime;
    },

    /**
     * Get the serveOptions.
     * @return {Object[]} Serve options of the busiess.
     */
    serveOptions: state => {
      return state.serveOptions;
    },
    /**
     * Get list of tax ids for initial store on profile creation.
     * @param {Object} state Local state
     * @returns List of tax ids.
     */
    taxIdsForInitialStore: state => {
      return state.taxIdsForInitialStore;
    },
    ayrshareKey: state => {
      return state.profile.ayrshareKey;
    },
    stripeCustomerId: state => {
      return state.profile.stripeCustomerId;
    }
  },
  actions: {
    // API
    /**
     * Load Merchant Profile.
     * It loads Merchant Profile from the sever.
     * @return {Object} Response object of a Promise.
     */
    async loadProfileServer({commit}) {
      const response = await getProfileApi();
      if (isResponseOK(response)) {
        const profile = parseProfile(response.data);
        commit("setProfile", profile);
        // console.log('loadProfileServer', profile);
      } else {
        console.log("Could not get profiles:");
        // TODO: some handler later
      }
      return response;
    },
    async loadSessionData({commit}, sessionId) {
      const response = await getSessionData(sessionId);

      return response;
    },

    async loadStripeCustomerData({commit}, customerId) {
      const response = await getStripeCustomerData(customerId);

      return response;
    },

    async loadStripeCustomerSubscriptions({commit}, customerId) {
      const response = await getStripeCustomerSubscriptions(customerId);

      return response;
    },
    async cancelStripeCustomerSubscription({commit}, subscriptionId) {
      const response = await cancelStripeCustomerSubscription(subscriptionId);

      return response;
    },
    async getStripeCustomerSubscriptionSession({commit}, customerId,successUrl) {
      const response = await getStripeCustomerSubscriptionSession(customerId,successUrl);

      return response;
    },
    /**
     * Upload Merchant Profile.
     * It updates Merchant Profile to the server.
     * @return {Object} Response object of a Promise.
     */
    async uploadProfileServer({state, dispatch, getters, commit}) {
      // console.log('state.profile', state.profile)
      const response = await updateProfileApi(state.profile, getters.logoImageFile, getters.businessImageFiles);
      const profile = parseProfile(response.data);
      commit("setProfile", profile);

      await dispatch("deleteImagesServer"); // TODO : deal with response.
      // await dispatch("resetImageObjects");
      // await dispatch("loadProfileServer");
      return response;
    },
    /**
     * Delete business images of Merchant Profile in the server.
     * @return {Object} Response object of a Promise or null.
     */
    deleteImagesServer({state}) {
      if (state.businessImagesToDelete.length == 0) {
        return null;
      } else {
        return deleteImageUrlsApi(state.businessImagesToDelete);
      }
    },

    // Local profile helpers
    /**
     * Set(Update) Merchant Profile in the state.
     * @param {Object} profile Merchant Profile object: Refer to MODEL_MERCHANT_PROFILE.
     */
    updateMerchnatProfile({commit}, profile) {
      commit("setProfile", profile);
    },
    /**
     * Set(Update) address of Merchant Profile in the state.
     * @param {Object} address Address object: Refer to MODEL_ADDRESS.
     */
    updateMerchantProfileAddress({commit}, address) {
      commit("updateProfileAddress", address);
    },
    /**
     * Set list of selected tax ids for initial store on profile creation.
     * @param {TaxIds[]} taxIds List of selected tax ids for new store.
     */
    updateMerchantProfileTaxIds({commit}, taxIds) {
      commit("setTaxIdsForInitialStore", taxIds);
    },

    updateAyrshareKey({commit}, profileKey) {
      commit("setAyrshareKey", profileKey);
    },

    updateStripeCustomerId({commit}, customerId) {
      commit("setStripeCustomerId", customerId);
    },
    /**
     * Set(Update) business lines of Merchant Profile in the state.
     * @param {string[]} blines Business lines.
     */
    updateMerchantProfileBusinessLines({commit}, blines) {
      commit("updateProfileBusinessLines", blines);
    },

    // Local images
    /**
     * Reset all images objects for the merchant profile in the state.
     * It also resets operationTime.
     */
    resetImageObjects({commit}) {
      commit("resetImageObjects");
      commit("setOperationTime", []);
    },
    /**
     * Set the logo image object in the state.
     * @param {Object} imageObject Image object for the logo.
     */
    setLogoImageObject({commit}, imageObject) {
      commit("setLogoImageObject", imageObject);
    },
    /**
     * Set if the logo image(profile.logoUrl) is deleted in the state.
     * @param {boolean} isDeleted Is the logo image deleted.
     */
    setIsLogoImageDeleted({commit}, isDeleted) {
      commit("setIsLogoImageDeleted", isDeleted);
    },
    /**
     * Set the business image objects in the state.
     * @param {Object[]} imageObjects Image objects for the business.
     */
    setBusinessImageObjects({commit}, imageObjects) {
      commit("setBusinessImageObjects", imageObjects);
    },
    /**
     * Update business images to delete in the state.
     * @param {string[]} imgUrls Urls of business images to delete.
     */
    updateBusinessImagesToDelete({commit}, imgUrls) {
      commit("updateBusinessImagesToDelete", imgUrls);
    },

    /**
     * Set the operation time in the state.
     * @param {Object[]} operationTime Operation time objects: Refer to MODEL_DAY_TIME.
     */
    setOperationTime({commit}, operationTime) {
      commit("setOperationTime", operationTime);
    },

    /**
     * Set the serve options in the state.
     * @param {Object[]} serveOptions Serve options of the business including DINE_IN, DELIVERY, PICKUP
     */
    setServeOptions({commit}, serveOptions) {
      commit("setServeOptions", serveOptions);
    },
  }
};
