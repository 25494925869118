var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-date-picker", {
    staticClass: "d-inline-block mr-1 mb-1 custom-form-group btn-group",
    attrs: {
      "data-cy": "top-date-picker",
      mode: "range",
      "input-props": {
        class: "custom-input-form custom-date-picker",
        placeholder: _vm.$t("form-components.date-range"),
      },
    },
    on: {
      input: function ($event) {
        return _vm.onChange()
      },
    },
    model: {
      value: _vm.selectedDuration,
      callback: function ($$v) {
        _vm.selectedDuration = $$v
      },
      expression: "selectedDuration",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }