import {
  createPostContent, createPostImage,
  createScheduledPost, getPostHistory
} from "@/api/social_media_post";

import LocalStorageService from "@/utils/local_store.js";
import merchant_profile from "@/store/modules/merchant_profile";
import {isResponseOK} from "@/utils/general";
import {parseProductList} from "@/models/products/product";
import {ENUM_STATUS_STRING} from "@/constants/common";


export default {
  namespaced: true,
  state: {
    postType: "",
    selectedHolidays: [],
    onTheDay: false,
    beforeTheDay: false,
    afterTheDay: false,
    selectedBeforeTheDay: "",
    selectedAfterTheDay: "",
    selectedAccounts: "",
    selectedPerks: [],
    selectedDate: "",
    selectedRepeat: "No repeat",
    postContent: "Test Post", // will be set from openAI
    postCount: {},
    shortTermPOstCount:{},
    posts:[],
  },

  mutations: {
    setPostType(state, postType) {
      state.postType = postType;
    },
    setPostContent(state, postContent) {
      state.postContent = postContent;
    },
    setOnTheDay(state, onTheDay) {
      state.onTheDay = onTheDay;
    },
    setBeforeTheDay(state, beforeTheDay) {
      state.beforeTheDay = beforeTheDay;
    },
    setAfterTheDay(state, afterTheDay) {
      state.afterTheDay = afterTheDay;
    },
    setSelectedHolidays(state, selectedHolidays) {
      state.selectedHolidays = selectedHolidays;
    },
    setSelectedAccounts(state, selectedAccounts) {
      state.selectedAccounts = selectedAccounts;
    },
    setSelectedPerks(state, selectedPerks) {
      state.selectedPerks = selectedPerks;
    },
    setSelectedDate(state, selectedDate) {
      state.selectedDate = selectedDate;
    },
    setSelectedRepeat(state, selectedRepeat) {
      state.selectedRepeat = selectedRepeat;
    },
    setPostCount(state, postCount) {
      state.postCount = postCount;
    },
    setShortTermPostCount(state, shortTermPostCount) {
      state.shortTermPOstCount = shortTermPostCount;
    },
    setAllPosts(state, posts) {
      state.posts = posts;
    }
  },
  getters: {
    getPostType: (state) => {
      return state.postType;
    },
    getPostContent: (state) => {
      return state.postContent;
    },
    getOnTheDay: (state) => {
      return state.onTheDay;
    },
    getBeforeTheDay: (state) => {
      return state.beforeTheDay;
    },
    getAfterTheDay: (state) => {
      return state.afterTheDay;
    },
    getSelectedHolidays: (state) => {
      return state.selectedHolidays;
    },
    getSelectedPerks: (state) => {
      return state.selectedPerks;
    },
    getSelectedDate: (state) => {
      return state.selectedDate;
    },
    getSelectedRepeat: (state) => {
      return state.selectedRepeat;
    },
    getPostCount: (state) => {
      return state.postCount;
    },
    getShortTermPostCount: (state) => {
      return state.shortTermPOstCount;
    },
    getAllPosts: (state) => {
      return state.posts;
    }

  },
  actions: {

    setPostContent({commit}, content) {
      commit("setPostContent", content);
    },
    setPostType({commit}, postType) {
      commit("setPostType", postType);

    },
    setOnTheDay({commit}, onTheDay) {
      commit("setOnTheDay", onTheDay);
    },
    setBeforeTheDay({commit}, beforeTheDay) {
      commit("setBeforeTheDay", beforeTheDay);
    },
    setAfterTheDay({commit}, afterTheDay) {
      commit("setAfterTheDay", afterTheDay);
    },
    setSelectedAccounts({commit}, selectedAccounts) {
      commit("setSelectedAccounts", selectedAccounts);
    },
    setSelectedHolidays({commit}, selectedHolidays) {
      commit("setSelectedHolidays", selectedHolidays);
    },
    setSelectedPerks({commit}, selectedPerks) {
      commit("setSelectedPerks", selectedPerks);
    },
    setSelectedDate({commit}, selectedDate) {
      commit("setSelectedDate", selectedDate);
    },
    setSelectedRepeat({commit}, selectedRepeat) {
      commit("setSelectedRepeat", selectedRepeat);
    },
    setPostCount({commit}, postCount) {
      commit("setPostCount", postCount);
    },
    setShortTermPostCount({commit}, shortTermPostCount) {
      commit("setShortTermPostCount", shortTermPostCount);
    },
    setAllPosts({commit}, posts) {
      commit("setAllPosts", posts);
    },
    createScheduledPost({commit}, data) {

      const {values, onSuccess, onFail} = data;

      createScheduledPost(values)
        .then(response => {
          console.log(response);
          onSuccess(response);
        })
        .catch(error => {
          if (onFail) {
            onFail(error.response);
          }
        });
    },

    async loadPostHistory({commit}, data) {
      const {values, onSuccess, onFail} = data;
      const response = await getPostHistory(values);
      if (isResponseOK(response)) {
        const posts = response.data;
        commit("setAllPosts", posts);
      } else {
        commit("setAllPosts", null);
        console.log("[Error]loadPosts:");
      }
      return response;
    },

    async getPostHistory({commit}, data) {
      const {values, onSuccess, onFail} = data;
      const response = await getPostHistory(values);
      return response;
    },

    async createPostContent({commit}, data) {
      const {values, onSuccess, onFail} = data;
      const response = createPostContent(values);
      return response;
    },

      async createPostImage({commit}, data) {
          const {values, onSuccess, onFail} = data;
          const response = createPostImage(values);
          return response;
      }
  }
};
