var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-alert" },
    [
      _c(
        "b-modal",
        {
          ref: "modalAlert",
          attrs: {
            id: "modalAlert",
            size: "sm",
            "hide-header": _vm.actionType == "",
            title: _vm.actionType,
          },
          on: {
            hide: function ($event) {
              return _vm.onCencel()
            },
          },
        },
        [
          _c("p", {
            staticClass: "markdown-body",
            domProps: { innerHTML: _vm._s(_vm.description) },
          }),
          _vm._v(" "),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-1",
                  attrs: { "data-cy": "dlg-alert-btn-ok", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmit()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.buttonSubmitText) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    "data-cy": "dlg-alert-btn-cancel",
                    variant: "outline-secondary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onCencel()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }