// Dialog with Name and Description
// Author : Huen Oh (heons921@gmail.com)
//
// @param {string}  name   Name of the component. It is the identifier of the component. (in)
// @param {string}  title  Title of the dialog
// @param {boolean} dialog Handler variable for the dialog(Close and Open)


<template>
  <!-- Dialog: Name and Description -->
  <b-modal id="modalNameDescription" ref="modalNameDescription" :title="title" class="modal-right" size="sm" @hide="onCencel()">
    <b-form>
      <b-row>
        <b-colxx sm="12">
          <!-- Name -->
          <b-form-group :label="$t('product.provide-name-description')">
            <b-form-input
              :data-cy="`${name}-input-name`"
              v-model="data.name"
              :placeholder="$t('forms.name')"
              :state="!$v.data.name.$invalid || isFirstLoad"
            />
            <b-form-invalid-feedback>
              {{ $t('validations.no-name-validation')}}
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- Description -->
          <b-form-group>
            <b-textarea 
              :data-cy="`${name}-input-description`"
              v-model="data.description" 
              :rows="2" 
              :max-rows="2" 
              :placeholder="$t('forms.descriptions')"
            />
          </b-form-group>
        </b-colxx>
      </b-row>
    </b-form>
    <template slot="modal-footer">
      <b-button 
        :data-cy="`${name}-btn-cancel`"
        variant="outline-secondary" @click="onCencel()">{{ $t('buttons.cancel') }}</b-button>
      <b-button 
        :data-cy="`${name}-btn-submit`"
        variant="primary" class="mr-1" :disabled="$v.data.$invalid && !isFirstLoad" @click="onSubmit()">{{ $t('buttons.submit') }}</b-button>
    </template>
  </b-modal>
</template>

<script>

import { validationMixin } from "vuelidate";
const { required, minLength } = require("vuelidate/lib/validators");

export default {
  components:{
  },
  props: {
    // {string} Name of the component. It is the identifier of the component. (in)
    name: {
      type: String,
      default: ""
    },
    // {string} Title of the dialog
    title:{
      type: String,
      default: "",
    },
    // {boolean} Handler variable for the dialog(Close and Open).
    dialog:{
      type: Boolean,
      default: false,
    }
  },
  data(){
    return{
      data: {name:"", description:""},
      
      // {boolean} Is component first loaded to display validation message
      isFirstLoad: true
    }
  },
  mixins: [validationMixin],
  validations: {
    data: {
      name: {
        required,
        minLength: minLength(1)
      },
    }
  },

  methods: {
    /**
     * On Cencel button : Close the dialog
     */
    onCencel() {
      this.isFirstLoad = true;

      this.$emit('dialog-close');
    },
    /**
     * On Submit button : Save the data and Close the dialog
     */
    onSubmit() {
      this.isFirstLoad = false;

      // Not submit the form if fail validation
      if(this.$v.data.$invalid){
        return;
      }

      // console.log("onSubmit");
      // console.log(this.data);
      if(this.data.name != "") {
        this.$emit('dialog-submit', this.data);
        this.$emit('dialog-close');
      } else {}
    },
  },

  watch: {
    // Control of the dialog: Show, Hide
    dialog: function(newVal, oldVal){
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if(newVal == true) {
        this.$refs["modalNameDescription"].show();
      } else {
        this.$refs["modalNameDescription"].hide();
      }
    }
  },

  mounted(){ 
  }
}
</script>