// List Post data.
// Author : Aysegul Yalc'nkaya
// @param {string}   cyPrefix      Prefix for data-cy.
// @param {Object}   data          Data object
// @param {string[]} selectedItems Selected items' Id to check if the current list is selected or not.
// @param {string[]} buttons       List of buttons in the components.

<template>
  <b-colxx xxs="12" class="mb-3" v-if="data.status=='success' || data.status=='pending'">
  <b-card
    :data-cy="`${cyPrefix}`"
    @click="onClickEvent($event, data.id)"
    :class="{'d-flex flex-row':true}"
    no-body
  >
    <!-- Dialog: Date/Time -->
    <dialog-date-time
      :disabled="data.showOnly"
      :data="data"
      :dialog="dialogs.schedule"
      @dialog-close="closeDialogSchedule"
      @dialog-submit="onSubmitDialogSchedule"
    />
    <!-- Dialog: Alert -->
    <dialog-alert
      :dialog="dialogs.alert"
      :description="$t('alert.delete')"
      :actionType="$t('buttons.delete')"
      :buttonSubmitText="$t('buttons.delete')"
      @dialog-close="closeDialogAlert"
      @dialog-submit="onSubmitDialogAlert"
    />

    <!-- Image
    <div class="align-self-center">
      <router-link :to="`?p=${data.id}`" class="d-flex">
        <b-img
          :src="(Array.isArray(data.mediaUrls) && (data.mediaUrls.length >0)) ? data.mediaUrls[0] : '/assets/img/no-image.png'"
          class="list-thumbnail responsive border-0"
          :alt="data.id"
          />
      </router-link>
    </div>
    -->

    <div class="pl-2 d-flex flex-grow-1 min-width-zero">

      <div class="card-body align-self-center flex-lg-row justify-content-between min-width-zero align-items-lg-center w-60" >

          <b-row>

            <!-- Badge : platform -->
            <BadgeListPlatform
              v-if="Array.isArray(data.postIds) && data.postIds.length > 0"
              :cyPrefix="`${cyPrefix}`"
              :data="data.postIds"
            />


          </b-row>
           <!-- Description of Product -->
          <b-row>
            <p :data-cy="`${cyPrefix}-description`" class="mb-1 text-muted">{{data.post}}</p>
          </b-row>

          <b-row v-if="data.scheduleDate">
            <p :data-cy="`${cyPrefix}-scheduled`" class="mb-1 text-default text-small">Scheduled Time: {{ formattedDate }}</p>
          </b-row>
        <b-row v-if="data.status">
          <p :data-cy="`${cyPrefix}-status`" class="mb-1 text-default text-small">Status: {{ data.status }}</p>
        </b-row>


      </div>


       <!-- Button Block  for reward-->
      <ButtonBlockInListReward
        v-if="isRewardItem"
        :config="{
          id:data.id,
          name:`${cyPrefix}`,
          points:{
            requiredPoints:data.requiredPoints,
          },
          toggleBtn:{
            status: data.status,
            disabled: !data.isValid,
            change: function(payload){ updateStatus(payload); }
          },
          imgBtnDuplicate: {
            click: function(){ onDuplicateItem(); }
          },
          imgBtnEdit: {
            click: function(){ onEditItem()}
          },
          imgBtnDelete: {
            click: function(){ openDialogAlert(); }
          },
          expireDate: data.availableTo
        }"
      />
    </div>
  </b-card>
  </b-colxx>
</template>

<script>
import DialogAlert              from '@/components/Common/DialogAlert'
import DialogDateTime           from '@/components/Common/DialogDateTime'
import BadgeListPlatform        from '@/components/Badges/BadgeListPlatform'
import BadgeListCategory        from '@/components/Badges/BadgeListCategory'
import BadgeListAddonGroup      from '@/components/Badges/BadgeListAddonGroup'
import BadgeListProductGroup    from '@/components/Badges/BadgeListProductGroup'
import ButtonBlockInListProduct from '@/components/Forms/ButtonBlockInListProduct'
import ButtonBlockInListReward  from '@/components/Forms/ButtonBlockInListReward'

// utils
import { formatNumber } from "@/utils/general.js"

// Notifications
import {
  addNotifyWarning,
} from "@/utils/notify.js"

export default {
  props:{
    // {string} Prefix for data-cy.
    cyPrefix: {
      type: String,
      default: ""
    },
    // {Object} Data object: Refer to PRODUCT_MODEL/ADDON_MODEL/PRODUCT_GROUP_MODEL/ADDON_GROUP_MODEL/MODEL_PROMOTION_READ.
    data: {
      type: Object,
      default:() => {}
    },
    // {string[]} Selected items' Id to check if the current list is selected or not.
    selectedItems:{
      type: Array,
      default:() => []
    },
    // {string[]} List of buttons in the components.
    buttons:{
      type: Array,
      default:() => []
    },
    // {String} Display reason why the item is disabled. Ex: Add-on not belong to group
    disabledReason: {
      type: String,
      default: ''
    },
    // {Boolean} Whether the elements of the list are shaking to drag and drop
    isDraggable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BadgeListPlatform,
    DialogAlert,
    DialogDateTime,
    BadgeListCategory,
    BadgeListAddonGroup,
    BadgeListProductGroup,
    ButtonBlockInListProduct,
    ButtonBlockInListReward,
  },
   data(){
    return {
      dialogs: {          // Dialog controls
        schedule:false,
        alert:false,
      },
    }
  },
  computed: {
    /**
     * To check if it is ProductGroup/AddonGroup.
     */
    isGroup: function () {
      return Array.isArray(this.data.products);
    },
    /**
     * To check if the ProductGroup/AddonGroup has Products/Addons.
     */
    isProductsInGroup : function() {
      return (Array.isArray(this.data.products) && (this.data.products.length > 0));
    },
    /**
     * To check if an Add-on belong to any Add-on group
     */
    isOrphanedAddon: function(){
      return this.data.isAddon && this.data.groups.length == 0;
    },
    /**
     * To check if it is reward item.
     */
    isRewardItem : function() {
      return this.data.requiredPoints ? true : false;
    },
    formattedDate : function() {
      return new Date(this.data.scheduleDate).toLocaleString();
    }
  },
  methods:{
    // Notifications
    addNotifyWarning,

    /**
     * On Click Event handler.
     *
     * It handles on click event.
     * Especially, it prevents being selected with image buttons and switch toggles in the list.
     *
     * @param {Object} event Event object
     * @param {string} itemId Item's Id
     */
    onClickEvent(event, itemId) {
      // console.log(event);
      // NOTE : Whenever the <toggle-button> is clicked, two events are called.
      //        Therefore, these condition needs to be applied.
      if((event.target.classList[0] != "v-switch-core")       // To avoid switch click as select toggle
        && (event.target.classList[0] != "v-switch-label")    // To avoid switch click as select toggle
        && (event.target.classList[0] != "v-switch-button")   // To avoid switch click as select toggle
        && (event.target.classList[0] != "no-checkbox")       // To avoid click as toggle
        && (event.target.classList[0] != "badge")             // To avoid badge click as select toggle
        && (event.target.localName != "a")                    // To avoid x in a badge click as select toggle
        ) {
          if ((event.target.labels === undefined) // For all others
          // || ((event.target.type == "checkbox") && (event.target.id == "item_checkbox")) // For checkbox
          ) {
            // console.log("toggle", this.isSelected);
            this.$emit('toggle-item', event, itemId);
          }
      } else {}
    },



    /**
     * Delete Item - Product/Addon/ProductGroup/AddonGroup.
     */
    deleteItem() {
      this.data.showOnly = false;
      this.$emit('delete-item', this.data.id);
    },

    /**
     * Update schedule.
     * @param {Object} data Schedule data object: Refer EMPTY_SCHEDULE.
     */
    updateSchedule(data) {
      // console.log(data);
      this.data.hideUntil = data.hideUntil;
      this.data.availableTime = data.availableTime;
      this.data.isSyncedInGroup = data.isSyncedInGroup;
      // console.log(this.data);
      this.$emit('update-schedule', this.data);
    },

     /**
     * Update status of the item
     * @param {string} status Status: "ACTIVE" or "INACTIVE"
     */
    updateStatus(status) {
      // ProudctGroup/AddonGroup
      if(this.isGroup){
        if (this.isProductsInGroup) {
          this.data.status = status;
          this.$emit('update-status',this.data);
        }
      }
      // Product/Addon
      else {
        this.data.status = status;
        this.$emit('update-status', this.data);
      }
    },

    /**
     * On click title.
     */
    onClickTitle() {
      if(this.cyPrefix.includes('reward')) {
        this.$emit('show-item');  // For Reward item show dialog
      } else {
        this.onShowItem();        // For Product show dialog
      }
    },

    /**
     * On click duplicate button.
     */
    onDuplicateItem() {
      // console.log('onDuplicateItem', this.data);
      if (this.isRewardItem){
        this.$emit('duplicate-item', this.data);
      } else {
        this.data.showOnly = false;
        let baseData = JSON.parse(JSON.stringify(this.data)); // Copy
        delete baseData.id;       // Id is removed for Add mode
        delete baseData.images;   // Images is removed since it needs to be selected again.
        baseData.name = baseData.name + "_copy"; // Edit the name to +_copy to indicate it's duplicated item.
        this.$emit('duplicate-item', baseData);
      }
    },
    /**
     * On click show button.
     */
    onShowItem(){
      // console.log("onShowItem", this.data);
      this.data.showOnly = true;
      this.$emit('edit-item');
      this.data.showOnly = false;
    },
    /**
     * On click add button.
     */
    onAddItem() {
      this.$emit('add-item', this.data);
    },
     /**
     * On click edit button.
     */
    onEditItem() {
      // console.log("onEditItem", this.data);
      this.data.showOnly = false;
      this.$emit('edit-item',this.data);
    },


    // Dialog operations - Schedule
    /**
     * Close Schedule dialog.
     */
    closeDialogSchedule() {
      this.dialogs.schedule = false;
    },
    /**
     * Open Schedule dialog.
     */
    openDialogSchedule() {
      this.dialogs.schedule = true;
    },
    /**
     * Submit from schedule dialog.
     * @param {Object} data Schedule data object: Refer EMPTY_SCHEDULE
     */
    onSubmitDialogSchedule(data) {
      this.updateSchedule(data);
    },

    // Dialog operations - Delete Alert
    /**
     * Close Alert dialog - delete
     */
    closeDialogAlert() {
      this.dialogs.alert = false;
    },
    /**
     * On Open alert dialog - delete
     */
    openDialogAlert() {
      this.dialogs.alert = true;
    },
    /**
     * On Submit alert dialog - delete
     */
    onSubmitDialogAlert() {
      this.deleteItem();
    },
    /**
     * Format number
     */
    formatNumber(num){
      const isToFixed = true;
      return formatNumber(num, isToFixed);
    }
  },
  mounted(){
  },
  created(){
    // console.log(this.data);
  },
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>
