// TODO : add in-code documentation :done

import { parse, serialize } from "./general.js";
import { isNullOrUndefined } from "../utils/general.js";
import {
  getDateFromDayYear,
  getDateFromWeekYear,
  getMonthFromMonthYear,
  getCurrentTime
} from "../utils/datetime";

const DETAIL_CHART_DATA = {
  label: "label",
  total: "total"
};

export function parseChartData(data) {
  let label = "";
  // Parse daily data
  if (!isNullOrUndefined(data.day)) {
    label = getDateFromDayYear(data.day, data.year);
  } // Parse weekly data
  else if (!isNullOrUndefined(data.week)) {
    label = getDateFromWeekYear(data.week - 1, data.year);
  }
  // Parse monthly
  else if (!isNullOrUndefined(data.month)) {
    label = getMonthFromMonthYear(data.month, data.year);
  }
  return parse({ label: label, total: data.total }, DETAIL_CHART_DATA);
}

/**  Serialize the chart data
 *
 * @param  {Object} data
 */
export function serializeChartData(data) {
  return serialize(data, DETAIL_CHART_DATA);
}

/**  Parsing the Real time chart data
 *
 * @param  {Object} data
 */
export function parseRealtimeChartData(data) {
  const label = getCurrentTime();
  return parse({ label: label, total: data }, DETAIL_CHART_DATA);
}
