<template>
  <b-dropdown
    data-cy="top-dropdown-serveOption"
    :text="serveOptionsLabel"
    variant="outline-dark" 
    class="mr-1 mb-1 btn-group " 
    size="xs" 
    no-flip
  >
    <b-dropdown-item 
      v-for="(serveOption,index) in serveOptions"
      :data-cy="`top-dropdown-serveOption-item-${index}`"
      :key="index" 
      @click="onClick(serveOption)"
      :active="serveOption.active"
    >
      {{ serveOption.text }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { ENUM_SERVE_OPTIONS_STRING } from "@/models/order";

export default {
  props: {
    serveOptions: {
      type: Array,
      validator: function (value) {
        return value && value.length > 0;
      },
      default: function () {
        return [
          { value: ENUM_SERVE_OPTIONS_STRING.PICKUP, text: this.$t('order.serve-options.pick-up'), active: false },
          { value: ENUM_SERVE_OPTIONS_STRING.DELIVERY, text: this.$t('order.serve-options.delivery'), active: false },
          { value: ENUM_SERVE_OPTIONS_STRING.DINE_IN, text: this.$t('order.serve-options.dine-in'), active: false },
        ]
      }
    }
  },
  components: {
    "v-select": vSelect,
  },
  methods: {
    onClick: function(filter) {

      /* Update active value for the serveOptions to highlight. */
      filter.active = !filter.active;

      // Send the values which are active.
      this.$emit('change', this.serveOptions);
    },
  },
  computed: {
    /**
     * Create a Label text for b-dropdown.
     * Returns string like: Option1, Option2
     */
    serveOptionsLabel: function () {
      const activeOptions = this.serveOptions && this.serveOptions.filter(option => option.active);
      const value = activeOptions && 
                    activeOptions.length > 0 
                    ?
                    activeOptions
                      .map(option => option.text)
                      .join(", ")
                    :
                    this.$t('order.serve-options.all');

      return `${this.$t('order.serve-options.serve-options')}: ${value}`
    }
  }
}
</script>