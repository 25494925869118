var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app-orders" } },
    [
      _c("top-nav", { attrs: { type: "app-orders" } }),
      _vm._v(" "),
      _c("OrdersSidebar", {
        staticClass: "custom-sidebar",
        attrs: { cyPrefix: `orders-sidebar` },
      }),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }