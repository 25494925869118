/**
 * It contains models in general
 */


/**
 * {Object} Model for operation time.
 */
export const MODEL_DAY_TIME = {
  day: "day",           // {string} Day of week: Refer to DAY_OF_WEEK_LONG.
  fromTime: "fromTime", // {string} From time: hh:mm.
  toTime: "toTime"      // {string} To time: hh:mm.
};

/**
 * Model for location. Refer to GeoJsonPoint.
 */
export const MODEL_LOCATION = {
  // TODO : It's center of location?
  x: 0.0, // {number} Longitude(double).
  y: 0.0, // {number} Latitude(double). lon
  coordinates: [0.0, 0.0], // {number[]} [0]:Longitude, [1]:Latitude.
  type: "Point" // {string} Type of location.
};

/**
 * Model for address.
 */
export const MODEL_ADDRESS = {
  streetNumber: 1,  // {number}   Street number, required.
  unit: null,       // {[number]} Unit number.
  streetName: "",   // {string}   Street name, required.
  postalCode: "",   // {string}   Postal code, required.
  city: "",         // {string}   City name, required.
  province: "",     // {string}   Province naem, required.
  country: "",      // {string}   Country name, required.
  location: {}      // {Object}   Location object, required: Refer to MODEL_LOCATION.
};

/**
 * Flatten an object.
 * @param {Object} data Object to flatten.
 * @return {Object} Flattened object.
 */
export const flatten = data => {
  var result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop + "[" + i + "]");
      if (l == 0) result[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "." + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
};
/**
 * Unflatten an object.
 * @param {Object} data Object to unflatten.
 * @return {Object} Unflattened object.
 */
export const unflatten = data => {
  "use strict";
  if (Object(data) !== data || Array.isArray(data)) return data;
  var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
    resultholder = {};
  for (var p in data) {
    var cur = resultholder,
      prop = "",
      m;
    while ((m = regex.exec(p))) {
      cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
      prop = m[2] || m[1];
    }
    cur[prop] = data[p];
  }
  return resultholder[""] || resultholder;
};


// TODO : Find right way to use.
//        Currently 'data' and 'model' has same props.
/**
 * Parse Data object based on Model object.
 * @param {Object} data  Data object.
 * @param {Object} model Model object.
 * @return {Object} Parsed object.
 */
export function parse(data, model) {
    let parsedItem = {};
    for (const [modelKey, serverKey] of Object.entries(model)) {
        parsedItem[modelKey] = data[serverKey];
    }
    return parsedItem;
}
/**
 * Serialize Data object based on Model object.
 * @param {Object} data  Data object.
 * @param {Object} model Model object.
 * @return {Object} Serialized object.
 */
export function serialize(data, model) {
    let serializedItem = {};
    for (const [modelKey, serverKey] of Object.entries(model)) {
        serializedItem[serverKey] = data[modelKey];
    }
    return serializedItem;
}
