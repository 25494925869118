// Dialog componet of AddonGroup for Add/EditShow
// Author : Huen Oh (heons921@gmail.com)
// @param {bool}    dialog  Handler variable for the dialog(Close and Open).
// @param {Object}  data    AddonGroup object: Refer to ADDON_GROUP_MODEL and EMPTY_ADDON_GROUP. It should be a copied object.
// TODO : may need @on-submit for further action.


<template>
  <!-- Dialog for AddonGroup -->
  <b-modal id="modalAddonGroup" ref="modalAddonGroup" size="lg" @hide="onCencel()">
    <!-- Emulate built in modal header with custom title and buttons -->
    <template v-slot:modal-header>
     <!-- Header Component -->
      <HeaderDialogProduct
        title="objects.addon-group"
        name="dlg-addon-group"
        :status="data.status"
        :disabled="data.showOnly || (addonsInGroup.length <= 0)"
        :actionType="actionType"
        @changeStatus="updateStatus"
      />
    </template>

    <b-form>
      <!-- Dialog for Alert -->
      <dialog-alert
        :dialog="dialogs.alert"
        :description="$t('alert.no-change')"
        :actionType="actionType"
        :buttonSubmitText="$t('buttons.ok')"
        @dialog-close="closeDialogAlert"
        @dialog-submit="onSubmitDialogAlert"
      />
      <!-- Dialog for Addon -->
      <dialog-addon
        :dialog="dialogs.addon"
        :data="currentAddon"
        @dialog-close="closeDialogAddon"
      />
      <b-row>
        <!-- Left -->
        <b-colxx sm="6">
          <!-- Name and Description -->
          <EditNameDescription
            name="dlg-addon-group"
            label="addon-group"
            :data="data"
            :disabled="data.showOnly"
            :isFirstLoad="isFirstLoad"
          />
          <!-- Optional/Mandatory Selection -->
          <b-button-group class="mb-2">
            <b-button
              variant="primary" 
              class="pl-5 pr-5" 
              data-cy="dlg-addon-group-btn-optional"
              @click="onClickSelectedOptionTypeBtn(1)"
              :pressed="selectedOptionType==1"
            >
              {{$t('buttons.optional')}}
            </b-button>
            <b-button
              data-cy="dlg-addon-group-btn-mandatory" 
              variant="primary" 
              class="pr-5 pl-5" 
              @click="onClickSelectedOptionTypeBtn(2)" 
              :pressed="selectedOptionType==2"
            >
              {{$t('buttons.mandatory')}}
            </b-button>
          </b-button-group>

          <!-- Checkbox for isSameAddonAllowed -->
          <b-form-checkbox 
            data-cy="dlg-addon-group-btn-check-same-allowed"
            v-model="isSameAddonAllowed" 
            class="mb-3 mt-3" 
            :disabled="(data.showOnly)"
          >
            {{$t('addon-group.place-holder.checkbox')}}
          </b-form-checkbox>
          
          <!-- Force Min/Max Selection -->
          <b-row>
            <b-colxx sm="5">
              <label class="form-group has-float-label">
              <input
                data-cy="dlg-addon-group-input-force-minimum" 
                type="number" 
                class="form-control" 
                v-model="forcedMin" 
                @change="onChangeForcedMin" 
                :disabled="(data.showOnly)"
              >
              <span>{{$t('objects.labels.force-minimum')}}</span>
            </label>
            </b-colxx>
            <b-colxx sm="5">
            <label class="form-group has-float-label">
              <input 
                data-cy="dlg-addon-group-input-force-maximum"
                type="number" 
                class="form-control" 
                v-model="forcedMax" 
                @change="onChangeForcedMax" 
                :disabled="(data.showOnly)"
              >
              <span>{{$t('objects.labels.force-maximum')}}</span>
            </label>
            </b-colxx>
          </b-row>
        </b-colxx>
        <!-- Right -->
        <b-colxx sm="6">
          <!-- Image Selection -->
          <b-form-group>
            <h6>{{$t('product.choose-image')}}</h6>
            <ImagesSelection
              name="dlg-addon-group"
              v-model="isChanged"
              :images="data.images"
              :single="true"
              :disabled="data.showOnly"
              key="selImage"
              ref="selImage"
            />
          </b-form-group>
        </b-colxx>
      </b-row>

      <b-row align-v="end" class="mt-2">
        <!-- Left -->
        <BaseSelectItem
          :colSize="sizeColMain"
          :disabled="data.showOnly"
          :title="$t('addon-group.add-addon-to-group')"
          cyPrefix="dlg-addon-group"
          cySuffix="addon"
          :options="currentSelAddonOptions"
          :noOptionsMessage="$t('validations.no-add-on-available')"
          @input="addAddonToGroup"
        />
        <!-- Right -->
        <b-colxx sm="6">
          <!-- Search -->
          <div class="search-sm d-inline-block float-md-right m-3">
            <b-input 
              data-cy="dlg-addon-group-input-search"
              :placeholder="$t('menu.search')" 
              v-model="searchKeyword"
            />
          </div>
        </b-colxx>
      </b-row>

      <!-- Table List for added addons -->
      <TableListProducts
        cyPrefix="dlg-addon-group"
        cySuffix="addons"
        :items="searchedAddonsInGroup"
        :disabled="data.showOnly"
        :selectableGroups="selectableAddonGroups"
        @changeStatus="updateOneAddon" 
        @editItem="onEditAddon"
        @deleteItem="onDeleteAddon" 
      /> 
    </b-form>

    <!-- Footer -->
    <template slot="modal-footer">
      <FooterDialogProduct
        name="dlg-addon-group"
        :disabled="$v.data.$invalid && !isFirstLoad"
        :actionType="actionType"
        @click-cancel="onCencel"
        @click-submit="onSubmit"
      />
    </template>
  </b-modal>
</template>


<script>
import vSelect from "vue-select"
import DialogAlert          from '@/components/Common/DialogAlert'
import ImagesSelection      from '@/components/Common/ImagesSelection'
import HeaderDialogProduct  from '@/components/Common/HeaderDialogProduct'
import FooterDialogProduct  from '@/components/Common/FooterDialogProduct'
import TableListProducts    from '@/components/Common/TableListProducts'
import BaseSelectItem       from '@/components/Common/BaseSelectItem'
import ToggleButtonStatus   from '@/components/Forms/ToggleButtonStatus'
import EditNameDescription  from '@/components/Forms/Editbox/EditNameDescription'

import { getNamesByIdsFromList, compareStringInt, isResponseCREATED } from '@/utils/general'

// Notifications
import {
  addNotifySuccess,
  addNotifyWarning,
  addNotifyFail,
} from "@/utils/notify.js"

// For validation
import { validationMixin } from "vuelidate";
const { required, minLength, maxLength } = require("vuelidate/lib/validators");
import { mapGetters, mapActions } from 'vuex'
export default {
  components:{
    vSelect,
    DialogAlert,
    ImagesSelection,
    ToggleButtonStatus,
    EditNameDescription,
    HeaderDialogProduct,
    FooterDialogProduct,
    BaseSelectItem,
    TableListProducts,
    DialogAddon: () => import('@/components/Products/DialogAddon'), // Async import
  },
  props:{
    // {bool} Handler variable for the dialog(Close and Open) default false.
    dialog:{
      type:Boolean,
      default:false,
    },
    // {Object} AddonGroup object: Refer to ADDON_GROUP_MODEL and EMPTY_ADDON_GROUP. It should be a copied object.
    data:{
      type:Object,
      default:()=> {},
    }
  }, 
  data(){
    return{
      sizeColMain:10,   // {number}   Size of the column for forms.
      actionType: "",   // {string}   Type of action : "Add", "Edit" or "Show"
      isChanged: false, // {boolean}  Flag if anything has changed in the dialog.
      isfirstWatch: {   // {Object}   Flags if it the first watch to detemine isChanged flag.
        data: true,
      },
      isStatusChanged: false, // {boolean} To check status is changed. To not to update unintentionally edits //TODO : move to the component
      dialogs: {              // {Object} Handler object for dialogs(Close and Open).
        alert:false,
        addon:false,
      },
      currentAddon:{},  // {Object} Current Addon data for Addon dialog. Refer to ADDON_GROUP_MODEL and EMPTY_ADDON_GROUP.
      
      selAddon:{},      // {Object} Selected Addon to add.
      addedAddons:[],   // {Object[]} Added Addons by Addon Selection to add.
      searchKeyword: "",        // {string} Search keyword to search Products in the table.
      selectedOptionType: 1,    // {number} Selected Type of the AddonGroup - 1:Optional, 2:Mandatory // TODO : enum
      isSameAddonAllowed: true, // {boolean} To check if adding same addon multiple times is allowed.
      forcedMin: 0,             // {number} Force Min value.
      forcedMax: 0,             // {number} Force Max value.

      // For the changes in the table.
      isTableChanged: false, // {boolean} Flag if there is a change in the table.
      copiedProducts: [],    // {string[]) Copied productIds from to be comared with addAddonList in duplication mode to update isTableChanged.

      // is First Load, in order to not display validation message at first
      isFirstLoad: true
    }
  },
  // Validations
  mixins: [validationMixin],
  validations: {
    data: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(35)
      },
      description: {
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
    }
  },
  methods: {
    // Get names(labels) where [list].value == [ids]
    getNamesByIdsFromList,
    
    // Notifications.
    addNotifySuccess,
    addNotifyWarning,
    addNotifyFail,
    
    // Map actions.
    ...mapActions('addon', [
      "updateOneAddon",
      "resetAddonFilters",
      "addOneFilteredAddon",
      "deleteOneFilteredAddon"
    ]),
    ...mapActions("addon_group", [
      "addOneAddonGroupWithImage", 
      "updateOneAddonGroupWithImage", 
      "applyAddonGroupFilters",
      ]),
    /**
     * Reset all variables.
     */
    reset() {
      this.isChanged = false;
      this.isfirstWatch.data = true;
      this.isStatusChanged = false;
      this.addedAddons = [];
      this.selectedOptionType = 1;
      this.isSameAddonAllowed = true;
      this.forcedMin = 0;
      this.forcedMax = 0;

      this.isTableChanged = false;
      this.copiedProducts = [];
    },
    /**
     * Initialize the dialog with input data.
     */
    initDialog() {
      // console.log('initDialog', this.data);
      // Reset
      this.reset();
      if(this.data.id) {
        // Reset the Addon filters to show the list of Addons in an AddonGroup
        this.resetAddonFilters({group:{value:this.data.id, label:this.data.name}});
      }

      // TODO : It is a temporary fix. We need to find at which point the products becomes null.
      // Check the products and make it empty if it is not a list.
      if (!Array.isArray(this.data.products)) {
        this.data.products = [];
      } else {}

      // Update the list of Addons on duplication
      if(!this.data.id) {
        if(this.data.products.length > 0) {
          for(let addonId of this.data.products) {
            const addon = this.findAddonById(addonId);
            if(addon) {
              this.data.addAddonList.push(addonId);
              this.addedAddons.push(addon); 
              this.copiedProducts.push(addonId);
            }
          }
          this.data.products = [];
        }
      }
      // Set title
      if (this.data.showOnly) {
        this.actionType = this.$t('actions.show');
      } else if (this.data.id) {
        this.actionType = this.$t('actions.edit');
      } else {
        this.actionType = this.$t('actions.add');
      }
      
      // Forced Min and Max
      this.forcedMin = this.data.forcedMin;
      this.forcedMax = this.data.forcedMax;

      // Get if same item selection is allowed.
      this.isSameAddonAllowed = this.data.isSameAddonAllowed == true;
    },
    /**
     * On Cencel button : Close the dialog.
     */
    onCencel() {
      this.isFirstLoad = true;

      this.$emit('dialog-close');
    },
    /**
     * On Submit button : Save the data and Close the dialog.
     */
    onSubmit() {
      this.isFirstLoad = false;

      // Not submit the form if fail validation
      if(this.$v.$invalid){
        return;
      }

      // console.log('onSubmit', this.data);
      if(this.data.showOnly === true) {
        // Show -> Edit
        this.data.showOnly = false;
        this.actionType = this.$t('actions.edit');
      } else {
        if(this.data.id) { //Edit
          this.submitChanges();
        } else {
          // Add
          if(this.isChanged || this.isTableChanged) {
            this.submitChanges();
          } else {
            // There is no change, are you sure?
            this.openDialogAlert();
          }
        }
      }
    },
    /**
     * Submit changes of the dialog.
     */
    submitChanges() {
      // console.log("onSubmit", this.data);
      // Set image object for the change.
      let imageObject = null;
      const imageObjects = this.$refs.selImage.getImageObjects(); 
      if(imageObjects.length > 0) {
        imageObject = imageObjects[0];
      } else {}
      // console.log('submitChanges', imageObject);

      // Set isSameAddonAllowed;
      this.data.isSameAddonAllowed = this.isSameAddonAllowed;

      // Select what to do: Update or Add by id
      if(this.data.id != undefined) {
        // Update - PUT
        // console.log('submitChanges', this.data);
        if(!this.isStatusChanged) {
          delete this.data.status;
        }
        this.updateAddonGroup(this.data, imageObject);
        // TODO : add emit on-submit?
      } else {
        // Add - POST
        // Add an AddonGroup with images.
        // If there is no images, an AddonGroup will be added without image
        this.addAddonGroup(this.data, imageObject);
        // TODO : add emit on-submit?
      }
      this.$emit('dialog-close');
    },
    /**
     * Add an AddonGroup to the server.
     * @param {Object} addonGroup   AddonGroup object: Refer to ADDON_GROUP_MODEL and EMPTY_ADDON_GROUP.
     * @param {Object} imageObject  Image object: {files, preview}
     */
    async addAddonGroup(addonGroup, imageObject) {
      addonGroup.products = addonGroup.addAddonList;
      let response = await this.addOneAddonGroupWithImage({group:addonGroup, imageObject:imageObject});
      if (isResponseCREATED(response)) {
        this.applyAddonGroupFilters();
        this.addNotifySuccess(this.$t('notify.addon-group-add'));
        this.$emit('onAddItem', response.data.id);
      } else {
        this.addNotifyFail(this.$t('notify.addon-group-add'));
      }
    },
    /**
     * Update an AddonGroup to the server.
     * @param {Object} addonGroup   AddonGroup object: Refer to ADDON_GROUP_MODEL and EMPTY_ADDON_GROUP.
     * @param {Object} imageObject  Image object: {files, preview}
     */
    async updateAddonGroup(addonGroup, imageObject){
      // console.log("updateAddonGroup", addonGroup);
      await this.updateOneAddonGroupWithImage({group:addonGroup, imageObject:imageObject});
      this.applyAddonGroupFilters();
      this.addNotifySuccess(this.$t('notify.addon-group-update'));
    },
    /**
     * Update status of the item
     * @param {string} status Status: "ACTIVE" or "INACTIVE"
     */
    updateStatus(status) {
      this.data.status = status;
      this.isStatusChanged = true;
    },
    // Dialog operations - Addon
    /**
     * Close Addon dialog - Add.
     */
    closeDialogAddon() {
      this.dialogs.addon = false;
    },
    /**
     * Open Addon dialog - Add.
     * @param {Objec} addon Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
     */
    openDialogAddon(addon) {
      this.currentAddon = JSON.parse(JSON.stringify(addon)); // Copy
      this.dialogs.addon = true;
    },
    // onSubmitDialogAddon() in dialog.
    // Dialog operations - Unchanged submit Alert
    /**
     * Close Alert dialog - Unchanged submit.
     */
    closeDialogAlert() {
      this.dialogs.alert = false;
    },
    /**
     * Open Alert dialog - Unchanged submit.
     */
    openDialogAlert() {
      this.dialogs.alert = true;
    },
    /**
     * On Submit Alert dialog - Unchanged submit.
     * Submit the changes.
     */
    onSubmitDialogAlert() {
      this.submitChanges();
    },
    /* Conditions between optioni type and force min/max.
      Min = 0 -> Optional
      Min > 0 -> Mandatory
      Max <=1 -> Not Multiple
      Max >1 -> multiple
      Always: Min <= Max, Min and Max >=0
    /*
    /**
     * On click selected option type button
     * @param {int} val Selected option type: (1:Optional, 2:Mandatory)
     */
    onClickSelectedOptionTypeBtn(val) {
      if(!this.data.showOnly) {
        this.selectedOptionType = val;
      } else {}
    },
    /**
     * On change force minimum.
     */
    onChangeForcedMin() {
      // console.log("onChangeForcedMin");
      // Bound min and max then assign to the data
      this.forcedMin = (this.forcedMin < 0) ? 0:this.forcedMin;
      this.forcedMax = compareStringInt(this.forcedMax, this.forcedMin) ? this.forcedMin : this.forcedMax;
      this.data.forcedMin = this.forcedMin;
      this.data.forcedMax = this.forcedMax;
      // console.log('onChangeForcedMin',this.data.forcedMin);
    },
    /**
     * On change force maximum.
     */
    onChangeForcedMax() {
      // console.log("onChangeForcedMax");
      // Bound max and min then assign to the data
      this.forcedMax = (this.forcedMax < 0) ? 0:this.forcedMax;
      this.forcedMin = compareStringInt(this.forcedMax, this.forcedMin) ? this.forcedMax : this.forcedMin;
      this.data.forcedMin = this.forcedMin;
      this.data.forcedMax = this.forcedMax;
      // console.log('onChangeForcedMax',this.data.forcedMin);
    },
    // Actions in the list of Addons.
    /**
     * Change an Addon' status to the server.
     * @param {Object} addon  Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
     */
    onChangeAddonStatus(addon) {
      // TODO : revise with updateStatus()
      const refName = 'toggle-status-' + addon.id;
      addon.status = this.$refs[refName].getSwitchValueToString();
      this.updateOneAddon(addon);
    },
    /**
     * Open Addon dialog to edit.
     * @param {Object} addon  Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
     */
    onEditAddon(addon) {
      // console.log('onEditAddon', addon);
      addon.showOnly = this.data.showOnly;
      this.openDialogAddon(addon);
    },
    /**
     * Change an Addon locally..
     * @param {string} addonId  Id of an Addon object.
     */
    onDeleteAddon(addonId) {
      // console.log('onDeleteAddon', addonId);
      const idx = this.data.addAddonList.findIndex(el => el==addonId);
      if(idx != -1) {
        this.data.addAddonList.splice(idx, 1);
        this.addedAddons.splice(idx, 1);
      } else {
        // To check addon's groups
        const addon = this.findAddonById(addonId);
        if (addon.groups.length === 1) {
          this.addNotifyWarning(this.$t('notify.one-group-required'));
        } else {
          // To delete
          this.deleteOneFilteredAddon(addonId);
          this.data.deleteAddonList.push(addonId);
        }
      }

      // Update isTableChanged value.
      this.updateIsTableChanged();
    },
    addAddonToGroup(selAddon) { //TODO : may not need - watch.selAddon
        // console.log("addAddonToGroup", selAddon);

        if ((selAddon != null) && (selAddon.value !== "")){
          const addonId = this.data.products.find(el => el === selAddon.value);
          if(!addonId) {
            const addon = this.findAddonById(selAddon.value);
            this.addedAddons.push(addon); // To display // TODO : color?
            // added to addon list to be added
            this.data.addAddonList.push(selAddon.value);
            
          } else {
            const deletedAddonIdx = this.data.deleteAddonList.findIndex(el => el === selAddon.value);
            // It is being resotred. Remove it from deleteAddonList
            if(deletedAddonIdx != -1) {
              this.addOneFilteredAddon(selAddon.value);
              this.data.deleteAddonList.splice(deletedAddonIdx, 1);
            } else {}
          }

          // Update isTableChanged value.
          this.updateIsTableChanged();
        } else {}    
    
    },
    // TODO : find way to use either computed or watch. - tried and failed once.
    /**
     * Update isTableChanged based on the table changes.
     */
    updateIsTableChanged() {
      let changed = false;
      if(!this.data.id) {
        if(this.copiedProducts.length > 0) {
          // TODO : Make it as general function.
          // Compare products and addAddonList
          if(this.copiedProducts.length === this.data.addAddonList.length) {
            this.copiedProducts.forEach((productId) => {
              if(this.data.addAddonList.includes(productId)) {
                // Nothing.
                // console.log('changed-false', changed)
              } else {
                // console.log('changed-true', changed)
                changed = true;
              }
            })
          } else {
            changed = true;
          }
        } else {
          changed = (this.data.addAddonList.length > 0) || (this.data.deleteAddonList.length > 0);
        }
      } else {
        changed = (this.data.addAddonList.length > 0) || (this.data.deleteAddonList.length > 0);
      }

      this.isTableChanged = changed;
      // console.log('this.data.addAddonList', this.data.addAddonList)
      // console.log('this.data.deleteAddonList', this.data.deleteAddonList)
      // console.log('this.isTableChanged', this.isTableChanged)
    }
  },

  computed:{
    // Map getters
    ...mapGetters("addon", [
      "addons", 
      "selectableAddons", 
      "filteredAddons", 
      "filters", 
      "findAddonById",
    ]),
    ...mapGetters("addon_group", ["selectableAddonGroups"]),
    // Addons in a AddonGroup
    addonsInGroup(){
      if(this.data.id) {
        // Edit/Show
        return [...this.filteredAddons, ...this.addedAddons];
      } else {
        // Add
        return [...this.addedAddons];
      }
    },
    // Searched Addons in a AddonGroup
    searchedAddonsInGroup() {
      return this.addonsInGroup.filter((addon) => {
        return addon.name.toLowerCase().includes(this.searchKeyword.toLowerCase());
      })
    },
    // Computed value to exclude selected items from the selection option.
    currentSelAddonOptions: function() {
      return this.selectableAddons.filter((item) => {
        return !this.addonsInGroup.map((addon) => {
          return addon.id;
        }).includes(item.value) && (item.value != "");
      });
    },
  },
  watch: {
    /**
     * Watch to control the dialog: Show, Hide.
     * @param {bool} newVal New value.
     * @param {bool} oldVal Old value.
     */
    dialog: function(newVal, oldVal){
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if(newVal == true) {
        this.initDialog();
        this.$refs["modalAddonGroup"].show();
      } else {
        this.$refs["modalAddonGroup"].hide();
      }
    },
    /**
     * Watch to check changes in the data.
     */
    data: {
      deep: true,
      // immediate: true,
      handler() {
        // console.log("watch data changed");
        if(this.isfirstWatch.data == false) {
          if(this.isChanged == false) {
            this.isChanged = true;
            // console.log("watch data changed");
          }
        } else {
          // console.log("watch data changed", this.data);
          this.isfirstWatch.data = false;
        } 
        // Change selected option type by forcedMin
        if(this.forcedMin == 0) {
          this.selectedOptionType = 1;
        } else if (this.data.forcedMin > 0) {
          this.selectedOptionType = 2;
        } else {
          console.log("Error value in forcedMin");
        }
      },
    },
   
    /**
     * Watch to add an Addon in an AddonGroup.
     
    selAddon: {
      deep: true,
      handler() {
        if ((this.selAddon != null) && (this.selAddon.value !== "")){
          const addonId = this.data.products.find(el => el === this.selAddon.value);
          console.log(addonId);
          if(!addonId) {
            const addon = this.findAddonById(this.selAddon.value);
            console.log(addon);
            this.addedAddons.push(addon); // To display // TODO : color?
            // added to addon list to be added
            this.data.addAddonList.push(this.selAddon.value);
            
          } else {
            const deletedAddonIdx = this.data.deleteAddonList.findIndex(el => el === this.selAddon.value);
            // It is being resotred. Remove it from deleteAddonList
            if(deletedAddonIdx != -1) {
              this.addOneFilteredAddon(this.selAddon.value);
              this.data.deleteAddonList.splice(deletedAddonIdx, 1);
            } else {}
          }

          // Reset selection.
            this.selAddon = null;
          // console.log(addon);

          // Update isTableChanged value.
          this.updateIsTableChanged();
        } else {}
      }
    },
    **/
    /**
     * Watch for selected option type changes.
     */
    selectedOptionType: function() {
      // forcedMin = 0 -> Optional
      // forcedMin > 0 -> Mandatory
      // Always: Min <= Max, Min and Max >=0
      if (this.selectedOptionType == 1) {
        if(this.data.forcedMin != 0) {
          this.forcedMin = 0;
          this.data.forcedMin = 0;
          // console.log(this.data.forcedMin);
        } else {}
      } else if (this.selectedOptionType == 2) {
        if(this.data.forcedMin <= 0) {
          this.forcedMin = 1;
          this.data.forcedMin = 1;
          // console.log('selectedOptionType', this.data.forcedMin);
          if (this.data.forcedMax <= 0) {
            this.forcedMax = 1;
            this.data.forcedMax = 1;
          } else {}
        } else {}
      } else {}
    },
  },
  mounted(){ 
    
  }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>