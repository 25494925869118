/**
 * It contains store module for AddonGroups.
 */

import {
  getAddonGroupsApi,
  addAddonGroupApi,
  addAddonGroupWithImageApi,
  deleteAddonGroupApi,
  updateAddonGroupApi,
  updateAddonGroupWithImageApi,
  addAddonsInGroupApi,
  deleteAddonsInGroupApi,
} from "@/api/product/addon_groups.js";

import { parseAddonGroup, parseAddonGroups } from "@/models/products/addon_group.js";
import { getListForDropDownMenu, isResponseOK, isResponseCREATED } from "@/utils/general.js"
import { sortInOrder }            from "@/utils/sort.js"

import { ENUM_STATUS_STRING } from "@/constants/common";

export default {
  namespaced: true,
  state: {
    addonGroups: [],              // List of AddonGroups: Refer to ADDON_GROUP_MODEL.
    filteredAddonGroups: [],      // List of filtered AddonGroups: Refer to ADDON_GROUP_MODEL.
    selectableAddonGroups: [],    // List of selectable AddonGroups: {value:id, label:name, text:name}
    currentAddonGroupIndex: -1,   // Index of current AddonGroup
    filters: {                    // Filters
      order: { value: "", label: "", isAscending: true, },
      keyword: "",
    },
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.addonGroups = [];
      state.filteredAddonGroups = [];
      state.selectableAddonGroups = [];
      state.currentAddonGroupIndex = -1;
      state.filters = {
        order: { value: "", label: "", isAscending: true, },
        keyword: "",
      };
    },
    /**
     * Set the index of current AddonGroup.
     * @param {Object}  state Local State object.
     * @param {number}  index Index of current AddonGroup.
     */
    setCurrentAddonGroupIndex(state, index) {
      state.setCurrentAddonGroupIndex = index
    },
    /**
     * Set all AddonGroups in the state.
     * @param {Object} state        Local State object.
     * @param {Object} addonGroups  List of AddonGroup objects: Refer to ADDON_GROUP_MODEL.
     */
    setAllAddonGroups(state, addonGroups) {
      state.addonGroups = addonGroups;
    },
    /**
     * Delete an AddonGroup in the state.
     * @param {Object} state Local State object.
     * @param {number} index Index of an AddonGroup in the list.
     */
    deleteOneAddonGroup(state, index) {
      state.addonGroups.splice(index, 1);
    },
    /**
     * Add an AddonGroup in the state.
     * @param {Object} state  Local State object.
     * @param {Object} group  AddonGroup object: Refer to ADDON_GROUP_MODEL.
     */
    addOneAddonGroup(state, group) {
      state.addonGroups.push(group);
    },
    /**
     * Upate an AddonGroup in the state.
     * @param {Object} state  Local State object.
     * @param {Object} group  AddonGroup object: Refer to ADDON_GROUP_MODEL.
     * @param {number} index  Index of an AddonGroup in the list.
     */
    updateOneAddonGroup(state, { group, index }) {
      state.addonGroups[index] = {
        ...state.addonGroups[index],
        ...group
      };
    },
    /**
     * Set selectable AddonGroup in the state.
     * @param {Object} state Local State object.
     */
    setSelectableAddonGroups(state) {
      state.selectableAddonGroups = getListForDropDownMenu(state.addonGroups);
    },
    /**
     * Delete a filtered AddonGroup in the state.
     * @param {Object} state  Local State object.
     * @param {number} index  Index of a filtered AddonGroup in the list.
     */
    deleteOneFilteredAddonGroup(state, index) {
      state.filteredAddonGroups.splice(index, 1);
    },
    /**
     * Set AddonGroup filters in the state.
     * @param {Object} state    Local State object.
     * @param {Object} filters  Filter object.
     */
    setAddonGroupFilters(state, filters) {
      state.filters = {
        ...state.filters,
        ...filters,
      }
    },
    /**
     * Apply AddonGroup filters.
     * @param {Object} state Local State object.
     */
    applyAddonGroupFilters(state) {
      // console.log("applyAddonGroupFilters", state.filters);
      // Apply filters
      // 1. Copy to filtered AddonGroups
      state.filteredAddonGroups = JSON.parse(JSON.stringify(state.addonGroups));

      // 2. Name in search
      if (state.filters.keyword != "") {
        state.filteredAddonGroups = state.filteredAddonGroups.filter(
          element => element.name.toLowerCase().includes(state.filters.keyword.toLowerCase())
        );
      }
      // 3. Order
      sortInOrder(state.filteredAddonGroups, state.filters.order);
    },

  },
  getters: {
    /**
     * Get isAddonGroupsSet
     * @return {bool} True if AddonGroups are set, False otherwise.
     */
    isAddonGroupsSet: (state) => {
      return state.addonGroups.length > 0;
    },
    /**
     * Get AddonGroup in the state.
     */
    addonGroups: (state) => {
      return state.addonGroups;
    },
    /**
     * Get selectable AddonGroup in the state.
     */
    selectableAddonGroups: (state) => {
      return state.selectableAddonGroups;
    },
    /**
     * Get filtered AddonGroup in the state.
     */
    filteredAddonGroups: (state) => {
      return state.filteredAddonGroups;
    },
    /**
     * Get filters in the state.
     */
    filters: (state) => {
      return state.filters;
    },
    
    /**
     * Get current AddonGroup in the state.
     */
    currentAddonGroup: (state) => {
      const index = state.currentAddonGroupIndex;
      return state.addonGroups[index >= 0 ? index : 0]; // what if there is no addonGroups?
    },
    /**
     * Get an AddonGroup by Id.
     * @param {string} id Id of an AddonGroup.
     */
    findAddonGroupById: (state) => (id) => {
      return state.addonGroups.find(
        group => group.id === id
      );
    },
    /**
     * Get an index of an AddonGroup by Id.
     * @param {string} id Id of an AddonGroup.
     */
    findAddonGroupIndexById: (state) => (id) => {
      return state.addonGroups.findIndex(
        group => group.id === id
      );
    },
    /**
     * Get AddonGroups by an Addon Id.
     * @param {string} id Id of an Addon.
     */
    findAddonGroupByAddonId: (state) => (id) => {
      return state.addonGroups.filter(
        group => group.products.includes(id)
      );
    },
  },
  actions: {
    /**
     * Load AddonGroups.
     * 
     * Work flow.
     * 1. Load AddonGroups from the sever.
     * 2. Set selectable AddonGroups.
     * 3. Query status of all the Addons in the AddonGroups and set as AddonGroups' status.
     */
    async loadAddonGroups({ commit, dispatch }) {
      const response = await getAddonGroupsApi();
      if (isResponseOK(response)) {
        const addonGroups = parseAddonGroups(response.data);
        // Set all AddonGroups
        commit("setAllAddonGroups", addonGroups);
        // Set selectable AddonGroups
        commit("setSelectableAddonGroups");
        // Set status of AddonGroups by checking all the status of the Addons.
        dispatch("getQueriedStatus");
      } else {
        console.log("[Error]loadAddonGroups:");
      }
      return response;
    },
    /**
     * Update an AddonGroup.
     * 
     * Work flow.
     * 1. Delete Addons in an AddonGroup if there are any.
     * 2. Add Addons in an AddonGroup if there are any.
     * 3. Re-load all the Addons if any Addons are deleted of added in an AddonGroup.
     * 4. Update an AddonGroup.
     * 5. Update status of all the Addons in an AddonGroups according to the AddonGroup update.
     * 6. Set selectable AddonGroups.
     * 7. Query status of all the Addons in the AddonGroups and set as AddonGroups' status.
     * @param {Object} group AddonGroup object: Refer to ADDON_GROUP_MODEL.
     */
    async updateOneAddonGroup({ commit, getters, dispatch }, group) {
      // Index of the AddonGroup
      const index = getters.findAddonGroupIndexById(group.id);

      // TODO : Seperate it
      // Delete Addons from the AddonGroup
      let isDel = false;
      if (group.deleteAddonList) {
        if (group.deleteAddonList.length > 0) {
          const response_del = await deleteAddonsInGroupApi(group.id, group.deleteAddonList);
          if (isResponseOK(response_del)) {
            group.products = response_del.data.products;
            isDel = true;
          } else { }
        } else { }
      } else { }
      
      // TODO : Seperate it
      // Add Addons from the AddonGroup
      let isAdd = false;
      if (group.addAddonList) {
        if (group.addAddonList.length > 0) {
          const response_add = await addAddonsInGroupApi(group.id, group.addAddonList);
          if (isResponseOK(response_add)) {
            group.products = response_add.data.products;
            isAdd = true;
          } else { }
        } else { }
      } else { }

      // Re-load the Addons
      if (isDel || isAdd) {
        // All the addon of those updated by addon group operation should be re-fetched
        // since it is only updated in the server.
        // TODO : load onnly those updated.
        dispatch('addon/loadAddons', null, { root: true });
      }
      
      // Update the AddonGroup
      const response = await updateAddonGroupApi(group);
      if (isResponseOK(response)) {
        // Update status of the Addons in the AddonGroup
        dispatch("updateQueriedStatus", group);

        // Update the AddonGroup
        commit(
          "updateOneAddonGroup",
          {
            group: parseAddonGroup(response.data),
            index: index
          }
        );
        // Set selectable AddonGroups
        commit("setSelectableAddonGroups");
        // Set status or AddonGroups by checking all the status of the Addons.
        dispatch("getQueriedStatus");
      } else {
        console.log("[Error]updateOneAddonGroup:");
      }
      return response;
    },
    /**
     * Update an AddonGroup with images.
     *
     * Work flow.
     * 1. Delete Addons in an AddonGroup if there are any.
     * 2. Add Addons in an AddonGroup if there are any.
     * 3. Re-load all the Addons if any Addons are deleted of added in an AddonGroup.
     * 4. Update an AddonGroup.
     * 5. Update status of all the Addons in an AddonGroups according to the AddonGroup update.
     * 6. Set selectable AddonGroups.
     * 7. Query status of all the Addons in the AddonGroups and set as AddonGroups' status.
     * @param {Object}  group         AddonGroup object: Refer to ADDON_GROUP_MODEL.
     * @param {Object}  [imageObject] Image objects: {files, preview}
     */
    async updateOneAddonGroupWithImage({ commit, getters, dispatch }, { group, imageObject }) {
      // Index of the AddonGroup
      const index = getters.findAddonGroupIndexById(group.id);

      // TODO : Seperate it
      // Delete Addons from the AddonGroup
      let isDel = false;
      if (Array.isArray(group.deleteAddonList)) {
        if (group.deleteAddonList.length > 0) {
          const response_del = await deleteAddonsInGroupApi(group.id, group.deleteAddonList);
          if (isResponseOK(response_del)) {
            group.products = response_del.data.products;
            isDel = true;
          } else { }
        } else { }
      } else { }

      // TODO : Seperate it
      // Add Addons from the AddonGroup
      let isAdd = false;
      if (Array.isArray(group.addAddonList)) {
        if (group.addAddonList.length > 0) {
          const response_add = await addAddonsInGroupApi(group.id, group.addAddonList);
          if (isResponseOK(response_add)) {
            group.products = response_add.data.products;
            isAdd = true;
          } else { }
        } else { }
      } else { }
      

      // Re-load the Addons
      if (isDel || isAdd) {
        // All the addon of those updated by addon group operation should be re-fetched
        // since it is only updated in the server.
        // TODO : load onnly those updated.
        dispatch('addon/loadAddons', null, { root: true });
      }

      // Update the AddonGroup
      const response = await updateAddonGroupWithImageApi(group, imageObject);
      if (isResponseOK(response)) {
        // Update status of the Addons in the AddonGroup
        dispatch("updateQueriedStatus", group);

        // Update the AddonGroup
        commit(
          "updateOneAddonGroup",
          {
            group: parseAddonGroup(response.data),
            index: index
          }
        );
        // Set selectable AddonGroups
        commit("setSelectableAddonGroups");
        // Set status or AddonGroups by checking all the status of the Addons.
        dispatch("getQueriedStatus");
      } else {
        console.log("[Error]updateOneAddonGroupWithImage:");
      }
      return response;
    },
    /**
     * Delete an AddonGroup.
     * 
     * Work flow.
     * 1. Delete an AddonGroup.
     * 2. Set selectable AddonGroups.
     * 3. Re-load all the Addons.
     * @param {string} id Id of an AddonGroup.
     */
    async deleteOneAddonGroup({ commit, getters, dispatch }, id) {
      const response = await deleteAddonGroupApi(id);
      if (isResponseOK(response)) {
        commit("deleteOneAddonGroup", getters.findAddonGroupIndexById(id));
        // Set selectable AddonGroups
        commit("setSelectableAddonGroups");
        // Re-load the Addons
        dispatch('addon/loadAddons', null, { root: true });
      } else {
        console.log("[Error]deleteOneAddonGroup:");
      }
      return response;
    },
    /**
     * Add an AddonGroup.
     * 
     * Work flow.
     * 1. Add an AddonGroup.
     * 2. Set selectable AddonGroups.
     * 3. Re-load all the Addons if any Addons are added in an AddonGroup.
     * @param {Object} group AddonGroup object: Refer to ADDON_GROUP_MODEL.
     */
    async addOneAddonGroup({ commit, dispatch }, group) {
      // Update addons in a group if there is addAddonList.
      if (group.addAddonList) {
        group.products = group.addAddonList;
      } else {}

      const response = await addAddonGroupApi(group);
      if (isResponseCREATED(response)) {
        commit("addOneAddonGroup", parseAddonGroup(response.data));
        // Set selectable AddonGroups
        commit("setSelectableAddonGroups");
        // Re-load the Addons if necessary.
        if (group.addAddonList) {
          if (group.addAddonList.length > 0) {
            dispatch('addon/loadAddons', null, { root: true });
          } else { }
        } else { }
      } else {
        console.log("[Error]addOneAddonGroup:");
      }
      return response;
    },
    /**
     * Add an AddonGroup with images.
     * 
     * Work flow.
     * 1. Add an AddonGroup.
     * 2. Set selectable AddonGroups.
     * 3. Re-load all the Addons if any Addons are added in an AddonGroup.
     * @param {Object}  group         AddonGroup object: Refer to ADDON_GROUP_MODEL.
     * @param {Object}  [imageObject] Image objects: {files, preview}
     */
    async addOneAddonGroupWithImage({ commit, dispatch }, { group, imageObject }) {
      // Update addons in a group if there is addAddonList.
      if (group.addAddonList) {
        group.addons = group.addAddonList;
      } else { }

      const response = await addAddonGroupWithImageApi(group, imageObject);
      if (isResponseCREATED(response)) {
        commit("addOneAddonGroup", parseAddonGroup(response.data));
        // Set selectable AddonGroups
        commit("setSelectableAddonGroups");
        // Re-load the Addons if necessary.
        if (group.addAddonList) {
          if (group.addAddonList.length > 0) {
            dispatch('addon/loadAddons', null, { root: true });
          } else { }
        } else { }
        
      } else {
        console.log("[Error]addOneAddonGroupWithImage:");
        // commit("setSyncState"); TODO : sync?
      }
      return response;
    },
    /**
     * Delete Addons from an AddonGroup.
     * It deletes Addons in an AddonGroup and updates QueriedStatus accordingly.
     * @param {Object} group AddonGroup object(partial): {id, deleteAddonList}.
     */
    async deleteAddonsInGroup({ commit, getters, dispatch }, group) {
      // Index of the AddonGroup
      const index = getters.findAddonGroupIndexById(group.id);

      // TODO : Seperate it
      // Delete Addons from the AddonGroup
      if (Array.isArray(group.deleteAddonList)) {
        if (group.deleteAddonList.length > 0) {
          const response = await deleteAddonsInGroupApi(group.id, group.deleteAddonList);
          if (isResponseOK(response)) {
            group = response.data;

            // Update the AddonGroup
            commit(
              "updateOneAddonGroup",
              {
                group: parseAddonGroup(group),
                index: index
              }
            );

            // Set status or AddonGroups by checking all the status of the Addons.
            dispatch("getQueriedStatus");
          } else { }
        } else { }
      } else { }
    },

    // Filters
    /**
     * Apply filters.
     */
    applyAddonGroupFilters({ commit }) {
      commit("applyAddonGroupFilters");
    },
    /**
     * Set and apply filters.
     * @param {Object} filters Filters object.
     */
    setAddonGroupFilters({ commit }, filters) {
      commit("setAddonGroupFilters", filters);
      commit("applyAddonGroupFilters");
    },
    /**
     * Reset and apply filters.
     * @param {Object} [filters] Filters object.
     */
    resetAddonGroupFilters({ commit }, filters) {
      const emptyFilters = {
        store: { value: "", label: "" },
        keyword: "",
        ...filters,
      };
      commit("setAddonGroupFilters", emptyFilters);
      commit("applyAddonGroupFilters");
    },
    /**
     * Delete a filtered AddonGroup in the state.
     * It deletes a filtered AddonGroup.
     * @param {string} id Id of an AddonGroup.
     */
    deleteOneFilteredAddonGroup({ state, commit }, id) {
      const idx = state.filteredAddonGroups.findIndex(el => el.id == id);
      if (idx >= 0) {
        commit("deleteOneFilteredAddonGroup", idx);
      } else { }
    },

    // Queries
    /**
     * Set status of AddonGroups by checking all the status of the Addons.
     */
    getQueriedStatus({ state, commit, rootGetters }) {

      for (let i = 0; i < state.addonGroups.length; ++i) {
        const curAddonIds = state.addonGroups[i].products ? state.addonGroups[i].products : [];

        let status = ENUM_STATUS_STRING.INACTIVE;
        for (let j = 0; j < curAddonIds.length; ++j) {
          const addon = rootGetters["addon/findAddonById"](curAddonIds[j]);
          if (addon) {
            if (addon.status === ENUM_STATUS_STRING.ACTIVE) {
              status = addon.status;
              break;
            } else { }
          } else { }
        }

        // Update status of the AddonGroup
        commit(
          "updateOneAddonGroup",
          {
            group: {status:status},
            index: i
          }
        );
      }
      // console.log(state.addonGroups);
    },
    // TODO : simplify it.
    /**
     * Update status of Addons in an AddonGroups
     * @param {Object} addonGroup AddonGroup object: Refer to ADDON_GROUP_MODEL.
     */
    async updateQueriedStatus({ rootGetters, dispatch }, addonGroup) {

      // Update only if there is a status in the addon group
      // Eg. if status is not changed in edit mode it shouldn't be changed or
      //     all the status of the addon in the addon group can be changed unintentionally.
      if (addonGroup.status) {

        const curAddonIds = addonGroup.products ? addonGroup.products:[];
        for (let j = 0; j < curAddonIds.length; ++j) {
          let addon = rootGetters["addon/findAddonById"](curAddonIds[j]);
          console.log(addon);
          if (addon) {
            if (addon.status != addonGroup.status) {
              // Update
              if (addon.status != ENUM_STATUS_STRING.DELETED) {
                addon.status = addonGroup.status;
                // console.log(addon);
                await dispatch('addon/updateOneAddon', addon, { root: true });
              } else { }
            } else { }
          } else { }
        }
       
        // TODO : if condition to check success
        // this.addNotify("Success", "Status of addons in a group is updated");
      }
    },
  }
};
