/**
 * @param {String[]} defaultSelected List of default options
 */

<template>
  <b-dropdown
    data-cy="top-dropdown-filterColumn"
    :text="$t('order.history.filter-columns')"
    variant="outline-dark" 
    class="mr-1 mb-1" 
    menu-class="dropdown-filter-column-ul"
    size="xs" 
    no-flip
  >
    <b-dropdown-item 
      v-for="(column,index) in columnOptions"
      :data-cy="`top-dropdown-filterColumn-item-${index}`"
      :key="index" 
      @click="onClick(column)"
      :active="column.active"
      :disabled="column.disabled"
    >
      {{ column.text }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    defaultSelected: Array, // String[]
    selectedColumns: Array, // String[]
  },
  data: function() {
    return {
      columnOptions: [
        {value: "all", text: this.$t('general.all'), active: false}, // Keep it as first element of the array.
        {value: "default", text: this.$t('general.default'), active: false }, 
        {value: "id", text: this.$t('order.fields.id'), active: false},
        {value: "storeId", text: this.$t('order.fields.store-id'), active: false},
        {value: "customerName", text: this.$t('order.fields.customer-name'), active: false},
        {value: "customerPhone", text: this.$t('order.fields.customer-phone'), active: false},
        {value: "subAmount", text: this.$t('order.fields.sub-amount'), active: false},
        {value: "discountAmount", text: this.$t('order.fields.discount-amount'), active: false},
        {value: "appliedTaxes", text: this.$t('order.fields.tax-amount'), active: false},
        {value: "totalAmount", text: this.$t('order.fields.total-amount'), active: false},
        {value: "globalPointsUsed", text: this.$t('order.fields.global-points-used'), active: false},
        {value: "isPickup", text: this.$t('order.fields.is-pickup'), active: false},
        {value: "preparingTime", text: this.$t('order.fields.preparing-time'), active: false},
        {value: "deliveringTime", text: this.$t('order.fields.delivering-time'), active: false},
        {value: "deliveryAddress", text: this.$t('order.fields.delivery-address'), active: false},
        {value: "deliveryDistance", text: this.$t('order.fields.delivery-distance'), active: false},
        {value: "deliveryPrice", text: this.$t('order.fields.delivery-price'), active: false},
        {value: "status", text: this.$t('order.fields.status'), active: false},
        // {value: "serveStatus", text: this.$t('order.fields.serve-status'), active: false}, // Duplicated field with isPickUp Ticket: BP-2691. 
        {value: "lastUpdatedAt", text: this.$t('order.fields.last-updated-at'), active: false},
        {value: "orderDetails", text: this.$t('order.fields.order-details'), active: false, disabled: true},
      ],
    }
  },
  methods: {
    /**
     * payload: Object {value, text, active}
     */
    onClick: function(payload) {
      
      if (payload.value === "all") {
        if (payload.active === false) { //selected all
          this.columnOptions = this.columnOptions.map(option => option.value === "default" 
                                                                ? ({...option, active: false}) 
                                                                : ({...option, active: true}));

        } else {                        //deselected all
          payload.active = !payload.active;
        }
      } else if (payload.value === "default") {
        if (payload.active === false) { // selected default
          this.columnOptions = this.columnOptions.map(option => this.defaultSelected.includes(option.value) 
                                                                ? ({...option, active: true})
                                                                : ({...option, active: false}))
          this.columnOptions[0].active = false; // All option.
          this.columnOptions[1].active = true;  // Default option.

        } else {                        // deselected default
          this.columnOptions[1].active = false; // Default option.
        }
      } else {
        payload.active = !payload.active;
        
        this.columnOptions[0].active = false; // All option.
        this.columnOptions[1].active = false; // Default option.
      }

      const columns = this.columnOptions.filter(column => column.active && column.value !== "all" && column.value !== "default");
      this.$emit("change", columns.map(column => column.value));
    },

    /**
     * Only updates the active value for columnOptions.
     */
    updateOptionsByListOfColumns(columnList) {
      // Match the columnOptions and active them.
      this.columnOptions = this.columnOptions.map(option => {
        const matchedOption = columnList.find(key => key === option.value);
        return matchedOption ? {...option, active: true} : {...option, active: false};
      });
    },
  },
  watch: {
    selectedColumns: function (newVal, oldVal) {
      if (newVal && newVal.length > 0) {
        this.updateOptionsByListOfColumns(newVal);
      } 
    }
  },
  mounted() {
    if (this.selectedColumns && this.selectedColumns.length > 0) {
      this.updateOptionsByListOfColumns(this.selectedColumns);
    }
  }
}
</script>