// Componet for Selection Dialog Product
// Author : Huen Oh (heons921@gmail.com)
// @param {string}  cyPrefix    Prefix for data-cy.
// @param {string}  cySuffix    Suffix for data-cy.
// @param {string}  title       Title of the component. 
// @param {array}   options     Options of the Selection. 
// @param {boolean} disabled    Diabled property of the component.
// @param {number}  colSize     Size of the column for the form. : 1 ~ 12.


<template>
  <b-colxx sm="6">
    <!-- Selection -->
    <b-form-group>
      <h6>{{title}}</h6>
      <b-row align-v="center" no-gutters v-if="!(disabled)">
        <b-colxx :sm="colSize">
          <v-select
            :data-cy="`${cyPrefix}-select-${cySuffix}`"
            :options="options"
            v-model="selItem"
            @input="onChangeSelection"
          >
            <span slot="no-options">
              {{noOptionsMessage}}
            </span>

            <template #search="{ attributes, events }">
              <input
                :data-cy="`${cyPrefix}-select-${cySuffix}-input`"
                class="vs__search"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
        </b-colxx>
      </b-row>
    </b-form-group>
  </b-colxx>
</template>        


<script>
// Events.
/**
 * Event on input for select.
 * 
 * @event BaseSelectItem#input
 * @type {object}
 */

import vSelect from "vue-select"

export default {
  components:{
    vSelect,
  },
  data(){
    return {
    // {Object} Selected Item to add.
      selItem:{}    
    }
  },
  props:{
    // {string} Prefix for data-cy.
    cyPrefix: {
      type: String,
      default: ""
    },
    // {string} Suffix for data-cy.
    cySuffix: {
      type: String,
      default: ""
    },
    // {array} Options of the Selection. 
    options:{
      type: Array,
      default:()=>[]    
    },
    // {boolean} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {string} title of an component.
    title: {
      type: String,
      default: ""
    },
     // {number} Size of the column for the form. : 1 ~ 12.
    colSize: {
      type: Number,
      default: 10,
    },
    noOptionsMessage:{
      type: String,
      default: "Sorry, no matching options.alksjdalksd"
    }
  }, 
  methods:{  
    onChangeSelection(selItem){
      this.$emit('input', selItem)
      // To Reset selection.
      this.selItem = null;
    }
  },
}
</script>
<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>