// Orders page.
// Author : Huen Oh (heons921@gmail.com)

<template>
  <div id="app-orders">
    <top-nav type="app-orders"/>
    
    <!-- Left -->
    <OrdersSidebar :cyPrefix="`orders-sidebar`" class="custom-sidebar"/>
    <!-- Right -->
    <router-view/>
  
    
    <!-- <footer-component/> -->
  </div>
</template>


<script>
import TopNav from '../../../../containers/TopNav'
import OrdersSidebar from './OrdersSidebar'

export default {
  components: {
    'top-nav': TopNav,
    'OrdersSidebar': OrdersSidebar,
  },
  data() {
    return {
    }
  },
};
</script>
<style lang="scss" scoped>
</style>