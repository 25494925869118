var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.allowTaxConfigDialog
        ? _c("dialog-tax-config", {
            attrs: {
              disabled: _vm.disabled,
              storeId: _vm.taxConfigStoreId,
              taxConfigIds: _vm.getTaxConfigIdsByStoreId(),
              dialog: _vm.dialogs.taxConfig,
            },
            on: {
              "dialog-close": _vm.closeDialogTaxConfig,
              "dialog-submit": _vm.onSubmitDialogTaxConfig,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectableStores.length >= 2
        ? _c(
            "b-form-group",
            { staticClass: "custom-form-group" },
            [
              !_vm.disabled
                ? _c("h6", [_vm._v(_vm._s(_vm.$t("forms.choose-stores")))])
                : _c("h6", [_vm._v(_vm._s(_vm.$t("forms.applicable-stores")))]),
              _vm._v(" "),
              _c(
                "b-row",
                { attrs: { "align-v": "center", "no-gutters": "" } },
                [
                  _c(
                    "b-colxx",
                    { attrs: { sm: _vm.colSize } },
                    [
                      _vm._l(_vm.selectableStores, function (store, index) {
                        return _c(
                          "b-input-group",
                          { key: store.value, staticClass: "mb-3" },
                          [
                            store.value != ""
                              ? _c(
                                  "b-input-group-prepend",
                                  { attrs: { "is-text": "" } },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.storeSelStatus[index],
                                          expression: "storeSelStatus[index]",
                                        },
                                      ],
                                      attrs: {
                                        "data-cy": `${_vm.name}-checkbox-store-${index}`,
                                        type: "checkbox",
                                        disabled: _vm.disabled,
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.storeSelStatus[index]
                                        )
                                          ? _vm._i(
                                              _vm.storeSelStatus[index],
                                              null
                                            ) > -1
                                          : _vm.storeSelStatus[index],
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$a = _vm.storeSelStatus[index],
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.storeSelStatus,
                                                    index,
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.storeSelStatus,
                                                    index,
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.storeSelStatus,
                                                index,
                                                $$c
                                              )
                                            }
                                          },
                                          function ($event) {
                                            return _vm.updateStoreIds(index)
                                          },
                                        ],
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            store.value != ""
                              ? _c("b-form-input", {
                                  staticClass: "custom-form-input",
                                  attrs: {
                                    disabled: "",
                                    placeholder: store.label,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.allowTaxConfigDialog &&
                            store.value != "" &&
                            _vm.storeSelStatus[index]
                              ? _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass:
                                    "custom-form-input text-truncate",
                                  attrs: {
                                    title: _vm.getTaxConfigText(store.value),
                                    disabled: "",
                                    placeholder: _vm.getTaxConfigText(
                                      store.value
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.allowTaxConfigDialog &&
                            store.value != "" &&
                            _vm.storeSelStatus[index]
                              ? _c(
                                  "b-input-group-append",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "default",
                                        attrs: {
                                          variant: "outline-primary",
                                          disabled: _vm.disabled,
                                          "data-cy": `${_vm.name}-checkbox-customize-tax-btn-${index}`,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openDialogTaxConfig(
                                              store.value
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("product.customize-tax")
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticStyle: { "margin-top": "-1.5rem" },
                          attrs: {
                            state: !_vm.$v.storeIds.$invalid || _vm.isFirstLoad,
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("validations.no-selection-validation")
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }