// Componet for selecting Stores.
// Author : Huen Oh (heons921@gmail.com)
// @param: {bool}     disabled  Disabled property of the component. (in)
// @param: {number}   colSize   Size of the column for the form. : 1 ~ 12. (in)
// @param: {string[]} storeIds  Ids of Stores. (in, out)


<template>
<div>
  <!-- // Dialog tax config here -->
  <dialog-tax-config
    v-if="allowTaxConfigDialog"
    :disabled="disabled"
    :storeId="taxConfigStoreId"
    :taxConfigIds="getTaxConfigIdsByStoreId()"
    :dialog="dialogs.taxConfig"
    @dialog-close="closeDialogTaxConfig"
    @dialog-submit="onSubmitDialogTaxConfig"
  />
  <!-- Selection :  Stores -->
  <b-form-group class="custom-form-group" v-if="selectableStores.length >= 2">
    <h6 v-if="!disabled">{{$t('forms.choose-stores')}}</h6>
    <h6 v-else>{{$t('forms.applicable-stores')}}</h6>
    <b-row align-v="center" no-gutters>
      <b-colxx :sm="colSize">
        <!-- Stores : Selection using checkboxes -->
        <b-input-group class="mb-3" v-for="(store, index) in selectableStores" :key="store.value">
          <!-- Store select checkbox  -->
          <b-input-group-prepend   v-if="store.value !=''" is-text>
            <input :data-cy="`${name}-checkbox-store-${index}`" type="checkbox" @change="updateStoreIds(index)" v-model="storeSelStatus[index]" :disabled="disabled">
          </b-input-group-prepend>
          <!-- Merchant store name  -->
          <b-form-input v-if="store.value !=''" class="custom-form-input" disabled="" :placeholder="store.label"></b-form-input>
          <!-- Tax config information  -->
          <b-form-input 
            v-if="allowTaxConfigDialog && (store.value !='' && storeSelStatus[index])" 
            v-b-tooltip.hover :title="getTaxConfigText(store.value)"
            class="custom-form-input text-truncate" 
            disabled="" 
            :placeholder="getTaxConfigText(store.value)"
          ></b-form-input>
          <!-- Tax config edit button  -->
          <b-input-group-append v-if="allowTaxConfigDialog && (store.value !='' && storeSelStatus[index])">
            <b-button 
              variant="outline-primary" 
              class="default" 
              :disabled="disabled" 
              @click="openDialogTaxConfig(store.value)"
              :data-cy="`${name}-checkbox-customize-tax-btn-${index}`"
            >{{$t('product.customize-tax')}}</b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback 
        style="margin-top:-1.5rem"
        :state="!$v.storeIds.$invalid || isFirstLoad">
          {{ $t('validations.no-selection-validation')}}
        </b-form-invalid-feedback>
      </b-colxx>
    </b-row>
  </b-form-group>
</div>
</template>


<script>
import { mapGetters } from 'vuex'

// For validation
import { validationMixin } from "vuelidate";
const { required, minLength } = require("vuelidate/lib/validators");

import { isNullOrUndefined, objToStrMap, strMapToObj } from '@/utils/general';

import DialogTaxConfig from '@/components/Products/DialogTaxConfig';

import _ from 'lodash';

export default {
  props:{
    // {string} Name of the component. Index in usual.
    name: {
      type: String,
      default: ""
    },
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {number} Size of the column for the form. : 1 ~ 12.
    colSize: {
      type: Number,
      default: 10,
    },
    // {string[]} Ids of Stores.
    storeIds: {
      type: Array,
      default: () => [],
    },
    productTaxConfigs: {
      type: Array,
      default: () => [],
    },
    // Is First Load, in order to not display validation message at first
    isFirstLoad:{
      type: Boolean,
      default: true
    },
    allowTaxConfigDialog: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DialogTaxConfig,
  },
  data(){
    return {
      storeSelStatus:[],   // {bool[]} Selection status of the storeIds for checkboxes. [true or false].

      // For tax config
      taxConfigStoreId: null,

      productTaxConfigsLocal: this.productTaxConfigs == null ? [] : this.productTaxConfigs,

      dialogs: {
        taxConfig: false,
      }
    }    
  },
  // Validations
  mixins: [validationMixin],
  validations: {
    storeIds:{
      required,
      minLength: minLength(1),
    }
  },
  created: function () {
    // console.log('created');
  },
  watch: {
    /**
     * Watch for Updating status of Stores.
     */
    selectableStores: function() {
      this.updateStoreSelStatus();
    },
    /**
     * Watch for Updating selected Stores.
     */
    storeIds: function() {
      this.updateStoreSelStatus();
    },
    /**
     * Watch for updating selected tax config ids.
     */
    productTaxConfigs: function () {
      this.productTaxConfigsLocal = this.productTaxConfigs == null ? [] : this.productTaxConfigs;
    },
  },
  computed: {
    // Map getters
    ...mapGetters("merchant_store", ["selectableStores", "getDefaultTaxConfigsOfStore", "getTaxConfigsByIds"]),
  },
  methods:{
    /**
     * Update status of Stores.
     */
    updateStoreSelStatus() {
      // Create list of store status for the checkbox
      this.storeSelStatus = [];
      for (const store of this.selectableStores) {
        this.storeSelStatus.push(false);
      }
      
      // To copy stores only in the selectableStores to check the case some stores are deleted.
      let storeList = [];
      // Update list of store status from the data.storeIds
      for(const store of this.storeIds) {
        const idx = this.selectableStores.findIndex(element => element.value == store);
        if(idx != -1) {
          this.storeSelStatus[idx] = true;
          storeList.push(store);
        } else {}
      }

      // To check the case some stores are deleted.
      // Remove the deleted stores from the storeIds list.
      if (storeList.length !== this.storeIds.length) {
        this.storeIds.splice(0, this.storeIds.length);
        storeList.forEach(store => this.storeIds.push(store));
      }
    },
     /**
     * Update Ids of Stores. It only updates locally.
     * @param {number} index index of an item to update.
     */
    updateStoreIds(index) {
      // console.log(store);
      const storeId = this.selectableStores[index].value;
      if(this.storeSelStatus[index] == false) {
        //remove
        const idx = this.storeIds.findIndex(element => element == storeId);
        this.storeIds.splice(idx, 1);

        // Update the tax configs of that store as well by removing it.
        this.productTaxConfigsLocal = this.productTaxConfigsLocal.filter(taxConfig => taxConfig.storeId !== storeId);
      } else {
        //add
        this.storeIds.push(storeId);

        // Update the tax configs of that store as well by adding the store's default tax configs.
        const defaultTaxConfigsForStore = this.getDefaultTaxConfigsOfStore(storeId);

        this.productTaxConfigsLocal = this.productTaxConfigsLocal.filter(taxConfig => taxConfig.storeId !== storeId);
        if (!isNullOrUndefined(defaultTaxConfigsForStore)) {
          this.productTaxConfigsLocal.push({storeId: storeId, taxConfigIds: defaultTaxConfigsForStore, isDefaultStoreTax: true});
        }
      }
    },

    /**
     * Show tax config dialog box to edit default tax configuration.
     * @param storeId To show tax config for specific merchant store.
     */
    openDialogTaxConfig(storeId) {
      this.taxConfigStoreId = storeId;
      this.dialogs.taxConfig = true;
    },

    /**
     * Handle closing the tax congif dialog box.
     */
    closeDialogTaxConfig() {
      this.dialogs.taxConfig = false;
    },

    /**
     * add selected tax configs for current product.
     */
    onSubmitDialogTaxConfig(dataToApply) {
        
      // Check if the tax config ids are customized and are not default anymore.
      let isDefaultStoreTax = true;
      const defaultTaxConfigsForStore = this.getDefaultTaxConfigsOfStore(dataToApply.storeId);

      if (!isNullOrUndefined(defaultTaxConfigsForStore)) {

        if (isNullOrUndefined(dataToApply.taxConfigIds) || (dataToApply.taxConfigIds.length === 0)) { // Tax exempt case
          isDefaultStoreTax = false;

        } else if (defaultTaxConfigsForStore.length == dataToApply.taxConfigIds.length) {

          const matchedTaxConfigIds = dataToApply.taxConfigIds.filter(taxConfigId => 
            defaultTaxConfigsForStore.includes(taxConfigId));

          if (matchedTaxConfigIds.length !== defaultTaxConfigsForStore.length) {
            isDefaultStoreTax = false;
          }

        } else {
          isDefaultStoreTax = false;
        }
      }

      
      // Override the previous taxConfigIds for the store, since the new ones will include the updated values.
      this.productTaxConfigsLocal = this.productTaxConfigsLocal.filter(taxConfig => taxConfig.storeId !== dataToApply.storeId);
      this.productTaxConfigsLocal.push({
        storeId: dataToApply.storeId, 
        taxConfigIds: dataToApply.taxConfigIds, // It will be [] empty array if tax exempt case.
        isDefaultStoreTax: isDefaultStoreTax
      });

    },

    /**
     * Get the tax config object to save it in the product.
     */
    getTaxConfigIdsObject() {
      return this.productTaxConfigsLocal.filter(taxConfig => !isNullOrUndefined(taxConfig.storeId));
    },

    /**
     * Gets list of taxConfigIds by selected storeId.
     */
    getTaxConfigIdsByStoreId() {

      const foundProductTaxConfig = this.productTaxConfigsLocal.find(taxConfig => taxConfig.storeId === this.taxConfigStoreId); 
      if (isNullOrUndefined(foundProductTaxConfig)) {
        return null;
      }

      const taxConfigIds = foundProductTaxConfig.taxConfigIds; 
      if (isNullOrUndefined(taxConfigIds)) {
        return null;
      } else {
        return taxConfigIds;
      }
    },

    /**
     * Create text for applied taxs from list of tax config ids to display for each store.
     */
    getTaxConfigText(storeId) {
      if (isNullOrUndefined(storeId)) {
        return null;
      }

      const foundProductTaxConfig = this.productTaxConfigsLocal.find(taxConfig => taxConfig.storeId === storeId); 

      if (foundProductTaxConfig === undefined) {
        return this.$t('product.no-default-tax');
      }

      const {taxConfigIds, isDefaultStoreTax} = foundProductTaxConfig;

      if (taxConfigIds === null) return null; 

      if (taxConfigIds.length === 0) {
        // Tax exempt case
        return this.$t('general.tax-exempt');
      }

      // Assume that must contain atleast one taxConfigId.
      if (taxConfigIds.length > 0) {
      
        // Get all tax configs by their ids.
        const appliedTaxConfigs = this.getTaxConfigsByIds(taxConfigIds);

        // Create the result string "GST (13%) + PST (5%)"
        return appliedTaxConfigs
          .map(({taxType, percentage}) => `${taxType} (${percentage}%)`)
          .join(' + ') + ` (${isDefaultStoreTax ? this.$t('general.default') : this.$t('general.customized')})`;

      }

    },

    /**
     * Load the only store available and its taxes as default value for storeIds and productTaxConfigs.
     * NOTE: this is only required when only 1 store is available for the merchant.
     */
    preloadStoreIdsAndTaxes() {
      // get default store
      const storeId = this.selectableStores[1].value;

      this.storeIds.push(storeId);

      if (_.isEmpty(this.productTaxConfigs)) {

        const defaultTaxConfigsForStore = this.getDefaultTaxConfigsOfStore(storeId);
        this.productTaxConfigs.push({storeId: storeId, taxConfigIds: defaultTaxConfigsForStore, isDefaultStoreTax: true});
      }
    }
  },
  mounted(){
    // console.log('mounted');
    // Update status of Stores.
    this.updateStoreSelStatus();

    if (_.isEmpty(this.storeIds) && (this.selectableStores.length === 2)) { 
      /*
        Determine whether creating a new Product/Addon by checking if storeIds are empty.
        If only 1 store is present, pre-load the storeId and tax ids related to that store as default.
        Since, the same logic is used for Adding and Duplicating a product, the values must be checked before pre-loading. 
        For example: When duplicating a product, the stores and productTaxConfigs values are already present, so do not pre-load the default values.
        Only load when creating a new product from scratch.
      */

      this.preloadStoreIdsAndTaxes();

    }

  }
}
</script>
