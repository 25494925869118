// Dropdown for AddonGroup Filter selection.
// Author : Huen Oh (heons921@gmail.com)
// @param {string} label Label text for the dropdown. (in)

<template>
  <b-dropdown 
    data-cy="top-dropdown-addon-group" 
    id="ddown4" 
    :text="`${$t('objects.addon-group')}: ${label}`" 
    variant="outline-dark" 
    class="mr-1 btn-group align-top" 
    size="xs" 
    no-flip
  >               
    <b-dropdown-item 
      :data-cy="`top-dropdown-addon-group-item-${index}`" 
      v-for="(group,index) in selectableAddonGroups" 
      :key="index" 
      @click="onClick(group)"
    >
    {{ group.label }}
    </b-dropdown-item>
  </b-dropdown>
   
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  props:{
    // {string} Label text for the dropdown. (in)
    label:{
      type: String,
      default: "",
    }
  },
  computed:{
    // Map getters
    ...mapGetters("addon_group",    ["selectableAddonGroups"]),
  },
  methods:{
    /**
     * On click the button.
     */
    onClick(filter) {
      this.$emit('click', filter);
    }
  }
}
</script>
