// Select Day and Time Limit component.
// Author : Huen Oh (heons921@gmail.com)
// @param: {Object} data      Object with date ranage: {displayTime}.
// @param: {bool}   disabled  Disabled property of the toogle button.

<template>
<div class="mb-2">
  <!-- Dialog: Date/Time -->
  <dialog-date-time
    :disabled="disabled"
    :data="data"
    :dialog="dialogs.schedule"
    :disableHideUntil="true"
    @dialog-close="closeDialogSchedule"
    @dialog-submit="onSubmitDialogSchedule"
  />

  <!-- Day and Time limit -->
  <b-row align-v="center">
    <!-- Toggle button for the check -->
    <b-colxx xxs="2">
      <toggle-button-check  
        name="date-time-limit"
        v-model="isTimeLimited" 
        :disabled="disabled"
      />
    </b-colxx>
    <!-- Title -->
    <b-colxx xxs="6">
      <!-- <h6>{{`${$t('promotions.limits-time-title')}`}}</h6> -->
      <h6>{{$t('promotions.date-time-limit-title')}}</h6>
    </b-colxx>
    <b-colxx v-if="isTimeLimited">
      <!-- TODO : apply ImageButtonSchedule -> check cls-img-btn-big  -->
      <a href="javascript:void(0);">
        <b-img 
          data-cy="date-time-limit-btn-availability"
          :id="'schedule-'+data.id" 
          class="no-checkbox cls-img-btn-big ml-2" 
          src="/assets/icons/work-schedule.svg"
          @click="openDialogSchedule()"
        />
      </a>
      <b-tooltip 
        :target="'schedule-'+data.id" 
        :title="$t('tool-tips.schedule')"
        placement="bottom" 
      />
    </b-colxx>
  </b-row>
</div>
</template>


<script>
import moment from 'moment';

import ToggleButtonCheck    from '@/components/Forms/ToggleButtonCheck'
import DialogDateTime from '@/components/Common/DialogDateTime'


export default {
  props:{
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    data: {  // {Object} Object with date ranage: {displayTime}.
      type: Object,
      default: {}
    }
  },
  components: { 
    ToggleButtonCheck, 
    DialogDateTime
  },
  data(){
    return {
      isTimeLimited: false, // {bool}   To check time limit.
      isLoaded: false,

      selectedValueRange: { // {Object} Selected dates for time limit.
        start: new Date(),  // {Object} Start date : Date object.
        end: new Date()     // {Object} End date : Date object.
      },
      dialogs: {          // Dialog controls
        schedule:false,
      },
    }    
  },
  created: function () {
    // // Set value for the toggle button.
    // this.switchValue = this.inputValue;
  },
  watch: {
    /**
     * Watch to check changes in data.
     */
    data: {
      deep: true,
      // immediate: true,
      handler() {
        // Only works for the first load.
        if(!this.isLoaded) {
          // Set time limit.
          if (Array.isArray(this.data.displayTime) && (this.data.displayTime.length > 0)) {
            this.isTimeLimited = true;
          }
          
          this.isLoaded = true;
          this.data.availableTime = this.data.displayTime; // TODO : tmep.
          // console.log('this.data.availableTime', this.data.availableTime)
        } else {}
      },
    },
  },
  computed: {
  },
  methods:{
    /**
     * Get Day and Time limit : displayTime
     */
    getDayTime() {
      return this.isTimeLimited ? this.data.displayTime : null;
    },

    // Dialog operations - Schedule
    /**
     * Close Schedule dialog.
     */
    closeDialogSchedule() {
      this.dialogs.schedule = false;
    },
    /**
     * Open Schedule dialog.
     */
    openDialogSchedule() {
      this.dialogs.schedule = true;
    },
    /**
     * Submit from schedule dialog.
     * @param {Object} data Schedule data object: Refer EMPTY_SCHEDULE
     */
    onSubmitDialogSchedule(data) {
      this.data.displayTime = data.availableTime;
      this.data.availableTime = data.availableTime;
      // console.log('this.data', this.data)
      // console.log('this.data.availableTime', this.data.availableTime)
      // console.log('data.availableTime', data.availableTime)
    },
  },
  mounted(){
    // Set time limit.
    if (Array.isArray(this.data.displayTime) && (this.data.displayTime.length > 0)) {
      this.isTimeLimited = true;
    }

    this.data.availableTime = this.data.displayTime; // TODO : tmep.

  }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>



