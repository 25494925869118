<template>
    <v-date-picker
      data-cy="top-date-picker"
      mode="range"
      v-model="selectedDuration"
      class="d-inline-block mr-1 mb-1 custom-form-group btn-group"
      :input-props="{
        class: 'custom-input-form custom-date-picker',
        placeholder: $t('form-components.date-range'),
      }"
      @input="onChange()"
    ></v-date-picker>
</template>

<script>

import "@/assets/css/sass/style_custom.scss"

const currentDate = new Date();

const defaultReportDuration = {
  start: new Date(
    currentDate.getFullYear(),
    currentDate.getUTCMonth() - 1,
    currentDate.getUTCDate()
  ),
  end: new Date(
    currentDate.getFullYear(),
    currentDate.getUTCMonth(),
    currentDate.getUTCDate()
  )
}

export default {
  props:{
    // {Object} Consist of start/end of the duration
    duration: {
      type: Object,
      default:() => defaultReportDuration
    }
  },
  data(){
    return {
      selectedDuration: {
        start: new Date(this.duration.start),
        end: new Date(this.duration.end)
      }
    }
  },
  methods: {
    onChange() {
      this.$emit(
        "change", 
        {
          duration: this.selectedDuration || defaultReportDuration,
        }
      );
    },
  },
  watch: {
    duration: function (newVal, oldVal) {
      if (newVal.start && newVal.end) {
        this.selectedDuration = newVal;
      } else {
        this.selectedDuration = this.defaultReportDuration;
      }
    }
  }
}
</script>