var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      staticClass: "mr-1 btn-group",
      attrs: {
        "data-cy": "top-dropdown-promotion-list",
        id: "ddown1",
        text: `${_vm.$t("objects.type")}: ${_vm.label}`,
        variant: "outline-dark",
        size: "xs",
        "no-flip": "",
      },
    },
    _vm._l(_vm.options, function (type, index) {
      return _c(
        "b-dropdown-item",
        {
          key: index,
          attrs: { "data-cy": `top-dropdown-promotion-list-item-${index}` },
          on: {
            click: function ($event) {
              return _vm.onClick(type)
            },
          },
        },
        [_vm._v("\n  " + _vm._s(type.label) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }