// Notifications.
// Author : Huen Oh (heons921@gmail.com)


export function showProgressNotify() {
  this.$notify("info", "Saving...", "In progress", { duration: 2000, permanent: false });
}
export function showSuccessNotify() {
  this.$notify("primary", "Success", "Saved", { duration: 2000, permanent: false });
}
export function showErrorNotify() {
  this.$notify("error", "Error", "Something went wrong, try again", { duration: 2000, permanent: false });
}
export function showValidationError(message) {
  this.$notify("error", "Validation Error", message, { duration: 2000, permanent: false });
}

/**
 * Add success notification.
 * @param {string} description Description of the success.
*/
export function addNotifySuccess(description) {
  this.$notify("primary", this.$t('notify.title-success'), description, { duration: 3000, permanent: false });
}

/**
 * Add fail notification.
 * @param {string} description  Description of the success.
 */
export function addNotifyFail(description) {
  this.$notify("error", this.$t('notify.title-fail'), description, { duration: 3000, permanent: true });
}

/**
 * Add warning notification.
 * @param {string} description  Description of the success.
 */
export function addNotifyWarning(description) {
  this.$notify("error", this.$t('notify.title-warning'), description, { duration: 3000, permanent: false });
}