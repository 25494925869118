
// Multi Language Add
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '@/locales/en.json'
import es from '@/locales/es.json'
import { defaultLocale, localeOptions } from '@/constants/config'

// utils
import LocalStorageService from '@/utils/local_store'

Vue.use(VueI18n);

const messages = { en: en, es: es };
const locale = (LocalStorageService.getCurrentLanguage() && localeOptions.filter(x => x.id === LocalStorageService.getCurrentLanguage()).length > 0) ? LocalStorageService.getCurrentLanguage() : defaultLocale;
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages
});

export default i18n;