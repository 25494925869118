var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isNullOrUndefined(_vm.data) == false &&
    !_vm.isNullOrUndefined(_vm.data.taxAmount) &&
    _vm.data.taxAmount > 0
    ? _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "text-left ml-2",
              attrs: { "data-cy": `applied-tax-order-${_vm.name}-type` },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.data.taxType) +
                  "(" +
                  _vm._s(_vm.data.taxPercentage) +
                  "%)\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "text-right mr-3",
              attrs: { "data-cy": `applied-tax-order-${_vm.name}-amount` },
            },
            [
              _vm._v(
                "\n      $" +
                  _vm._s(
                    !_vm.isNullOrUndefined(_vm.data.taxAmount)
                      ? _vm.formatPriceWith2Decimal(_vm.data.taxAmount)
                      : ""
                  ) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }