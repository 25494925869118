var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalAddon",
      attrs: { id: "modalAddon", size: "lg" },
      on: {
        hide: function ($event) {
          return _vm.onCencel()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c("HeaderDialogProduct", {
                    attrs: {
                      title: "objects.addon",
                      name: "dlg-addon",
                      status: _vm.data.status,
                      disabled: _vm.data.showOnly,
                      actionType: _vm.actionType,
                    },
                    on: { changeStatus: _vm.updateStatus },
                  }),
                  _vm._v(" "),
                  _vm.isOnAddonPage === false
                    ? _c("div", [
                        _vm.isAddonDeleted
                          ? _c("p", { staticClass: "text-warning" }, [
                              _c("u", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$t("general.note")) +
                                      ": " +
                                      _vm._s(_vm.$t("addon.addon-deleted"))
                                  ),
                                ]),
                              ]),
                            ])
                          : _c("p", { staticClass: "text-muted" }, [
                              _c("u", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("general.note")) + ": "),
                                ]),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("addon.current-information-note")
                                    ) +
                                    "\n          "
                                ),
                              ]),
                            ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-form",
        [
          _c("dialog-alert", {
            attrs: {
              dialog: _vm.dialogs.alert,
              description: _vm.$t("alert.no-change"),
              actionType: _vm.actionType,
              buttonSubmitText: _vm.$t("buttons.ok"),
            },
            on: {
              "dialog-close": _vm.closeDialogAlert,
              "dialog-submit": _vm.onSubmitDialogAlert,
            },
          }),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-colxx",
                { attrs: { sm: "6" } },
                [
                  _c("EditNameDescription", {
                    attrs: {
                      name: "dlg-addon",
                      data: _vm.data,
                      label: "addon",
                      disabled: _vm.data.showOnly,
                      isFirstLoad: _vm.isFirstLoad,
                    },
                  }),
                  _vm._v(" "),
                  _c("EditPrice", {
                    attrs: {
                      name: "dlg-addon",
                      colSize: _vm.sizeColMain,
                      data: _vm.data,
                      disabled: _vm.data.showOnly,
                      isFirstLoad: _vm.isFirstLoad,
                    },
                  }),
                  _vm._v(" "),
                  _c("select-addon-groups", {
                    ref: "selAddonGroups",
                    attrs: {
                      name: "dlg-addon",
                      disabled: _vm.data.showOnly,
                      groups: _vm.data.groups,
                      isRequired: true,
                      isFirstLoad: _vm.isFirstLoad,
                    },
                    on: {
                      isValid: (ele) => {
                        _vm.isValidGroups = ele
                      },
                    },
                    model: {
                      value: _vm.isChanged,
                      callback: function ($$v) {
                        _vm.isChanged = $$v
                      },
                      expression: "isChanged",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-colxx",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "b-form-group",
                    [
                      !_vm.data.showOnly
                        ? _c("h6", [
                            _vm._v(_vm._s(_vm.$t("product.choose-image"))),
                          ])
                        : _c("h6", [_vm._v(_vm._s(_vm.$t("product.images")))]),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { attrs: { "align-v": "center", "no-gutters": "" } },
                        [
                          _c(
                            "b-colxx",
                            { attrs: { sm: _vm.sizeColMain } },
                            [
                              _c("ImagesSelection", {
                                key: "selImage",
                                ref: "selImage",
                                attrs: {
                                  name: "dlg-addon",
                                  images: _vm.data.images,
                                  single: false,
                                  disabled: _vm.data.showOnly,
                                },
                                model: {
                                  value: _vm.isChanged,
                                  callback: function ($$v) {
                                    _vm.isChanged = $$v
                                  },
                                  expression: "isChanged",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("select-attributes", {
                    ref: "selAttrubute",
                    attrs: {
                      name: "dlg-addon",
                      disabled: _vm.data.showOnly,
                      attributes: _vm.data.attributes,
                    },
                    model: {
                      value: _vm.isChanged,
                      callback: function ($$v) {
                        _vm.isChanged = $$v
                      },
                      expression: "isChanged",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-colxx",
                [
                  _c("CheckSelectStores", {
                    ref: "selStores",
                    attrs: {
                      name: "dlg-addon",
                      storeIds: _vm.data.stores,
                      productTaxConfigs: _vm.data.productTaxConfigs,
                      disabled: _vm.data.showOnly,
                      isFirstLoad: _vm.isFirstLoad,
                      allowTaxConfigDialog: true,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c("FooterDialogProduct", {
            attrs: {
              name: "dlg-addon",
              disabled:
                (_vm.$v.$invalid && !_vm.isFirstLoad) || _vm.isAddonDeleted,
              actionType: _vm.actionType,
            },
            on: { "click-cancel": _vm.onCencel, "click-submit": _vm.onSubmit },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }