var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalProduct",
      attrs: { id: "modalProduct", size: "lg" },
      on: {
        hide: function ($event) {
          return _vm.onCencel()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c("HeaderDialogProduct", {
                    attrs: {
                      title: _vm.isRewardItem
                        ? "objects.reward-item"
                        : "objects.promotion",
                      name: "dlg-promotion",
                      status: _vm.data.status,
                      disabled: true,
                      actionType: "Show",
                    },
                    on: { clickSchedule: _vm.openDialogSchedule },
                  }),
                  _vm._v(" "),
                  _vm.isOnPromotionPage === false
                    ? _c("div", [
                        _vm.isPromotionDeleted
                          ? _c("p", { staticClass: "text-warning" }, [
                              _c("u", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$t("general.note")) +
                                      ": " +
                                      _vm._s(
                                        _vm.$t("promotions.promotion-deleted")
                                      )
                                  ),
                                ]),
                              ]),
                            ])
                          : _c("p", { staticClass: "text-muted" }, [
                              _c("u", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("general.note")) + ": "),
                                ]),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "promotions.current-information-note"
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ]),
                            ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-colxx",
            { attrs: { sm: "6" } },
            [
              _c("edit-name-description", {
                attrs: {
                  name: "dlg-promotion",
                  label: _vm.isRewardItem ? "reward-item" : "promotions",
                  disabled: true,
                  data: _vm.data,
                },
              }),
              _vm._v(" "),
              _c("check-select-stores", {
                attrs: {
                  name: "dlg-promotion",
                  disabled: true,
                  storeIds: _vm.data.stores,
                },
              }),
              _vm._v(" "),
              _vm.isPromotionADeal
                ? [
                    _c(
                      "b-row",
                      [
                        _c("b-colxx", { attrs: { sm: "9" } }, [
                          _c("h6", [
                            _vm._v(_vm._s(_vm.$t("promotions.hide-perk-btn"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-colxx",
                          { attrs: { sm: "3" } },
                          [
                            _c("toggle-button", {
                              attrs: {
                                "data-cy": "dlg-promotion-toggle-btn-hide",
                                color: "#87bcbf",
                                value: !_vm.data.isPublicAvailable,
                                sync: true,
                                labels: { checked: "ON", unchecked: "OFF" },
                                width: 60,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.data.couponCode != null &&
                    _vm.data.couponCode.length > 0
                      ? _c(
                          "b-form-group",
                          { staticClass: "mt-3" },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c(
                                  "span",
                                  { staticClass: "align-self-center mr-5" },
                                  [
                                    _c("h6", { staticClass: "mr-5" }, [
                                      _vm._v(
                                        _vm._s(
                                          `${_vm.$t("promotions.coupon-code")}:`
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: {
                                    "data-cy": "dlg-promotion-coupon-code",
                                    value: _vm.data.couponCode,
                                    disabled: "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      [
                        _c(
                          "b-input-group",
                          {
                            staticClass: "mt-3 mb-3",
                            attrs: { append: "CAD" },
                          },
                          [
                            _c("span", { staticClass: "align-self-center" }, [
                              _c("h6", { staticClass: "mr-5" }, [
                                _vm._v(
                                  _vm._s(
                                    `${_vm.$t(
                                      "promotions.minimum-order-title"
                                    )}:`
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("b-form-input", {
                              attrs: {
                                "data-cy": "dlg-promotion-min-order-input-num",
                                type: "number",
                                value: _vm.formatPrice(_vm.data.requiredAmount),
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-muted text-small" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                `${_vm.$t(
                                  "promotions.minimum-order-description"
                                )}`
                              ) +
                              "\n          "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isDealBuyOneGetOne === false &&
                    _vm.isDealMulti === false
                      ? [
                          _c("h6", [
                            _vm._v(
                              _vm._s(`${_vm.$t("promotions.highlight-title")}`)
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-input-group",
                                {
                                  staticClass: "mb-3",
                                  attrs: { append: "CAD" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "align-self-center" },
                                    [
                                      _c("h6", { staticClass: "mr-4" }, [
                                        _vm._v(
                                          _vm._s(
                                            `${_vm.$t(
                                              "promotions.cart-value-exceeds"
                                            )}:`
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("b-form-input", {
                                    attrs: {
                                      "data-cy":
                                        "dlg-promotion-cart-value-input-num",
                                      type: "number",
                                      value: _vm.data.highlightAmount
                                        ? _vm.formatPrice(
                                            _vm.data.highlightAmount
                                          )
                                        : 0,
                                      disabled: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "text-muted text-small" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        `${_vm.$t(
                                          "promotions.highlight-description"
                                        )}`
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c("HighlightInProductList", {
                            attrs: {
                              highlightInProductList:
                                _vm.data.highlightInProductList,
                              productList: _vm.data.requiredProductList,
                              perkType: _vm.perkType,
                              show: true,
                            },
                          }),
                        ],
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            { attrs: { sm: "6" } },
            [
              _vm.isImagesPresent
                ? _c(
                    "b-form-group",
                    [
                      _c("images-selection", {
                        attrs: {
                          name: "dlg-promotion",
                          images: _vm.data.images,
                          single: false,
                          disabled: true,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPromotionADeal === false ||
              (_vm.isPromotionADeal && _vm.isDealMulti == false)
                ? [
                    _vm.showEligibleItemsField
                      ? _c(
                          "b-form-group",
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(_vm.$t("promotions.eligible-items")) +
                                  ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("treeselect-products", {
                              attrs: {
                                name: "dlg-promotion-products-required",
                                productIds: _vm.eligibleProductIds,
                                labeledGroupsWithProducts:
                                  _vm.labeledGroupsWithProducts,
                                disabled: true,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : [
                    _vm.showEligibleItemsField
                      ? _c(
                          "b-form-group",
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("promotions.eligible-items-with-group")
                                ) + ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.groupIndexList,
                              function (groupIndex, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "mb-1" },
                                  [
                                    _c("treeselect-products", {
                                      attrs: {
                                        name: `dlg-promotion-products-required-${index}`,
                                        productIds:
                                          _vm.getProductIdsListByGroupIndex(
                                            groupIndex
                                          ),
                                        labeledGroupsWithProducts:
                                          _vm.labeledGroupsWithProducts,
                                        disabled: true,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
              _vm._v(" "),
              _vm.showRewardItemsField
                ? _c(
                    "b-form-group",
                    [
                      _c("h6", [
                        _vm._v(_vm._s(_vm.$t("promotions.reward-items")) + ":"),
                      ]),
                      _vm._v(" "),
                      _c("treeselect-products", {
                        attrs: {
                          name: "dlg-promotion-products-required",
                          productIds: _vm.rewardProductIds,
                          labeledGroupsWithProducts:
                            _vm.labeledGroupsWithProducts,
                          disabled: true,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPromotionADeal
                ? _c(
                    "b-form-group",
                    { staticClass: "mb-5" },
                    [
                      _vm.isDealPercentDiscount
                        ? [
                            _c("h6", [
                              _vm._v(
                                _vm._s(_vm.$t("promotions.benefit-discounts")) +
                                  ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-3", attrs: { append: "%" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    "data-cy":
                                      "dlg-promotion-discount-percent-input-num",
                                    type: "number",
                                    value: _vm.data.discountPercent,
                                    disabled: true,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isDealFixedAmountDiscount
                        ? [
                            _c("h6", [
                              _vm._v(
                                _vm._s(_vm.$t("promotions.benefit-discounts")) +
                                  ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-3", attrs: { append: "CAD" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    "data-cy":
                                      "dlg-promotion-discount-amount-input-num",
                                    type: "number",
                                    value: _vm.data.discountAmount,
                                    disabled: true,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isDealFlatPriceDiscount
                        ? [
                            _c("h6", [
                              _vm._v(
                                _vm._s(_vm.$t("promotions.flat-price")) + ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-3", attrs: { append: "CAD" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    "data-cy":
                                      "dlg-promotion-fixed-amount-input-num",
                                    type: "number",
                                    value: _vm.data.fixedAmount,
                                    disabled: true,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPromotionPointPerk
                ? _c(
                    "b-form-group",
                    { staticClass: "my-4" },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("span", { staticClass: "align-self-center" }, [
                            _c("h6", { staticClass: "mr-3" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(`${_vm.$t("promotions.points")}`) +
                                  "\n            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("b-form-input", {
                            attrs: {
                              "data-cy": "dlg-promotion-points-input-num",
                              type: "number",
                              value: _vm.data.rewardPoints,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isRewardItem
                ? _c(
                    "b-form-group",
                    { staticClass: "my-4" },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("span", { staticClass: "align-self-center" }, [
                            _c("h6", { staticClass: "mr-3" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(`${_vm.$t("promotions.points")}`) +
                                  "\n            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("b-form-input", {
                            attrs: {
                              "data-cy": "dlg-promotion-points-input-num",
                              type: "number",
                              value: _vm.data.requiredPoints,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPromotionPunchCard
                ? _c(
                    "b-form-group",
                    { staticClass: "my-4" },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c(
                            "span",
                            { staticClass: "align-self-center col-10 pl-0" },
                            [
                              _c("h6", { staticClass: "mr-3" }, [
                                _vm._v(
                                  _vm._s(
                                    `${_vm.$t("promotions.choose-num-punches")}`
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-form-input", {
                            staticClass: "col-2",
                            attrs: {
                              "data-cy": "dlg-promotion-num-punches-input-num",
                              type: "number",
                              value: _vm.data.requiredPunches,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPromotionPunchCard
                ? _c(
                    "b-form-group",
                    { staticClass: "my-4" },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c(
                            "span",
                            { staticClass: "align-self-center col-10 pl-0" },
                            [
                              _c("h6", { staticClass: "mr-3" }, [
                                _vm._v(
                                  _vm._s(
                                    `${_vm.$t("promotions.choose-num-rewards")}`
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-form-input", {
                            staticClass: "col-2",
                            attrs: {
                              "data-cy": "dlg-promotion-num-rewards-input-num",
                              type: "number",
                              value: _vm.data.rewardItems,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-form-group",
                [
                  _c("select-time-limit", {
                    attrs: { data: _vm.data, disabled: true },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isPromotionPunchCard
                ? _c(
                    "b-row",
                    { staticClass: "mb-4", attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-colxx",
                        { attrs: { xxs: "2" } },
                        [
                          _c("toggle-button-check", {
                            attrs: {
                              name: "dlg-promotion-points",
                              value: _vm.isRewardPoints,
                              disabled: true,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-colxx", { attrs: { xxs: "10" } }, [
                        _c("h6", [
                          _vm._v(
                            _vm._s(_vm.$t("promotions.choose-points-giveaway"))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("b-colxx", { attrs: { xxs: "2" } }, [
                        _vm.isRewardPoints
                          ? _c("p", { staticClass: "text-medium" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(`${_vm.$t("promotions.points")}`) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-colxx",
                        [
                          _c(
                            "b-form-group",
                            [
                              _vm.isRewardPoints
                                ? _c("b-form-input", {
                                    attrs: {
                                      "data-cy":
                                        "dlg-promotion-points-input-num",
                                      type: "number",
                                      value: _vm.data.rewardPoints,
                                      disabled: "",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPromotionPunchCard === false && _vm.isRewardItem === false
                ? [
                    _vm.isPromotionPointPerk === false
                      ? _c(
                          "b-form-group",
                          [
                            _c("select-day-time-limit", {
                              attrs: { data: _vm.data, disabled: true },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      [
                        _c(
                          "b-row",
                          {
                            staticClass: "mb-2",
                            attrs: { "align-v": "center" },
                          },
                          [
                            _c(
                              "b-colxx",
                              { attrs: { xxs: "2" } },
                              [
                                _c("toggle-button-check", {
                                  attrs: {
                                    name: "dlg-promotion-client-limit",
                                    value:
                                      _vm.data.maxRedeem &&
                                      _vm.data.maxRedeem === 1,
                                    disabled: true,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("b-colxx", [
                              _c("h6", [
                                _vm._v(
                                  _vm._s(
                                    `${_vm.$t("promotions.limits-once-title")}`
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      [
                        _c("select-stock-limit", {
                          attrs: {
                            data: _vm.data.limitedStock,
                            disabled: true,
                          },
                          model: {
                            value: _vm.isLimitedStock,
                            callback: function ($$v) {
                              _vm.isLimitedStock = $$v
                            },
                            expression: "isLimitedStock",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-form-group",
                [
                  _c("h6", [
                    _vm._v(_vm._s(_vm.$t("promotions.other-options")) + ":"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.otherOptions, function (option, index) {
                    return _c(
                      "b-badge",
                      {
                        key: index,
                        staticClass: "mr-1 mb-1",
                        attrs: {
                          variant: "primary",
                          sz: "lg",
                          "data-cy": `dlg-promotion-other-options-${index}`,
                        },
                      },
                      [
                        _c("h6", { staticClass: "m-0 p-0" }, [
                          _vm._v(_vm._s(option)),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c("FooterDialogProduct", {
            attrs: {
              name: "dlg-promotion",
              actionType: "Show",
              disabled: _vm.isPromotionDeleted,
            },
            on: { "click-cancel": _vm.onCencel, "click-submit": _vm.onSubmit },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }