/**
 * It contains model of ProductUnit object.
 */
//TODO: move to model/product?

// General parse and serialize function
import { parse, serialize } from "../general.js";

/**
 * ProductUnit Model - for Parse and Serialize use.
 */
const PRODUCT_UNIT_MODEL = {
  id: "id",
  merchantId: "merchantId",
  name: "name"
};

/**
 * Parse a ProductUnit object.
 * @param {Object} productUnit ProductUnit object: Refer to PRODUCT_UNIT_MODEL.
 * @return {Object} Prased ProductUnit object.
 */
export function parseProductUnit(productUnit) {
  return productUnit;
  // TODO
  // return parse(productUnit, PRODUCT_UNIT_MODEL);
}

/**
 * Serialize a ProductUnit object.
 * @param {Object} productUnit ProductUnit object: Refer to PRODUCT_UNIT_MODEL.
 * @return {Object} Serialized ProductUnit object.
 */
export function serializeProductUnit(productUnit) {
  return productUnit;
  // TODO
  // return serialize(productUnit, PRODUCT_UNIT_MODEL);
}