import Vue from "vue";
import Router from "vue-router";
import AuthRequired from './utils/AuthRequired';

Vue.use(Router);

/**
 * Lazy Loading Routes:
 * https://router.vuejs.org/guide/advanced/lazy-loading.html#grouping-components-in-the-same-chunk
 * https://medium.com/@mrodal/how-to-make-lazy-loading-actually-work-in-vue-cli-3-7f3f88cfb102
 */

 // General
import App   from "./views/app";
import Error from "./views/Error";

// For user management
import User from "./views/user";
// const User = () => import(/* webpackChunkName: "User" */"./views/user");
const UserLogin    = () => import(/* webpackChunkName: "UserLogin" */     "./views/user/Login");
const UserRegister = () => import(/* webpackChunkName: "UserRegister" */  "./views/user/Register");
const UserActiveAccount         = () => import(/* webpackChunkName: "UserActiveAccount" */      "./views/user/ActiveAccount");
const UserConfirmationEmail     = () => import(/* webpackChunkName: "UserConfirmationEmail" */  "./views/user/ConfirmationEmail");
const UserForgotPassword        = () => import(/* webpackChunkName: "UserForgotPassword" */ "./views/user/ForgotPassword");
const UserResetPassword         = () => import(/* webpackChunkName: "UserResetPassword" */  "./views/user/ResetPassword");
const UserResendActivationEmail = () => import(/* webpackChunkName: "UserResendActivationEmail" */  "./views/user/ResendActivationEmail");



// Setup pages.
import Setup from "./views/app/pages/setup";
// Business Category
const BusinessProfile         = () => import(/* webpackChunkName: "BusinessProfile" */        "./views/app/pages/setup/BusinessProfile");
const BusinessProfileDetails  = () => import(/* webpackChunkName: "BusinessProfileDetails" */ "./views/app/pages/setup/BusinessProfileDetails");
const BusinessBrand           = () => import(/* webpackChunkName: "BusinessBrand" */          "./views/app/pages/setup/BusinessBrand");
const BusinessCategory        = () => import(/* webpackChunkName: "BusinessCategory" */       "./views/app/pages/setup/BusinessCategory");
const BusinessType            = () => import(/* webpackChunkName: "BusinessCategory" */       "./views/app/pages/setup/BusinessType");
const GlobalPoints            = () => import(/* webpackChunkName: "GlobalPoints" */           "./views/app/pages/setup/GlobalPoints");
const StripePayment           = () => import(/* webpackChunkName: "Stripe Payment" */         "./views/app/pages/setup/Payment");

const SocialMediaAccounts      = () => import(/* webpackChunkName: "Social Media Accounts" */  "./views/app/pages/setup/SocialMediaAccounts");
const Subscriptions      = () => import(/* webpackChunkName: "Subscriptions" */  "./views/app/pages/setup/Subscriptions");
/// Business Locations
import Stores from "./views/app/pages/setup/stores";
const StoresMain        = () => import(/* webpackChunkName: "StoresMain" */         "./views/app/pages/setup/stores/StoresMain");
const StoreGeneralInfo  = () => import(/* webpackChunkName: "StoreGeneralInfo" */   "./views/app/pages/setup/stores/StoreGeneralInfo");
const StoreTaxDetails   = () => import(/* webpackChunkName: "StoreGeneralInfo" */   "./views/app/pages/setup/stores/StoreTaxDetails");
const StoreDetails      = () => import(/* webpackChunkName: "StoreDetails" */       "./views/app/pages/setup/stores/StoreDetails");
const StoreDeliveryRuleMain = () => import(/* webpackChunkName: "StoreDeliveryRuleMain" */  "./views/app/pages/setup/stores/StoreDeliveryRuleMain");
const StoreDeliveryRuleItem = () => import(/* webpackChunkName: "StoreDeliveryRuleItem" */  "./views/app/pages/setup/stores/StoreDeliveryRuleItem");


// Products pages.
import ProductsMenu from "./views/app/pages/products";
const Addons    = () => import(/* webpackChunkName: "Addons" */   "./views/app/pages/products/Addons");
const Products  = () => import(/* webpackChunkName: "Products" */ "./views/app/pages/products/Products");
const AddonGroups   = () => import(/* webpackChunkName: "AddonGroups" */    "./views/app/pages/products/AddonGroups");
const ProductGroups = () => import(/* webpackChunkName: "ProductGroups" */  "./views/app/pages/products/ProductGroups");

// Promotion pages.
import Promotions         from "./views/app/pages/promotions";
// Main pages.
const NewPerk         = () => import(/* webpackChunkName: "NewPerk" */        "./views/app/pages/promotions/NewPerk");
const RewardsMain     = () => import(/* RewardsMain: "RewardsMain" */         "./views/app/pages/promotions/RewardsMain");
const PromotionsMain  = () => import(/* webpackChunkName: "PromotionsMain" */ "./views/app/pages/promotions/PromotionsMain");
// Detail pages.
const PerkGeneralInfo     = () => import(/* webpackChunkName: "PerkGeneralInfo" */    "./views/app/pages/promotions/PerkGeneralInfo");
const RewardsDetails      = () => import(/* webpackChunkName: "RewardsDetails" */     "./views/app/pages/promotions/RewardsDetails");
const PunchCardDetails    = () => import(/* webpackChunkName: "PunchCardDetails" */   "./views/app/pages/promotions/PunchCardDetails");
const PointsPerksDetails  = () => import(/* webpackChunkName: "PointsPerksDetails" */ "./views/app/pages/promotions/PointsPerksDetails");
const DealDiscountDetails1Items = () => import(/* webpackChunkName: "DealDiscountDetails1Items" */  "./views/app/pages/promotions/DealDiscountDetails1Items");
const DealDiscountDetails1Cart  = () => import(/* webpackChunkName: "DealDiscountDetails1Cart" */   "./views/app/pages/promotions/DealDiscountDetails1Cart");
const DealDiscountDetails2      = () => import(/* webpackChunkName: "DealDiscountDetails2" */       "./views/app/pages/promotions/DealDiscountDetails2");
const DealMultiItemsDetails1 = () => import(/* webpackChunkName: "DealMultiItemsDetails1" */  "./views/app/pages/promotions/DealMultiItemsDetails1");
const DealMultiItemsDetails2 = () => import(/* webpackChunkName: "DealMultiItemsDetails2" */  "./views/app/pages/promotions/DealMultiItemsDetails2");
const DealBuyOneGetOneFreeDetails1 = () => import(/* webpackChunkName: "DealBuyOneGetOneFreeDetails1" */  "./views/app/pages/promotions/DealBuyOneGetOneFreeDetails1");

// Social Media Posting pages.
import  socialMediaPosting        from "./views/app/pages/social-media-posting";
// Main pages.
const YourPosts         = () => import(/* webpackChunkName: "YourPosts" */        "./views/app/pages/social-media-posting/YourPosts");
const AIPostGenerator     = () => import(/* RewardsMain: "AIPostGenerator" */         "./views/app/pages/social-media-posting/AIPostGenerator");
const PublicHolidays     = () => import(/* RewardsMain: "PublicHolidays" */         "./views/app/pages/social-media-posting/PublicHolidays");
const PostOptions     = () => import(/* RewardsMain: "PostOptions" */         "./views/app/pages/social-media-posting/PostOptions");
const ExistingPerks     = () => import(/* AIPostGenerator: "ExistingPerks" */         "./views/app/pages/social-media-posting/ExistingPerks");

// Dashboards.
import Dashboards from "./views/app/dashboards";
const MerchantDashboard = () => import(/* webpackChunkName: "MerchantDashboard" */  "./views/app/dashboards/MerchantDashboard");
const SalesDashboard    = () => import(/* webpackChunkName: "SalesDashboard" */     "./views/app/dashboards/SalesDashboard");
const DetailChart       = () => import(/* webpackChunkName: "DetailChart" */        "./views/app/dashboards/DetailChart");

// Profiles
import Profiles from "./views/app/pages/profile"
const UserProfile = () => import(/* webpackChunkName: "UserProfile" */ "./views/app/pages/profile/UserProfile");

// Orders pages.
import Orders from "./views/app/pages/orders";
const OrdersMain = () => import(/* webpackChunkName: "OrdersMain" */ "./views/app/pages/orders/OrdersMain");

// Report pages.
import Report from "./views/app/pages/report";
import OrderHistory from "./views/app/pages/report/OrderHistory";
import OrderDetail from "./views/app/pages/report/OrderDetail";

// Support pages.
import Support from "./views/app/pages/support";
import SupportMain from "./views/app/pages/support/SupportMain";
import Posts from "@/views/app/pages/social-media-posting/Posts.vue";
import Success from "@/views/app/pages/setup/Success.vue";


const routes = [
  {
    path: "/app",
    component: App,
    redirect: { name:"app-dashboards" },
    beforeEnter: AuthRequired,
  },

  {
    path: "/",
    component: App,
    redirect: { name:"app-dashboards" },
    beforeEnter: AuthRequired,
    children: [
      // Route for setup
      {
        path: "app/setup",
        name: "app-setup",
        component: Setup,
        redirect: { name: "business-type" },
        children: [
          {
            path: "stores/:id",
            name: "stores",
            component: Stores,
            children: [
              // Store : 1st page.
              { path: "general", name: "store-general", component: StoreGeneralInfo },

              // Store : 2nd page.
              { path: "location", name: "store-location", component: BusinessBrand },

              // Store : 3rd page.
              { path: "tax-details", name: "store-tax-details", component: StoreTaxDetails },

              // Store : 3rd page.
              { path: "details", name: "store-details", component: StoreDetails },

              // Store : 4th page - Delivery Rules.
              { path: "delivery-rules", name: "delivery-rules", component: StoreDeliveryRuleMain },

              // Store : Delivery Rule Item.
              { path: "delivery-rules/:idx", name: "delivery-rules-item", component: StoreDeliveryRuleItem },
            ]
          },

          // Business profile: category, locations.
          { path: "business-brand", name: "business-brand", component: BusinessBrand },
          { path: "business-tax-details", name: "business-tax-details", component: StoreTaxDetails },
          { path: "business-type", name: "business-type", component: BusinessType },
          { path: "business-category", name: "business-category", component: BusinessCategory },
          { path: "business-location", name: "business-location", component: StoresMain },
          { path: "business-profile", name: "business-profile", component: BusinessProfile },
          { path: "business-profile-details", name: "business-profile-details", component: BusinessProfileDetails },
          { path: "global-points", name: "global-points", component: GlobalPoints},
          { path: "payment", name: "payment", component: StripePayment },
          { path: "social-media-accounts", name: "social-media-accounts", component: SocialMediaAccounts},
          { path: "subscriptions", name: "subscriptions", component: Subscriptions},
          { path: "success", name: "success", component: Success}
        ]
      },

      // Route for products
      {
        path: "app/products",
        name: "app-products",
        component: ProductsMenu,
        redirect: { name: 'products' },
        children: [
          { path: "addons", name: 'addons', component: Addons },
          { path: "products", name: 'products', component: Products },
          { path: "addon-groups", name: 'addon-groups', component: AddonGroups },
          { path: "product-groups", name: 'product-groups', component: ProductGroups },
        ]
      },

      // Route for promotions
      {
        path: "/app/promotions",
        name: "app-promotions",
        component: Promotions,
        redirect: { name: "promotions-main" },
        children: [
          { path: "promotions-main", name: "promotions-main", component: PromotionsMain },
          { path: "new-perk", name: "promotions-new", component: NewPerk },
          { path: "rewards", name: "promotions-rewards", component: RewardsMain },

          // 1st page of the Promotions.
          {
            path: ":type/:action/general",
            name: "promotion-general",
            component: PerkGeneralInfo,
          },

          // Point Perk - Details
          {
            path: "point-perk/:action/details",
            name: "point-perk-details",
            component: PointsPerksDetails,
          },

          // Punch Card - Details
          {
            path: "punch-card/:action/details",
            name: "punch-card-details",
            component: PunchCardDetails,
          },

          // Reward Item - It is the only page for Reward Item.
          {
            path: "reward-item/:action/details",
            name: "reward-item-details",
            component: RewardsDetails,
          },

          // Deals
          // Deal - Buy One Get One Free - Details.
          {
            path: ":type/:action/details1",
            name: "deal-buy-one-get-one-details1",
            component: DealBuyOneGetOneFreeDetails1,
          },
          {
            path: ":type/:action/details2",
            name: "deal-buy-one-get-one-details2",
            component: DealMultiItemsDetails2,
          },

          // Deal - Free Item - Details.
          {
            path: "item/:type/:action/details1",
            name: "deal-free-item-details1",
            component: DealDiscountDetails1Items,
          },
          {
            path: "item/:type/:action/details2",
            name: "deal-free-item-details2",
            component: DealDiscountDetails2,
          },

          // Deal - Discount Items - Percent - Details.
          {
            path: "item/:type/:action/details1",
            name: "deal-discount-items-p-details1",
            component: DealDiscountDetails1Items,
          },
          {
            path: "item/:type/:action/details2",
            name: "deal-discount-items-p-details2",
            component: DealDiscountDetails2,
          },

          // Deal - Discount Cart - Percent - Details.
          {
            path: "cart/:type/:action/details1",
            name: "deal-discount-cart-p-details1",
            component: DealDiscountDetails1Cart,
          },
          {
            path: "cart/:type/:action/details2",
            name: "deal-discount-cart-p-details2",
            component: DealDiscountDetails2,
          },

          // Deal - Discount Cart - Fixed - Details.
          {
            path: "cart/:type/:action/details1",
            name: "deal-discount-cart-f-details1",
            component: DealDiscountDetails1Cart,
          },
          {
            path: "cart/:type/:action/details2",
            name: "deal-discount-cart-f-details2",
            component: DealDiscountDetails2,
          },

          // Deal - Combo - Details.
          {
            path: "multi/:type/:action/details1",
            name: "deal-multi-combo-details1",
            component: DealMultiItemsDetails1,
          },
          {
            path: "multi/:type/:action/details2",
            name: "deal-multi-combo-details2",
            component: DealMultiItemsDetails2,
          },

          // Deal - Bundle - Details.
          {
            path: "multi/:type/:action/details1",
            name: "deal-multi-bundle-details1",
            component: DealMultiItemsDetails1,
          },
          {
            path: "multi/:type/:action/details2",
            name: "deal-multi-bundle-details2",
            component: DealMultiItemsDetails2,
          },
        ]
      },
      // Route for social media posting
      {
        path: "app/social-media-posting",
        name: "app-social-media-posting",
        component: socialMediaPosting,
        redirect: { name: 'ai-post-generator' },
        children: [
          { path: "your-posts", name: 'your-posts', component: Posts },
          { path: "ai-post-generator", name: 'ai-post-generator', component: AIPostGenerator },
          { path: "public-holidays", name: 'public-holidays', component: PublicHolidays },
          { path: "post-options", name: 'post-options', component: PostOptions },
          { path: "existing-perks", name: 'existing-perks', component: ExistingPerks },
        ]
      },

      // Route for profile
      {
        path: "/app/profile",
        name: "app-profile",
        component: Profiles,
        redirect: { name: "user-profile" },
        children: [
          {
            path: "user-profile",
            name: "user-profile",
            component: UserProfile
          },
        ]
      },

      // Route for dashboards
      {
        path: "app/dashboards",
        name: "app-dashboards",
        component: Dashboards,
        redirect: { name: "merchant-dashboard" },
        children: [
          {
            path: "sales-dashboard",
            name: "sales-dashboard",
            component: SalesDashboard
          },
          {
            path: "merchant-dashboard",
            name: "merchant-dashboard",
            component: MerchantDashboard
          },
          {
            path: "detail-chart/:metric",
            name: "detail-chart",
            component: DetailChart
          },
        ]
      },

      // Route for report
      {
        path: "/app/report",
        name: "app-report",
        component: Report,
        redirect: { name: "order-history" },
        children: [
          { path: "order-history", name: "order-history", component: OrderHistory },
          { path: ":id", name: "order-detail", component: OrderDetail},
        ]
      },

      // Route for support
      {
        path: "/app/support",
        name: "app-support",
        component: Report,
        redirect: { name: "support-main" },
        children: [
          { path: "support-main", name: "support-main", component: SupportMain },
        ]
      }
    ]
  },

  // Route for Orders
  {
    path: "/app/orders",
    name: "app-orders",
    component: Orders,
    redirect: { name: "orders-main", params: { id: "main" } },
    beforeEnter: AuthRequired,
    children: [
      { path: "orders-main/:id", name: "orders-main", component: OrdersMain },
    ],
  },

  // Route for user management.
  {
    path: "/user",
    component: User,
    redirect: { name: "login" },
    children: [
      {
        path: "login",
        name: "login",
        component: UserLogin,
        alias: "/login"
      },
      {
        path: "register",
        name: "register",
        component: UserRegister,
        alias: "/register"
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: UserForgotPassword,
        alias: "/forgot-password"
      },
      {
        path: "reset-password",
        name: "reset-password",
        component: UserResetPassword,
        alias: "/reset_password"
      },
      {
        path: "confirm-email",
        name: "confirm-email",
        component: UserConfirmationEmail,
        alias: "/confirm-email"
      },
      {
        path: "active_account",
        name: "active-account",
        component: UserActiveAccount,
        alias: "/active_account"
      },
      {
        path: "resend-activation-email",
        name: "resend-activation-email",
        component: UserResendActivationEmail,
        props: true
      }
    ]
  },

  // Route for errors
  { path: "/error", name:"error", component: Error },
  {
    path: "*",
    component: Error,
    redirect: { name: "app-dashboards" },
  },
];



const router =new Router({
  linkActiveClass: "active",
  routes,
  mode: "history"
});

export default router
