var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-2" },
    [
      _c("dialog-date-time", {
        attrs: {
          disabled: _vm.disabled,
          data: _vm.data,
          dialog: _vm.dialogs.schedule,
          disableHideUntil: true,
        },
        on: {
          "dialog-close": _vm.closeDialogSchedule,
          "dialog-submit": _vm.onSubmitDialogSchedule,
        },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        { attrs: { "align-v": "center" } },
        [
          _c(
            "b-colxx",
            { attrs: { xxs: "2" } },
            [
              _c("toggle-button-check", {
                attrs: { name: "date-time-limit", disabled: _vm.disabled },
                model: {
                  value: _vm.isTimeLimited,
                  callback: function ($$v) {
                    _vm.isTimeLimited = $$v
                  },
                  expression: "isTimeLimited",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-colxx", { attrs: { xxs: "6" } }, [
            _c("h6", [
              _vm._v(_vm._s(_vm.$t("promotions.date-time-limit-title"))),
            ]),
          ]),
          _vm._v(" "),
          _vm.isTimeLimited
            ? _c(
                "b-colxx",
                [
                  _c(
                    "a",
                    { attrs: { href: "javascript:void(0);" } },
                    [
                      _c("b-img", {
                        staticClass: "no-checkbox cls-img-btn-big ml-2",
                        attrs: {
                          "data-cy": "date-time-limit-btn-availability",
                          id: "schedule-" + _vm.data.id,
                          src: "/assets/icons/work-schedule.svg",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openDialogSchedule()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-tooltip", {
                    attrs: {
                      target: "schedule-" + _vm.data.id,
                      title: _vm.$t("tool-tips.schedule"),
                      placement: "bottom",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }