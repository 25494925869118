var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "my-2 py-1",
      attrs: {
        "data-cy": `list-item-order-product-${_vm.name}`,
        "no-body": "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "h3",
                  {
                    staticClass:
                      "text-left font-weight-bold ml-5 mt-3 mb-2 pb-0",
                  },
                  [
                    _vm.detailed
                      ? [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                "data-cy": `list-item-order-product-${_vm.name}-link`,
                                to: `?p=${_vm.data.productId}`,
                              },
                            },
                            [
                              _c("span", { on: { click: _vm.onTitleClick } }, [
                                _vm._v(_vm._s(_vm.data.productName)),
                              ]),
                            ]
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.data.productName) +
                              "\n          "
                          ),
                        ],
                    _vm._v(" "),
                    _vm.productType != null
                      ? _c(
                          "b-badge",
                          {
                            staticClass: "item-type-badge",
                            attrs: {
                              variant: _vm.isRedeemItem() ? "primary" : "info",
                              "data-cy": `item-order-product-${_vm.name}-link`,
                            },
                          },
                          [_vm._v(_vm._s(_vm.productType) + "\n          ")]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-3" },
            [
              !_vm.isNullOrUndefined(_vm.data.quantity) &&
              !_vm.isNullOrUndefined(_vm.data.price) &&
              !_vm.isNullOrUndefined(_vm.data.totalAmount)
                ? _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("h6", { staticClass: "ml-5 mb-0 pb-0" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.data.quantity) +
                              " x $" +
                              _vm._s(_vm.formatNumber(_vm.data.price)) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("b-col", [
                        _c("h6", { staticClass: "text-right mr-5 mb-0 pb-0" }, [
                          _vm._v(
                            "\n            $" +
                              _vm._s(_vm.formatNumber(_vm.data.totalAmount)) +
                              "\n          "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNullOrUndefined(_vm.data.totalDiscountAmount) &&
              _vm.data.totalDiscountAmount > 0
                ? _c(
                    "b-row",
                    { staticClass: "text-muted" },
                    [
                      _c("b-col", [
                        _c("h6", { staticClass: "ml-5 mb-0 pb-0" }, [
                          _vm._v(_vm._s(_vm.$t("general.discounts"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("b-col", [
                        _c("h6", { staticClass: "mr-5 text-right mb-0 pb-0" }, [
                          _vm._v(
                            "\n            - $" +
                              _vm._s(
                                _vm.formatNumber(_vm.data.totalDiscountAmount)
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isNullOrUndefined(_vm.data.appliedTaxes) == false
                ? _vm._l(_vm.data.appliedTaxes, function (tax, taxIdx) {
                    return _c("ListAppliedTaxOnItem", {
                      key: `product-${taxIdx}`,
                      attrs: { data: tax, name: `${taxIdx}`, type: "product" },
                    })
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _vm._l(_vm.data.addons, function (addon, index) {
            return [
              _c(
                "b-row",
                { key: `title-${index}` },
                [
                  _c("b-col", { staticClass: "order-item-name" }, [
                    _c(
                      "h6",
                      { staticClass: "text-left text-muted ml-5 mb-1 pb-0" },
                      [
                        _vm.detailed
                          ? [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    "data-cy": `list-item-order-addon-${_vm.name}-name-${index}`,
                                    to: `?p=${addon.productId}`,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAddonClick(
                                            addon.productId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(addon.productName))]
                                  ),
                                ]
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n              " +
                                  _vm._s(addon.productName) +
                                  "\n            "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { key: index, staticClass: "ml-3" },
                [
                  !_vm.isNullOrUndefined(addon.price) &&
                  !_vm.isNullOrUndefined(addon.quantity) &&
                  !_vm.isNullOrUndefined(_vm.data.quantity)
                    ? _c(
                        "b-row",
                        { key: `quantity-${index}` },
                        [
                          _c("b-col", [
                            _c("p", { staticClass: "ml-5 mb-0 pb-0" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(addon.quantity * _vm.data.quantity) +
                                  " x $" +
                                  _vm._s(_vm.formatNumber(addon.price)) +
                                  "\n            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("b-col", [
                            _c(
                              "p",
                              { staticClass: "text-right mr-5 mb-0 pb-0" },
                              [
                                _vm._v(
                                  "\n              $" +
                                    _vm._s(
                                      _vm.formatNumber(
                                        addon.price *
                                          addon.quantity *
                                          _vm.data.quantity
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isNullOrUndefined(addon.totalDiscountAmount) &&
                  addon.totalDiscountAmount > 0
                    ? _c(
                        "b-row",
                        { key: `discount-${index}`, staticClass: "text-muted" },
                        [
                          _c("b-col", [
                            _c("p", { staticClass: "ml-5 mb-0 pb-0" }, [
                              _vm._v(_vm._s(_vm.$t("general.discounts"))),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("b-col", [
                            _c(
                              "p",
                              { staticClass: "mr-5 text-right mb-0 pb-0" },
                              [
                                _vm._v(
                                  "\n              - $" +
                                    _vm._s(
                                      _vm.formatNumber(
                                        addon.totalDiscountAmount
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isNullOrUndefined(addon.appliedTaxes) == false
                    ? _vm._l(addon.appliedTaxes, function (tax, taxIdx) {
                        return _c("ListAppliedTaxOnItem", {
                          key: `tax-${index}-${taxIdx}`,
                          attrs: {
                            data: tax,
                            name: `${taxIdx}`,
                            type: "addon",
                          },
                        })
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.data.instruction
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "1" } }, [
                _c("div", { staticClass: "order-num-items ml-2 p-0" }, [
                  _c("p", { staticClass: "text-right text-medium mt-2 mx-2" }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "order-item-name", attrs: { cols: "8" } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-left text-medium text-muted ml-2 mb-2",
                      attrs: {
                        "data-cy": `list-item-order-product-${_vm.name}-instructions`,
                      },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.data.instruction) + "\n      "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }