/**
 * @fileoverview It create axios for the INTRA_SERVICE_STRIPE_PAYMENT service in the application.
 * @author Hung Phung
 */

// Constant.
import { INTRA_SERVICE_STRIPE_PAYMENT } from "@/constants/config.js";

// Modules in the application.
import { axios_base, interceptResponseSuccess, interceptResponseFail } from "./axios_base";
import LocalStorageService from "@/utils/local_store.js";

const axios_stripe_payment = axios_base.create();

/**
 * Intercept the request to set additional headers.
 */
axios_stripe_payment.interceptors.request.use(
  config => {
    const token = LocalStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers[`intra-service-name`] = INTRA_SERVICE_STRIPE_PAYMENT;
    } // else
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

/**
 * Intercept function for the response of axios when it's failed.
 * @param {Object} error Error object from axios.
 * @return {Promise} Promise object.
 */
const customInterceptResponseFail = function (error) {
  return interceptResponseFail(error, axios_stripe_payment);
}

/**
 * Intercept the response.
 */
axios_stripe_payment.interceptors.response.use(
  interceptResponseSuccess,
  customInterceptResponseFail
);


export default axios_stripe_payment;
