
/**
 * It contains backend Apis for global points.
 * @author: Hanson Phung
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// Modules in the application.
import axios from "@/api/axios/axios_stripe_payment";

// URLs for Backend
const HOST = BE_URLS.BACKEND_PAYMENT_SERVER_URL;

const CHECK_STRIPE_CONNECTED_URL = `${HOST}/accounts/check/has-account`;
const CONNECT_STRIPE_ACCOUNT_URL = `${HOST}/accounts/user/stripe/connect`;
const STRIPE_ONBOARDING_URL = `${HOST}/accounts/onboarding-link`;
const STRIPE_DASHBOARD_URL = `${HOST}/accounts/merchant/dashboard`;


/**
   * Send request to check whether merchant Stripe account is connected to platform
   * @return {Promise} POST Promise.
   */
export function sendCheckStripeConnectedApi(data) {
    return axios.get(`${CHECK_STRIPE_CONNECTED_URL}/${data.merchantId}`);
}

/**
   * Send request to connect merchant Stripe account to platform
   * @return {Promise} POST Promise.
   */
export function sendConnectMerchantStripeApi(data) {
    return axios.post(`${CONNECT_STRIPE_ACCOUNT_URL}/${data.authCode}`);
}

/**
 * Send request to get Stripe Express Dashboard of the merchant
 */
export function getStripeDashboardUrlApi() {
    return axios.get(STRIPE_DASHBOARD_URL);
}


/**
 * Get Stripe onboarding link to connect merchant Stripe to platform
 * @return Result of Get request for Stripe Onboarding Link 
 */
export function getStripeOnboardingLinkApi() {
    return axios.get(STRIPE_ONBOARDING_URL);
}
