var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      class: {
        "d-flex flex-row": true,
        active: _vm.selectedItems.includes(_vm.data.id),
      },
      attrs: { "data-cy": `${_vm.cyPrefix}`, "no-body": "" },
      on: {
        click: function ($event) {
          return _vm.onClickEvent($event, _vm.data.id)
        },
      },
    },
    [
      _c("dialog-date-time", {
        attrs: {
          disabled: _vm.data.showOnly,
          data: _vm.data,
          dialog: _vm.dialogs.schedule,
        },
        on: {
          "dialog-close": _vm.closeDialogSchedule,
          "dialog-submit": _vm.onSubmitDialogSchedule,
        },
      }),
      _vm._v(" "),
      _c("dialog-alert", {
        attrs: {
          dialog: _vm.dialogs.alert,
          description: _vm.$t("alert.delete"),
          actionType: _vm.$t("buttons.delete"),
          buttonSubmitText: _vm.$t("buttons.delete"),
        },
        on: {
          "dialog-close": _vm.closeDialogAlert,
          "dialog-submit": _vm.onSubmitDialogAlert,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "align-self-center" },
        [
          _c(
            "router-link",
            { staticClass: "d-flex", attrs: { to: `?p=${_vm.data.id}` } },
            [
              _c("b-img", {
                staticClass: "list-thumbnail responsive border-0",
                attrs: {
                  src:
                    Array.isArray(_vm.data.images) && _vm.data.images.length > 0
                      ? _vm.data.images[0]
                      : "/assets/img/no-image.png",
                  alt: _vm.data.title,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pl-2 d-flex flex-grow-1 min-width-zero" },
        [
          _c(
            "div",
            {
              staticClass:
                "card-body align-self-center flex-lg-row justify-content-between min-width-zero align-items-lg-center w-60",
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "w-60 w-sm-100",
                  attrs: { to: `?p=${_vm.data.id}` },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: `?p=${_vm.data.id}` } },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "no-checkbox list-item-heading mb-1 truncate",
                              attrs: {
                                "data-cy": `${_vm.cyPrefix}-title`,
                                id: "title-" + _vm.data.id,
                              },
                              on: { click: _vm.onClickTitle },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.data.name) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("b-tooltip", {
                        attrs: {
                          target: "title-" + _vm.data.id,
                          placement: "bottom",
                          title: _vm.$t("tool-tips.detail"),
                        },
                      }),
                      _vm._v(" "),
                      Array.isArray(_vm.data.categories) &&
                      _vm.data.categories.length > 0
                        ? _c("BadgeListCategory", {
                            attrs: {
                              cyPrefix: `${_vm.cyPrefix}`,
                              data: _vm.data.categories,
                            },
                            on: { onDelete: _vm.deleteBadgeListCategory },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.data.isAddon === false &&
                      Array.isArray(_vm.data.groups) &&
                      _vm.data.groups.length > 0
                        ? _c("BadgeListProductGroup", {
                            attrs: {
                              cyPrefix: `${_vm.cyPrefix}`,
                              data: _vm.data.groups,
                            },
                            on: { onDelete: _vm.deleteBadgeGroup },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      Array.isArray(_vm.data.stores) &&
                      _vm.data.stores.length > 0
                        ? _c("BadgeListStore", {
                            attrs: {
                              cyPrefix: `${_vm.cyPrefix}`,
                              data: _vm.data.stores,
                            },
                            on: { onDelete: _vm.deleteBadgeStore },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.data.isAddon === false &&
                      Array.isArray(_vm.data.addonGroups) &&
                      _vm.data.addonGroups.length > 0
                        ? _c("BadgeListAddonGroup", {
                            attrs: {
                              cyPrefix: `${_vm.cyPrefix}`,
                              data: _vm.data.addonGroups,
                              variant: "outline-dark",
                            },
                            on: { onDelete: _vm.deleteBadgeAddonGroup },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.data.isAddon === true &&
                      Array.isArray(_vm.data.groups) &&
                      _vm.data.groups.length > 0
                        ? _c("BadgeListAddonGroup", {
                            attrs: {
                              cyPrefix: `${_vm.cyPrefix}`,
                              data: _vm.data.groups,
                            },
                            on: { onDelete: _vm.deleteBadgeGroup },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-row", [
                    _c(
                      "p",
                      {
                        staticClass: "mb-1 text-muted text-small",
                        attrs: { "data-cy": `${_vm.cyPrefix}-description` },
                      },
                      [_vm._v(_vm._s(_vm.data.description))]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.data.price
                    ? _c("b-row", [
                        _c(
                          "p",
                          {
                            staticClass: "mb-1 text-default text-one",
                            attrs: { "data-cy": `${_vm.cyPrefix}-price` },
                          },
                          [
                            _vm._v(
                              "$" + _vm._s(_vm.formatNumber(_vm.data.price))
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isRewardItem
            ? _c("ButtonBlockInListProduct", {
                attrs: {
                  config: {
                    id: _vm.data.id,
                    name: `${_vm.cyPrefix}`,
                    toggleBtn: {
                      status: _vm.isGroup
                        ? _vm.isProductsInGroup
                          ? _vm.data.status
                          : undefined
                        : _vm.data.status,
                      disabled: _vm.isGroup
                        ? !_vm.isProductsInGroup
                        : _vm.isOrphanedAddon,
                      disabledReason: _vm.disabledReason,
                      change: function (payload) {
                        _vm.updateStatus(payload)
                      },
                    },
                    imgBtnSchedule: _vm.buttons.includes("schedule")
                      ? {
                          click: function () {
                            _vm.openDialogSchedule()
                          },
                        }
                      : null,
                    imgBtnDuplicate: _vm.buttons.includes("duplicate")
                      ? {
                          click: function () {
                            _vm.onDuplicateItem()
                          },
                        }
                      : null,
                    imgBtnShow: _vm.buttons.includes("show")
                      ? {
                          click: function () {
                            _vm.onShowItem()
                          },
                        }
                      : null,
                    imgBtnAdd: _vm.buttons.includes("add")
                      ? {
                          click: function () {
                            _vm.onAddItem()
                          },
                        }
                      : null,
                    imgBtnEdit: _vm.buttons.includes("edit")
                      ? {
                          click: function () {
                            _vm.onEditItem()
                          },
                        }
                      : null,
                    imgBtnDelete: _vm.buttons.includes("delete")
                      ? {
                          click: function () {
                            _vm.openDialogAlert()
                          },
                        }
                      : null,
                    checkBox: _vm.buttons.includes("checkbox")
                      ? { checked: _vm.selectedItems.includes(_vm.data.id) }
                      : null,
                    lastUpdate: {
                      update: _vm.data.lastUpdatedAt,
                    },
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isRewardItem
            ? _c("ButtonBlockInListReward", {
                attrs: {
                  config: {
                    id: _vm.data.id,
                    name: `${_vm.cyPrefix}`,
                    points: {
                      requiredPoints: _vm.data.requiredPoints,
                    },
                    toggleBtn: {
                      status: _vm.data.status,
                      disabled: !_vm.data.isValid,
                      change: function (payload) {
                        _vm.updateStatus(payload)
                      },
                    },
                    imgBtnDuplicate: {
                      click: function () {
                        _vm.onDuplicateItem()
                      },
                    },
                    imgBtnEdit: {
                      click: function () {
                        _vm.onEditItem()
                      },
                    },
                    imgBtnDelete: {
                      click: function () {
                        _vm.openDialogAlert()
                      },
                    },
                    expireDate: _vm.data.availableTo,
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }