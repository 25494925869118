// TODO : add in-code documentation :done
// TODO : update the model. it seems not completed.

import {parse, serialize} from "./general.js";

const SALES_DASHBOARD = {
    metric: "metric",
    platform: "platform",
    total: "total"
};

/** 
 * Parsing 
 * 
 * @param merchantStore 
 */
export function parseSalesDashboard(merchantStore) {
    return parse(merchantStore, SALES_DASHBOARD);
}

/**
 * Serialize using model
 * 
 * @param merchantStore 
 */
export function serializeSalesDashboard(merchantStore) {
    return serialize(merchantStore, SALES_DASHBOARD);
}
