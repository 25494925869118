var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalNameDescription",
      staticClass: "modal-right",
      attrs: { id: "modalNameDescription", title: _vm.title, size: "sm" },
      on: {
        hide: function ($event) {
          return _vm.onCencel()
        },
      },
    },
    [
      _c(
        "b-form",
        [
          _c(
            "b-row",
            [
              _c(
                "b-colxx",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("product.provide-name-description"),
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          "data-cy": `${_vm.name}-input-name`,
                          placeholder: _vm.$t("forms.name"),
                          state: !_vm.$v.data.name.$invalid || _vm.isFirstLoad,
                        },
                        model: {
                          value: _vm.data.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "name", $$v)
                          },
                          expression: "data.name",
                        },
                      }),
                      _vm._v(" "),
                      _c("b-form-invalid-feedback", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("validations.no-name-validation")) +
                            "\n          "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    [
                      _c("b-textarea", {
                        attrs: {
                          "data-cy": `${_vm.name}-input-description`,
                          rows: 2,
                          "max-rows": 2,
                          placeholder: _vm.$t("forms.descriptions"),
                        },
                        model: {
                          value: _vm.data.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "description", $$v)
                          },
                          expression: "data.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              attrs: {
                "data-cy": `${_vm.name}-btn-cancel`,
                variant: "outline-secondary",
              },
              on: {
                click: function ($event) {
                  return _vm.onCencel()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mr-1",
              attrs: {
                "data-cy": `${_vm.name}-btn-submit`,
                variant: "primary",
                disabled: _vm.$v.data.$invalid && !_vm.isFirstLoad,
              },
              on: {
                click: function ($event) {
                  return _vm.onSubmit()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.submit")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }