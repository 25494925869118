var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      staticClass: "mr-1 btn-group align-top",
      attrs: {
        "data-cy": "top-dropdown-addon-group",
        id: "ddown4",
        text: `${_vm.$t("objects.addon-group")}: ${_vm.label}`,
        variant: "outline-dark",
        size: "xs",
        "no-flip": "",
      },
    },
    _vm._l(_vm.selectableAddonGroups, function (group, index) {
      return _c(
        "b-dropdown-item",
        {
          key: index,
          attrs: { "data-cy": `top-dropdown-addon-group-item-${index}` },
          on: {
            click: function ($event) {
              return _vm.onClick(group)
            },
          },
        },
        [_vm._v("\n  " + _vm._s(group.label) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }