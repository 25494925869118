var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order-history" },
    [
      _c("top-menu", {
        attrs: {
          title: "Order History",
          config: {
            searchOnEnter: { onEnter: _vm.onSearchEnter },
            filterStore: { click: _vm.onStoreFilter },
            dateRange: { change: _vm.onDateFilter },
            serveOption: { change: _vm.onServeOptionsChange },
            orderStatus: { click: _vm.onOrderStatusClick },
            pageSize: { change: _vm.onPageSizeChange },
            filterColumns: {
              change: _vm.onFilterColumns,
              defaultSelected: _vm.defaultSelectedColumns,
            },
            clearAllFilters: { click: _vm.onClearFiltersClick },
            filters: {
              store: _vm.selectedStore,
              serveOptions:
                _vm.serveOptions.length > 0 ? _vm.serveOptions : undefined,
              duration:
                _vm.selectedReportDuration.start &&
                _vm.selectedReportDuration.end
                  ? _vm.selectedReportDuration
                  : _vm.defaultReportDuration,
              pageSize: _vm.pageSize,
              searchText: _vm.searchText,
              fields: _vm.currentSelectedFieldsValues,
              orderStatus: _vm.orderStatus,
            },
          },
        },
      }),
      _vm._v(" "),
      _c(
        "b-card",
        { staticClass: "w-100", attrs: { "body-class": "py-0 px-0" } },
        [
          _c("b-table", {
            staticClass: "text-center",
            attrs: {
              items: _vm.orders,
              fields: _vm.fields,
              "thead-class": "primary-color-table-head",
              "data-cy": `table-order-history`,
              "primary-key": "orderDetail",
              hover: "",
              responsive: "",
              "no-border-collapse": "",
              "sort-by": _vm.sortBySync,
              "sort-desc": _vm.sortDescSync,
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBySync = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBySync = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortDescSync = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDescSync = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "cell(id)",
                fn: function (data) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "order-number-link",
                        attrs: {
                          to: {
                            name: "order-detail",
                            params: { id: data.value },
                          },
                          "data-cy": `table-order-history-number-details-${data.index}-btn-show`,
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.orderNumber(data.value)) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(storeId)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.storeName(data.value)) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "cell(customerName)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.customerName(data.value)) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "cell(customerPhone)",
                fn: function (data) {
                  return [
                    data.value && data.value !== null
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatPhoneNumber(data.value)) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.not-applicable")) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
              {
                key: "cell(subAmount)",
                fn: function (data) {
                  return [
                    data.value !== null && data.value !== ""
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatAmount(data.value)) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.not-applicable")) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
              {
                key: "cell(discountAmount)",
                fn: function (data) {
                  return [
                    data.value !== null && data.value !== ""
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatAmount(data.value)) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.not-applicable")) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
              {
                key: "cell(appliedTaxes)",
                fn: function (data) {
                  return [
                    data.value !== null && data.value !== ""
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.taxAmount(data.value)) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.not-applicable")) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
              {
                key: "cell(totalAmount)",
                fn: function (data) {
                  return [
                    data.value !== null && data.value !== ""
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatAmount(data.value)) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.not-applicable")) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
              {
                key: "cell(globalPointsUsed)",
                fn: function (data) {
                  return [
                    data.value && data.value > 0
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                `${data.value} ${_vm.$t(
                                  "order.reward-points-suffix"
                                )}`
                              ) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.not-applicable")) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
              {
                key: "cell(isPickup)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.serveType(data.value)) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "cell(preparingTime)",
                fn: function (data) {
                  return [
                    data.value && data.value !== null
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatTime(data.value)) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.not-applicable")) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
              {
                key: "cell(deliveringTime)",
                fn: function (data) {
                  return [
                    _vm.isDeliveryOrder(data.item)
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatTime(data.value)) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.not-applicable")) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
              {
                key: "cell(deliveryAddress)",
                fn: function (data) {
                  return [
                    _vm.isDeliveryOrder(data.item)
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatAddress(data.value)) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.not-applicable")) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
              {
                key: "cell(deliveryDistance)",
                fn: function (data) {
                  return [
                    _vm.isDeliveryOrder(data.item)
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatDistance(data.value)) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.not-applicable")) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
              {
                key: "cell(deliveryPrice)",
                fn: function (data) {
                  return [
                    _vm.isDeliveryOrder(data.item)
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatAmount(data.value)) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.not-applicable")) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function (data) {
                  return [
                    _c(
                      "b-badge",
                      {
                        attrs: { variant: _vm.orderStatusVariant(data.value) },
                      },
                      [_vm._v(_vm._s(data.value))]
                    ),
                  ]
                },
              },
              {
                key: "cell(lastUpdatedAt)",
                fn: function (data) {
                  return [
                    data.value !== null
                      ? [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.formatDateWithTime(data.value)) +
                              "\n        "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.not-applicable")) +
                              "\n        "
                          ),
                        ],
                  ]
                },
              },
              {
                key: "cell(orderDetails)",
                fn: function (data) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "order-detail",
                            params: { id: data.item.id },
                          },
                          "data-cy": `table-order-history-details-${data.index}-btn-show`,
                        },
                      },
                      [
                        _c("b-img", {
                          staticClass: "cls-btn-icon",
                          attrs: { src: "/assets/img/eye.png", id: "btn_edit" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.pagination.totalOrders,
              "per-page": _vm.pageSize.value,
              align: "center",
              "data-cy": `table-order-history-pagination`,
              "last-class": "pagination-buttons-size",
              "first-class": "pagination-buttons-size",
              "next-class": "pagination-buttons-size",
              "prev-class": "pagination-buttons-size",
            },
            on: { input: _vm.onPaginationClick },
            model: {
              value: _vm.pageNumberVModel,
              callback: function ($$v) {
                _vm.pageNumberVModel = $$v
              },
              expression: "pageNumberVModel",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }