// Footer Component for Dialogs.
// Author : Huen Oh (heons921@gmail.com)
// @param {bool}   disabled   Disabled property of the component. (in)
// @param {string} name       For data-cy value
// @param {string} actionType Type of action : "Add", "Edit" or "Show".

<template >
  <div>
    <!-- Button : Cancel -->
    <b-button 
      :data-cy="`${name}-btn-cancel`" 
      variant="outline-secondary" 
      @click="onClick()"
    >
      {{ $t('buttons.cancel') }}
    </b-button>
    <!-- Button : Edit or Submit -->
    <b-button 
      :data-cy="actionType==$t('actions.show') ? `${name}-btn-edit` : `${name}-btn-submit`" 
      variant="primary" 
      class="mr-1" 
      @click="onSubmit()" 
      :disabled="disabled"
    >
      {{actionType==$t('actions.show') ? $t('buttons.edit') : $t('buttons.submit')}}
    </b-button>
  </div>
</template>


<script>

export default {
  props:{
    // {string} Name of the component. For data-cy.
    name: {
      type: String,
      default: ""
    },
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {string} actionType Type of action : "Add", "Edit" or "Show".
    actionType:{
      type: String,
      default: ""
    },
  },
 
  methods:{
    /**
     * On click the button for cancel operation.
     */
    onClick() {
      this.$emit('click-cancel');
    },
    /**
     * On click the button for submit operation.
     */
    onSubmit() {
      this.$emit('click-submit');
    },
  }
}
</script>