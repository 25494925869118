import {
  themeRadiusStorageKey
} from "@/constants/config";

/**
 * Class created with various functions
 */
class LocalStorageService {

  // 1. Theme and Global settings

  /**
   * Get direction value, used in theme setting utils/index.js
   * @returns String direction value
   */
  getDirection() {
    return localStorage.getItem("direction");
  }

  /**
   * Set direction value.
   * @param {String} direction rtl | ltr
   */
  setDirection(direction) {
    localStorage.setItem("direction", direction);
  }

  /**
   * Get themeColor value.
   * @returns color value for current active theme.
   */
  getThemeColor() {
    return localStorage.getItem("themeColor");
  }

  /**
   * Set themeColor
   * @param {String} color theme color
   */
  setThemeColor(color) {
    localStorage.setItem("themeColor", color);
  }

  /**
   * Get theme_radius value.
   * @returns String theme radius value for current active theme. Ex: rounded.
   */
  getThemeRadius() {
    return localStorage.getItem(themeRadiusStorageKey);
  }

  /**
   * Set theme_radius.
   * @param {String} radius theme radius value
   */
  setThemeRadius(radius) {
    localStorage.setItem(themeRadiusStorageKey, radius)
  }

  /**
   * Get currentLanguage.
   * @returns String current language
   */
  getCurrentLanguage() {
    return localStorage.getItem("currentLanguage");
  }

  /**
   * Set currentLanguage option.
   * @param {String} currentLanguage language option
   */
  setCurrentLanguage(currentLanguage) {
    localStorage.setItem("currentLanguage", currentLanguage);
  }


  // 2. Authorization and User information

  /**
   * Get user value.
   * @returns user
   */
  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  /**
   * Get username of current logged in merchant.
   * @returns String username
   */
  getUsername() {
    return localStorage.getItem("username");
  }

  /**
   * Set current logged in Merchant's username.
   * @param {String} username current logged in merchant's username
   */
  setUsername(username) {
    localStorage.setItem("username", username);
  }

  /**
   * Removes username value.
   */
  deleteUsername() {
    localStorage.removeItem("username");
  }

  /**
   * Get access_token
   * @return {String} access_token after user authenticates
   */
  getAccessToken() {
    const auth = this.getAuth();
    if (auth != null) {
      return auth.access_token;
    }
  }

  /**
   * Get access_token
   * @return {String} refresh_token after user authenticates
   */
  getRefreshToken() {
    const auth = this.getAuth();
    if (auth != null) {
      return auth.refresh_token;
    }
  }

  /**
   * Get merchant id
   * @return {String} id of merchant
   */
  getMerchantId() {
    const auth = this.getAuth();
    if (auth != null) {
      return auth.merchantID;
    }
  }

  /**
   * Set merchantAuth in local storage as json string
   * @param {Object} merchchantAuth
   */
  setAuth(merchant) {
    localStorage.setItem("merchantAuth", JSON.stringify(merchant));
  }

  /**
   * Delete merchantAuth in local storage as json string
   */
  deleteAuth() {
    localStorage.removeItem("merchantAuth");
  }

  /**
   * Get merchant if exists in local storage
   * @return  {Object[]} merchchantAuth
   */
  getAuth() {
    return JSON.parse(localStorage.getItem("merchantAuth"));
  }

  /**
   * Get flag whether merchant Stripe is connected
   * @return  {Boolean} isStripeConnected
   */
  getIsStripeConnected() {
    return JSON.parse(localStorage.getItem("isStripeConnected"));
  }

  /**
   * Set flag whether merchant Stripe is connected
   * @param {Boolean} isStripeConnected
   */
  setIsStripeConnected(isStripeConnected) {
    localStorage.setItem("isStripeConnected", isStripeConnected);
  }

  /**
   * Removes isStripeConnected value.
   */
  deleteIsStripeConnected() {
    localStorage.removeItem("isStripeConnected");
  }

  // 3. Order History Filters

  /**
   * Get OrderHistory table's columns.
   * @returns {Object[]} Array of OrderHistory table's columns
   */
  getOrderHistoryColumns() {
    return JSON.parse(localStorage.getItem("orderHistoryColumns"));
  }

  /**
   * Set OrderHistory table's filtered columns value.
   * @param {Object[]} orderHistoryColumns
   */
  setOrderHistoryColumns(orderHistoryColumns) {
    localStorage.setItem("orderHistoryColumns", JSON.stringify(orderHistoryColumns));
  }

  /**
   * Removes OrderHistory table's column filter.
   */
  deleteOrderHistoryColumns() {
    localStorage.removeItem("orderHistoryColumns");
  }

  /**
   * Set flag whether merchant Ayrshare is connected
   * @param {Boolean} isSocialMediaAccountConnected
   */
  setIsSocialMediaAccountConnected(isSocialMediaAccountConnected) {
    localStorage.setItem("isSocialMediaAccountConnected", isSocialMediaAccountConnected);
  }


  /**
   * Set flag whether merchant Ayrshare is connected
   * @param {Boolean} isSocialMediaAccountConnected
   */
  getIsSocialMediaAccountConnected() {
    localStorage.getItem("isSocialMediaAccountConnected");
  }

}

const instance = new LocalStorageService();
export default instance;
