// Main page for the support.
// Author : Huen Oh (heons921@gmail.com)

<template>
  <b-card>
    <b-form>
      <b-row>
        <b-colxx xxs="12" md="6">
          <h4 class="mb-0 pb-4">{{$t('support.send-email.title')}}</h4>
          <!-- Choose store -->
          <b-form-group>
            <h4 class="pb-2">{{$t('support.send-email.choose-store')}}</h4>
            <SelectOneStore
              ref="select-one-store"
              :name="'support'"
              :colSize="12"
              :isAll="false"
              @click="onSelectStore"
            />
          </b-form-group>
          <!-- Issue title -->
          <b-form-group>
            <h4 class="mb-0 pb-2">{{$t('support.send-email.issue-title')}}</h4>
            <SelectOneIssue
              ref="select-one-issue"
              :name="'support'"
              :colSize="12"
              @click="onSelectIssue"
            />
          </b-form-group>
           <!-- Issue description -->
          <b-form-group>
            <h4 class="mb-0 pb-2">{{$t('support.send-email.issue-description')}}</h4>
            <b-textarea
              data-cy="support-issue-description"
              v-model="issue.inquiryDescription"
              :rows="5"
              :max-rows="5"
            ></b-textarea>
          </b-form-group>
          <!-- Next Button -->
          <div class="float-sm-right">
          <b-button
            data-cy="btn-send"
            variant="primary"
            size="sm"
            :disabled="isButtonDisabled"
            @click="next"
          >
            {{ $t('buttons.send') }}
          </b-button>
          </div>
        </b-colxx>
        <b-colxx xxs="12" md="6">
          <h4 class="mb-0 pb-2">{{$t('support.information.title')}}:</h4>
          <p class="text-muted text-medium">{{$t('support.information.description')}}</p>
          <p class="text-muted text-medium">{{$t('support.information.website')}}: <a href="https://timelyperks.com/contact/knowledge-base">{{$t('support.contact.website')}}</a></p>
          <p class="text-muted text-medium">{{$t('support.information.email')}}: <a href="mailto:support@timelyperks.com">{{$t('support.contact.email')}}</a></p>
        </b-colxx>
      </b-row>
    </b-form> 
  </b-card>
</template>



<script>
import SelectOneStore from '@/components/Forms/SelectOneStore'
import SelectOneIssue from '@/components/Forms/SelectOneIssue'

import { postMerchantInquiryApi } from '@/api/general'

// Notifications
import {
  addNotifySuccess,
} from "@/utils/notify.js"

export default {
  components:{
    SelectOneStore,
    SelectOneIssue,
  },

  data() {
    return {
      issue: { // {Object} Issue.
        storeId: null,          // {string} Store Id.
        inquiryTitle: "",       // {string} Title.
        inquiryDescription: "", // {string} Description.
      },
    }
  },
  computed: {
    isButtonDisabled() {
      return this.issue.inquiryTitle==="" || this.issue.inquiryDescription === "";
    }
  },
  methods: {
    // Notifications.
    addNotifySuccess,

    resetForm() {
      this.issue.inquiryDescription = "";

      // this.issue.storeId = null;
      this.$refs['select-one-store'].resetSelection();
      // this.issue.inquiryTitle = "";
      this.$refs['select-one-issue'].resetSelection();
    },

    onSelectStore(store) {
      // console.log('onSelectStore', store);
      this.issue.storeId = store ? store.value : null;
    },
    onSelectIssue(issueType) {
      this.issue.inquiryTitle = issueType ? issueType.label : "";
    },
    
    next() {
      // console.log('next');
      // console.log('this.issue', this.issue);
      // this.resetForm();

      // Do some backend.
      postMerchantInquiryApi(this.issue).then(response => {
        // console.log('response', response);
        this.addNotifySuccess(this.$t('support.send-email.message-success'));
        this.resetForm();
      }).catch(error => {
        console.log('error', error);
        this.resetForm();
      });
    }
  }
};
</script>