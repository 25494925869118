/**
 * It contains backend CRUD Apis for ProductAttributes.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// Modules in the application.
import axios                          from "@/api/axios/axios_core_deal.js";
import { serializeProductAttribute }  from "@/models/products/product_attribute.js";

// URLs for backend
const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;

function getUrl(id) {
  return `${HOST}/product-attributes/${id ? id : ""}`;
}

/**
 * Get ProductAttributes.
 * @return {Promise} Result of GET for ProductAttributes.
 */
export async function getProductAttributesApi() {
  return await axios.get(getUrl());
}

/**
 * Get a ProductAttribute by Id.
 * @param {string} id ID of a ProductAttribute object.
 * @return {Promise} Result of GET for a ProductAttribute by Id.
 */
export async function getOneProductAttributeApi(id) {
  return await axios.get(getUrl(id));
}

/**
 * Add a ProductAttribute.
 * @param {Object} data ProductAttribute object: Refer to PRODUCT_UNIT_MODEL.
 * @return {Promise} Result of POST for a ProductAttribute.
 */
export async function addProductAttributeApi(data) {
  return await axios.post(getUrl(), serializeProductAttribute(data));
}

/**
 * Update a ProductAttribute.
 * @param {Object} data ProductAttribute object: Refer to PRODUCT_UNIT_MODEL.
 * @return {Promise} Result of PUT for a ProductAttribute.
 */
export async function updateProductAttributeApi(data) {
  return await axios.put(getUrl(data.id), serializeProductAttribute(data));
}

/**
 * Delete a ProductAttribute by Id.
 * @param {string} id ID of a ProductAttribute object.
 * @return {Promise} Result of DELETE for deleting a ProductAttribute.
 */
export async function deleteProductAttributeApi(id) {
  return await axios.delete(getUrl(id));
}

