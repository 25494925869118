//Component for Recent post
//
// @param {Object} data Data of the post.


<template>
  <span class="position-relative" @click="onClick">
    <i :class="data.icon" class="d-none d-sm-inline-block" />
    <b-badge v-if="!isNullOrUndefined(data) && !isNullOrUndefined(data.badgeNumber) 
        && data.badgeNumber > 0" variant="primary"
        class="position-absolute badge-top-right circle-badge">{{data.badgeNumber}}
    </b-badge>
  </span>
</template>
<script>

import { isNullOrUndefined } from "@/utils/general";

export default {
  props: { 
    // {Object} Data of the post.
    data:{
      type: Object,
      default: () => ({
        icon: '',
        badgeNumber: 0
      })
    }
  },
  mounted(){
  },
  methods: {
    isNullOrUndefined(val){
      return isNullOrUndefined(val);
    },
    onClick() {
      this.$emit('click');
    }
  }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>
