var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        [
          _c(
            "b-row",
            { attrs: { "align-v": "center", "no-gutters": "" } },
            [
              _c(
                "b-colxx",
                { attrs: { sm: _vm.colSize } },
                [
                  !_vm.disabled
                    ? _c("v-select", {
                        attrs: {
                          "data-cy": `${_vm.name}-select-one-issue`,
                          options: _vm.currentSelOptions,
                        },
                        on: { input: _vm.onClick },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "search",
                              fn: function ({ attributes, events }) {
                                return [
                                  _c(
                                    "input",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "vs__search",
                                          attrs: {
                                            "data-cy": `${_vm.name}-select-one-issue-input`,
                                          },
                                        },
                                        "input",
                                        attributes,
                                        false
                                      ),
                                      events
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3933407646
                        ),
                        model: {
                          value: _vm.selectedIssue,
                          callback: function ($$v) {
                            _vm.selectedIssue = $$v
                          },
                          expression: "selectedIssue",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-invalid-feedback",
            {
              attrs: {
                state: _vm.selectedIssue || _vm.isFirstLoad ? true : false,
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("validations.no-selection-validation")) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }