var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 26 17" } },
    [
      _c("rect", { attrs: { x: "0.5", y: "0.5", width: "25", height: "1" } }),
      _vm._v(" "),
      _c("rect", { attrs: { x: "0.5", y: "7.5", width: "25", height: "1" } }),
      _vm._v(" "),
      _c("rect", { attrs: { x: "0.5", y: "15.5", width: "25", height: "1" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }