var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-button",
    {
      attrs: {
        "data-cy": "top-btn-add",
        variant: "outline-secondary",
        size: "xs",
      },
      on: { click: _vm.onClick },
    },
    [
      _c("b-img", {
        attrs: { src: "/assets/icons/plus.svg", widht: "16", height: "16" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }