// Button for Add Item.

<template>
  <b-button
    data-cy="top-btn-delete"
    variant="outline-secondary" 
    size="xs" 
    @click="onClick"
  >
    <b-img src="/assets/icons/trash.svg" widht="16" height="16" ></b-img>
  </b-button>
</template>


<script>

export default {
  methods:{
    /**
     * On click the button.
     */
    onClick() {
      this.$emit('click');
    }
  }
}
</script>
