var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-colxx",
        { attrs: { md: "12" } },
        [
          _c(
            "b-card",
            { attrs: { "body-class": "pt-0 px-0" } },
            [
              _c("b-table", {
                attrs: {
                  "data-cy": `${_vm.cyPrefix}-table-${_vm.cySuffix}`,
                  hover: "",
                  responsive: "",
                  "sticky-header": "",
                  "no-border-collapse": "",
                  items: _vm.items,
                  fields: _vm.fields,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                },
                on: {
                  "update:sortBy": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function ($event) {
                    _vm.sortDesc = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(status)",
                    fn: function (row) {
                      return [
                        _c("toggle-button-status", {
                          ref: "toggle-status-" + row.item.id,
                          attrs: {
                            name: `${_vm.cyPrefix}-table-${_vm.cySuffix}-${row.index}`,
                            status: row.item.status,
                            disabled: _vm.disabled,
                          },
                          on: {
                            "on-toggle-change": function ($event) {
                              return _vm.changeStatus(row.item)
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(groups)",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.getNamesByIdsFromList(
                                row.item.groups,
                                _vm.selectableGroups
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(actions)",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pl-1 align-self-center pr-4 action-block",
                          },
                          [
                            _vm.disabled == true
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      "data-cy": `${_vm.cyPrefix}-table-${_vm.cySuffix}-${row.index}-btn-show`,
                                      href: "#",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEdit(row.item)
                                      },
                                    },
                                  },
                                  [
                                    _c("b-img", {
                                      staticClass: "cls-btn-icon",
                                      attrs: {
                                        src: "/assets/img/view.svg",
                                        id: "btn_edit",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.disabled != true
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      "data-cy": `${_vm.cyPrefix}-table-${_vm.cySuffix}-${row.index}-btn-edit`,
                                      href: "#",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEdit(row.item)
                                      },
                                    },
                                  },
                                  [
                                    _c("b-img", {
                                      staticClass: "cls-btn-icon",
                                      attrs: {
                                        src: "/assets/icons/pencil.svg",
                                        id: "btn_edit",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.disabled != true
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      "data-cy": `${_vm.cyPrefix}-table-${_vm.cySuffix}-${row.index}-btn-delete`,
                                      href: "#",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDelete(row.item.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("b-img", {
                                      staticClass: "cls-btn-icon",
                                      attrs: {
                                        src: "/assets/icons/close.svg",
                                        id: "btn_delete",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }