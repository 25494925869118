/**
 * It contains model of Merchant Store object.
 */


import { unflatten, flatten } from "../general.js";


/**
 * Model for Delivery Rule.
 */
export const MODEL_DELIVERY_RULE = {
  id: "",             // {String} Id of the rule. UUID as String. Unique.
  name: "",           // {string} Name, required: minLength(1), maxLength(50).
  distance: 1,        // {number} Distance, required: km, Int, min(1).
  price: 0,           // {number} Price, required: min(0).
  minAmount: 0,       // {number} Minimum amount order: min(0).
  isTaxExempt: false, // {Boolean} No tax applied on delivery: default - false
};

/**
 * Model for TimeZone.
 */
export const MODEL_TIMEZONE = {
  abbreviation: "", // {string} Abbreviation of the timezone.
  gmtOffset: 0,     // {number} The time offset in seconds based on UTC time.
  dst: false,       // {bool}   Whether Daylight Saving Time (DST) is used. Either False (No) or True (Yes).
};


/**
 * Model for Merchant Store.
 */
export const MODEL_MERCHANT_STORE = {
  // id:"",           // {number}   Id.
  // merchantId:, ""  // {number}   Merchant Id.
  name: "",           // {string}   Name, required: minLength(1), maxLength(255).
  description: "",    // {string}   Description: minLength(0), maxLength(255).
  address: {},        // {Object}   Address, required: Refer to MODEL_ADDRESS.
  images: [],         // {string[]} Urls of images.
  phone: "",          // {string}   Phone number, required: ^\+(?:[0-9] ?){2,10}[0-9]$
  email: "",          // {string}   Email, required.
  operationTime: [],  // {Object[]} Opercation times: Refer to MODEL_DAY_TIME.
  timeZone: {},       // {Object}   TimeZone: Refer to MODEL_TIMEZONE.
  status: "",         // {string}   Status: ACTIVE, INACTIVE, DELETED. Refer to ENUM_STATUS_STRING.
};


/**
 * Parse Store objects.
 * It flattens 'address' field.
 * @param {Object} stores Store objects: Refer to MODEL_MERCHANT_STORE.
 * @return {Object} Prased Store objects.
 */
export function parseStores(stores) {
  return stores;
  // // TODO: make it more functional
  // let parsedStores = [];
  // for (let store of stores) {
  //   let addressObject = {};
  //   for (let [key, value] of Object.entries(flatten(store.address))) {
  //     addressObject[`address.${key}`] = value;
  //   }
  //   parsedStores.push({
  //     ...store,
  //     ...addressObject
  //   });
  // }
  // return parsedStores;
}

/**
 * Parse a Store object.
 * It flattens 'address' field.
 * @param {Object} store Store object: Refer to MODEL_MERCHANT_STORE.
 * @return {Object} Prased Store object.
 */
export function parseStore(store) {
  return store;
  // let addressObject = {};
  // for (let [key, value] of Object.entries(flatten(store.address))) {
  //   addressObject[`address.${key}`] = value;
  // }
  // return {
  //   ...store,
  //   ...addressObject
  // };
}

/**
 * Serialize a Store object.
 * It unflattens the object.
 * @param {Object} store Store object: Refer to MODEL_MERCHANT_STORE.
 * @return {Object} Serialized Store object.
 */
export function serializeStore(store) {
  return store;
  // console.log('unflatten-before', store)
  // console.log('unflatten', unflatten(store, ""))
  // return unflatten(store, "");
}


/**
 * Serialize a DeliveryRule object.
 * @param {Object} deliveryRule DeliveryRule object, Refer to : MODEL_DELIVERY_RULE.
 * @returns Serialized store object.
 */
export function serializeDeliveryRule(deliveryRule) {
  return deliveryRule;
}
