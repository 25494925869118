// Dialog componet of Product for Add/EditShow
// Author : Huen Oh (heons921@gmail.com)
// @param {bool}    dialog  Handler variable for the dialog(Close and Open).
// @param {Object}  data    Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT. It should be a copied object.
// @param {bool}    isOnPromotionPage since this model is displayed on other pages than the promotion. Use this value to display extra info, if necessary.


<template>
  <!-- Dialog for Product -->
  <b-modal id="modalProduct" ref="modalProduct" size="lg" @hide="onCencel()">
    <!-- Emulate built in modal header with custom title and buttons -->
    <template v-slot:modal-header>
      <div class="w-100">
        <!-- Header Component -->
        <HeaderDialogProduct
          :title="isRewardItem ? 'objects.reward-item' : 'objects.promotion'"
          name="dlg-promotion"
          :status="data.status"
          :disabled="true"
          actionType="Show"
          @clickSchedule="openDialogSchedule"
        />
        <div v-if="isOnPromotionPage === false">
          <p v-if="isPromotionDeleted" class="text-warning">
            <u>
              <strong>{{$t('general.note')}}: {{$t('promotions.promotion-deleted')}}</strong>
            </u>
          </p>

          <p v-else class="text-muted">
            <u>
              <strong>{{$t('general.note')}}: </strong>
              {{$t('promotions.current-information-note')}}
            </u>
          </p>
        </div>
        
      </div>
    </template>

    <b-row>

      <!-- Left column  -->

      <b-colxx sm="6">
        <!-- Name and description  -->
        <edit-name-description
          name="dlg-promotion"
          :label="isRewardItem ? 'reward-item' : 'promotions'"
          :disabled="true"
          :data="data"
        />

        <!-- Store information  -->
        <check-select-stores
          name="dlg-promotion"
          :disabled="true"
          :storeIds="data.stores"
        />

        <template v-if="isPromotionADeal">
          <!-- Hide Perk option  -->
          <b-row>
            <b-colxx sm="9">
              <h6>{{$t('promotions.hide-perk-btn')}}</h6>
            </b-colxx>
            <b-colxx sm="3">
              <toggle-button
                data-cy="dlg-promotion-toggle-btn-hide"
                color="#87bcbf"
                :value="!data.isPublicAvailable"
                :sync="true"
                :labels="{checked: 'ON', unchecked: 'OFF'}"
                :width="60"
              />
            </b-colxx>
          </b-row>

          <!-- Coupon Code -->
          <b-form-group class="mt-3" v-if="data.couponCode != null && data.couponCode.length > 0">
            <b-input-group>
              <span class="align-self-center mr-5">
                <h6 class="mr-5">{{`${$t('promotions.coupon-code')}:`}}</h6>
              </span>
              <b-form-input
                data-cy="dlg-promotion-coupon-code"
                :value="data.couponCode"
                disabled
              />
            </b-input-group>
          </b-form-group>

          <!-- Minimum Order -->
          <b-form-group >
            <b-input-group append="CAD" class="mt-3 mb-3" >
              <span class="align-self-center">
                <h6 class="mr-5">{{`${$t('promotions.minimum-order-title')}:`}}</h6>
              </span>
              <b-form-input
                data-cy="dlg-promotion-min-order-input-num"
                type="number"
                :value="formatPrice(data.requiredAmount)"
                disabled
              />
            </b-input-group>
            <p class="text-muted text-small">
              {{`${$t('promotions.minimum-order-description')}`}}
            </p>
          </b-form-group>

          <!-- Highlight Amount  -->
          <template v-if="isDealBuyOneGetOne === false && isDealMulti === false">
            <h6>{{`${$t('promotions.highlight-title')}`}}</h6>

            <b-form-group >
              <b-input-group append="CAD" class="mb-3" >
                <span class="align-self-center">
                  <h6 class="mr-4">{{`${$t('promotions.cart-value-exceeds')}:`}}</h6>
                </span>
                <b-form-input
                  data-cy="dlg-promotion-cart-value-input-num"
                  type="number"
                  :value="data.highlightAmount ? formatPrice(data.highlightAmount) : 0"
                  disabled
                />
              </b-input-group>
              <p class="text-muted text-small">
                {{`${$t('promotions.highlight-description')}`}}
              </p>
            </b-form-group>
          </template>

          <!-- Highlight product  -->
          <template v-else>
            <HighlightInProductList
              :highlightInProductList="data.highlightInProductList"
              :productList="data.requiredProductList"
              :perkType="perkType"
              :show="true"
            />
          </template>
          
        </template>

      </b-colxx>

      <!-- Right column  -->

      <b-colxx sm="6">
        <!-- Image selection  -->
        <b-form-group v-if="isImagesPresent">
          <images-selection
            name="dlg-promotion"
            :images="data.images"
            :single="false"
            :disabled="true"
          />
        </b-form-group>

        <!-- Eligible Items  -->
        <template v-if="isPromotionADeal === false || (isPromotionADeal && isDealMulti == false)">
          <b-form-group v-if="showEligibleItemsField">
            <h6>{{$t('promotions.eligible-items')}}:</h6>
            <treeselect-products
              name="dlg-promotion-products-required"
              :productIds="eligibleProductIds" 
              :labeledGroupsWithProducts="labeledGroupsWithProducts"
              :disabled="true"
            />
          </b-form-group>
        </template>

        <!-- Eligible Items for multi deals  -->
        <template v-else>
          <b-form-group v-if="showEligibleItemsField">
            <h6>{{$t('promotions.eligible-items-with-group')}}:</h6>
            <div 
              class="mb-1"
              v-for="(groupIndex, index) in groupIndexList"
              :key="index"
            >
              <treeselect-products
                :name="`dlg-promotion-products-required-${index}`"
                :productIds="getProductIdsListByGroupIndex(groupIndex)" 
                :labeledGroupsWithProducts="labeledGroupsWithProducts"
                :disabled="true"
              />
            </div>
          </b-form-group>
        </template>

        <!-- Reward Items  -->
        <b-form-group v-if="showRewardItemsField">
          <h6>{{$t('promotions.reward-items')}}:</h6>
          <treeselect-products
            name="dlg-promotion-products-required"
            :productIds="rewardProductIds" 
            :labeledGroupsWithProducts="labeledGroupsWithProducts"
            :disabled="true"
          />
        </b-form-group>

        <!-- Discount options for Deal  -->
        <b-form-group class="mb-5" v-if="isPromotionADeal">

          <!-- Percent discount deals -->
          <template v-if="isDealPercentDiscount">
            <h6>{{$t('promotions.benefit-discounts')}}:</h6>
            <b-input-group append="%" class="mb-3" >
              <b-form-input
                data-cy="dlg-promotion-discount-percent-input-num"
                type="number"
                :value="data.discountPercent"
                :disabled="true"
              />
            </b-input-group>
          </template>

          <!-- Fixed amount discount deals -->
          <template v-if="isDealFixedAmountDiscount">
            <h6>{{$t('promotions.benefit-discounts')}}:</h6>
            <b-input-group append="CAD" class="mb-3" >
              <b-form-input
                data-cy="dlg-promotion-discount-amount-input-num"
                type="number"
                :value="data.discountAmount"
                :disabled="true"
              />
            </b-input-group>
          </template>

          <!-- Flat price discount deals (only Bundle deal yet) -->
          <template v-if="isDealFlatPriceDiscount">
            <h6>{{$t('promotions.flat-price')}}:</h6>
            <b-input-group append="CAD" class="mb-3" >
              <b-form-input
                data-cy="dlg-promotion-fixed-amount-input-num"
                type="number"
                :value="data.fixedAmount"
                :disabled="true"
              />
            </b-input-group>
          </template>

        </b-form-group>

        <!-- Points for Point Perk -->
        <b-form-group v-if="isPromotionPointPerk" class="my-4">
          <b-input-group>
            <span class="align-self-center">
              <h6 class="mr-3">
              {{`${$t('promotions.points')}`}}
              </h6>
            </span>
            <b-form-input
              data-cy="dlg-promotion-points-input-num"
              type="number"
              :value="data.rewardPoints"
              disabled
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- Points for Reward Item -->
        <b-form-group v-if="isRewardItem" class="my-4">
          <b-input-group>
            <span class="align-self-center">
              <h6 class="mr-3">
              {{`${$t('promotions.points')}`}}
              </h6>
            </span>
            <b-form-input
              data-cy="dlg-promotion-points-input-num"
              type="number"
              :value="data.requiredPoints"
              disabled
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- Number of Punches -->
        <b-form-group v-if="isPromotionPunchCard" class="my-4">
          <b-input-group>
            <span class="align-self-center col-10 pl-0">
              <h6 class="mr-3">{{`${$t('promotions.choose-num-punches')}`}}</h6>
            </span>
            <b-form-input
              data-cy="dlg-promotion-num-punches-input-num"
              type="number"
              class="col-2"
              :value="data.requiredPunches"
              disabled
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- Number of Rewards -->
        <b-form-group v-if="isPromotionPunchCard" class="my-4">
          <b-input-group>
            <span class="align-self-center col-10 pl-0">
              <h6 class="mr-3">{{`${$t('promotions.choose-num-rewards')}`}}</h6>
            </span>
            <b-form-input
              data-cy="dlg-promotion-num-rewards-input-num"
              type="number"
              class="col-2"
              :value="data.rewardItems"
              disabled
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <!-- Time limit -->
          <select-time-limit
            :data="data"
            :disabled="true"
          />
        </b-form-group>

        <!-- Points giveaway with PUNCH CARD promotion -->
        <b-row class="mb-4" align-v="center" v-if="isPromotionPunchCard">
          <!-- Toggle button for the check -->
          <b-colxx xxs="2">
            <toggle-button-check
              name="dlg-promotion-points"
              :value="isRewardPoints" 
              :disabled="true"
            />
          </b-colxx>
          <!-- Title -->
          <b-colxx xxs="10">
            <h6>{{$t('promotions.choose-points-giveaway')}}</h6>
          </b-colxx>
          <b-colxx xxs="2">
            <p class="text-medium" v-if="isRewardPoints">
              {{`${$t('promotions.points')}`}}
            </p>
          </b-colxx>
          <b-colxx>
            <b-form-group>
              <b-form-input
                data-cy="dlg-promotion-points-input-num"
                v-if="isRewardPoints"
                type="number"
                :value="data.rewardPoints"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-colxx>
        </b-row>

        <!-- The following limit options are not applicable for punch card promotion  -->
        <template v-if="isPromotionPunchCard === false && isRewardItem === false">
          <!-- Time limit -->
          <!-- Not applicable for Point perk  -->
          <b-form-group v-if="isPromotionPointPerk === false">
            <select-day-time-limit
              :data="data"
              :disabled="true"
            />
          </b-form-group>

          <b-form-group>
            <!-- Once per client limit -->
            <b-row align-v="center" class="mb-2">
              <b-colxx xxs="2">
                <toggle-button-check
                  name="dlg-promotion-client-limit" 
                  :value="data.maxRedeem && data.maxRedeem === 1"
                  :disabled="true"
                />
              </b-colxx>
              <b-colxx>
                <h6>{{`${$t('promotions.limits-once-title')}`}}</h6>
              </b-colxx>
            </b-row>
          </b-form-group>

          <b-form-group>
            <!-- Stock limit -->
            <select-stock-limit
              v-model="isLimitedStock"
              :data="data.limitedStock"
              :disabled="true"
            />
          </b-form-group>
        </template>

        <b-form-group>
          <h6>{{$t('promotions.other-options')}}:</h6>
          <!-- Display list of badges  -->
          <b-badge 
            variant="primary" 
            class="mr-1 mb-1" 
            sz="lg" 
            v-for="(option, index) in otherOptions" :key="index"
            :data-cy="`dlg-promotion-other-options-${index}`"
          >
            <h6 class="m-0 p-0">{{option}}</h6>
          </b-badge>
        </b-form-group>
      </b-colxx>
    </b-row>

    

    <!-- Footer -->
    <template slot="modal-footer">
      <FooterDialogProduct
        name="dlg-promotion"
        actionType="Show"
        :disabled="isPromotionDeleted"
        @click-cancel="onCencel"
        @click-submit="onSubmit"
      />
    </template>
  </b-modal>
</template>

<script>

import { ToggleButton }   from 'vue-js-toggle-button';

import TreeselectProducts     from '@/components/Forms/TreeselectProducts'
import HeaderDialogProduct    from '@/components/Common/HeaderDialogProduct'
import FooterDialogProduct    from '@/components/Common/FooterDialogProduct'
import EditNameDescription    from '@/components/Forms/Editbox/EditNameDescription.vue'
import CheckSelectStores      from '@/components/Forms/CheckSelectStores'
import ImagesSelection        from '@/components/Common/ImagesSelection.vue'
import SelectTimeLimit        from '@/components/Forms/SelectTimeLimit'
import SelectDayTimeLimit     from '@/components/Forms/SelectDayTimeLimit'
import ToggleButtonCheck      from '@/components/Forms/ToggleButtonCheck'
import SelectStockLimit       from '@/components/Forms/SelectStockLimit'
import HighlightInProductList from '@/components/Forms/HighlightInProductList'

import { formatNumber }  from "@/utils/general";

import { mapActions } from 'vuex'

import _ from 'lodash';

import { ENUM_STATUS_STRING } from "@/constants/common";

import { getDealType, TYPE_PROMOTION, TYPE_PROMOTION_LOWERCASE, TYPE_DEAL } from '@/models/promotion'

export default {
  components:{
    HeaderDialogProduct,
    FooterDialogProduct,
    EditNameDescription,
    CheckSelectStores,
    ImagesSelection,
    ToggleButton,
    TreeselectProducts,
    SelectTimeLimit,
    SelectDayTimeLimit,
    ToggleButtonCheck,
    SelectStockLimit,
    HighlightInProductList,
  },
  props: {
    // {bool} Handler variable for the dialog(Close and Open).
    dialog:{
      type:Boolean,
      default:false,
    },
    // {Object[]} Promotion object
    data:{
      type:Object,
      default:()=> {},
    },
    //{Bool} to determine if this model is shown at default promotion page or at some other page.
    isOnPromotionPage: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      labeledGroupsWithProducts: [],

      isLimitedStock: true, // default value for SelectStockLimit v-model.

      dialogs: {        // Handler object for dialogs(Close and Open).
        schedule:false,
        alert:false,
      },
      
    }
  },

  computed: {

    // return lowercase name of the promotion type (deal type if promotion is deal)
    perkType() {
      if (this.data.type === TYPE_PROMOTION.DEAL) {

        return TYPE_DEAL[this.data.dealType];
      } else {
        return TYPE_PROMOTION_LOWERCASE[this.data.type];
      }
    },

    eligibleProductIds() {

      // Check if the promotion is from following, then use reward product list for eligible items.
      //    Discount on selected item
      //    Get a free item

      if (this.data.type === TYPE_PROMOTION.DEAL) {
        const dealType = getDealType(this.data);

        if (dealType === TYPE_DEAL.DEAL_DISCOUNT_ITEMS_P || dealType === TYPE_DEAL.DEAL_FREE_ITEM) {

          // Use reward product list instead of required productlist.
          if (this.data.rewardProductList && this.data.rewardProductList.length > 0)
            return this.data.rewardProductList.map(product => product.id);
        }

      } 


      // Use required product list for rest of the promotion types including some deal types.
      if (this.data.requiredProductList && this.data.requiredProductList.length > 0)
        return this.data.requiredProductList.map(product => product.id);

    },

    rewardProductIds() {
      if (this.data.rewardProductList && this.data.rewardProductList.length > 0)
        return this.data.rewardProductList.map(product => product.id);
    },

    showEligibleItemsField() {
      // if required product list is not empty
      // and it deal type is the following
      //    Discoutn on selected item
      //    Get a free item

      if (this.data.requiredProductList && this.data.requiredProductList.length > 0) {
        return true;
      } else {
        // Check the specific deals
        if (this.data.type === TYPE_PROMOTION.DEAL) {
          const dealType = getDealType(this.data);

          if (dealType === TYPE_DEAL.DEAL_DISCOUNT_ITEMS_P || dealType === TYPE_DEAL.DEAL_FREE_ITEM) {
            if (this.data.rewardProductList && this.data.rewardProductList.length > 0)
              return true;
          } else {}
        } else {}

        return false;
      }
    },

    showRewardItemsField() {
      // First check if the promtion is from following types
      //    Discount on selected item.
      //    Get a Free item.

      if (this.data.type === TYPE_PROMOTION.DEAL) {
        const dealType = getDealType(this.data);

        if (dealType === TYPE_DEAL.DEAL_DISCOUNT_ITEMS_P || dealType === TYPE_DEAL.DEAL_FREE_ITEM) {
          return false;
        }
      } 

      // If the promotion does not belong to the above listed promotion, then it should be shown
      if (this.data.rewardProductList && this.data.rewardProductList.length > 0) 
        return true;
    },

    /**
     * List of options: isAddonCharged, perk exclusive or not, target clients
     */
    otherOptions() {
      const options = [];
      const dealType = this.data.type === TYPE_PROMOTION.DEAL ? getDealType(this.data) : null;
      // TODO : Need to update these conditions with the conditions in PromotionDetails.js.
      // Addons extra charge
      // This option is NOT applicable for point perk
      if (this.data.type !== TYPE_PROMOTION.POINT_PERK) {
        if (this.data.isAddonCharged) {
          options.push(this.$t('promotions.conditions.isAddonCharged-true'))
        } else {
          options.push(this.$t('promotions.conditions.isAddonCharged-false'))
        }
      }

      // Client type allowed
      // This option is NOT applicable for punch card
      if (this.data.type !== TYPE_PROMOTION.PUNCH_CARD &&
          this.data.type !== TYPE_PROMOTION.REWARD_ITEM) {
        if (this.data.isNewClient == null) {
          options.push(this.$t('promotions.conditions.isNewClient-null'))
        } else if (this.data.isNewClient === true) {
          options.push(this.$t('promotions.conditions.isNewClient-true'))
        } else {
          options.push(this.$t('promotions.conditions.isNewClient-false'))
        }
      }

      // Perk exclusive or not
      // This option is not applicable for PUNCH_CARD and DEAL_FREE_ITEM deal.
      if (this.data.type !== TYPE_PROMOTION.PUNCH_CARD && 
          this.data.type !== TYPE_PROMOTION.REWARD_ITEM &&
          dealType !== TYPE_DEAL.DEAL_FREE_ITEM) {
        if (this.data.isMaster == null) {
          options.push(this.$t('promotions.conditions.isMaster-null'))
        } else if (this.data.isMaster === true) {
          options.push(this.$t('promotions.conditions.isMaster-true'))
        } else {
          options.push(this.$t('promotions.conditions.isMaster-false'))
        }
      }

      // Coupon code
      if (this.data.couponCode != null && this.data.couponCode.length > 0) {
        options.push(this.$t('promotions.conditions.couponCode', [this.data.couponCode]))
      }

      return options;
    },

    isPromotionADeal() {
      return this.data.type === TYPE_PROMOTION.DEAL;
    },
    isPromotionPointPerk() {
      return this.data.type === TYPE_PROMOTION.POINT_PERK;
    },
    isPromotionPunchCard() {
      return this.data.type === TYPE_PROMOTION.PUNCH_CARD;
    },

    /**
     * Includes deals: BOGOF, Percent discount on cart/selected items
     */
    isDealPercentDiscount() {
      const dealType = getDealType(this.data);

      return dealType === TYPE_DEAL.DEAL_BUY_ONE_GET_ONE ||
              dealType === TYPE_DEAL.DEAL_DISCOUNT_ITEMS_P ||
              dealType === TYPE_DEAL.DEAL_DISCOUNT_CART_P;
    },
    /**
     * Includes deals: Fixed discount amount on cart, combo deal
     */
    isDealFixedAmountDiscount() {
      const dealType = getDealType(this.data);

      return dealType === TYPE_DEAL.DEAL_DISCOUNT_CART_F ||
              dealType === TYPE_DEAL.DEAL_MULTI_COMBO;
    },

    /**
     * Includes deals: Bundle deal
     */
    isDealFlatPriceDiscount() {
      const dealType = getDealType(this.data);

      return dealType === TYPE_DEAL.DEAL_MULTI_BUNDLE;
    },

    /**
     * Assumes that the promotion is a deal.
     */
    isDealBuyOneGetOne() {
      const dealType = getDealType(this.data);

      return dealType === TYPE_DEAL.DEAL_BUY_ONE_GET_ONE;
    },
    /**
     * Assumes that the promotion is a deal.
     */
    isDealMulti() {
      const dealType = getDealType(this.data);

      return dealType === TYPE_DEAL.DEAL_MULTI_COMBO ||
        dealType === TYPE_DEAL.DEAL_MULTI_BUNDLE;
    },

    /**
     * To check whether points giveaway with punch card promo applicable or not.
     */
    isRewardPoints() {
      return this.data.rewardPoints && this.data.rewardPoints > 0;
    },

    /**
     * Check if the promotion has highlightProductInList for BOFO or Multi deals.
     */
    hasHighlight() {
      if (this.data.highlightInProductList && 
          this.data.highlightInProductList != null &&
          Array.isArray(this.data.highlightInProductList)) {
        return true;
      } else {
        return false;
      }
    },

    /**
     * Checks if the promotion has whether required or reward product in its highlight perk option.
     * value in highlightProductInList[0] = 0: Required, 1: Reward
     */
    isRequiredHighlightItem() {
      return this.data.highlightInProductList &&
        Array.isArray(this.data.highlightInProductList) &&
        this.data.highlightInProductList[0] === 0;
    },

    /**
     * List of groupIndexes from requiredItems
     * Assumes that the promotion is a multi deal.
     */
    groupIndexList() {
      if (this.data.requiredProductList) {
        
        let maxGroupNum = _.max(
          _.compact(this.data.requiredProductList.map(product => product.groupIndex))
        );
        maxGroupNum = maxGroupNum ? maxGroupNum : 0;
  
        return [...Array(maxGroupNum+1).keys()];
      } else {
        return [];
      }
    },

    /**
     * Checks if there are any images in current promotion.
     */
    isImagesPresent() {
      if (Array.isArray(this.data.images) && this.data.images.length > 0) {
            return true;
      } else {
        return false;
      }
    },
    
    /** 
     * Checks if the current promotion is a REWARD_ITEM.
     */
    isRewardItem() {
      if (this.data.type === TYPE_PROMOTION.REWARD_ITEM) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Check whether the promotion is deleted 
     */
    isPromotionDeleted(){
      return this.data.status === ENUM_STATUS_STRING.DELETED;
    }
  },

  methods: {
    ...mapActions("product_group",  ["getLabeledGroupsWithProducts"]),

    formatPrice(num) {
      const isToFixed = true;
      return formatNumber(num, isToFixed);
    },

    // TODO : double call with @hide 
    /**
     * On Cencel button : Close the dialog.
     */
    onCencel() {
      console.log("onCencel");
      this.$emit('dialog-close');
    },

  /**
   * On Edit promotion
   */
    onSubmit() {
      if (this.data.type === TYPE_PROMOTION.REWARD_ITEM) {
        this.$emit('edit-item', this.data);
      } else {
        this.$emit('edit-item');
      }
    },
    
    // Dialog operations - Schedule
    /**
     * Close Schedule dialog.
     */
    closeDialogSchedule() {
      this.dialogs.schedule = false;
    },
    /**
     * Open Schedule dialog.
     */
    openDialogSchedule() {
      this.dialogs.schedule = true;
    },

    // Dialog operations - Unchanged submit Alert
    /**
     * Close Alert dialog - Unchanged submit.
     */
    closeDialogAlert() {
      this.dialogs.alert = false;
    },
    /**
     * Open Alert dialog - Unchanged submit.
     */
    openDialogAlert() {
      this.dialogs.alert = true;
    },
    /**
     * Get all products from requiredProductList matching groupIndex.
     */
    getProductIdsListByGroupIndex(groupIndex) {
      if (this.data.requiredProductList)
        return this.data.requiredProductList
          .filter(productObj => productObj.groupIndex === groupIndex)
          .map(productObj => productObj.id);
      else 
        return [];
    } 

  },
  
  watch: {
    /**
     * Watch to control the dialog: Show, Hide.
     * @param {bool} newVal New value.
     * @param {bool} oldVal Old value.
     */
    dialog: function(newVal, oldVal){
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if(newVal == true) {
        this.$refs["modalProduct"].show();
      } else {
        this.$refs["modalProduct"].hide();
      }
    },
   

  },

  async mounted() {
    this.labeledGroupsWithProducts = await this.getLabeledGroupsWithProducts();
  }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>

