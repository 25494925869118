
import {
    getMerchantGlobalPointApi,
    redeemMerchantGlobalPointApi,
} from "../../api/global_point.js";

import { isResponseOK } from "@/utils/general.js"

export default {
    namespaced: true,
    state: {
        globalPoints: {}
    },
    mutations: {
        resetAll(state) {
            state.globalPoints = {}
        },
        /**
         * Reset all the state.
         * @param {Object}  state Local State object.
         */
        setGlobalPoints(state, data) {
            state.globalPoints = data;
        }
    },
    getters: {
        // Let's return one object
        // with all props: merchantId, redeemableGlobalPoint, redeemedGlobalPoint
        globalPoints: (state) => {
            return state.globalPoints;
        }
    },
    actions: {
        async getMerchantGlobalPoints({ commit }) {
            const response = await getMerchantGlobalPointApi();
            if (isResponseOK(response)) {
                commit("setGlobalPoints", response.data);
            }
            return response.data;
        },
        async redeemMerchantGlobalPoints({ commit }, payload) {
            const { data, onSuccess, onFail } = payload;
            redeemMerchantGlobalPointApi(data)
                .then(response => {
                    if (isResponseOK(response)) {
                        commit("setGlobalPoints", response.data);
                        onSuccess();
                    }
                }).catch(err => {
                    // console.log('err', err.response);
                    if (onFail) {
                        onFail(err.response);
                    } // else
                });
        }
    }
};
