/**
 * It contains store module for Websocket.
 */

/**
 * When websocket connection is established, client subscribes for websocket channels
 * Therefore, when connection is closed and after reconnection, all channels need to be subscribed again.
 * It store all of the websocket subscription.
*/
export default {
  namespaced: true,
  state: {
    // If checksum changed, it means the connection is new, then the client need to re-subscribe the necessary channels
    socketChecksum: 0 // {Integer} Checksum represent the version of the websocket connection
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.socketChecksum = 0;
    },
    /**
     * Update websocket checksum when new connection is established
     * @param {Object} state   Local State object.
     * @param {Integer} checksum The checksum of the new websocket connection
     */
    setSocketChecksum(state, checksum) {
      state.socketChecksum = checksum;
    }
  },
  getters: {
    /**
     * Get websocket connection checksum
     * @return {Integer} True if merchant businessLines is set, False otherwise.
     */
    socketChecksum: (state) => {
      return state.socketChecksum;
    }
  },
  actions: {
    /**
     * Update websocket connection checksum.
     * @param {Integer} checksum The new connection checksum
     */
    updateSocketChecksum({ commit }, checksum) {
      commit("setSocketChecksum", checksum);
    }
  }
};
