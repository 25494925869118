// Dropdown for Promotion Type Filter selection.
// Author : Huen Oh (heons921@gmail.com)
// @param {string} label    Label text for the dropdown. (in)
// @param {array}  options  Options for the orderby dropdown. (in)

<template>
  <b-dropdown 
    data-cy="top-dropdown-promotion-list"
    id="ddown1" 
    :text="`${$t('objects.type')}: ${label}`" 
    variant="outline-dark" 
    class="mr-1 btn-group " 
    size="xs" 
    no-flip
  >                
    <b-dropdown-item 
      :data-cy="`top-dropdown-promotion-list-item-${index}`" 
      v-for="(type, index) in options" 
      :key="index"
      @click="onClick(type)">
    {{ type.label }}
    </b-dropdown-item>
  </b-dropdown>
</template>     


<script>

export default {
  props:{
    // {string} Label text for the dropdown. (in)
    label:{
      type: String,
      default: "",
    },
    //{array} Options for the order by dropdown
    options:{
      type:Array,
      default:() => [],
    }
  },  
  methods:{
    /**
     * On click the button.
     */
    onClick(filter) {
      this.$emit('click', filter);
    }
  }
}
</script>