var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "dropdown-filter-store" },
    [
      _vm.selectableStores.length > 2
        ? _c(
            "b-dropdown",
            {
              staticClass: "mr-1 mb-1 btn-group align-top",
              attrs: {
                "data-cy": "top-dropdown-store",
                text: `${_vm.$t("product.store")}: ${_vm.label}`,
                variant: "outline-dark",
                size: "xs",
                "no-flip": "",
              },
            },
            _vm._l(_vm.currentSelOptions, function (store, index) {
              return _c(
                "b-dropdown-item",
                {
                  key: index,
                  attrs: { "data-cy": `top-dropdown-store-item-${index}` },
                  on: {
                    click: function ($event) {
                      return _vm.onClick(store)
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(store.label) + "\n    ")]
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }