var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.getMenuType, attrs: { id: "app-container" } },
    [
      _c("top-nav"),
      _vm._v(" "),
      _c("sidebar"),
      _vm._v(" "),
      _c("main", { class: { "is-global-warning": !_vm.isStripeConnected } }, [
        _c("div", { staticClass: "container-fluid" }, [_c("router-view")], 1),
      ]),
      _vm._v(" "),
      _c("footer-component"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }