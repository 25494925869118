var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalDateTime",
      staticClass: "modal-right",
      attrs: {
        id: "modalDateTime",
        title: _vm.$t("product.select-schedule"),
        size: "lg",
      },
      on: {
        hide: function ($event) {
          return _vm.onCencel()
        },
      },
    },
    [
      _c(
        "b-form",
        [
          !_vm.disableHideUntil
            ? _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-5" },
                    [
                      _c(
                        "b-colxx",
                        { attrs: { xxs: "3" } },
                        [
                          _vm.data.type == "group_product"
                            ? _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "mb-0",
                                  attrs: {
                                    "data-cy":
                                      "dlg-schedule-hide-until-checkbox-group",
                                    disabled: _vm.disabled,
                                  },
                                  model: {
                                    value: _vm.isSyncedInGroup.hideUntil,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.isSyncedInGroup,
                                        "hideUntil",
                                        $$v
                                      )
                                    },
                                    expression: "isSyncedInGroup.hideUntil",
                                  },
                                },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(_vm.$t("product.hide-until"))
                                    ),
                                  ]),
                                ]
                              )
                            : _c("h3", [
                                _vm._v(_vm._s(_vm.$t("product.hide-until"))),
                              ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-colxx",
                        [
                          _c("toggle-button", {
                            attrs: {
                              "data-cy": "dlg-schedule-hide-until-toggle-btn",
                              disabled: _vm.disabled,
                              value: _vm.switches.hideUntil,
                              sync: true,
                              color: "#f18024",
                              width: 100,
                              labels: {
                                checked: "HIDE",
                                unchecked: "NOT HIDE",
                              },
                            },
                            on: {
                              change: function ($event) {
                                return _vm.markChanged()
                              },
                            },
                            model: {
                              value: _vm.switches.hideUntil,
                              callback: function ($$v) {
                                _vm.$set(_vm.switches, "hideUntil", $$v)
                              },
                              expression: "switches.hideUntil",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-colxx",
                        { attrs: { xxs: "12" } },
                        [
                          _vm.switches.hideUntil
                            ? _c("v-date-picker", {
                                attrs: {
                                  "data-cy":
                                    "dlg-schedule-hide-until-date-picker",
                                  mode: "single",
                                  "disabled-dates": _vm.disabled
                                    ? { weekdays: [1, 2, 3, 4, 5, 6, 7] }
                                    : { weekdays: [] },
                                  "input-props": {
                                    class: "form-control",
                                    placeholder: _vm.$t("product.hide-until"),
                                    disabled: _vm.disabled,
                                  },
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.markChanged()
                                  },
                                },
                                model: {
                                  value: _vm.hideUntil,
                                  callback: function ($$v) {
                                    _vm.hideUntil = $$v
                                  },
                                  expression: "hideUntil",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-card",
            [
              _c("DayDateTime", {
                ref: "availableTime",
                attrs: {
                  disabled: _vm.disabled,
                  days: _vm.availableTime,
                  typeGroup: _vm.data.type == "group_product",
                  isGroupSynced: _vm.isSyncedInGroup.availableTime,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              attrs: {
                "data-cy": "dlg-schedule-btn-cancel",
                variant: "outline-secondary",
              },
              on: {
                click: function ($event) {
                  return _vm.onCencel()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mr-1",
              attrs: {
                "data-cy": "dlg-schedule-btn-submit",
                variant: "primary",
                disabled: _vm.disabled,
              },
              on: {
                click: function ($event) {
                  return _vm.onSubmit()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.submit")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }