// Dialog componet of Product for Add/EditShow
// Author : Huen Oh (heons921@gmail.com)
// @param {bool}    dialog  Handler variable for the dialog(Close and Open).
// @param {Object}  data    Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT. It should be a copied object.
// @param {bool}    isOnProductPage since this model is displayed on other pages than the product. Use this value to display extra info, if necessary.


<template>
  <!-- Dialog for Product -->
  <b-modal id="modalProduct" ref="modalProduct" size="lg" @hide="onCencel()">
    <!-- Emulate built in modal header with custom title and buttons -->
    <template v-slot:modal-header>
      <div class="w-100">
        <!-- Header Component -->
        <HeaderDialogProduct
          title="objects.product"
          name="dlg-product"
          :status="data.status"
          :disabled="data.showOnly"
          :actionType="actionType"
          @clickSchedule="openDialogSchedule"
          @changeStatus="updateStatus"
        />

        <div v-if="isOnProductPage === false">
          <p v-if="isProductDeleted" class="text-warning">
            <u>
              <strong>{{$t('general.note')}}: {{$t('product.product-deleted')}}</strong>
            </u>
          </p>

          <p v-else class="text-muted">
            <u>
              <strong>{{$t('general.note')}}: </strong>
              {{$t('product.current-information-note')}}
            </u>
          </p>
        </div>
      </div>
    </template>

    <b-form>
      <!-- Dialog for Alert -->
      <dialog-alert
        :dialog="dialogs.alert"
        :description="$t('alert.no-change')"
        :actionType="actionType"
        :buttonSubmitText="$t('buttons.ok')"
        @dialog-close="closeDialogAlert"
        @dialog-submit="onSubmitAlertDialog"
      />
      <!-- Dialog for Date/Time -->
      <dialog-date-time
        :disabled="data.showOnly"
        :data="data"
        :dialog="dialogs.schedule"
        @dialog-close="closeDialogSchedule"
        @dialog-submit="onSubmitDialogSchedule"
      />
      <b-row>
        <b-colxx sm="6">
          <!-- Name and Description -->
          <EditNameDescription
            name="dlg-product"
            :data="data"
            label="product"
            :isFirstLoad="isFirstLoad"
            :disabled="data.showOnly"
          />
          <!-- Product Price -->
          <EditPrice
            name="dlg-product"
            :colSize="sizeColMain"
            :data="data"
            :disabled="data.showOnly"
            :isFirstLoad="isFirstLoad"
          />
          <!-- ProductGroups -->
          <select-product-groups
            name="dlg-product"
            ref="selProductGroups"
            v-model="isChanged"
            :disabled="data.showOnly"
            :groups="data.groups"
            :isRequired="false"
            :isFirstLoad="isFirstLoad"
            @isValid="(ele) => {isValidProductGroups = ele;}"
          />
          <!-- AddonGroups -->
          <select-addon-groups
            name="dlg-product"
            ref="selAddonGroups"
            v-model="isChanged"
            :disabled="data.showOnly"
            :groups="data.addonGroups"
            :isRequired="false"
            :isFirstLoad="isFirstLoad"
            @isValid="(ele) => {isValidAddonGroups = ele;}"
          />
          <!-- Product Unit -->
          <b-form-group>
            <h6 v-if="!data.showOnly">{{$t('product.choose-unit')}}</h6>
            <h6 v-else>{{$t('product.unit')}}</h6>
            <!-- Product Unit : Selection -->
            <b-row align-v="center" no-gutters v-if="!(data.showOnly)">
              <b-colxx :sm="sizeColMain">
                <v-select
                  data-cy="dlg-product-select-unit"
                  :options="currentSelUnitOptions"
                  v-model="newSelItem.unit"
                  @input="updateUnit()"
                >
                  <template #search="{ attributes, events }">
                    <input
                      :data-cy="`dlg-product-select-unit-input`"
                      class="vs__search"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
              </b-colxx>
            </b-row>
            <!-- Product Unit : Edit -->
            <b-row align-v="center" no-gutters>
              <b-colxx :sm="sizeColMain">
                <b-form-input
                  data-cy="dlg-product-input-unit"
                  v-model="data.unit"
                  :placeholder="$t('forms.unit')"
                  :state="!$v.data.unit.$invalid || isFirstLoad"
                  :disabled="(data.showOnly)"
                />
                <b-form-invalid-feedback>
                  {{ $t('validations.no-value')}}
                </b-form-invalid-feedback>
              </b-colxx>
            </b-row>
          </b-form-group>
        </b-colxx>

        <b-colxx sm="6">
          <!-- Image Selection --> 
          <b-form-group>
            <h6 v-if="!data.showOnly">{{$t('product.choose-image')}}</h6>
            <h6 v-else>{{$t('product.images')}}</h6>
            <b-row align-v="center" no-gutters>
              <b-colxx :sm="sizeColMain">
                <ImagesSelection
                  name="dlg-product"
                  v-model="isChanged"
                  :images="data.images"
                  :single="false"
                  :disabled="data.showOnly"
                  key="selImage"
                  ref="selImage"
                />
              </b-colxx>
            </b-row>
          </b-form-group>
          <!-- Product Category -->
          <select-categories
            name="dlg-product"
            ref="selCategory"
            v-model="isChanged"
            :disabled="data.showOnly"
            :categories="data.categories"
          /> 
          
          <!-- Product Attribute Selection -->
          <select-attributes
            name="dlg-product"
            ref="selAttrubute"
            v-model="isChanged"
            :disabled="data.showOnly"
            :attributes="data.attributes"
          />      
        </b-colxx>
      </b-row>
      <b-row>
        <b-colxx>
          <!-- Selection :  Stores -->
        <CheckSelectStores
          name="dlg-product"
          :storeIds="data.stores"
          :productTaxConfigs="data.productTaxConfigs"
          :disabled="data.showOnly"
          :isFirstLoad="isFirstLoad"
          :allowTaxConfigDialog="true"
          ref="selStores"
        />
        </b-colxx>
      </b-row>
    </b-form>

    <!-- Footer -->
    <template slot="modal-footer">
      <FooterDialogProduct
        name="dlg-product"
        :disabled="($v.$invalid && !isFirstLoad) || isProductDeleted"
        :actionType="actionType"
        @click-cancel="onCencel"
        @click-submit="onSubmit"
      />
    </template>
  </b-modal>
</template>

<script>
import vSelect    from 'vue-select'
import 'vue-select/dist/vue-select.css'

import _ from 'lodash';

import DialogNameDescription  from '@/components/Common/DialogNameDescription'
import DialogDateTime         from '@/components/Common/DialogDateTime'
import DialogAlert            from '@/components/Common/DialogAlert'
import ImagesSelection        from '@/components/Common/ImagesSelection'
import HeaderDialogProduct    from '@/components/Common/HeaderDialogProduct'
import FooterDialogProduct    from '@/components/Common/FooterDialogProduct'
import ToggleButtonStatus     from '@/components/Forms/ToggleButtonStatus'
import EditPrice              from '@/components/Forms/Editbox/EditPrice'
import CheckSelectStores      from '@/components/Forms/CheckSelectStores'
import SelectCategories       from '@/components/Forms/SelectCategories'
import SelectAttributes       from '@/components/Forms/SelectAttributes'
import SelectAddonGroups      from '@/components/Forms/SelectAddonGroups'
import SelectProductGroups    from '@/components/Forms/SelectProductGroups'
import EditNameDescription    from '@/components/Forms/Editbox/EditNameDescription'


import { getNameByIdFromList, addItemValueToList, isNullOrUndefined } from "@/utils/general"

import { ENUM_STATUS_STRING } from "@/constants/common";


// Notifications
import {
  addNotifySuccess,
  addNotifyWarning,
} from "@/utils/notify.js"

import { mapGetters, mapActions } from 'vuex'

// For validation
import { validationMixin } from "vuelidate";
const { required, minLength, maxLength, decimal, sameAs } = require("vuelidate/lib/validators");

// Empty selected item
const EMPTY_SEL_ITEM = {
  unit: {value:"", label:""},
  store:"",
};

export default {
  components:{
    'v-select': vSelect,
    DialogAlert,
    DialogNameDescription,
    DialogDateTime,
    ImagesSelection,
    ToggleButtonStatus,
    CheckSelectStores,
    SelectCategories,
    SelectAttributes,
    SelectAddonGroups,
    SelectProductGroups,
    EditNameDescription,
    EditPrice,
    HeaderDialogProduct,
    FooterDialogProduct,
  },
  props: {
    // {bool} Handler variable for the dialog(Close and Open).
    dialog:{
      type:Boolean,
      default:false,
    },
    // {Object[]} Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
    data:{
      type:Object,
      default:()=> {},
    },
    //{Bool} to determine if this model is shown at default product page or at some other page.
    isOnProductPage: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      sizeColMain:10,   // {number} Size of the column for forms.
      actionType: "",   // {string} Type of action : "Add", "Edit" or "Show"
      VAL_TRUE: true,   // {bool}  True value for the validation - const.

      isChanged: false, // {bool} Flag if anything has changed in the dialog.
      isfirstWatch: {   // {bool} Flag if it the first watch to detemine isChanged flag.
        data: true,
        productImages: true,
      },
      isValidProductGroups: false,  // {bool} Flag if ProductGroups are valid.
      isValidAddonGroups: false,    // {bool} Flag if AddonGroups are valid.

      dialogs: {        // Handler object for dialogs(Close and Open).
        schedule:false,
        alert:false,
      },
      
      newSelItem: EMPTY_SEL_ITEM, // Selected items object: Refer to EMPTY_SEL_ITEM.

      // is First Load, in order to not display validation message at first
      isFirstLoad: true

    }
  },
  // Validations
  mixins: [validationMixin],
  validations: {
    data: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(35)
      },
      description: {
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      price: {
        required,
        decimal
      },
      unit: {
        required
      },
      stores:{
        required,
        minLength: minLength(1),
      }
    },
    isValidProductGroups: {
      required,
      isTrue: sameAs('VAL_TRUE'),
    }
  },

  methods: {
    // Get name of an item from a list.
    getNameByIdFromList,

    // Add a value of an item to the item list if it doesn't exist in the list.
    addItemValueToList,

    // Notifications.
    addNotifySuccess,
    addNotifyWarning,

    // Map actions.
    ...mapActions("product", [
      "updateOneProductWithImages",
      "addOneProductWithImages",
      "applyProductFilters",
    ]),
    ...mapActions("product_unit", ["addOneProductUnit"]),

    /**
     * Reset all variables.
     */
    reset() {
      this.newSelItem = EMPTY_SEL_ITEM;
      this.isChanged = false;
      this.isfirstWatch.data = true;
      this.isfirstWatch.productImages = true;
    },
    /**
     * Initialize the dialog with input data.
     */
    initDialog() {
      this.reset();
      // console.log('initDialog', this.data);

      // Set title
      if (this.data.showOnly) {
        this.actionType = this.$t('actions.show');
      } else if (this.data.id) {
        this.actionType = this.$t('actions.edit');
      } else {
        this.actionType = this.$t('actions.add');
      }

      // console.log('selectableProductUnits', this.selectableProductUnits);
    },

    // TODO : double call with @hide 
    /**
     * On Cencel button : Close the dialog.
     */
    onCencel() {
      this.isFirstLoad = true;

      console.log("onCencel");
      this.$emit('dialog-close');
    },
    /**
     * On Submit button : Save the data and Close the dialog.
     */
    onSubmit() {
      this.isFirstLoad = false;

      // Not submit the form if fail validation
      if(this.$v.$invalid){
        return;
      }

      // console.log('onSubmit', this.data);
      // console.log('onSubmit', this.isChanged);

      if(this.data.showOnly === true) {
        // Show -> Edit
        this.data.showOnly = false;
        this.actionType = this.$t('actions.edit');
      } else {
        if(this.data.id) { //Edit
          this.submitChanges();
        } else {
          // Add
          if(this.isChanged) {
            this.submitChanges();
          } else {
            // There is no change, are you sure?
            this.openDialogAlert();
          }
        }
      }
    },
    /**
     * Submit changes of the dialog.
     */
    async submitChanges() {
      // console.log(this.data);
      // Add unit if not exists.
      const newUnit = this.data.unit;
      const isFound = this.selectableProductUnits.find(element => element.value == newUnit);
      // console.log(isFound);
      if(isFound == undefined) {
        // console.log("add unit here");
        this.addOneProductUnit({name:newUnit});
      }

      // Set Categories.
      this.data.categories = this.$refs.selCategory.getCategories();

      // Set Attributes.
      this.data.attributes = this.$refs.selAttrubute.getAttrubutesObject();

      // Set ProductGroups.
      const updatedGroups = this.$refs.selProductGroups.getGroups();
      let deletedGroups = [];
      this.data.groups.forEach(group => {
        if(!updatedGroups.includes(group)) {
          deletedGroups.push(group);
        } else {}
      });
      this.data.deletedGroups = deletedGroups;
      this.data.groups = updatedGroups;

      this.data.productTaxConfigs = this.$refs.selStores.getTaxConfigIdsObject();

      // Set AddonGroups.
      this.data.addonGroups = this.$refs.selAddonGroups.getGroups();

      // Set image objects for the change.
      const imageObjects = this.$refs.selImage.getImageObjects(); 
      // console.log('submitChanges', imageObject);

      // Select what to do: Update or Add by id
      if(this.data.id != undefined) {
        // Update - PUT
        await this.updateProduct(this.data, imageObjects);
      } else {
        // Add - POST
        this.addProduct(this.data, imageObjects);
      }

      this.onCencel();
    },

    /**
     * Add a Product to the server.
     * @param {Object} product            Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
     * @param {Object[]} [imageObjects[]] Image objects: [{files, preview}]
     */
    addProduct(product, imageObjects) {
      // If there is no images, the product will be added without images
      this.addOneProductWithImages({product, imageObjects})
        .then((response)=>{
          // this.products.push(response.data);
          this.applyProductFilters();
          this.addNotifySuccess(this.$t('notify.product-add'));
        });;
    },
    /**
     * Update a Product to the server.
     * @param {Object} product            Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
     * @param {Object[]} [imageObjects[]] Image objects: [{files, preview}]
     */
    async updateProduct(product, imageObjects){
      await this.updateOneProductWithImages({product, imageObjects})
        .then((response)=>{
          if(response.data) {
            this.applyProductFilters();
            this.addNotifySuccess(this.$t('notify.product-update'));
          }
      });
    },

    
    /**
     * Update unit value by selection.
     */
    updateUnit() {
      // console.log("updateUnit", this.newSelItem);
      if(this.newSelItem.unit) {
        if(this.newSelItem.unit.value != "") {
          this.data.unit = this.newSelItem.unit.value;
        } else {}
      } else {}
    },
    /**
     * Update status of the item
     * @param {string} status Status: "ACTIVE" or "INACTIVE"
     */
    updateStatus(status) {
      this.data.status = status;
    },


    // Dialog operations - Schedule
    /**
     * Close Schedule dialog.
     */
    closeDialogSchedule() {
      this.dialogs.schedule = false;
    },
    /**
     * Open Schedule dialog.
     */
    openDialogSchedule() {
      this.dialogs.schedule = true;
    },
    /**
     * On Submit Schedule dialog.
     * Update Schedule - hideUntil and availableTime.
     * @param {Object} data  Schedule object: Refer to EMPTY_SCHEDULE.
     */
    onSubmitDialogSchedule(data) {
        // console.log(data);
      this.data.hideUntil = data.hideUntil;
      this.data.availableTime = data.availableTime;
      // console.log(this.data);
    },

    // Dialog operations - Unchanged submit Alert
    /**
     * Close Alert dialog - Unchanged submit.
     */
    closeDialogAlert() {
      this.dialogs.alert = false;
    },
    /**
     * Open Alert dialog - Unchanged submit.
     */
    openDialogAlert() {
      this.dialogs.alert = true;
    },
    /**
     * On Submit Alert dialog - Unchanged submit.
     * Submit the changes.
     */
    onSubmitAlertDialog() {
      this.submitChanges();
    },
  },
  computed:{
    // Map gatters
    ...mapGetters("product_unit",       ["selectableProductUnits"]),
    ...mapGetters("merchant_store",     ["selectableStores", "getDefaultTaxConfigsOfStore"]),

    // Computed value to exclude selected items from the selection option.
    currentSelUnitOptions: function() {
      return this.selectableProductUnits.filter((item) => {
        return (item.value !== "");
      });
    },
    /**
     * Check whether the product is deleted 
     */
    isProductDeleted(){
      return this.data.status === ENUM_STATUS_STRING.DELETED;
    }
  },
  
  watch: {
    /**
     * Watch to control the dialog: Show, Hide.
     * @param {bool} newVal New value.
     * @param {bool} oldVal Old value.
     */
    dialog: function(newVal, oldVal){
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if(newVal == true) {
        this.initDialog();
        this.$refs["modalProduct"].show();
      } else {
        this.$refs["modalProduct"].hide();
      }
    },

    /**
     * Watch to check changes in data.
     */
    data: {
      deep: true,
      // immediate: true,
      handler() {
        if(this.isfirstWatch.data == false) {
          if(this.isChanged == false) {
            this.isChanged = true;
            // console.log("watch data changed");
          }
        } else {
          // console.log("watch data changed", this.data);
          this.isfirstWatch.data = false;
        }
      },
    },

  },

  mounted(){ 
  }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>

