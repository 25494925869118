// Dropdown for Product Category Filter selection.
// Author : Huen Oh (heons921@gmail.com)
// @param {string} label Label text for the dropdown. (in)

<template>
  <b-dropdown 
    data-cy="top-dropdown-category"
    :text="`${$t('product.category')}: ${label}`"  
    variant="outline-dark" 
    class="mr-1 btn-group align-top"  
    size="xs" 
    no-flip
  >                
    <b-dropdown-item 
      v-for="(category,index) in selectableProductCategories"
      :data-cy="`top-dropdown-category-item-${index}`"
      :key="index" 
      @click="onClick(category)"
    >
    {{ category.label }}
    </b-dropdown-item>
  </b-dropdown>
</template>     


<script>
import { mapGetters } from 'vuex'

export default {
  props:{
    // {string} Label text for the dropdown. (in)
    label:{
      type: String,
      default: "",
    }
  },
  computed:{
    // Map getters
    ...mapGetters("product_category", ["selectableProductCategories"]),
  },
  methods:{
    /**
     * On click the button.
     */
    onClick(filter) {
      this.$emit('click', filter);
    }
  }
}
</script>