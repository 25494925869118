// @param {string} type Type of the TopNav: "" or "app-orders".

<template>
  <nav class="navbar fixed-top">
    <!-- Dialog: Auth -->
    <DialogAuthCurrentUser
      :title="$t('order.change-store.title')"
      :description="$t('order.change-store.description')"
      :dialog="dialogs.auth"
      :showStoreFilter="showStoreFilter"
      @dialog-close="closeDialogAuthCurrentUser"
      @dialog-submit="onSubmitDialogAuthCurrentUser"
    />
    <a href="#" class="menu-button d-none d-md-block ml-4" @click.prevent.stop="changeSideMenuStatus({step :menuClickCount+1,classNames:menuType,selectedMenuHasSubItems})">
      <!-- Menu Icon -->
      <menu-icon v-if="type !== 'app-orders'"/>
      <div v-else class="d-inline-flex">
        <b-button
          variant="primary"
          size="sm"
          type="button"
          data-cy="top-nav-order-back-button"
          @click="goToPreviousPage"
        >
        {{$t('buttons.back')}}
        </b-button>
        <!-- Store Selection - Only for the order pages -->
        <b-button
          v-if="showStoreFilter"
          variant="primary"
          size="sm"
          type="button"
          class="change-store ml-2"
          data-cy="top-nav-order-change-store-button"
          @click="openDialogAuthCurrentUser"
        >
          {{$t('buttons.select-store')}}: {{ currentStore.label }}
        </b-button>
      </div>
    </a>
    <a href="#" class="menu-button-mobile d-xs-block d-sm-block d-md-none" @click.prevent.stop="changeSideMenuForMobile(menuType)">
      <!-- Menu Icon -->
      <mobile-menu-icon v-if="type !== 'app-orders'"/>
      <!-- Store Selection - Only for the order pages -->
      <div v-else class="d-inline-flex flex-column">
        <b-button
          variant="primary"
          size="xs"
          type="button"
          class="back-button"
          data-cy="top-nav-order-back-button"
          @click="goToPreviousPage"
        >
          Back
        </b-button>
        <!-- Sotre Selection - Only for the order pages -->
        <b-button
          v-if="showStoreFilter"
          variant="primary"
          size="xs"
          type="button"
          class="change-store mt-1"
          data-cy="top-nav-order-change-store-button"
          @click="openDialogAuthCurrentUser"
        >
          {{$t('buttons.select-store')}}: {{ currentStore.label }}
        </b-button>
      </div>
    </a>
    <router-link class="navbar-logo" tag="a" to="/app">
      <span><img v-bind:src="require('../assets/img/dealigenceLogoFillOrange.svg')" style="max-height: 100%"/></span>

      <span class="logo-mobile d-xs-block d-sm-block d-md-none"></span>
    </router-link>
      <div>
      <div class="header-icons d-inline-block align-middle">

        <IconWithBadge
          :data="orderMenuWithBadge"
          :class="orderIconStyle"
          data-cy="top-nav-order-link-icon"
          @click="onOrderPageIconClick"
        />

        <div class="position-relative d-none d-sm-inline-block">
          <b-dropdown variant="empty" size="sm" right toggle-class="header-icon" menu-class="position-absolute mt-3 iconMenuDropdown" no-caret>
            <template slot="button-content">
              <i class="simple-icon-grid" />
            </template>
            <div>
              <router-link tag="a" :to="{ name:'app-dashboards' }"  class="icon-menu-item">
                <i  class="iconsminds-shop-4 d-block" />{{$t('menu.dashboards')}}
              </router-link>
              <router-link tag="a" :to="{ name:'app-setup' }" class="icon-menu-item">
                <i  class="iconsminds-digital-drawing d-block" /> {{$t('menu.setup')}}
              </router-link>
              <router-link tag="a" :to="{ name:'app-products' }" class="icon-menu-item">
                <i  class="iconsminds-mine d-block" />{{$t('menu.products')}}
              </router-link>
              <router-link tag="a" :to="{ name:'app-promotions' }" class="icon-menu-item">
                <i  class="iconsminds-gift-box d-block" />{{$t('menu.promotions')}}
              </router-link>
              <router-link tag="a" :to="{ name:'order-history' }" class="icon-menu-item">
                <i  class="simple-icon-notebook d-block" />{{$t('menu.order-history')}}
              </router-link>
              <router-link tag="a" :to="{ name:'support-main' }" class="icon-menu-item">
                <i  class="simple-icon-support d-block" />{{$t('menu.support')}}
              </router-link>
            </div>
          </b-dropdown>
        </div>
        <b-button variant="empty" class="header-icon d-none d-sm-inline-block" @click="toggleFullScreen">
          <i :class="{'d-block':true,'simple-icon-size-actual':fullScreen,'simple-icon-size-fullscreen':!fullScreen }" />
        </b-button>
      </div>
      <div class="user d-inline-block">
        <b-dropdown class="dropdown-menu-right"  right variant="empty" toggle-class="p-0" menu-class="mt-3" no-caret>
          <template slot="button-content">
            <span  class="name mr-1 username">{{profileMain && profileMain.name || $t('general.loading')}}</span>
            <span>
              <img :src="avatarURL || noAvatar" alt="Avatar"/>
            </span>
          </template>
          <b-dropdown-item @click="onClickUserProfile">
            {{$t('user-profile.profile')}}
          </b-dropdown-item>
          <b-dropdown-item @click="signOut">
            {{$t('user-profile.sign-out')}}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div class="global-notification-message vertical-center" v-if="!isStripeConnected">
      <b-colxx md="9" class="float-left">
        <b-row>
          <span class="text-one">{{$t('payment.status.payment-method-not-connected')}}</span>
        </b-row>

        <b-row>
          <span>{{$t("payment.stripe-not-setup-warning")}}</span>
        </b-row>
      </b-colxx>

      <b-colxx md="3">
        <div class="btn background-theme-color float-right">
          <router-link style="color:white; font-weight:bold;" tag="a" :to="{ name:'payment' }">
            {{$t('payment.connect.let-connect')}}
          </router-link>
        </div>
      </b-colxx>

    </div>

  </nav>
</template>

<script>

import { mapGetters, mapMutations, mapActions } from "vuex";
import { MenuIcon, MobileMenuIcon } from '@/components/Svg'
import DialogAuthCurrentUser        from '@/components/Common/DialogAuthCurrentUser'
import IconWithBadge                from '@/components/Common/IconWithBadge';

import { searchPath, menuHiddenBreakpoint, localeOptions } from '@/constants/config'
import { NO_AVATAR } from '@/constants/common';

import { ENUM_ORDER_STATUS_STRING, ENUM_ORDER_SERVING_STATUS_STRING} from "@/models/order";


// utils
import LocalStorageService from "@/utils/local_store.js";
import { isNullOrUndefined, isEmptyString } from "@/utils/general";
import { addNotifyWarning } from "@/utils/notify";
// JSON bigInt parser
const JSONbig = require("json-bigint")({ "storeAsString": true });

// Websocket
import { connectWebsocket, disconnectWebsocket, subscribeWebsocket } from "@/utils/websocket";

// Mp3 Sound
import newOrderSound from "../assets/sounds/new_order.mp3";

export default {
  props: {
    type: {
      type: String,
      default: ""
    }
  },
  components: {
    MenuIcon,
    MobileMenuIcon,
    DialogAuthCurrentUser,
    IconWithBadge
  },
  data() {
    return {
      selectedParentMenu: "",
      searchKeyword:"",
      isMobileSearch:false,
      isSearchOver:false,
      fullScreen:false,
      menuHiddenBreakpoint,
      searchPath,
      localeOptions,
      noAvatar: "",
      dialogs: {   // {Object} Dialog controls.
        auth:false,
      },
      orderIconStyle: 'header-icon',
      filters: {  // {Object} Filer format.
        store: {  // {Object} Store filter/selection.
          isAscending: true,
          label:"",
        }
      },
      pollingCheckSocketConnection: null,      // Polling to check websocket connection,
      websocketConnectionInterval: 1000*10,    // Interval check websocket connection
      playSoundInterval: 5000,         // Interval to play sound to notify new orders
    };
  },
  mounted() {
    console.log('mounted-app-top-nav');

    this.noAvatar = NO_AVATAR;
    this.$nextTick(() => {
      // actual data will
      // be taken by a getter
      // console.log('profileMain', this.profileMain);

      this.initializeMerchant();

      // Get number of new orders of all stores belong to this merchant
      this.getNumberOfNewOrdersByMerchant();

      // Update store selection label.
      if (this.type === 'app-orders') {
        this.initOrdersPage();
      }

      // Load Profile for the profile image.
      this.loadProfile();
      this.loadProfileServer();

      // Interval to reconnect websocket if disconnected
      const auth = LocalStorageService.getAuth();
      if (!isNullOrUndefined(auth) && !isNullOrUndefined(LocalStorageService.getAccessToken())){
        this.connectWebsocket(this.onSubscribeBaseChannels);

        this.pollingCheckSocketConnection = setInterval(() => {
            this.connectWebsocket(this.onSubscribeBaseChannels);
          }
        , this.websocketConnectionInterval);
      }

      // Set interval to notify order every 5 seconds
      this.setUpOrderNotificationInterval();

      // Check the scripe connection after 2 sec.
      setTimeout(()=> {
          this.checkStripeConnected( { merchantId: this.merchantId, onSuccess: () => {} });
      }, 1000)

    });

    // Update the fullscreen state value if fullscreen exit using Esc key.
    document.addEventListener("fullscreenchange", event => {
      this.fullScreen = this.isInFullScreen();
    })

  },
  methods: {
    ...mapMutations(["changeSideMenuStatus", "changeSideMenuForMobile"]),
    ...mapActions(["setLang"]),
    ...mapActions("auth", ["logout", "initializeMerchant"]),
    ...mapActions(["resetAll"]),
    ...mapActions("order", [
      "loadOrdersByStoreId",
      "setCurrentOrder",
      "setCurrentStore",
      "updateNumNewOrders",
      "getNumberOfNewOrdersByMerchant",
      "loadOneOrder",
      "updateOrderList",
      "updateOrderNotificationInterval"
    ]),

    ...mapActions("merchant_store",   ["loadStores"]),
    ...mapActions("merchant_profile", ["loadProfileServer"]),
    ...mapActions("user_profile",     ["loadProfile"]),
    ...mapActions("payments",         ["checkStripeConnected"]),

    ...mapActions("websocket",        ["updateSocketChecksum"]),

    // Websocket
    connectWebsocket,
    disconnectWebsocket,
    subscribeWebsocket,

    addNotifyWarning,

    /**
     * Initialize merchant store and orders for that store when merchant navigates to orders page.
     * Checks invalid conditions for currentStore before loading orders.
     */
    async initOrdersPage() {

      // Guard for currentOrder.
      // No need to show the previously completed order.
      if (!_.isEmpty(this.currentOrder) &&
            (this.currentOrder.status === ENUM_ORDER_STATUS_STRING.COMPLETED) ||
            (this.currentOrder.status === ENUM_ORDER_STATUS_STRING.REFUNDED)) {
        this.setCurrentOrder({});
      }

      // Guard for currentStore.
      // If no store is present, reload and don't process further if still no store is present.
      // Case: if Order page is accessed directly via entering the url. Need to load the stores first.
      if (this.numOfStores === 0) {
        await this.loadStores();
        if (this.numOfStores === 0) {
          this.addNotifyWarning(this.$t('notify.no-store-available'));
          return;
        } // else continue
      }

      // If currentStore is selected, check if it is deleted.
      // If deleted, continue with next if block to set valid store based on num of store available.
      // Else currentStore is valid and load orders by that store.
      if (!isEmptyString(this.currentStore.value)) {
        // Check if the selected store is not removed.
        const validStore = this.selectableStores.find(store => store.value === this.currentStore.value);

        if (!isNullOrUndefined(validStore)) {
          this.loadOrdersByStoreId(this.currentStore.value);
          return;
        } else {
          this.setCurrentStore({});
          // continue.
        }
      }

      // If currentStore is not selected
      // Case1: Only one store available, set it as default.
      if (this.numOfStores === 1) {
        // Select the default store.
        if (Array.isArray(this.selectableStores) &&
          (this.selectableStores.length == 2) &&
          (!isEmptyString(this.selectableStores[1].value))) {
          this.setCurrentStore(this.selectableStores[1]);
          this.loadOrdersByStoreId(this.currentStore.value);
          return;
        }
      }
      // Case 2: Multiple stores available. let user select the store.
      else {
        // select store and load orders is then handled by dialog box
        this.openDialogAuthCurrentUser();
      }

    },

    search(){
      this.$router.push(`${this.searchPath}?search=${this.searchKeyword}`)
      this.searchKeyword="";
    },
    searchClick(){
      if (window.innerWidth < this.menuHiddenBreakpoint) {
        if(!this.isMobileSearch){
          this.isMobileSearch=true;
        }else{
          this.search()
          this.isMobileSearch=false;
        }
      }else{
        this.search()
      }
    },
    handleDocumentforMobileSearch(){
      if(!this.isSearchOver){
        this.isMobileSearch=false;
        this.searchKeyword="";
      }
    },
    changeLocale(locale){
      this.setLang(locale)
    },
    signOut() {
      this.logout().then(() => {
        // Clear order notification sound interval
        if(!isNullOrUndefined(this.orderNotificationInterval)){
          clearInterval(this.orderNotificationInterval);
        }

        // Reset all persisted data.
        window.sessionStorage.clear();
        this.resetAll();

        // Disconnect websocket
        this.disconnectWebsocket();

        // Route to login.
        this.$router.push('/user/login')
      })
    },
    onClickUserProfile() {
      this.$router.push({ name: "user-profile" });
    },
    toggleFullScreen(){
      const isInFullScreen = this.isInFullScreen();

      var docElm = document.documentElement;
      if (!isInFullScreen) {
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
        } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
      this.fullScreen=!isInFullScreen;
    },
    isInFullScreen(){
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      )
    },

    /**
     * Authorize the merchant store first by displaying the dialog box.
     * Then route to OrdersMain page if authorized successfully.
     */
    onOrderPageIconClick() {
      if (this.$router.currentRoute.name != 'orders-main') {
        if (this.numOfStores > 1) {
          this.openDialogAuthCurrentUser();
        } else {
          this.$router.push("/app/orders");
        }
      }
    },

    // Dialog operations - Auth for current user
     /**
     * Close Auth dialog
     */
    closeDialogAuthCurrentUser() {
      this.dialogs.auth = false;

      // check if at the OrdersMain page and no current store selected, then navigate to home.
      if (this.$router.currentRoute.name === 'orders-main' && isEmptyString(this.currentStore.value) ) {
        this.$router.push('/');
      }
    },
    /**
     * Open Auth dialog
     */
    openDialogAuthCurrentUser() {
      this.dialogs.auth = true;
    },
    /**
     * On Submit Auth dialog
     */
    onSubmitDialogAuthCurrentUser(selectedStore) {

      if (isEmptyString(selectedStore.value)) {
        this.closeDialogAuthCurrentUser();
        return;
      }

      this.setCurrentStore(selectedStore);
      this.loadOrdersByStoreId(this.currentStore.value);
      this.setCurrentOrder({});

      // check if not at the OrdersMain page, then navigate to it.
      if (this.$router.currentRoute.name !== 'orders-main') {
        this.$router.push('/app/orders');
      }
    },
    onSubscribeBaseChannels() {
      // Subscribe errors channel
      this.subscribeWebsocket("/topic/errors", (message) => {
        console.log("An error happened with websocket: " + message.body);
      });

      // Subscribe orders channel
      this.subscribeWebsocket("/user/queue/notify-order", (message) => {
        const updatedOrder = message.body
        const parsedOrder = JSONbig.parse(updatedOrder);

        this.notifyOrder(parsedOrder);

        this.updateBadge(parsedOrder);

        this.updateOrder(parsedOrder);
      });

      // Update socket connection checksum so that other components can re-subscribe the channels
      const socketChecksum = this.socketChecksum + 1;
      this.updateSocketChecksum(socketChecksum);
    },
    // Push notification that there is new change of order comes from server
    notifyOrder(order) {
      // TODO: Provide store name in the notification
      // Get store name

      // Push notification
      let message = "";
      switch(order.status){
        case ENUM_ORDER_STATUS_STRING.REFUNDED:
          message = this.$t('order.notification.rejected');
          break;
        case ENUM_ORDER_STATUS_STRING.COMPLETED:
          message = this.$t('order.notification.completed');
          break;
        case ENUM_ORDER_STATUS_STRING.PAID:
          if(order.serveStatus == null){
            message = this.$t('order.notification.new');
          }else{
            message = this.$t('order.notification.updated');
          }
          break;
      }
      this.$notify("primary", 'Success', message, { duration: 3000, permanent: false });

      // Play a sound
      let audio = new Audio(newOrderSound);
      audio.play();
    },
    // Update the badge number on Order Menu icon
    updateBadge(order){


      // There is a new order comes, then increase the badge number
      if(order.status == ENUM_ORDER_STATUS_STRING.PAID && order.serveStatus == null){
        // Refresh number of new orders of all stores belong to this merchant
        this.getNumberOfNewOrdersByMerchant();
      } else if(order.status == ENUM_ORDER_STATUS_STRING.REFUNDED ||
                (order.status == ENUM_ORDER_STATUS_STRING.PAID
                    && order.serveStatus == ENUM_ORDER_SERVING_STATUS_STRING.ACCEPTED)){
      // The order is ACCEPTED/REJECTED, then decrease the number of new orders
        // Refresh number of new orders of all stores belong to this merchant
        this.getNumberOfNewOrdersByMerchant();
        }
    },
    // Update the order list and the order detail of the selected order
    updateOrder(order){
      // Access VueX store directly
      const currentStore = this.$store.state.order.currentStore;
      const currentOrder = this.$store.state.order.currentOrder;

      if(!isNullOrUndefined(currentStore.value) &&
          order.storeId.toString() === currentStore.value.toString()){
        this.updateOrderList(order);

        if(!isNullOrUndefined(currentOrder.id) && currentOrder.id === order.id){
          this.loadOneOrder(order.id);
        }
      }
    },

    /**
     * Navigate to previous page.
     */
    goToPreviousPage() {
      this.$router.back();
    },
    /**
     * Set up order notification interval
     */
    setUpOrderNotificationInterval(){
      if(!isNullOrUndefined(this.orderNotificationInterval)){
        clearInterval(this.orderNotificationInterval);

        // Clear interval in Vue state
        this.updateOrderNotificationInterval(null);
      }

      if(this.numNewOrders > 0){
        // If there is no interval so far, create interval to play sound
        const interval = setInterval(() => {
          // Play a sound
          let audio = new Audio(newOrderSound);
          audio.play();
        }, this.playSoundInterval);

        // Store the interval to vue State
        this.updateOrderNotificationInterval(interval);
      }
    }
  },

  computed: {
    ...mapGetters({
      menuType: "getMenuType",
      menuClickCount: "getMenuClickCount",
      selectedMenuHasSubItems: 'getSelectedMenuHasSubItems'
    }),
    ...mapGetters("auth",             ["merchantId"]),
    ...mapGetters("order",            ["currentStore", "orders", "numNewOrders", "currentOrder", "orderNotificationInterval"]),
    ...mapGetters("user_profile",     ["avatarURL"]),
    ...mapGetters("merchant_profile", ["profileMain"]),
    ...mapGetters("merchant_store",   ["findStoreById", "selectableStores", "numOfStores"]),
    ...mapGetters("payments",         ["isStripeConnected"]),
    ...mapGetters("websocket",        ["socketChecksum"]),

    orderMenuWithBadge(){
      return {
              to: 'app-orders',
              icon: 'iconsminds-tablet-3',
              badgeNumber: this.numNewOrders
          };
    },

    // Whether to display Store dropdown in auth dialog box.
    showStoreFilter() {

      if (this.numOfStores >= 2) {
        return true;
      } else {
        return false;
      }

    }
  },

  beforeDestroy(){
    document.removeEventListener("click", this.handleDocumentforMobileSearch);

    clearInterval(this.pollingCheckSocketConnection);
    this.pollingCheckSocketConnection = null;
  },

  watch: {
    '$i18n.locale'(to,from){
      if(from!=to){
        this.$router.go(this.$route.path)
      }
    },
    isMobileSearch(val){
      if(val) {
        document.addEventListener("click", this.handleDocumentforMobileSearch);
      } else{
        document.removeEventListener("click", this.handleDocumentforMobileSearch);
      }
    },
    selectableStores(updatedStoreList) {
      // Check if the selected store is not removed.
      const validStore = updatedStoreList.some(store => store.value === this.currentStore.value);
      if (validStore === false) {
        this.setCurrentStore({});
      }
    },
    numNewOrders(){
      this.setUpOrderNotificationInterval();
    }
  }
};
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>
