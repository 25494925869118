/**
 * @fileoverview It create aixos for the INTRA_SERVICE_CORE_DEAL service in the application.
 * @author Huen Oh (heons921@gmail.com)
 */

// Constant.
import { INTRA_SERVICE_CORE_DEAL } from "@/constants/config.js";

// Modules in the application.
import { axios_base, interceptResponseSuccess, interceptResponseFail } from "./axios_base";
import LocalStorageService from "@/utils/local_store.js";

const axios_core_deal = axios_base.create();

/**
 * Intercept the request to set additional headers.
 */
axios_core_deal.interceptors.request.use(
  config => {
    const token = LocalStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers[`intra-service-name`] = INTRA_SERVICE_CORE_DEAL;
    } // else
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

/**
 * Intercept function for the response of axios when it's failed.
 * @param {Object} error Error object from axios.
 * @return {Promise} Promise object.
 */
const customInterceptResponseFail = function (error) {
  return interceptResponseFail(error, axios_core_deal);
}

/**
 * Intercept the response.
 */
axios_core_deal.interceptors.response.use(
  interceptResponseSuccess,
  customInterceptResponseFail
);


export default axios_core_deal;
