// TODO : add in-code documentation DONE

import { parse, serialize } from "./general.js";

/**
 * Model for POPULAR TIME CHART.
 */
const POPULAR_TIME_DATA = {
  hour: "hour",                                     // {Number} Hours in a day.
  totalUserEngagement: "totalUserEngagement",       // {Number} Total UserEngagement in that hour.
};

/**
 * Parse a PopularTime object.
 * @param {Object} data PopularTime object: Refer to POPULAR_TIME_DATA.
 * @return {Object} Prased PopularTime object.
 */
export function parsePopularTimeChartData(data) {
  return parse(
    { hour: data.hour, totalUserEngagement: data.totalUserEngagement },
    POPULAR_TIME_DATA
  );
}

/**
 * Serialize a PopularTime object.
 * @param {Object} data PopularTime object: Refer to POPULAR_TIME_DATA.
 * @return {Object} Prased PopularTime object.
 */
export function serializeChartData(data) {
  return serialize(data, POPULAR_TIME_DATA);
}
