// Image Selection Component.
// Author : Huen Oh (heons921@gmail.com).
// @param {string[]}  images    List of image urls. (in)
// @param {Object[]}  inImageObjects List of image objects. (in)
// @param {bool}      single    For single image flag. (in)
// @param {bool}      disabled  Disabled property of the component. (in)
// @param {bool}      value     Value for isChanged. v-model. (in, out)
// @param {string}    name      Name of the component. It is the identifier of the component. (in)

<template>
  <div class="image-selection">
    <b-input-group class="mb-3" v-if="!disabled">
      <b-form-file
        :data-cy="`${name}-select-image`"
        v-model="currentImage" 
        accept="image/*" 
        :disabled="disabled"
      ></b-form-file>
    </b-input-group>
    <draggable v-model="imageObjects" class="row mb-2" :disabled="disabled">
      <b-colxx xxs="3" v-for="(item, index) in imageObjects" :key="index">
        <div
          :data-cy="`${name}-image-${index}`"
          class="cls-img-container"
        >
          <b-img v-if="index == 0" class="cls-img-main cls-img" :src="item.preview"/>
          <b-img v-else class="cls-img" :src="item.preview"/>
          <div class="cls-img-middle" v-if="!disabled">
            <a href="javascript:void(0);"><b-img
              src='/assets/icons/close.svg'
              width="32px"
              height="32px"
              @click="deleteImage(index)"
            /></a>
          </div>
        </div>
      </b-colxx>
    </draggable>

    <!-- Modal for the Image Cropper -->
    <b-modal v-if="!disabled" :title="'Crop the image'" ref="modalImageCrop" class="modal-right" size="lg" v-model="dialogs.imageCrop" @hide="onCencelCropImage()">
      <cropper
        ref="imageCropper"
        classname="cropper"
        backgroundClassname="cropper-background"
        :src="imagePreview"
        :stencil-props="{aspectRatio: 6/4}"
      />
      <template slot="modal-footer">
        <b-button 
          data-cy="btn-cancel"
          variant="outline-secondary" 
          @click="onCencelCropImage()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button 
          data-cy="btn-submit"
          variant="primary" 
          class="mr-1" 
          @click="onSubmitCropImage()"
        >
          {{ $t('buttons.submit') }}
        </b-button>
      </template>
    </b-modal>
  </div>
  
</template>

<script>
import _ from "lodash";

import draggable from 'vuedraggable'

// TODO : think about using copper.js instead.
import { Cropper } from 'vue-advanced-cropper';

import { addNotifyFail } from '@/utils/notify';

import { compressImage } from '@/utils/general';

export default {
  components: {
    draggable,
    Cropper,
  },
  props:{
    // TODO : replace images with inImageObjects
    // {string[]} List of image urls.
    images: {
      type: Array,
      default: () => []
    },
    // {Object[]}  inImageObjects List of image objects. (in)
    inImageObjects: {
      type: Array,
      default: () => []
    },
    // {bool} For single image flag.
    single: {
      type: Boolean,
      default: false
    },
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {bool} Value for isChanged. v-model.
    value: {
      type: Boolean,
      default: false
    },
    // {string} Name of the component. It is the identifier of the component. (in)
    name: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      currentImage: null, // Current selected image.
      imageObjects: [],   // Image objects. [{file:File, preview:""}]
      toDelete: [],       // List of image urls to delete.

      isFirstWatch:true,  // {bool} Flag if it the first watch to detemine isChanged flag.
      isChanged:false,    // {bool} Flag if anything has changed in the component. // TODO : may not need

      // Image src
      imagePreview: '',   // {string} Preview of the image in Image Cropper.
      imageFile: null,    // {File}   Selected image file in Image Cropper.
      
      dialogs: {          // {Object} Handler object for dialogs(Close and Open).
        imageCrop: false,
      },
    }
  },
  watch: {
    /**
     * Watch for currnetImage to read image file and open dialog for Image Cropper.
     * @param {File} img Image file.
     */
    currentImage: function(img) {
      if (img) {
        const fr = new FileReader();
        fr.readAsDataURL(img);
        fr.addEventListener("load", () => {
          // Save the file and preview.
          this.imageFile = img;
          this.imagePreview = fr.result;
          // Open the crop dialog.
          this.dialogs.imageCrop = true;
        });
      }
      // console.log('mounted', this.imageObjects);
    },
    /**
     * Watch to check changes in currentGroups.
     */
    imageObjects: {
      deep:true,
      handler() {
        // console.log("watch currentGroups changed");
        if(this.isFirstWatch == false) {
          this.isChanged = true;
          this.$emit('input', true);
          // console.log("watch currentGroups changed - not first");
        } else {
          // console.log("watch currentGroups changed - first", this.imageObjects);
          this.isFirstWatch = false;
        }        
      },
    },
    /**
     * Watch for images to update imageObjects.
     */
    images: {
      deep:true,
      handler() {
        this.updateImageObjects();
      }
    },
    /**
     * Watch for inImageObjects to update imageObjects.
     */
    inImageObjects: {
      deep: true,
      handler() {
        if (Array.isArray(this.inImageObjects) && this.inImageObjects.length > 0) {
          const listObj = [];
          this.inImageObjects.forEach(obj => {
            listObj.push(obj);
          });
          this.imageObjects = listObj;
        }
      }
    },
  },

  computed: {
  },
  methods: {
    addNotifyFail,
    /**
     * On cancel in Image Cropper Dialog.
     * Close the dialog.
     */
    onCencelCropImage() {
      this.dialogs.imageCrop = false;
      this.currentImage = null;
    },
    /**
     * On submit in Image Cropper Dialog.
     * Update image objects with cropped image.
     */
    onSubmitCropImage() {
      // Get canvas.
      const { canvas } = this.$refs.imageCropper.getResult();

      canvas.toBlob((blob) => {
        // Create temporary file
        const tmpFile = new File([blob], "tmpFile.jpg", {type:this.imageFile.type});

        // Create a small size image for preview.
        compressImage(canvas.toDataURL()).then(dataURL => {

          // Create the image object.
          const item = {file:tmpFile, preview:dataURL};
  
          // Validate the size of image.
          // NOTE: after cropping the file size is changed. so it is better to validate here.
          if (this.validateImageSize(item)) {
  
            // Update the image objects.
            if (this.single) {
              if(this.imageObjects.length > 0) {
                this.deleteImage(0);
              } else {}
              this.imageObjects = [item];
            } else {
              this.imageObjects.push(item);
            }
          }

        })

      }, this.imageFile.type);

      // Close the dialog.
      this.onCencelCropImage();
    },

    /**
     * @param {Object} image {file:File, preview:String}
     * @returns Boolean true if valid else false.
     */
    validateImageSize(image) {
      const MAX_SINGLE_FILE_SIZE = 5242880;
      const MAX_MULTIPLE_FILE_SIZE = 10485760;

      if (image.file.size > MAX_SINGLE_FILE_SIZE) {
        this.addNotifyFail(this.$t('validations.image-size-exceed-error'));
        return false;
      }

      if (this.single === false) {
        // Check for overall image upload size
        const images = this.imageObjects
          .filter(image => image.file != null);

        // Include the current image.
        images.push(image);

        const overallImageSize = images
          .reduce((total, current) => total + current.file.size, 0);

        if (overallImageSize > MAX_MULTIPLE_FILE_SIZE) {
          this.addNotifyFail(this.$t('validations.image-list-size-exceed-error'));
          return false;
        }
      }

      return true;

    },

    /**
     * Get image objects.
     * @return {Object} Image objects: [{file:File, preview:""}]
     */
    getImageObjects() {
      // console.log('getImageObjects', this.imageObjects)
      return this.imageObjects;
    },
    /**
     * Get the list of image files which are added.
     * @return {File[]} List of image files.
     */
    getImageFiles() {
      let files = this.imageObjects.map((obj) => {
        return obj.file;
      })
      return _.compact(files);
    },
    /**
     * Get the list of image urls to delete.
     * @return {string[]} List of image urls to delete.
     */
    getImagesToDelete() {
      // console.log('getImagesToDelete', this.toDelete);
      return this.toDelete;
    },
    /**
     * Delete image from the image objects.
     * If the image object doesn't have file (urls only) then add it to toDelete.
     * @param {number} index Index of the image objects to delete.
     */
    deleteImage(index) {
      // If there is a preview but without a file,
      // it means it was uploaded already and
      // has to be deleted
      if (this.imageObjects[index].file === null) {
        this.toDelete.push(this.imageObjects[index].preview);
      }
      this.imageObjects.splice(index, 1);
    },
    /**
     * Update imageObjects by images input.
     */
    updateImageObjects() {
      if (!Array.isArray(this.images) || (this.images.length < 1)) {
          this.isFirstWatch = false;
          return;
        }

        if (this.single) {
          // Work with a genral interface - an array
          // but support even a single value
          const preview = Array.isArray(this.images) ? this.images[0] : this.images;
          this.imageObjects = [{file:null, preview:preview}];
        } else if (this.images.length > 0) {
          this.imageObjects = [];
          for (let preview of this.images) {
            this.imageObjects.push({file:null, preview:preview});
          }
        }
    },
  },
  mounted() {
    // console.log('mounted', this.images);
    // console.log('mounted', this.imageObjects);
    this.updateImageObjects();
  }
}
</script>
