// Checkbox component
// Author : Huen Oh (heons921@gmail.com)

<template>
  <b-form-group class="d-inline-block" >
    <b-form-checkbox
      data-cy="top-checkbox"
      class="ml-4"
      :checked="checked" 
      :indeterminate="indeterminate"
      @change="onChange"
    />   
  </b-form-group>
</template>


<script>

export default {
  props:{
      // {bool} bool value of  items: true/ false
      checked:{
        type:Boolean,
        default:false
      },
      // {bool} bool value of  items: true/ false
      indeterminate:{
        type:Boolean,
        default:false
   }

  },
  methods:{
    onChange() {
      this.$emit('change', true);
    }
  },
  
  
  }

</script>
