// Search bar component
// Author : Huen Oh (heons921@gmail.com)

<template>
  <div class="search-sm d-inline-block mr-1 align-top">
    <b-input 
      data-cy="top-input-search"
      :placeholder="$t('menu.search')" 
      v-model="search"
    />
  </div>
</template>


<script>
import {mapActions } from 'vuex';

export default {
  data() {
    return {
      search:'' // {string} Search keyword.
    }
  },
  methods:{
  },
  watch: { 
    search: function(phrase) {
      this.$emit('change', this.search);
    }
  },
}
</script>
