// Componet for selecting Attrubutes.
// Author : Huen Oh (heons921@gmail.com)
// @param {string} name       Name of the component. Index in usual.
// @param {bool}   value      Value for isChanged. v-model.
// @param {bool}   disabled   Disabled property of the component. (in)
// @param {number} colSize    Size of the column for the form. : 1 ~ 12. (in)
// @param {Object} attributes Attributes. (in)


<template>
<div>
  <!-- Dialog for Add Attribute -->
  <!-- TODO : change add-new-attribute -->
  <dialog-name-description
    name="dlg-attribute"
    :title="$t('product.add-new-attribute')"
    :dialog="dialogs.attribute"
    @dialog-close="closeDialogAttribute"
    @dialog-submit="onSubmitDialogAttribute"
  />
  
  <!-- Product Attribute Selection -->
  <!-- TODO : change choose-attribute -->
  <b-form-group>
    <h6 v-if="!disabled">{{$t('product.choose-attribute')}}</h6>
    <h6 v-else>{{$t('product.attribute')}}</h6>
    <b-row align-v="center" no-gutters v-if="!disabled">
      <!-- Product Attribute : Selection -->
      <b-colxx :sm="colSize">
        <v-select
          :data-cy="`${name}-select-attribute`"
          :options="currentSelOptions" 
          v-model="selectedAttribute" 
          @input="addAttributeField"
        >
          <span slot="no-options">
            {{$t('validations.no-attribute-available')}}
          </span>

          <template #search="{ attributes, events }">
            <input
              :data-cy="`${name}-select-attribute-input`"
              class="vs__search"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
      </b-colxx>
      <b-colxx>
        <!-- Product Attribute : Add button -->
        <b-button 
          :data-cy="`${name}-btn-add-attribute`"
          variant="outline-primary" 
          size="xs" 
          class="cls-btn-add" 
          @click="openDialogAttribute()"
        >
          <b-img src="/assets/icons/plus.svg" widht="12" height="12"></b-img>
        </b-button>
      </b-colxx>
    </b-row>
  </b-form-group>
  <!-- Product Attribute Edit -->
  <b-form-group class="custom-form-group">
    <b-row align-v="center" no-gutters>
      <b-colxx :sm="colSize">
        <div>
          <b-row align-v="center"
            v-for="(attr, index) in currentAttributes"
            :key="attr.label">
            <b-colxx sm="1">
            </b-colxx>
            <!-- Product Attribute : Edit Input -->
            <b-colxx sm="9">
              <label class="form-group has-top-label">
                <input 
                  :data-cy="`${name}-input-attribute-${index}`" 
                  class="form-control custom-form-input" 
                  v-model="attr.value" 
                  :disabled="disabled"
                >
                <span>{{ attr.label }}</span>
              </label>
            </b-colxx>
            <!-- Product Attribute : Button x -->
            <b-colxx sm="1" v-if="!disabled">
              <b-row>
                <a :data-cy="`${name}-input-attribute-${index}-delete`" href="javascript:void(0);">
                  <b-img class="cls-btn-icon" src="/assets/icons/close.svg" id="btn_del_attr" @click="deleteAttribute(index)"></b-img>
                </a>
              </b-row>
            </b-colxx>
          </b-row>

          <b-badge v-if="currentAttributes.length == 0 && disabled" class="mb-1 cls-badge" pill variant="outline-dark">
            {{$t('general.none')}}
          </b-badge>
        </div>
      </b-colxx>
    </b-row>
  </b-form-group>   
</div>
</template>


<script>
import vSelect    from 'vue-select'
import 'vue-select/dist/vue-select.css'

import DialogNameDescription  from '@/components/Common/DialogNameDescription'

import { mapGetters, mapActions } from 'vuex'

// Notifications
import {
  addNotifySuccess,
} from "@/utils/notify.js"

export default {
  props:{
    // {string} Name of the component. Index in usual.
    name: {
      type: String,
      default: ""
    },
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {string} Size of the column for the form. : 1 ~ 12.
    colSize: {
      type: Number,
      default: 10,
    },
    // {Object[]} Attributes.
    attributes: {
      type: Object,
      default: () => {},
    },
    // {bool} Value for isChanged. v-model.
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'v-select': vSelect,
    DialogNameDescription,
  },
  data(){
    return {
      selectedAttribute: {value: "", label:"", text:""}, // Selected attrubute object.
      currentAttributes: [],  // Current selected attributes [{value:"", label:"", text:""}]

      dialogs: {              // Handler object for dialogs(Close and Open).
        attribute:false,
      },

      isFirstWatch:true,  // {bool} Flag if it the first watch to detemine isChanged flag.
      isChanged:false,    // {bool} Flag if anything has changed in the component. // TODO : may not need
    }    
  },

  created: function () {
    // console.log('created');
  },
  watch: {
    /**
     * Watch to check changes in currentAttributes.
     */
    currentAttributes: {
      deep:true,
      handler() {
        // console.log("watch currentAttributes changed");
        if(this.isFirstWatch == false) {
          this.isChanged = true;
          this.$emit('input', true);
          // console.log("watch currentAttributes changed");
        } else {
          // console.log("watch currentAttributes changed", this.currentAttributes);
          this.isFirstWatch = false;
        }
      },
    },
  },
  computed: {
    // Map getters
    ...mapGetters("product_attribute",  ["selectableProductAttributes"]),

    // Computed value to exclude selected items from the selection option.
    currentSelOptions: function() {
      return this.selectableProductAttributes.filter((attr) => {
        return (this.currentAttributes.find(element => element.label === attr.label) === undefined) && (attr.value !== "");
      });
    },
  },
  methods:{
    // Notifications.
    addNotifySuccess,

    // Map actions.
    ...mapActions("product_attribute",  ["addOneProductAttribute"]),

    /**
     * Reset values.
     */
    reset() {
      this.selectedAttribute = {value: "", label:"", text:""};
      this.currentAttributes = [];
      this.isFirstWatch = true;
      this.isChanged = false;
    },
    /**
     * Get attrubutes object of Attributes.
     * @return {Object} Attributes object as Key-value pair.
     */
    getAttrubutesObject() {
      // Convert attribute
      // [{value, label}, ... ] -> {value:label, value:label...}
      let attrObject = {};
      for (const attr of this.currentAttributes) {
        attrObject[attr.label] = attr.value;
      }
      return attrObject;
    },
    /**
     * Add attribute field to current attributes.
     */
    addAttributeField() {
      // console.log('this.selectedAttribute', this.selectedAttribute);
      // console.log('this.currentAttributes', this.currentAttributes);
      if (this.selectedAttribute) {
        if (this.selectedAttribute.value != "") {
          if (this.currentAttributes.find(element => element.label == this.selectedAttribute.label) === undefined) {
            // this.currentAttributes.push({value: "", label:this.selectedAttribute});
            this.currentAttributes.push(this.selectedAttribute);
          } else {}
        } else {}
      } else {}

      // Reset selection.
      this.selectedAttribute = {value: "", label:"", text:""};
      // console.log(this.currentAttributes);
    },
    /**
     * Delete an attribute. It only updates locally.
     * @param {number} index index of an item to delete.
     */
    deleteAttribute(index) {
      this.currentAttributes.splice(index,1);
    },

    // Dialog operations - Attribute
    /**
     * Close Attribute dialog - Add.
     */
    closeDialogAttribute() {
      this.dialogs.attribute = false;
    },
    /**
     * Open Attribute dialog - Add.
     */
    openDialogAttribute() {
      this.dialogs.attribute = true;
    },
    /**
     * On Submit Attribute dialog - Add.
     * Add and reload Attrubutes.
     * @param {Object} data Attribute object: Refer to PRODUCT_ATTRIBUTE_MODEL
     */
    onSubmitDialogAttribute(data) {
      // console.log('onSubmitDialogAttribute', data);
      if(data.name != "") {
        this.addOneProductAttribute(data).then(()=> {
          this.selectedAttribute = {value: data.name, label:data.name, text:data.name};
          this.addAttributeField();
          this.addNotifySuccess(this.$t('notify.attribute-add'));
        });
      } else { }
    },
  },
  mounted(){

     console.log('attributes', this.attributes);
    // console.log('disabled', this.disabled);
    // Initialize currentAttributes from the data.attributes
    const keys = Object.keys(this.attributes)
    for (const key of keys) {
      const item = {value : this.attributes[key], label:key};
      this.currentAttributes.push(item);
    }

    // If there are no attributes, isFirstWatch needs to be triggered manually.
    if(keys.length === 0) {
      this.isFirstWatch = false;
    } else {}
  },

}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>

