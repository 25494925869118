var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "root" } }, [
    _c("div", { staticClass: "fixed-background" }, [
      _c("main", [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "b-row",
              { staticClass: "h-100" },
              [
                _c(
                  "b-colxx",
                  {
                    staticClass: "mx-auto my-auto",
                    attrs: { xxs: "12", md: "10" },
                  },
                  [
                    _c(
                      "b-card",
                      { staticClass: "auth-card", attrs: { "no-body": "" } },
                      [
                        _c(
                          "div",
                          { staticClass: "position-relative image-side" },
                          [
                            _c("p", { staticClass: "text-white h2 pr-5" }, [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .$t("dashboards.marketing-platform-title")
                                    .toUpperCase()
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "white mb-0" }, [
                              _vm._v(_vm._s(_vm.$t("user.error-text"))),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-side" },
                          [
                            _c(
                              "router-link",
                              { attrs: { tag: "a", to: "/" } },
                              [_c("span", { staticClass: "logo-single" })]
                            ),
                            _vm._v(" "),
                            _c("h6", { staticClass: "mb-4" }, [
                              _vm._v(_vm._s(_vm.$t("pages.error-title"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "mb-0 text-muted text-small mb-0",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("pages.error-code")) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "display-1 font-weight-bold mb-5",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("user.bad-credentials-404"))
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-shadow",
                                attrs: {
                                  type: "submit",
                                  variant: "primary",
                                  size: "lg",
                                },
                                on: { click: _vm.goBack },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("pages.go-back-home")) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }