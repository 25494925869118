// Componet for selecting Day and Time for availableTime.
// Author : Huen Oh (heons921@gmail.com)
// @param: {bool}     disabled      Disabled property of the component. (in)
// @param: {bool}     typeGroup     To check if its for group. (in)
// @param: {bool}     isGroupSynced To check group sync. (in)
// @param: {Object[]} days          Days:availableTime: { day, fromTime, toTime }. (in)

<template>
    <div>
      <b-row class="mb-5">
        <b-colxx xxs="4">
          <!-- Check Box for Group Sync -->
          <b-form-checkbox class="mb-0"
            data-cy="day-date-time-checkbox-group"
            v-if="typeGroup == true" 
            v-model="isGroupSync" 
            :disabled="disabled"
          >
            <h3>{{$t('forms.select-available-time')}}</h3>
          </b-form-checkbox>
          <h3 v-else>{{$t('forms.select-available-time')}}</h3>
        </b-colxx>
        <b-colxx>
          <!-- Toggle button to sync time -->
          <toggle-button
            data-cy="day-date-time-toggle-sync"
            :disabled="disabled"
            :value="switches.syncTime"
            :sync="true"
            :labels="{checked: 'SYNCED', unchecked: 'NOT SYNCED'}"
            :width="100"
            v-model="switches.syncTime"
            color="#f18024"
            @change="markChanged()"
          />
        </b-colxx>
      </b-row>
      <b-row class="mb-1" v-for="day in dayOfWeek" :key="day">
        <!-- Button -->
        <b-colxx xxs="2" class="mb-5">
          <b-button 
            :data-cy="`day-date-time-btn-${day}`"
            :disabled="disabled"
            variant="outline-primary" 
            class="mb-2" size="sm" 
            @click="onClickCheckButtonDay(day)" 
            :pressed="sliderCheckboxes.indexOf(day) !== -1">
            {{day}}
          </b-button>
        </b-colxx>
        <!-- Slider bar -->
        <b-colxx xxs="10" class="mb-5" v-if="sliderCheckboxes.indexOf(day) !== -1">
          <vue-slider
            :data-cy="`day-date-time-slider-${day}`"
            v-if="switches.syncTime"
            v-model="sliderTimeValuesSync" 
            ref="sliders"
            :data="sliderTimeData"
            :disabled="disabled"
            tooltipPlacement="bottom"
            tooltip="always"
            :tooltip-formatter="convertSliderTimeToTime"
            :useKeyboard="true"
            :enableCross="false"
            :maxRange="96"
            :dot-options="dotOptions"
            @change="markChanged">
          </vue-slider>
          <vue-slider
            :data-cy="`day-date-time-slider-${day}`"
            v-else
            v-model="sliderTimeValues[day]"
            ref="sliders"
            :data="sliderTimeData"
            :disabled="disabled"
            tooltipPlacement="bottom"
            tooltip="always"
            :tooltip-formatter="convertSliderTimeToTime"
            :useKeyboard="true"
            :enableCross="false"
            :maxRange="96"
            :dot-options="dotOptions"
            @change="markChanged">
          </vue-slider>
        </b-colxx>
      </b-row>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import { ToggleButton } from 'vue-js-toggle-button'

import { 
  DAY_OF_WEEK_SHORT, 
  DAY_OF_WEEK_LONG, 
  DAY_SHORT_TO_LONG, 
  DAY_LONG_TO_SHORT 
} from '@/constants/common'

// Default time value range for the slider.
const DEFAULT_TIME_VALUE = ['08:00A','18:00A'];

export default {
  components: {
    VueSlider,
    ToggleButton,
  },
  props:{
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {bool} To check if its for group.
    typeGroup: {
      type: Boolean,
      default: false
    },
    // {bool} To check group sync.
    isGroupSynced: {
      type: Boolean,
      default: false
    },
    // {Object[]} Days:availableTime: { day, fromTime, toTime }.
    days: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sliderTimeValuesSync: DEFAULT_TIME_VALUE,
      sliderTimeValues: {},         // {Object}   Selected time value ranges by days: {day:[fromTime, toTime]...}.
      sliderTimeData: [],           // {string[]} Data for the sliders.
      sliderCheckboxes: [],         // {string[]} Checked days for the sliders.
      dayOfWeek: DAY_OF_WEEK_SHORT, // {string[]} Days of week.
      switches: {
        syncTime: false,
      },

      isChanged: false,     // {bool} To check if anything has been changed by user actions.
      isGroupSync: false,   // {bool} For group sync only: ProductGroup and AddonGroup.
    }
  },

  methods: {
    /**
     * Get avaiableTime.
     * @return {Object[]} AvailableTime objects: {day, fromTime, toTime}
     */
    getAvailableTime() {
      let availableTime = [];
      for(let day of this.sliderCheckboxes) {
        const selTimeValues = this.switches.syncTime ? this.sliderTimeValuesSync : this.sliderTimeValues[day];

        let fromTime = this.convertSliderTimeToTime(selTimeValues[0]);
        let toTime = this.convertSliderTimeToTime(selTimeValues[1]);
        if (fromTime > toTime) {
          // Separate the time into Two
          availableTime.push({
            day: DAY_SHORT_TO_LONG[day],
            fromTime: fromTime,
            toTime: '23:59',
          });

          // case : if toTime is set at midnight (00:00) => fromTime > toTime, 
          //        but should not add the next day time.
          if (toTime !== '00:00') {
            let nextDay = this.getNextDay(DAY_OF_WEEK_SHORT, day);
            availableTime.push({
              day: DAY_SHORT_TO_LONG[nextDay],
              fromTime: '00:00',
              toTime: toTime,
            });
          }
        } else if (fromTime === toTime) {
          // Whole day which is from 00:00AM to 00:00AM next day
          if(fromTime === '00:00'){
            availableTime.push({
              day: DAY_SHORT_TO_LONG[day],
              fromTime: '00:00',
              toTime: '23:59',
            });  
          }else{

            // Separate the time into Two
            availableTime.push({
              day: DAY_SHORT_TO_LONG[day],
              fromTime: fromTime,
              toTime: '23:59',
            });

            let nextDay = this.getNextDay(DAY_OF_WEEK_SHORT, day);
            availableTime.push({
              day: DAY_SHORT_TO_LONG[nextDay],
              fromTime: '00:00',
              toTime: toTime,
            });
          }
        }else {
          availableTime.push({
            day: DAY_SHORT_TO_LONG[day],
            fromTime: fromTime,
            toTime: toTime,
          });
        }
      }

      // return [];
      return availableTime;
    },
    /**
     * Get isChanged.
     */
    getIsChanged() {
      return this.isChanged;
    },
    /**
     * Get isGroupSync.
     */
    getIsGroupSync() {
      return this.isGroupSync;
    },

    /**
     * Initialize sliderTimeData.
     */
    initSliderTimeData() {
      this.sliderTimeData = [];
      // let's use 15 mins interval
      for (let hour of Array.from(Array(24).keys())) {
        for (let minute of [0, 15, 30, 45]) {
          const hourPad = `${hour}`.padStart(2, '0');
          const minutePad = `${minute}`.padStart(2, '0');
          this.sliderTimeData.push(`${hourPad}:${minutePad}`);
        }
      }
      const timeData1 = this.sliderTimeData.map(time => {
        return time+'A';
      });
      const timeData2 = this.sliderTimeData.map(time => {
        return time+'B';
      });
      // console.log([...timeData1, ...timeData2])
      // [00:00A, 00:15A, ..., 00:00B, ..., 23:45B]
      this.sliderTimeData = [...timeData1, ...timeData2];
    },

    /**
     * Set up the slider value by given 'days:availableTime' object.
     */
    setUp() {
      // console.log('this.days', this.days)
      this.switches.syncTime = false;
      this.sliderCheckboxes = [];
      this.sliderTimeValues = {};
      this.isChanged = false;
      this.isGroupSync = this.isGroupSynced;
      this.sliderTimeValuesSync = DEFAULT_TIME_VALUE;

      // Convert the string of the days.
      if (!this.days) {
        for(let day of this.dayOfWeek) {
          this.sliderTimeValues[day] = DEFAULT_TIME_VALUE;
        }

      } else {
        
        let isDayTypeShort = true;  // Is Day type is SHORT? (true: SHORT, false: LONG)
        let dayToCompare = null;    // Day to compare to determine time synced status.
        if(this.days.length > 0) {
          // Get day type(SHORT or LONG) in the data
          if(!DAY_OF_WEEK_SHORT.includes(this.days[0].day)) {
            isDayTypeShort = false;
          } else {}

          // Create the map.
          let mapDayTimes = new Map();
          if(isDayTypeShort) {
            // Get sun ~ sat. order as map.
            DAY_OF_WEEK_SHORT.forEach((key) => {
              let selDay = this.days.filter((day) => {
                return day.day === key;
              });

              if(selDay.length > 0) {
                mapDayTimes.set(key, selDay);
              } else {}
            });
          } else {
            // Get sun ~ sat. order as map.
            DAY_OF_WEEK_LONG.forEach((key) => {
              let selDay = this.days.filter((day) => {
                return day.day === key;
              });              

              if(selDay.length > 0) {
                mapDayTimes.set(DAY_LONG_TO_SHORT[key], selDay);
              } else {}

            });
          }

          // Create the data.
          this.sliderTimeValues = {};
          mapDayTimes.forEach((value, key) => {
            // console.log('key', key);
            // console.log('value', value);

            value.sort((a,b) => (a.fromTime < b.fromTime) ? -1 : ((a.fromTime > b.fromTime) ? 1 : 0));

            value.forEach((day) => {
              if (day.fromTime === '00:00') {

                
                let prevDayKey = this.getPreviousDay(DAY_OF_WEEK_SHORT, key);
                let prevDay = mapDayTimes.get(prevDayKey);

                if (prevDay) {
                  let prevDayRange = prevDay[prevDay.length - 1];

                  // Don't add the range to current day 
                  // if prev day's range ends at 23:59 (midnight) : condition 1
                  // and prev day's range does not start at 00:00 : condidtion 2
                  // and prev day's from time and today's to time makes 24 hours or less. : condition 3
                  // Because in that case, this range must be added in prev day's range.

                  if (prevDayRange.toTime == '23:59' && 
                    prevDayRange.fromTime !== '00:00' && 
                    prevDayRange.fromTime >= day.toTime) {
                    return;
                  }
                }

                // this.sliderTimeValues[key] = [day.fromTime, day.toTime];
                this.sliderTimeValues[key] = this.convertTimeToSliderTime(day);
              } else {
                let nextDayKey = this.getNextDay(DAY_OF_WEEK_SHORT, key);
                let nextDay = mapDayTimes.get(nextDayKey);

                if(nextDay) {
                  // Find conditioned day to append.
                  let foundDayToAppend = nextDay.filter((dayToAppend) => {
                    return (dayToAppend.fromTime === '00:00') && 
                            (dayToAppend.toTime !== '23:59') && 
                            (dayToAppend.toTime <= day.fromTime);
                  });
                  // console.log('foundDayToAppend', foundDayToAppend);
                  if(foundDayToAppend.length === 0) {
                    // Partial day: completed.
                    // this.sliderTimeValues[key] = [day.fromTime, day.toTime];
                    this.sliderTimeValues[key] = this.convertTimeToSliderTime(day);
                  } else {
                    // Appended day: completed.
                    // this.sliderTimeValues[key] = [day.fromTime, foundDayToAppend[0].toTime];
                    this.sliderTimeValues[key] = this.convertTimeToSliderTime({fromTime:day.fromTime, toTime:foundDayToAppend[0].toTime});
                  }
                } else {
                  // Partial day: completed.
                  // this.sliderTimeValues[key] = [day.fromTime, day.toTime];
                  this.sliderTimeValues[key] = this.convertTimeToSliderTime(day);
                }
              }
            });
            
          });
          // console.log('this.sliderTimeValues',this.sliderTimeValues);

          // To get sync value
          let keys =  Object.keys(this.sliderTimeValues);
          let timeToCompare = this.sliderTimeValues[keys[0]];
          this.sliderTimeValuesSync = timeToCompare; //sync time
          let isEqual = true;
          for(const key in this.sliderTimeValues)  {
            this.sliderCheckboxes.push(key);
            if (timeToCompare[0] !== this.sliderTimeValues[key][0]) {
              isEqual = false;
            }
            if (timeToCompare[1] !== this.sliderTimeValues[key][1]) {
              isEqual = false;
            }
          }

          // Set Sync Time switch
          if(isEqual == true) {
            this.switches.syncTime = true;
          } else {
            this.switches.syncTime = false;
          }

        } else {}

      }

      // console.log(this.sliderTimeValues);
    },

    // TODO : make it general and move to utils/general
    /**
     * Get previous day.
     * It assumes day is in dayOfWeek.
     * @param {string[]}  dayOfWeek Days of week.
     * @param {string}    day       Day.
     */
    getPreviousDay(dayOfWeek, day) {
      let index = dayOfWeek.indexOf(day);
      return dayOfWeek[(dayOfWeek.length+index-1)%dayOfWeek.length];
    },
    /**
     * Get next day.
     * It assumes day is in dayOfWeek.
     * @param {string[]}  dayOfWeek Days of week.
     * @param {string}    day       Day.
     */
    getNextDay(dayOfWeek, day) {
      let index = dayOfWeek.indexOf(day);
      return dayOfWeek[(index+1)%dayOfWeek.length];
    },

    /**
     * Convert SliderTime(hh:mmA or hh:mmB) to Time(hh:mm).
     * It just return substring to delete 'A' or 'B'.
     * @param {string} time SliderTime(hh:mmA or hh:mmB).
     * @return {string} Time(hh:mm).
     */
    convertSliderTimeToTime(time) {
      return time.substring(0,5);
    },
    /**
     * Convert Time(hh:mm) to SliderTime(hh:mmA or hh:mmB).
     * It assumes day object has valid fromTime and toTime.
     * @param {Object} day DayDateTime object: {day, fromTime, toTime}.
     * @return {string[]} Time range: [fromTime, toTime].
     */
    convertTimeToSliderTime(day) {
      let fromTime, toTime;
      if (day.fromTime >= day.toTime) {
        fromTime = day.fromTime + 'A';
        toTime = day.toTime + 'B';
      } else {
        fromTime = day.fromTime + 'A';
        toTime = day.toTime + 'A';
        if (day.toTime === '23:59') {
          // Special case for whole day.
          toTime = '00:00B';
        }
      }
      return [fromTime, toTime];
    },

    /**
     * Mark the flags of changed.
     */
    markChanged() {
      this.isGroupSync = true;
      this.isChanged = true;
    },

    /**
     * On click check button of a day.
     * It toggles the check button and mark isChanged.
     * @param {string} strDay Day string.
     */
    onClickCheckButtonDay(strDay) {
      const index = this.sliderCheckboxes.indexOf(strDay);
      if (index === -1) {
        this.sliderCheckboxes.push(strDay);

        if (!this.sliderTimeValues[strDay]) {
          this.sliderTimeValues[strDay] = DEFAULT_TIME_VALUE;
        }
      } else {
        this.sliderCheckboxes.splice(index, 1);
      }

      // Mark as changed 
      this.markChanged();
    },

    // // testing
    // onSliderChange(rangeVal) {
    //   this.markChanged();
    //   console.log('onSliderChange', rangeVal);
    //   if(rangeVal[0].includes('B')) {
    //     console.log('onSliderChange', rangeVal[0]);
    //     rangeVal[0] = '00:00B';
    //   }
    // },

  },

  computed: {
    /**
     * Set the dot options for slider.
     * @returns DotOption interface by vue-slider-component
     */
    dotOptions() {
      // Limit the max value for first dot to 23:45.
      return [
        // options for dot 1.
        {
          max: '23:45A'
        },
        // options for dot 2. 
        {}
      ]
    }
  },
  watch: {
    // testing
    days: function(){
      this.setUp();
    },
  },


  mounted() { 
    // Initialize sliderTimeData.
    this.initSliderTimeData();
    
    this.$nextTick(() => {
      // Set up the page with input prob data.
      this.setUp();
    });
  }
}
</script>