var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("dialog-product-group", {
        attrs: { dialog: _vm.dialogs.groups, data: _vm.emptyProductGroup },
        on: {
          "dialog-close": _vm.closeDialogProductGroup,
          onAddItem: _vm.onAddItem,
        },
      }),
      _vm._v(" "),
      _c(
        "b-form-group",
        [
          !_vm.disabled
            ? _c("h6", [_vm._v(_vm._s(_vm.$t("product.choose-product-group")))])
            : _c("h6", [_vm._v(_vm._s(_vm.$t("product.product-group")))]),
          _vm._v(" "),
          !_vm.disabled
            ? _c(
                "b-row",
                { attrs: { "align-v": "center", "no-gutters": "" } },
                [
                  _c(
                    "b-colxx",
                    { attrs: { sm: _vm.colSize } },
                    [
                      _c(
                        "v-select",
                        {
                          class: { "mb-5": _vm.$v.currentGroups.$invalid },
                          attrs: {
                            "data-cy": `${_vm.name}-select-product-group`,
                            options: _vm.currentSelOptions,
                          },
                          on: { input: _vm.onSelectItem },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "search",
                                fn: function ({ attributes, events }) {
                                  return [
                                    _c(
                                      "input",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "vs__search",
                                            attrs: {
                                              "data-cy": `${_vm.name}-select-product-group-input`,
                                            },
                                          },
                                          "input",
                                          attributes,
                                          false
                                        ),
                                        events
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            203382999
                          ),
                          model: {
                            value: _vm.selGroup,
                            callback: function ($$v) {
                              _vm.selGroup = $$v
                            },
                            expression: "selGroup",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "validations.no-product-group-available"
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-colxx",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "cls-btn-add",
                          attrs: {
                            "data-cy": `${_vm.name}-btn-add-product-group`,
                            variant: "outline-primary",
                            size: "xs",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openDialogProductGroup()
                            },
                          },
                        },
                        [
                          _c("b-img", {
                            attrs: {
                              src: "/assets/icons/plus.svg",
                              width: "12",
                              height: "12",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-row",
            { attrs: { "align-v": "center", "no-gutters": "" } },
            [
              _c(
                "b-form-invalid-feedback",
                {
                  attrs: {
                    state: _vm.isRequired
                      ? !_vm.$v.currentGroups.$invalid || _vm.isFirstLoad
                      : true,
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("validations.no-selection-validation")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.currentGroups, function (productGroup, index) {
                return _c(
                  "b-badge",
                  {
                    key: productGroup,
                    staticClass: "mb-1 cls-badge",
                    attrs: { pill: "", variant: "outline-dark" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.getNameByIdFromList(
                            productGroup,
                            _vm.selectableProductGroups
                          )
                        ) +
                        " \n        "
                    ),
                    !_vm.disabled
                      ? _c(
                          "a",
                          {
                            attrs: {
                              "data-cy": `${_vm.name}-badge-product-group-${index}-delete`,
                              href: "javascript:void(0);",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteProductGroup(index)
                              },
                            },
                          },
                          [_vm._v("x")]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.currentGroups.length == 0 && _vm.disabled
                ? _c(
                    "b-badge",
                    {
                      staticClass: "mb-1 cls-badge",
                      attrs: { pill: "", variant: "outline-dark" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.none")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }