// Button for Add Item.
// Author : Huen Oh (heons921@gmail.com)

<template>
  <b-button
    data-cy="top-btn-add"
    variant="outline-secondary" 
    size="xs" 
    @click="onClick"
  >
    <b-img src="/assets/icons/plus.svg" widht="16" height="16"></b-img>
  </b-button>
</template>


<script>

export default {
  methods:{
    /**
     * On click the button.
     */
    onClick() {
      this.$emit('click');
    }
  }
}
</script>
