var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("dialog-name-description", {
        attrs: {
          name: "dlg-attribute",
          title: _vm.$t("product.add-new-attribute"),
          dialog: _vm.dialogs.attribute,
        },
        on: {
          "dialog-close": _vm.closeDialogAttribute,
          "dialog-submit": _vm.onSubmitDialogAttribute,
        },
      }),
      _vm._v(" "),
      _c(
        "b-form-group",
        [
          !_vm.disabled
            ? _c("h6", [_vm._v(_vm._s(_vm.$t("product.choose-attribute")))])
            : _c("h6", [_vm._v(_vm._s(_vm.$t("product.attribute")))]),
          _vm._v(" "),
          !_vm.disabled
            ? _c(
                "b-row",
                { attrs: { "align-v": "center", "no-gutters": "" } },
                [
                  _c(
                    "b-colxx",
                    { attrs: { sm: _vm.colSize } },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            "data-cy": `${_vm.name}-select-attribute`,
                            options: _vm.currentSelOptions,
                          },
                          on: { input: _vm.addAttributeField },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "search",
                                fn: function ({ attributes, events }) {
                                  return [
                                    _c(
                                      "input",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "vs__search",
                                            attrs: {
                                              "data-cy": `${_vm.name}-select-attribute-input`,
                                            },
                                          },
                                          "input",
                                          attributes,
                                          false
                                        ),
                                        events
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2962217202
                          ),
                          model: {
                            value: _vm.selectedAttribute,
                            callback: function ($$v) {
                              _vm.selectedAttribute = $$v
                            },
                            expression: "selectedAttribute",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("validations.no-attribute-available")
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-colxx",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "cls-btn-add",
                          attrs: {
                            "data-cy": `${_vm.name}-btn-add-attribute`,
                            variant: "outline-primary",
                            size: "xs",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openDialogAttribute()
                            },
                          },
                        },
                        [
                          _c("b-img", {
                            attrs: {
                              src: "/assets/icons/plus.svg",
                              widht: "12",
                              height: "12",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { staticClass: "custom-form-group" },
        [
          _c(
            "b-row",
            { attrs: { "align-v": "center", "no-gutters": "" } },
            [
              _c("b-colxx", { attrs: { sm: _vm.colSize } }, [
                _c(
                  "div",
                  [
                    _vm._l(_vm.currentAttributes, function (attr, index) {
                      return _c(
                        "b-row",
                        { key: attr.label, attrs: { "align-v": "center" } },
                        [
                          _c("b-colxx", { attrs: { sm: "1" } }),
                          _vm._v(" "),
                          _c("b-colxx", { attrs: { sm: "9" } }, [
                            _c(
                              "label",
                              { staticClass: "form-group has-top-label" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: attr.value,
                                      expression: "attr.value",
                                    },
                                  ],
                                  staticClass: "form-control custom-form-input",
                                  attrs: {
                                    "data-cy": `${_vm.name}-input-attribute-${index}`,
                                    disabled: _vm.disabled,
                                  },
                                  domProps: { value: attr.value },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        attr,
                                        "value",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(attr.label))]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          !_vm.disabled
                            ? _c(
                                "b-colxx",
                                { attrs: { sm: "1" } },
                                [
                                  _c("b-row", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          "data-cy": `${_vm.name}-input-attribute-${index}-delete`,
                                          href: "javascript:void(0);",
                                        },
                                      },
                                      [
                                        _c("b-img", {
                                          staticClass: "cls-btn-icon",
                                          attrs: {
                                            src: "/assets/icons/close.svg",
                                            id: "btn_del_attr",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteAttribute(index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm.currentAttributes.length == 0 && _vm.disabled
                      ? _c(
                          "b-badge",
                          {
                            staticClass: "mb-1 cls-badge",
                            attrs: { pill: "", variant: "outline-dark" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.none")) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }