import HttpStatus from 'http-status-codes';
import i18n from '@/i18n'

// Selectable item : all
export const SELECTABLE_ALL = { value: "", label: i18n.tc('general.options-all'), text: i18n.tc('general.options-all') };

/**
 * Compare two value of string.
 * @param {string} val1 Compare value1.
 * @param {string} val2 Compare value2.
 * @return true if int value of val1 < val2, false otherwise.
 */
export function compareStringInt(val1, val2) {
  return parseInt(val1) < parseInt(val2);
}

// Get {value: id, text: name} list for dropdown selection menu from list of objects
// listObject : A list of object containing (id and name)
export function getListForDropDownMenu(listObjects, valueAsId = true) {
  // console.log(listObjects);
  let listForDropDownMenu = [];
  listForDropDownMenu.push(SELECTABLE_ALL); //Add all option

  for (const data of listObjects) {
    let item;
    if (true == valueAsId) {
      // item = { value: data.id, label: data.name };
      item = { value: data.id, text: data.name, label: data.name };
    } else {
      // item = { value: data.name, label: data.name };
      item = { value: data.name, text: data.name, label: data.name };
    }
    listForDropDownMenu.push(item);
  }

  return listForDropDownMenu;
}

/**
 * Add a value of an item to the item list if it doesn't exist in the list.
 * @param {string[]}  itemList List of items.
 * @param {Object}    item     Item object: {value,...}.
 */
export function addItemValueToList(itemList, item) {
  // console.log("addItemValueToList", itemList);
  // console.log("addItemValueToList", item);
  if (item) {
    const value = item.value;
    if (value != "") {
      if (itemList.find(element => element == value)) {
        // Same item is found and not going to be added.
        // console.log("Same item exist");
      } else {
        // console.log("added");
        itemList.push(value);
      }
    } else { }
  } else { }
}

/**
 * Format the Date as MM/DD/YYYY.
 * @param {String} date
 * @return  {String} in formated way with required locale
 */
export function formatDate(date) {
  if (date) {
    var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    let today = new Date(date);
    return today.toLocaleDateString("en-US", options);
  } else {
    return "";
  }
}

/**
 * Format the Date with time as MM/DD/YYYY, HH:MM AM/PM.
 * @param {String} date
 * @return  {String} in formated way with required locale
 */
export function formatDateWithTime(date) {
  if (date) {
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    let today = new Date(date);
    return today.toLocaleDateString("en-US", options);
  } else {
    return "";
  }
}

/**
 * Get formatted time from Date object, HH:MM AM/PM.
 * @param {String} date
 * @return  {String} Time in formated way with required locale
 */
export function getformattedTime(date) {
  if (date) {
    var options = { hour: '2-digit', minute: '2-digit' };
    let today = new Date(date);
    return today.toLocaleTimeString("en-US", options);
  } else {
    return "";
  }
}


/**
 * Get name(label) where [list].value == id
 * @param {string}    id   Id of an object.
 * @param {Object[]}  list List of objects: [{value,...},...]
 */
export function getNameByIdFromList(id, list) {
  // console.log(list);
  if (list.length > 0) {
    const findResult = list.find(element => element.value == id);
    if (undefined === findResult) {
      return "";
    } else {
      return findResult.label;
    }
  } else {
    return "";
  }
}

/**
 * Get names(labels) where [list].value == [ids]
 * @param {string[]} ids  Ids of objects.
 * @param {Object[]} list List of objects: [{value,...},...]
 */
export function getNamesByIdsFromList(ids, list) {
  let names = [];
  for (let id of ids) {
    names.push(getNameByIdFromList(id, list));
  }
  return names.join(', ');
}
/**
 * Compare if given two Date objects are equal.
 * It only compares year, month and day.
 * @param {Date} hideUntil1 Date object to compare.
 * @param {Date} hideUntil2 Date object to compare.
 * @return {bool} true if those are equal, false otherwise.
 */
export function isHideUntilEqual(hideUntil1, hideUntil2) {
  // compare only to the date
  const date1 = formatDate(hideUntil1);
  const date2 = formatDate(hideUntil2);
  return date1 == date2;
}
// TODO : simplify
/**
 * Compare if two given avaibleTimes are equal.
 * @param {Object} time1 AvailableTime object to compare: [{day, fromTime, toTime}...].
 * @param {Object} time2 AvailableTime object to compare: [{day, fromTime, toTime}...].
 * @return {bool} true if those are equal, false otherwise.
 */
export function isAvailableTimeEqual(time1, time2) {
  // console.log('time1', time1);
  // console.log('time2', time2);

  if ((time1 == null) || (time2 == null)) {
    if ((time1 == null) && (time2 == null)) {
      return true;
    } else {
      // console.log("out in null");
      return false;
    }
  }
  if (time1.length != time2.length) {
    // console.log("out in length");
    return false;
  }
  // Create a map from time1.
  let mapTimes = new Map();
  time1.forEach((time) => {
    mapTimes.set(time.day + time.fromTime + time.toTime, "dummy");
  });
  // Check the equal.
  let isEqual = true;
  time2.forEach((time) => {
    if (!mapTimes.has(time.day + time.fromTime + time.toTime)) {
      isEqual = false;
    }
  });

  return isEqual;
}

// TODO : varify and test.
/**
 * Deep copy an object or arrary of objects.
 * @param {Object | Object[]} object Object or Array of Objects to copy.
 * @return {Object | Object[]} Duplicated object of array of objects.
 */
export function copyObject(object) {
  if (Array.isArray(object)) {
    let copiedObj = []
    for (const obj of object) {
      copiedObj.push(JSON.parse(JSON.stringify(obj)));
    }
    return copiedObj;
  } else {
    return JSON.parse(JSON.stringify(object));
  }
}

/**
 * Check if object is null/undefined
 * @param {Object} obj  object need to be checked.
 */
export function isNullOrUndefined(obj) {
  return (obj === undefined || obj === null)
}

/**
 * Checks if the given string is undefined/null/empty.
 * @param {String} value 
 * @returns {boolean} true if value is undefined or null or empty string.
 */
export function isEmptyString(value) {
  return (value === undefined || value === null || value === '')
}

/**
 * Is response OK?
 * @param {Object} response Response object from Promise.
 * @return {boolean} true is response.status is HttpStatus.OK, false otherwise.
 */
export function isResponseOK(response) {
  return (response) && (response.status === HttpStatus.OK);
}

/**
 * Is response CREATED?
 * @param {Object} response Response object from Promise.
 * @return {boolean} true is response.status is HttpStatus.CREATED, false otherwise.
 */
export function isResponseCREATED(response) {
  return (response) && (response.status === HttpStatus.CREATED);
}

/**
 * Is response BAD_REQUEST?
 * @param {Object} response Response object from Promise.
 * @return {boolean} true is response.status is HttpStatus.BAD_REQUEST, false otherwise.
 */
export function isResponseBAD_REQUEST(response) {
  return (response) && (response.status === HttpStatus.BAD_REQUEST);
}


// Data URI related utils.

const BASE64_MARKER = ';base64,';

/**
 * Does the given URL (string) look like a base64-encoded URL?
 * Ref: https://gist.github.com/kylefox/c09a7b738aacf088c2ec2b359ddcc5ea
 * @param {string} url URL.
 */
export function isDataURI(url) {
  return url.split(BASE64_MARKER).length === 2;
}

/**
 * Converts a data URI string into a File object.
 * NOTE: Can not keep the File object in the persistent storage(Vuex)
 *       since the File object lost its prototype and become Object
 *       when the browser is refreshed.
 * Ref: https://gist.github.com/kylefox/c09a7b738aacf088c2ec2b359ddcc5ea
 * @param {string} dataURI Data URI
 */
export function dataURItoFile(dataURI) {
  // Format of a base64-encoded URL:
  // data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYAAAAEOCAIAAAAPH1dAAAAK
  const mime = dataURI.split(BASE64_MARKER)[0].split(':')[1];
  const filename = 'dataURI-file-' + (new Date()).getTime() + '.' + mime.split('/')[1];
  const bytes = atob(dataURI.split(BASE64_MARKER)[1]);
  const writer = new Uint8Array(new ArrayBuffer(bytes.length));

  for (var i = 0; i < bytes.length; i++) {
    writer[i] = bytes.charCodeAt(i);
  }

  return new File([writer.buffer], filename, { type: mime });
}

/**
 * Resize a base64 image
 * Ref: https://dev.to/antonreshetov/compress-image-in-browser-easy-5238
 * @param {String} base64 
 * @returns dataURL String.
 */
export function compressImage (base64) {
  const canvas = document.createElement('canvas')
  const img = document.createElement('img')

  return new Promise((resolve, reject) => {
    img.onload = function () {
      let width = img.width
      let height = img.height
      const maxHeight = 200
      const maxWidth = 200

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width))
          width = maxWidth
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height))
          height = maxHeight
        }
      }
      canvas.width = width
      canvas.height = height

      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)

      resolve(canvas.toDataURL('image/jpeg', 0.7))
    }
    img.onerror = function (err) {
      reject(err)
    }
    img.src = base64
  })
}



/**
 * Format number using comma as separator
 * @param {*} num 
 */
export function formatNumber(num, isToFixed) {
  if (isNullOrUndefined(num))
    return 0;
  if(isToFixed){
    return parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

/**
 * Convert a number to price format using commas and only 2 decimal places.
 * @param {number} num 
 * @returns formatted value.
 */
export function formatPrice(num) {
  const isToFixed = true;
  return +formatNumber(num, isToFixed);
  // + sign in the return statement removes the traling zeros.
}

/**
 * Format a number with 2 decimal places.
 * @param {number} num Nubmer to format.
 * @returns Formatted numver (2.00)
 */
export function formatPriceWith2Decimal(num) {
  const isToFixed = true;
  return formatNumber(num, isToFixed);
}


/**
 * To check if the number has more than 2 decimal places
 * @param {number} num 
 * @returns true/false whether the number has more than 2 decimal places
 */
export function isHasLess2DecimalPlaces(num){
  if(num != null && num != undefined && num.toString().includes('.')){
    const decs = num.toString().split('.')[1];
    return decs.length < 2;
  }

  return true;
}

/**
 * Converts +19876543210 to +1 (987) 654-3210
 * @param {*} phoneNumberString 
 */
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null;
}

/**
 * addressObject: { streetNumber, unit, streetName, postalCode, city, province, country, location: { x, y, type, coordinates } }
 * Example: 8 Fake Dr, Barrie, ON FGH DFG, Canada 
 */
export function formatAddress({ streetNumber, unit, streetName, postalCode, city, province, country }) {
  const streetNumberOrUnit = streetNumber && streetNumber.length > 0 ? streetNumber : unit;
  return `${streetNumberOrUnit} ${streetName}, ${city}, ${province} ${postalCode.toUpperCase()}, ${country}`;
}

/**
 * Convert Int value to Distance kms and meters. 60 -> 60 km(s), 0.12 -> 120 meter(s).
 * @param {*} distanceInteger 
 */
export function formatDistance (distanceInteger) {
  if (distanceInteger >= 1) {
    return `${Math.round(distanceInteger)} km(s)`;
  } else if (distanceInteger > 0 && distanceInteger < 1) {
    return `${Math.round(distanceInteger * 1000)} meter(s)`;   // convert kilometer to meters
  } else {
    return null;
  }
}

/**
 * Convert a Map object to js Object.
 * @param {Map} strMap map object to convert
 * @returns js object
 */
export function strMapToObj(strMap) {
  if (isNullOrUndefined(strMap)) {
    return null;
  }

  let obj = Object.create(null);
  for (let [k,v] of strMap) {
    // We don’t escape the key '__proto__'
    // which can cause problems on older engines
    obj[k] = v;
  }
  return obj;
}

/**
 * Convert js object to Map object.
 * @param {*} obj 
 * @returns 
 */
export function objToStrMap(obj) {
  if (isNullOrUndefined(obj)) {
    return null;
  }

  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}