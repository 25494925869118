import LocalStorageService from "@/utils/local_store";

/**
 * Guard function for beforeEnter to check if authentication.
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards.
 * @param {Route} to    The target Route Object being navigated to.
 * @param {Route} from  The current route being navigated away from.
 * @param {Function} next This function must be called to resolve the hook. The action depends on the arguments provided to next.
 */
export default (to, from, next) => {
  if (!!LocalStorageService.getAuth() && !!LocalStorageService.getAccessToken()) {
    // Refresh token should be done in Axios' interceptors
    next();
  } else {
    LocalStorageService.deleteAuth();
    LocalStorageService.deleteIsStripeConnected();
    next({ name: 'login' });
  }
}