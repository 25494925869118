/**
 * It contains store module for Merchant Dashboard.
 */

// Apis.
import {
  getMerchantInitMetricWithinDateRangeApi,
  getPlatformDataWithinDateRangeApi,
  getPopularTimeByDayWithinDateRangeApi,
  getUserRatingDataWithinDateRangeApi,
  getUserRatingOrderWithinDateRangeApi
} from "@/api/dashboard/merchant_dashboard.js";


import {
  parseUserRatingChartData,
  parseUserRatingPercentage
} from "@/models/userRating";
import { parsePopularTimeChartData }       from "@/models/popularTimeData";
import { isValidDate }                     from "@/utils/datetime"
import { isNullOrUndefined, isResponseOK } from "@/utils/general.js"

export default {
  namespaced: true,
  state: {
    initData: [],             // {Object[]} Initial Metric data: Refer to DASHBOARD_INIT.
    popularTimeData: [],      // {Object[]} User engagement data in that hour: Refer to DASHBOARD_POPULAR_TIME.
    initPlatformsData: [],    // {Object[]} Initial Platform data: Refer to DASHBOARD_PLATFORM.
    userRatingChartData: [],  // {Array} Array of objects holding rate(e.g: 1 or 2...) and respective total user ratings.
    averageRating: 0,         // {Number} Average of all the user ratings.
    ratingPagination:         // {Object} Including total totalRatings, pageNumber, pageSize
     { pageNumber: 1, pageSize: 5 },     
    userRatingOrders:         // {Object} Including totalRatings & all of the rating along with comment and order info
      { totalRatings: 0, ratings: [] },     
    totalRating: 0,           // {Number} Total number of user ratings.
    ratePercent: [],          // {Array} Array of numbers having %user ratings per rate(e.g: Rate:1->50%,Rate:2->20%...).
    selectedStore: { value: "", label: "-All-" }, // {Object} Selected Store Id.
    selectedReportDuration: {
      // {Object} Selected report duration.
      start: new Date(
        new Date().getFullYear(),
        new Date().getUTCMonth() - 1,
        new Date().getUTCDate()
      ),
      end: new Date(
        new Date().getFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate()
      )
    }
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.initData = [];
      state.popularTimeData = [];
      state.initPlatformsData = [];
      state.userRatingChartData = [];
      state.ratePercent = [];
      state.averageRating = 0;
      state.totalRating = 0;
      state.ratingPagination = { pageNumber: 1, pageSize: 5 };
      state.userRatingOrders = { totalRatings: 0, ratings: [] };

      state.selectedStore = { value: "", label: "-All-" }, // {Object} Selected Store Id.
      state.selectedReportDuration = {
        // {Object} Selected report duration.
        start: new Date(
          new Date().getFullYear(),
          new Date().getUTCMonth() - 1,
          new Date().getUTCDate()
        ),
          end: new Date(
            new Date().getFullYear(),
            new Date().getUTCMonth(),
            new Date().getUTCDate()
          )
      }
    },
    /**
     * Set selected stored 
     * @param {Object} state Local State object 
     * @param {Object} store Selected store 
     */
    setSelectedStore(state, store){
      state.selectedStore = store;

      // Reset user rating pagination to page 1
      state.ratingPagination.pageNumber = 1;
    },
    /**
     * Set report duration
     * @param {Object} state Local State object 
     * @param {Object} reportDuration Selected report duration 
     */
    setSelectedReportDuration(state, reportDuration){
      state.selectedReportDuration = reportDuration;
      
      // Reset user rating pagination to page 1
      state.ratingPagination.pageNumber = 1;
    },
    /**
     * Set initial data.
     * @param {Object} state Local State object.
     * @param {Object} data  Initial Metric data: Refer to DASHBOARD_INIT.
     */
    setInitData(state, data) {
      if (Array.isArray(data)) {
        state.initData = data;
      } else {
        state.initData = [];
      }
    },
    /**
     * Set platform data.
     * @param {Object} state Local State object.
     * @param {Object} data  Initial Metric data: Refer to DASHBOARD_PLATFORM.
     */
    setPlatformsData(state, data) {
      if (Array.isArray(data)) {
        state.initPlatformsData = data;
      } else {
        state.initPlatformsData = [];
      }
    },
    /**
     * Set PopularTime data.
     * @param {Object} state Local State object.
     * @param {Object} data  Initial Metric data: Refer to DASHBOARD_POPULAR_TIME.
     */
    setPopularTimeData(state, data) {
      state.popularTimeData = data;
    },
    /**
     * Setter for userRatingChartData array in the state.
     * @param {Object} state State of the user_rating store.
     * @param {Object} data Data to set userRatingChartData.
    */
    setUserRatingChartData(state, data) {
      state.userRatingChartData = data;
    },
    /**
     * Setter for averageRating in the state.
     * @param {Object} state State of the user_rating store.
     * @param {Object} data Data to set averageRating.
     */
    setAverageRating(state, data) {
      state.averageRating = data;
    },
    /**
    * Setter for totalRating in the state.
    * @param {Object} state State of the user_rating store.
    * @param {Object} data Data to set totalRating.
    */
    setTotalRating(state, data) {
      state.totalRating = data;
    },

    /**
     * Setter for rating pagination
     * @param {*} state 
     * @param {*} pagination - including totalRatings, pageSize, pageNumber
     */
    setRatingPagination(state, pagination){
      state.ratingPagination = pagination;
    },
    /**
     * Setter for all user rating orders
     * @param {} state 
     * @param {*} data 
     */
    setUserRatingOrders(state, data){
      state.userRatingOrders = data;
    },
    /**
     * Setter for rating page number
     * @param {*} state 
     * @param {*} pageNumber 
     */
    setPageNumber(state, pageNumber){
      state.ratingPagination.pageNumber = pageNumber;
    },
    /**
    * Setter for ratePercent array in the state.
    * @param {Object} state State of the user_rating store.
    * @param {Object} data Data to set ratePercent.
    */
    setRatePercent(state, data) {
      state.ratePercent = data;
    }
  },
  getters: {
    /**
     * Return selected store
     * @param {Object} state Local State object 
     */
    selectedStore: state => {
      return state.selectedStore;
    },
    /**
     * Return selected report duration
     * @param {Object} state Local State object 
     */
    selectedReportDuration: state => {
      return state.selectedReportDuration;
    },
    /**
     * Return initial data.
     * @param {Object}  state Local State object.
     */
    initData: state => {
      return state.initData;
    },
    /**
     * Return platforms data.
     * @param {Object}  state Local State object.
     */
    initPlatformsData: state => {
      return state.initPlatformsData;
    },
    /**
     * Return PopularTime data.
     * @param {Object} state Local State object.
    */
    popularTimeData: (state) => {
      return state.popularTimeData;
    },
    /**
    * Getter to get userRatingChartData array in the state.
    * @param {Object} state State of the popular_time store.
    * @return {Array} userRatingChartData array of the state. 
   */
    userRatingChartData: (state) => {
      return state.userRatingChartData;
    },
    /**
    * Getter to get averageRating in the state.
    * @param {Object} state State of the popular_time store.
    * @return {Array} averageRating of the state. 
   */
    averageRating: (state) => {
      return state.averageRating;
    },
    /**
     * Getter to get totalRating in the state.
     * @param {Object} state State of the popular_time store.
     * @return {Array} totalRating array of the state. 
    */
    totalRating: (state) => {
      return state.totalRating;
    },
    /**
     * Getter to get ratePercent array in the state.
     * @param {Object} state State of the popular_time store.
     * @return {Array} ratePercent array of the state. 
    */
    ratePercent: (state) => {
      return state.ratePercent;
    },
    /**
     * Getter to get rating pagination in the state
     * @param {*} state 
     * @returns {Object} including totalRatings, pageNumber, pageSize
     */
    ratingPagination: (state) => {
      return state.ratingPagination;
    },
    /**
     * Getter to get user rating orders in the state
     * @param {*} state 
     * @returns 
     */
    userRatingOrders: (state) => {
      return state.userRatingOrders;
    }
  },
  actions: {
    /**  
     * Load initial data for the merchant dashboard.
     * @param  {Object} payload Payload to get the data: Refer to DASHBOARD_PAYLOAD_GENERAL.
     * @return {Object} Response object of a Promise.
     */
    async loadInitMetricWithinDateRange({ commit }, payload) {
      // Validate the payload
      if (!isValidDate(payload.startDate) || !isValidDate(payload.endDate) || isNullOrUndefined(payload.merchantId)) {
        return null;
      } else { }

      const response = await getMerchantInitMetricWithinDateRangeApi(payload);

      if (isResponseOK(response)) {
        commit("setInitData", response.data);
      } else {}
      return response;
    },
    /**  
     * Load platform data for the merchant dashboard.
     * @param  {Object} payload Payload to get the data: Refer to DASHBOARD_PAYLOAD_GENERAL.
     * @return {Object} Response object of a Promise.
     */
    async loadPlatformsDataWithinDateRange({ commit }, payload) {
      // Validate the payload
      if (!isValidDate(payload.startDate) || !isValidDate(payload.endDate) || !payload.merchantId) {
        return null;
      } else { }

      const response = await getPlatformDataWithinDateRangeApi(payload);

      if (isResponseOK(response)) {
        commit("setPlatformsData", response.data);
      } else {}
      return response;
    },

    /**
     * Load PopularTime data for the merchant dashboard.
     * @param  {Object} payload Payload to get the data: Refer to DASHBOARD_PAYLOAD_GENERAL.
     * @return {Object} Response object of a Promise.
    */
    async loadPopularTimeByDayWithinDateRange({ commit }, popularTimePayload) {
      // Validate the payload
      if (!isValidDate(popularTimePayload.startDate) || !isValidDate(popularTimePayload.endDate) || !popularTimePayload.merchantId) {
        return null;
      } else { }
      
      const response = await getPopularTimeByDayWithinDateRangeApi(popularTimePayload);
      if (isResponseOK(response)) {
        let data = response.data;                       // To fill the Response array with total 24 hours. 
        if (Array.isArray(data) && data.length > 0) {   // Fill array only if array is not empty.  
          let noOfHour = 1;                             // Else chart will show no data to display.
          while (noOfHour <= 24) {                      // Loop through 24 hours.
            if (!data.some((element) => element.hour === noOfHour)) {
              // Fill the empty hours
              data.push({ hour: noOfHour, totalUserEngagement: 0 }); 
            } 
            noOfHour++;
          }
          // Sort the array based of hour to display on chart.
          data.sort((a, b) => (a.hour > b.hour ? 1 : -1));
          // Parse
          data = data.map((x) => {
            return parsePopularTimeChartData(x);
          });
        } else {
          data = [];
        }

        // Mutate popularTimeData array.
        commit("setPopularTimeData", data);
      }

      return response;
    },
    /**
     * Get userRatingChartData array, averageRating, totalRating, ratePercent  in the state.
     * @param {Object} commit To commit a mutation of state.
    * @param {
        Object
      } userRatingPayload Object to hold the inputs.
     * @return {Object} Response object from API call.
    */
    async loadUserRatingDataWithinDateRange({ commit }, userRatingPayload) {
      // Validate payload before calling api
      if (userRatingPayload.merchantId == null || userRatingPayload.merchantId == 0
        || !isValidDate(userRatingPayload.startDate) || !isValidDate(userRatingPayload.endDate)) {
        return;
      }

      const response = await getUserRatingDataWithinDateRangeApi(userRatingPayload);

      if (isResponseOK(response)) {
        let data = response.data;                                               // To fill the rating array with total 5 rates.
        if (data.ratingList.length != 5 && data.totalUserRatings != 0) {        // Fill rating array only if array is not empty.
          let noOfRates = 1;                                                    // And if total rates are not 5.
          while (noOfRates <= 5) {                                              // Loop through 5 rates.
            if (!data.ratingList.some((element) => element.rate === noOfRates)) // If rating array does not have that rate.
              data.ratingList
                .push({ rate: noOfRates, totalRatingsPerRate: 0, percentRatingsPerRate: 0 });     // Then push that empty data for that rate.
            noOfRates++;
          }
        }
        data.ratingList.sort((a, b) => (a.rate > b.rate ? 1 : -1));             // Sort rating array based of rate to display on chart.

        const chartData = data.ratingList.map((x) => {                          // Parse rating array using USER_RATING_DATA model.
          return parseUserRatingChartData(x);
        });
        const percent = data.ratingList.map((x) => {                            // Parse rating array using USER_RATING_PERCENTAGE model.
          return parseUserRatingPercentage(x);
        });

        const average = data.averageRating == 0 ? 3 : data.averageRating;       // If there is no rating then average rating is 3.

        const total = data.totalUserRatings;

        commit("setUserRatingChartData", chartData);                            // Mutate the state 
        commit("setAverageRating", average);
        commit("setTotalRating", total);
        commit("setRatePercent", percent);
      }
      return response;
    },
    async loadUserRatingOrders( {commit}, payload ){
      // Validate payload before calling api
      if (payload.merchantId == null || payload.merchantId == 0
        || !isValidDate(payload.startDate) || !isValidDate(payload.endDate)) {
        return;
      }

      payload.pageNumber -= 1;

      const response = await getUserRatingOrderWithinDateRangeApi(payload);

      if (isResponseOK(response)) {
        let data = response.data;                                               // To fill the rating array with total 5 rates.
        
        const ratings = {
          totalRatings: data.totalElements,
          ratings: data.content
        };
        
        // Mutate the state 
        commit("setUserRatingOrders", ratings);
      }
      return response;
    }
  },
  
};
