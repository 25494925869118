
<template>
  <div>Successfully subscribed for automated post generation service.</div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import HttpStatus from "http-status-codes";

export default {

  data() {
    return {
      session_id:this.$route.query.session_id,
      sessionData: null
    };
  },
  created() {
    // Call method to fetch session data when the component is created
    this.getSessionData();
  },
  methods: {
      ...mapActions("merchant_profile", [
          "loadSessionData","updateStripeCustomerId","uploadProfileServer"
      ]),
    async getSessionData() {
      const sessionId = this.$route.query.session_id; // Replace with your session ID
       try {
         const response = await this.loadSessionData( sessionId );
         this.sessionData=response.data;
         this.updateStripeCustomerId(this.sessionData);

         this.uploadProfileServer()
           .then(reponse => {
             // console.log("uploadProfile1", status);
             if (reponse.status !== HttpStatus.OK) {
               throw new Error("Cannot upload profile");
             }
             console.log(response);
           })
           .catch(error => {
             this.showErrorNotify();
           })
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    }
  }
};
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>
