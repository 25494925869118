/**
 * It contains common const structure in the app.
 */

/**
 * {string[]} Day of week in long terms.
 */
export const DAY_OF_WEEK_LONG = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY"
]

/**
 * {string[]} Day of week in short terms.
 */
export const DAY_OF_WEEK_SHORT = [
  "SUN", "MON", "TUE",
  "WED", "THU", "FRI", "SAT"
]

/**
 * {Object} To conver day of week from short to long terms.
 */
export const DAY_SHORT_TO_LONG = {
  SUN: "SUNDAY",
  MON: "MONDAY",
  TUE: "TUESDAY",
  WED: "WEDNESDAY",
  THU: "THURSDAY",
  FRI: "FRIDAY",
  SAT: "SATURDAY",
}

/**
 * {Object} To conver day of week from long to short terms.
 */
export const DAY_LONG_TO_SHORT = {
  SUNDAY: "SUN",
  MONDAY: "MON",
  TUESDAY: "TUE",
  WEDNESDAY: "WED",
  THURSDAY: "THU",
  FRIDAY: "FRI",
  SATURDAY: "SAT",
}

export const TIME = {
  SECOND: "SECOND",
  MINUTE: "MINUTE",
  HOUR: "HOUR",
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH"
}


/**
 * Enum value of the status in general as string.
 * It is being used in
 * Product/Addon/ProductGroup/AddonGroup/Store/DevliveryRule.
 */
export const ENUM_STATUS_STRING = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
  REDEEMED: 'REDEEMED',
};

/**
 * Enum value of the status in general as value.
 * It is being used in
 * Product/Addon/ProductGroup/AddonGroup/Store/DevliveryRule.
 * true, false value in ACTIVE and INACTIVE is for the toggle button.
 */
export const ENUM_STATUS_VALUE = {
  ACTIVE: true,   // 1
  INACTIVE: false,  // 0
  DELETED: -1,
  REDEEMED: 2,
};

/**
 * Map status (string) to (value)
 * Product/Addon/ProductGroup/AddonGroup/Store/DevliveryRule.
 */
export const MAP_STATUS_STRING_TO_VALUE = new Map([
  [ENUM_STATUS_STRING.ACTIVE, ENUM_STATUS_VALUE.ACTIVE],
  [ENUM_STATUS_STRING.INACTIVE, ENUM_STATUS_VALUE.INACTIVE],
  [ENUM_STATUS_STRING.DELETED, ENUM_STATUS_VALUE.DELETED],
  [ENUM_STATUS_STRING.REDEEMED, ENUM_STATUS_VALUE.REDEEMED],
]);


// Toronto
// Refer to MODEL_LOCATION.
export const DEFAULT_LOCATION = {
  x: -79.347015,
  y: 43.651070,
  coordinates: [
    -79.347015,
    43.651070
  ],
  type: "Point"
};

// TODO : may need to be in promotion model?
// Path map for the routing.
export const PATH_MAP_PROMOTIONS = {
  PUNCH_CARD: "punch-card",
  REWARD_ITEM: "reward-item",
  POINT_PERK: "point-perk",
  DEAL: "deal", // TODO remove or add
}

export const NO_AVATAR = "/assets/img/placeholder_avatar.png";

// View mode for the Dashboards.
// label is used to get text value from locale file. -> dashboard.view-mode.{label}
export const VIEW_MODES =[
    {value: "DAILY", label: "daily"},
  {value: "WEEKLY", label: "weekly"},
  {value: "MONTHLY", label: "monthly"}
]

export const CHART_VIEW_MODE = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY"
}

export const GROUP_OTHERS = 'group-others'

export const PROFILE_DISPLAY_NAMES = {
  linkedin: "LinkedIn",
  youtube: "YouTube",
  facebook: "Facebook",
  instagram: "Instagram",
  pinterest:"Pinterest"
}
