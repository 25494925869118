var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "align-self-center pl-1 pr-4 cls-list-right-reward" },
    [
      _c(
        "b-row",
        { staticClass: "align-self-center", attrs: { "align-h": "end" } },
        [
          _c(
            "b-col",
            [
              _vm.config.points
                ? _c("b-row", { staticClass: "align-self-center pr-4 mt-4" }, [
                    _c("h2", { staticClass: "cls-text-right" }, [
                      _vm._v(_vm._s(_vm.config.points.requiredPoints) + " pt"),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "b-row",
                { staticClass: "align-self-center pr-2 mt-4" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "javascript:void(0);",
                        id: `${_vm.config.name}-toggle-btn-status`,
                      },
                    },
                    [
                      _vm.config.toggleBtn
                        ? _c("toggle-button-status", {
                            attrs: {
                              name: _vm.config.name,
                              status: _vm.config.toggleBtn.status,
                              disabled: _vm.config.toggleBtn.disabled,
                            },
                            on: {
                              "on-toggle-change": _vm.config.toggleBtn.change,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.config.toggleBtn.disabled
                    ? _c("b-tooltip", {
                        attrs: {
                          target: `${_vm.config.name}-toggle-btn-status`,
                          placement: "bottom",
                          title: _vm.$t("promotions.status-disabled-tooltip"),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "b-row",
                { staticClass: "align-self-center pr-2 mt-4" },
                [
                  _vm.config.imgBtnDuplicate
                    ? _c("ImageButtonDuplicate", {
                        staticClass: "ml-2",
                        attrs: { id: _vm.config.id, name: _vm.config.name },
                        on: { click: _vm.config.imgBtnDuplicate.click },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.imgBtnEdit
                    ? _c("ImageButtonEdit", {
                        attrs: { id: _vm.config.id, name: _vm.config.name },
                        on: { click: _vm.config.imgBtnEdit.click },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.imgBtnDelete
                    ? _c("ImageButtonDelete", {
                        attrs: { id: _vm.config.id, name: _vm.config.name },
                        on: { click: _vm.config.imgBtnDelete.click },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        {
          staticClass: "align-self-center m-0 p-0",
          attrs: { "align-h": "end" },
        },
        [
          _c("b-col"),
          _vm._v(" "),
          _c("b-col", { staticClass: "p-0", attrs: { cols: "7" } }, [
            _c("p", { staticClass: "text-muted text-small text-right" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("general.expires") +
                      (_vm.config.expireDate
                        ? _vm.formatDate(_vm.config.expireDate)
                        : "N/A")
                  ) +
                  "\n      "
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }