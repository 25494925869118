/**
 * It contains backend CRUD Apis for Addons.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// 3rd party libraries.
import _ from "lodash";

// Modules in the application.
import axios                                from "@/api/axios/axios_core_deal.js";
import { uploadImagesApi, updateImagesApi } from "@/api/general.js";
import { serializeAddon }                   from "@/models/products/addon.js";

// URLs for backend
const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;
const IMAGE_UPLOAD_URL = BE_URLS.BACKEND_IMAGE_UPLOAD_URL;

/**
 * Get URL for Addons.
 * @param {string} [id] ID of an Addon object.
 * @return {string} URL of backend for Addons
 */
function getUrl(id) {
  return `${HOST}/addons/${id ? id : ""}`;
}

/**
 * Get Addons.
 * @return {Promise} Result of GET for Addons.
 */
export async function getAddonsApi() {
  return await axios.get(getUrl());
}

/**
 * Get an Addon by Id.
 * @param {string} id ID of an Addon object.
 * @return {Promise} Result of GET for an Addon by Id.
 */
export async function getOneAddonApi(id) {
  return await axios.get(getUrl(id));
}

/**
 * Add an Addon.
 * @param {Object} data Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
 * @return {Promise} Result of POST for an Addon.
 */
export async function addAddonApi(data) {
  return await axios.post(getUrl(), serializeAddon(data));
}


/**
 * Add an Addon with images.
 *
 * It adds an Addon with images.
 * If imageObject is not provided(null or undefined), it adds an Addon without images.
 *
 * @param {Object}   data           Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
 * @param {Object[]} [imageObjects] Image objects: [{files, preview}]
 * @return {Promise} Result of POST for an Addon.
 */
export async function addAddonWithImagesApi(data, imageObjects) {
  // let toSend = { ...serializeAddon(data) };
  let toSend = data;

  if (!Array.isArray(imageObjects) || (imageObjects.length <= 0)) {
    // Add(Upload) the Addon without images
    return addAddonApi(toSend);

  } else {
    // Add(Upload) the Addon with images
    // Convert image arrary
    // [{file, preview}] -> [file]
    let imageFiles = _.compact(imageObjects.map((image) => { return image.file }));

    // Upload images
    toSend.changedImages = await uploadImagesApi(IMAGE_UPLOAD_URL, imageFiles);
    // console.log(toSend);
    return addAddonApi(toSend);
  }
}

/**
 * Update an Addon.
 * @param {Object} data Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
 * @return {Promise} Result of PUT for an Addon.
 */
export async function updateAddonApi(data) {
  return await axios.put(getUrl(data.id), serializeAddon(data));
}

/**
 * Update an Addon with images.
 *
 * It updates an Addon with images.
 * If imageObject is not provided(null or undefined), it updates Addon without images.
 *
 * @param {Object}   data           Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
 * @param {Object[]} [imageObjects] Image objects: [{files, preview}]
 * @return {Promise} Result of PUT for an Addon.
 * @return {null}    If there is an error - TODO
 */
export async function updateAddonWithImagesApi(data, imageObjects) {
  // let toSend = { ...serializeAddon(data) };
  let toSend = data;

  // console.log('updateAddonWithImagesApi-in', imageObjects);
  if (!Array.isArray(imageObjects)) {
    // Update(Upload) the Addon without images
    return updateAddonApi(toSend);

  } else {
    // Update(Upload) the Addon with images

    // Update images : changedImages, deletedImages.
    await updateImagesApi(IMAGE_UPLOAD_URL, data, imageObjects);

    // console.log('updateAddonWithImagesApi-excute', data);
    return updateAddonApi(toSend);
  }
}

/**
 * Delete an Addon by Id (Soft).
 * @param {string} id ID of an Addon object.
 * @return {Promise} Result of DELETE for deleting an Addon(Soft).
 */
export async function deleteAddonApi(id) {
  return await axios.delete(getUrl(id));
}
