// Select Stock Limit component.
// Author : Huen Oh (heons921@gmail.com)
// @param: {number} data      Limited stock value(limitedStock).
// @param: {bool}   value     Value for the validation. v-model.
// @param: {bool}   disabled  Disabled property of the toogle button.

<template>
  <div>
    <!-- Stock limit -->
    <b-form-group>
      <b-row align-v="center">
        <!-- Toggle button for the check -->
        <b-colxx xxs="2">
          <toggle-button-check
            name="stock-limit"
            v-model="limitedStock"
            :disabled="disabled"
            @input="onChangeInput"/>
        </b-colxx>
        <!-- Title -->
        <b-colxx xxs="3">
          <h6>{{`${$t('promotions.limits-stock-title')}`}}</h6>
        </b-colxx>
        <!-- Input -->
        <b-colxx>
          <b-form-input
            data-cy="promotion-stock-limit-input-num"
            class="mt-2"
            type="number"
            v-if="limitedStock"
            v-model="limitedStockValue"
            :state="!$v.limitedStockValue.$invalid"
            @change="() => {this.limitedStockValue = this.limitedStockValue < 1 ? 1 : this.limitedStockValue}"
            :disabled="disabled"
            v-bind:class="{ 'mb-5' : $v.limitedStockValue.$invalid}"
          >
          </b-form-input>
          <b-form-invalid-feedback
          class="invalid-feedback-space-md" >
            {{ $t('general.no-points-range')}}
          </b-form-invalid-feedback>
        </b-colxx >
        <!-- Description -->
        <b-colxx xxs="12">
          <p class="text-muted text-small mt-3">
            {{$t('promotions.limits-stock-description')}}
          </p>
        </b-colxx>
      </b-row>
    </b-form-group>
  </div>
</template>


<script>
import ToggleButtonCheck    from '@/components/Forms/ToggleButtonCheck'

// Validation
import { validationMixin } from "vuelidate";
const { required, numeric, between, sameAs} = require("vuelidate/lib/validators");

const IS_TRUE = true;

export default {
  components: { ToggleButtonCheck },
  props:{
    // {bool} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    data: {  // {number} Limited stock value(limitedStock).
      type: Number,
      default: null
    },
    value: {  // {bool} Value for the validation. v-model.
      type: Boolean,
      default: true
    }
  },
  
  // Validation
  mixins: [validationMixin],
  validations: {
    limitedStockValue: {
      required,
      numeric,
      between: between(1, 100000)
    },
  },
  data(){
    return {
      limitedStockValue: 1, // {number} Limited stock value.
      limitedStock: false,  // {bool} Stock limit : true/false.
    }    
  },
  created: function () {
  },
  watch: {
    /**
     * Watch to check changes in data.
     */
    data: {
      deep: true,
      handler() {
        // Set stock limit.
        this.limitedStockValue = this.data ? this.data : 1;
        this.limitedStock = this.data != null;
      }
    },
  },
  computed: {
  },
  methods:{
    /**
     * Get limited stock value
     */
    getLimitedStock() {
      return this.limitedStock ? this.limitedStockValue : null;
    },
    /**
     * On change inputs.
     * It updates validation value for the componet.
     */
    onChangeInput() {
      if(this.limitedStock) {
        this.$emit('input', !this.$v.limitedStockValue.$invalid);
      } else {
        this.$emit('input', true);
      }
    },
  },
  mounted(){

    // If component is mounted after watch property is updated. Update the data values manually.

    // Set stock limit.
    this.limitedStockValue = this.data ? this.data : 1;
    this.limitedStock = this.data != null;

  }
}
</script>
