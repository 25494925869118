/**
 * It contains store module for ProductCategories.
 */

import {
  getProductCategoriesApi,
  addProductCategoryApi,
  updateProductCategoryApi,
  deleteProductCategoryApi,
} from "@/api/product/product_categories.js";

import { parseProductCategory }   from "@/models/products/product_category.js";
import { sortByName }             from "@/utils/sort.js"
import { getListForDropDownMenu, isResponseOK, isResponseCREATED } from "@/utils/general.js"

export default {
  namespaced: true,
  state: {
    productCategories: [],           // List of ProductCategories: Refer to PRODUCT_CATEGORY_MODEL.
    selectableProductCategories: [], // List of selectable ProductCategories: {value:name, label:name, text:name}
  },
  mutations: {
    /**
     * Reset all the state.
     * @param {Object}  state Local State object.
     */
    resetAll(state) {
      state.productCategories = [];
      state.selectableProductCategories = [];
    },
    /**
     * Set all ProductCategories in the state.
     * @param {Object} state      Local State object.
     * @param {Object} categories List of ProductCategory objects: Refer to PRODUCT_CATEGORY_MODEL.
     */
    setAllProductCategories(state, categories) {
      state.productCategories = categories;
    },
    /**
     * Delete a ProductCategory in the state.
     * @param {Object} state Local State object.
     * @param {number} index Index of a ProductCategory in the list.
     */
    deleteOneProductCategory(state, index) {
      state.productCategories.splice(index, 1);
    },
    /**
     * Add a ProductCategory in the state.
     * @param {Object} state    Local State object.
     * @param {Object} category ProductCategory object: Refer to PRODUCT_CATEGORY_MODEL.
     */
    addOneProductCategory(state, category) {
      state.productCategories.push(category);
    },
    /**
     * Upate a ProductCategory in the state.
     * @param {Object} state    Local State object.
     * @param {Object} category ProductCategory object: Refer to PRODUCT_CATEGORY_MODEL.
     * @param {number} index    Index of a ProductCategory in the list.
     */
    updateOneProductCategory(state, { category, index }) {
      state.productCategories[index] = {
        ...state.productCategories[index],
        ...category
      };
    },
    /**
     * Set selectable ProductCategories in the state.
     * @param {Object} state Local State object.
     */
    setSelectableProductCategories(state) {
      state.selectableProductCategories = getListForDropDownMenu(state.productCategories, false);
    },
  },
  getters: {
    /**
     * Get ProductCategories in the state.
     */
    productCategories: (state) => {
      return state.productCategories;
    },
    /**
     * Get selectable ProductCategories in the state.
     */
    selectableProductCategories: (state) => {
      return state.selectableProductCategories;
    },
    /**
     * Get a ProductCategory by Id.
     * @param {string} id Id of a ProductCategory.
     */
    findProductCategoryById: (state) => (id) => {
      return state.productCategories.find(
        category => category.id === id
      );
    },
    /**
     * Get an index of a ProductCategory by Id.
     * @param {string} id Id of a ProductCategory.
     */
    findProductCategoryIndexById: (state) => (id) => {
      return state.productCategories.findIndex(
        category => category.id === id
      );
    },
  },
  actions: {
    /**
     * Load ProductCategories.
     * It loads ProductCategories from the sever and set selectable ProductCategories.
     */
    async loadProductCategories({ commit }) {
      const response = await getProductCategoriesApi();
      if (isResponseOK(response)) {
        const productCategories = parseProductCategory(response.data);
        commit("setAllProductCategories", productCategories.sort(sortByName));
        commit("setSelectableProductCategories");
      } else {
        console.log("[Error]loadProductCategories:");
      }
      return response;
    },
    /**
     * Update a ProductCategory.
     * It updates a ProductCategory and selectable ProductCategories accordingly.
     * @param {Object} category ProductCategory object: Refer to PRODUCT_CATEGORY_MODEL.
     */
    async updateOneProductCategory({ commit, getters }, category) {
      const response = await updateProductCategoryApi(category);
      if (isResponseOK(response)) {
        commit(
          "updateOneProductCategory",
          {
            category: parseProductCategory(response.data),
            index: getters.findProductCategoryIndexById(category.id)
          }
        );
        commit("setSelectableProductCategories");
      } else {
        console.log("[Error]updateOneProductCategory:");
      }
      return response;
    },
    /**
     * Delete a ProductCategory.
     * It deletes a ProductCategory and selectable ProductCategories accordingly.
     * @param {string} id Id of a ProductCategory.
     */
    async deleteOneProductCategory({ commit, getters }, id) {
      const response = await deleteProductCategoryApi(id);
      if (isResponseOK(response)) {
        commit("deleteOneProductCategory", getters.findProductCategoryIndexById(id));
        commit("setSelectableProductCategories");
      } else {
        console.log("[Error]deleteOneProductCategory:");
      }
      return response;
    },
    /**
     * Add a ProductCategory.
     * It adds a ProductCategory and selectable ProductCategories accordingly.
     * @param {Object} category ProductCategory object: Refer to PRODUCT_CATEGORY_MODEL.
     */
    async addOneProductCategory({ commit }, category) {
      const response = await addProductCategoryApi(category);
      if (isResponseCREATED(response)) {
        commit("addOneProductCategory", parseProductCategory(response.data));
        commit("setSelectableProductCategories");
      } else {
        console.log("[Error]addOneProductCategory:");
      }
      return response;
    },
  }
};
