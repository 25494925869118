var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pl-0 align-self-center pr-4" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "b-row",
                { staticClass: "align-self-center pr-4 mt-4" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "javascript:void(0);",
                        id: `${_vm.config.name}-toggle-btn-status`,
                      },
                    },
                    [
                      _vm.config.toggleBtn
                        ? _c("toggle-button-status", {
                            attrs: {
                              name: _vm.config.name,
                              status: _vm.config.toggleBtn.status,
                              disabled: _vm.config.toggleBtn.disabled,
                            },
                            on: {
                              "on-toggle-change": _vm.config.toggleBtn.change,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.config.toggleBtn.disabled
                    ? _c("b-tooltip", {
                        attrs: {
                          target: `${_vm.config.name}-toggle-btn-status`,
                          placement: "bottom",
                          title: _vm.config.toggleBtn.disabledReason,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            [
              _c(
                "b-row",
                { staticClass: "align-self-center pr-2 mt-4" },
                [
                  _vm.config.imgBtnSchedule
                    ? _c("ImageButtonSchedule", {
                        attrs: { id: _vm.config.id, name: _vm.config.name },
                        on: { click: _vm.config.imgBtnSchedule.click },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.imgBtnShow
                    ? _c("ImageButtonShow", {
                        attrs: { id: _vm.config.id, name: _vm.config.name },
                        on: { click: _vm.config.imgBtnShow.click },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.imgBtnDuplicate
                    ? _c("ImageButtonDuplicate", {
                        attrs: { id: _vm.config.id, name: _vm.config.name },
                        on: { click: _vm.config.imgBtnDuplicate.click },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.imgBtnAdd
                    ? _c("ImageButtonAdd", {
                        attrs: { id: _vm.config.id, name: _vm.config.name },
                        on: { click: _vm.config.imgBtnAdd.click },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.imgBtnEdit
                    ? _c("ImageButtonEdit", {
                        attrs: { id: _vm.config.id, name: _vm.config.name },
                        on: { click: _vm.config.imgBtnEdit.click },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.imgBtnDelete
                    ? _c("ImageButtonDelete", {
                        attrs: { id: _vm.config.id, name: _vm.config.name },
                        on: { click: _vm.config.imgBtnDelete.click },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.checkBox
                    ? _c("b-form-checkbox", {
                        staticClass: "itemCheck ml-3",
                        attrs: {
                          "data-cy": `${_vm.config.name}-checkbox`,
                          checked: _vm.config.checkBox.checked,
                          id: "item_checkbox",
                          disabled: "",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.config.lastUpdate
                ? _c("b-row", { staticClass: "align-self-center mb-0" }, [
                    _c(
                      "p",
                      { staticClass: "mt-3 text-muted text-small mb-0" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("general.last-updated") +
                              _vm.formatDate(_vm.config.lastUpdate.update)
                          )
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }