/**
 * It contains backend CRUD Apis for ProductGroup.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// Modules in the application.
import axios                      from "@/api/axios/axios_core_deal.js";
import { uploadOneImageApi }      from "@/api/general.js";
import { serializeProductGroup }  from "@/models/products/product_group.js";

// URLs for backend
const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;
const IMAGE_UPLOAD_URL = BE_URLS.BACKEND_IMAGE_UPLOAD_URL;

/**
 * Get URL for ProductGroups.
 * @param {string} [id] ID of a ProductGroup object.
 * @return {string} URL of backend for ProductGroups
 */
function getUrl(id) {
  return `${HOST}/product-groups/${id ? id : ""}`;
}

/**
 * Get URL for Product update in a ProductGroup.
 * @param {string} [id] ID of a ProductGroup object.
 * @return {string} URL of backend for Product update in a ProductGroup.
 */
function getUrlForProductUpdate(id) {
  return `${HOST}/product-groups/${id ? id : ""}/products`;
}

/**
 * Get URL for updating Product Group Rank.
 * @return {string} URL of backend for updating Product Group rank.
 */
function getUrlForUpdateProductGroupRank() {
  return `${HOST}/product-groups/rank`;
}



/**
 * Get ProductGroups.
 * @return {Promise} Result of GET for ProductGroups.
 */
export async function getProductGroupsApi() {
  return await axios.get(getUrl());
}

/**
 * Get a ProductGroup by Id.
 * @param {string} id ID of a ProductGroup object.
 * @return {Promise} Result of GET for a ProductGroup by Id.
 */
export async function getOneProductGroupApi(id) {
  return await axios.get(getUrl(id));
}

/**
 * Add a ProductGroup.
 * @param {Object} data ProductGroup object: Refer to PRODUCT_GROUP_MODEL and EMPTY_PRODUCT_GROUP.
 * @return {Promise} Result of POST for a ProductGroup.
 */
export async function addProductGroupApi(data) {
  return await axios.post(getUrl(), serializeProductGroup(data));
}

/**
 * Add a ProductGroup with an image.
 * 
 * It adds a ProductGroup with an image.
 * If imageObject is not provided(null or undefined), it adds a ProductGroup without an image.
 * 
 * @param {Object} data           ProductGroup object: Refer to PRODUCT_GROUP_MODEL and EMPTY_PRODUCT_GROUP.
 * @param {Object} [imageObject]  Image object: {files, preview}
 * @return {Promise} Result of POST for a ProductGroup.
 * @return {null}    If there is a error - TODO
 */
export async function addProductGroupWithImageApi(data, imageObject) {
  // let toSend = { ...serializeStore(data) };
  let toSend = data;

  if (!imageObject || !imageObject.file) {
    // Add(Upload) a ProductGroup without an image
    return addProductGroupApi(toSend);

  } else {
    // Add(Upload) a ProductGroup with an image

    // Upload images
    const imageFromServer = await uploadOneImageApi(IMAGE_UPLOAD_URL, imageObject.file);
    // console.log(imageFromServer);

    // Add a ProductGroup When uploading an image is successful
    if (imageFromServer != null) {
      // Add the image to the toSend data
      toSend.changedImages = [imageFromServer];
      // console.log(toSend);
      return addProductGroupApi(toSend);
    } else {
      return null;
    }
  }
}

/**
 * Update a ProductGroup rank.
 * @param {Object} data - including to be updated product group id and its rank.
 * @return {Promise} Result of PUT for a list of updated ProductGroup.
 */
export async function updateProductGroupRankApi(data) {
  return await axios.put(getUrlForUpdateProductGroupRank(), data);
}

/**
 * Update a ProductGroup.
 * @param {Object} data ProductGroup object: Refer to PRODUCT_GROUP_MODEL and EMPTY_PRODUCT_GROUP.
 * @return {Promise} Result of PUT for a ProductGroup.
 */
export async function updateProductGroupApi(data) {
  return await axios.put(getUrl(data.id), serializeProductGroup(data));
}

/**
 * Update a ProductGroup with an image.
 *
 * It updates a ProductGroup with an image.
 * If imageObject is undefined, it updates a ProductGroup without an image.
 * If imageObject is null, it updates a ProductGroup by deleting an image.
 *
 * @param {Object} data           ProductGroup object: Refer to PRODUCT_GROUP_MODEL and EMPTY_PRODUCT_GROUP.
 * @param {Object} [imageObject]  Image object: {files, preview}
 * @return {Promise} Result of PUT for a ProductGroup.
 * @return {null}    If there is a error - TODO
 */
export async function updateProductGroupWithImageApi(data, imageObject) {
  // console.log("imageObject", imageObject);
  // let toSend = { ...serializeStore(data) };
  let toSend = data;

  if(undefined === imageObject) {
    // Update a ProductGroup without an image
    return updateProductGroupApi(toSend);

  } else if (null === imageObject) {
    // Update a ProductGroup deleting an image
    // Update deletedImages
    if (toSend.images) {
      toSend.deletedImages = toSend.images;
    }
    return updateProductGroupApi(toSend);

  } else {
    // Update a ProductGroup with adding/changing an image
    if(imageObject.file) {
      // Upload images
      const imageFromServer = await uploadOneImageApi(IMAGE_UPLOAD_URL, imageObject.file);
      // console.log(imagesFromServer);

      if (imageFromServer != null) {
        // Add the image to the toSend data
        toSend.changedImages = [imageFromServer];
        // Update deletedImages
        if (toSend.images) {
          toSend.deletedImages = toSend.images;
        }
        // console.log(toSend);
        return updateProductGroupApi(toSend);
      } else {
        return null;
      }

    } else {
      // Update a ProductGroup without an image
      return updateProductGroupApi(toSend);
    } 
  }

}

/**
 * Delete a ProductGroup.
 * @param {Object} id ID of a ProductGroup object.
 * @return {Promise} Result of DELETE for a ProductGroup.
 */
export async function deleteProductGroupApi(id) {
  return await axios.delete(getUrl(id));
}


/**
 * Add Products to a ProductGroup.
 * @param {string}   id   ID of a ProductGroup object.
 * @param {string[]} data Array of Products' Id: [product.id, product.id, ...]
 * @return {Promise} Result of PUT to add Products to a ProductGroup.
 */
export async function addProductsInGroupApi(id, data) {
  const products = {
    productList: data
  };
  return await axios.put(getUrlForProductUpdate(id), products);
}

/**
 * Delete Products from a ProductGroup.
 * @param {string}   id   ID of a ProductGroup object.
 * @param {string[]} data Array of Products' Id: [product.id, product.id, ...]
 * @return {Promise} Result of DELETE to delete Products from a ProductGroup.
 */
export async function deleteProductsInGroupApi(id, data) {
  const products = {
    productList: data
  };
  // return await axios.delete(getUrlForProductUpdate(id), products);
  return await axios({
    method: "delete",
    url: getUrlForProductUpdate(id),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    },
    data: products
  });
}
