var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      attrs: { "data-cy": `${_vm.name}-btn-edit`, href: "javascript:void(0);" },
    },
    [
      _c("b-img", {
        staticClass: "no-checkbox cls-img-btn ml-2",
        attrs: { id: "edit-" + _vm.id, src: "/assets/icons/pencil.svg" },
        on: { click: _vm.onClick },
      }),
      _vm._v(" "),
      _c("b-tooltip", {
        attrs: {
          target: "edit-" + _vm.id,
          placement: "bottom",
          title: _vm.$t("tool-tips.edit"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }