var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalProductGroup",
      attrs: { id: "modalProductGroup", size: "lg" },
      on: {
        hide: function ($event) {
          return _vm.onCencel()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("HeaderDialogProduct", {
                attrs: {
                  title: "objects.product-group",
                  name: "dlg-product-group",
                  status: _vm.data.status,
                  disabled:
                    _vm.data.showOnly || _vm.productsInGroup.length <= 0,
                  actionType: _vm.actionType,
                },
                on: {
                  clickSchedule: _vm.openDialogSchedule,
                  changeStatus: _vm.updateStatus,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-form",
        [
          _c("dialog-alert", {
            attrs: {
              dialog: _vm.dialogs.alert,
              description: _vm.$t("alert.no-change"),
              actionType: _vm.actionType,
              buttonSubmitText: _vm.$t("buttons.ok"),
            },
            on: {
              "dialog-close": _vm.closeDialogAlert,
              "dialog-submit": _vm.onSubmitDialogAlert,
            },
          }),
          _vm._v(" "),
          _c("dialog-date-time", {
            attrs: {
              disabled: _vm.data.showOnly,
              data: _vm.data,
              dialog: _vm.dialogs.schedule,
            },
            on: {
              "dialog-close": _vm.closeDialogSchedule,
              "dialog-submit": _vm.onSubmitDialogSchedule,
            },
          }),
          _vm._v(" "),
          _c("dialog-product", {
            attrs: { dialog: _vm.dialogs.product, data: _vm.currentProduct },
            on: { "dialog-close": _vm.closeDialogProduct },
          }),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-colxx",
                { attrs: { sm: "6" } },
                [
                  _c("EditNameDescription", {
                    attrs: {
                      name: "dlg-product-group",
                      data: _vm.data,
                      label: "product-group",
                      disabled: _vm.data.showOnly,
                      isFirstLoad: _vm.isFirstLoad,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-colxx",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("h6", [
                        _vm._v(_vm._s(_vm.$t("product.choose-image"))),
                      ]),
                      _vm._v(" "),
                      _c("ImagesSelection", {
                        key: "selImage",
                        ref: "selImage",
                        attrs: {
                          name: "dlg-product-group",
                          images: _vm.data.images,
                          single: true,
                          disabled: _vm.data.showOnly,
                        },
                        model: {
                          value: _vm.isChanged,
                          callback: function ($$v) {
                            _vm.isChanged = $$v
                          },
                          expression: "isChanged",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mt-2", attrs: { "align-v": "end" } },
            [
              _c("BaseSelectItem", {
                attrs: {
                  colSize: _vm.sizeColMain,
                  disabled: _vm.data.showOnly,
                  title: _vm.$t("product-group.add-product-to-group"),
                  cyPrefix: "dlg-product-group",
                  cySuffix: "product",
                  noOptionsMessage: _vm.$t("validations.no-product-available"),
                  options: _vm.currentSelProductOptions,
                },
                on: { input: _vm.addProductToGroup },
              }),
              _vm._v(" "),
              _c("b-colxx", { attrs: { sm: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass: "search-sm d-inline-block float-md-right m-3",
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        "data-cy": "dlg-product-group-input-search",
                        placeholder: _vm.$t("menu.search"),
                      },
                      model: {
                        value: _vm.searchKeyword,
                        callback: function ($$v) {
                          _vm.searchKeyword = $$v
                        },
                        expression: "searchKeyword",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("TableListProducts", {
            attrs: {
              cyPrefix: "dlg-product-group",
              cySuffix: "products",
              items: _vm.searchedProductsInGroup,
              disabled: _vm.data.showOnly,
              selectableGroups: _vm.selectableProductGroups,
            },
            on: {
              changeStatus: _vm.updateOneProduct,
              editItem: _vm.onEditProduct,
              deleteItem: _vm.onDeleteProduct,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c("FooterDialogProduct", {
            attrs: {
              name: "dlg-product-group",
              disabled: _vm.$v.data.$invalid && !_vm.isFirstLoad,
              actionType: _vm.actionType,
            },
            on: { "click-cancel": _vm.onCencel, "click-submit": _vm.onSubmit },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }