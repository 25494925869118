var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalAlert",
      attrs: { id: "modalAlert", size: "md", "hide-header": "" },
      on: {
        hide: function ($event) {
          return _vm.onCencel()
        },
      },
    },
    [
      _c("h2", { staticClass: "text-center mb-5" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _vm._v(" "),
      _vm.showStoreFilter
        ? _c("DropdownFilterStore", {
            staticClass: "d-inline-block mb-4",
            attrs: { label: _vm.selectedStore.label, isAll: false },
            on: { click: _vm.onSelectedStoreChange },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("label", { staticClass: "form-group has-float-label mb-4" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          staticClass: "form-control",
          attrs: { "data-cy": `dlg-auth-input-password`, type: "password" },
          domProps: { value: _vm.password },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.onSubmit()
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.password = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.$t("user.password")))]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              staticClass: "mr-1",
              attrs: { "data-cy": `dlg-auth-btn-ok`, variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onSubmit()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.ok")))]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: {
                "data-cy": `dlg-auth-btn-cancel`,
                variant: "outline-secondary",
              },
              on: {
                click: function ($event) {
                  return _vm.onCencel()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }