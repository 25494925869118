var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("treeselect", {
        attrs: {
          "data-cy": `${_vm.name}-tree-select`,
          multiple: true,
          options: _vm.labeledGroupsWithProducts,
          "sort-value-by": "LEVEL",
          "show-count": true,
          "value-consists-of": "ALL",
          disabled: _vm.disabled,
          clearable: false,
        },
        on: {
          select: _vm.onSelect,
          deselect: _vm.onDeselect,
          input: _vm.onChangeGroupSelection,
        },
        scopedSlots: _vm._u([
          {
            key: "value-label",
            fn: function ({ node }) {
              return _c(
                "div",
                {
                  staticClass: "m-0 p-0",
                  class: {
                    "text-theme-2 font-weight-bold": _vm.isDeleted(node),
                  },
                  attrs: {
                    "data-cy": `${_vm.name}-tree-select-selected-item-${node.label}`,
                  },
                },
                [_vm._v(_vm._s(node.label))]
              )
            },
          },
          {
            key: "option-label",
            fn: function ({ node, shouldShowCount, count, labelClassName }) {
              return _c(
                "label",
                {
                  class: labelClassName,
                  attrs: {
                    "data-cy": `${_vm.name}-tree-select-selectable-item-${node.label}`,
                  },
                },
                [
                  _vm._v("\n        " + _vm._s(node.label) + "\n      "),
                  shouldShowCount
                    ? _c("span", { staticClass: "countClassName" }, [
                        _vm._v("(" + _vm._s(count) + ")"),
                      ])
                    : _vm._e(),
                ]
              )
            },
          },
        ]),
        model: {
          value: _vm.selectedProductIds,
          callback: function ($$v) {
            _vm.selectedProductIds = $$v
          },
          expression: "selectedProductIds",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }