var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.status == "success" || _vm.data.status == "pending"
    ? _c(
        "b-colxx",
        { staticClass: "mb-3", attrs: { xxs: "12" } },
        [
          _c(
            "b-card",
            {
              class: { "d-flex flex-row": true },
              attrs: { "data-cy": `${_vm.cyPrefix}`, "no-body": "" },
              on: {
                click: function ($event) {
                  return _vm.onClickEvent($event, _vm.data.id)
                },
              },
            },
            [
              _c("dialog-date-time", {
                attrs: {
                  disabled: _vm.data.showOnly,
                  data: _vm.data,
                  dialog: _vm.dialogs.schedule,
                },
                on: {
                  "dialog-close": _vm.closeDialogSchedule,
                  "dialog-submit": _vm.onSubmitDialogSchedule,
                },
              }),
              _vm._v(" "),
              _c("dialog-alert", {
                attrs: {
                  dialog: _vm.dialogs.alert,
                  description: _vm.$t("alert.delete"),
                  actionType: _vm.$t("buttons.delete"),
                  buttonSubmitText: _vm.$t("buttons.delete"),
                },
                on: {
                  "dialog-close": _vm.closeDialogAlert,
                  "dialog-submit": _vm.onSubmitDialogAlert,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pl-2 d-flex flex-grow-1 min-width-zero" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-body align-self-center flex-lg-row justify-content-between min-width-zero align-items-lg-center w-60",
                    },
                    [
                      _c(
                        "b-row",
                        [
                          Array.isArray(_vm.data.postIds) &&
                          _vm.data.postIds.length > 0
                            ? _c("BadgeListPlatform", {
                                attrs: {
                                  cyPrefix: `${_vm.cyPrefix}`,
                                  data: _vm.data.postIds,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "p",
                          {
                            staticClass: "mb-1 text-muted",
                            attrs: { "data-cy": `${_vm.cyPrefix}-description` },
                          },
                          [_vm._v(_vm._s(_vm.data.post))]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.data.scheduleDate
                        ? _c("b-row", [
                            _c(
                              "p",
                              {
                                staticClass: "mb-1 text-default text-small",
                                attrs: {
                                  "data-cy": `${_vm.cyPrefix}-scheduled`,
                                },
                              },
                              [
                                _vm._v(
                                  "Scheduled Time: " + _vm._s(_vm.formattedDate)
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.data.status
                        ? _c("b-row", [
                            _c(
                              "p",
                              {
                                staticClass: "mb-1 text-default text-small",
                                attrs: { "data-cy": `${_vm.cyPrefix}-status` },
                              },
                              [_vm._v("Status: " + _vm._s(_vm.data.status))]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isRewardItem
                    ? _c("ButtonBlockInListReward", {
                        attrs: {
                          config: {
                            id: _vm.data.id,
                            name: `${_vm.cyPrefix}`,
                            points: {
                              requiredPoints: _vm.data.requiredPoints,
                            },
                            toggleBtn: {
                              status: _vm.data.status,
                              disabled: !_vm.data.isValid,
                              change: function (payload) {
                                _vm.updateStatus(payload)
                              },
                            },
                            imgBtnDuplicate: {
                              click: function () {
                                _vm.onDuplicateItem()
                              },
                            },
                            imgBtnEdit: {
                              click: function () {
                                _vm.onEditItem()
                              },
                            },
                            imgBtnDelete: {
                              click: function () {
                                _vm.openDialogAlert()
                              },
                            },
                            expireDate: _vm.data.availableTo,
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }