var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-center" }, [
    _c(
      "svg",
      {
        staticClass: "main",
        attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 9 17" },
      },
      [
        _c("rect", { attrs: { x: "0.48", y: "0.5", width: "7", height: "1" } }),
        _vm._v(" "),
        _c("rect", { attrs: { x: "0.48", y: "7.5", width: "7", height: "1" } }),
        _vm._v(" "),
        _c("rect", {
          attrs: { x: "0.48", y: "15.5", width: "7", height: "1" },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "svg",
      {
        staticClass: "sub",
        attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 17" },
      },
      [
        _c("rect", {
          attrs: { x: "1.56", y: "0.5", width: "16", height: "1" },
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: { x: "1.56", y: "7.5", width: "16", height: "1" },
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: { x: "1.56", y: "15.5", width: "16", height: "1" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }