/**
 * It contains backend Apis for social media accounts.
 * @author: Aysegul Yalcinkaya
 */

// Constant.
import {BE_URLS} from "@/constants/config.js";

// Modules in the application.
import axios from "@/api/axios/axios_ayrshare";
import {BIconBadge4k} from "bootstrap-vue";
import HttpStatus from "http-status-codes";

// URLs for Backend
const HOST = BE_URLS.BACKEND_PAYMENT_SERVER_URL;

const CREATE_AYRSHARE_ACCOUNT = `https://app.ayrshare.com/api/profiles/profile`;
const GET_AYRSHARE_URL = `https://app.ayrshare.com/api/profiles/generateJWT`;
const GET_AYRSHARE_USER_INFO = `https://app.ayrshare.com/api/user`;
const DISCONNECT_SOCIAL_MEDIA_ACCOUNT = `https://app.ayrshare.com/api/profiles/social`;


/**
 * Send request to create merchant Ayrshare account to platform
 * @return {Promise} POST Promise.
 */
export function createAyrshareAccount(data) {
  console.log(data);
  //const header = {'Authorization': 'Bearer XGJH803-9XBM3W3-KW63E29-RDM1G1V'};

  let result = axios.request({
    url: CREATE_AYRSHARE_ACCOUNT,
    method: "POST",
    //headers: header,
    data:
      {title: data.merchantId.toString()}
  });
  console.log(result);
  return result;
}

  /**
   * Send request to get Ayrshare account page url
   */
  export function getAyrshareOnboardingLink(data) {
    console.log(data);
    //const header = {'Authorization': 'Bearer XGJH803-9XBM3W3-KW63E29-RDM1G1V'};

    let result = axios.request({
      url: GET_AYRSHARE_URL,
      method: "POST",
     // headers: header,
      data:
        {domain:'id-mineo',
          privateKey:'-----BEGIN RSA PRIVATE KEY-----\nMIIEogIBAAKCAQEAjE54WO4B1A663umKzH+sHe9t2P5OyE7PUKQt4P60VemFrosn\nWYj+xnUSIhKoF4eIiN7Z4lvm6XdSEOHxhpm1iJfhKXIgenIlgwXzzLbr3caJCJeO\nmWoFwgbFYi9bGJFlDoV4aTRRHpboRQbNY+qy+7ZGJQfUIF5caQtTb4nL84q+WEJF\nLIF2It2kwAO0gjsNQc05iGxLWC8G3mQhlHSiq6/Oz9eDhnBgbKqT0+MvfdMgE1OQ\nDOD4dyPkGPfxQ391S1c1m8TdWo/Ir74SCTTuFolE3pTJJ+pWH52PgxKGcuCCi0AN\nfrMb5Drzzbm0HwkK8ZRkjjAo7GK7X/NHxpopvwIDAQABAoIBABf3B0NZU+KeqXiN\nt3F6lTF/xel8doRsQajqghOeLbtG6diIWC5I4guPz4sWWLO8KKwINHsfa2+RO+k6\nToDJHGK6XnEv1zBS8ulQSPY8gEkfMiTPjkHSBC3B7lD4+bxWiUZTsd92Wiy5Ze70\nXHymh+/KkGyey7LDq1Ed6CBzDu0IEvE8h8/xgxY3MjrvGxTK3pN9BCb7WmabjB+B\nmteoxN06JQ4tNBDviM0JZ4cbw7JcJXQsX56D8Otu4eyW8vLvGM0O23a67VJ5fPck\n4oEWeuBYXe12eeUAhTLiW5uOAB9hTtvfup2YQ+NDHi8H6QjyZGLQ63FyQ15UwD9Z\nEFDvLTECgYEAxekRPuws/CkLC1Y8dJXGoLAT66X4RCKfcistTjCqqB/QtGq9R7b4\nW3CyChMHlw0QeQeRyHd7LYwok6aVYHzlQPBDoImRLi8ox544nWnP7OsUB22bD1y6\nx+F9Lg24ZnZbubcXPMwC1NfDZkkj/HXZjA58pi3PJn/uqvcYf9hggK0CgYEAtX0P\n+h6iza8f7vEWvk1gW/U3DGL9lC1UPa5H1SULU9Qdnx8dv/4KQJ1VJW+7viwQdV3F\nQrErzwx3jjTM9unGQcynWjb3iVt0jj/nY+24+1eRRYp9DnfXF5whPQ1lkSiITQhV\nfabeKlXmhxxvenFaAJMcilE/AcUL8wsDrdwIZZsCgYAqhCJHUTdsyGht/A2qwg5U\nsN7mrdYC1SlJvorSFFRlH8iXKaMWB/Gcuz4UOfhUc+MJDR49YaWdqFGDPrMyckJR\nzAvLLiJ5OHpbP9tLvdKERBAedGZuS254yDE7NolZwa183R9p2S32iY+ZprbLYonk\ns6wFiyvISo18Pu8Nb+9zVQKBgGxiDYKPfSZcaTOm4rAvQVZpcMCC9XDiSSa0x80w\nXD1ptW7rhDa+HZ1M2CaNPY9qfUG6CC215LW3ekaAnc7u+SkecOrdXKK47ThuTV9N\n/mSLfQQMmnZjdx5wbSEquPchP5TRX0y0tISP3CXgA2sZ+mfRcv/fWUNTS7mPYFkZ\nyCH1AoGAKYYLqrZCpiVxpQgrOfJtUfJ7uz6QKNYe8nXb/QnsQy73U/URJrq2AXAJ\nLCKfQs39zVqtc4c9f5PCA326OV+o/rVqx3W8fSe6/xFgJyOd4x0E3/WgWHhRRq/L\n4+Urv0d1rt5FBhceaQSWw8flt2Vh0AcdT9K0Zk4LnR/wTQ8WB2I=\n-----END RSA PRIVATE KEY-----\n',
          profileKey: data.profileKey,
        logout:true,
        expireIn:30}
    });
    console.log(result);
    return result;
  }

/**
 * Send request to get connected social media accounts
 */
export function getAyrshareUserInfo(data) {
  console.log(data);
  axios.defaults.headers.common['Profile-Key'] = data.profileKey
  const header = {'Authorization': 'Bearer XGJH803-9XBM3W3-KW63E29-RDM1G1V',
  'Profile-Key':data.profileKey};

  let result = axios.request({
    url: GET_AYRSHARE_USER_INFO,
    method: "GET",
   // headers: header
  });
  console.log(result);
  return result;
}

/**
* Send request to disconnect social media account
*/
export function disconnectSocialMediaAccount(key,platform) {

  let result = axios.request({
    url: DISCONNECT_SOCIAL_MEDIA_ACCOUNT,
    method: "DELETE",
    data:{
      platform: platform,
      profileKey: key
    }
  });
  console.log(result);
  return result;
}


