/**
 * It contains backend CRUD Apis for Promotions.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS } from "@/constants/config.js";

// 3rd party libraries.
import _ from "lodash";

// Modules in the application.
import axios                from "@/api/axios/axios_core_deal";
import { uploadImagesApi }  from "@/api/general.js";

// URLs for backend
const HOST            = BE_URLS.BACKEND_DEAL_SERVER_URL;
const PROMOTIONS_URL  = `${HOST}/promotions`;
const STATUS_URL      = `${PROMOTIONS_URL}/status`;
const PUNCHCARD_URL   = `${PROMOTIONS_URL}/punch-card`;
const POINTPERK_URL   = `${PROMOTIONS_URL}/point-perk`;
const REWARDITEM_URL  = `${PROMOTIONS_URL}/reward-item`;
const DEAL_URL        = `${PROMOTIONS_URL}/deal`;

// COMMON
/**
 * Get Promotions.
 * @return {Promise} Result of GET for Promotions.
 */
export function getPromotionsApi() {
  return axios.get(PROMOTIONS_URL,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}

/**
 * Get a Promotion by Id.
 * @param {string} id ID of a Promotion object.
 * @return {Promise} Result of GET for a Promotion by Id.
 */
export function getOnePromotionApi(id) {
  return axios.get(`${PROMOTIONS_URL}/${id}`,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}

/**
 * Delete a Promotion by Id.
 * @param {string} id ID of a Promotion object.
 * @return {Promise} Result of DELETE for a Promotion by Id.
 */
export function deletePromotionApi(id) {
  return axios.delete(`${PROMOTIONS_URL}/${id}`,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}

/**
 * Update status of a Promotion by Id.
 * @param {string} id     ID of a Promotion object.
 * @param {string} status Status of a Promotion object: Refer to ENUM_STATUS_STRING.
 * @return {Promise} Result of PUT for status of a Promotion by Id.
 */
export async function updatePromotionStatusApi(id, status) {
  return await axios.put(`${STATUS_URL}/${id}/${status}`);
}

/**
 * Update(Upload) images for Promotion.
 * @param {File[]} files  Image files.
 * @return {string[]} Urls of uploaded images.
 */
export async function uploadImages(files) {
  return await uploadImagesApi(BE_URLS.BACKEND_IMAGE_UPLOAD_URL, files);
}
/**
 * Update(Upload) images for Promotion and Promotion.data.
 * @param {Object} data   Promotion object. {images}.
 * @param {File[]} files  Image files.
 * @return {string[]} Urls of uploaded images.
 */
async function updateImagesInData(data, files) {
  // TODO : check with backend images is deleted if images === null, no matter what deletedImages is.
  //        It's temporary fix.
  if (Array.isArray(data.images)) {
    // It is to empty current image which is in the server.
    // The backend only add temporary uploaded files and
    // throw an error if there is images alread in the server.
    data.changedImages = [];
    data.changedImages = _.compact([...data.images, ...await uploadImages(files)]);
  } else {
    if (files) {
      const uploadedImages = await uploadImages(files);
      if (uploadedImages.length > 0) {
        data.changedImages = uploadedImages;
      } else { }
    } else {
      // data.changedImages = [];
      // data.changedImages = null;
    }
  }
  // console.log('updateImagesInData', data);
}

// CREATE
/**
 * Add a Promotion(PunchCard).
 * @param {Object} data   Promotion(PunchCard) object.
 * @return {Promise} Result of POST for a Promotion(PunchCard).
 */
export async function addPunchCardApi(data) {
  return await axios.post(PUNCHCARD_URL, data,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}
/**
 * Add a Promotion(PointPerk).
 * @param {Object} data   Promotion(PointPerk) object.
 * @param {File[]} files  Image files.
 * @return {Promise} Result of POST for a Promotion(PointPerk).
 */
export async function addPointPerkApi(data) {
  return await axios.post(POINTPERK_URL, data,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}
/**
 * Add a Promotion(RewardItem).
 * @param {Object} data   Promotion(RewardItem) object.
 * @return {Promise} Result of POST for a Promotion(RewardItem).
 */
export async function addRewardItemApi(data) {
  return await axios.post(REWARDITEM_URL, data,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}
/**
 * Add a Promotion(Deal).
 * @param {Object} data   Promotion(Deal) object.
 * @return {Promise} Result of POST for a Promotion(Deal).
 */
export async function addDealApi(data) {
  return await axios.post(DEAL_URL, data,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}


// TODO : revise by adding the image handling function.
// UPDATE
/**
 * Update a Promotion(PunchCard).
 * @param {string} id     ID of a Promotion object.
 * @param {Object} data   Promotion(PunchCard) object.
 * @return {Promise} Result of PUT for a Promotion(PunchCard).
 */
export async function updatePunchCardApi(id, data) {
  return await axios.put(`${PUNCHCARD_URL}/${id}`, data);
}
/**
 * Update a Promotion(PointPerk).
 * @param {string} id     ID of a Promotion object.
 * @param {Object} data   Promotion(PointPerk) object.
 * @return {Promise} Result of PUT for a Promotion(PointPerk).
 */
export async function updatePointPerkApi(id, data) {
  return await axios.put(`${POINTPERK_URL}/${id}`, data);
}
/**
 * Update a Promotion(RewardItem).
 * @param {string} id     ID of a Promotion object.
 * @param {Object} data   Promotion(RewardItem) object.
 * @return {Promise} Result of PUT for a Promotion(RewardItem).
 */
export async function updateRewardItemApi(id, data) {
  return await axios.put(`${REWARDITEM_URL}/${id}`, data);
}
/**
 * Update a Promotion(Deal).
 * @param {string} id     ID of a Promotion object.
 * @param {Object} data   Promotion(Deal) object.
 * @return {Promise} Result of PUT for a Promotion(Deal).
 */
export async function updateDealApi(id, data) {
  return await axios.put(`${DEAL_URL}/${id}`, data);
}




