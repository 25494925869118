var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "image-selection" },
    [
      !_vm.disabled
        ? _c(
            "b-input-group",
            { staticClass: "mb-3" },
            [
              _c("b-form-file", {
                attrs: {
                  "data-cy": `${_vm.name}-select-image`,
                  accept: "image/*",
                  disabled: _vm.disabled,
                },
                model: {
                  value: _vm.currentImage,
                  callback: function ($$v) {
                    _vm.currentImage = $$v
                  },
                  expression: "currentImage",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticClass: "row mb-2",
          attrs: { disabled: _vm.disabled },
          model: {
            value: _vm.imageObjects,
            callback: function ($$v) {
              _vm.imageObjects = $$v
            },
            expression: "imageObjects",
          },
        },
        _vm._l(_vm.imageObjects, function (item, index) {
          return _c("b-colxx", { key: index, attrs: { xxs: "3" } }, [
            _c(
              "div",
              {
                staticClass: "cls-img-container",
                attrs: { "data-cy": `${_vm.name}-image-${index}` },
              },
              [
                index == 0
                  ? _c("b-img", {
                      staticClass: "cls-img-main cls-img",
                      attrs: { src: item.preview },
                    })
                  : _c("b-img", {
                      staticClass: "cls-img",
                      attrs: { src: item.preview },
                    }),
                _vm._v(" "),
                !_vm.disabled
                  ? _c("div", { staticClass: "cls-img-middle" }, [
                      _c(
                        "a",
                        { attrs: { href: "javascript:void(0);" } },
                        [
                          _c("b-img", {
                            attrs: {
                              src: "/assets/icons/close.svg",
                              width: "32px",
                              height: "32px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteImage(index)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ])
        }),
        1
      ),
      _vm._v(" "),
      !_vm.disabled
        ? _c(
            "b-modal",
            {
              ref: "modalImageCrop",
              staticClass: "modal-right",
              attrs: { title: "Crop the image", size: "lg" },
              on: {
                hide: function ($event) {
                  return _vm.onCencelCropImage()
                },
              },
              model: {
                value: _vm.dialogs.imageCrop,
                callback: function ($$v) {
                  _vm.$set(_vm.dialogs, "imageCrop", $$v)
                },
                expression: "dialogs.imageCrop",
              },
            },
            [
              _c("cropper", {
                ref: "imageCropper",
                attrs: {
                  classname: "cropper",
                  backgroundClassname: "cropper-background",
                  src: _vm.imagePreview,
                  "stencil-props": { aspectRatio: 6 / 4 },
                },
              }),
              _vm._v(" "),
              _c(
                "template",
                { slot: "modal-footer" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        "data-cy": "btn-cancel",
                        variant: "outline-secondary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onCencelCropImage()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("buttons.cancel")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-1",
                      attrs: { "data-cy": "btn-submit", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmitCropImage()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("buttons.submit")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }