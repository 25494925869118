// Dropdown for Store Filter selection.
// Author : Huen Oh (heons921@gmail.com)
// @param {string}  label Label text for the dropdown. (in)
// @param {boolean} isAll To display -All- option or not. (in)

<template>
  <span class="dropdown-filter-store">
    <b-dropdown
      data-cy="top-dropdown-store"
      v-if="selectableStores.length > 2" 
      :text="`${$t('product.store')}: ${label}`" 
      variant="outline-dark" 
      class="mr-1 mb-1 btn-group align-top" 
      size="xs" 
      no-flip
    >
      <b-dropdown-item 
        v-for="(store,index) in currentSelOptions"
        :data-cy="`top-dropdown-store-item-${index}`"
        :key="index" 
        @click="onClick(store)"
      >
        {{ store.label }}
      </b-dropdown-item>
    </b-dropdown>
  </span>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props:{
    // {string} Label text for the dropdown. (in)
    label:{
      type: String,
      default: "",
    },
    // {boolean} To display -All- option or not.
    isAll:{
      type: Boolean,
      default: true,
    },
  },
  computed:{
    // Map gatters
    ...mapGetters("merchant_store",   ["selectableStores"]),
    ...mapGetters("order",            ["currentStore"]),

    // Computed value to exclude selected items from the selection option.
    currentSelOptions: function() {
      if (this.isAll) {
        return this.selectableStores;
      } else {
        return this.selectableStores.filter((item) => {
          return item.value !== "";
        });
      }
    },
  },
  mounted () {
    if (Array.isArray(this.selectableStores)) {

      // If no selectable stores are present, try fetch the data again from the server.
      // Maybe User directly wants to access the Order from stores, straight after loggin in.
      // selectableStores.length <= 1 : 1st value could be 'All' stores.
      if (this.selectableStores.length <= 1) {
        this.loadStores();
      }

      // When there is only one store, select the store. It is for Order page.
      else if(Array.isArray(this.selectableStores) && this.selectableStores.length == 2) {
        if (this.currentStore.label != this.selectableStores[1].label) {
          this.onClick(this.selectableStores[1]);
        } else {}
      } else {}

    } else {}

    
  },
  methods:{

    ...mapActions("merchant_store", [
        "loadStores"
      ]),

    /**
     * On click the button.
     */
    onClick(filter) {
      this.$emit('click', filter);
    }
  }
}
</script>
