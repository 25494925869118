// Componet for Header Dialog Product
// Author : Huen Oh (heons921@gmail.com)
// @param {string}  name        Name of the component. For data-cy.
// @param {string}  title       Title of the page. 
// @param {boolean} disabled    Diabled property of the component.
// @param {string}  status      Status of an object.
// @param {string}  actionType  Type of action : "Add", "Edit" or "Show".

<template>
  <div class="card-body  d-flex flex-column flex-lg-row justify-content-between p-0 ">
     <!-- Title -->
    <h1 class="mb-0">{{actionType}} {{$t(title)}}</h1>
    <div>
      <!-- Image button : schedule -->
      <a 
        v-if="name.includes('product')"
        :data-cy="`${name}-btn-schedule`" 
        href="javascript:void(0);"
      >
        <b-img 
          class="cls-btn-icon" 
          src="/assets/icons/work-schedule.svg" 
          @click="onClick"
        >
        </b-img>
      </a>
        <!-- Toggle button for status -->
        <toggle-button-status
          :name="name"
          :status="status"
          :disabled="disabled"
          @on-toggle-change="onChangeStatus"
        />
    </div>
  </div>
</template> 


<script>
// Events.
/**
 * Event on click Schedule image button.
 * 
 * @event HeaderDialogProduct#clickSchedule
 * @type {object}
 */
/**
 * Event on change status from ToggleButtonStatus
 * 
 * @event HeaderDialogProduct#changeStatus
 * @type {object}
 * @param {string} status Status.
 */

import ToggleButtonStatus from '@/components/Forms/ToggleButtonStatus'

export default {
  components:{
    ToggleButtonStatus, 
  },
  props:{
    // {string} Name of the component. For data-cy.
    name: {
      type: String,
      default: ""
    },
    // {string} Title of the page. 
    title: {
      type: String,
      default: ""
    },
    // {boolean} Disabled property of the component.
    disabled: {
      type: Boolean,
      default: false
    },
    // {string} Status of an object.
    status: {
      type: String,
      default: ""
    },
    // {string} Type of action : "Add", "Edit" or "Show".
    actionType:{
      type: String,
      default: ""
    },
  }, 
  methods:{  
    onClick(){
      // Event - HeaderDialogProduct#clickSchedule
      this.$emit('clickSchedule');
    },
    onChangeStatus(status){
      // Event - HeaderDialogProduct#changeStatus
      this.$emit('changeStatus', status);
    },
  },
}
</script>
<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>