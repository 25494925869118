export const defaultMenuType = 'menu-default' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
// export const defaultStartPath = '/app/dashboards/default'
export const searchPath = '/app/pages/miscellaneous/search'
export const buyUrl = 'https://1.envato.market/nEyZa'

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

export const defaultLocale = 'en'
export const defaultDirection = 'ltr'
export const localeOptions = [
  { id: 'en', name: 'English LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English RTL', direction: 'rtl' }
]

export const apiUrl = 'https://api.coloredstrategies.com'

export const isDemo = true
export const themeRadiusStorageKey = 'theme_radius'
export const defaultColor = 'light.orange'
export const colors = [
  'light.purple',
  'dark.purple',
  'light.blue',
  'dark.blue',
  'light.green',
  'dark.green',
  'light.orange',
  'dark.orange',
  'light.red',
  'dark.red'
]

// Enable this to test with local backend.
// const TEST_LOCAL = true;
const TEST_LOCAL = false;


let BE_AUTH = "https://staging.timely-api.com"; // ureka - dev
let BE_MAIN = "https://staging.timely-api.com/api"; // ureka - dev
let WS_MAIN = "wss://staging.timely-api.com/api"; // ureka - dev
let WH="https://merchant.timely-api.com"

/*
let BE_AUTH = "http://54.91.127.8:9000"; // ureka - dev
let BE_MAIN = "http://54.91.127.8:9000/api"; // ureka - dev
let WS_MAIN = "ws://54.91.127.8:9000/api"; // ureka - dev
*/

console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === 'production') {
  BE_AUTH = process.env.VUE_APP_BACKEND_ADDR;           // ureka - prod-d or prod-p
  BE_MAIN = process.env.VUE_APP_BACKEND_ADDR + "/api";  // ureka - prod-d or prod-p
  WS_MAIN = process.env.VUE_APP_WEBSOCKET_ADDR + "/api"; // ureka - dev
  WH= "https://dealigense.com";
} // else



// Change the URLs if it is for TEST_LOCAL.
if (TEST_LOCAL) {
  // BE_AUTH = "http://3.214.167.242:8091";
  //BE_MAIN = "http://localhost:8082/api";
  //WS_MAIN = "ws://localhost:8083/api";
  WH= "http://localhost:4200";
}

// URLS for the backend.
export const BE_URLS = {
  BACKEND_URL: `${BE_AUTH}`,
  BACKEND_DEAL_SERVER_URL: `${BE_MAIN}`, // Local test
  BACKEND_PAYMENT_SERVER_URL: `${BE_MAIN}`,
  BACKEND_IMAGE_UPLOAD_URL: `${BE_MAIN}/upload/multi`,
  WEBHOST:`${WH}`,
};

// Service constants.
export const INTRA_SERVICE_CORE_DEAL          = "core-deal-service";
export const INTRA_SERVICE_MERCHANT_DASHBOARD = "merchant-dashboard";
export const INTRA_SERVICE_STRIPE_PAYMENT = "stripe-payment-service";

// Dashboard : Realtime chart interval
export const REAL_TIME_INTERVAL = 2000;

// Websocket server
export const WEBSOCKET_URL = `${WS_MAIN}/websocket`;

// Maximum number of posts allowed in short period
export const MAX_NUMBER_OF_POSTS=3;
export const MAX_NUMBER_OF_DAILY_POSTS=10;
export const STRIPE_PUBLISHABLE_KEY='pk_test_51Hgz6DIl3CZpJ9676iOskyVBVlBvUsCMdD7hntFd8ih6z2bSgeAjIBU6vTgeul1L1Phwtx22NluFSSI1VppBUdpf009mF07D85';
export const STRIPE_PRICE_ID='price_1PGlahIl3CZpJ967w46jsuTt';
