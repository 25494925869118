var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-colxx",
        { attrs: { xxs: "12" } },
        [
          _c("h1", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("piaf-breadcrumb"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-2 mt-2" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.displayOptions",
                      modifiers: { displayOptions: true },
                    },
                  ],
                  staticClass: "pt-0 pl-0 d-inline-block d-md-none",
                  attrs: { variant: "empty" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("layouts.display-options")) +
                      " "
                  ),
                  _c("i", {
                    staticClass: "simple-icon-arrow-down align-middle",
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                { staticClass: "d-md-flex", attrs: { id: "displayOptions" } },
                [
                  _vm.config.btnAdd
                    ? _c(
                        "span",
                        {
                          staticClass: "mr-3 mb-2 d-inline-block float-md-left",
                        },
                        [
                          _c("ButtonAddItem", {
                            on: { click: _vm.config.btnAdd.click },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.btnEdit
                    ? _c(
                        "span",
                        {
                          staticClass: "mr-3 mb-2 d-inline-block float-md-left",
                        },
                        [
                          _c("ButtonEditItem", {
                            on: { click: _vm.config.btnEdit.click },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.btnDelete
                    ? _c(
                        "span",
                        {
                          staticClass: "mr-3 mb-2 d-inline-block float-md-left",
                        },
                        [
                          _c("ButtonDeleteItem", {
                            on: { click: _vm.config.btnDelete.click },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mr-3 mb-2 d-block d-md-inline-block" },
                    [
                      _vm.config.search
                        ? _c("SearchBar", {
                            on: { change: _vm.config.search.change },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.searchOnEnter
                        ? _c("SearchOnEnter", {
                            attrs: {
                              defaultValue: _vm.config.filters.searchText,
                            },
                            on: { onEnter: _vm.config.searchOnEnter.onEnter },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.filterStore
                        ? _c("DropdownFilterStore", {
                            attrs: { label: _vm.config.filters.store.label },
                            on: { click: _vm.config.filterStore.click },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.serveOption
                        ? _c("ServeOptionFilter", {
                            attrs: {
                              serveOptions: _vm.config.filters.serveOptions,
                            },
                            on: { change: _vm.config.serveOption.change },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.orderStatus
                        ? _c("DropdownFilterOrderStatus", {
                            attrs: {
                              selectedOrderStatus:
                                _vm.config.filters.orderStatus,
                            },
                            on: { click: _vm.config.orderStatus.click },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.dateRange
                        ? _c("SelectDateRange", {
                            attrs: { duration: _vm.config.filters.duration },
                            on: { change: _vm.config.dateRange.change },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.pageSize
                        ? _c("PaginationPageSizeFilter", {
                            attrs: { pageSize: _vm.config.filters.pageSize },
                            on: { change: _vm.config.pageSize.change },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.filterColumns
                        ? _c("DropdownFilterColumns", {
                            attrs: {
                              defaultSelected:
                                _vm.config.filterColumns.defaultSelected,
                              selectedColumns: _vm.config.filters.fields,
                            },
                            on: { change: _vm.config.filterColumns.change },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.sortOrderBy
                        ? _c("DropdownSortOrderBy", {
                            attrs: {
                              label: _vm.config.filters.order.label,
                              orderOptions: _vm.config.sortOrderBy.orderOptions,
                            },
                            on: { click: _vm.config.sortOrderBy.click },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.filterAddonGroup
                        ? _c("DropdownFilterAddonGroup", {
                            attrs: { label: _vm.config.filters.group.label },
                            on: { click: _vm.config.filterAddonGroup.click },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.filterPromotionTypes
                        ? _c("DropdownFilterPromotionTypes", {
                            attrs: {
                              label: _vm.config.filters.type.label,
                              options: _vm.config.filterPromotionTypes.options,
                            },
                            on: {
                              click: _vm.config.filterPromotionTypes.click,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.filterProductGroup
                        ? _c("DropdownFilterProductGroup", {
                            attrs: { label: _vm.config.filters.group.label },
                            on: { click: _vm.config.filterProductGroup.click },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.filterProductCategory
                        ? _c("DropdownFilterProductCategory", {
                            attrs: { label: _vm.config.filters.category.label },
                            on: {
                              click: _vm.config.filterProductCategory.click,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.TopMenuCheckbox
                        ? _c("TopMenuCheckbox", {
                            attrs: {
                              checked: _vm.config.TopMenuCheckbox.checked,
                              indeterminate:
                                _vm.config.TopMenuCheckbox.indeterminate,
                            },
                            on: { change: _vm.config.TopMenuCheckbox.change },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-2 d-inline-block float-md-left" },
                    [
                      _vm.config.draggableList
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                "data-cy":
                                  "drag-drop-cancel-product-groups-btn",
                                variant: "outline-secondary",
                                size: "xs",
                              },
                              on: {
                                click:
                                  _vm.config.draggableList.btnEditCancelDragging
                                    .click,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.config.draggableList.isDragging
                                    ? _vm.$t("buttons.cancel")
                                    : _vm.$t("buttons.edit")
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.config.draggableList &&
                      _vm.config.draggableList.isDragging
                        ? _c(
                            "b-button",
                            {
                              attrs: {
                                "data-cy": "drag-drop-save-product-groups-btn",
                                variant: "outline-secondary",
                                size: "xs",
                              },
                              on: {
                                click:
                                  _vm.config.draggableList.btnSaveDragging
                                    .click,
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("setup.save")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.config.clearAllFilters
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "mb-2 ml-auto top-menu-clear-filters-button",
                        },
                        [
                          _c("ButtonClearFilters", {
                            on: { click: _vm.config.clearAllFilters.click },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.config.draggableList
                ? _c("p", { staticClass: "text-muted" }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.config.draggableList.message)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span"),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "separator mb-5" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }