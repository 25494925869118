<template>
  <div v-if="isLoaded && !isOrderEmpty" class="order-detail-main-div">
    <!-- Dialog for Product -->
    <dialog-product
      :dialog="dialogs.product.show"
      :data="dialogs.product.value"
      :isOnProductPage="false"
      @dialog-close="closeDialogProduct"
    />

    <!-- Dialog for Addon -->
    <dialog-addon
      :dialog="dialogs.addon.show"
      :data="dialogs.addon.value"
      :isOnAddonPage="false"
      @dialog-close="closeDialogAddon"
    />

    <!-- Dialog for Promotion -->
    <dialog-promotion
      :dialog="dialogs.promotion.show"
      :data="dialogs.promotion.value"
      :isOnPromotionPage="false"
      @dialog-close="closeDialogPromotion"
      @edit-item="editPromotion(dialogs.promotion.value)"
    />

    <!-- Rejection message by merchant if any  -->
    <b-alert class="mx-3 rounded" :show="isOrderRejected" variant="danger">
      <strong>{{$t('order.message-by-merchant')}}</strong>: {{currentOrder.messageByMerchant}}
    </b-alert>
    <!-- Main Order information  -->
    <b-card class="mx-3 px-3">
      <b-row>
        <b-col md="10" class="d-flex flex-column">
          <h1 data-cy="heading-order-id"><strong>{{ heading }}</strong></h1>
          <h2>{{ storeName }}</h2>
          <h6>{{ orderCompletedAt }}</h6>
        </b-col>
        <!-- Back to Order History button (link)  -->
        <b-col md="1" class="ml-auto">
          <router-link data-cy="link-order-history" to="order-history">
            <b-button data-cy="button-back">{{ $t('buttons.back') }}</b-button>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <!-- Order status and type information  -->
        <b-col>
          <b-card
            no-body
            class="p-3 mt-3"
          >
            <b-row>
              <b-col>
                <h6>{{$t('order.fields.serve-status')}}: </h6>
              </b-col>
              <b-col>
                <h6><b-badge variant="primary" class="text-uppercase" data-cy="order-detail-server-status">{{serveStatus}}</b-badge></h6>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!-- Order preparing and delivering time information  -->
        <b-col>
          <b-card
            no-body
            class="p-3 mt-3"
            v-if="isOrderRejected === false"
          >
            <b-row class="mb-2">
              <b-col>
                <h6>{{$t('order.fields.preparing-time')}}: </h6>
              </b-col>
              <b-col>
                <h6 data-cy="order-detail-preparing-time">{{formatTime(currentOrder.preparingTime)}}</h6>
              </b-col>
            </b-row>
            <b-row
              v-if="isDeliveryOrder"
              class="mt-1"
            >
              <b-col>
                <h6>{{$t('order.fields.delivering-time')}}: </h6>
              </b-col>
              <b-col>
                <h6 data-cy="order-detail-delivering-time">{{formatTime(currentOrder.deliveringTime)}}</h6>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <!-- Customer Details -->
    <b-card class="m-3">
      <h1 class="font-weight-bold">{{ $t('order.customer-details') }}</h1>
      <h4 data-cy="order-detail-customer-name">{{currentOrder.customerName ? customerName : this.$t('customer-no-name')}}</h4>
      <!-- Customer's phone number -->
      <div v-if="currentOrder.customerPhone">
        <h4 class="text-muted" data-cy="order-detail-customer-phone">
          {{formatPhoneNumber(currentOrder.customerPhone)}}
        </h4>
      </div>
      <!-- Address for the delivery -->
      <div v-if="currentOrder.isPickup===null">
        <h4 class="text-muted" data-cy="order-detail-customer-address">{{currentOrder.deliveryAddress.unit ? currentOrder.deliveryAddress.unit + ' -' : ""}}
          {{currentOrder.deliveryAddress.streetNumber}}, 
          {{currentOrder.deliveryAddress.streetName}}, 
          {{currentOrder.deliveryAddress.city}}, 
          {{ $t('address.postal') }}: {{currentOrder.deliveryAddress.postalCode}}
        </h4>
      </div>
    </b-card>

    <b-card class="m-3">

      <!-- List of Products -->
      <ListOrderProduct 
        v-for="(product, index) in currentOrder.items" :key="index" 
        :name="`${index}`"
        :data="product"
        :detailed="true"
        @show-product="openDialogProduct(product.productId)"
        @show-addon="openDialogAddon"
      />

      <!-- Price -->
      <!-- Sub-total -->
      <div class="mt-5">
        <b-row>
          <b-col class="text-left ml-2">
            {{ $t('order.price.sub') }}
          </b-col>
          <b-col class="text-right mr-3" data-cy="order-detail-sub-total">
            ${{formatPrice(currentOrder.subAmount)}}
          </b-col>
        </b-row>
        <!-- Delivery Price -->
        <b-row v-if="currentOrder.isPickup == null">
          <b-col class="text-left ml-2">
            {{ $t('order.price.delivery') }}
            <span v-if="currentOrder.isDeliveryPriceTaxExempt">({{$t('store.delivery-no-tax')}})</span>
          </b-col>
          <b-col class="text-right mr-3" data-cy="order-detail-delivery-price">
            ${{formatPrice(currentOrder.deliveryPrice)}}
          </b-col>
        </b-row>
        <!-- Perk: Discount Amount -->
        <b-row v-if="currentOrder.discountAmount">
          <b-col class="text-left text-primary ml-2">
            {{ $t('order.price.perks') }}
          </b-col>
          <b-col class="text-right text-primary mr-3" data-cy="order-detail-discount-amount">
            - ${{formatPrice(currentOrder.discountAmount)}}
          </b-col>
        </b-row>
        <!-- Additional service fee -->
        <b-row v-if="!isNullOrUndefined(currentOrder.serviceFee) && currentOrder.serviceFee > 0">
          <b-col class="text-left ml-2">
            {{ $t('order.price.service-fee') }}
          </b-col>
          <b-col class="text-right mr-3" data-cy="order-detail-service-fee">
            ${{formatPrice(currentOrder.serviceFee)}}
          </b-col>
        </b-row>
        <!-- Applied taxes -->
        <template v-if="isNullOrUndefined(currentOrder.appliedTaxes) == false">
          <ListAppliedTaxOnOrder
            v-for="(tax, taxIdx) in currentOrder.appliedTaxes" :key="taxIdx"
            :data="tax"
            :name="`${taxIdx}`"
          />
        </template>
        <!-- Global Points used -->
        <b-row v-if="currentOrder.globalPointsUsed">
          <b-col class="text-left font-weight-bold ml-2">
            {{ $t('order.price.global-points') }}
          </b-col>
          <b-col class="text-right font-weight-bold mr-3" data-cy="order-detail-global-points-used">
            {{ formatNumber(currentOrder.globalPointsUsed) }} pts.
          </b-col>
        </b-row>
        <!-- Total Amount -->
        <b-row>
          <b-col class="text-left font-weight-bold ml-2">
            {{ $t('order.price.total') }}
          </b-col>
          <b-col class="text-right font-weight-bold mr-3" data-cy="order-detail-total-amount">
            ${{formatPrice(currentOrder.totalAmount)}}
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Global Points section  --> 
    <OrderGlobalPoints
      v-if="currentOrder.globalPointsUsed"
      cy-prefix="order-details"
      :data="getOrderGlobalPointsProps"
    />

    <!-- Perks on order -->
    <b-card v-if="promotionPerks.length > 0" class="m-3">
      <h1 class="font-weight-bold">{{ $t('order.perks') }}</h1>
      <!-- List of Promotions -->
      <ListOrderPromotion 
        v-for="(item, index) in promotionPerks" :key="index" 
        :name="`${index}`"
        :detailed="true"
        :data="item"
        @show-item="openDialogPromotion(item.id)"
        @show-applied-product="openDialogProduct"
      />
    </b-card>

  </div>
  <div v-else data-cy="div-loading" class="loading"></div>
</template>

<script>
import ListOrderProduct   from '@/components/Listing/ListOrderProduct'
import ListOrderPromotion from '@/components/Listing/ListOrderPromotion'
import DialogProduct from '@/components/Products/DialogProduct.vue'
import DialogAddon from '@/components/Products/DialogAddon.vue'
import DialogPromotion from '@/components/Promotions/DialogPromotion.vue'
import OrderGlobalPoints from '@/components/Sections/OrderGlobalPoints.vue'
import ListAppliedTaxOnOrder from '@/components/Listing/ListAppliedTaxOnOrder'

import { getPerkList } from '@/models/PromotionDetail'
import { TYPE_PROMOTION, getDealType }  from '@/models/promotion'
import { ENUM_ORDER_SERVING_STATUS_STRING, ENUM_ORDER_TYPE_STRING } from "@/models/order"
import { PATH_MAP_PROMOTIONS } from "@/constants/common.js";

import { formatNumber, formatDateWithTime, formatPhoneNumber, isNullOrUndefined }  from "@/utils/general";

import { formatTime } from "@/utils/datetime";

import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  components: {
    ListOrderProduct,
    ListOrderPromotion,
    DialogProduct,
    DialogAddon,
    DialogPromotion,
    OrderGlobalPoints,
    ListAppliedTaxOnOrder,
  },
  data() {
    return {
      isLoaded: false,

      // Handle dialogs information: Product
      dialogs: {
        product: {
          show: false,  // When to display the dialog.
          value: {},    // Which product to show.
        },

        addon: {
          show: false,
          value: {},
        },

        promotion: {
          show: false,
          value: {},
        }
      }
    }
  },
  methods: {
    ...mapActions("order", ["loadOneOrder"]),
    ...mapActions("promotion", ["getDeletedPromotion"]),
    ...mapActions("addon", ["getDeletedAddon"]),
    ...mapActions("product", ["getDeletedProduct"]),
    ...mapMutations("promotion", ["resetCurrentPromotion", "updateCurrentPromotion", "resetStep", "increaseStep"]),

    formatDateWithTime,
    formatTime,
    formatPhoneNumber,
    formatNumber,
    isNullOrUndefined,

    formatPrice(num) {
      const isToFixed = true;
      return this.formatNumber(num, isToFixed);
    },

    /**
     * Initialize the page.
     */
    async initPage() {      
      // Get the list of Perks in the PromotionDetails.
      if (this.currentOrder.promotions) {
        this.promotionPerks = getPerkList(this.currentOrder.promotions, this.currentOrder.items);
      }else {
        this.promotionPerks = [];
      }
    },

    // Dialog operations - Product
    /**
     * Close Product dialog - Add/Edit/Show.
     */
    closeDialogProduct() {
      this.dialogs.product.show = false;
    },
    /**
     * Open Product dialog - Add/Edit/Show.
     * @param {Object} product  Product object: Refer to PRODUCT_MODEL and EMPTY_PRODUCT.
     */
    async openDialogProduct(productId) {
      let product = this.findProductById(productId);

      if(isNullOrUndefined(product)){
        product = this.findDeletedProductById(productId);
      }

      if(isNullOrUndefined(product)){
        product = await this.getDeletedProduct({id : productId});
      }

      product.showOnly = true;

      if(product != undefined) {
        this.dialogs.product.value = JSON.parse(JSON.stringify(product)); // Copy
        this.dialogs.product.show = true;
        if (this.dialogs.product.value.addonGroups == null) { // TODO : move to right position or find other way
          this.dialogs.product.value.addonGroups = [];
        }

      } // else 
      product.showOnly = false;
    },

    // Dialog operations - Addon
    /**
     * Close Addon dialog - Add/Edit/Show.
     */
    closeDialogAddon() {
      this.dialogs.addon.show = false;
    },
    /**
     * Open Addon dialog - Add/Edit/Show.
     * @param {Object} addon  Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
     */
    async openDialogAddon(addonId) {
      let addon = this.findAddonById(addonId);

      if(isNullOrUndefined(addon)){
        addon = this.findDeletedProductById(addonId);
      }

      if(isNullOrUndefined(addon)){
        addon = await this.getDeletedAddon({id : addonId});
      }
      
      addon.showOnly = true;
      if(addon != undefined) {
        this.dialogs.addon.value = JSON.parse(JSON.stringify(addon)); // Copy
        this.dialogs.addon.show = true;

      } // else
      addon.showOnly = false;
    },

    //Dialog operations - Promotion
    closeDialogPromotion() {
      this.dialogs.promotion.show = false;
    },

    async openDialogPromotion(promotionId) {
      let promotion = this.findPromoById(promotionId);

      if(isNullOrUndefined(promotion)){
        promotion = this.findDeletedPromoById(promotionId);
      }

      if(isNullOrUndefined(promotion)){
        promotion = await this.getDeletedPromotion({id : promotionId});
      }

      this.dialogs.promotion.value = promotion;
      this.dialogs.promotion.show = true;
    },

    /**
     * Edit the Promotion.
     * It goes to the edit page.
     * @param {Object} item Promotion
     */
    async editPromotion(item) {
      if (item && item.id) {

        await this.resetCurrentPromotion();
        await this.updateCurrentPromotion(item);

        const promoType = item.type;
        if (promoType === TYPE_PROMOTION.REWARD_ITEM) {

          // Update the Step for bypasing the logic to edit reward items.
          // REFER TO: /src/store/modules/promotion => step value. Used in RewardDetails.vue.
          this.resetStep();
          this.increaseStep();

          this.$router.push ({ 
            name: 'reward-item-details', 
            params: {action:'edit'},
            query: {id:item.id}
          });
        }
        else if (promoType === 'DEAL') {
          this.$router.push({
            name:"promotion-general", 
            params:{type:getDealType(item), action:"edit"}, 
            query:{id:item.id}
          });
        } else {
          this.$router.push({
            name:"promotion-general", 
            params:{type:PATH_MAP_PROMOTIONS[promoType], action:"edit"}, 
            query:{id:item.id}
          });
        }
      }
    },
    isNullOrUndefined(obj){
      return isNullOrUndefined(obj);
    }
  },
  computed: {
    ...mapGetters("order", ["currentOrder"]),
    ...mapGetters("merchant_store", ["findStoreById"]),
    ...mapGetters("product", ["findProductById", "findDeletedProductById"]),
    ...mapGetters("addon", ["findAddonById", "findDeletedAddonById"]),
    ...mapGetters("promotion", ["findPromoById", "findDeletedPromoById"]),

    heading() {
      const orderNumber = this.currentOrder.id.substring(this.currentOrder.id.length - 4);
      const orderStatus = this.currentOrder.status.charAt(0) + this.currentOrder.status.slice(1).toLowerCase();

      return `${this.$t('order.order-id')}: ${orderNumber} - ${orderStatus}`;
    },

    /**
     * Makes sure to Capitalize first letter.
     */
    customerName() {
      return this.currentOrder.customerName.split(" ")
        .map(name => name.charAt(0).toUpperCase() + name.substring(1))
        .join(" ");
    },

    /**
     * Get store name by store Id from currentOrder.
     */
    storeName() {
      return this.findStoreById(this.currentOrder.storeId).name;
    },

    /**
     * Get formatted date time for Order completion.
     */
    orderCompletedAt() {
      return this.formatDateWithTime(this.currentOrder.lastUpdatedAt);
    },

    /**
     * Get the Serve Status of the Order.
     */
    serveStatus() {
      if (this.currentOrder.isPickup === true) {
        return this.$t('order.serve-status.pickup')
      } else if (this.currentOrder.isPickup === false) {
        return this.$t('order.serve-status.dine-in')
      } else if (this.currentOrder.isPickup === null) {
        return this.$t('order.serve-status.delivery')
      }
    },

    /**
     * Check if the Order's Serve Type is Delivery.
     */
    isDeliveryOrder() {
      return this.currentOrder.isPickup === null;
    },

    /**
     * Check if the Order's rejected to display the rejection message.
     */
    isOrderRejected() {
      return this.currentOrder.serveStatus === ENUM_ORDER_SERVING_STATUS_STRING.REJECTED
              && this.currentOrder.messageByMerchant !== null;
    },

    /**
     * {boolean} To check if the Order is empty.  
     */ 
    isOrderEmpty() {
      return this.currentOrder.id ? false : true;
    },

    /**
     * Return global points information for display 
     */
    getOrderGlobalPointsProps() {
      return {
        globalPointsUsed: this.currentOrder.globalPointsUsed,
        globalPointsEquivalentAmount: this.currentOrder.globalPointsEquivalentAmount,
      }
    }
  },

  async mounted() {
    await this.loadOneOrder(this.$route.params.id);
    await this.initPage();
    this.isLoaded = true;
  }
}
</script>


<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>
