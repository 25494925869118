var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      staticClass: "mr-1 mb-1 btn-group",
      attrs: {
        "data-cy": "top-dropdown-serveOption",
        text: _vm.serveOptionsLabel,
        variant: "outline-dark",
        size: "xs",
        "no-flip": "",
      },
    },
    _vm._l(_vm.serveOptions, function (serveOption, index) {
      return _c(
        "b-dropdown-item",
        {
          key: index,
          attrs: {
            "data-cy": `top-dropdown-serveOption-item-${index}`,
            active: serveOption.active,
          },
          on: {
            click: function ($event) {
              return _vm.onClick(serveOption)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(serveOption.text) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }