/**
 * It contains model of Addon object.
 */

 // General parse and serialize function
import { parse, serialize } from "../general.js";

/**
 * Addon Model - for Parse and Serialize use.
 */
const ADDON_MODEL = {
  id: "id",
  name: "name",
  description: "description",
  images: "images",
  attributes: "attributes",
  // categories: "categories",
  price: "price",
  // unit: "unit",
  merchantID: "merchantID",
  stores: "stores",
  productTaxConfigs: "productTaxConfigs",
  groups: "groups",
  isAddon: "isAddon",
  // addonGroups: "addonGroups",
  hideUntil: "hideUntil",
  availableTime: "availableTime",
  status: "addonStatus",
  lastUpdatedAt: "lastUpdatedAt",
};

/**
 * Empty default Addon.
 */
export const EMPTY_ADDON = {
  name: "",
  description: "",
  images: null,
  attributes: {},
  // categories: [],
  price: 0.0,
  // unit: "",
  // merchantId: "merchantId",
  stores: [],
  productTaxConfigs: [],
  groups: [],
  // isAddon: true,
  addonGroups: null,
  hideUntil: null,
  avaiableTime: null,
  status: "INACTIVE",
  lastUpdatedAt: ""
};

/**
 * Parse an Addon object.
 * @param {Object} addon Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
 * @return {Object} Prased Addon object.
 */
export function parseAddon(addon) {
  return addon;
  // TODO
  // return parse(addon, ADDON_MODEL);
}

/**
 * Parse an Addon object.
 * @param {Object} addon Addon object: Refer to ADDON_MODEL and EMPTY_ADDON.
 * @return {Object} Serialized Addon object.
 */
export function serializeAddon(addon) {
  return addon;
  // TODO
  // return serialize(addon, ADDON_MODEL);
}
