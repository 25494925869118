/**
 * It contains General Apis.
 * @author: Huen Oh (heons921@gmail.com)
 */

// Constant.
import { BE_URLS, KEY_TIMEZONE_DB_API } from "@/constants/config.js"

// 3rd party libraries.
import _      from "lodash";
import axios  from "axios";

// Modules in the application.
import axios_core_deal from "@/api/axios/axios_core_deal.js";

// URLs for backend
const HOST = BE_URLS.BACKEND_DEAL_SERVER_URL;
const INQUIRY_MERCHANT_URL = `${HOST}/inquiry/merchant`;
const GET_TIMEZONE_URL = `${HOST}/timezone`;

/**
 * Upload one image.
 * @param {string}  url        Backend URL for the upload.
 * @param {File}    imageFile  File object.
 * @return {string} Uploaded image. null if it's failed.
 */
export async function uploadOneImageApi(url, imageFile) {
  const formData = new FormData();
  formData.append("file", imageFile);
  const dataUploaded = await axios_core_deal.post(url, formData);
  const imagesFromServer = dataUploaded.data;
  if (
    !imagesFromServer ||
    !Array.isArray(imagesFromServer) ||
    !imagesFromServer[0].uploaded
  ) {
    console.log("Upload is failed");
    return null;
  }
  return imagesFromServer[0].uploaded;
}

/**
 * Upload multiple images.
 * @param {string} url        Backend URL for the upload.
 * @param {File[]} imageFiles File objects.
 * @return {string[]} Uploaded images. Empty arrary if it's failed.
 */
export async function uploadImagesApi(url, imageFiles) {
  let uploadedImages = [];

  // Check the input.
  if (!imageFiles || !imageFiles.length || imageFiles.length <= 0) {
    console.log("uploadImagesApi: No image in imageFiles");
    return uploadedImages; // Empty arrary
  } // else

  // Create Form data for images.
  const formData = new FormData();
  for (const image of imageFiles) {
    formData.append("file", image);
  }

  // Upload.
  const dataUploaded = await axios_core_deal.post(url, formData);
  const imagesFromServer = dataUploaded.data;
  if (
    !imagesFromServer ||
    !Array.isArray(imagesFromServer) ||
    (imagesFromServer.length != imageFiles.length) ||
    !imagesFromServer[0].uploaded // TODO : Check only the first one?
  ) {
    console.log("uploadImagesApi: Upload is failed");
    return uploadedImages; // Empty arrary
  } // else

  // Update uploaded images.
  uploadedImages = imagesFromServer.map((image) => { return image.uploaded; });
  return _.compact(uploadedImages);
}


/**
 * Update 'data' by updating images.
 *
 * It upload images and update 'data.changedImages' and 'data.deletedImages'.
 *
 * @param {string}   url            Backend URL for the upload.
 * @param {Object}   data           Object: {images, deletedImages, changedImages}.
 * @param {Object[]} [imageObjects] Image objects: [{files, preview}]
 */
export async function updateImagesApi(url, data, imageObjects) {

  // console.log('imageObjects', imageObjects);
  if (Array.isArray(imageObjects)) {

    if (imageObjects.length <= 0) {
      // Delete all images if there are no images - tested
      if ((Array.isArray(data.images)) && (data.images.length > 0)) {
        //delete detailImage
        data.deletedImages = data.images;
      } // else
      // console.log(data);

    } else {
      // deletedImages
      data.deletedImages = [];
      if (Array.isArray(data.images)) {
        for (const image of data.images) {
          const isFound = imageObjects.find(element => element.preview == image);
          if (!isFound) {
            data.deletedImages.push(image);
          } // else
        }
      }

      // changedImages
      data.changedImages = [];
      for (let i = 0; i < imageObjects.length; ++i) {
        const image = imageObjects[i];
        // console.log("image", image);
        if (image.file != null) {
          // Upload image file -> Add to detailImages
          const uploadedImage = await uploadOneImageApi(url, image.file);
          // console.log("Upload-details", uploadedImage);
          if (uploadedImage) {
            data.changedImages.push(uploadedImage);
          } else {
            // TODO : error handling by return empty Promise?
          }
        } else {
          // add preview(URL) to detailImage
          data.changedImages.push(image.preview);
        }
      }
    }

  } // else
}

/**
 * Get Timezone using TimeZoneDB API.
 * @param {number} lat Latitude.
 * @param {number} lng Longitude.
 * @return {Promise} Resut of GET for the timezone of the desired location.
 */
export async function getTimeZoneApi(lat, lng) {
  const endpoint = `${GET_TIMEZONE_URL}?lat=${lat}&lng=${lng}`;

  return await axios_core_deal.get(endpoint);
}


/**
 * Post merchant inquiry request.
 * @param {string} data Email request data: {storeId, inquiryTitle, inquiryDescription}
 * @return {Promise} GET Promise.
 */
export function postMerchantInquiryApi(data) {
  return axios_core_deal.post(INQUIRY_MERCHANT_URL, data,{
    headers:{
      'Access-Control-Allow-Origin':'*',
    }
  });
}

