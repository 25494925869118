// List of applied tax on Products/Addons. To display on Order.
// Props: data {Object} Tax info
//        name {String} for data-cy
//        type {String} Product/Addon

<template>
	<b-row v-if="(isNullOrUndefined(data) == false) && (!isNullOrUndefined(data.taxAmount)) && (data.taxAmount > 0)" :data-cy="`applied-tax-${type}-${name}`" class="text-muted">
    <b-col> 
      <span class="ml-5" :data-cy="`tax-type-${type}-${name}`">{{data.taxType}} ({{data.taxPercentage}}%)</span>
    </b-col>
    <b-col class="order-item-price">
      <div class="text-right mr-5" :data-cy="`tax-amount-${type}-${name}`">+ ${{formatPriceWith2Decimal(data.taxAmount)}}</div>
    </b-col>
  </b-row>

</template>

<script>
import {formatPriceWith2Decimal, isNullOrUndefined} from '@/utils/general'

export default {
  props: {
    // Tax object: taxType, taxPercentage, taxAmount
    data: {
      type: Object,
      default: () => {},
    },
    // For data cy
    name: {
      type: String,
      default: "",
    },
    // Product or addon
    type: {
      type: String,
      default: "",
    }
  },
  methods: {
    formatPriceWith2Decimal,
    isNullOrUndefined,
  }
}
</script>