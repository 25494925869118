// List componet of Orders.
// Author : Huen Oh (heons921@gmail.com) && Sukhpreet Singh
// @param {string} name Name of the component: Index in usual.
// @param {Object} data Store object: Refer to MODEL_ORDER_ITEM.
// @param {Boolean} detailed value to add link to product information. Requires Parent component to have DialogProduct component. 


<template>
  <b-card 
    :data-cy="`list-item-order-product-${name}`"
    class="my-2 py-1" 
    no-body
  >
    <!-- Product -->
    <div class="mb-3">

      <!-- Product title  -->
      <b-row>
        <b-col>
          <h3 class="text-left font-weight-bold ml-5 mt-3 mb-2 pb-0">
            <template v-if="detailed">
              <router-link :data-cy="`list-item-order-product-${name}-link`" :to="`?p=${data.productId}`">
                <span @click="onTitleClick">{{data.productName}}</span>
              </router-link>
            </template>
            <template v-else>
              {{data.productName}}
            </template>

            <b-badge v-if="productType != null"
               class="item-type-badge"
              :variant="isRedeemItem() ? 'primary' : 'info'"
              :data-cy="`item-order-product-${name}-link`">{{productType}}
            </b-badge>
          </h3> 
        </b-col>
      </b-row>


      <div class="ml-3">
        <!-- Quantity and price  -->

        <b-row v-if="!isNullOrUndefined(data.quantity) && !isNullOrUndefined(data.price) && !isNullOrUndefined(data.totalAmount)">
          <b-col>
            <h6 class="ml-5 mb-0 pb-0">
              {{data.quantity}} x ${{formatNumber(data.price)}}
            </h6>
          </b-col>
          <b-col>
            <h6 class="text-right mr-5 mb-0 pb-0">
              ${{formatNumber(data.totalAmount)}}
            </h6>
          </b-col>
        </b-row>

        <!-- Discount  -->

        <b-row class="text-muted" v-if="!isNullOrUndefined(data.totalDiscountAmount) && data.totalDiscountAmount > 0">
          <b-col>
            <h6 class="ml-5 mb-0 pb-0">{{$t('general.discounts')}}</h6>
          </b-col>
          <b-col>
            <h6 class="mr-5 text-right mb-0 pb-0">
              - ${{formatNumber(data.totalDiscountAmount)}}
            </h6>
          </b-col>
        </b-row>

        <!-- Applied Tax for products  -->
        <template v-if="isNullOrUndefined(data.appliedTaxes) == false">
          <ListAppliedTaxOnItem
            v-for="(tax, taxIdx) in data.appliedTaxes" :key="`product-${taxIdx}`"
            :data="tax"
            :name="`${taxIdx}`"
            type="product"
          />
        </template>
      </div>
    </div>
    <!-- Product End  -->

    <!-- Addons -->
    <div class="mb-3 ">
      <template v-for="(addon, index) in data.addons">

        <!-- Addon title  -->
        <b-row :key="`title-${index}`">
          <b-col class="order-item-name">
            <h6 class="text-left text-muted ml-5 mb-1 pb-0">
              <template v-if="detailed">
                <router-link 
                  :data-cy="`list-item-order-addon-${name}-name-${index}`" 
                  :to="`?p=${addon.productId}`"
                >
                  <span @click="onAddonClick(addon.productId)">{{addon.productName}}</span>
                </router-link>
              </template>
              <template v-else>
                {{addon.productName}}
              </template>
            </h6>
          </b-col>
        </b-row>

        <div class="ml-3" :key="index">
          <!-- Addon quantity and amount  -->
          <b-row :key="`quantity-${index}`" v-if="!isNullOrUndefined(addon.price) && !isNullOrUndefined(addon.quantity) && !isNullOrUndefined(data.quantity)">
            <b-col>
              <p class="ml-5 mb-0 pb-0">
                {{addon.quantity * data.quantity}} x ${{formatNumber(addon.price)}}
              </p>
            </b-col>
            <b-col>
              <p class="text-right mr-5 mb-0 pb-0">
                ${{formatNumber(addon.price * addon.quantity * data.quantity)}}
              </p>
            </b-col>
          </b-row>

          <!-- Discount  -->
          <b-row class="text-muted" :key="`discount-${index}`" v-if="!isNullOrUndefined(addon.totalDiscountAmount) && addon.totalDiscountAmount > 0">
            <b-col>
              <p class="ml-5 mb-0 pb-0">{{$t('general.discounts')}}</p>
            </b-col>
            <b-col>
              <p class="mr-5 text-right mb-0 pb-0">
                - ${{formatNumber(addon.totalDiscountAmount)}}
              </p>
            </b-col>
          </b-row>

          <!-- Applied Tax for products  -->
          <template v-if="isNullOrUndefined(addon.appliedTaxes) == false">
            <ListAppliedTaxOnItem
              v-for="(tax, taxIdx) in addon.appliedTaxes" :key="`tax-${index}-${taxIdx}`"
              :data="tax"
              :name="`${taxIdx}`"
              type="addon"
            />
          </template>
        </div>

      </template>
    </div> 
    <!-- End Addon  -->

    <!-- Instruction -->
    <b-row v-if="data.instruction">
      <b-col cols="1">
        <div class="order-num-items ml-2 p-0">
          <p class="text-right text-medium mt-2 mx-2"></p>
        </div>
      </b-col>
      
      <b-col cols="8" class="order-item-name">
        <p 
          class="text-left text-medium text-muted ml-2 mb-2"
          :data-cy="`list-item-order-product-${name}-instructions`"
        >
          {{data.instruction}}
        </p>
      </b-col>
    </b-row>
      
  </b-card>
</template>

<script>

import { formatNumber, isNullOrUndefined } from "@/utils/general";

import { mapGetters, mapActions } from 'vuex';

import { ENUM_ORDER_ITEM_TYPE_STRING } from "@/models/order";
import { TYPE_PROMOTION } from "@/models/promotion";


import ListAppliedTaxOnItem from '@/components/Listing/ListAppliedTaxOnItem';

export default {
  props:{
    // {string} Name of the component. Index in usual.
    name: {
      type: String,
      default: ""
    },
    // {Object} Store object: Refer to MODEL_ORDER_ITEM.
    data: {
      type: Object,
      default:() => {}
    },
    // {Boolean} whether to display product detail information using dialog or not. Being used in OrderDetail.vue.
    detailed: {
      type: Boolean,
      default:() => false
    }
  },
  components: {
    ListAppliedTaxOnItem,
  },
  computed: {
    ...mapGetters("promotion", ["findPromoById", "findDeletedPromoById"])
  },
  data(){
    return {
      productType: null
    }
  },
  methods: {
    isNullOrUndefined,
    
    ...mapActions("promotion", ["getDeletedPromotion"]),

    formatNumber(num){
      const isToFixed = true;
      return formatNumber(num, isToFixed);
    },
    onTitleClick() {
      this.$emit("show-product");
    },
    onAddonClick(addonId) {
      this.$emit("show-addon", addonId)
    },
    /**
     * Get type of Promotion. Refer to @model/promotion/TYPE_PROMOTION
     */
    async getPromotionType(promotionId){
      let promotion = this.findPromoById(promotionId);

      if(isNullOrUndefined(promotion)){
        promotion = this.findDeletedPromoById(promotionId);
      }

      if(isNullOrUndefined(promotion)){
        promotion = await this.getDeletedPromotion({id : promotionId});
      }

      if(isNullOrUndefined(promotion)){
        return null;
      }

      return promotion.type;
    },
    isRedeemItem() {
      return this.productType === this.$t('order.item.type.redeem');
    }
  },
  /**
     * Determine whether the product is NORMAL ITEM, FREE ITEM or REDEEM ITEM
     */
    async mounted(){
      if(this.data.appliedPromotionId == null){
        return null;
      }

      const type = await this.getPromotionType(this.data.appliedPromotionId);

      if(type == null || (type != TYPE_PROMOTION.PUNCH_CARD && type != TYPE_PROMOTION.REWARD_ITEM
       && type != TYPE_PROMOTION.DEAL )){
        this.productType = null;
      }
      else if(type === TYPE_PROMOTION.PUNCH_CARD || type === TYPE_PROMOTION.REWARD_ITEM ){
        this.productType = this.$t('order.item.type.redeem');
      }else if(type === TYPE_PROMOTION.DEAL){
        this.productType = this.$t('order.item.type.free-item');
      }
    }
}
</script>

<style src="@/assets/css/sass/style_custom.scss" lang="scss" scoped></style>