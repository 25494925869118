var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    [
      _c(
        "b-form",
        [
          _c(
            "b-row",
            [
              _c(
                "b-colxx",
                { attrs: { xxs: "12", md: "6" } },
                [
                  _c("h4", { staticClass: "mb-0 pb-4" }, [
                    _vm._v(_vm._s(_vm.$t("support.send-email.title"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    [
                      _c("h4", { staticClass: "pb-2" }, [
                        _vm._v(
                          _vm._s(_vm.$t("support.send-email.choose-store"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("SelectOneStore", {
                        ref: "select-one-store",
                        attrs: { name: "support", colSize: 12, isAll: false },
                        on: { click: _vm.onSelectStore },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    [
                      _c("h4", { staticClass: "mb-0 pb-2" }, [
                        _vm._v(
                          _vm._s(_vm.$t("support.send-email.issue-title"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("SelectOneIssue", {
                        ref: "select-one-issue",
                        attrs: { name: "support", colSize: 12 },
                        on: { click: _vm.onSelectIssue },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    [
                      _c("h4", { staticClass: "mb-0 pb-2" }, [
                        _vm._v(
                          _vm._s(_vm.$t("support.send-email.issue-description"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-textarea", {
                        attrs: {
                          "data-cy": "support-issue-description",
                          rows: 5,
                          "max-rows": 5,
                        },
                        model: {
                          value: _vm.issue.inquiryDescription,
                          callback: function ($$v) {
                            _vm.$set(_vm.issue, "inquiryDescription", $$v)
                          },
                          expression: "issue.inquiryDescription",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "float-sm-right" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            "data-cy": "btn-send",
                            variant: "primary",
                            size: "sm",
                            disabled: _vm.isButtonDisabled,
                          },
                          on: { click: _vm.next },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("buttons.send")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-colxx", { attrs: { xxs: "12", md: "6" } }, [
                _c("h4", { staticClass: "mb-0 pb-2" }, [
                  _vm._v(_vm._s(_vm.$t("support.information.title")) + ":"),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-muted text-medium" }, [
                  _vm._v(_vm._s(_vm.$t("support.information.description"))),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-muted text-medium" }, [
                  _vm._v(_vm._s(_vm.$t("support.information.website")) + ": "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://timelyperks.com/contact/knowledge-base",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("support.contact.website")))]
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-muted text-medium" }, [
                  _vm._v(_vm._s(_vm.$t("support.information.email")) + ": "),
                  _c(
                    "a",
                    { attrs: { href: "mailto:support@timelyperks.com" } },
                    [_vm._v(_vm._s(_vm.$t("support.contact.email")))]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }