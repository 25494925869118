var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    { staticClass: "d-inline-block" },
    [
      _c("b-form-checkbox", {
        staticClass: "ml-4",
        attrs: {
          "data-cy": "top-checkbox",
          checked: _vm.checked,
          indeterminate: _vm.indeterminate,
        },
        on: { change: _vm.onChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }