var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "navbar fixed-top" },
    [
      _c("DialogAuthCurrentUser", {
        attrs: {
          title: _vm.$t("order.change-store.title"),
          description: _vm.$t("order.change-store.description"),
          dialog: _vm.dialogs.auth,
          showStoreFilter: _vm.showStoreFilter,
        },
        on: {
          "dialog-close": _vm.closeDialogAuthCurrentUser,
          "dialog-submit": _vm.onSubmitDialogAuthCurrentUser,
        },
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "menu-button d-none d-md-block ml-4",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.changeSideMenuStatus({
                step: _vm.menuClickCount + 1,
                classNames: _vm.menuType,
                selectedMenuHasSubItems: _vm.selectedMenuHasSubItems,
              })
            },
          },
        },
        [
          _vm.type !== "app-orders"
            ? _c("menu-icon")
            : _c(
                "div",
                { staticClass: "d-inline-flex" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        size: "sm",
                        type: "button",
                        "data-cy": "top-nav-order-back-button",
                      },
                      on: { click: _vm.goToPreviousPage },
                    },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.$t("buttons.back")) + "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.showStoreFilter
                    ? _c(
                        "b-button",
                        {
                          staticClass: "change-store ml-2",
                          attrs: {
                            variant: "primary",
                            size: "sm",
                            type: "button",
                            "data-cy": "top-nav-order-change-store-button",
                          },
                          on: { click: _vm.openDialogAuthCurrentUser },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("buttons.select-store")) +
                              ": " +
                              _vm._s(_vm.currentStore.label) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "menu-button-mobile d-xs-block d-sm-block d-md-none",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.changeSideMenuForMobile(_vm.menuType)
            },
          },
        },
        [
          _vm.type !== "app-orders"
            ? _c("mobile-menu-icon")
            : _c(
                "div",
                { staticClass: "d-inline-flex flex-column" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "back-button",
                      attrs: {
                        variant: "primary",
                        size: "xs",
                        type: "button",
                        "data-cy": "top-nav-order-back-button",
                      },
                      on: { click: _vm.goToPreviousPage },
                    },
                    [_vm._v("\n        Back\n      ")]
                  ),
                  _vm._v(" "),
                  _vm.showStoreFilter
                    ? _c(
                        "b-button",
                        {
                          staticClass: "change-store mt-1",
                          attrs: {
                            variant: "primary",
                            size: "xs",
                            type: "button",
                            "data-cy": "top-nav-order-change-store-button",
                          },
                          on: { click: _vm.openDialogAuthCurrentUser },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("buttons.select-store")) +
                              ": " +
                              _vm._s(_vm.currentStore.label) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "navbar-logo", attrs: { tag: "a", to: "/app" } },
        [
          _c("span", [
            _c("img", {
              staticStyle: { "max-height": "100%" },
              attrs: {
                src: require("../assets/img/dealigenceLogoFillOrange.svg"),
              },
            }),
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "logo-mobile d-xs-block d-sm-block d-md-none",
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "header-icons d-inline-block align-middle" },
          [
            _c("IconWithBadge", {
              class: _vm.orderIconStyle,
              attrs: {
                data: _vm.orderMenuWithBadge,
                "data-cy": "top-nav-order-link-icon",
              },
              on: { click: _vm.onOrderPageIconClick },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "position-relative d-none d-sm-inline-block" },
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      variant: "empty",
                      size: "sm",
                      right: "",
                      "toggle-class": "header-icon",
                      "menu-class": "position-absolute mt-3 iconMenuDropdown",
                      "no-caret": "",
                    },
                  },
                  [
                    _c("template", { slot: "button-content" }, [
                      _c("i", { staticClass: "simple-icon-grid" }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "icon-menu-item",
                            attrs: { tag: "a", to: { name: "app-dashboards" } },
                          },
                          [
                            _c("i", {
                              staticClass: "iconsminds-shop-4 d-block",
                            }),
                            _vm._v(
                              _vm._s(_vm.$t("menu.dashboards")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "icon-menu-item",
                            attrs: { tag: "a", to: { name: "app-setup" } },
                          },
                          [
                            _c("i", {
                              staticClass: "iconsminds-digital-drawing d-block",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("menu.setup")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "icon-menu-item",
                            attrs: { tag: "a", to: { name: "app-products" } },
                          },
                          [
                            _c("i", { staticClass: "iconsminds-mine d-block" }),
                            _vm._v(
                              _vm._s(_vm.$t("menu.products")) + "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "icon-menu-item",
                            attrs: { tag: "a", to: { name: "app-promotions" } },
                          },
                          [
                            _c("i", {
                              staticClass: "iconsminds-gift-box d-block",
                            }),
                            _vm._v(
                              _vm._s(_vm.$t("menu.promotions")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "icon-menu-item",
                            attrs: { tag: "a", to: { name: "order-history" } },
                          },
                          [
                            _c("i", {
                              staticClass: "simple-icon-notebook d-block",
                            }),
                            _vm._v(
                              _vm._s(_vm.$t("menu.order-history")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "icon-menu-item",
                            attrs: { tag: "a", to: { name: "support-main" } },
                          },
                          [
                            _c("i", {
                              staticClass: "simple-icon-support d-block",
                            }),
                            _vm._v(
                              _vm._s(_vm.$t("menu.support")) + "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "header-icon d-none d-sm-inline-block",
                attrs: { variant: "empty" },
                on: { click: _vm.toggleFullScreen },
              },
              [
                _c("i", {
                  class: {
                    "d-block": true,
                    "simple-icon-size-actual": _vm.fullScreen,
                    "simple-icon-size-fullscreen": !_vm.fullScreen,
                  },
                }),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "user d-inline-block" },
          [
            _c(
              "b-dropdown",
              {
                staticClass: "dropdown-menu-right",
                attrs: {
                  right: "",
                  variant: "empty",
                  "toggle-class": "p-0",
                  "menu-class": "mt-3",
                  "no-caret": "",
                },
              },
              [
                _c("template", { slot: "button-content" }, [
                  _c("span", { staticClass: "name mr-1 username" }, [
                    _vm._v(
                      _vm._s(
                        (_vm.profileMain && _vm.profileMain.name) ||
                          _vm.$t("general.loading")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _c("img", {
                      attrs: {
                        src: _vm.avatarURL || _vm.noAvatar,
                        alt: "Avatar",
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  { on: { click: _vm.onClickUserProfile } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("user-profile.profile")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("b-dropdown-item", { on: { click: _vm.signOut } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("user-profile.sign-out")) +
                      "\n        "
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.isStripeConnected
        ? _c(
            "div",
            { staticClass: "global-notification-message vertical-center" },
            [
              _c(
                "b-colxx",
                { staticClass: "float-left", attrs: { md: "9" } },
                [
                  _c("b-row", [
                    _c("span", { staticClass: "text-one" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("payment.status.payment-method-not-connected")
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-row", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("payment.stripe-not-setup-warning"))
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-colxx", { attrs: { md: "3" } }, [
                _c(
                  "div",
                  { staticClass: "btn background-theme-color float-right" },
                  [
                    _c(
                      "router-link",
                      {
                        staticStyle: { color: "white", "font-weight": "bold" },
                        attrs: { tag: "a", to: { name: "payment" } },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("payment.connect.let-connect")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }