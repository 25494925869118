import Stomp from "webstomp-client";
import LocalStorageService from "@/utils/local_store.js";

export default {
  install(Vue) {
    // Connect Websocket
    let connectWS = function (serverEndPoint, connectCallback, errorCallback) {
      // en:If the connection has established, then return. Avoid multiple connections 
      if (Vue.$stompClient && Vue.$stompClient.connected) {
        console.log('websocket is connected');
        return;
      }

      const token = LocalStorageService.getAccessToken();

      var headers = {
        "Authorization": `Bearer ${token}`
      };

      /*
      // In case connect STOMP over other type of Websocket
      let socket = new WebSocket(serverEndPoint, "v10.stomp");
      let stompClient = Stomp.over(socket);
      */

      // Debug mode to console.log all events
      const isDebug = (process.env.NODE_ENV !== 'production');
      const options = {
        // TODO: Enable this logic to deploy production
        debug: isDebug
        //debug: true
      }; 

      // Provide multiple version depending on client browser support
      const socketVersion = ["v12.stomp", "v11.stomp", "v10.stomp"];
      const socket = new WebSocket(serverEndPoint, socketVersion);

      let stompClient = Stomp.over(socket, options);

      // WS client send heartbeat every 30 seconds
      stompClient.heartbeat.outgoing = 30000;
      stompClient.heartbeat.incoming = 30000;

      Vue.$stompClient = Vue.prototype.$stompClient = stompClient;

      Vue.$stompClient.connect(headers, connectCallback, errorCallback);
    }
    // en:Set the connection function that with monitor function
    Vue.$connectWS = Vue.prototype.$connectWS = connectWS;
    // en:disconnect
    let disconnectWS = function (callback) {
      // en:disconnect
      if (Vue.$stompClient && Vue.$stompClient.connected) {
        Vue.$stompClient.disconnect(callback);
      }
    };

    Vue.$disconnectWS = Vue.prototype.$disconnectWS = disconnectWS;
  }
};